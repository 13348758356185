import InnerBox from "@/components/Atoms/Box/innerBox"
import {FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"

const LangTh = () => {

    return (<>
    <InnerBox>
    <h1>สภาพแวดล้อมในการดำรงชีวิต</h1>
    <p>
    ขอให้ทำความเข้าใจในประเด็นสำคัญดังต่อไปนี้และดำเนินการอย่างถูกต้องเหมาะสม พร้อมขอรับการสนับสนุนจากรัฐบาลผ่านช่องทางให้คำปรึกษา เพื่อจะได้หาที่อยู่อาศัยและใช้ชีวิตในฟุกุโอกะได้อย่างอุ่นใจ
    </p>
    </InnerBox>
    <BlackNote>
    <ul className="deco">
    <li>
    <h4>１.วิธีการหาที่พักอาศัย</h4>
    <p>
    ●ใช้เวปไซค์หรือแอปพลิเคชั่นเกี่ยวกับข้อมูลอสังหาริมทรัพย์ในการหาที่พักอาศัย<br/>
    ●ใช้บริการบริษัทจัดหาที่พักที่สามารถให้บริการเป็นภาษาต่างประเทศ
    </p>
    </li>
    <li>
    <h4>２.ประเภทของที่อยู่อาศัยแบบให้เช่า</h4>
    <p>
    ●「ที่อยู่อาศัยของเอกชน」：ที่พักอาศัยที่เช่าจากเจ้าของบ้าน（ผู้ให้เช่า）<br/>
    ●「ที่อยู่อาศัยของรัฐบาล」：ที่พักอาศัยที่จังหวัดหรือเทศบาลเปิดให้เช่า<br/>
    ●「ที่อยู่อาศัยของＵＲ」：ที่พักอาศัยที่「หน่วยงาน UR」เปิดให้เช่า ซึ่งไม่ต้องมีผู้ค้ำประกัน
    </p>
    </li>
    <li>
    <h4>３.หลักเกณฑ์ของการทำสัญญาเช่าที่พัก</h4>
    <p>
    ●ปกติแล้วในสัญญาการเช่าที่พักอาศัยจะระบุเนื้อหาเกี่ยวกับเงินมัดจำ(เงินประกัน) เงินตอบแทน และค่าเช่าไว้ด้วย ฉะนั้นต้องศึกษาทำความเข้าใจในรายละเอียดและตรวจสอบเงื่อนไขของสัญญาให้ดี
    </p>
    </li>
    <li>
    <h4>４.เงินมัดจำและเงินตอบแทน</h4>
    <p>
    ●เงินมัดจำจะได้รับคืนเมื่อสิ้นสุดสัญญาเช่า แต่ในกรณีที่อุปกรณ์เครื่องใช้หรือห้องพักบางส่วนเกิดความเสียหายจากความสกปรกเปื้อนจะถูกหักค่าเสียหายจากเงินมัดจำ เงินตอบแทนจะไม่ได้รับคืนเมื่อสิ้นสุดสัญญาเช่า
    </p>
    </li>
    <li>
    <h4>５.ผู้ค้ำประกัน</h4>
    <p>
    ●หลังจากย้ายเข้าที่พักแล้วจะต้องทำเรื่องแจ้งย้ายเข้าในทะเบียนที่อยู่อาศัย ณ ที่ว่าการองค์กรการปกครองส่วนท้องทิ่นที่อาศัยอยู่นั้น
    </p>
    </li>
    <li>
    <h4>６.ค่าอินเตอร์เน็ตและค่าสาธารณูปโภค</h4>
    <p>
    ●ที่พักอาศัยส่วนใหญ่จะมีการติดตั้งระบบอินเตอร์เน็ตไว้อยู่แล้ว แต่ในส่วนค่าสาธารณูปโภค(เช่น ค่าไฟ,ค่าแก๊ส,ค่าน้ำประปา เป็นต้น) จะต้องจ่ายแยกต่างหากจากค่าเช่า
    </p>
    </li>
    <li>
    <h4>７.ของตกแต่งในบ้านและเครื่องใช้ไฟฟ้า</h4>
    <p>
    ●ที่อยู่อาศัยสำหรับให้เช่าบางที่จะมีของตกแต่งในบ้านหรือเครื่องใช้ไฟฟ้าให้ กรุณาตัดสินใจว่าจะเช่าที่อยู่อาศัยแบบที่มีให้หรือจะจัดซื้อจัดหาเอง
    </p>
    </li>
    <li>
    <h4>８.การแจ้งเข้าทะเบียนที่อยู่อาศัย</h4>
    <p>
    หลังจากย้ายเข้าที่พักแล้วจะต้องทำเรื่องแจ้งย้ายเข้าในทะเบียนที่อยู่อาศัย ณ ที่ว่าการองค์กรการปกครองส่วนท้องทิ่นที่อาศัยอยู่นั้น
    </p>
    </li>
    <li>
    <h4>9.ขนบธรรมเนียนและกฎระเบียบในการดำรงชีวิตในญี่ปุ่น</h4>
    <p>
    ●ในญี่ปุ่นจะมีขนบธรรมเนียมและกฎระเบียบในการดำรงชีวิตที่แตกต่างกัน เช่น การเกรงใจบ้านใกล้เรือนเคียงหรือวิธีการแยกขยะ เป็นต้น ฉะนั้นให้ระวังรักษากฎระเบียบดังกล่าว
    </p>
    </li>
    </ul>
    </BlackNote>
    <InnerBox top={20} bottom={20}>
    <FukidashiRedLeft>
    ในลิงค์ด้านล่างนี้เป็นลิ้งค์แสดงคำศัพท์ที่มักพบเจอหรือได้ยินบ่อยตอนหาที่พักอาศัยและ “คู่มือแนะนำการหาที่พักอาศัย” ในภาษาต่างๆ ที่เป็นประโยชน์ต่อชาวต่างชาติในการค้นหาที่พักอาศัย
    </FukidashiRedLeft>
    <h1>
    โฮมเพจสำนักงานตรวจคนเข้าเมือง「คู่มือแนะนำการใช้ชีวิตและทำงานในญี่ปุ่น」
    </h1>
    <ul className="links">
    <li>
    ●ภาษาอังกฤษ：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392830.pdf</a>
    </li>
    <li>
    ●ภาษาจีน：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392831.pdf</a>
    </li>
    <li>
    ●ภาษาเกาหลี：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001393145.pdf</a>
    </li>
    <li>
    ●ภาษาเวียดนาม：<a href="https://www.moj.go.jp">https://www.moj.go.j/isa/content/001393146.pdfp</a>
    </li>
    <li>
    ●ภาษาไทย： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●ภาษาเนปาล： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●ภาษาญี่ปุ่นที่เข้าใจง่าย： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/930004715.pdf</a>
    </li>
    </ul>
    </InnerBox>
    <InnerBox bottom={20}>
    <h1>สมาคมการจัดการที่อยู่อาศัยแบบให้เช่าประเทศญี่ปุ่น “คำอธิบายเกี่ยวกับการช่วยเหลือเพื่ออำนวยความสะดวกในการเข้าอยู่อาศัยของชาวต่างชาติ”</h1>
    <p>
    <a href="https://www.jpm.jp">https://www.jpm.jp/foreign/</a>
    </p>
    </InnerBox>
    </>
    )
}

export default LangTh