
const LangKo = () => {

    return (<>
    <h1>FUKUOKA IS OPEN에 대하여</h1>
    <p>
    후쿠오카현은 우호 제휴 지역 수, 유학생 수 등에서 일본 전국 상위에 있으며,
    후쿠오카현 거주 외국인 수는 89,518명으로 사상 최고를 갱신했습니다.
    </p>
    <p>
    앞으로 인구 감소 사회 속에서 지역의 활력을 유지해 나가기 위해서는 후쿠오카현 내 기업의 일꾼 및 지역사회의 새로운 주역으로서 외국인 인재가 활약하도록 하는 것이 필수적입니다.
    </p>
    <p>
    이러한 가운데 더욱 많은 분이 해외에서 오실 수 있게 하기 위하여 ‘FUKUOKA IS OPEN’이라는 캐치프레이즈 하에 전 세계를 향해 후쿠오카현의 시책을 통합적으로 발신하여 후쿠오카현이 외국인에게 살기 좋고 외국인이 활약할 수 있는 지역임을 알리고자 합니다.
    </p>
    <p>
    이를 위해 다국어 포털 사이트 ‘FUKUOKA IS OPEN’(<a href="https://www.fisop.net">https://www.fisop.net</a>)에서는 후쿠오카현의 교육과 의료 등 외국인 대상 생활 관련 정보를 비롯해 유학생, 인바운드, 국제금융기능 유치와 스포츠, 환경, 원 헬스(One Health) 등 선진적 시책에 관한 정보, 거주 외국인의 활약 등 최신 정보를 발신합니다.
    </p>
    <br/>
    <h5 className="title-large">FUKUOKA IS OPEN 오리지널 로고 마크 사용에 대하여</h5>
    <p>본 로고 마크를 FUKUOKA IS OPEN 추진 사업 실행 위원회의 승낙 없이 사용하는 것은 금지합니다.<br/>
    사용시에는 사용 요령에 따라 사용 신청이 필요합니다.<br/>
    사용 요령 및 이용 가이드라인을 확인하시고,
    ‘(양식 제1호) FUKUOKA IS OPEN 추진 사업 로고 마크 사용 신청서’에 필요 사항을 기재하신 후 FAX로 제출해 주십시오.</p>
    <br/>
    <p>신청 내용 확인 후 승인서 및 로고 마크 데이터를 보내드립니다.</p>
    <br/>
    <p>
    【신청서류 등】
    </p>
    <ul className="dotto">
    <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOPromotionProjectLogoUsageInstructions.pdf" target="_blank">FUKUOKA IS OPEN 추진 사업 로고 마크 사용 요령</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form1_FIOPromotionProjectLogoApplicationForm.docx">(양식 제1호) FUKUOKA IS OPEN 추진 사업 로고 마크 사용 신청서</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form4_FIOPromotionProjectLogoApplicationForm.docx">(양식 제4호) FUKUOKA IS OPEN 추진 사업 로고 마크 사용 승인 변경 신청서</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form5_FIOPromotionProjectLogoUsageStatusReport.docx">(양식 제5호) FUKUOKA IS OPEN 추진 사업 로고 마크 사용 상황 보고서 </a></span></li>
    <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOLOGOUSERGUIDELINE.pdf" target="_blank">[FUKUOKA IS OPEN] LOGO USER GUIDELINE</a></span></li>
    </ul>
    <br/>
    <p>
    【문의처】<br/>
    FUKUOKA IS OPEN 추진 사업 실행 위원회 사무국(후쿠오카현 국제국 국제정책과 외국인재지원담당)
    </p>
    <br/>
    <p>
    TEL：<a href="tel:+81-92-643-3201">+81-92-643-3201</a><br/>
    FAX：<a href="tel:+81-92-643-3224">+81-92-643-3224</a><br/>
    8시 30분부터 17시 15분 (9시 00분부터 17시 45분)<br/>
    단, 토, 일, 공휴일 및 12월 29일부터 1월 3일 제외
    </p>
    
    </>)
}

export default LangKo