
const LangEn = () => {


    return (
        <>
            <h2>
                <img src="./images/virtual/fio_park.webp" alt="FUKUOKA IS OPEN PARK"/>
            </h2>
           
            <div className="virtual-inner">
                <p className="message al-l">
                    Experience the simulated streets, food, vehicles and more of Fukuoka Prefecture at the "FUKUOKA IS OPEN PARK", an urban-linked metaverse created based on actual spaces in Fukuoka.
                </p>
                <div className="fiopark">
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_01_title_en.webp" alt="FUKUOKA IS OPENパークの入口"/></dt>
                        <dd>
                        <p>Gather with friends, relax, chat and enjoy watching videos in the lush Tenjin Central Park in the Tenjin area, the heart of Fukuoka and Kyushu. You can go out to various worlds from here!</p>
                            <figure>
                                <img src="./images/virtual/fio_park_01_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_02_title_en.webp" alt="FUKUOKA フルーツパーク"/></dt>
                        <dd>
                            <p>Enjoy harvesting seasonal fruits and vegetables from Fukuoka Prefecture!</p>
                            <figure>
                                <img src="./images/virtual/fio_park_02_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    
                </div>
                <div className="fiopark-howto">
                    <div className="fiopark-howto-title en">
                    <h3>How to use<span>"FUKUOKA IS OPEN PARK"</span></h3>
                    </div>
                    <div className="fiopark-howto-entrance">
                        <div><img src="./images/virtual/entrance_en.webp" alt="FUKUOKA IS OPEN 参加する"/></div>
                        <div><p>You can join by clicking "Join".</p></div>
                    </div>
                    <div className="fiopark-howto-app">
                        <div>
                            <p>The app "cluster" must be downloaded in advance to your smartphone, tablet, PC, dedicated VR device, etc.</p>
                            
                            <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend pc" target="_blank">
                               <span> Click here for recommended environment</span>
                            </a>
                        </div>
                        <div>
                            <h4>If you already have the app,</h4>
                            <img src="./images/virtual/cluster01.webp" alt="clusterを起動" width={200}/>
                            <p>click "Start cluster" </p>
                            <img src="./images/virtual/cluster02.webp" alt="遊びに行く"/>
                            <p>and then click "Go play" to enter.</p> 
                        </div>
                        <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend sp" target="_blank">
                            <span> Click here for recommended environment </span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

}

export default LangEn