import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import InnerBox from "@/components/Atoms/Box/innerBox"

const LangZhCn = () => {

    return (<>
    <div className="pc-wapper">
        <InnerBox>
        <h1>
        Consulate
        </h1>
        </InnerBox>
        <BlackNote>
        <ul className="deco">
        <li>
        <p className="name">
        U.S. Consulate Fukuoka<br/> (Est. April 28, 1952)
        </p>
        <p className="address">
        810-0052 2-5-26 Ohori, Chuo-ku, Fukuoka City
        </p>
        <p className="tel">
        092-739-9088
        </p>
        </li>
        <li>
        <p className="name">
        Consulate General of South Korea, Fukuoka<br/>(Est. January 27, 1966)
        </p>
        <p className="address">
        810-0065 1-1-3 Jigyohama, Chuo-ku, Fukuoka
        </p>
        <p className="tel">
        092-771-0461
        </p>
        </li>
        <li>
        <p className="name">
        Consulate-General of The People's Republic of China in Fukuoka<br/>(Est. April 17, 1985)
        </p>
        <p className="address">
        810-0065 1-3-3 Jigyohama, Chuo-ku, Fukuoka City
        </p>
        <p className="tel">
        092-713-1121
        </p>
        </li>
        <li>
        <p className="name">
        Consulate-General of Vietnam in Fukuoka<br/>(Est. April 22, 2009)
        </p>
        <p className="address">
        810-0801 5-3-8, Nakasu, Hakata-ku, Fukuoka<br/>Aqua Hakata 4F
        </p>
        <p className="tel">
        092-263-7668
        </p>
        </li>
        <li>
        <p className="name">
        Royal Thai Consulate-General in Fukuoka<br/>( Est. October 1, 2018)
        </p>
        <p className="address">
        810-0001 4-1-37, Tenjin, Chuo-ku, Fukuoka-shi, Fukuoka <br/>2nd Floor Dai Ichi Myojo
        </p>
        <p className="tel">
        092-739-9088
        </p>
        </li>
        </ul>
        </BlackNote>
    </div>
    
    <div className="checkpostion01 sp-only">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>

    <div className="pc-list-wrap">
        <InnerBox bottom={20}>
        <h1>姐妹城市</h1>
        <EmphasisNote>
        <h2>
        夏威夷州（美国）<br/>1981.9.25
        </h2>
        <p>
        昭和55年9月，「博多祇园山笠」参加了阿罗哈威克庆典（Aloha Festivals），与此同时，「福冈物产展」也在举行，两县州之间的友好交流氛围高涨，在各自的议会上纷纷通过了推进姐妹关系缔结的决议。
        在此基础上，继续进行了为期一年的姐妹提携的讨论，昭和56年9月25日，夏威夷州州长有吉（Ariyoshi）和福冈县知事亀井于夏威夷州檀香山市进行了签约仪式。
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        江苏省（中国）<br/>1992.11.4
        </h2>
        <p>
        平成4年9月，值中共中央总书记・江泽民访问福冈县之际，该县知事表示在中日正常建交20周年的背景下，将在本年度内争取与中国建立友好的合作关系。基于此，成立了由政府、议会和民间组织组成的友好合作检讨委员会。在考察团的派遣和协商等结果下，决定与江苏省建立友好提携。<br/>
        平成4年9月，提出了建立友好合作关系的申请。同年11月4日，江苏省省长陈江与福冈县知事奥田在南京市举行了签署仪式。
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        曼谷都（泰国）<br/>2006.2.8
        </h2>
        <p>
        为了进一步扩大和深化与亚洲的关系，在亚洲的各地区中，我们决定以本县的主要产业——汽车产业为核心，与发展迅速的泰国进行交流的活动。在其中，决定选择政治、经济、文化的中心地——曼谷都作为候补地区。2005年10月，派遣了考察团在当地与曼谷市长李胜利（Aphirak）进行了面谈，在确认交流意向时，由于得到了积极回应，在2006年2月，由福冈县知事麻生担任团长组织访问团访问了曼谷都，并签署了友好合作协定书。
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        德里邦（印度）<br/>2007.3.5
        </h2>
        <p>
        为了进一步扩大和深化与亚洲的关系，在亚洲的各地区中，我们决定与以最前沿的IT技术以及汽车产业为中心发展迅速的印度进行交流的活动。在其中，决定选择政治、经济、文化的中心地——德里邦作为候补地区。2005年10月派遣考察团，2006年2月由福冈县知事麻生担任团长组织访问团访问了德里邦，与德里邦首席部长（Dikshit）进行了面谈，双方就友好交流问题积极交换了意见。在获得印度政府批准后，2006年12月，在日印首脑会谈上，关于欢迎福冈县与德里邦建立友好合作关系的表态被纳入了联合声明。随后，于2007年3月正式签署了友好合作协议。
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        河内市（越南）<br/>2008.2.22
        </h2>
        <p>
        为了进一步加深与亚洲的关系，在亚洲各地区中，我们决定与劳动力充足、国民勤劳等因素推动下取得显著成长的越南进行交流。在其中，决定选择政治、经济、文化的中心地——河内市作为候补地区。在2005年10月派遣了考察团后，在实务层面继续进行了谈判，于2008年2月，福冈县知事麻生担任团长组织访问团访问了河内市并签署了友好合作协议。
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        韩国南岸的一市三道与九州北部三县以及山口县<br/><br/>共同宣言文发表
        </h2>
        <p>
        日韩两国地缘相邻，自古素有"琴瑟和鸣、邻里交好"的邻里情谊。特别是日本的九州北部三县（福冈县、佐贺县、长崎县）与韩国南岸的一市三道（釜山广域市、全罗南道、庆尚南道、济州特别自治道），在日韩海峡两岸充当着重要的交流窗口，发挥着重要的作用。<br/>在这样的地理和历史背景下，1990年10月，九州北部三县知事座谈会在长崎县対馬举行，就促进两地区交流问题进行了热烈讨论。在此次座谈的基础上，与韩国方面进行了磋商。最终，于1992年7月，就两地区之间的交流现状和未来展望展开了坦率的意见交换。双方达成了共识，同意召开首脑交流会议，旨在促进各个领域的交流。会议正式命名为「日韩海峡沿岸县市道知事交流会议」。<br/>
        　"1992年8月，在韩国济州道举办了「第1回日韩海峡沿岸县市道知事交流会议」，发表了关于实施共同项目和定期举行知事交流会议以促进日韩海峡沿岸地区交流的共同宣言文。<br/>
        　从1999年度开始，山口县也参与了这一交流活动。<br/>
        　2008年度起，会议更名为「日韩海峡沿岸县市道知事交流会议」。"
        </p>
        </EmphasisNote>
        </InnerBox>
        </div>

        <InnerBox bottom={20}>
         <div className="pc-navigator">
            <NavigatorCheck dir="right"></NavigatorCheck>
            </div>
        </InnerBox>
        </>
    )
}

export default LangZhCn