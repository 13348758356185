import { NavigateCracter } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"

const LangVi = () => {

    return (<>
        <Article className="pc-noTopMargin">
            <h1>Thời cổ đại - Cửa ngõ giao lưu với châu Á－</h1>
            <p>
            Thời cổ đại, Fukuoka đã lập ra chính quyền Dazaifu gọi là Toonomikado và nhà khách tiếp đón đại sứ nước ngoài gọi là Korokan và trở thành cửa ngõ giao lưu của Nhật Bản với Trung Quốc đại lục và bán đảo Triều Tiên. Ngay cả vào thời Trung cổ, cảng Hakata đã phát triển mạnh mẽ như một trung tâm ngoại thương với Trung Quốc đại lục, bán đảo Triều Tiên, Ryukyus và biển Nam.
            </p>
            </Article>
            <div className="space">
            <div className="navigator right">
            <NavigateCracter type="smile"/>
            </div>
            </div>
            <Article>
            <h1>Thời kỳ Edo - Trọng điểm thương nghiệp</h1>
            <p>
            Vào thời kỳ Edo, sản xuất đồ thương mại như cây sơn và các sản phẩm thủ công như vải Hakata, vải Kurume vải Kokura rất phát triển.  Nhiều con đường, bến cảng và giao thông đường thủy qua sông Onga và Chikugo được phát triển khiến tỉnh trở thành trung tâm giao thông quan trọng ở Kyushu.
            </p>
            </Article>
            <div className="space">
            </div>
            <Article>
            <h1>Thời kỳ Meiji đến Showa - Sự thịnh vượng của ngành công nghiệp than và thép</h1>
            <p>
            Từ thời Meiji đến thời Showa, sản xuất than phát triển mạnh ở vùng Chikuho và vùng Omuta của Chikugo. Tận dụng lợi thế này ở phía bắc, khu công nghiệp Kitakyushu tập trung vào thép, máy móc, điện, hóa chất, gốm sứ...còn phía nam "Khu liên hợp hóa chất than'' được hình thành vàhỗ trợ quá trình hiện đại hóa và phát triển kinh tế của Nhật Bản.<br/>
            "Sau chiến tranh thế giới thứ II - Sự phục hồi của ngành công nghiệp
Sau chiến tranh thế giới thứ II, nhờ phương pháp sản xuất ưu tiên của chính phủ và chiến tranh Triều Tiên mà kinh tế được phục hồi. Ngành công nghiệp chế tạo kim loại, hóa chất và ngành công nghiệp than đá trở nên phồn thịnh giúp tỉnh Fukuoka nhanh chóng hồi phục từ tình trạng suy thoái."
            </p>
            </Article>
            <div className="space">
            </div>
            <Article>
            <h1>Thời kỳ tăng trưởng cao độ - Sự chuyển đổi cấu trúc công nghiệp do Cách mạng năng lượng</h1>
            <p>
            Trong thời kỳ tăng trưởng kinh tế cao bắt đầu từ năm 1955, sản xuất công nghiệp của tỉnh tăng lên đáng kể nhưng đồng thời cuộc cách mạng năng lượng đã dẫn đến sự suy giảm của ngành than và các vùng sản xuất than bị ảnh hưởng nặng nề.<br/>
            Từ sau 2 cú sốc dầu mỏ trong những năm 1970, kinh tế tỉnh Fukuoka đã phải đối mặt với thách thức nặng nề do tăng trưởng thấp, đồng yên cao và trong bối cảnh chuyển đổi ngành sản xuất. Do đó, tỉnh đã tích cực thúc đẩy phát triển các ngành công nghiệp tiên tiến như công nghiệp ô tô, bán dẫn tiên tiến, công nghệ sinh học và robot. Ngoài ra, ở trung tâm thành phố Fukuoka, ngành công nghiệp thứ ba là thương mại và dịch vụ đã phát triển mạnh mẽ.
            </p>
            </Article>
            <div className="space">
            <div className="navigator left">
            <NavigateCracter type="good"/>
            </div>
            </div>
            <Article>
            <h1>Hiện tại - Hướng đến là cửa ngõ giao lưu của Kyushu, Tây Nhật Bản và châu Á</h1>
            <p>
            Từ cuối những năm 1970 trở đi, việc xây dựng cơ sở hạ tầng giao thông phục vụ công nghiệp và đời sống hàng ngày đã đi vào chính thức. Sau khi khánh thành cầu Kanmon là “cây cầu treo dài nhất ở phía Đông” nối Honshu và Kyushu thu hút quan tâm vào thời điểm đó, vào những năm 1970 và 1980, giao thông trong khu vực được nâng cấp rất nhiều hoan thiện toàn tuyến Sanyo Shinkansen giữa Shin-Osaka và Hakata, khai thông giao lộ Tosu và Koga trên đường cao tốc Kyushu chạy từ bắc xuống nam ở Kyushu, mở tuyến tàu điện ngầm thành phố Fukuoka giữa Muromi và Tenjin, khai trương tuyến đường sắt trên không Kitakyushu...<br/>
            Ngoài ra, sân bay Shin Kitakyushu (nay là Sân bay Kitakyushu) được khai trương vào năm 2006, tuyến Kyushu Shinkansen giữa Hakata và Kagoshima Chuo đi vào sử dụng từ năm 2011, khai thông toàn tuyến đường cao tốc Higashikyushu giữa thành phố Kitakyushu và thành phố Miyazaki vào năm 2016.<br/>
            Bảo tàng Quốc gia Kyushu mở cửa vào năm 2005 với tư cách là bảo tàng quốc gia thứ tư ở Nhật Bản. Năm 2015, các cơ sở liên quan như mỏ than, công nghiệp thép và đóng tàu đã được Tổ chức Giáo dục, Khoa học và Văn hóa Liên Hợp Quốc (UNESCO) đăng ký là Di sản Văn hóa Thế giới với tên gọi "Di sản Cách mạng Công nghiệp Minh Trị của Nhật Bản: Sản xuất sắt thép , đóng tàu và công nghiệp than''. Vào năm 2017, “Đảo của các vị thần” Munakata/Okinoshima và quần thể di sản liên quan đã được đăng ký là Di sản Văn hóa Thế giới.<br/>
            Trong bối cảnh xã hội có những thay đổi lớn, để thúc đẩy giao lưu với châu Á và thế giớ, tỉnh Fukuoka đã vượt qua nhiều khó khăn khác nhau phát triển cơ sở hạ tầng giao thông quan trọng, thu hút các ngành công nghiệp mới và phát triển công nghệ vượt trội... Tỉnh đã phát triển về mọi mặt, bao gồm cả công nghiệp và văn hóa. Tới đây tỉnh sẽ có vai trò dẫn đầu ở Kyushu hỗ trợ sự phát triển trong tương lai của Nhật Bản.<br/>
            </p>
            </Article>
    </>)
}

export default LangVi