
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import { AreaMap } from "./areaMap"
const SSvg = styled.div`
      
`

const SOverride = styled.div`
@media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
(max-width: ${props => props.theme.responsive.minPc}) ),
screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
    .fukidashiPosition{
        &.kitakyu{
            top: 18% !important;
        }
        &.chikuhou{
            bottom: 46% !important;
        }
        &.chikugo{
            bottom: 42% !important;
        }
    }
}
`


const LangZhTw = ({onSwich, onFukidashi}) => {

    return (
        <SOverride>
            <div className="area-info">
            <SSvg>
            <figure className="map">
            <svg id="_レイヤー_1" data-name="レイヤー 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.92 286.92">
            <rect className="fukuoka" onClick={ () => { onSwich(4) }} x="11.44" y="58.23" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(97.1 140.13) rotate(180)"/>
            <g onClick={ () => { onSwich(4) }}>
                <path className="moji" d="M28.43,71.36c-.08.17-.27.39-.51.39s-.32-.1-.96-.93v4.6c0,.45-.18.63-.66.63-.59,0-.59-.46-.59-.63v-3.91c-.72.7-.89.75-1.09.75-.35,0-.61-.35-.61-.69,0-.29.04-.33.75-.92,1.48-1.25,2.11-2.6,2.11-2.78,0-.19-.22-.19-.33-.19h-1.78c-.44,0-.62-.18-.62-.62,0-.57.47-.57.62-.57h.95v-1.24c0-.49.22-.64.67-.64.5,0,.58.36.58.63v1.25h.25c.61,0,1,.29,1,.84,0,.73-.53,1.67-.9,2.23.28.38.59.72.72.85.36.36.4.41.45.55.16-.7.87-.72,1.16-.72h4.22c.86,0,1.23.32,1.23,1.23v3.13c0,.92-.38,1.23-1.23,1.23h-4.22c-.82,0-1.21-.29-1.21-1.23v-3.23ZM28.68,66.06c-.21,0-.63-.01-.63-.55,0-.42.25-.56.63-.56h6.24c.21,0,.62.02.62.56,0,.47-.33.55-.62.55h-6.24ZM34.77,68.56c0,.89-.27,1.1-1.15,1.1h-3.67c-.87,0-1.15-.21-1.15-1.1v-.82c0-.9.29-1.1,1.15-1.1h3.67c.85,0,1.15.19,1.15,1.1v.82ZM29.62,72.44h1.56v-1.25h-1.23c-.24,0-.34.12-.34.34v.91ZM31.19,74.75v-1.41h-1.56v1.07c0,.24.12.34.34.34h1.23ZM33.59,67.89c0-.23-.11-.34-.34-.34h-2.94c-.29,0-.34.17-.34.34v.48c0,.19.07.34.34.34h2.94c.22,0,.34-.1.34-.34v-.48ZM33.91,72.44v-.91c0-.22-.1-.34-.34-.34h-1.27v1.25h1.61ZM33.91,73.34h-1.61v1.41h1.27c.19,0,.34-.07.34-.34v-1.07Z"/>
                <path className="moji" d="M38.27,75.37c0,.18-.01.64-.61.64-.53,0-.63-.33-.63-.64v-8.71c0-1.24.52-1.59,1.6-1.59h7.28c1.12,0,1.59.39,1.59,1.59v7.75c0,1.53-.78,1.53-2.15,1.53-.67,0-.81-.01-.98-.15-.17-.13-.23-.32-.23-.53,0-.62.45-.62.81-.62.15,0,.78.02.91,0,.33-.06.41-.27.41-.62v-7.18c0-.36-.16-.62-.62-.62h-6.77c-.52,0-.62.32-.62.62v8.53ZM42.82,73.14h.9c.28,0,.42-.13.42-.44v-2.1c0-.16,0-.57.56-.57.45,0,.62.16.62.57v2.3c0,1.01-.4,1.35-1.35,1.35h-3.42c-.93,0-1.35-.33-1.35-1.35v-2.3c0-.16,0-.57.56-.57.51,0,.62.23.62.57v2.1c0,.22.07.44.42.44h.89v-3.69h-2.52c-.16,0-.56,0-.56-.55,0-.4.21-.54.53-.54h1.18c-.1-.3-.21-.58-.38-1.01-.07-.17-.11-.27-.11-.39,0-.34.42-.45.69-.45.36,0,.47.21.52.29.12.25.39,1.04.56,1.55h1.29c.13-.28.28-.63.55-1.37.07-.22.16-.47.59-.47.32,0,.68.16.68.49,0,.19-.38,1.03-.55,1.36h1.24c.17,0,.53.01.53.54s-.34.55-.57.55h-2.55v3.69Z"/>
                <path className="moji" d="M50.46,68.97h-.85c-.12,0-.59,0-.59-.59s.46-.61.59-.61h.85v-2.51c0-.18,0-.57.58-.57.52,0,.67.18.67.57v2.51h.62c.39,0,.52.24.52.57s-.08.63-.52.63h-.62v3.95c.18-.05.72-.23.83-.23.29,0,.45.25.45.62,0,.47-.18.56-1.43.97-.41.13-1.72.55-2.05.55-.36,0-.52-.41-.52-.72,0-.45.29-.53.5-.58.33-.09.66-.17.98-.25v-4.31ZM54.67,74.13c0,.49.33.49.68.49,1.12,0,3.24,0,3.35-.02.25-.05.3-.27.39-.88.07-.53.11-.83.61-.83.08,0,.65,0,.65.58,0,.16-.06,1-.25,1.49-.35.89-1.25.89-1.88.89h-3.19c-.85,0-1.58-.07-1.58-1.43v-4.45l-.5.15c-.1.02-.18.05-.27.05-.42,0-.46-.51-.46-.68,0-.42.21-.49.45-.56l.78-.23v-2.38c0-.17,0-.57.57-.57.5,0,.66.17.66.57v2l1.36-.4v-2.69c0-.17,0-.57.57-.57.49,0,.66.16.66.57v2.32l1.09-.33c.15-.05.39-.11.67-.11.97,0,.97.79.97,1.29,0,.73-.02,2.47-.16,3.11-.21,1.04-.8,1.12-1.43,1.12-.32,0-.87,0-.87-.72,0-.53.28-.53.68-.55.3-.01.41-.07.49-.44.05-.28.08-1.13.08-1.76,0-.49,0-.69-.27-.69-.04,0-.17.02-.27.05l-.98.3v4.16c0,.17,0,.58-.57.58-.46,0-.66-.15-.66-.58v-3.8l-1.36.41v4.55Z"/>
                <path className="moji" d="M61.85,66.41c0-1.06.44-1.36,1.35-1.36h8.94c.12,0,.58.01.58.55,0,.41-.19.58-.58.58h-8.56c-.3,0-.47.16-.47.47v7.46c0,.32.17.46.47.46h8.65c.17,0,.62,0,.62.57s-.44.58-.62.58h-9.03c-.91,0-1.35-.3-1.35-1.36v-7.96ZM67.68,72.77c0,.95-.22,1.19-1.18,1.19h-1.49c-.95,0-1.18-.23-1.18-1.19v-1.07c0-.93.22-1.19,1.18-1.19h1.49c.95,0,1.18.24,1.18,1.19v1.07ZM71.2,68.79c0,.89-.23,1.1-1.1,1.1h-4.21c-.86,0-1.09-.21-1.09-1.1v-.88c0-.9.23-1.1,1.09-1.1h4.21c.86,0,1.1.2,1.1,1.1v.88ZM66.66,71.81c0-.21-.1-.34-.34-.34h-1.13c-.23,0-.34.11-.34.34v.84c0,.19.08.34.34.34h1.13c.25,0,.34-.14.34-.34v-.84ZM70.01,68.1c0-.22-.11-.34-.34-.34h-3.37c-.22,0-.33.11-.33.34v.51c0,.23.11.34.33.34h3.37c.23,0,.34-.11.34-.34v-.51ZM72.29,72.77c0,.95-.22,1.19-1.18,1.19h-1.5c-.95,0-1.18-.23-1.18-1.19v-1.07c0-.93.22-1.19,1.18-1.19h1.5c.95,0,1.18.24,1.18,1.19v1.07ZM71.26,71.81c0-.22-.1-.34-.33-.34h-1.15c-.25,0-.33.15-.33.34v.84c0,.18.06.34.33.34h1.15c.22,0,.33-.12.33-.34v-.84Z"/>
            </g>
            <rect className="kitakyu" onClick={ () => { onSwich(1) }} x="189.13" y="10.6" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(452.46 44.87) rotate(180)"/>
            <g onClick={ () => { onSwich(1) }}>
                <path className="moji" d="M198.99,21.24h-2.72c-.16,0-.64,0-.64-.59,0-.51.25-.64.64-.64h2.72v-2.34c0-.19.01-.64.59-.64.49,0,.7.17.7.64v10.12c0,.17,0,.64-.61.64-.49,0-.69-.18-.69-.64v-1.08c-.57.21-2.51.87-2.83.87-.39,0-.57-.47-.57-.81,0-.45.17-.5.96-.69,1.18-.29,1.87-.52,2.44-.71v-4.11ZM203.1,26.46c0,.49.22.49,1.12.49,1.24,0,1.25,0,1.33-.45.05-.25.1-1.38.13-1.6.07-.35.4-.38.55-.38.68,0,.68.5.68.63,0,.27-.12,1.87-.28,2.34-.17.5-.51.68-1.06.7-.29.01-1.24.01-1.61.01-1.09,0-1.39,0-1.71-.21-.45-.28-.45-.86-.45-1.15v-9.2c0-.16,0-.64.61-.64.5,0,.69.18.69.64v3.15c1.16-.63,1.82-1.14,2.07-1.36.55-.45.66-.56.91-.56.41,0,.69.5.69.8,0,.24-.1.39-.86.93-1.25.87-2.33,1.41-2.81,1.65v4.18Z"/>
                <path className="moji" d="M208.73,20.46c-.18,0-.64,0-.64-.62,0-.57.35-.64.64-.64h2.98c0-.21.01-1.01.01-1.15,0-.56,0-.62.05-.74.12-.27.5-.29.62-.29.67,0,.67.39.67.65,0,.29-.02,1.23-.04,1.53h2.37c.95,0,1.43.3,1.43,1.42v5.94c0,.38.13.38.61.38.58,0,.67,0,.69-1.18.02-.89.02-.95.07-1.09.07-.27.34-.33.56-.33.64,0,.64.42.64.75,0,.62-.05,2.05-.32,2.57-.24.49-.75.53-1.69.53s-1.3,0-1.57-.25c-.24-.22-.3-.52-.3-.86v-6.13c0-.38-.17-.5-.5-.5h-2.06c-.15,2.33-.4,4.15-1.81,5.93-.69.87-1.81,1.84-2.37,1.84-.41,0-.68-.42-.68-.79,0-.28.16-.41.4-.57,2.84-1.81,3.02-4.39,3.14-6.42h-2.92Z"/>
                <path className="moji" d="M222.2,19.95c0,.46-.07,2.13-.45,3.49-.08.31-.19.69-.59.69-.22,0-.63-.13-.63-.55,0-.05,0-.14.05-.27.41-1.12.5-2.07.55-3.43.01-.21.1-.52.55-.52.53,0,.53.4.53.58ZM231.35,27.76c0,.16,0,.63-.58.63-.47,0-.66-.19-.66-.63v-4.56c-.1.35-.45.5-.68.5-.39,0-.47-.22-.74-.93-.17-.44-.64-1.42-.87-1.81-.19-.33-.27-.44-.33-.58v6.91c0,.16,0,.63-.58.63-.45,0-.64-.17-.64-.63v-3.78c-.23.27-.52.27-.57.27-.36,0-.46-.27-.56-.56-.38-1.12-.63-1.78-1.04-2.73v1.29c0,1.06-.13,3.97-1.69,5.87-.44.53-.64.64-.87.64-.35,0-.73-.32-.73-.68,0-.13.06-.28.18-.42.41-.46.86-1.04,1.01-1.31.69-1.33.88-2.92.88-4.21v-3.91c0-.19.01-.63.57-.63.42,0,.64.15.64.62v2.09c.13-.15.33-.22.52-.22.33,0,.42.18.75.92.25.57.67,1.52.9,2.2v-4.79c0-.16,0-.63.58-.63.46,0,.64.19.64.63v1.86c.21-.24.47-.24.52-.24.32,0,.42.15.87.95.29.51,1.09,1.93,1.23,2.51v-5.35c0-.24.04-.63.57-.63.44,0,.67.12.67.63v10.11Z"/>
                <path className="moji" d="M234.36,21.34h-.85c-.12,0-.59,0-.59-.59s.46-.61.59-.61h.85v-2.51c0-.18,0-.57.58-.57.52,0,.67.18.67.57v2.51h.62c.39,0,.52.24.52.57s-.08.63-.52.63h-.62v3.96c.18-.05.72-.23.83-.23.29,0,.45.25.45.62,0,.47-.18.56-1.43.97-.41.13-1.72.55-2.05.55-.36,0-.52-.41-.52-.72,0-.45.29-.53.5-.58.33-.08.66-.17.98-.25v-4.31ZM238.57,26.5c0,.48.33.48.68.48,1.12,0,3.24,0,3.35-.02.25-.05.3-.27.39-.89.07-.53.11-.83.61-.83.08,0,.66,0,.66.58,0,.16-.06,1-.25,1.49-.35.89-1.25.89-1.88.89h-3.19c-.85,0-1.58-.07-1.58-1.43v-4.45l-.5.15c-.1.02-.18.05-.27.05-.42,0-.46-.51-.46-.68,0-.42.21-.49.45-.56l.78-.23v-2.38c0-.17,0-.57.57-.57.5,0,.65.17.65.57v2l1.36-.4v-2.69c0-.17,0-.57.57-.57.49,0,.66.16.66.57v2.32l1.09-.33c.15-.05.39-.11.67-.11.97,0,.97.79.97,1.29,0,.73-.02,2.47-.16,3.11-.21,1.04-.8,1.12-1.43,1.12-.32,0-.87,0-.87-.71,0-.53.28-.53.68-.55.3-.01.41-.07.49-.44.05-.28.08-1.13.08-1.76,0-.48,0-.69-.27-.69-.04,0-.17.02-.27.05l-.98.3v4.16c0,.17,0,.58-.57.58-.46,0-.65-.15-.65-.58v-3.8l-1.36.41v4.55Z"/>
                <path className="moji" d="M245.75,18.79c0-1.06.44-1.36,1.35-1.36h8.94c.12,0,.58.01.58.55,0,.41-.19.58-.58.58h-8.56c-.3,0-.47.16-.47.47v7.46c0,.32.17.46.47.46h8.65c.17,0,.62,0,.62.57s-.44.58-.62.58h-9.02c-.91,0-1.35-.3-1.35-1.36v-7.96ZM251.58,25.14c0,.95-.22,1.19-1.18,1.19h-1.49c-.95,0-1.18-.23-1.18-1.19v-1.07c0-.93.22-1.19,1.18-1.19h1.49c.95,0,1.18.24,1.18,1.19v1.07ZM255.1,21.16c0,.89-.23,1.1-1.1,1.1h-4.21c-.86,0-1.09-.21-1.09-1.1v-.89c0-.9.23-1.1,1.09-1.1h4.21c.86,0,1.1.21,1.1,1.1v.89ZM250.56,24.18c0-.21-.1-.34-.34-.34h-1.13c-.23,0-.34.11-.34.34v.84c0,.19.08.34.34.34h1.13c.25,0,.34-.15.34-.34v-.84ZM253.91,20.47c0-.22-.11-.34-.34-.34h-3.37c-.22,0-.33.11-.33.34v.51c0,.23.11.34.33.34h3.37c.23,0,.34-.11.34-.34v-.51ZM256.19,25.14c0,.95-.22,1.19-1.18,1.19h-1.5c-.95,0-1.18-.23-1.18-1.19v-1.07c0-.93.22-1.19,1.18-1.19h1.5c.95,0,1.18.24,1.18,1.19v1.07ZM255.16,24.18c0-.22-.1-.34-.33-.34h-1.15c-.25,0-.33.15-.33.34v.84c0,.18.06.34.33.34h1.15c.22,0,.33-.12.33-.34v-.84Z"/>
            </g>
           
            <rect className="chikugo" onClick={ () => { onSwich(2) }} x="11.44" y="185.61" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(97.1 394.9) rotate(180)"/>
            <g onClick={ () => { onSwich(2) }}>
                <path className="moji" d="M30.98,194.08c-.23.32-.61.74-.9,1.01-.12.11-.23.16-.4.16-.36,0-.66-.33-.66-.62,0-.18.08-.28.34-.56-.08.01-.12.01-.22.01h-1.27c.06.17.28.75.28.91,0,.42-.38.52-.68.52-.39,0-.46-.19-.52-.42-.06-.22-.18-.62-.3-1.01h-.47c-.38.51-.73.85-.86.97-.21.19-.39.35-.62.35-.32,0-.64-.32-.64-.67,0-.21.02-.23.61-.79.06-.06.66-.73.96-1.24.29-.47.42-.69.81-.69.36,0,.65.23.65.5,0,.13-.06.24-.18.47h2.24c.21,0,.66.04.55.7.35-.38.45-.54.89-1.26.17-.29.24-.41.56-.41.23,0,.72.12.72.52,0,.13-.06.28-.16.45h3.28c.16,0,.57,0,.57.55s-.38.55-.57.55h-1.99c.11.22.4.81.4.99,0,.44-.61.46-.72.46-.35,0-.46-.16-.53-.3-.08-.17-.35-.98-.41-1.15h-.74ZM25.84,197.16h-.89c-.15,0-.62,0-.62-.57,0-.47.22-.63.63-.63h3.19c.19,0,.63.01.63.59,0,.52-.31.61-.63.61h-1.09v3.13c.4-.1,1.07-.28,1.21-.28.39,0,.5.35.5.59,0,.38-.11.46-1.15.78-.86.27-2.58.75-2.97.75-.53,0-.53-.64-.53-.7,0-.3.11-.46.38-.53.21-.06,1.15-.25,1.35-.3v-3.43ZM32.64,195.74c.88,0,1.22.33,1.22,1.21v4.71c0,.25.08.33.34.33.16,0,.28,0,.34-.1.07-.12.1-1.26.1-1.46,0-.24,0-.52.41-.52.45,0,.45.28.45.62,0,.49-.04,1.94-.22,2.33-.18.39-.45.44-1.01.44-.86,0-1.6,0-1.6-1.22v-4.77c0-.19-.07-.39-.39-.39h-1.56c-.29,0-.39.16-.39.39v1.84c0,1.54-.44,2.72-1.3,3.6-.22.22-.78.7-1.14.7s-.67-.32-.67-.64c0-.23.02-.25.81-.89,1.1-.89,1.1-2.32,1.1-2.8v-2.17c0-.91.38-1.21,1.23-1.21h2.27ZM31.64,198.48c.28.39,1.03,1.46,1.03,1.8,0,.36-.39.54-.61.54-.34,0-.48-.28-.65-.61-.12-.23-.22-.36-.86-1.35-.11-.16-.19-.28-.19-.44,0-.27.3-.51.66-.51.19,0,.29.1.63.56Z"/>
                <path className="moji" d="M37.91,198.5c-.45.42-.62.56-.84.56-.32,0-.57-.34-.57-.73q0-.29.85-1.08c.41-.38,1.06-1.18,1.29-1.55.62-1.03.64-1.07.92-1.07s.62.23.62.61c0,.41-.74,1.42-1.07,1.87v5.76c0,.38-.13.57-.63.57-.57,0-.57-.4-.57-.57v-4.37ZM39.82,192.67c0,.15,0,.23-.52.9-.57.73-1.67,1.89-2.13,1.89-.39,0-.62-.4-.62-.72,0-.29.05-.34.62-.78.74-.57,1.15-1.1,1.56-1.65.21-.27.41-.27.5-.27.23,0,.59.24.59.62ZM45.55,198.44c.35,0,1.08,0,1.08.78,0,.97-1.7,2.17-1.86,2.28.58.23,1.37.49,2.58.68.25.04.58.09.58.53,0,.06-.01.69-.57.69-.42,0-2.13-.4-3.76-1.19-1.43.75-3.09,1.12-3.55,1.12s-.56-.46-.56-.64c0-.46.34-.52.57-.56.57-.07,1.55-.28,2.4-.63-.5-.38-.93-.84-1.32-1.33-.28.23-.85.69-1.13.69s-.56-.28-.56-.64c0-.23.11-.33.35-.49,1.09-.69,1.76-1.41,2.11-1.93-.62.04-1.15.06-1.72.06-.16,0-.61,0-.61-.58,0-.24.1-.49.42-.52.02,0,1.44-.01,1.71-.01.34-.34.47-.49.62-.64-.55-.5-1.09-.88-1.44-1.13-.73-.51-.74-.51-.74-.78,0-.3.25-.63.59-.63.1,0,.18,0,.59.28.34-.44.49-.66.81-1.19.36-.58.41-.64.68-.64.33,0,.67.23.67.57,0,.18-.07.3-.38.73-.38.52-.51.72-.89,1.18.17.15.56.44.89.74.66-.76,1.13-1.39,1.83-2.33.17-.23.29-.3.47-.3.32,0,.7.27.7.63,0,.19-.08.33-.4.7-.4.5-1.95,2.29-2.41,2.77.93-.04,1.02-.05,2.55-.17-.02-.04-.23-.34-.35-.52-.1-.15-.21-.3-.21-.47,0-.29.33-.53.64-.53.16,0,.3.02.91.89.34.47,1.02,1.44,1.02,1.86,0,.33-.34.59-.7.59-.33,0-.34-.05-.75-.85-.58.07-.74.09-3.14.24-.15.25-.27.45-.47.72h2.73ZM41.93,199.46c1.1,1.17,1.4,1.33,1.69,1.49.41-.24.91-.57,1.26-.98.11-.12.25-.29.25-.39,0-.15-.16-.15-.32-.15h-2.86l-.02.02Z"/>
                <path className="moji" d="M50.46,196.36h-.85c-.12,0-.59,0-.59-.59s.46-.61.59-.61h.85v-2.51c0-.18,0-.57.58-.57.52,0,.67.18.67.57v2.51h.62c.39,0,.52.24.52.57s-.08.63-.52.63h-.62v3.96c.18-.05.72-.23.83-.23.29,0,.45.25.45.62,0,.47-.18.56-1.43.97-.41.13-1.72.55-2.05.55-.36,0-.52-.41-.52-.72,0-.45.29-.53.5-.58.33-.08.66-.17.98-.25v-4.31ZM54.67,201.51c0,.49.33.49.68.49,1.12,0,3.24,0,3.35-.02.25-.05.3-.27.39-.88.07-.53.11-.83.61-.83.08,0,.65,0,.65.58,0,.16-.06,1-.25,1.49-.35.89-1.25.89-1.88.89h-3.19c-.85,0-1.58-.07-1.58-1.43v-4.45l-.5.15c-.1.02-.18.05-.27.05-.42,0-.46-.51-.46-.68,0-.42.21-.48.45-.56l.78-.23v-2.38c0-.17,0-.57.57-.57.5,0,.66.17.66.57v2l1.36-.4v-2.69c0-.17,0-.57.57-.57.49,0,.66.16.66.57v2.32l1.09-.33c.15-.05.39-.11.67-.11.97,0,.97.79.97,1.29,0,.73-.02,2.47-.16,3.11-.21,1.04-.8,1.12-1.43,1.12-.32,0-.87,0-.87-.72,0-.53.28-.53.68-.54.3-.01.41-.07.49-.44.05-.28.08-1.13.08-1.76,0-.49,0-.69-.27-.69-.04,0-.17.02-.27.05l-.98.3v4.16c0,.17,0,.58-.57.58-.46,0-.66-.15-.66-.58v-3.8l-1.36.41v4.55Z"/>
                <path className="moji" d="M61.85,193.8c0-1.05.44-1.36,1.35-1.36h8.94c.12,0,.58.01.58.55,0,.41-.19.58-.58.58h-8.56c-.3,0-.47.16-.47.47v7.46c0,.32.17.46.47.46h8.65c.17,0,.62,0,.62.57s-.44.58-.62.58h-9.03c-.91,0-1.35-.3-1.35-1.36v-7.96ZM67.68,200.15c0,.95-.22,1.19-1.18,1.19h-1.49c-.95,0-1.18-.23-1.18-1.19v-1.07c0-.93.22-1.19,1.18-1.19h1.49c.95,0,1.18.24,1.18,1.19v1.07ZM71.2,196.17c0,.89-.23,1.1-1.1,1.1h-4.21c-.86,0-1.09-.21-1.09-1.1v-.89c0-.9.23-1.1,1.09-1.1h4.21c.86,0,1.1.21,1.1,1.1v.89ZM66.66,199.2c0-.21-.1-.34-.34-.34h-1.13c-.23,0-.34.11-.34.34v.84c0,.19.08.34.34.34h1.13c.25,0,.34-.15.34-.34v-.84ZM70.01,195.48c0-.22-.11-.34-.34-.34h-3.37c-.22,0-.33.11-.33.34v.51c0,.23.11.34.33.34h3.37c.23,0,.34-.11.34-.34v-.51ZM72.29,200.15c0,.95-.22,1.19-1.18,1.19h-1.5c-.95,0-1.18-.23-1.18-1.19v-1.07c0-.93.22-1.19,1.18-1.19h1.5c.95,0,1.18.24,1.18,1.19v1.07ZM71.26,199.2c0-.22-.1-.34-.33-.34h-1.15c-.25,0-.33.15-.33.34v.84c0,.18.06.34.33.34h1.15c.22,0,.33-.12.33-.34v-.84Z"/>
            </g>
            <AreaMap onSwich={onSwich}/>
            <rect className="chikuhou" onClick={ () => { onSwich(3) }} x="203.28" y="173.27" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(480.77 370.22) rotate(180)"/>
            <g onClick={ () => { onSwich(3) }}>
                <path className="moji" d="M222.82,181.73c-.23.32-.61.74-.9,1.01-.12.11-.23.16-.4.16-.36,0-.66-.33-.66-.62,0-.18.08-.28.34-.56-.08.01-.12.01-.22.01h-1.27c.06.17.28.75.28.91,0,.42-.38.52-.68.52-.39,0-.46-.19-.52-.42-.06-.22-.18-.62-.3-1.01h-.47c-.38.51-.73.85-.86.97-.21.19-.39.35-.62.35-.32,0-.64-.31-.64-.67,0-.21.02-.23.61-.79.06-.06.65-.73.96-1.24.29-.47.42-.69.81-.69.36,0,.66.23.66.5,0,.13-.06.24-.18.47h2.24c.21,0,.65.04.55.7.35-.38.45-.55.88-1.26.17-.29.24-.41.56-.41.23,0,.72.12.72.52,0,.13-.06.28-.16.45h3.28c.16,0,.57,0,.57.55s-.38.54-.57.54h-1.99c.11.22.4.81.4,1,0,.44-.61.46-.72.46-.35,0-.46-.16-.53-.3-.08-.17-.35-.98-.41-1.15h-.74ZM217.67,184.82h-.88c-.15,0-.62,0-.62-.57,0-.47.22-.63.63-.63h3.19c.19,0,.63.01.63.59,0,.52-.32.61-.63.61h-1.09v3.13c.4-.1,1.07-.28,1.21-.28.39,0,.5.35.5.59,0,.38-.11.46-1.15.78-.86.27-2.58.75-2.97.75-.53,0-.53-.64-.53-.7,0-.3.11-.46.38-.53.21-.06,1.15-.26,1.35-.3v-3.43ZM224.48,183.4c.89,0,1.23.33,1.23,1.21v4.71c0,.25.08.33.34.33.16,0,.28,0,.34-.1.07-.12.1-1.26.1-1.46,0-.24,0-.52.41-.52.45,0,.45.28.45.62,0,.49-.04,1.94-.22,2.33-.18.39-.45.44-1.01.44-.86,0-1.6,0-1.6-1.23v-4.77c0-.19-.07-.39-.39-.39h-1.57c-.29,0-.39.16-.39.39v1.84c0,1.54-.44,2.72-1.3,3.6-.22.22-.78.7-1.14.7s-.67-.31-.67-.64c0-.23.02-.25.81-.89,1.1-.89,1.1-2.32,1.1-2.8v-2.17c0-.91.38-1.21,1.22-1.21h2.27ZM223.47,186.14c.28.39,1.03,1.46,1.03,1.79,0,.36-.39.55-.61.55-.34,0-.49-.28-.66-.61-.12-.23-.22-.36-.86-1.35-.11-.16-.19-.28-.19-.44,0-.27.3-.51.66-.51.19,0,.29.1.63.56Z"/>
                <path className="moji" d="M239.25,189.64c.16,0,.56,0,.56.51s-.38.52-.56.52h-10.29c-.15,0-.56,0-.56-.51s.4-.52.56-.52h2.47c-.04-.11-.21-.62-.23-.73-.04-.11-.02-.15-.02-.21h-.5c-.76,0-1.08-.24-1.08-1.06v-.28c0-.82.32-1.07,1.08-1.07h6.85c.74,0,1.08.23,1.08,1.07v.28c0,.79-.3,1.06-1.08,1.06h-.53s0,.13-.04.24c-.05.16-.11.31-.27.69h2.55ZM228.98,185.79c-.13,0-.55,0-.55-.49s.38-.48.55-.48h10.26c.12,0,.55,0,.55.48s-.41.49-.55.49h-10.26ZM237.76,180.45c1.02,0,1.26.25,1.26,1.26v1.3c0,.98-.23,1.25-1.26,1.25h-7.27c-1,0-1.25-.24-1.25-1.25v-1.3c0-1.01.24-1.26,1.25-1.26h1.6v-.28c0-.21.02-.49.57-.49s.61.22.61.49v.28h1.66v-.28c0-.19.04-.49.56-.49.41,0,.62.09.62.49v.28h1.65ZM232.12,181.99v-.7h-1.38c-.22,0-.34.11-.34.34v.36h1.72ZM232.12,182.71h-1.72v.41c0,.19.1.34.34.34h1.38v-.75ZM237.35,187.44c0-.17-.08-.27-.27-.27h-5.96c-.18,0-.25.1-.25.27v.19c0,.17.07.27.27.27h5.94c.18,0,.27-.1.27-.27v-.19ZM235.47,189.64c.1-.28.21-.62.32-.93h-3.35s.25.66.29.93h2.74ZM234.93,181.29h-1.67v.7h1.67v-.7ZM234.93,182.71h-1.67v.75h1.67v-.75ZM236.07,181.99h1.78v-.36c0-.21-.1-.34-.34-.34h-1.44v.7ZM236.07,182.71v.75h1.44c.28,0,.34-.18.34-.34v-.41h-1.78Z"/>
                <path className="moji" d="M242.3,184.02h-.85c-.12,0-.59,0-.59-.59s.46-.61.59-.61h.85v-2.51c0-.18,0-.57.58-.57.52,0,.67.18.67.57v2.51h.62c.39,0,.52.24.52.57s-.08.63-.52.63h-.62v3.95c.18-.05.72-.23.83-.23.29,0,.45.25.45.62,0,.47-.18.56-1.43.97-.41.13-1.72.55-2.05.55-.36,0-.52-.41-.52-.72,0-.45.29-.53.5-.58.33-.09.66-.17.98-.25v-4.31ZM246.5,189.17c0,.49.33.49.68.49,1.12,0,3.24,0,3.35-.02.25-.05.3-.27.39-.89.07-.53.11-.82.61-.82.08,0,.66,0,.66.58,0,.16-.06.99-.25,1.49-.35.88-1.25.88-1.88.88h-3.19c-.85,0-1.58-.07-1.58-1.43v-4.45l-.5.15c-.1.02-.18.05-.27.05-.42,0-.46-.51-.46-.68,0-.42.21-.49.45-.56l.78-.23v-2.38c0-.17,0-.57.57-.57.5,0,.65.17.65.57v2l1.36-.4v-2.69c0-.17,0-.57.57-.57.49,0,.66.16.66.57v2.32l1.09-.33c.15-.05.39-.11.67-.11.97,0,.97.79.97,1.29,0,.73-.02,2.47-.16,3.11-.21,1.04-.8,1.12-1.43,1.12-.32,0-.87,0-.87-.72,0-.53.28-.53.68-.55.3-.01.41-.07.49-.44.05-.28.08-1.13.08-1.76,0-.49,0-.69-.27-.69-.04,0-.17.02-.27.05l-.98.3v4.16c0,.17,0,.58-.57.58-.46,0-.65-.15-.65-.58v-3.8l-1.36.41v4.55Z"/>
                <path className="moji" d="M253.68,181.46c0-1.06.44-1.36,1.35-1.36h8.94c.12,0,.58.01.58.54,0,.41-.19.58-.58.58h-8.56c-.3,0-.47.16-.47.47v7.46c0,.32.17.46.47.46h8.65c.17,0,.62,0,.62.57s-.44.58-.62.58h-9.02c-.91,0-1.35-.3-1.35-1.36v-7.96ZM259.52,187.81c0,.95-.22,1.19-1.18,1.19h-1.49c-.95,0-1.18-.23-1.18-1.19v-1.07c0-.93.22-1.19,1.18-1.19h1.49c.95,0,1.18.24,1.18,1.19v1.07ZM263.04,183.83c0,.89-.23,1.1-1.1,1.1h-4.21c-.86,0-1.09-.21-1.09-1.1v-.89c0-.9.23-1.1,1.09-1.1h4.21c.86,0,1.1.21,1.1,1.1v.89ZM258.5,186.85c0-.21-.1-.34-.34-.34h-1.13c-.23,0-.34.11-.34.34v.84c0,.19.08.34.34.34h1.13c.25,0,.34-.15.34-.34v-.84ZM261.85,183.14c0-.22-.11-.34-.34-.34h-3.37c-.22,0-.33.11-.33.34v.51c0,.23.11.34.33.34h3.37c.23,0,.34-.11.34-.34v-.51ZM264.13,187.81c0,.95-.22,1.19-1.18,1.19h-1.5c-.95,0-1.18-.23-1.18-1.19v-1.07c0-.93.22-1.19,1.18-1.19h1.5c.95,0,1.18.24,1.18,1.19v1.07ZM263.1,186.85c0-.22-.1-.34-.33-.34h-1.15c-.25,0-.33.15-.33.34v.84c0,.18.06.34.33.34h1.15c.22,0,.33-.12.33-.34v-.84Z"/>
            </g>
            </svg>
            </figure>
            </SSvg>
                <div className={`fukidashiPosition kitakyu ${onFukidashi === 1 ? 'active' : ''}`}>
                    <div className="fukidashi kitakyu">
                        <figure>
                        <img src={publicPath('images/basicinfo_area/zhtw/kitakyu.svg')} className="fit-img"
                         alt={`鋼鐵、汽車、尖端半導體、機器人等產業不斷進步，企圖利用累積的「產品製造技術」來振興當地激活地區塑造新的活力。 24小時營運的北九州機場，正在鞏固為亞洲的物流基地。`} />
                        </figure>
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition fukuoka ${onFukidashi === 4 ? 'active' : ''}`}>
                    <div className="fukidashi fukuoka">
                        <figure>
                        <img src={publicPath('images/basicinfo_area/zhtw/fukuoka.svg')} className="fit-img"
                        alt={`九州的中央行政管理機能和第三產業的日益集中，今已發展成為日本西部的領先地區。九州新幹線、福岡機場、博多港等各樣交通基礎設施不斷地興建發展。`} />
                        </figure>
                        
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition chikugo ${onFukidashi === 2 ? 'active' : ''}`}>
                    <div className="fukidashi chikugo">
                    
                    <figure>
                    <img src={publicPath('images/basicinfo_area/zhtw/chikugo.svg')} className="fit-img"
                        alt={`福岡除了擁有豐富的天然農林漁業產品、地方產業、工商業等多樣性的產業與文化，此外縣內有許多兼具特色的地方城市等，是個充滿魅力的地域。目前正在推進筑後網絡田園都市計畫的構想，讓各個充滿豐富個性的城市彼此連結合作，且能在保有其機能性下發展成長。`} />
                    </figure>
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition chikuhou ${onFukidashi === 3 ? 'active' : ''}`}>
                    <div className="fukidashi chikuhou">
                    <figure>
                        <img src={publicPath('images/basicinfo_area/zhtw/chikuho.svg')} className="fit-img"
                        alt={`汽車產業取代過往的石炭產業成為新興產業，日益發展向全國轉移，積極致力於培育汽車產業的人才與其人力資源開發。此外，也致力於藉結合以理工科系大學為中心的新創企業和研究機構來創建新產業基地。`} />
                        </figure>
                    
                        <span className="speak"></span>
                    </div>
                </div>
            </div>
        </SOverride>
    )
}

export default LangZhTw