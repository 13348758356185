
import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlueLeft } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { NavigatorFukidashiCheck } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"


const LangNe = () => {

    return (<>
    <InnerBox top={0} bottom={30} >
    <h1>सार्वजनिक हित समावेशी फाउन्डेसन फुकुओका प्रिफेक्चर इन्टरनेशनल एक्सचेन्ज सेन्टरको बारेमा</h1>
    <p>
    सार्वजनिक हित समावेशी फाउन्डेसन फुकुओका प्रिफेक्चर इन्टरनेशनल एक्सचेन्ज सेन्टर、फुकुओका प्रान्तको भौगोलिक विशेषताहरू、ऐतिहासिक विशेषताहरूको सदुपयोग गर्दै、प्रान्तको एक्सचेन्ज समूह आदि र सहयोग गरि प्रदेश सरकारको इन्टरनेशनल एक्सचेन्ज प्रवर्द्धन गर्न、इन्टरनेशनल एक्सचेन्जमा फुकुओका प्रान्तको भूमिका बढाउने अतिरिक्त、एसियाली देशहरु・क्षेत्रहरू र विश्वभरका अन्य देशहरू･स्थानीय समुदाय संग गहिरो अन्तरक्रिया、आपसी समृद्धि र विश्व शान्तिको लागि योगदान गर्ने उद्देश्यले、१९८९मा स्थापित भएको थियो।    </p>
    <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
    <InnerBox top={20} bottom={20} left={0} right={0}>
    <EmphasisNote>

    <p>
    प्रान्तका बासिन्दाहरु र विदेशी बासिन्दाहरू बीचको अन्तरक्रियाको लागि、व्याख्यान、सेमिनारहरू जस्ता विभिन्न कार्यक्रमहरू आयोजना गर्नुका साथै、विदेशी बासिन्दाहरूको लागि समस्याहरू जापानी भाषा सहित २२ भाषाहरूमा समाधान「फुकुओका प्रान्त विदेशी परामर्श केन्द्र」को व्यवस्थापन र स्वयंसेवक खोज्ने、प्रकोप सम्बन्धित जानकारी आदि जीवन सम्बन्धित जानकारी पनी प्रदान गर्दछौं।
    </p>
    <p>
    विदेशी आदानप्रदानको सन्दर्भमा、फुकुओका प्रान्त संग मित्रता वा अन्य गठबन्धन भएको हवाई प्रान्त (संयुक्त राज्य अमेरिका)、जियाङ्सु प्रान्त (चीन)、बैंकक (थाइल्याण्ड) आदि सँगको आदानप्रदानहरूलाई बढावा दिइरहेका छौं।
    </p>
    <p>
    थप रूपमा, प्रान्त भित्रका विद्यालय र सामुदायिक केन्द्रहरूमा विदेशी बासिन्दाहरूलाई प्रशिक्षकका रूपमा पठाएर अन्तर्राष्ट्रिय समझदारी शिक्षा कार्यान्वयन गर्नुका साथै、निजि इन्टरनेशनल एक्सचेन्ज समूहले सञ्चालन गर्ने कुसानोने एक्सचेन्जलाई सहयोग गर्ने तथा、स्थानीय जापानी भाषा कक्षाहरूको लागि सहयोग पनी गर्दै आएको छ।
    </p>
    <p>
    यसबाहेक、उत्पादन・अध्ययन・सरकार मा स्थापित रहेको फुकुओका प्रिफेक्चर इन्टरनेशनल स्टुडेन्ट सपोर्ट सेन्टरको सचिवालयको रूपमा、अन्तर्राष्ट्रिय विद्यार्थीहरूलाई व्यापक सहयोग प्रदान गर्छौं।
    </p>
    </EmphasisNote>
    </InnerBox>
    <p>
    【सम्पर्क जानकारी】<br/>
    〒८१०-०००१ <br/>
    फुकुओका प्रान्त फुकुओका शहर च्युओ वार्ड तेन्जिन १-१-१ अकरोस फुकुओका ८तला／३ तला<br/>
    ८ तला फोन：०९२-७२५-९२०४ FआX:०९२-७२५-९२०५ ८:30~१७:४५ （शनिबार, आइतबार र बिदा बाहेक)<br/>
    ३ तला(कोकुसाई हिरोबा) फोन :०९२-७२५-९२०० फ्याक्स :०९२-७२५-९२०६ १०:००~१८:३० (वर्षको अन्त्य र नयाँ वर्षको बिदा बाहेक) <br/>
    <br/>
    </p>
    <div className="custom-link">
    <FukidashiRedLeft>सार्वजनिक हित समावेशी फाउन्डेसन फुकुओका प्रिफेक्चर इन्टरनेशनल एक्सचेन्ज सेन्टरको बारेमा</FukidashiRedLeft>

    <a href="https://kokusaihiroba.or.jp/" target="_blank">
    <img src={publicPath('images/fief.png')} className="img-fit" alt="सार्वजनिक हित समावेशी फाउन्डेसन फुकुओका प्रिफेक्चर इन्टरनेशनल एक्सचेन्ज सेन्टरको बारेमा"/>
    </a>
    </div>

    </InnerBox>
    <InnerBox top={20} bottom={20}>
    <h1>
    फुकुओका प्रान्त विदेशी परामर्श केन्द्र
    </h1>
    <div className="sp-only">
    <NavigatorFukidashiCheck dir="left">
    <span className="txt-yellow">
    फुकुओका मा जीबनयापन गर्दा दुख पाएका कुरा छैनन？ <br/>
    विदेशी परामर्श केन्द्रमा धेरै भाषाहरूमा तपाईंको दुख चिन्ता समाधान हुन्छ।
    </span>
    </NavigatorFukidashiCheck>
    </div>

    <br/>
    <p>
    विदेशीहरूको जिबन यापन सहयोगमा 「फुकुओका प्रान्त बिदेशी परामर्श केन्द्र 」ले रोजगारी, श्रम,चिकित्सा,आवास, शिक्षा, आदि को जीबनयापनमा पर्ने समस्या तथा आप्रवासन प्रक्रिया 、कानुनी सल्लाह आदिको विभिन्न परामर्शहरु २२ भाषामा उपलब्द छ।
    </p>
    
    <div className="pc-only">
        <div className="pc-flex col-2">
            <div>
                <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
            </div>
            <div className="center">
                <FukidashiBlueLeft>
                    <span className="txt-yellow">
                    विदेशीहरूको जिबन यापन सहयोगमा 「फुकुओका प्रान्त बिदेशी परामर्श केन्द्र 」ले रोजगारी, श्रम,चिकित्सा,आवास, शिक्षा, आदि को जीबनयापनमा पर्ने समस्या तथा आप्रवासन प्रक्रिया 、कानुनी सल्लाह आदिको विभिन्न परामर्शहरु २२ भाषामा उपलब्द छ।
                    </span>
                </FukidashiBlueLeft>
            </div>
        </div>
    </div>

    <br/>
    
    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi navigatorCheck right pointing">
            जान्न चाहेका कुरा तथा कुनै समस्या भएमा,
            नहिचकिचाइ फोन गर्नु अथवा हाम्रो काउन्टरमा आउनु होला। विभिन्न भाषाहरूमा परामर्श गर्न सक्नुहुन्छ। (नि:शुल्क)
            </div>
        </div>
        <div>
            <figure>
                <img src={publicPath('images/international01.png')} className="img-fit" alt=""/>
            </figure>
            <figure className="pc-only">
                <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
            </figure>
        </div>
    </div>
    
    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi challengerQ left thinking">
            जापानमा बसाईको अवधि बढाउन चाहन्छु। के गर्नुपर्छ?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right puzzled">
            आफ्नै देशको भाषा उपलब्द(मद्दत) हुने अस्पताल छन्?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            घरधनीसँग समस्या परेको छ। कहाँ सल्लाह लिंदा राम्रो हुन्छ?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            निवासी अभिलेख आवश्यक छ। कहाँ पाउन सक्छु?
            </div>
        </div>
    </div>

    <figure>
    <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
    </figure>
    <InnerBox top={20} bottom={20} left={0} right={0}>
    <EmphasisNote>
    <h2>
    उपलब्द भाषा
    </h2>
    <p>
    जापानी, अङ्ग्रेजी, चिनियाँ, कोरियाली, भियतनामी, नेपाली, इन्डोनेसियाली, तागालोग, थाई, मलय, स्पेनिस, फ्रेन्च, जर्मनी, इटालियन, रुसी, पोर्चुगिज, बर्मी, खमेर , मंगोलियन, सिंहला, हिन्दी, बंगाली
    </p>
    </EmphasisNote>
    </InnerBox>
    <p>
    फुकुओका शहर चुयो वार्ड तेन्जिन १-१-१अकुरोस फुकुओका तेस्रो तला <br/>
    (सार्वजनिक हित समावेशी फाउन्डेसन) <br/>
    फुकुओका प्रिफेक्चर अन्तर्राष्ट्रिय एक्सचेन्ज
    </p>
    <FukidashiRedLeft>
    सेन्टर ०१२०-२७९-९०६
    </FukidashiRedLeft>
    <div className="pc-flex col-2">
        <p>
            <a href="https://kokusaihiroba.or.jp/" target="_blank">
                <img src={publicPath('images/fief.png')} className="img-fit" alt="公益財団法人福岡県国際交流センター"/>
            </a>
        </p>
        <p>
            <a href="https://www.fissc.net/" target="_blank">
                <img src={publicPath('images/ficc.png')} className="img-fit" alt="FiSSC"/>
            </a>
        </p>
    </div>
   
    <NavigatorAction actionType="goodluck" charactorType="good" dir="right"></NavigatorAction>
    </InnerBox>
    </>)
}

export default LangNe