const LangKo = () => {

    return (<>
    <h1>사이트 정책</h1>
    <p>
    FUKUOKA IS OPEN 추진사업 실행위원회(이하, 실행위원회)가 제공하는 포털 사이트(https://www.fisop.net 의 포털 사이트를 가리키며 이하 포털 사이트)를 이용하시는 분은 아래 내용을 읽어주시기 바랍니다. 아울러 아래 내용은 예고 없이 변경하는 경우가 있으므로 유의하시기 바랍니다.
    </p>
    <p>
    <dl>
    <dt>
    ●면책사항에 대하여
    </dt>
    <dd>
    <p>
    「본 포털 사이트에 게재된 정보의 내용에 관해서는 만전을 기하고 있으나 그 내용의 정확성 및 안전성을 보장하지는 않습니다. 실행위원회는 이용자가 본 사이트에 게재된 정보로 인하여 입은 손해, 손실에 대하여 일체의 책임을 지지 않습니다.
    </p>
    <p>
    또한 본 포털 사이트상에서 실행위원회 이외가 관리하고 있는 홈페이지에 링크되어 있는 경우, 링크된 홈페이지에 대해서는 실행위원회가 관리, 운영하지 않을 수 있습니다. 또 그 내용의 사실 여부에 대해서도 일체 책임지지 않으므로 유의하시기 바랍니다.
    </p>
    <p>
    아울러 본 포털 사이트에 게재되어 있는 정보에 대해서는 예고 없이 변경 또는 삭제하는 경우가 있으므로 유의하시기 바랍니다.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●저작권에 대하여
    </dt>
    <dd>
    본 포털 사이트에 게재된 글, 로고, 사진, 동영상, 소프트웨어 및 기타 모든 정보의 저작권은 별도 기재가 없는 한 실행위원회 또는 제삼자가 저작권을 가지고 있습니다. 사적 이용 등 저작권법에 의해 인정된 경우를 제외하고 저작권자의 사전 허가 없이 이러한 정보를 이용(복제, 변경, 전재, 재배포, 공중 송신 등을 포함)할 수 없습니다. 또 일부 사진 등의 저작권은 원저작자가 가지고 있습니다.
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●이용환경(브라우저)에 대하여
    </dt>
    <dd>
    <p>
    본 포털 사이트는 각종 인터넷 열람 소프트(브라우저) 최신판의 이용을 권장합니다.
    </p>
    <p>
    상기 목적 이외에 열람하시는 경우에는 올바르게 표시되지 않을 수 있으니 유의하시기 바랍니다.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●자주 보시는 페이지에 대하여
    </dt>
    <dd>
    <p>
    인터넷 열람 소프트(브라우저)에서 이전에 열람한 시점의 정보(PC 내에 일시적으로 보존된 정보)가 표시되는 일이 있습니다.
    </p>
    <p>
    그러므로 자주 보시는 페이지에 대해서는 최신 정보로 갱신(새로고침)할 것을 권장합니다.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●JavaScript에 대하여
    </dt>
    <dd>
    <p>
    본 포털 사이트에서는 일부의 JavaScript를 사용하고 있습니다. 사용하는 인터넷 브라우저가 JavaScript를 지원하지 않거나 JavaScript를 비활성화해 사용하는 경우에는 일부 기능이 작동하지 않습니다.
    </p>
    <p>
    모든 기능을 이용하시려면 JavaScript를 지원하는 인터넷 브라우저를 이용하시거나 인터넷 브라우저 설정에서 JavaScript를 활성화하시기 바랍니다.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●준거법
    </dt>
    <dd>
    이 포털 사이트의 이용과 사이트 정책의 해석 및 적용은 그 밖에 별도의 규정이 없는 한 일본의 법률에 준거하는 것으로 합니다.<br/>
    본 포털 사이트의 접속은 접속하신 분의 자유의사에 따라 이루어졌으며 접속하신 분의 책임하에 본 포털 사이트를 이용해 주시기 바랍니다. 본 포털 사이트에 관한 일체의 분쟁에 대해서는 후쿠오카 지방 재판소를 제1심의 전속적 합의 관할 재판소로 합니다.
    </dd>
    </dl>
    </p>
    </>)
}

export default LangKo