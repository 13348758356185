
const LangTh = () => {


    return (
        <>
            <h2>
                <img src="./images/virtual/fio_park.webp" alt="FUKUOKA IS OPEN PARK"/>
            </h2>
           
            <div className="virtual-inner">
                <p className="message al-l">
                มาสัมผัสประสบการณ์รูปแบบการจำลอง เช่น วิวทิวทัศน์, อาหาร, ยานพาหนะการเดินทางต่างๆ ของจังหวัดฟุกุโอกะ ได้ที่ <span>"FUKUOKA IS OPEN PARK" ที่นำเสนอในรูปแบบโลกเสมือนจริง ซึ่งสร้างขึ้นจากพื้นที่จริงในฟุกุโอกะกันเถอะ</span>
                </p>
                <div className="fiopark">
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_01_title_en.webp" alt="FUKUOKA IS OPENパークの入口"/></dt>
                        <dd>
                        <p>มารวมตัวพบปะกับเพื่อนฝูง สนทนาพูดคุย และชมภาพเคลื่อนไหวของสวนสาธารณะเทนจินเซ็นทรัลพาร์คอันเขียวขจี ที่ตั้งอยู่ในเขตเทนจินใจกลางคิวชูจังหวัดฟุกุโอกะอย่างสนุกสนานเพลิดเพลินกันเถอะ ต่อจากนี้ไปคุณสามารถออกไปสัมผัสกับโลกต่างๆอีกมากมาย</p>
                            <figure>
                                <img src="./images/virtual/fio_park_01_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_02_title_en.webp" alt="FUKUOKA フルーツパーク"/></dt>
                        <dd>
                            <p>มาสนุกสนานเพลิดเพลินกับการเก็บเกี่ยวพืชผักและผลไม้ตามฤดูกาลของ จังหวัดฟุกุโอกะกันเถอะ!</p>
                            <figure>
                                <img src="./images/virtual/fio_park_02_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    
                </div>
                <div className="fiopark-howto">
                    <div className="fiopark-howto-title">
                    <h3><span>วิธีใช้งาน </span>FUKUOKA IS OPEN PARK</h3>
                    </div>
                    <div className="fiopark-howto-entrance">
                        <div><img src="./images/virtual/entrance_en.webp" alt="FUKUOKA IS OPEN 参加する"/></div>
                        <div><p>คุณสามารถเข้าร่วมได้โดยคลิกที่ "Join"</p></div>
                    </div>
                    <div className="fiopark-howto-app">
                        <div>
                            <p>จำเป็นต้องดาวน์โหลดแอปพลิเคชั่น "cluster" ลงในสมาร์ทโฟน, แท็บเล็ต, พีซี, อุปกรณ์ VR ฯลฯ ไว้ก่อนล่วงหน้า!</p>
                            
                            <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend pc" target="_blank">
                               <span> หากคุณมีแอปพลิเคชั่นอยู่แล้ว ให้คลิก</span>
                            </a>
                        </div>
                        <div>
                            <h4>หากคุณมีแอปพลิเคชั่นอยู่แล้ว ให้คลิก </h4>
                            <img src="./images/virtual/cluster01.webp" alt="clusterを起動" width={200}/>
                            <span>"เริ่มcluster"</span>
                            <p>หลังจากนั้นคลิกที่ปุ่ม</p>
                            <img src="./images/virtual/cluster02.webp" alt="遊びに行く"/>
                            <p>ไปเที่ยว" เพื่อเข้าไปยังห้อง</p> 
                        </div>
                        <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend sp" target="_blank">
                            <span> ข้อกำหนดต่างๆของระบบสามารถดูได้ที่นี่</span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

}

export default LangTh