import HeadLine from "@/components/Atoms/HeadLine"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { TopSectionCotainerTemplate } from "@/components/Templates/Responsive/topSectionContainer"
import { langType } from "src/types/lang"
import { tsutaeruToEastJa } from "src/utilty/easyJaCheker"
import getLayer from "src/utilty/getLayer"
import { PathAnalysisValue } from "src/utilty/pathAnalyzer"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"


const SSection = styled.section`
    position: relative;
    background:url(${publicPath('images/top/basicInfoBk.png')}) no-repeat;
    background-size:100% auto;
    background-position: center;
    width: 100%;
    height: 100%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        background: url('images/pc/bk_basicinfo.webp') no-repeat;
        background-size: auto 100%;
    }
`

const SHeadline = styled.h2`
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    top:0;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 440px;
        transform: translate(-50%,-30%);
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 550px;
        transform: translate(-50%,-30%);
    }
`

const SMessagePosion = styled.div`
    font-size: ${props => props.theme.fontSize.top_mes_sp};
    color :#fff;
    font-weight: bold;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 14%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        font-size: ${props => props.theme.fontSize.topSection_pc};
        top: 20%;
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        font-size: ${props => props.theme.fontSize.topSection_pc};
        top: 20%;
    }
`

const SLinks =  styled.ul`
    position: absolute;
    top:20%;
    width: ${props => props.theme.topPage.links.ulWidth};
    left: 50%;
    transform: translateX(-50%);
    li{
        width: ${props => props.theme.topPage.links.liWidth};
        margin-top: -8px;
        &:nth-child(2n){
            margin-top: ${props => props.theme.topPage.links.oddMarginTop};
            margin-left: ${props => props.theme.topPage.links.oddOffest};
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        display: flex;
        flex-wrap: wrap;
        top:40%;
        li{
            flex-basis: 25%;
            margin: 0;
            transform: rotate(2deg);
            &:nth-child(2n){
                margin: 0;
            }
        }
    }
  
`

const BasicInfoSection = ({pathValue}:{pathValue: PathAnalysisValue}) => {

    const layer = getLayer('basicinfo')

    const allowLang = tsutaeruToEastJa(pathValue)
    return (
        <SSection>
            <TopSectionCotainerTemplate>
            <SHeadline>
                <HeadLine nameKey="basicinfo" lang={pathValue.lang}/>
            </SHeadline>
            <SMessagePosion>{layer[pathValue.lang].title}</SMessagePosion>
            <SLinks>
                <li><YellowArrowLink 
                       lang={allowLang}
                        href={`/basicinfo/areas?lang=${pathValue.lang}`}
                        linkKey="area"
                    ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/basicinfo/history?lang=${pathValue.lang}`}
                        linkKey="ayumi"
                        ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                       lang={allowLang}
                        href={`/basicinfo/population?lang=${pathValue.lang}`}
                        linkKey="jinko"
                        ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/basicinfo/industries?lang=${pathValue.lang}`}
                        linkKey="shuyo_sangyo"
                        ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/basicinfo/symbol?lang=${pathValue.lang}`}
                        linkKey="symbol"
                        ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/basicinfo/foreign?lang=${pathValue.lang}`}
                        linkKey="foreign"
                        ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/basicinfo/international?lang=${pathValue.lang}`}
                        linkKey="international"
                    />
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/basicinfo/consulate?lang=${pathValue.lang}`}
                        linkKey="ryojikan"
                        ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/basicinfo/kyushulogomark?lang=${pathValue.lang}`}
                        linkKey="kyushulogomark"
                        ></YellowArrowLink>
                </li>
            </SLinks>
            </TopSectionCotainerTemplate>
        </SSection>    
    )
}

export default BasicInfoSection
