import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangZhTw = () => {

    return (
        <Article>
        <p>
        福岡出入國在留管理局負責辦理有關在留資格的各種手續。<br/>
        (在留期間更新、在留資格變更、永住申請、為打工而需要辦理的在留資格外活動許可手續、邀請在海外外國人入國的手續等）。<br/>
        　外國人在留綜合谘詢信息中心 是以提供與在留資格等相關出入境手續的信息服務為主的谘詢機構。 近年來，外國人在日常生活中遇到的問題越來越多，因此該中心除了提供常規的出入境手續指導外，還提供一般生活問題的谘詢，並發揮著在留手續支援入口的作用。
        </p>
        <NavigatorAction dir="right" actionType="pickup" charactorType="normal"></NavigatorAction>

        <EmphasisNote>
        <h2>谘詢對象</h2>
        <p>
        ●外國居民及其家屬和相關人員等
        </p>
        <figure>
        <img src={publicPath('images/articles/nyukan02.png')} alt="福岡出入國在留管理局（申請窗口）"/>
        <figcaption>福岡出入國在留管理局（申請窗口）</figcaption>
        </figure>
        <h2>設施信息</h2>
        <p>
        ●設施名稱　／　<br/>
        福岡出入國在留管理局　審查管理部門<br/>
        ●地址　／　<br/>
        〒810-0073 福岡市中央區舞鶴 3-5-25 福岡第1法務綜合庁舎6層<br/>
        ●電話：092-717-7595<br/>
        ●營業時間／9:00 - 16:00 [周六、周日、節假日、年末年初休息]<br/>
        ●無 翻譯
        </p>
        <p>
        信息中心提供有關如何填寫申請表的信息<br/>
        ※　※信息中心的開放時間與申請窗口相同。 可對應英語、中文和韓語。
        </p>
        <h2>出入國在留管理庁網站 URL</h2>
        <p>
        【URL】<br/>
        <a href="https://www.moj.go.jp/isa/index.html" target="_blank">https://www.moj.go.jp/isa/index.html</a>
        </p>
        <h2>福岡出入國在留管理局Facebook URL</h2>
        <p>
        【URL】<br/>
        <a href="https://www.facebook.com/fukuoka.nyukan.support" target="_blank">https://www.facebook.com/fukuoka.nyukan.support</a>
        </p>
        <FukidashiRedLeft>
        除在留資格信息外，還登載申請窗口的擁擠情況等實用信息。歡迎點讚關注。
        </FukidashiRedLeft>
        <figure>
        <img src={publicPath('images/articles/nyukan_fb_qr.png')} alt="福岡入國管理局Facebook"/>
        <figcaption>福岡入國管理局Facebook</figcaption>
        </figure>

        </EmphasisNote>
        <br/>
        <NavigatorAction dir="right" actionType="pickup" charactorType="pointing"></NavigatorAction>

        </Article>
    )
}

export default LangZhTw