import { MenuButton } from "@/components/Atoms/Button/menuButton"
import { Flogo } from "@/components/Atoms/FLogo"
import LangNav from "@/components/Molecules/LangNav"
import SeachForm from "@/components/Molecules/SearchForm"
import { useEffect, useState } from "react"
import styled from "styled-components"
import Menu from "../Menu"
import { useLocation } from "react-router-dom"
import { pathAnalyzer } from "src/utilty/pathAnalyzer"
import publicPath from "src/utilty/publicPath"
import SiteNav from "@/components/Molecules/SiteNav"
import { Path } from "@react-pdf/renderer"
import { Emergency } from "../Emergency"



const SHdear = styled.header`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    max-width: ${props => props.theme.innerMaxWidth}; 
    @media screen and (min-width:   ${props => props.theme.innerMaxWidth} ){
        left: 50%;
        transform: translateX(-50%);
    }
    @media screen and (min-width: 1200px) and (max-width:1350px) {
        width: 415px
    }

        

    .header_logo{
        background: #FFF;
    }

    [class*='--inner']{
        max-width: ${props => props.theme.innerMaxWidth};
        margin: 0 auto;
    }
    .header_logo--inner{
        text-align: center;
        padding: 5px 0;
    }

    .header_functions--inner{
        display: flex;
        justify-content: flex-start;
       
        aspect-ratio: 10 / 1.6;
        & > div{
            &:first-child{
                flex-basis: 30%;
                margin-left: 0;
                padding: 5px;
            }
            &:nth-child(2){
                flex-basis: 50%;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                padding: 8px 5px 0 10%;
            }
            &:last-child{
                flex-basis: 20%;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                position: relative;
            }
        }
        &.active{
            background: ${props => props.theme.colors.black};
        }
        @media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
        (max-width: ${props => props.theme.responsive.minPc}) ),
        screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
            aspect-ratio: 10 / 1.2;
        }
    
    }
    z-index: 999;
    
`

const SLangNavWrap = styled.div`
    position: relative;
    & > div{
        position: absolute;
    }
`

const SMenuBtnPostion = styled.div`
    position: absolute;
    right: 0;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        display: none;
    }
`

const SMenuPositon = styled.div`
    position: fixed;
    top: 75px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: ${props => props.theme.responsive.minSp};
    z-index: 998;
    opacity: 0;
    display: none;
    &.active{
        display: block;
        animation-name:fedeInAnime;
        animation-duration:0.5s;
        animation-fill-mode:forwards;
    }

    @media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
    (max-width: ${props => props.theme.responsive.minPc}) ),
    screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
        max-width: ${props => props.theme.innerMaxWidth};
    }
    
    @keyframes fedeInAnime{
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`
const SMenu = styled.div`
   
    background: ${props => props.theme.colors.black};
    position: relative;
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    &::after{
        content: '';
        width: 100%;
        aspect-ratio: 320 / 420;
        position: fixed;
        background: url(${publicPath('images/menuBk.svg')}) no-repeat;
        background-size: 100% auto;
        background-position: bottom;
        bottom: 0;
        left: 0;
        z-index: 0;
    }
`
const SiteNavPostion = styled.div`
    position: absolute;
    top: 80px;
    left: 40%;
    z-index: 1;
    padding-bottom: 200px;
`

const Header = () => {

    const [menuActive, setMenuActive] = useState<boolean>(false)
    let location = useLocation();
    const path = pathAnalyzer(location)

    useEffect(() => {
        setMenuActive(false)
    },[location.pathname])
    const search = false
    return (
        <>
        <SHdear>
            <div className="header_logo">
                <div className="header_logo--inner">
                    <Flogo width={120} lang={path.lang}></Flogo>
                </div>
            </div>
            
            <div className="header_functions">
                <div className={`header_functions--inner ${menuActive ? 'active' : ''}`}>
                    <SLangNavWrap>
                        <LangNav/>
                    </SLangNavWrap>
                    <div>
                        <SeachForm/>  
                    </div>
                    <div>
                        <SMenuBtnPostion>
                         <MenuButton 
                            isActive={menuActive}
                            onClick={() => {
                                setMenuActive(!menuActive)
                            }}
                            ></MenuButton>
                        </SMenuBtnPostion>
                    </div>
                </div>
            </div>
        </SHdear>
        {menuActive === true &&
            <SMenuPositon className={menuActive ? 'active' : ''}>
                <SMenu>
                    <SiteNavPostion>
                        <SiteNav lang={path.lang}></SiteNav>
                    </SiteNavPostion>
                </SMenu>
            </SMenuPositon> 
        }
        </>
        
    )
}

export default Header