import { MenuLinkFrist, MenuLinkTo } from "@/components/Atoms/Links/menuLinksFrist"
import { MenuLinksSecond } from "@/components/Atoms/Links/menuLinksSecond"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { langType } from "src/types/lang"
import getLayer from "src/utilty/getLayer"
import { pathAnalyzer } from "src/utilty/pathAnalyzer"
import styled from "styled-components"

const SNav = styled.nav`
    li{
        margin-bottom: 10px;
    }
    .secondNav{
        display: none;
        margin-top: 5px;
        margin-left: 5px;
        li{
            margin-bottom: 8px;
        }
        &.active{
            display: block;
        }
    }
`

const SiteNav = ({lang}:{lang: langType}) => {

    const onClick = (e) => {
        var second = e.target.parentNode.parentNode.querySelector('.secondNav');

        if (second) {      
            second.classList.toggle('active');
        }
    }

   

    const basicinfo = getLayer('basicinfo')
    const lifestyle = getLayer('lifestyle')
    const consultation = getLayer('consultation')
    const initiatives = getLayer('initiatives')
    const about = getLayer('about')
    const support = getLayer('support')

    return (
        <SNav>
            <ul>
                <li>
                    <MenuLinkTo 
                        menuName="home"
                        href={`/?lang=${lang}`}
                    />
                </li>
                <li>
                    <MenuLinkTo 
                        menuName="news"
                        href={`/information?lang=${lang}`}
                    />
                </li>
                <li>
                    <MenuLinkTo 
                        menuName='gwm'
                        href={`/gwm?lang=${lang}`}
                    />
                </li>
                <li>
                    <MenuLinkFrist 
                        menuName='basicInfo'
                        onClick={onClick}
                    />
                    <ul className="secondNav">
             
                        <li>
                            <MenuLinksSecond href={`/basicinfo/areas?lang=${lang}`}>
                              {basicinfo.sublayer["areas"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/basicinfo/history?lang=${lang}`}>
                            {basicinfo.sublayer["history"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/basicinfo/population?lang=${lang}`}>
                            {basicinfo.sublayer["population"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/basicinfo/industries?lang=${lang}`}>
                            {basicinfo.sublayer["industries"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/basicinfo/symbol?lang=${lang}`}>
                            {basicinfo.sublayer["symbol"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/basicinfo/foreign?lang=${lang}`}>
                            {basicinfo.sublayer["foreign"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/basicinfo/international?lang=${lang}`}>
                            {basicinfo.sublayer["international"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/basicinfo/consulate?lang=${lang}`}>
                            {basicinfo.sublayer["consulate"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/basicinfo/kyushulogomark?lang=${lang}`}>
                            {basicinfo.sublayer["kyushulogomark"][lang].title}
                            </MenuLinksSecond>
                        </li>
                    </ul>
                </li>
                <li>
                    <MenuLinkFrist 
                        menuName='lifestyle'
                        onClick={onClick}
                    />
                    <ul className="secondNav">
                        <li>
                            <MenuLinksSecond href={`/lifestyle/medical?lang=${lang}`}>
                            {lifestyle.sublayer["medical"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/lifestyle/employment?lang=${lang}`}>
                            {lifestyle.sublayer["employment"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/lifestyle/living?lang=${lang}`}>
                            {lifestyle.sublayer["living"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/lifestyle/education?lang=${lang}`}>
                            {lifestyle.sublayer["education"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/lifestyle/disasterguidance?lang=${lang}`}>
                            {lifestyle.sublayer["disasterinfo"][lang].title}
                            </MenuLinksSecond>
                        </li>
                    </ul>
                </li>
                <li>
                    <MenuLinkFrist 
                        menuName='consultation'
                        onClick={onClick}
                    />
                    <ul className="secondNav">
                        <li>
                            <MenuLinksSecond href={`/consultation/international?lang=${lang}`}>
                            {consultation.sublayer["international"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/consultation/immigrationbureau?lang=${lang}`}>
                            {consultation.sublayer["immigrationbureau"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/consultation/municipalitycounter?lang=${lang}`}>
                            {consultation.sublayer["municipalitycounter"][lang].title}
                            </MenuLinksSecond>
                        </li>
                    </ul>
                </li>
                <li>
                    <MenuLinkFrist 
                        menuName='initiatives'
                        onClick={onClick}
                    />
                    <ul className="secondNav">
                        <li>
                            <MenuLinksSecond href={`/initiatives/sports?lang=${lang}`}>
                            {initiatives.sublayer["sports"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/initiatives/industry?lang=${lang}`}>
                            {initiatives.sublayer["industry"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/initiatives/health?lang=${lang}`}>
                            {initiatives.sublayer["health"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/initiatives/sightseeing?lang=${lang}`}>
                            {initiatives.sublayer["sightseeing"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/initiatives/environment?lang=${lang}`}>
                            {initiatives.sublayer["environment"][lang].title}
                            </MenuLinksSecond>
                        </li>
                    </ul>
                </li>
                <li>
                    <MenuLinkTo 
                        menuName='movie'
                        href={`/movie/?lang=${lang}`}
                    />
                </li>
                <li>
                    <MenuLinkTo 
                        menuName='virtual'
                        href={`/virtual?lang=${lang}`}
                    />
                </li>
                <li>
                    <MenuLinkTo 
                        menuName='special'
                        href={`/special?lang=${lang}`}
                    />
                </li>
                <li>
                    <MenuLinkFrist 
                        menuName='about'
                        onClick={onClick}
                    />
                    <ul className="secondNav">
                        <li>
                            <MenuLinksSecond href={`/about/fio?lang=${lang}`}>
                            {about.sublayer["fio"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/about/sitepolicy?lang=${lang}`}>
                            {about.sublayer["sitepolicy"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/about/privacy?lang=${lang}`}>
                            {about.sublayer["privacy"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/about/accessibility?lang=${lang}`}>
                            {about.sublayer["accessibility"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/about/linkforsite?lang=${lang}`}>
                            {about.sublayer["linkforsite"][lang].title}
                            </MenuLinksSecond>
                        </li>
                    </ul>
                </li>
                <li>
                    <MenuLinkFrist 
                        menuName='support'
                        onClick={onClick}
                    />
                    <ul className="secondNav">
                        <li>
                            <MenuLinksSecond href={`/support/faq?lang=${lang}`}>
                            {support.sublayer["faq"][lang].title}
                            </MenuLinksSecond>
                        </li>
                        <li>
                            <MenuLinksSecond href={`/support/links?lang=${lang}`}>
                            {support.sublayer["links"][lang].title}
                            </MenuLinksSecond>
                        </li>
                    </ul>
                </li>
            </ul>
        </SNav>    
    )

}

export default SiteNav  