import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangTh = () => {

    return (
        <Article>
        <p>
        สำนักงานตรวจคนเข้าเมืองฟุกุโอกะดำเนินการขั้นตอนต่างๆ เกี่ยวกับสถานะการพำนัก<br/>
        (การขยายระยะเวลาการพำนัก, การเปลี่ยนสถานะการพำนัก, การขอมีถิ่นที่อยู่ถาวร, การขอนุญาตทำกิจกรรมนอกเหนือจากที่ได้รับอนุญาตเพื่อทำงานพาร์ทไทม์, การเชิญชาวต่างชาติที่อยู่ต่างประเทศเข้าประเทศญี่ปุ่น เป็นต้น)<br/>
        　เราได้จัดตั้งศูนย์ข้อมูลให้คำปรึกษาสำหรับผู้อยู่อาศัยชาวต่างชาติ เพื่อเป็นสถานที่ให้คำปรึกษาเกี่ยวกับขั้นตอนการตรวจคนเข้าเมืองที่เกี่ยวข้องกับสถานะการพำนักเป็นหลัก อีกทั้งในช่วงไม่กี่ปีที่ผ่านมามีความจำเป็นในการให้ความช่วยเหลือต่อปัญหาของผู้อยู่อาศัยชาวต่างชาติมากขึ้น ดังนั้นนอกจากทางศูนย์จะให้คำแนะนำเกี่ยวกับขั้นตอนการตรวจคนเข้าเมืองแล้ว ยังให้คำปรึกษาเกี่ยวกับการใช้ชีวิตโดยทั่วไปและทำหน้าที่เป็นจุดเริ่มแรกสำหรับการสนับสนุนด้านการอยู่อาศัย
        </p>
        <NavigatorAction dir="right" actionType="pickup" charactorType="normal"></NavigatorAction>

        <EmphasisNote>
        <h2>ผู้ที่สามารถรับคำปรึกษา</h2>
        <p>
        ●ผู้อยู่อาศัยชาวต่างชาติและครอบครัว รวมถึงบุคคลที่เกี่ยวข้อง เป็นต้น
        </p>
        <figure>
        <img src={publicPath('images/articles/nyukan02.png')} alt="สำนักงานตรวจคนเข้าเมืองจังหวัดฟุกุโอกะ (เคาน์เตอร์ยื่นคำร้อง)"/>
        <figcaption>สำนักงานตรวจคนเข้าเมืองจังหวัดฟุกุโอกะ (เคาน์เตอร์ยื่นคำร้อง)</figcaption>
        </figure>
        <h2>ข้อมูลหน่วยงาน</h2>
        <p>
        ●ชื่อหน่วยงาน:／　<br/>
        สำนักงานตรวจคนเข้าเมืองจังหวัดฟุกุโอกะ ฝ่ายตรวจสอบและจ้ดการ<br/>
        ●ที่ตั้ง:／　<br/>
        3-5-25 Fukuoka Legal Affairs General Office ชั้น 6 ไมซูรุ เขตจูโอ เมืองฟุกุโอกะ 810-0073<br/>
        ●หมายเลขโทรศัพท์: 092-717-7595<br/>
        ●เวลาทำการ:／9:00-16:00 น. [*ปิดวันเสาร์อาทิตย์ วันหยุดนักขัตฤกษ์ และวันหยุดปีใหม่]<br/>
        ●ล่าม: ไม่มี
        </p>
        <p>
        ศูนย์ข้อมูลจะให้คำแนะนำเกี่ยวกับวิธีการกรอกใบคำร้อง<br/>
        ※*เวลาทำการของศูนย์ข้อมูลเป็นเวลาเดียวกับเคาน์เตอร์ยื่นคำร้อง ให้บริการในภาษาอังกฤษ จีน และเกาหลี
        </p>
        <h2>URL โฮมเพจสำนักงานตรวจคนเข้าเมือง</h2>
        <p>
        【URL】<br/>
        <a href="https://www.moj.go.jp/isa/index.html" target="_blank">https://www.moj.go.jp/isa/index.html</a>
        </p>
        <h2>URL Facebook สำนักงานตรวจคนเข้าเมืองจังหวัดฟุกุโอกะ</h2>
        <p>
        【URL】<br/>
        <a href="https://www.facebook.com/fukuoka.nyukan.support" target="_blank">https://www.facebook.com/fukuoka.nyukan.support</a>
        </p>
        <FukidashiRedLeft>
        นอกจากข้อมูลเกี่ยวกับสถานะการพำนัก ยังโพสต์ข้อมูลความหนาแน่นของผู้รอใช้บริการเคาน์เตอร์ยื่นคำร้องและข้อมูลที่เป็นประโยชน์อื่นๆ อีกด้วยฝากท่านกดถูกใจและกดติดตามเรา
        </FukidashiRedLeft>
        <figure>
        <img src={publicPath('images/articles/nyukan_fb_qr.png')} alt="Facebook สำนักงานตรวจคนเข้าเมืองฟุกุโอกะ"/>
        <figcaption>Facebook สำนักงานตรวจคนเข้าเมืองฟุกุโอกะ</figcaption>
        </figure>

        </EmphasisNote>
        <br/>
        <NavigatorAction dir="right" actionType="pickup" charactorType="pointing"></NavigatorAction>

        </Article>
    )
}

export default LangTh