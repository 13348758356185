import { Icon } from "@/components/Atoms/Icons"
import styled from "styled-components"


const SIcons = styled.div`
    & > * {
        margin-left: 5px;
    }
`

const SnsIcons = () => {

    const width = 40

    return (
        <SIcons>
            <a href="https://www.youtube.com/@FUKUOKA-IS-OPEN" target="_blank">
                <Icon src="images/sns_youtube.png" width={width} alt="youtube"/>
            </a>
            <a href="https://www.instagram.com/fukuoka_is_open/" target="_blank">
                <Icon src="images/sns_insta.png" width={width} alt="instagram"/>
            </a>
            <a href="https://twitter.com/fukuoka_is_open" target="_blank">
                <Icon src="images/sns_x.png" width={width} alt="x"/>
            </a>
            <a href="https://www.facebook.com/FUKUOKA.IS.OPEN" target="_blank">
                <Icon src="images/sns_fb.png" width={width} alt="facebook"/>
            </a>
        </SIcons>
    )
}

export default SnsIcons