import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlue, FukidashiBlueBottom } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { ChallengerAction, NavigatorAction } from "@/components/Molecules/CharactorAction"


const LangVi = () => {
    const lang = 'vi'
    return (<>
    <InnerBox left={0} right={0} bottom={60}>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>Tôi không nói được tiếng Nhật. Có tổng đài tư vấn nào hỗ trợ bằng nhiều ngôn ngữ không?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>Tại “Trung tâm tư vấn cho người nước ngoài Fukuoka”, bạn có thể nhận được tư vấn bằng 22 ngôn ngữ, bao gồm cả tiếng Nhật. Ngoài ra, mỗi thành phố, thị trấn cũng đã thiết lập các Tổng đài tư vấn đa ngôn ngữ và Hiệp hội giao lưu quốc tế để cung cấp hỗ trợ về ngôn ngữ, tư vấn pháp lý, và hỗ trợ tất cả các mặt trong cuộc sống. </span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="normal"
        actionType="anser"/>
        </div>
        </div>
    </div>

    <div className="links">
    <span className="left">Để biết thêm chi tiết</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/consultation/international?lang=${lang}`}
    linkKey="kokusai_soudan"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="puzzled"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>Tôi muốn đưa gia đình đến nhưng họ không nói được tiếng Nhật. Vui lòng chỉ cho tôi nơi có thể học tiếng Nhật được không?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>Tại Fukuoka, có hơn 100 lớp học tiếng Nhật được tổ chức bởi chính phủ và các tổ chức tình nguyện. Trong đó có các lớp học nơi bạn có thể thoải mái thảo luận về những vấn đề khó khăn trong cuộc sống hàng ngày.</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="smile"
        actionType="anser"/>
        </div>
        </div>
    </div>

    <div className="links">
    <span className="left">Để biết thêm chi tiết</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/education?lang=${lang}`}
    linkKey="kyoiku"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>


    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>Xin vui lòng cung cấp thông tin về các cơ sở y tế có hỗ trợ bằng tiếng nước ngoài.</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>“Trung tâm hỗ trợ y tế quốc tế Fukuoka” cung cấp thông tin về các cơ sở y tế có hỗ trợ tiếng nước ngoài, các cơ sở y tế cung cấp dịch vụ phiên dịch y tế, giới thiệu các cơ sở y tế trong tỉnh Fukuoka, cung cấp thông tin về hệ thống y tế Nhật Bản và cơ sở y tế có hỗ trợ phiên dịch qua điện thoại với bác sĩ.</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="good"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">Để biết thêm chi tiết</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/medical?lang=${lang}`}
    linkKey="iryo"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">  
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>Có trường quốc tế nào cho con tôi theo học không?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>Tỉnh Fukuoka có trường quốc tế được Tổ chức tú tài Quốc tế -tổ chức chứng nhận giáo dục quốc tế công nhận, giảng dạy bằng tiếng Anh liên cấp từ mẫu giáo đến THPT. </span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="pointing"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">Để biết thêm chi tiết</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/education?lang=${lang}`}
    linkKey="kyoiku"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>Tôi đang tìm việc làm ở Fukuoka. Nơi nào có thể giới thiệu việc làm tôi?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>Tư vấn và giới thiệu về việc làm cho người nước ngoài có tại Hello Work Fukuoka Chuo/Trung tâm dịch vụ tuyển dụng cho người nước ngoài Fukuoka.</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="normal"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">Để biết thêm chi tiết</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/employment?lang=${lang}`}
    linkKey="works"
    ></YellowArrowLink>
    </div>

    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>Tôi muốn chuyển đến Fukuoka nhưng tôi lo lắng không biết liệu mình có thể thích nghi với khu vực này hay không.</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>Tại trang web này, chúng tôi đăng tải video giới thiệu về câu chuyện thành công của những người nước ngoài đang sống và làm việc tại tỉnh Fukuoka. Ở Fukuoka có rất nhiều người nước ngoài như những người được giới thiệu trong video đã rời xa quê hương và hòa nhập với cộng đồng địa phương để sống và làm việc. </span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="smile"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">Để biết thêm chi tiết</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/movie?lang=${lang}`}
    linkKey="fortunehills"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>
    </InnerBox>
    </>)
}

export default LangVi