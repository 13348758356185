
import { NavigatorNice } from "@/components/Atoms/Character/navigator"
import { FukidashiBlueBottom } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorFukidashiPicup } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"

const LangEn = () => {

    return (<>
    <h1>Support for Foreign Nationals in the Event of a Disaster</h1>
    <p>
    It is very important for foreign visitors to be prepared on a daily basis so that they can act appropriately in the event of a disaster and live safely and peacefully in our prefecture.<br/>
    Our Prefecture provides information in a variety of forms. We hope that by learning about disasters, you will be able to protect your own life and the lives of your family, friends and other loved ones around you.
    </p>
    <NavigatorFukidashiPicup dir="right">
    In the event of a disaster, it is important to ensure that disaster and evacuation information is obtained. The following websites and apps make it easy to gather information.
    </NavigatorFukidashiPicup>
    <br/>
    <EmphasisNote>

    <h2>Download the disaster prevention application "Fukuoka Disaster Prevention Navi・Mamoru-kun</h2>
    <p>
    "The disaster prevention application ""Fukuoka Disaster Prevention Navi・Mamoru-kun"" provides disaster information and other information.<br/>
    It is free to use and register, and disaster prevention information can be obtained with simple operations."
    </p>
    <p>
    ●Disaster prevention information for your current location is available via push notification.<br/>
    ●The map clearly shows the location of evacuation centers.<br/>
    ●Illustrations of actions to take in the event of a disaster are provided.<br/>
    ●Allows you to check the location of your family members in the event of a disaster.<br/>
    ●Languages supported are Japanese, English, Chinese (simplified and traditional), Korean, and Vietnamese.
    </p>
    <figure>
    <img src={publicPath('images/articles/mamorukun01.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/mamorukun02.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
    </figure>

    <div className="app_qr">
    <a href="https://apps.apple.com/jp/app/%E3%81%B5%E3%81%8F%E3%81%8A%E3%81%8B%E9%98%B2%E7%81%BD%E3%83%8A%E3%83%93-%E3%81%BE%E3%82%82%E3%82%8B%E3%81%8F%E3%82%93/id1659756418" target="blank">
    <figure>
    <img src={publicPath('images/articles/mamorukun_app01.png')} alt="App Store" className="fit-img"/>
    </figure>
    </a>
    <hr/>
    <a href="https://play.google.com/store/apps/details?id=jp.lg.fukuoka.pref.fukuoka_bosai&hl=ja&gl=US" target="blank">
    <figure>
    <img src={publicPath('images/articles/mamorukun_app02.png')} alt="Google Play" className="fit-img"/>
    </figure>
    </a>
    </div>
    <h2>Fukuoka Prefecture Disaster Prevention Website</h2>
    <p>
    Fukuoka has established the "Fukuoka Prefecture Disaster Prevention Website" to provide the latest information on disaster preparedness and disaster information.
    </p>
    <p>
    （URL:<a href="https://www.bousai.pref.fukuoka.jp" target="_blank">
    https://www.bousai.pref.fukuoka.jp
    </a> ）
    </p>
    <h2>Disaster Prevention Handbook</h2>
    <p>
    We have prepared a "Disaster Prevention Handbook for Foreign Residents" in 9 languages, so please check it in advance to be prepared for disasters.
    </p>
    <p>
    （URL:<a href="https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html" target="_blank">https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html</a>）
    </p>
    <p>
    Publication details,<br/>
    ●Description of disasters that may occur<br/>
    ●Actions to take in the event of a disaster<br/>
    ●Daily Disaster Preparedness<br/>
    ●evacuation sites<br/>
    ●Emergency contact information, etc.<br/>
    ●Languages are available in easy Japanese, English, Chinese, Korean, Tagalog, Vietnamese, Nepali, Indonesian, and Myanmar.
    </p>
    <p>
    <a href="https://www.pref.fukuoka.lg.jp/uploaded/life/671824_61619901_misc.pdf" target="_blnak">
    <figure>
    <img src={publicPath('images/articles/disasterprevantionhandbook.png')} alt="disaster prevantion handbook" className="fit-img"/>
    </figure>
    </a>
    </p>

    <h2>Disaster preparedness training for foreigners</h2>
    <p>
    Furthermore, to ensure that foreign residents can take appropriate actions in the event of a disaster, we conduct disaster drills for foreign residents. We encourage you to participate in these drills and experience firsthand the actions that should be taken in the event of a disaster.
    </p>
    <p>
    ●Lecture on Disasters Likely to Occur in Japan<br/>
    ●Hands-on learning (e.g., assembling equipment used in evacuation shelters, making instant disaster prevention goods, etc.), etc.
    </p>
    <figure>
    <img src={publicPath('images/articles/disasterinfo01.jpg')} alt="" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/disasterinfo02.jpg')} alt="" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/disasterinfo03.jpg')} alt="" className="fit-img"/>
    </figure>
    <h2>Multilingual Disaster Assistance Center</h2>
    <p>
    A "Multilingual Disaster Support Center" will be established to assist municipalities in dealing with foreign residents in the event of a large-scale disaster.
    </p>
    <p>
    ●It will be set up in the International Center when the Fukuoka Prefecture Disaster Countermeasures Headquarters is established.<br/>
    ●Once established, the system will be available 24 hours a day, 7 days a week. (Subject to change depending on the disaster situation.)<br/>
    ●The main operations of the Multilingual Support Center are as follows<br/>
    ⇒Collect information from the affected municipalities regarding the situation of foreign residents.<br/>
    ⇒Interpretation and translation support for information provision and evacuation center management at the time of disaster, as requested by the affected municipalities.<br/>
    ⇒We dispatch volunteer interpreters/translators to disaster-stricken areas at the request of the municipalities affected by the disaster.
    </p>
    </EmphasisNote>
    <br/>
    <NavigatorNice dir="right"></NavigatorNice>
    </>)
}

export default LangEn