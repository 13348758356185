
import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlueLeft } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { NavigatorFukidashiCheck } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"

const LangJa = () => {

    return (
        <>
         <InnerBox  top={0} bottom={30} >
            <h1>公益財団法人福岡県国際交流センター</h1>
            <p>
            公益財団法人福岡県国際交流センターは、福岡県のもつ地理的、歴史的特性を生かし、県下の交流団体等と協力して県民主体の国際交流を推進することにより、国際交流における福岡県の拠点性を高めていくとともに、アジア諸国･地域をはじめとして世界各国･地域との交流を深め、もって相互の繁栄と世界の平和に寄与することを目的として、平成元年に設立されました。
            </p>
          
            
            <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
            <InnerBox top={20} bottom={20} left={0} right={0}>
            <EmphasisNote>
                <h2>取り組み内容</h2>
                <p>
                県民と在住外国人との交流では、講演会、セミナーなど様々なイベントを開催するほか、在住外国人の諸問題を日本語を含む22言語で解決する「福岡県外国人相談センター」の運営やボランティア募集、災害関連情報など生活関連情報の提供も行っています。
                </p>
                <p>
                海外との交流では、福岡県と友好等提携を結んでいるハワイ州(米国)、江蘇省(中国)、バンコク都(タイ)等との交流を進めています。
                </p>
                <p>
                また、県内の学校や公民館に在住外国人等を講師として派遣する国際理解教育を実施するとともに、民間国際交流団体が行う草の根交流の支援や、地域における日本語教室の支援も行っています。
                </p>
                <p>
                さらに、産・学・官で設置された福岡県留学生サポートセンターの事務局として、留学生の総合的な支援も行っています。
                </p>
            </EmphasisNote>
            </InnerBox>
            <p className="m-b-30">
            【お問合せ先】<br/>
            〒810-0001<br/>
            <span>福岡県福岡市中央区天神1丁目1番1号</span> <span>アクロス福岡 8F／3F</span><br/>
            <span>8F/ </span><span>TEL：092-725-9204</span><span>8:30~17:45（土日・祝日除く）</span><br/>
            <span> 3F（こくさいひろば）/  </span><span>TEL：092-725-9200</span> <span>FAX：092-725-9206</span>
            <span>10:00~18:30（年末年始除く）</span>
            </p>
            <div className="custom-link">
            <FukidashiRedLeft>公益財団法人福岡県国際交流センターのホームページはこちら</FukidashiRedLeft>
            
            <a href="https://kokusaihiroba.or.jp/" target="_blank">
                <figure>
                    <img src={publicPath('images/fief.png')} className="img-fit" alt="公益財団法人福岡県国際交流センター"/>
                </figure>
            </a>
            </div>
        </InnerBox>
        
        <InnerBox  top={20} bottom={20}>
            <h1>
                福岡県外国人相談センター
            </h1>
            <div className="sp-only">
            <NavigatorFukidashiCheck dir="left">
                <span className="txt-yellow">
                    福岡での生活で困ったことはありませんか？？<br/>
                    外国人相談センターでは多言語で皆様の悩みを解決します
                </span>
            </NavigatorFukidashiCheck>
            </div>
            <br/>
            <p>
                外国人の生活支援では、「福岡県外国人相談センター」で雇用、労働、医療、住宅、教育などの生活での困りごとや、在留手続き、法律相談など、様々な相談に２２言語で対応しています。
            </p>
            <div className="pc-only">
                <div className="pc-flex col-2">
                    <div>
                        <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
                    </div>
                    <div className="center">
                        <FukidashiBlueLeft>
                            <span className="txt-yellow">
                                福岡での生活で困ったことはありませんか？？<br/>
                                外国人相談センターでは多言語で皆様の悩みを解決します
                            </span>
                        </FukidashiBlueLeft>
                    </div>
                </div>
            </div>

            <br/>
            <div className="pc-flex col-2">
                <div>
                    <div className="deco-fukidashi navigatorCheck right pointing">
                        知りたいことや困ったことがあったら、お気軽にお電話、または窓口にお越しください。<br/>
                        色々な言葉で相談することができます。（無料）
                    </div>
                </div>
                <div>
                    <figure>
                        <img src={publicPath('images/international01.png')} className="img-fit" alt=""/>
                    </figure>
                    <figure className="pc-only">
                        <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
                    </figure>
                </div>
            </div>
            <div className="pc-flex col-2">
                <div>
                    <div className="deco-fukidashi challengerQ left thinking">
                        日本にいる在留の期間を延長したい。どうすればいいか？
                    </div>
                </div>
                <div>
                        <div className="deco-fukidashi challengerQ right puzzled">
                        自分の国の言葉で対応してくれる病院はありますか？
                        </div>
                </div>
                <div>
                    <div className="deco-fukidashi challengerQ right thinking">
                    大家さんとトラブルになってます。どこに相談すればいいですか？
                    </div>
                </div>
                <div>
                    <div className="deco-fukidashi challengerQ right thinking">
                    住民票が必要です。どこでもらえますか？
                    </div>
                </div>
            </div>
            
            <figure className="sp-only">
                <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
            </figure>
            <InnerBox top={20} bottom={20} left={0} right={0}>
            <EmphasisNote>
                <h2>
                対応言語
                </h2>
                <p>
                日本語、英語、中国語、韓国語、ベトナム語、ネパール語、インドネシア語、タガログ語、タイ語、マレー語、スペイン語、フランス語、ドイツ語、イタリア語、ロシア語、ポルトガル語、ミャンマー語、クメール語、モンゴル語、シンハラ語、ヒンディー語、ベンガル語
                </p>
            </EmphasisNote>
            </InnerBox>
            <p className="m-b-30">
            【お問合せ先】<br/>
            福岡市中央区天神１－１－１ アクロス福岡３Ｆ<br/>
            （公財）福岡県国際交流センター 内<br/>
            TEL.0120-279-906
            </p>
            <FukidashiRedLeft>
                詳しい情報はこちらでも確認いただけます
            </FukidashiRedLeft>
            <div className="pc-flex col-2">
                <p>
                    <a href="https://kokusaihiroba.or.jp/" target="_blank">
                        <img src={publicPath('images/fief.png')} className="img-fit" alt="公益財団法人福岡県国際交流センター"/>
                    </a>
                </p>
                <p>
                    <a href="https://www.fissc.net/" target="_blank">
                        <img src={publicPath('images/ficc.png')} className="img-fit" alt="FiSSC"/>
                    </a>
                </p>
            </div>
            <NavigatorAction actionType="goodluck" charactorType="good" dir="right"></NavigatorAction>
        </InnerBox>
        </>
    )
}

export default LangJa