import { PageTopButton } from "@/components/Atoms/Button/pageTopButton"
import { Copyright } from "@/components/Atoms/Copylight"
import SnsIcons from "@/components/Molecules/Sns/icons"
import { forwardRef, memo, useEffect, useState } from "react"
import styled from "styled-components"


const SFooter = styled.footer`

    position: fixed;
    z-index: 1000;
    bottom: -40px;
    width: 100%;
    @media screen and (min-width:${props => props.theme.innerMaxWidth} ){
      //  width:${props => props.theme.innerMaxWidth};
        left: 50%;
        transform: translateX(-50%);
    }

    &.active{
       bottom: 0px;
    }

`
const SFooterFix = styled.div`
    width: 100%;
   /* max-width: ${props => props.theme.innerMaxWidth};*/
    bottom: 0;
    position: relative;
`  

const SPageTopPositon = styled.div`
    position: absolute;
    top: -18px;
    right: 0;
`


const Footer = memo(() => {
    
    const onPageTop = () => {
        window.scrollTo({ top: 0,left: 0, behavior: "smooth"})
    }

    const [active, setActive] = useState<boolean>(false)

    function calcbottomPoint(){
        return  document.body.clientHeight -  window.innerHeight
    }
    
    const handleScrollActive = () => {    
        if (window.scrollY >= calcbottomPoint()) {
            setActive(true)
        }else{}
        // スクロールが最下部に到達したときの処理
        
    };

    const handleScrollNoActive = () => {    
        if (window.scrollY <  calcbottomPoint()) {
            setActive(false)
        }else{}
        // スクロールが最下部に到達したときの処理
    };
   

    useEffect(() => {
        if(active){
            window.removeEventListener('scroll', handleScrollActive);
            window.addEventListener('scroll', handleScrollNoActive);
        }else{
            window.removeEventListener('scroll', handleScrollNoActive);
            window.addEventListener('scroll', handleScrollActive);
        }
    
        // コンポーネントのアンマウント時にイベントリスナーを削除
        return () =>{ 
            window.removeEventListener('scroll', handleScrollNoActive);
            window.removeEventListener('scroll', handleScrollActive);
        }

    },[active])



    return (
        <>
        <SFooter className={active ? 'active' : ''}>
            <SFooterFix id="fixedElement">
                <SnsIcons></SnsIcons>
                <SPageTopPositon >
                    <PageTopButton onClick={onPageTop}/>
                </SPageTopPositon>
            </SFooterFix>
            <Copyright></Copyright>
        </SFooter>
           
        </>
      
    )
})

export default Footer