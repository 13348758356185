const LangZhTw = () => {

    return (<>
        <h1>關於個人資訊保護</h1>
        <p>
        本入口網站（<a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a>）基於《個人資訊保護法》和《福岡縣個人資訊保護法施行條例（2022年福岡縣條例43號）》，對個人資訊的收集、利用及管理按照如下規定進行適當處理。另外，關於該個人資訊處理的記載，在2023年4月1日進行了更新。
        </p>
        <p>
        福岡縣的個人資訊保護制度首頁請點擊此處（<a href="http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html" target="_blank">http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html</a>）
        </p>
        <p>
        <dl>
        <dt>●何謂個人資訊</dt>
        <dd>
        是指有關個人的資訊，能夠識別或者有可能識別特定個人的資訊（住址、姓名、電話號碼、電子郵箱等）。
        </dd>
        </dl>
        </p>
        <p>
        <dl>
        <dt>●關於個人資訊的收集</dt>
        <dd>
        FUKUOKA IS OPEN推進事業執行委員會（以下稱為「執行委員會。）」透過本入口網站收集個人資訊時，明確收集的目的，並在達成其目的所需的範圍內進行收集。
        </dd>
        </dl>
        </p>
        <p>
        <dl>
        <dt>●安全性的確保</dt>
        <dd>
        對於所收集的個人資訊，我們會防止被洩露、滅失、被竄改等，進行嚴格管理。此外，對於沒有必要繼續持有的個人資訊，我們會迅速地廢棄（消除）。"
        </dd>
        </dl>
        </p>
        <p>
        <dl>
        <dt>●關於（Cookie）</dt>
        <dd>
        <p>本網站為了讓用戶能夠利用某些頁面，存在使用了「Cookie」這一技術的頁面。</p>
        <p>
        「Cookie」是指Web伺服器識別用戶電腦時使用的行業標準技術，它實際是一個小容量的文本文件。
        </p>
        <p>
        一般用於如下用途：對因本入口網站的用戶進行的操作而向網站管理員提供的個人資訊（用戶ID和密碼）以及操作歷史記錄、訪問狀況和傾向進行分析，並作為統計資訊進行使用等。
        </p>
        <p>
        此外，「Cookie」可識別用戶的電腦，但只要用戶自己不輸入、提供詳細的個人資訊，則無法確定、識別個人身份。
        </p>
        <p>
        本入口網站無論用戶瀏覽器是否啟用「Cookie」功能，用戶均可保持匿名進行閱覽與利用。
        </p>
        <p>
        另外，用戶可透過更改所使用的瀏覽器的設置，禁用Cookie功能，但有時會導致無法利用部分頁面的全部或部分服務。
        </p>
        </dd>
        </dl>
        </p>
        <p>
        <dl>
        <dt>●"利用及提供的限制</dt>
        <dd>
        <p>
        對於執行委員會所收集的個人資訊，除了《個人資訊保護法》或者《福岡縣個人資訊保護法施行條例》中規定的情形外，一概不進行超過收集目的的利用及向執行委員會以外提供資訊。
        </p>
        <p>
        但是，對於本入口網站的訪問資訊、對執行委員會的建議和意見以及與這些相關的個人屬性，有時會公佈進行了統計性處理後的數據或意見等。
        </p>
        </dd>
        </dl>
        </p>
        <p>
        <dl>
        <dt>●社交媒體（SNS）按鈕的相關注意事項</dt>
        <dd>
        <p>
        在本入口網站中，瀏覽設置了社交媒體（SNS）按鈕等的頁面時，瀏覽者的相關資訊有時會被髮送到該SNS上。本入口網站所利用的SNS等服務的隱私策略等，請在各相關企業的網站等進行確認。
        </p>
        </dd>
        </dl>
        </p>
        <p>
            <dl>
                <dt>●有關Google Analytics的留意事項</dt>
                <dd>
                本入口網站為了把握入口網站的利用狀況使用了Google Analytics。Google Analytics利用Cookie收集用戶的資訊。關於Google Analytics的使用條款，請瀏覽此處<br/>
                （打開新窗口 <a href=" https://www.google.com/analytics/terms/jp.html" target="_blank">https://www.google.com/analytics/terms/jp.html </a>）。
                </dd>
            </dl>
        </p>
        <p>
            <dl>
                <dt>●適用範圍</dt>
                <dd>
                本隱私策略僅適用於本門戶網站（<a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a>以下的目錄）。
                </dd>
            </dl>
        </p>
    </>)
}

export default LangZhTw