import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangZhCn = () => {

    return (
        <Article>
        <p>
        福冈出入境在留管理局，对在留资格相关的各种手续进行办理。<br/>
        （在留期间的更新，在留资格的变更，永驻的申请，打工时所需要的资格外活动许可的手续办理，将位于海外的外国人招引至日本时所需要的手续办理等）<br/>
        　设置于福冈的外国人在留综合咨询信息中心，是一个主要提供与在留资格所相关的入境管理手续向导业务的咨询窗口。近年来，由于帮助外国人解决日常生活中遇到的种种问题这一需求日益增加，该信息中心，除了传统出入境手续的指导外，还提供诸多日常生活中问题的咨询，充当着向外国人提供日本在留相关的支援的第一关口。
        </p>
        <NavigatorAction dir="right" actionType="pickup" charactorType="normal"></NavigatorAction>

        <EmphasisNote>
        <h2>可以进行咨询的人群</h2>
        <p>
        ●在留日本的外国人以及其家族，相关人员等
        </p>
        <figure>
        <img src={publicPath('images/articles/nyukan02.png')} alt="福冈出入境在留管理局（申请窗口）"/>
        <figcaption>福冈出入境在留管理局（申请窗口）</figcaption>
        </figure>
        <h2>设施情报</h2>
        <p>
        ●设施名称 ／　<br/>
        福冈出入境管理局 审查管理部门<br/>
        ●所在地 ／　<br/>
        〒810-0073 福冈市中央区舞鹤3-5-２5 福冈第1法务综合厅舍6楼<br/>
        ●电话号码 092-717-7595<br/>
        ●开厅时间 ／9点00分～16点00分［周六日节假日年末年初为闭厅日］<br/>
        ●翻译 无
        </p>
        <p>
        于在留综合咨询信息中心，提供各种申请文件的填写向导<br/>
        ※※信息中心的开放时间与申请窗口相同。提供英语，中文，韩语的向导。
        </p>
        <h2>出入境在留管理厅官方网站URL</h2>
        <p>
        【URL】<br/>
        <a href="https://www.moj.go.jp/isa/index.html" target="_blank">https://www.moj.go.jp/isa/index.html</a>
        </p>
        <h2>福冈出入境在留管理局Facebook的URL</h2>
        <p>
        【URL】<br/>
        <a href="https://www.facebook.com/fukuoka.nyukan.support" target="_blank">https://www.facebook.com/fukuoka.nyukan.support</a>
        </p>
        <FukidashiRedLeft>
        除了在留资格相关的情报外，于此还会发布各种包括申请窗口的繁忙状况的信息以及其他有用情报请务必关注点赞哦。
        </FukidashiRedLeft>
        <figure>
        <img src={publicPath('images/articles/nyukan_fb_qr.png')} alt="福冈出入境管理局Facebook"/>
        <figcaption>福冈出入境管理局Facebook</figcaption>
        </figure>

        </EmphasisNote>
        <br/>
        <NavigatorAction dir="right" actionType="pickup" charactorType="pointing"></NavigatorAction>

        </Article>
    )
}

export default LangZhCn