import { langType } from "src/types/lang"
import Data from 'src/json/arrowLinks.json';
import styled from "styled-components";
import { Link } from "react-router-dom";
import publicPath from "src/utilty/publicPath";

interface YellowArrowLinkProps {
    lang : langType
    linkKey : string
    href : string
}

const SAnker = styled(Link)`
    img{
        transition: transform 0.3s ease; /* アニメーションの効果を指定 */
    }
    &:hover{
        img{
            transform: translateX(10px); /* 右に10px動かす */
        }
    }
`

export const WhiteMoreLink = ({
    href
}: {href : string}) => {

    return (
        <SAnker to={href}>
            <img 
                src={publicPath(`images/arrowLinks/more/white.webp`)}
                alt='more'
                className="img-fit"
            />
        </SAnker>
    )

}   

