import { ChallengerExcl } from "@/components/Atoms/Character/challenger"
import TrapezoidTitle from "@/components/Atoms/HeadLine/trapezoidTitle"
import { PropsWithChildren } from "react"
import { direction } from "src/types/common"
import styled from "styled-components"


const STrapezoidExcl = styled.div`
    position: relative;
    padding-top: 55%;
    .charapostion{
        position: absolute;
        width: 90%;
        top: 0;
        aspect-ratio: 10 / 8;
        overflow-y: hidden;
    }
    .wrap{
        min-height: 60px;
        width: 100%;
        display: grid;
        place-items: center;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minSp}) {
        padding-top: 0;
        .charapostion{
            display: none;
        }
    }
`

export const TrapezoidExcl = ({
    dir,
    children
}: PropsWithChildren<{dir:direction}>) => {

    return (
        <STrapezoidExcl>
            <div className="charapostion">
                <ChallengerExcl dir={dir} />
            </div>
            <TrapezoidTitle>
                <div className="wrap">
                    {children}
                </div>
            </TrapezoidTitle>
        </STrapezoidExcl>
    )  
}