import { NavigateCracter } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"

const LangZhCn = () => {

    return (<>
        <Article>
        <h1>古代－与亚洲交流的窗口－</h1>
        <p>
        福冈曾经设有大宰府政厅（古代被称之为“远的朝廷”）和迎接外国使节的官邸—鴻臚馆，它是中国大陆、朝鲜半岛和日本交流的窗口。进入中世纪后，博多港首先与中国大陆、朝鲜半岛，而后与琉球、南海作为贸易基地共同繁荣发展。
        </p>
        </Article>
        <div className="space">
        <div className="navigator right">
        <NavigateCracter type="smile"/>
        </div>
        </div>
        <Article>
        <h1>明治～昭和 -煤炭、钢铁工业的兴起-</h1>
        <p>
        自明治时代至昭和时代，筑豊一带以及筑后的大牟田地区的煤炭产出量大幅增加。此后利用这些煤炭，在北部形成了以钢铁、机械、电力、化学、陶瓷为中心的「北九州工业地带」，南部则形成了「煤炭化学联合企业」，为日本的近代化和经济发展提供了支持。
        </p>
        </Article>
        <div className="space">
        </div>
        <Article>
        <h1>明治～昭和 -煤炭、钢铁工业的兴起-</h1>
        <p>
        自明治时代至昭和时代，筑豊一带以及筑后的大牟田地区的煤炭产出量大幅增加。此后利用这些煤炭，在北部形成了以钢铁、机械、电力、化学、陶瓷为中心的「北九州工业地带」，南部则形成了「煤炭化学联合企业」，为日本的近代化和经济发展提供了支持。<br/>
        二战结束后，受国家倾斜生产政策和朝鲜战争所引发的特殊采购热潮，钢铁、金属、化学等制造业以及煤炭产业陆续繁荣起来，迅速从战后的荒废中恢复了过来。
        </p>
        </Article>
        <div className="space">
        </div>
        <Article>
        <h1>快速增长期 -能源革命带来的产业结构转型-</h1>
        <p>
        在1955年以后的高度经济增长时期，福冈县的工业生产也得到了大幅增长，但与此同时进行的能源革命导致了煤炭产业的衰退，煤炭产区受到了严重的打击。<br/>
        在经历了1970年代的两次石油危机之后，在低增长、日元升值和产业结构转型的过程中，以材料工业为主的我县经济面临着严峻的局面。 针对此，培育和集聚尖端成长型产业，推动汽车产业、先进半导体、生物技术、机器人技术等相关企业的选址被逐步落实推进。 此外，以福冈市为中心，商业和服务业等第三产业也得到了显著发展。
        </p>
        </Article>
        <div className="space">
        <div className="navigator left">
        <NavigateCracter type="good"/>
        </div>
        </div>
        <Article>
        <h1>现在-致力于成为九州、西日本乃至亚洲的交流中心-</h1>
        <p>
        从1970年代中期开始，为支持工业和日常生活而大力发展交通基础设施。 作为连接本州和九州的「东方最长悬索桥」和当时备受瞩目的关门桥开通后，从1970年代到 1980 年代，山阳新干线新大阪至博多全线投入运行，贯穿九州南北的九州纵贯自动车道古贺 IC至鸟栖 IC 段也被开通，此外福冈市地下铁室见至天神段也投入运行、北九州单轨列车也开始运行、交通网络得到了全面发展。 <br/>
        此外，新北九州机场（现北九州机场）于 2006 年启用，九州新干线博多至鹿儿岛中央线于 2011 年启用，北九州市至宫崎市的东九州高速公路于 2016 年启用。<br/>
        2005 年，九州国立博物馆作为日本国内第四个国立博物馆开馆。2015 年，与煤矿开采、钢铁工业和造船业等相关的设施作为 「明治日本的工业革命遗产：钢铁制造、造船业和煤炭工业」被联合国教育科学文化组织（UNESCO）列为世界文化遗产。2017年，「『神灵之居岛』 宗像·冲之岛及相关遗产群」也被列为世界文化遗产。<br/>
        在社会发生巨大变革的同时，福冈县怀着促进亚洲乃至全球交流的目标，通过建设重要的交通基础设施，吸引新兴产业，开发先进技术等等，克服了各种困难，实现了产业和文化等各方面的发展。 作为支撑日本未来发展的九州的领头县，九州正朝着更加跃进的方向迈进。<br/>
        </p>
        </Article>
    </>)
}

export default LangZhCn