
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import { AreaMap } from "./areaMap"
const SSvg = styled.div`
      
`

const SOverride = styled.div`
@media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
(max-width: ${props => props.theme.responsive.minPc}) ),
screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
    .fukidashiPosition{
        &.kitakyu{
            top: 18% !important;
        }
        &.chikuhou{
            bottom: 46% !important;
        }
        &.chikugo{
            bottom: 42% !important;
        }
    }
}
`

const LangZhCn = ({onSwich, onFukidashi}) => {

    return (
        <SOverride>

            <div className="area-info">
            <SSvg>
                <figure className="map">
                <svg id="_レイヤー_1" data-name="レイヤー 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.92 286.92">
                    <rect className="fukuoka" onClick={() => {onSwich(4)}} x="11.44" y="58.23" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(97.1 140.13) rotate(180)"/>
                    <g onClick={() => {onSwich(4)}}>
                        <path className="moji" d="M28.8,70.54h6.28v5.29h-.86v-.53h-4.6v.57h-.83v-4.55l-.51.73c-.25-.33-.83-.95-1.27-1.4v5.19h-.87v-4.6c-.53.57-1.08,1.06-1.63,1.43-.08-.23-.33-.64-.46-.81,1.32-.86,2.71-2.38,3.48-3.98h-3.04v-.83h1.65v-2.26h.87v2.26h.97l.17-.04.5.33c-.34.9-.84,1.77-1.41,2.56.42.33,1.3,1.13,1.55,1.38v-.75ZM35.16,65.38v.78h-6.41v-.78h6.41ZM34.52,66.97v2.79h-5.09v-2.79h5.09ZM29.62,71.3v1.25h1.84v-1.25h-1.84ZM29.62,74.55h1.84v-1.3h-1.84v1.3ZM33.69,67.7h-3.46v1.35h3.46v-1.35ZM34.22,71.3h-1.94v1.25h1.94v-1.25ZM34.22,74.55v-1.3h-1.94v1.3h1.94Z"/>
                        <path className="moji" d="M47.16,74.64c0,.59-.17.86-.58,1.02-.41.15-1.09.16-2.24.16-.05-.25-.22-.66-.36-.9.84.04,1.69.02,1.92.01.25,0,.34-.07.34-.3v-8.31h-7.91v9.56h-.91v-10.44h9.75v9.21ZM44.6,73.94c-.49-.71-1.21-1.59-2.05-2.51-.9,1.14-1.88,2.15-2.88,2.95-.18-.16-.56-.42-.79-.56,1.1-.8,2.15-1.83,3.06-3.02-.89-.91-1.87-1.83-2.77-2.6l.63-.51c.87.72,1.81,1.57,2.66,2.4.69-.97,1.29-2.01,1.77-3.09l.87.27c-.58,1.21-1.26,2.38-2.03,3.45.9.95,1.69,1.84,2.2,2.57l-.68.65Z"/>
                        <path className="moji" d="M51.59,72.06c.45-.19.91-.4,1.38-.58l.18.8c-1.29.59-2.66,1.2-3.72,1.67l-.35-.9c.45-.16,1.03-.39,1.66-.66v-3.85h-1.55v-.86h1.55v-2.75h.85v2.75h1.42v.86h-1.42v3.51ZM58.58,74.78c.7,0,.82-.33.91-1.72.22.16.56.3.81.35-.15,1.65-.42,2.18-1.7,2.18h-2.97c-1.37,0-1.78-.33-1.78-1.62v-3.82l-.96.4-.35-.79,1.31-.55v-3.3h.86v2.92l1.63-.68v-3.37h.86v3.01l1.6-.67.19-.17.17-.13.63.24-.05.17c0,2.27-.02,4.08-.12,4.54-.06.5-.3.71-.64.8-.34.08-.8.08-1.14.08-.04-.24-.11-.58-.22-.78.29.01.68.01.81.01.18,0,.3-.05.36-.32.06-.29.08-1.64.08-3.57l-1.69.72v4.45h-.86v-4.09l-1.63.69v4.2c0,.64.16.8.95.8h2.92Z"/>
                        <path className="moji" d="M65.12,72.61c-1.1.57-2.29,1.17-3.23,1.63l-.35-.89c.39-.16.85-.36,1.36-.59v-4.17h-1.26v-.85h1.26v-2.8h.85v2.8h1.1v.85h-1.1v3.77c.36-.17.75-.36,1.13-.55l.24.8ZM69.01,73.34c-1.44.42-2.96.86-4.11,1.19l-.24-.85c1.09-.24,2.7-.69,4.27-1.1l.08.76ZM72.32,68.71c-.44,1.59-1.02,2.98-1.77,4.18.29,1.32.66,2.11,1.1,2.11.23,0,.34-.47.39-1.74.18.21.45.38.64.47-.13,1.61-.38,2.12-1.14,2.12s-1.26-.78-1.64-2.07c-.59.78-1.29,1.43-2.05,1.98-.13-.16-.47-.45-.67-.59.98-.62,1.79-1.44,2.46-2.4-.28-1.46-.44-3.31-.51-5.36h-4.08v-.84h4.05c-.02-.58-.04-1.18-.04-1.77h.87c0,.61,0,1.2.01,1.77h1.91c-.3-.36-.92-.91-1.44-1.3l.52-.45c.53.35,1.18.87,1.48,1.25l-.53.5h.64v.84h-2.55c.05,1.61.17,3.07.34,4.26.5-.95.87-1.98,1.17-3.12l.82.16ZM68.42,68.55v3.49h-3.01v-3.49h3.01ZM67.68,69.28h-1.56v2.04h1.56v-2.04Z"/>
                    </g>
                    <rect className="kitakyu" onClick={() => {onSwich(1)}} x="189.13" y="10.6" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(452.46 44.87) rotate(180)"/>
                    <g>
                        <path className="moji" d="M199.17,17.39h.91v10.74h-.91v-2.72c-1.09.47-2.18.93-3.06,1.3l-.42-.91c.89-.28,2.18-.79,3.48-1.29v-3.4h-3.11v-.89h3.11v-2.84ZM205.43,27.08c.56,0,.64-.52.73-2.52.22.18.61.36.86.42-.11,2.2-.35,2.98-1.53,2.98h-1.94c-1.13,0-1.44-.36-1.44-1.65v-8.92h.91v4c1.13-.68,2.27-1.48,3-2.17l.67.78c-1.01.8-2.39,1.61-3.66,2.33v3.99c0,.63.11.75.63.75h1.78Z"/>
                        <path className="moji" d="M218.19,27.18c.34,0,.4-.29.44-1.75.21.18.59.33.84.4-.08,1.69-.32,2.23-1.21,2.23h-1.43c-.93,0-1.23-.29-1.23-1.39v-5.52h-2.78c-.25,3.03-1.1,5.6-4.03,7.08-.12-.22-.42-.55-.67-.72,2.74-1.3,3.53-3.62,3.76-6.37h-3.2v-.9h3.26c.04-.95.04-1.94.04-2.96h.96c-.01,1.01-.01,2-.06,2.96h3.26s.36.01.36.01v6.42c0,.42.06.5.45.5h1.24Z"/>
                        <path className="moji" d="M222.41,20.35c-.22,1.08-.57,2.46-1.13,3.31l-.76-.34c.55-.84.95-2.12,1.13-3.19l.76.22ZM223.89,17.38v3.74c0,2.47-.24,5.12-2.39,7.11-.15-.22-.46-.52-.66-.68,1.95-1.81,2.16-4.21,2.16-6.43v-3.74h.88ZM224.93,20.34c.42.97.84,2.22.96,3l-.79.34c-.11-.79-.49-2.07-.91-3.05l.74-.29ZM227.32,17.63v9.78h-.89v-9.78h.89ZM228.29,20.23c.57.93,1.16,2.17,1.38,2.95l-.76.4c-.21-.79-.76-2.06-1.31-3.01l.69-.34ZM230.93,17.33v10.76h-.9v-10.76h.9Z"/>
                        <path className="moji" d="M235.49,24.43c.45-.19.91-.4,1.38-.58l.18.8c-1.29.59-2.66,1.2-3.72,1.67l-.35-.9c.45-.16,1.03-.39,1.66-.66v-3.84h-1.55v-.86h1.55v-2.75h.85v2.75h1.42v.86h-1.42v3.5ZM242.47,27.16c.7,0,.83-.33.91-1.72.22.16.56.3.81.35-.15,1.65-.42,2.18-1.7,2.18h-2.97c-1.37,0-1.78-.33-1.78-1.63v-3.82l-.96.4-.35-.79,1.31-.55v-3.3h.86v2.92l1.62-.68v-3.37h.86v3.01l1.6-.67.19-.17.17-.13.63.24-.05.17c0,2.27-.02,4.08-.12,4.54-.06.5-.3.72-.64.8-.34.08-.8.08-1.14.08-.04-.24-.11-.58-.22-.78.29.01.68.01.81.01.18,0,.3-.05.36-.32.06-.29.09-1.64.09-3.57l-1.69.72v4.45h-.86v-4.09l-1.62.69v4.2c0,.64.16.8.95.8h2.92Z"/>
                        <path className="moji" d="M249.02,24.98c-1.1.57-2.29,1.16-3.23,1.62l-.35-.88c.39-.16.85-.36,1.36-.59v-4.17h-1.26v-.85h1.26v-2.8h.85v2.8h1.1v.85h-1.1v3.77c.36-.17.75-.36,1.13-.55l.24.8ZM252.9,25.71c-1.44.42-2.96.86-4.11,1.19l-.24-.85c1.09-.24,2.7-.69,4.27-1.1l.08.76ZM256.21,21.08c-.44,1.59-1.02,2.98-1.77,4.19.29,1.32.66,2.11,1.1,2.11.23,0,.34-.47.39-1.73.18.21.45.38.64.47-.13,1.61-.38,2.12-1.14,2.12s-1.26-.78-1.64-2.07c-.59.78-1.29,1.43-2.05,1.98-.13-.16-.47-.45-.67-.59.98-.62,1.8-1.44,2.46-2.4-.28-1.46-.44-3.31-.51-5.36h-4.08v-.84h4.05c-.02-.58-.04-1.18-.04-1.77h.87c0,.61,0,1.2.01,1.77h1.9c-.3-.36-.92-.91-1.44-1.3l.52-.45c.53.35,1.18.87,1.48,1.25l-.53.5h.64v.84h-2.55c.05,1.61.17,3.07.34,4.26.5-.95.87-1.98,1.16-3.12l.83.16ZM252.32,20.92v3.49h-3.01v-3.49h3.01ZM251.58,21.65h-1.57v2.04h1.57v-2.04Z"/>
                    </g>
                    
                    <rect className="chikugo" onClick={() => {onSwich(2)}}  x="11.44" y="185.61" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(97.1 394.9) rotate(180)"/>
                    <g>
                        <path className="moji" d="M26.34,194.25c-.39.74-.86,1.38-1.33,1.88-.17-.13-.53-.39-.75-.51.9-.82,1.64-2.13,2.05-3.51l.86.22c-.12.39-.28.78-.45,1.14h3.02v.78h-1.83c.24.5.47,1.06.57,1.43l-.79.33c-.11-.47-.38-1.18-.68-1.76h-.67ZM28.91,200.63c-1.54.36-3.19.73-4.39.99l-.19-.84c.53-.11,1.2-.24,1.94-.4v-3.26h-1.67v-.81h4.21v.81h-1.66v3.09l1.7-.36.07.78ZM34.39,202.24c.1,0,.19-.01.25-.05.05-.04.11-.1.13-.23.02-.15.04-.46.05-.87.18.15.45.28.69.35-.01.42-.06.9-.12,1.08-.08.18-.19.3-.35.39-.15.07-.39.11-.58.11h-.62c-.23,0-.5-.06-.68-.22-.18-.17-.25-.35-.25-1.19v-4.63h-2.63v1.89c0,1.48-.38,3.25-2.41,4.37-.11-.18-.44-.53-.59-.66,1.87-1.01,2.13-2.45,2.13-3.74v-2.68h4.38v5.52c0,.28.01.45.07.5.05.04.11.06.19.06h.34ZM31.16,194.25c-.32.68-.68,1.29-1.09,1.76-.16-.15-.53-.4-.75-.51.75-.81,1.33-2.05,1.66-3.35l.86.21c-.11.38-.23.75-.35,1.12h3.69v.78h-2.2c.35.47.67,1.01.83,1.38l-.8.32c-.18-.45-.58-1.14-.98-1.7h-.86ZM31.03,198.25c.65.63,1.44,1.49,1.78,2.07l-.69.51c-.34-.58-1.09-1.49-1.75-2.13l.66-.45Z"/>
                        <path className="moji" d="M47.84,195.51v.87h-8.89v.12c0,1.93-.18,4.77-1.61,6.76-.12-.18-.46-.5-.7-.61,1.29-1.82,1.42-4.31,1.42-6.16v-3.38l.92.18c2.6-.21,5.46-.57,7.11-1.01l.75.72c-2.07.53-5.14.89-7.88,1.09v1.42h8.89ZM40.01,198.1h6.77v5.13h-.89v-.61h-5.02v.63h-.86v-5.16ZM40.87,198.95v2.85h5.02v-2.85h-5.02Z"/>
                        <path className="moji" d="M51.59,199.44c.45-.19.91-.4,1.38-.58l.18.8c-1.29.59-2.66,1.2-3.72,1.67l-.35-.9c.45-.16,1.03-.39,1.66-.66v-3.84h-1.55v-.86h1.55v-2.75h.85v2.75h1.42v.86h-1.42v3.5ZM58.58,202.17c.7,0,.82-.33.91-1.72.22.16.56.3.81.35-.15,1.65-.42,2.18-1.7,2.18h-2.97c-1.37,0-1.78-.33-1.78-1.62v-3.82l-.96.4-.35-.79,1.31-.55v-3.3h.86v2.92l1.63-.68v-3.37h.86v3.01l1.6-.67.19-.17.17-.13.63.24-.05.17c0,2.27-.02,4.08-.12,4.54-.06.5-.3.71-.64.8-.34.08-.8.08-1.14.08-.04-.24-.11-.58-.22-.78.29.01.68.01.81.01.18,0,.3-.05.36-.32.06-.29.08-1.64.08-3.57l-1.69.72v4.45h-.86v-4.09l-1.63.69v4.2c0,.64.16.8.95.8h2.92Z"/>
                        <path className="moji" d="M65.12,200c-1.1.57-2.29,1.16-3.23,1.62l-.35-.89c.39-.16.85-.36,1.36-.59v-4.17h-1.26v-.85h1.26v-2.8h.85v2.8h1.1v.85h-1.1v3.77c.36-.17.75-.36,1.13-.55l.24.8ZM69.01,200.72c-1.44.42-2.96.86-4.11,1.19l-.24-.85c1.09-.24,2.7-.69,4.27-1.1l.08.76ZM72.32,196.09c-.44,1.59-1.02,2.98-1.77,4.18.29,1.32.66,2.11,1.1,2.11.23,0,.34-.47.39-1.73.18.21.45.38.64.47-.13,1.61-.38,2.12-1.14,2.12s-1.26-.78-1.64-2.07c-.59.78-1.29,1.43-2.05,1.98-.13-.16-.47-.45-.67-.59.98-.62,1.79-1.44,2.46-2.4-.28-1.46-.44-3.31-.51-5.36h-4.08v-.84h4.05c-.02-.58-.04-1.18-.04-1.77h.87c0,.61,0,1.2.01,1.77h1.91c-.3-.36-.92-.91-1.44-1.3l.52-.45c.53.35,1.18.87,1.48,1.25l-.53.5h.64v.84h-2.55c.05,1.61.17,3.07.34,4.26.5-.95.87-1.98,1.17-3.12l.82.16ZM68.42,195.93v3.49h-3.01v-3.49h3.01ZM67.68,196.66h-1.56v2.04h1.56v-2.04Z"/>
                    </g>
                    <AreaMap onSwich={onSwich}/>
                    <rect className="chikuhou" onClick={() => {onSwich(3)}} x="203.28" y="173.27" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(480.77 370.22) rotate(180)"/>
                    <g>
                        <path className="moji" d="M218.17,181.9c-.39.74-.86,1.38-1.33,1.88-.17-.13-.53-.39-.75-.51.9-.83,1.64-2.13,2.05-3.51l.86.22c-.12.39-.28.78-.45,1.14h3.02v.78h-1.83c.24.5.47,1.06.57,1.43l-.79.33c-.11-.47-.38-1.18-.68-1.76h-.67ZM220.74,188.29c-1.54.36-3.19.73-4.39,1l-.19-.84c.53-.11,1.2-.24,1.94-.4v-3.26h-1.67v-.81h4.21v.81h-1.66v3.09l1.7-.36.07.78ZM226.23,189.9c.1,0,.19-.01.25-.05.05-.04.11-.1.13-.23.02-.15.04-.46.05-.87.18.15.45.28.69.35-.01.42-.06.9-.12,1.08-.08.18-.19.3-.35.39-.15.07-.39.11-.58.11h-.62c-.23,0-.5-.06-.68-.22-.18-.17-.25-.35-.25-1.19v-4.63h-2.63v1.89c0,1.48-.38,3.25-2.41,4.37-.11-.18-.44-.53-.59-.66,1.87-1.01,2.13-2.45,2.13-3.74v-2.68h4.38v5.52c0,.28.01.45.07.5.05.04.11.06.19.06h.34ZM223,181.9c-.32.68-.68,1.29-1.09,1.76-.16-.14-.53-.4-.75-.51.75-.81,1.33-2.05,1.66-3.35l.86.21c-.11.38-.23.75-.35,1.12h3.69v.78h-2.2c.35.47.67,1.01.83,1.38l-.8.31c-.18-.45-.58-1.14-.98-1.7h-.86ZM222.87,185.91c.66.63,1.44,1.49,1.78,2.08l-.69.51c-.34-.58-1.09-1.49-1.75-2.13l.66-.45Z"/>
                        <path className="moji" d="M239.51,187.99h-4.94v2.89h-.93v-2.89h-4.9v-.89h4.9v-1.95h-3.86v-.86h3.86v-1.8h-4.45v-.89h4.45v-1.77h.93v1.77h4.45v.89h-4.45v1.8h3.89v.86h-3.89v1.95h4.94v.89Z"/>
                        <path className="moji" d="M243.42,187.1c.45-.19.91-.4,1.38-.58l.18.8c-1.29.59-2.66,1.2-3.72,1.67l-.35-.9c.45-.16,1.03-.39,1.66-.65v-3.85h-1.55v-.86h1.55v-2.75h.85v2.75h1.42v.86h-1.42v3.51ZM250.41,189.83c.7,0,.83-.33.91-1.72.22.16.56.3.81.35-.15,1.65-.42,2.18-1.7,2.18h-2.97c-1.37,0-1.78-.33-1.78-1.63v-3.82l-.96.4-.35-.79,1.31-.55v-3.3h.86v2.92l1.62-.68v-3.37h.86v3.01l1.6-.67.19-.17.17-.13.63.24-.05.17c0,2.27-.02,4.08-.12,4.54-.06.5-.3.72-.64.8-.34.08-.8.08-1.14.08-.04-.24-.11-.58-.22-.78.29.01.68.01.81.01.18,0,.3-.05.36-.31.06-.29.08-1.64.08-3.57l-1.69.72v4.45h-.86v-4.09l-1.62.69v4.2c0,.64.16.8.95.8h2.92Z"/>
                        <path className="moji" d="M256.96,187.65c-1.1.57-2.29,1.16-3.23,1.63l-.35-.89c.39-.16.85-.36,1.36-.59v-4.17h-1.26v-.85h1.26v-2.8h.85v2.8h1.1v.85h-1.1v3.77c.36-.17.75-.36,1.13-.54l.24.8ZM260.84,188.38c-1.44.42-2.96.86-4.11,1.19l-.24-.85c1.09-.24,2.71-.69,4.27-1.1l.08.76ZM264.15,183.75c-.44,1.59-1.02,2.98-1.77,4.18.29,1.32.66,2.11,1.1,2.11.23,0,.34-.47.39-1.73.18.21.45.38.64.47-.13,1.61-.38,2.12-1.14,2.12s-1.26-.78-1.64-2.07c-.59.78-1.29,1.43-2.05,1.98-.13-.16-.47-.45-.67-.59.98-.62,1.8-1.44,2.46-2.4-.28-1.46-.44-3.31-.51-5.36h-4.08v-.84h4.05c-.02-.58-.04-1.18-.04-1.77h.87c0,.61,0,1.2.01,1.77h1.9c-.3-.36-.92-.91-1.44-1.3l.52-.45c.53.35,1.18.87,1.48,1.25l-.53.5h.64v.84h-2.55c.05,1.61.17,3.07.34,4.26.5-.95.87-1.98,1.16-3.12l.83.16ZM260.26,183.59v3.49h-3.01v-3.49h3.01ZM259.52,184.32h-1.57v2.04h1.57v-2.04Z"/>
                    </g>
                    </svg>
                </figure>
            </SSvg>
                <div className={`fukidashiPosition kitakyu ${onFukidashi === 1 ? 'active' : ''}`}>
                    <div className="fukidashi kitakyu">
                        <figure>
                        <img src={publicPath('images/basicinfo_area/zhcn/kitakyu.svg')} className="fit-img"
                        alt={`由于钢铁，汽车，先端半导体，机器人等产业的高度发展，这些通过长时间积累起来的"制造技术"的充分利用激发了该地域的生机和活力。该地域还拥有可以24小时不间断运转的北九州机场，其作为亚洲物流的重要据点一系列的基础设施建设也正在积极推进中。 24小時營運的北九州機場，正在鞏固為亞洲的物流基地。`} />
                        </figure>
                        <span className="speak"/>
                    </div>
                </div>
                <div className={`fukidashiPosition fukuoka ${onFukidashi === 4 ? 'active' : ''}`}>
                    <div className="fukidashi fukuoka">
                        <figure>
                        <img src={publicPath('images/basicinfo_area/zhcn/fukuoka.svg')} className="fit-img"
                         alt={`九州的中央行政管理機能和第三產業的日益集中，今已發展成為日本西部的領先地區。九州新幹線、福岡機場、博多港等各樣交通基礎設施不斷地興建發展。`} />                        
                         </figure>
                        
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition chikugo ${onFukidashi === 2 ? 'active' : ''}`}>
                    <div className="fukidashi chikugo">
                    
                    <figure>
                    <img src={publicPath('images/basicinfo_area/zhcn/chikugo.svg')} className="fit-img"
                         alt={`这是一个拥有丰富的自然景观和农林水产业，当地产业，工商业等的丰富多彩的产业，文化，以及个性各异都市群等的充满魅力的地域。由于这些个性丰富的都市将他们各自之间的职能不断进行串联，互补，筑后网状田园都市构想得以不断被推进。`} />
                    </figure>
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition chikuhou ${onFukidashi === 3 ? 'active' : ''}`}>
                    <div className="fukidashi chikuhou">
                    <figure>
                        <img src={publicPath('images/basicinfo_area/zhcn/chikuho.svg')} className="fit-img"
                         alt={`作为代替煤炭产业的新兴产业，汽车产业的地位在不断上升，为支撑汽车产业的人材养成项目也在如火如荼地进行中。以吸引新创企业以及研究机关向理工科大学周边聚集为目的，新兴产业创设据点的建设正在不断推进中。`} /> 
                        </figure>
                    
                        <span className="speak"></span>
                    </div>
                </div>
            </div>
        </SOverride>
    )
}

export default LangZhCn