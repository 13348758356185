import publicPath from "src/utilty/publicPath"
import styled from "styled-components"


const SBackButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    img{
        transition: transform 0.3s ease; /* アニメーションの効果を指定 */
    }
    &:hover{
        img{
            transform: translateX(-10px); /* 左に10px動かす */
        }
    }
    
`
const BackButton = ({
    onClick,
    ...props
} : {
    onClick : () => void
}) => {

    return (
        <SBackButton onClick={onClick}>
           <img src={publicPath('images/backLink.png')} className="img-fit"/>
        </SBackButton>
    )
}

export default BackButton