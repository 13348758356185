import { ChallengerQ } from "@/components/Atoms/Character/challenger";
import { NavigatorCheck, NavigatorNice, NavigatorPickUp } from "@/components/Atoms/Character/navigator";
import { FukidashiBlue, FukidashiBlueBottom } from "@/components/Atoms/Fukidashi/fukidashiBlue";
import { PropsWithChildren } from "react";
import { direction } from "src/types/common";
import styled from "styled-components";


const SWrap = styled.div`
    position: relative;
    width: 100%;
`

export const NavigatorFukidashiCheck = ({
    dir,
    children
}:PropsWithChildren<{dir: direction}>) =>{

    const SCheck = styled(SWrap)`
        &.right{
            padding-top: 28%;
            .charactor{
                position: absolute;
                top: 0;
                left: 0px;
                width: 75%;
                z-index: 2;
            }
        }
        &.left{
            padding-top: 25%;
            .charactor{
                position: absolute;
                top: 0;
                right: 10px;
                width: 74%;
                z-index: 2;
            }
        }
        .fukidashi{
        
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 1;
        }
    `
    
    return (
        <SCheck className={dir}>
            <div className={`charactor`} >
                <NavigatorCheck dir={dir}></NavigatorCheck>
            </div>
            <div className="fukidashi">
                <FukidashiBlue dir={dir}>
                    {children}
                </FukidashiBlue>
            </div>
        </SCheck>
    )

}

export const NavigatorFukidashiPicup = ({
    dir,
    children
}:PropsWithChildren<{dir: direction}>) =>{

    const SPickUp = styled(SWrap)`
        width: 100%;
        padding-bottom: 30%;
        .charactor{
            width: 86%;
            position: absolute;
            bottom: -20px;
            z-index: 11;
        }
    `
    return (
        <SPickUp className={dir}>
            <div className="fukidashi">
                <FukidashiBlueBottom dir={dir}>
                    {children}
                </FukidashiBlueBottom>
            </div>
            <div className={`charactor`} >
                <NavigatorPickUp dir={dir}></NavigatorPickUp>
            </div>
        </SPickUp>
    )

}