const LangTh = () => {

    return (<>
    <h1>เกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล</h1>
    <p>
    เว็บไซต์พอร์ทัลนี้ (<a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a>) รวมรวบ ใช้งาน และจัดการข้อมูลส่วนบุคคลอย่างเหมาะสมตาม "กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล" และ การบังคับใช้กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลของจังหวัดฟุกุโอกะ"" (ตามเทศบัญญัติของจังหวัดฟุกุโอกะฉบับที่ 43 ปีเรวะที่ 4 (พ.ศ. 2565)) อนึ่ง เนื้อหาเกี่ยวกับการจัดการข้อมูลส่วนบุคคลนี้ได้รับการปรับเปลี่ยนแก้ไขล่าสุดเมื่อวันที่ 1 เมษายน พ.ศ.2566
    </p>
    <p>
    สามารถไปยังหน้าแสดงรายละเอียดระบบคุ้มครองข้อมูลส่วนบุคคลของจังหวัดฟุกุโอกะได้ตามลิงก์นี้ (<a href="http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html" target="_blank">http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html</a>)
    </p>
    <p>
    <dl>
    <dt>●ข้อมูลส่วนบุคคลคืออะไร?</dt>
    <dd>
    ข้อมูลเกี่ยวกับบุคคล หรือข้อมูลที่สามารถใช้เพื่อระบุตัวบุคคลโดยเฉพาะ (ที่อยู่, ชื่อ, หมายเลขโทรศัพท์ หรือที่อยู่อีเมล เป็นต้น)
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●เกี่ยวกับการเก็บรวบรวมข้อมูลส่วนบุคคล</dt>
    <dd>
    เมื่อคณะกรรมการดำเนินการโครงการผลักดัน FUKUOKA IS OPEN (ต่อไปนี้จะเรียกว่า ""คณะกรรมการดำเนินการ"") จะทำการรวบรวมข้อมูลส่วนบุคคลผ่านทางเว็บไซต์พอร์ทัลนี้ จะชี้แจงวัตถุประสงค์ของการรวบรวมและรวบรวมข้อมูลส่วนบุคคลตามขอบเขตที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ดังกล่าว
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●รับประกันความปลอดภัย</dt>
    <dd>
    เราจะจัดการข้อมูลส่วนบุคคลที่เก็บรวบรวมอย่างเคร่งครัดเพื่อป้องกันการรั่วไหล สูญหาย หรือปลอมแปลง อีกทั้งจะทำการทำลาย(ลบ)ข้อมูลส่วนบุคคลที่ไม่มีความจำเป็นต้องเก็บอีกต่อไปทันที
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●เกี่ยวกับคุกกี้ (Cookie)</dt>
    <dd>
    <p>มีการใช้คุกกี้ (Cookie) เพื่อสนับสนุนการทำงานของหน้าเว็บไซต์บางส่วน</p>
    <p>
    "คุกกี้ (Cookie)" คือไฟล์ข้อความขนาดเล็กซึ่งเป็นเทคโนโลยีมาตรฐานอุตสาหกรรมที่เว็บเซิร์ฟเวอร์ใช้เพื่อระบุคอมพิวเตอร์ของท่าน"
    </p>
    <p>
    โดยทั่วไปจะถูกใช้เพื่อวัตถุประสงค์ในการบันทึกข้อมูลส่วนบุคคล (ID ผู้ใช้และรหัสผ่าน) ประวัติการดำเนินการของผู้ใช้ที่เคยให้ไว้ผ่านเว็บไซต์พอร์ทัล วิเคราะห์สถานะและแนวโน้มการเข้าถึง รวมทั้งใช้เป็นข้อมูลทางสถิติ
    </p>
    <p>
    อนึ่ง แม้ว่า "คุกกี้ (Cookie)" จะสามารถระบุคอมพิวเตอร์ของผู้ใช้ได้ แต่ก็ไม่สามารถระบุตัวบุคคลโดยเฉพาะได้ เว้นแต่ผู้ใช้จะป้อนข้อมูลหรือให้ข้อมูลส่วนบุคคลโดยละเอียด
    </p>
    <p>
    เว็บไซต์พอร์ทัลนี้สามารถดูและใช้งานได้โดยไม่เปิดเผยตัวตน ไม่ว่าจะมีหรือไม่มีฟังก์ชัน "คุกกี้ (Cookie)"
    </p>
    <p>
    ท่านสามารถปิดการใช้งานฟังก์ชั่นคุกกี้ได้โดยเปลี่ยนการตั้งค่าในเบราว์เซอร์ แต่ผลที่ตามมาอาจทำให้ไม่สามารถใช้บริการทั้งหมดหรือบางส่วนในบางหน้าได้"
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●ข้อจำกัดในการใช้และการให้ข้อมูล</dt>
    <dd>
    <p>
    ข้อมูลส่วนบุคคลที่รวบรวมโดยคณะกรรมการดำเนินการจะไม่ถูกนำไปใช้เพื่อวัตถุประสงค์ที่นอกเหนือจากการรวบรวม อีกทั้งจะไม่มีการมอบให้กับบุคคลอื่นนอกเหนือจากคณะกรรมการดำเนินการ ยกเว้นมีกำหนดในกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลหรือการบังคับใช้กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลของจังหวัดฟุกุโอกะ
    </p>
    <p>
    อย่างไรก็ตาม ข้อมูลการเข้าถึงของเว็บไซต์พอร์ทัลนี้ ข้อเสนอแนะหรือความคิดเห็นถึงคณะกรรมการดำเนินการ รวมถึงคุณลักษณะส่วนบุคคลที่เกี่ยวข้องกับสิ่งเหล่านี้ อาจถูกเปิดเผยในรูปแบบข้อมูลที่ถูกประมวลผลทางสถิติหรือความคิดเห็นได้
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●หมายเหตุเกี่ยวกับปุ่มโซเชียลมีเดีย</dt>
    <dd>
    <p>
    เมื่อดูหน้าเว็บบนเว็บไซต์พอร์ทัลนี้ที่มีปุ่มโซเชียลมีเดียติดตั้งอยู่ ข้อมูลเกี่ยวกับผู้ดูอาจถูกส่งไปยังโซเชียลมีเดียเหล่านั้น โปรดตรวจสอบนโยบายความเป็นส่วนตัวของโซเชียลมีเดียและบริการอื่นๆ ที่ใช้บนเว็บไซต์พอร์ทัลนี้บนเว็บไซต์ของแต่ละโซเชียลมีเดียและบริการที่เกี่ยวข้อง
    </p>
    </dd>
    </dl>
    </p>
    <p>
        <dl>
            <dt>●หมายเหตุเกี่ยวกับ Google Analytics</dt>
            <dd>
            เว็บไซต์พอร์ทัลนี้ใช้ Google Analytics เพื่อทำความเข้าใจสถานการณ์การใช้งานของพอร์ทัลไซต์ Google Analytics ใช้คุกกี้ในการรวบรวมข้อมูลผู้ใช้ สามารถดูข้อกำหนดในการให้บริการของ Google Analytics ได้ที่นี่<br/>
        (จะเปิดหน้าต่างใหม่  <a href=" https://www.google.com/analytics/terms/jp.html" target="_blank">https://www.google.com/analytics/terms/jp.html </a>)
            </dd>
        </dl>
    </p>
    <p>
        <dl>
            <dt>●ขอบเขตการใช้งาน</dt>
            <dd>
            นโยบายความเป็นส่วนตัวนี้ใช้กับเว็บไซต์พอร์ทัลนี้เท่านั้น (ไดเร็กทอรีภายใต้ <a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a>)
            </dd>
        </dl>
    </p>
    </>)
}

export default LangTh