


const LangZhTw = () => {

    return (
        <div className='article-inner'>
        <section>
        <h1>九州的徽標</h1>
        <ul className="photos">
            <li>
                <img src="./images/kyushulogomark/logomark01.jpg" alt="The Kyushu Logo"/>
            </li>
            <li>
                <img src="./images/kyushulogomark/logomark02.jpg" alt="The Kyushu Logo ※のれん"/>
            </li>
        </ul>
        <p>
        該徽標用於在全國乃至全世界宣傳九州。九州徽標以門簾*為主題，表達了通過九州門簾歡迎大家的願望。 它由漢字 "九"、"州 "和 "一 "組成，代表九州的多個地區團結一致。<br/>
        ※門簾<br/>
        門簾是掛在商店門口的遮陽布。這是日本特有的文化，上麵經常會展示商店的名稱、商標。
        </p>
        <br/>
        </section>
        <section>
        <h1>九州簡介</h1>
        <figure>
        <img src="./images/kyushulogomark/kyushu.jpg" alt="九州について"/>
        </figure>
        <p>
        九州位於日本的最西端，是一個可以盡情享受雄大山海、美麗自然、以及溫泉、歷史、文化和美食的地方。 更多信息，請觀看視頻。
        </p>
        
        
        <br/>
        <div className="youtube">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/5GNHVfoQd0s?si=JCxXRHx1bwIt-3EO" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        </section>
        <section>
            <h1>九州徽標在海外使用的狀況</h1>
            <p>九州徽標也在海外使用。</p>
            <p><strong>◎BOKKSU株式會社</strong></p>
            <p> “九州果子BOX”文化指南中登載有九州徽標</p>
            <figure>
            <p className="note">“九州點心盒”薈萃了九州的各種點心，並配有說明書，介紹點心、九州歷史、文化和旅遊景點</p>

            <img src="./images/kyushulogomark/logomark-bkksu.jpg" alt="BOKKUS"/>
            </figure>
            <p><strong>◎株式會社MARUTAI</strong></p>
            <p>“棒拉麵當地係列”（共 8 種產品）的包裝背面印有九州徽標</p>
            <figure>
            <img src="./images/kyushulogomark/logomark-marutai.jpg" alt="Marutai"/>
            </figure>
            <p className="note">博多豬骨拉麵、熊本黑MA油豬骨拉麵、鹿兒島黑豬豬骨拉麵</p>
            
            <p><strong>◎株式會社DAISHO</strong></p>
            <p>“山藥鐵板燒套餐”的包裝背面印有九州徽標</p>
            <figure>
            <img src="./images/kyushulogomark/yamaimoteppan.jpg" alt="山芋鉄板焼きの素"/>
            </figure>
        </section>

        <div className="deco-fukidashi navigatorCheck right smile">
        有關九州的詳細旅遊信息，請參閱“Visit Kyushu”。
        </div>
       
        <p>Visit Kyushu</p>
        <a href="https://www.welcomekyushu.com/" target="_blank">
            <figure>
            <img src='./images/banner/tabinet.png' alt="Visit Kyushu（九州旅ネット）" className="img-fit"/>
            </figure>
        </a>
        </div>
    )
}

export default LangZhTw;