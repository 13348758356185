import SeachForm from "@/components/Molecules/SearchForm"
import { Emergency } from "../Emergency"
import styled from "styled-components"
import { useEffect, useRef } from "react"

export const HeaderPc = () => {
    const ref = useRef<HTMLElement>()
    const handleScroll = () => {
        let top = ref.current.getBoundingClientRect().top;

        const point = window.scrollY - 200
        if (top <=  point ) {
            if( !ref.current.classList.contains('fixed')) ref.current.classList.add('fixed');
            
        } else {}
        
     }

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        
        // クリーンアップ関数
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    },[])

    return (
        <SHeader ref={el => ref.current = el}>
           
            <div className="searchHeader">
            <SeachForm></SeachForm>
            </div>
        </SHeader>
    )
   
}

const SHeader = styled.div`
    width: calc(100% - 250px);
    position: absolute;
    z-index: 999;
    right: 0;
    .searchHeader{
        margin-top: 20px;
        margin-right: 20px;
        width: 250px;
        top: 20px;
        right: 20px;
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: calc(100% - 300px);
    }
    &.fixed{
        position: fixed;
        top: 20px;
    }
`