import publicPath from "src/utilty/publicPath";


const LangTh = () => {

    return (<>
    <h1>เกี่ยวกับลิงก์</h1>
    <p>
    ●เกี่ยวกับลิงก์<br/>
    สามารถแปะลิงก์เชื่อมโยงมายังเว็บไซต์พอร์ทัลนี้ในหน้าหลักหรือหน้าบทความของโฮมเพจได้ไม่ว่าจะเป็นการใช้งานส่วนตัวหรือเพื่อวัตถุประสงเชิงพาณิชย์ เพียงแต่ต้องระบุอย่างชัดเจนว่ากำลังลิงก์มายังเว็บไซต์พอร์ทัลนี้<br/>
    หากมีการเชื่อมโยงลิงก์ โปรดส่งอีเมลมายัง <a href="mailto:intpol@pref.fukuoka.lg.jp" target="_blank">intpol@pref.fukuoka.lg.jp</a> เพื่อให้เราได้ตรวจสอบต้นทางของลิงก์ โดยโปรดระบุที่อยู่เว็บไซต์ที่เชื่อมโยงลิงก์, ชื่อ (สังกัด) และข้อมูลติดต่อ (ที่อยู่อีเมล) ในอีเมล
    </p>
    <p>
    ท่านสามารถใช้แบนเนอร์เพื่อเชื่อมโยงมายังเว็บไซต์พอร์ทัลของเราได้<br/>
    มีแบนเนอร์ 3 ประเภทให้เลือกดาวน์โหลดและใช้ตามต้องการ<br/>
    ทั้งนี้ โปรดระบุคำอธิบายรูปภาพแบนเนอร์ว่าเป็นลิงก์เพื่อไปยัง เว็บไซต์พอร์ทัลโครงการผลักดัน FUKUOKA IS OPEN
    </p>
    <p>Width 468 px × Height 60 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_468_60.png')}
    className="img-fit"/>
    </figure>
    </p>
    <p>Width 234 px × Height 60 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_234_60.png')}
    className="img-fit"/>
    </figure>
    </p>
    <p>Width 120 px × Height 90 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_120_90.png')}
    className="img-fit"/>
    </figure>
    </p>
    <br/>
    <br/>
    </>)
}

export default LangTh