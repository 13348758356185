import { langType } from "src/types/lang"
import { PathAnalysisValue } from "./pathAnalyzer"


export const easyJaToJa =  (lang:langType):langType => {

    return lang === 'easy_ja' ? 'ja' : lang
}   

export const tsutaeruToEastJa = (pathValue: PathAnalysisValue):langType => {

    return pathValue.tsutaeru ? 'easy_ja' : pathValue.lang
}