import Article from "@/components/Organisms/Article"
import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"


const SInternational = styled.div`
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .custom-link{
            max-width: 600px;
            figure{
                text-align: center;
               img{ max-width: 500px; }
            }
        }
    }
`

const LifeStyleInternational = ({lang}:{lang:langType}) => {

    setTitleDiscrption({
        layer:'consultation',
        lang:lang,
        subLayer:"international"
    })

    return (
        <SInternational>
            <Article offset={0} className="pc-noTopMargin">
            {lang === 'ja' &&
                <LangJa></LangJa>
            }
            {lang === 'easy_ja' &&
                <LangJa></LangJa>
            }
            {lang === 'en' &&
                <LangEn></LangEn>
            }
            {lang === 'ko' &&
                <LangKo></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh></LangTh>
            }
            {lang === 'vi' &&
                <LangVi></LangVi>
            }
            {lang === 'ne' &&
                <LangNe></LangNe>
            }
            </Article>
        </SInternational>
    )
    
}

export default LifeStyleInternational