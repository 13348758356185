import styled from "styled-components"


const STrapezoidTitle = styled.h1`
    padding:45px 10px 30px;
    text-align: center;
    color: #fff;
    font-size: ${props => props.theme.fontSize.h3_sp};
    line-height: 2.6rem;
    background: ${props => props.theme.colors.black};
    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 90%); 
    width: 100%;
    white-space: pre-wrap;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        position: relative;
        aspect-ratio: 10 / 1.5;
        padding: 0;
       
        .children{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            font-size: 3.0rem;
            letter-spacing: 0.2rem;
            line-height: 3.6rem;
        }
    }
`

const TrapezoidTitle = ({children}) => {


    return (
        <STrapezoidTitle>
            <span className="children">{children}</span>
        </STrapezoidTitle>
    )
}

export default TrapezoidTitle