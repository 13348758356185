
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import Article from "@/components/Organisms/Article"
import { SDecTextUl } from "."

const LangVi = () => {

    return (<>
     <Article offset={0}>
    <InnerBox>
    <p>Tại tỉnh Fukuoka có rất nhiều người nước ngoài đang sinh sống. Đứng thứ 9 trong các đô thị tại Nhật Bản về số lượng người nước ngoài đang sinh sống, là thành phố được nhiều người lựa chọn.</p>
    <h1>Tổng số người nước ngoài</h1>
    </InnerBox>
    <BlackNote>
    <SDecTextUl className="pc-flex col-2">
    <li>
    <p className="large">
    Tổng số người nước ngoài sống trong tỉnh
    </p>
    <p className="large">
    <span className="xl">89,518</span>người
    </p>
    <p className="middle">（thời điểm 12/2022）</p>
    </li>
    <li>
    <p className="large">
    Số người nước ngoài sống trong khu vực Fukuoka
    </p>
    <p className="large">
    <span className="xl">53,009</span>người
    </p>
    <p className="middle">（thời điểm 12/2022）</p>
    </li>
    <li>
    <p className="large">
    Số người nước ngoài sống trong khu vực KitaKyushu
    </p>
    <p className="large">
    <span className="xl">18,870</span>người
    </p>
    <p className="middle">（thời điểm 12/2022）</p>
    </li>
    <li>
    <p className="large">Số người nước ngoài sống trong khu vực Chikugo</p>
    <p className="large">
    <span className="xl">12,686</span>người
    </p>
    <p className="middle">（thời điểm 12/2022）</p>
    </li>
    <li>
    <p className="large">Số người nước ngoài sống trong khu vực Chikuho</p>
    <p className="large">
    <span className="xl">4,953</span>người
    </p>
    <p className="middle">（thời điểm 12/2022）</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    <InnerBox top={10} bottom={100}>
    <p className="text-small">Nguồn: Thống kê cư dân người nước ngoài - Bộ Tư pháp</p>
    </InnerBox>
    </Article>
    <div className="navposition01">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    <Article offset={0}>
    <InnerBox >
    <h1>
    Số du học sinh
    </h1>
    </InnerBox>

    <BlackNote>
    <SDecTextUl className="pc-flex col-2">
    <li>
    <p className="large">
    Tổng số du học sinh sống trong tỉnh
    </p>
    <p className="large">
    <span className="xl">19,188</span>người
    </p>
    <p className="middle">（thời điểm 12/2022）</p>
    </li>
    <li>
    <p className="large">
    Số du học sinh sống trong khu vực Fukuoka
    </p>
    <p className="large">
    <span className="xl">14,800</span>người
    </p>
    <p className="middle">（thời điểm 12/2022）</p>
    </li>
    <li>
    <p className="large">
    Số du học sinh sống trong khu vực KitaKyushu
    </p>
    <p className="large">
    <span className="xl">2,631</span>người
    </p>
    <p className="middle">（thời điểm 12/2022）</p>
    </li>
    <li>
    <p className="large">
    Số du học sinh sống trong khu vực Chikugo
    </p>
    <p className="large">
    <span className="xl">1,650</span>người
    </p>
    <p className="middle">（thời điểm 12/2022）</p>
    </li>
    <li>
    <p className="large">
    Số du học sinh sống trong khu vực Chikuho
    </p>
    <p className="large">
    <span className="xl">107</span>người
    </p>
    <p className="middle">（thời điểm 12/2022）</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    <InnerBox top={10} bottom={100}>
    <p className="text-small">Nguồn: Thống kê cư dân người nước ngoài - Bộ Tư pháp</p>
    </InnerBox>
    </Article>
    <div className="navposition01">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    <Article offset={0}>
    <InnerBox>
    <h1>
    Số lượng du học sinh đi làm sau khi tốt nghiệp
    </h1>
    </InnerBox>
    <BlackNote>
    <SDecTextUl>
    <li>
    <p className="large">
    Số lượng du học sinh đi làm tại các công ty tại tỉnh Fukuoka
    </p>
    <p className="large">
    <span className="xl">1,118</span>người
    </p>
    <p className="middle">(năm 2021)</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    </Article>
    </>)
}

export default LangVi