import publicPath from "src/utilty/publicPath"


const LangKo = () => {

    return (<>
    <h1>
    지사 메시지
    </h1>
    <figure className="governor">
     <img src={publicPath('images/fukuoka_governor.jpg')} className="img-fit"/>
    </figure>
    <p>
    　여러분 안녕하세요. 'FUKUOKA IS OPEN' 포털사이트에 오신 것을 환영합니다.
    </p>
    <p>
    　후쿠오카현은 예로부터 아시아 및 세계의 관문 역할을 했으며 풍부한 자연과 역사 속에서 다양한 문화 및 산업이 발전하였고 다양한 인재가 활약하고 있습니다.
    </p>
    <p>
    　FUKUOKA IS OPEN' 포털 사이트에서는 후쿠오카현에서 생활하기 위한 상담 창구, 의료, 교육, 취업 등의 정보를 비롯해 현내에서 활약하는 해외 출신 분들의 모습을 소개하고 있으며, 스포츠, 환경, 원헬스 등 후쿠오카현의 선진적인 시책 및 음식·관광 등의 매력을 전 세계에 알리고 있습니다.
    </p>
    <p>
    　해외에서 후쿠오카현으로 많은 분들이 오셔서 누구나 안심하고 항상 웃는 얼굴로 생활할 수 있도록 확실히 서포트 하겠습니다.
    </p>
    <p>
    FUKUOKA IS OPEN'을 표어로 이곳 후쿠오카에서 함께 미래를 만들어 나갑시다.
    </p>
    <br/>
    <br/>
    <p className="al-r">
    <strong>
    후쿠오카현 지사 핫토리 세이타로</strong>
    </p>
    </>)
}

export default LangKo