import PageBack from "@/components/Molecules/PageBack";
import PageTitleVirtual from "@/components/Molecules/PageTitle/virtual";
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio";
import SubLayerTemplate from "@/components/Templates/subLayer";
import { useLocation } from "react-router-dom";
import getLayer from "src/utilty/getLayer";
import { pathAnalyzer } from "src/utilty/pathAnalyzer";
import { setTitleDiscrption } from "src/utilty/setTitleDiscription";
import styled from "styled-components";
import { VirtualContens } from "@/components/Organisms/Virtual/contents";


const SVirtual = styled.div`
    background: #231815;
    background-image: url('./images/virtual/bk_wrap_pc.webp');
    background-size: 100% auto;
    position: relative;
    z-index: 11;
    margin-top: -1%;
    color:#fff;
  
    .virtual-contens{
        position: relative;
    }
    .virtual-inner{
        max-width: 1020px;
        padding: 0 20px;
        margin: 0 auto;
        position: relative;
    }
    span{ display:inline-block }
    p{
        font-size: 1.5rem;
        line-height: 2.2rem;
        font-weight: bold;
    }
    img{max-width: 100%}
    figure{margin: 0;}
    figure.main{
        margin: 0 0 40px;
        position: relative;
        aspect-ratio: 320 / 219;
        background: url('./images/virtual/main_bk.webp') no-repeat;
        background-size: auto  100%;
        background-position: center;
        img {
            width: 90%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -60%);
        }
    }

    .entrance_link{
        top: -50%;
        right: 0;
        width: 160px;
        display: none;
        position: absolute;
        z-index: 9999;
        &.fix{
            top : 160px;
            position: fixed;
            display: block;
        }
        &:hover{
            filter: contrast(1.4);
        }
    }

    .news{
        width: 100%;
        max-width: 600px;
        position: relative;
        border: solid 2px #fff;
        aspect-ratio: 10/6;
        margin-bottom: 20px;
        .corner{
            position: absolute;
            content: '';
            width: 15%;
            aspect-ratio: 1/1;
            background: url('./images/virtual/news_deco.webp');
            background-size: 100% 100%;
            top: -8%;
            left: -5%;
            &.rigt_top{
                top: -8%;
                left: auto;
                right: -5%;
                transform: rotate(90deg);
            }
            &.rigt_bottom{
                top: auto;
                bottom: -8%;
                left: auto;
                right: -5%;
                transform: rotate(180deg);
            }
            &.left_bottom{
                top: auto;
                bottom: -8%;
                left: -4.8%;
                transform: rotate(270deg);
            }
        }
   
      
        .news-title{
            position: absolute;
            width: 40%;
            color: #2EA7E0;
            border-top: solid 2px #fff;
            border-bottom: solid 2px #fff;
            padding: 10px 10px;
            top: 20%;
            span{
                font-size: 1rem;
                font-weight: bold;
                margin-bottom: 3px;
            }
            h2{
                font-size: 1.4rem;
                font-weight: bold;
            }
        }
        .news-slider{
            position: absolute;
            width: 57%;
            right:0;
            top: 5%;
        }
    }
   
    .message{
        text-align: center;
    }
  
   .fiopark{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 40px 0;
        dl{
            flex-basis: 100%;
            margin-bottom: 30px;
            dt{
                position: relative;
                width: 100%;
                aspect-ratio: 200 / 26;
                margin-bottom:5px;
                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    max-width: none !important;
                    transform: translate(-50%, -50%);
                }
            }
            dd{
                p{
                    display: block;
                    padding: 10px 0 20px;
                }
            }
        }
   }
   .fiopark-howto{
       
   }
   .fiopark-howto-title{
        position: relative;
        padding-bottom: 20px;
        h3{
            font-size: 2.4rem;
            text-align: center;
            margin: 0 auto;
            line-height: 3.2rem;
        }
        &::after{
            content: '';
            width: 100%;
            height: 20px;
            background: url('./images/virtual/lignt_border.webp');
            background-size: 100%  100%;
            background-position:center;
            position: absolute;
            bottom: 0;
            left: 0;
        }
   }
   .fiopark-howto-entrance{
        & > div{
            img{
                width: 250px;
            }
            text-align: center;
           p{ font-size: 2.2rem; }
        }
   }
   .fiopark-howto-app{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 40px 0px 80px;
        & > div{
            flex-basis: 100%;
            position: relative;
            margin-bottom: 20px;
        }
        h4{
            font-size: 1.8rem;
            margin-bottom: 5px;
        }
   }
   .recommend{
        background: #2EA7E0;
        border-radius: 10px;
        padding: 5px 5px;
        color: #fff;
        font-size: 1.8rem;
        font-weight: bold;
        position: relative;
        margin: 20px auto;
        span{
            display:inline-block;
            border: solid 2px #fff;
            padding: 8px 8px;
            border-radius:8px;
        }
        &:hover{
            box-shadow: 0px 0px 15px -5px #ffffff;
            border-radius: 10px;
            background: #61bce7;
        }
        &.pc{
            display: none;
            position: absolute;
            bottom: 0;
            right: 0;
        }
   }

   @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        p{
            font-size: 1.7rem;
            line-height: 2.4rem;
            font-weight: bold;
        }
        figure.main{
            aspect-ratio: 1000 / 463.313;
        }
        .entrance_link{
            display: block;
            width: 360px;
            &.fix{
                top : 20px;
            }
        }
        .news{
            margin: 0;
            aspect-ratio: 10/5.5;
            .news-title{
                span{
                    font-size: 1.8rem;
                    font-weight: bold;
                    margin-bottom: 3px;
                }
                h2{
                    font-size: 3.0rem;
                    font-weight: bold;
                }
            }
            .corner{
                top: -9%;
                &.rigt_top{
                    top: -9%;
                }
                &.rigt_bottom{
                    bottom: -9%;
                }
                &.left_bottom{
                    bottom: -9%;
                }
        }
        }
        .fiopark{
            dl{
                flex-basis: 48%;
                &.solo{
                    flex-basis: 70%;
                    margin: 0 auto;
                    
                    margin-bottom: 40px;
                }
            }
        }
        .fiopark-howto-title{
            padding-bottom: 0;
            &::before{
                content: '';
                width: calc((100% - 328px) / 2);
                height:100%;
                background: url('./images/virtual/lignt_border.webp');
                background-size: 100% 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
            &::after{
                content: '';
                width: calc((100% - 328px) / 2);
                height:100%;
                background: url('./images/virtual/lignt_border.webp');
                background-size: 100% 100%;
                position: absolute;
                left: auto;
                bottom: auto;
                right: 0;
                top: 0;
            }
            &.vi,&.en{
                &::after,&::before{
                    width: calc((100% - 380px) / 2);
                }
            }
        }
        .fiopark-howto-entrance{
            max-width: 640px;
            display: flex;
            & > div{
                flex-basis: 50%;
                display: flex;
                align-items: center;
                margin: 0;
                &:first-child{
                    flex-basis: 40%;
                }
                p{ font-size: 1.8rem;padding-left: 10px }
            }
        }
        .fiopark-howto-app{
            & > div{
                flex-basis: 45%;
            }
        }
        .recommend{
            margin: 0;
            &.pc{
                display: block;
            }
            &.sp{
                display: none;
            }
        }
   }
`

const FioParkPage = () => {

    const location = useLocation()
    const lang = pathAnalyzer(location).lang
    const layer = getLayer('virtual')

    setTitleDiscrption({
        layer : 'virtual',
        lang : lang,
        subLayer : 'fiopark'
    })

  

    
    
    
    return (
        <SVirtual>
         <VirtualContens lang={lang}></VirtualContens>
        </SVirtual>
    )
}

export default FioParkPage