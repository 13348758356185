import publicPath from "src/utilty/publicPath"

const LangZhTw = () => {

    return (<>
    <h1>
    知事致辭
    </h1>
   <figure className="governor">
     <img src={publicPath('images/fukuoka_governor.jpg')} className="img-fit"/>
    </figure>
    <p>
    　大家好。 歡迎訪問 "FUKUOKA IS OPEN "門戶網站。
    </p>
    <p>
    　福岡縣作為麵向亞洲和世界的窗口，長期以來一直保持著開放的姿態，其豐富的自然環境和歷史造就了多樣的文化、產業和人才。
    </p>
    <p>
    　福岡縣門戶網站 "FUKUOKA IS OPEN "提供福岡縣的生活谘詢窗口、醫療、教育和就業等信息，並介紹活躍在福岡縣的海外人士。 此外，還向世界介紹福岡縣在體育、環境、one health方麵的先進舉措，並展現其美食和旅遊景點的魅力。
    </p>
    <p>
    　為了讓更多的海外人士能夠來福岡縣安居樂業，我們將更加強有力地提供各種支持。
    </p>
    <p>
    讓我們在 "FUKUOKA IS OPEN "的口號下，共同創建福岡的未來。
    </p>
    <br/>
    <br/>
    <p className="al-r">
    <strong>
    福岡縣知事 服部誠太郎</strong>
    </p>
    </>)
}

export default LangZhTw