import { NavigatorType } from "src/types/charactor"
import { direction } from "src/types/common"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"

const SFigure = styled.figure`
    padding: 0;
    margin: 0;
`

interface CharacterProps {
    dir : direction,
    color? : string
}

export const NavigateCracter = ({type}:{type:NavigatorType}) => {

    return (
    <SFigure>
        <img src={publicPath(`images/character/v2/navigator_${type}.png`)}
        alt="publicPath" 
        className="img-fit"
        />
    </SFigure>
    )
}

export const NavigatorFullLength = () => {

    return (
        <SFigure>
        <img src={publicPath('images/character/navigator.png')}
        alt="challenger" 
        className="img-fit"
        />
        </SFigure>
    )
}

export const NavigatorFullLengthJump = () => {

    return (
        <SFigure>
        <img src={publicPath('images/character/navigator_jump.png')}
        alt="challenger" 
        className="img-fit"
        />
        </SFigure>
    )
}

export const NavigatorJaggedMark = () => {
    return (
        <SFigure>
        <img src={publicPath('images/character/navigator_patten02.png')}
        alt="challenger" 
        className="img-fit"
        />
        </SFigure>
    )
}

export const NavigatorSmorkMark = () => {
    return (
        <SFigure>
        <img src={publicPath('images/character/navigator_patten01.png')}
        alt="challenger" 
        className="img-fit"
        />
        </SFigure>
    )
}

export const NavigatorNice = ({
    dir,
    color = 'blue',
    ...props
}:CharacterProps) => {

    return (
        <SFigure>
                <img src={publicPath(`images/character/navigator_nice_${dir}_${color}.png`)}
            alt="NICE!!" 
            className="img-fit"
            />
        </SFigure>
    )
}

export const NavigatorGoodLuck = ({
    dir,
    color = 'blue',
    ...props
}:CharacterProps) => {

    return(
        <SFigure>
                <img src={publicPath(`images/character/navigator_goodluck_${dir}_${color}.png`)}
            alt="Good Luck!!" 
            className="img-fit"
            />
        </SFigure>
    )
}

export const NavigatorGood = ({
    dir,
    color = 'blue',
    ...props
}:CharacterProps) => {

    return(
        <SFigure>
                <img src={publicPath(`images/character/navigator_good_${dir}_${color}.png`)}
            alt="Good!!" 
            className="img-fit"
            />
        </SFigure>
    )
}

export const NavigatorPickUp = ({
    dir,
    color = 'blue',
    ...props
}:CharacterProps) => {

    return (
        <SFigure>
                <img src={publicPath(`images/character/navigator_pickup_${dir}_${color}.png`)}
            alt="Pick UP!!" 
            className="img-fit"
            />
        </SFigure>
    )
}

export const NavigatorCheck= ({
    dir,
    color = 'blue',
    ...props
}:CharacterProps) => {

    return (
        <SFigure>
                <img src={publicPath(`images/character/navigator_check_${dir}_${color}.png`)}
            alt="Check!!" 
            className="img-fit"
            />
        </SFigure>
    )
}

export const NavigatorEnjoy = ({
    dir,
    color = 'blue',
    ...props
}:CharacterProps) => {

    return (
        <SFigure>
                <img src={publicPath(`images/character/navigator_enjoy_${dir}_${color}.png`)}
            alt="Enjoy!!" 
            className="img-fit"
            />
        </SFigure>
    )
}

export const NavigatorGreate = ({
    dir,
    color = 'blue',
    ...props
}:CharacterProps) => {

    return (
        <SFigure>
                <img src={publicPath(`images/character/navigator_Greate_${dir}_${color}.png`)}
            alt="Greate" 
            className="img-fit"
            />
        </SFigure>
    )
}