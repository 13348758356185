import { NavigatorFullLength } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangKo = () => {

    return (<>
        <div className="navigatorPositon sp-only">
            <NavigatorFullLength></NavigatorFullLength>
        </div>
        <div className="pc-flex col-2">
            <div>
                <h1>
                현장(縣章)
                </h1>
                <figure>
                <img src={publicPath('images/symbol01.png')} alt="県のシンボル" className="img-fit"/>
                </figure>
                <p>
                현을 상징하는 꽃 "매화"를 형상화하여 현의 발전과 현민의 화합을 나타내고 있습니다.<br/>
                1966년 5월 10일 공모를 통해 현이 결정했습니다.
                </p>
            </div>
            <div>
                <h1>
                ●후쿠오카현의 꽃
                매화
                </h1>
                <figure>
                <img src={publicPath('images/symbol02.png')} className="img-fit"/>
                </figure>
                <p>
                1954년 3월 19일 "NHK 식물 동호회"가 다자이후의 매화와 관련지어 결정했습니다.
                매화는 다자이후 천만궁에 모셔져 있는 학문의 신 스가와라노 미치자네가 평생동안 각별히 사랑했다고 알려져 있습니다
                </p>
            </div>
            <div>
                <h1>
                ●후쿠오카현의 새
                휘파람새
                </h1>
                <figure>
                <img src={publicPath('images/symbol03.png')} className="img-fit"/>
                </figure>
                <p>
                1964년 7월 3일 공모를 통해서 현이 결정했습니다.
                일본에는 '매화에 휘파람새'라는 말이 있습니다.
                이것은 잘 어울리는 것, 아름답게 조화를 이루는 것의 비유적 의미입니다.
                </p>
            </div>
            <div>
                <h1>
                ●후쿠오카현의 나무
                진달래
                </h1>
                <figure>
                <img src={publicPath('images/symbol04.png')} className="img-fit"/>
                </figure>
                <p>
                1966년 9월 5일 "녹색 일본 전국 운동"에서 결정되었습니다.
                진달래는 정원수, 분재용으로 구루메 지역에서 재배가 활발하고 그 묘목은 해외로 수출되고 있습니다
                </p>
            </div>
        </div>
    </>)
}

export default LangKo