import publicPath from "src/utilty/publicPath"

const LangVi = () => {

    return (<>
    <h1>
    Lời nhắn của Thống đốc tỉnh
    </h1>
   <figure className="governor">
     <img src={publicPath('images/fukuoka_governor.jpg')} className="img-fit"/>
    </figure>
    <p>
    　Xin chào mọi người, chào mừng đến với Cổng thông tin điện tử "FUKUOKA IS OPEN".
    </p>
    <p>
    　Fukuoka từ lâu đã được xem như là cửa ngõ giao lưu với châu Á và thế giới, là nơi có nhiều văn hóa và ngành công nghiệp phát triển, có thiên nhiên và lịch sử phong phú, có nhiều nhân tài tụ họp.
    </p>
    <p>
    　Tại Cổng thông tin điện tử "FUKUOKA IS OPEN", chúng tôi vừa giới thiệu các địa chỉ tư vấn trong quán trình sinh hoạt tại Fukuoka, các thông tin về y tế, giáo dục, việc làm..., hình ảnh những người nước ngoài đang hoạt động tích cực trong tỉnh, vừa gửi đến toàn thế giới thông tin về những dự án tiên tiến của tỉnh trong các lĩnh vực như thể thao, môi trường, One Health, những điểm thu hút về ẩm thực và du lịch...
    </p>
    <p>
    　Chúng tôi sẽ tích cực hỗ trợ để đảm bảo rằng những người từ nước ngoài đến Fukuoka ai cũng có thể an tâm, sống một cuộc sống ổn định và hạnh phúc.
    </p>
    <p>
    Như chính khẩu hiệu "FUKUOKA IS OPEN", hãy cùng nhau xây dựng tương lai tại Fukuoka nhé!
    </p>
    <br/>
    <br/>
    <p className="al-r">
    <strong>
    Thống đốc tỉnh Fukuoka Hattori Seitaro</strong>
    </p>
    </>)
}

export default LangVi