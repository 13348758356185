import Loader from "@/components/Atoms/Loader"


const ArticleLoader = () => {


    return (
        <Loader></Loader>
    )
}

export default ArticleLoader