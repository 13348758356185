import PageBack from "@/components/Molecules/PageBack"
import { useCmsContext } from "@/context/CmsContext"
import getLayer  from "src/utilty/getLayer"


export const CmsPageBack = ({
    page,
    onClick = () => {},
    ...props
}: { 
    page : 'index' | 'view',
    onClick? : () => void
}) => {

    const CmsContext = useCmsContext()
    const layer = getLayer(CmsContext.pathAnalys.layer)
    return (
        <>
        {
            page === 'index' &&
            <PageBack 
            backto={`/?current=${layer.topPageCurrent}`}
            positon="left"></PageBack>   
        }
        {
            page === 'view' &&
            <PageBack 
                positon="center"
                type="button"
                onClick={onClick}
            ></PageBack>  
        }
        </>
        
    )
    
} 