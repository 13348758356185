import publicPath from "src/utilty/publicPath"

const LangNe = () => {

    return (<>
    <h1>
    गभर्नरको सन्देश
    </h1>
   <figure className="governor">
     <img src={publicPath('images/fukuoka_governor.jpg')} className="img-fit"/>
    </figure>
    <p>
    　सबैलाई नमस्कार। 「FUKUOKA IS OPEN」को पोर्टल साइटमा कृपया स्वागत छ।
    </p>
    <p>
    　फुकुओका प्रान्त लामो समयदेखि एशिया र बाँकी विश्वको प्रवेशद्वार भएको छ, समृद्ध प्रकृति र इतिहासको बीचमा, विभिन्न संस्कृति र उद्योगहरूको विकास भएको छ,विविध जनशक्ति सक्रिय छन्।
    </p>
    <p>
    　「FUKUOKA IS OPEN」 पोर्टल साइटमा फुकुओका प्रान्तमा बसोबास गर्नका लागि परामर्श काउन्टरहरू, चिकित्सा हेरचाह, शिक्षा, रोजगारी, इत्यादि बारे जानकारी प्रदान गर्दछ, साथै प्रान्तमा सक्रिय रहेका विदेश बाट आएका सबैजनाको अवस्था परिचय गराउनका साथै खेलकुद, वातावरण, One Health आदिको यस प्रान्तको उन्नतिका पहल तथा खाना・पर्यटन आदिको आकर्षण संसारमा प्रसारण गरिरहेका छौं।
    </p>
    <p>
    　विदेशबाट फुकुओका प्रान्तमा धेरै मानिसहरू आएर सबैजना ढुक्कका साथ धेरै मुस्कानका साथ बाँच्न सक्ने गरी पूर्ण सहयोग प्रदान गर्नेछौं।
    </p>
    <p>
    「FUKUOKA IS OPEN」भन्ने नाराका साथ यस फुकुओकामा सँगै भविष्य निर्माण गरौं।
    </p>
    <br/>
    <br/>
    <p className="al-r">
    <strong>
    फुकुओका प्रान्तका गभर्नर सेइतारो हात्तोरी</strong>
    </p>
    </>)
}

export default LangNe