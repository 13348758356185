import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlue, FukidashiBlueBottom } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { ChallengerAction, NavigatorAction } from "@/components/Molecules/CharactorAction"



const LangZhCn = () => {
    const lang = 'zh_cn'
    return (<>
        <InnerBox left={0} right={0} bottom={60}>
        <div className="pc-flex col-2">
            <div className="question">
            <div className="charactor">
            <ChallengerAction
            dir="left"
            charactorType="thinking"
            actionType="question"/>
            </div>
            <div className="fukidashi">
            <FukidashiBlue dir="left">
            <span>不能用日语交谈。有可以支持多语言的咨询窗口么。</span>
            </FukidashiBlue>
            </div>
            </div>

            <div className="anser-block">
            <div className="fukidashi">
            <FukidashiBlueBottom dir="right">
            <span>「福冈县外国人咨询中心」提供包括日语在内的22种语言的咨询服务。此外，各市镇村设有多语言支持的咨询窗口和国际交流协会，为外国人提供语言支持、法律咨询以及生活全方位的支持。</span>
            </FukidashiBlueBottom>
            </div>
            <div className="charactor">
            <NavigatorAction
            dir="right"
            charactorType="normal"
            actionType="anser"/>
            </div>
            </div>
        </div>

        <div className="links">
        <span className="left">详情请查看</span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/consultation/international?lang=${lang}`}
        linkKey="kokusai_soudan"
        ></YellowArrowLink>
        </div>
        <span className="right"></span>
        </div>

        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
            <div className="charactor">
            <ChallengerAction
            dir="left"
            charactorType="puzzled"
            actionType="question"/>
            </div>
            <div className="fukidashi">
            <FukidashiBlue dir="left">
            <span>想带着家人一起来、但因为不能用日语交谈、可以告诉我能够学习日语的地方么？</span>
            </FukidashiBlue>
            </div>
            </div>

            <div className="anser-block">
            <div className="fukidashi">
            <FukidashiBlueBottom dir="right">
            <span>在福冈县内有100所由政府和志愿者开设的日语教室。也有可以轻松咨询日常生活中遇到的困扰的教室。</span>
            </FukidashiBlueBottom>
            </div>
            <div className="charactor">
            <NavigatorAction
            dir="right"
            charactorType="smile"
            actionType="anser"/>
            </div>
            </div>
        </div>

        <div className="links">
        <span className="left">详情请查看</span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/lifestyle/education?lang=${lang}`}
        linkKey="kyoiku"
        ></YellowArrowLink>
        </div>
        <span className="right"></span>
        </div>


        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
            <div className="charactor">
            <ChallengerAction
            dir="left"
            charactorType="thinking"
            actionType="question"/>
            </div>
            <div className="fukidashi">
            <FukidashiBlue dir="left">
            <span>请告诉我支持外语的医疗机关信息。</span>
            </FukidashiBlue>
            </div>
            </div>

            <div className="anser-block">
            <div className="fukidashi">
            <FukidashiBlueBottom dir="right">
            <span>「福冈国际医疗支援中心」介绍了能够支持外语的医疗机构和提供医疗翻译服务的医疗机构，详细说明了福冈县内医疗机构的信息和日本医疗体系，并推荐通过电话提供医生翻译支持的医疗机构。</span>
            </FukidashiBlueBottom>
            </div>
            <div className="charactor">
            <NavigatorAction
            dir="right"
            charactorType="good"
            actionType="anser"/>
            </div>
            </div>
        </div>

        <div className="links">
        <span className="left">详情请查看</span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/lifestyle/medical?lang=${lang}`}
        linkKey="iryo"
        ></YellowArrowLink>
        </div>
        <span className="right"></span>
        </div>
        
        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
            <div className="charactor">
            <ChallengerAction
            dir="left"
            charactorType="thinking"
            actionType="question"/>
            </div>
            <div className="fukidashi">
            <FukidashiBlue dir="left">
            <span>有国际学校作为孩子的入学选择吗。</span>
            </FukidashiBlue>
            </div>
            </div>

            <div className="anser-block">
            <div className="fukidashi">
            <FukidashiBlueBottom dir="right">
            <span>在福冈县，提供通过国际教育认定机构「International Baccalaureate机构」认证，实行从幼儿时期到高中的一贯式英语教育的国际学校。</span>
            </FukidashiBlueBottom>
            </div>
            <div className="charactor">
            <NavigatorAction
            dir="right"
            charactorType="pointing"
            actionType="anser"/>
            </div>
            </div>
        </div>

        <div className="links">
        <span className="left">详情请查看</span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/lifestyle/education?lang=${lang}`}
        linkKey="kyoiku"
        ></YellowArrowLink>
        </div>
        <span className="right"></span>
        </div>

        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
            <div className="charactor">
            <ChallengerAction
            dir="left"
            charactorType="thinking"
            actionType="question"/>
            </div>
            <div className="fukidashi">
            <FukidashiBlue dir="left">
            <span>我正在福冈找工作。可以告诉我哪里可以看到招聘信息么。</span>
            </FukidashiBlue>
            </div>
            </div>

            <div className="anser-block">
            <div className="fukidashi">
            <FukidashiBlueBottom dir="right">
            <span>针对外国人工作的咨询・介绍，Hello Work福冈中央/福冈外国人雇佣服务中心可提供服务。</span>
            </FukidashiBlueBottom>
            </div>
            <div className="charactor">
            <NavigatorAction
            dir="right"
            charactorType="normal"
            actionType="anser"/>
            </div>
            </div>
        </div>
        <div className="links">
        <span className="left">详情请查看</span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/lifestyle/education?lang=${lang}`}
        linkKey="works"
        ></YellowArrowLink>
        </div>

        <span className="right"></span>
        </div>

        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
            <div className="charactor">
            <ChallengerAction
            dir="left"
            charactorType="thinking"
            actionType="question"/>
            </div>
            <div className="fukidashi">
            <FukidashiBlue dir="left">
            <span>我考虑搬到福冈，但对于能否融入当地社区而感到担忧。</span>
            </FukidashiBlue>
            </div>
            </div>

            <div className="anser-block">
            <div className="fukidashi">
            <FukidashiBlueBottom dir="right">
            <span>本网站通过视频分享了在福冈县活跃的外国人的成功故事。在福冈，有许多外国人像视频中介绍的那样，背井离乡，融入当地社区并取得成功。</span>
            </FukidashiBlueBottom>
            </div>
            <div className="charactor">
            <NavigatorAction
            dir="right"
            charactorType="smile"
            actionType="anser"/>
            </div>
            </div>
        </div>
        
        <div className="links">
        <span className="left">详情请查看</span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/movie?lang=${lang}`}
        linkKey="fortunehills"
        ></YellowArrowLink>
        </div>
        <span className="right"></span>
        </div>
        </InnerBox>
    </>)
}

export default LangZhCn