


const LangTh = () => {

    return (
        <div className='article-inner'>
        <section>
        <h1>คิวชูคืออะไร</h1>
        <ul className="photos">
            <li>
                <img src="./images/kyushulogomark/logomark01.jpg" alt="The Kyushu Logo"/>
            </li>
            <li>
                <img src="./images/kyushulogomark/logomark02.jpg" alt="The Kyushu Logo ※のれん"/>
            </li>
        </ul>
        <p>คิวชูตั้งอยู่ที่ริมปลายทางทิศตะวันตกของญี่ปุ่น เป็นสถานที่ที่สามารถเพลิดเพลินไปกับสเน่ห์ของญี่ปุ่น เช่น ธรรมชาติที่สวยงามและยิ่งใหญ่ของทั้งภูเขาและทะเล ออนเซ็น ประวัติศาสตร์ วัฒนธรรม อาหาร เป็นต้น สำหรับรายละเอียด กรุณารับชมคลิป</p>
        <br/>
        </section>
        <section>
        <h1>เครื่องหมายโลโก้คิวชูคืออะไร</h1>
        <figure>
        <img src="./images/kyushulogomark/kyushu.jpg" alt="九州について"/>
        </figure>
        <p>
        เครื่องหมายนี้ใช้ในการโฆษณาคิวชูในประเทศและทั่วโลก เครื่องหมายโลโก้คิวชูได้ออกแบบขึ้นมาจากแนวคิดม่านโนเรน* ซึ่งได้ใส่ความรู้สึกที่ต้องการต้อนรับผู้คนผ่านม่านโนเรนของคิวชู ประกอบด้วยคันจิ 「九 (คิว)」 「州 (ชู) 」และ 「一 (อิชิ) 」เพื่อเป็นการแสดงว่าหลากหลายพื้นที่ของคิวชูได้ร่วมมือเป็นหนึ่งเดียวกัน<br/>
        * โนเรน<br/>
        โนเรน คือ ผ้าที่แขวนไว้ตรงทางเข้าร้านเพื่อบังแดด เป็นวัฒนธรรมที่เป็นเอกลักษณ์ของญี่ปุ่น ส่วนใหญ่จะแสดงชื่อร้านหรือเครื่องหมายการค้าเอาไว้ด้วย
        </p>
        
        <br/>
        <div className="youtube">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/5GNHVfoQd0s?si=JCxXRHx1bwIt-3EO" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        </section>
        <section>
            <h1>การนำเครื่องหมายโลโก้คิวชูไปใช้ในต่างประเทศ </h1>
            <p>เครื่องหมายโลโก้คิวชู ก็ถูกใช้ในต่างประเทศด้วยเช่นกัน</p>
            <p><strong>◎บริษัท BOKKSU จำกัด</strong></p>
            <p>เขียนอยู่ในคู่มือวัฒนธรรม “กล่องขนมของคิวชู”</p>
            <figure>
            <img src="./images/kyushulogomark/logomark-bkksu.jpg" alt="BOKKUS"/>
            </figure>
            <p className="note">ใน "กล่องขนมของคิวชู" ที่รวบรวมขนมของคิวชูมาไว้ด้วยกัน ได้แนบเอกสารอธิบายขนม และเอกสารแนะนำวัฒนธรรมที่เขียนเกี่ยวกับประวัติศาสตร์ วัฒนธรรม และแหล่งท่องเที่ยงของคิวชูเอาไว้</p>

            <p><strong>◎บริษัท MARUTAI จำกัด</strong></p>
            <p>“เซ็ตราเมงแบบเส้นในพื้นที่ของคุณ”(รวม 8 รายการ) เขียนไว้ที่ด้านหลังบรรจุภัณฑ์</p>
            <figure>
            <img src="./images/kyushulogomark/logomark-marutai.jpg" alt="Marutai"/>
            </figure>
            <p className="note">ทงคตสีราเมงของฮาคาตะ ทงคตสีราเมงน้ำมันมาสีดำของคุมาโมโตะ ทงคตสึราเมงคูโระบูตะของคาโคะชิมะ</p>

            <p><strong>◎บริษัท DAISHO จำกัด</strong></p>
            <p>“ชุดยามาอิโมะ เทปันยากิ” เขียนไว้ที่ด้านหลังบรรจุภัณฑ์</p>
            <figure>
            <img src="./images/kyushulogomark/yamaimoteppan.jpg" alt="山芋鉄板焼きの素"/>
            </figure>
        </section>

        <div className="deco-fukidashi navigatorCheck right smile">
        สำหรับข้อมูลด้านการท่องเที่ยวโดยละเอียดของคิวชู กรุณารับชมที่ 「Visit Kyushu」
        </div>
       
        <p>Visit Kyushu</p>
        <a href="https://www.welcomekyushu.com/" target="_blank">
            <figure>
            <img src='./images/banner/tabinet.png' alt="Visit Kyushu（九州旅ネット）" className="img-fit"/>
            </figure>
        </a>
        </div>
    )
}

export default LangTh;