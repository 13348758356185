import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { TrapezoidExcl } from "@/components/Molecules/CharactorHeadline/challenger"
import Article from "@/components/Organisms/Article"


const LangKo = () => {

    return (<>
    <Article offset={0} className="pc-noTopMargin">
    <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
        </div>
        <BlackNote>
        <ul className="deco">
        <li>
        <p className="large">
        ●후쿠오카현의 인구<br/>
        514만 명
        </p>
        <p className="al-c">(2020년·전국 9위)</p>
        <span className="txt-small">
        자료 현조사통계과 '2022년 후쿠오카현의 인구와 세대연보'
        </span>
        </li>
        </ul>
        </BlackNote>
    </div>
    </Article>
    <TrapezoidExcl dir="left">
    ●후쿠오카현의 면적<br/>
    </TrapezoidExcl>
    <Article offset={0} className="pc-noTopMargin">
    <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
        </div>
        <BlackNote>
        <ul className="deco">
        <li>
        <p className="large">
        ●후쿠오카현의 면적 <br/>
        4,987㎢
        </p>
        <p className="al-c">(2020년·전국 29위)</p>
        <span className="txt-small">
        자료 국토교통성 국토지리원
        <br/>
        2022년 전국 도도부현 시구정촌별 면적조사'
        </span>
        </li>
        </ul>
        </BlackNote>
    </div>

    <div className="navi-position sp-only">
        <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    </Article>

    <TrapezoidExcl dir="left">
    ●후쿠오카현의 기후<br/>
    </TrapezoidExcl>
    <Article offset={0} className="pc-noTopMargin">
    <BlackNote>
    <ul className="deco c-flex col-2">
    <li>
    <p className="large">
    연평균 기온 <br/>
    17.3℃
    </p>
    </li>
    <li>
    <p className="large">
    연평균 습도<br/>
    68％
    </p>
    </li>
    <li>
    <p className="large">
    연간 일조시간<br/>
    1,889.4시간
    </p>
    </li>
    <li>
    <p className="large">
    연간 강수량<br/>
    1,686.9mm

    </p>
    <p>1991~2020년 30년간 평균치</p>
    <p className="txt-small">
    자료 후쿠오카 관구 기상대
    </p>
    </li>
    </ul>
   
    </BlackNote>
        <div className="sp-only">
            <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
    </Article>
    <InnerBox top={40} bottom={40}>
    </InnerBox>
    </>)
}

export default LangKo