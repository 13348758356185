
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote";


const LangEn = () => {

    return (
        <>
            <p className="discpriton">
            Tonkotsu Ramen, one of Fukuoka Prefecture's specialities.<br/>
            Ramen shops are dotted all over the prefecture, where you can taste the speciality of the shop.<br/>
            TONKOTSU RAMEN BEATS is based on the theme of tonkotsu ramen.<br/>
            Capture the rhythm of the donburi and notch up a high score!
            </p>
            <EmphasisNote className="customNote">
                <h4><img src="./images/ramenbeats/howtoplay.webp" alt="how to play"/></h4>
                <section>
                    <h5 className="first">
                    Tap the Start button at the bottom of the screen.
                    </h5>
                </section>
                <section>
                    <h5 className="second">
                        Tap on<br/>
                        <img src="./images/ramenbeats/start.webp" alt="start" width={230}/>
                    </h5>
                </section>
                <section>
                    <h5 className="third">
                    Tap the stage of your choice to start the game!
                    </h5>
                    <ul className="caption">
                        <li>
                        Play on an up-to-date operating system and browser.
                        </li>
                        <li>
                        This game is designed for smartphones.
                        </li>
                    </ul>
                </section>
            </EmphasisNote>
            <h4 className="whattonkotsu"><img src="./images/ramenbeats/what_tonkotsu.webp" alt="What’s TONKOTSU RAMEN？"/></h4>
            <p>
            Tonkotsu' means 'pig bone'.<br/>
            Tonkotsu ramen soup is made by boiling pork bones for a long time.<br/>
            It varies from region to region and shop to shop, but it is characterised by its cloudy white colour.<br/>
            Thin, straight noodles are the most common type of noodle.<br/>
            Many shops allow you to order noodles the way you like them, such as 'crunchy' or 'flat'.<br/>
            Many restaurants also offer '替玉' ((Kaedama) refills of rice noodles), where only the rice noodles are refilled.
            </p>
            <ul className="phots"> 
                <li>
                    <img  src="./images/ramenbeats/ramen01.webp" alt="豚骨ラーメン"/>
                </li>
                <li>
                    <img  src="./images/ramenbeats/ramen02.webp" alt="豚骨ラーメン"/>
                </li>
            </ul>
            
        
        </>
    )
}

export default LangEn