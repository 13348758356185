import { CmsListArticles } from "@/components/Molecules/Cms/List/articles";
import MoreLoad from "@/components/Molecules/MoreLoad";
import ArticleLoader from "@/components/Organisms/Article/articleLoader";
import { useCmsContext } from "@/context/CmsContext"
import { Suspense, useEffect, useState } from "react"
import { FetchArtcleIndexArg, fetchArticleIndex, fetchArticleNews, fetchKewordSearch } from "src/data/fetch";
import { AritcleIndex, AritcleSearchRes, ArticleObj, endpoint } from "src/types/article";
import { ICmsArticlesProps } from "../..";
import { getCmsNews} from "src/utilty/getNews";


export const CmsArticlesNewsSearch = ({
    searchWord,
    handleView
}: ICmsArticlesProps) => {
   
    const CmsContext = useCmsContext()
    const loadLimit = 6;
    const [offset, setOffset] = useState<number>(0)
    const [articleIndexs, setArticleIndexs] = useState<AritcleIndex[]>([])
    const [ articleContens, setArticleContents ] = useState<ArticleObj[]>()
    const [ moreCount, setMoreCount ] = useState<number>(1)
    const [isMore, setIsMore ] = useState<boolean>(false)


    const NewsArgs:FetchArtcleIndexArg  = {
        endpoint: 'information',
        lang: CmsContext.pathAnalys.lang,
        offset: 0,
        limit: 20,
        categroyId : 'index'
    }
    
    const MovieArgs:FetchArtcleIndexArg  = {
        endpoint: 'movie',
        lang: CmsContext.pathAnalys.lang,
        offset: 0,
        limit: 20,
        categroyId : 'index'
    }

    const LifeStyleArg:FetchArtcleIndexArg  = {
        endpoint: 'lifestyle',
        lang: CmsContext.pathAnalys.lang,
        offset: 0,
        limit: 20,
        categroyId : 'index'
    }
 
    const InitiativesArg:FetchArtcleIndexArg  = {
        endpoint: 'initiatives',
        lang: CmsContext.pathAnalys.lang,
        offset: 0,
        limit: 20,
        categroyId : 'index'
    }

    const BasicInfoArg:FetchArtcleIndexArg  = {
        endpoint: 'basicinfo',
        lang: CmsContext.pathAnalys.lang,
        offset: 0,
        limit: 20,
        categroyId : 'index'
    }

    const VirtualArg:FetchArtcleIndexArg  = {
        endpoint: 'virtual',
        lang: CmsContext.pathAnalys.lang,
        offset: 0,
        limit: 20,
        categroyId : 'index'
    }


    useEffect(() => {

        
        const fetchSearch = async () => {
            try{

                const contents:ArticleObj[] = await getCmsNews({NewsArgs, MovieArgs, LifeStyleArg, InitiativesArg, BasicInfoArg, VirtualArg})
               
                const searchRes: AritcleSearchRes = await fetchKewordSearch(
                    CmsContext.pathAnalys.lang,
                    searchWord
                )

                const result = contents.filter(content => searchRes.contents.some(searchId => searchId.id === content[CmsContext.pathAnalys.lang].id ))

                result.sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime());
                setArticleContents(result)

                setArticleIndexs([{
                    contents : result.slice(0, loadLimit),
                    totalCount: result.length,
                    offset : 0,
                    limit : 100
                }])

                setIsMore(result.length > loadLimit)

            }catch (error){
                console.error('データの取得に失敗しました:',error)
                if(articleContens) setIsMore(articleContens.length > loadLimit)
            }
            
        }
        fetchSearch()
        
    },[])

    const handleMore = () => {
        const offet = moreCount * loadLimit
        console.log(articleContens.slice(offet, loadLimit))
        setArticleIndexs([...articleIndexs,{
            contents : articleContens.slice(offet, offet+loadLimit),
            totalCount: articleContens.length,
            offset : 0,
            limit : 100
        }])
        setMoreCount(moreCount+1)
        setIsMore(articleContens.length > (offet + loadLimit))
    }

   
    
    return (
        <>
        
          { articleIndexs.length > 0 &&
                articleIndexs.map((articles,i) => (
                    <Suspense key={`aritcleList-${i}`}>
                        <div className="articles">
                            <CmsListArticles 
                            handleView={handleView}
                            articles={articles}></CmsListArticles>
                        </div>
                    </Suspense>
                ))
          }
          {
            articleIndexs.length === 0 &&
            <ArticleLoader/>
          }
          {
            isMore && 
            <MoreLoad onClick={handleMore}></MoreLoad>
          }
        </>
        
    )
}