
import { NavigatorNice } from "@/components/Atoms/Character/navigator"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorFukidashiPicup } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"

const LangVi = () => {

    return (<>
    <h1>Hỗ trợ người nước ngoài trong trường hợp xảy ra thảm họa</h1>
    <p>
    Điều cực kỳ quan trọng đối với người đến từ nước ngoài là thường ngày cần có sự chuẩn bị kỹ lưỡng để trong trường hợp xảy ra thảm họa có thể đưa ra những hành động phù hợp để có thể sống tại tịnh một cách an tâm và an toàn.<br/>
    Tỉnh cung cấp thông tin bằng nhiều hình thức khác nhau. Bằng cách tìm hiểu về thảm họa, bạn sẽ có thể bảo vệ tính mạng của chính mình và những người yêu quý như cả gia đình và bạn bè xung quanh.
    </p>
    <NavigatorFukidashiPicup dir="right">
    Khi xảy ra thiên tai, việc nắm bắt được thông tin về thiên tai và khu vực sơ tán là điều rất quan trọng. Việc sử dụng các trang web và ứng dụng sau đây sẽ giúp bạn dễ dàng thu thập thông tin cần thiết.
    </NavigatorFukidashiPicup>
    <br/>
    <EmphasisNote>

    <h2>Tải ứng dụng phòng chống thiên tai “Fukuoka Bonsai NaviMamorukun”</h2>
    <p>
    Ứng dụng phòng chống thiên tai “Fukuoka Bonsai NaviMamorukun” cung cấp thông tin về thiên tai...<br/>
    Đăng ký và sử dụng hoàn toàn miễn phí, có thể nắm được thông tin thiên tai qua vài thao tác đơn giản nên hãy đăng ký sử dụng nhé!
    </p>
   
    <p>
    ●Thông báo thông tin thiên tai tại vị trí hiện tại của bạn qua báo động.<br/>
    ●Hiển thị vị trí nơi sơ tán một cách rõ ràng bằng bản đồ.<br/>
    ●Nêu rõ những hành động cần thiết trong trường hợp xảy ra thiên tai thông qua hình ảnh minh họa.<br/>
    ●Bạn có thể kiểm tra thông tin vị trí của gia đình mình trong trường hợp xảy ra thảm họa.<br/>
    ●Ngôn ngữ được hỗ trợ: tiếng Nhật, tiếng Anh, tiếng Trung (giản thể và phồn thể), tiếng Hàn và tiếng Việt.
    </p>
    <figure>
    <img src={publicPath('images/articles/mamorukun01.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/mamorukun02.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
    </figure>

    <div className="app_qr">
    <a href="https://apps.apple.com/jp/app/%E3%81%B5%E3%81%8F%E3%81%8A%E3%81%8B%E9%98%B2%E7%81%BD%E3%83%8A%E3%83%93-%E3%81%BE%E3%82%82%E3%82%8B%E3%81%8F%E3%82%93/id1659756418" target="blank">
    <figure>
    <img src={publicPath('images/articles/mamorukun_app01.png')} alt="App Store" className="fit-img"/>
    </figure>
    </a>
    <hr/>
    <a href="https://play.google.com/store/apps/details?id=jp.lg.fukuoka.pref.fukuoka_bosai&hl=ja&gl=US" target="blank">
    <figure>
    <img src={publicPath('images/articles/mamorukun_app02.png')} alt="Google Play" className="fit-img"/>
    </figure>
    </a>
    </div>
    <h2>Trang chủ phòng chống thiên tai tỉnh Fukuoka</h2>
    <p>
    Tỉnh Fukuoka thiết lập “Trang chủ phòng chống thiên tai của tỉnh Fukuoka” để cung cấp thông tin từ việc chuẩn bị cho thiên tai đến thông tin mới nhất về thiên tai.
    </p>
    <p>
    （URL:<a href="https://www.bousai.pref.fukuoka.jp" target="_blank">
    https://www.bousai.pref.fukuoka.jp
    </a> ）
    </p>
    <h2>Sổ tay phòng chống thiên tai</h2>
    <p>
    Chúng tôi đã biên soạn ”Sổ tay phòng chống thiên tai cho người nước ngoài'' bằng 9 ngôn ngữ, vui lòng kiểm tra trước và chuẩn bị ứng phó với thảm họa.
    </p>
    <p>
    （URL:<a href="https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html" target="_blank">https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html</a>）
    </p>
    <p>
    Nội dung đăng tải<br/>
    ●Nội dung về các thảm họa có thể xảy ra<br/>
    ●Hành động cần thiết khi thảm hoạ xảy ra<br/>
    ●Chuẩn bị hàng ngày để đối phó với thảm họa<br/>
    ●Nơi sơ tán<br/>
    ●Thông tin liên lạc khẩn cấp…<br/>
    ●Các ngôn ngữ đang được hỗ trợ: tiếng Nhật dễ hiểu, tiếng Anh, tiếng Trung, tiếng Hàn, tiếng Tagalog, tiếng Việt, tiếng Nepal, tiếng Indonesia và tiếng Myanmar.
    </p>
    <p>
    <a href="https://www.pref.fukuoka.lg.jp/uploaded/life/671824_61619901_misc.pdf" target="_blnak">
    <figure>
    <img src={publicPath('images/articles/disasterprevantionhandbook.png')} alt="disaster prevantion handbook" className="fit-img"/>
    </figure>
    </a>
    </p>

    <h2>Huấn luyện phòng chống thiên tai dành cho người nước ngoài Thêm vào đó,</h2>
    <p>
    chúng tôi tổ chức các buổi diễn tập phòng chống thiên tai cho cư dân nước ngoài để họ có thể đưa ra các hành động phù hợp trong trường hợp xảy ra thảm họa. Hãy tham gia và trải nghiệm thực tế những hành động cần thực hiện khi có thiên tai.
    </p>
    <p>
    ● Bài giảng về các thảm họa thường gặp ở Nhật Bản<br/>
    ● Học tập trải nghiệm (lắp ráp các thiết bị được sử dụng tại nơi sơ tán, trải nghiệm sử dụng đồ phòng chống thiên tai tức thì)…
    </p>
    <figure>
    <img src={publicPath('images/articles/disasterinfo01.jpg')} alt="" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/disasterinfo02.jpg')} alt="" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/disasterinfo03.jpg')} alt="" className="fit-img"/>
    </figure>
    <h2>Trung tâm hỗ trợ đa ngôn ngữ khi xảy ra thiên tai </h2>
    <p>
    Thiết lập “Trung tâm hỗ trợ đa ngôn ngữ khi xảy ra thiên tai ” nhằm góp phần giúp chính quyền địa phương trong việc hỗ trợ người nước ngoài khi xảy ra thảm hoạ quy mô lớn.
    </p>
    <p>
    ● Đặt Trụ sở ứng phó thảm hoạ tỉnh Fukuoka trong Trung tâm giao lưu quốc tế.<br/>
    ●Khi được thiết lập, trung tâm sẽ hoạt động 24/7. (Có thể thay đổi tùy thuộc vào tình hình thảm hoạ.)<br/>
    ●Nhiệm vụ chính của Trung tâm hỗ trợ đa ngôn ngữ như sau.<br/>
    ⇒Thu thập thông tin về tình hình của người nước ngoài từ các địa phương bị ảnh hưởng bởi thảm hoạ.<br/>
    ⇒Hỗ trợ thông dịch biên dịch cho việc cung cấp thông tin khi xảy ra thảm họa, vận hành khu sơ tán theo yêu cầu từ các địa phương bị ảnh hưởng bởi thảm hoạ.<br/>
    ⇒Phái cử tình nguyện viên phiên dịch/biên dịch đến khu vực thảm họa theo yêu cầu từ địa phương bị ảnh hưởng bởi thảm họa.
    </p>
    </EmphasisNote>
    <br/>
    <NavigatorNice dir="right"></NavigatorNice>
    </>)
}

export default LangVi