import { PropsWithChildren } from "react";
import styled from "styled-components";


const SEmphasis = styled.div`
    background: ${props => props.theme.colors.gray};
    color: #fff;
    margin: 0;
    padding: 20px;
    font-size: ${props => props.theme.fontSize.txt_sp};
    line-height:${props => props.theme.lineheight.txt_sp};
    
    p{
        margin-bottom: 20px;
       
        &:last-child{
            margin-bottom: 0;
        }
    }
    h2,.title-middle{
        font-size: ${props => props.theme.fontSize.h2_sp};
        line-height:  ${props => props.theme.lineheight.h2_sp};
        border: solid 1px ${props => props.theme.colors.orange};
        color : ${props => props.theme.colors.orange};
        padding: 10px 20px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 10px;
        background: none;
        span{
            font-size: 100%;
        }
    }
    a{
        color:#fff
    }
    a:hover{
        color:${props => props.theme.colors.orange};
    }
    &.picup{
        position: relative;
        &::before{
            content: '';
            width: 100%;
            padding-bottom: 20%;
            position: absolute;
            top: -10%;
            background: url('./images/icon_picup.webp') no-repeat;
            background-position:center;
            background-size: auto 100%;
            left: 0;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minSp}) {
        font-size: ${props => props.theme.fontSize.txt_pc};
        line-height:${props => props.theme.lineheight.txt_pc};
        
    }
    
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        &.picup{
            &::before{
                height: auto;
                padding-bottom: 0;
                aspect-ratio: 100/7;
            }
        }
        margin: 40px 0;
    }
`

const EmphasisNote 
    = ({children, ...props}:PropsWithChildren<{className?:string}>) => 
{

    return (
        <SEmphasis className={props.className}>
            {children}
        </SEmphasis>
    )
}

export default EmphasisNote