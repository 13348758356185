import HeadLine from "@/components/Atoms/HeadLine"
import { PropsWithChildren } from "react"
import { langType } from "src/types/lang"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import { pageTitleProps } from "src/components/Molecules/PageTitle"
import TrapezoidTitle from "@/components/Atoms/HeadLine/trapezoidTitle"

const SPageTile = styled.div`
    margin-bottom: 30px;
    .headlineWrap{
        position: relative;
        width: 100%;
        aspect-ratio: 10 / 5;
        &::after{
            width: 100%;
            aspect-ratio: 10 / 5;
            background: url(${publicPath('images/headline/cartoon.png')}) no-repeat;
            background-size: auto 100%;
            background-position: center;
            content: '';
            position: absolute;
            top: 0;
        }
        .headline{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 80%;
            transform: translate(-50%, -35%);
            z-index: 11;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .headlineWrap{
            max-width: 500px;
        }
        margin-bottom: 0;
    }
`
const STrapezoidPositon = styled.div`
    margin-top: -12%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        margin-top:0;
    }
`

const STrapezoidTitle = styled.h1`
    padding:45px 10px 30px;
    text-align: center;
    color: #fff;
    font-size: ${props => props.theme.fontSize.h3_sp};
    line-height: 2.6rem;
    background: ${props => props.theme.colors.black};
    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 100%); 
    width: 100%;
    white-space: pre-wrap;
    position: relative;
    &::after{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        background: url('./images/virtual/bk_wrap_pc.webp') no-repeat;
        background-size: 100% auto;
        top: 0;
        left: 0;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        position: relative;
        aspect-ratio: 10 / 1.5;
        padding: 0;
       
        .children{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            font-size: 3.0rem;
            letter-spacing: 0.2rem;
            line-height: 3.6rem;
        }
    }
`

const PageTitleVirtual = ({
    lang,
    children,
    ...props
} : PropsWithChildren<pageTitleProps>) => {

    return (
        <SPageTile>
            <div className="headlineWrap">
                <div className="headline">
                    <HeadLine lang={lang} nameKey="virtual"/>
                </div> 
            </div>
            <STrapezoidPositon>
                <STrapezoidTitle>
                    <span className="children">{children}</span>
                </STrapezoidTitle>
            </STrapezoidPositon>
           
        </SPageTile>
       
    )
    
}

export default PageTitleVirtual