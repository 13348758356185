
const LangNe = () => {

    return (<>
    <h1>FUKUOKA IS OPEN को बारेमा</h1>
    <p>
    फुकुओका प्रान्त मित्रता साझेदार क्षेत्रहरूको संख्या、अन्तर्राष्ट्रिय विद्यार्थीहरूको संख्या आदिमा、देशकै उच्च स्थानमा हुनुका साथै、प्रान्तमा बसोबास गर्ने विदेशी बासिन्दाहरूको संख्या ८९५१८ रहेको र हाल सम्मको नयाँ उच्च रेकर्ड हो।
    </p>
    <p>
    अब देखि、 घट्दो जनसङ्ख्या भएको समाजमा क्षेत्रीय जीवन्तता कायम राख्नको लागि、प्रान्तभित्रका कम्पनीहरूमा कामदारको रूपमा र स्थानीय समुदायमा नयाँ वाहकको रूपमा विदेशी मानव संसाधनले सक्रिय भूमिका खेल्नु अत्यावश्यक छ।
    </p>
    <p>
    यी परिस्थितिहरूमा, विदेशबाट अझ धेरै मानिसहरूलाई बोलाउन को लागि、「FUKUOKA IS OPEN」भन्ने क्याचफ्रेज अन्तर्गत、विश्वको तर्फ यस प्रान्तको नीतिहरूलाई फैलाउदै、यस प्रान्त विदेशीहरूका बस्न सजिलो हुने、जिबनयापन गर्न सकिने ठाउँ भएको कुरा थाहा दिने विचार गरका छौ।
    </p>
    <p>
    तसर्थ, बहुभाषिक पोर्टल साइट 「FUKUOKA IS OPEN」 (<a href="https://www.fisop.net">https://www.fisop.net</a>) ले、यस प्रान्तको शिक्षा र चिकित्सा हेरचाह आदिको विदेशीहरूको लागि जीवन-सम्बन्धित जानकारी प्रदान गर्ने साथै、अन्तर्राष्ट्रिय विद्यार्थी、इनबाउन्ड、अन्तर्राष्ट्रिय वित्तीय कार्यहरू र खेलकुदहरूलाई आकर्षित गर्दै、वातावरण、one health आदिको उन्नत उपायहरू बारे जानकारी、विदेशी बासिन्दाहरूको गतिविधिहरूमा नवीनतम जानकारी प्रदान गर्नेछौं।
    </p>
    <br/>
    <h5 className="title-large">FUKUOKA IS OPEN ओरिजिनल लोगोमार्क प्रयोगको बारेमा</h5>
    <p>यस लोगोमार्कलाई FUKUOKA IS OPEN प्रवर्द्धन परियोजना कार्यसमितिको स्वीकृति बिना प्रयोग गर्न निषेध गरिएको छ।<br/>
    प्रयोगको लागि, प्रयोग निर्देशिका बमोजिम, प्रयोग अनुमतिको लागि निवेदन दिनु आवश्यक छ।<br/>
    प्रयोग निर्देशिका तथा प्रयोग गाइड लाइन पढी,
    "(फारामको ढाँचा १), FUKUOKA IS OPEN प्रवर्द्धन परियोजना लोगोमार्क प्रयोग अनुमतिको निवेदन फाराम" मा आवश्यक विवरण भरी, फ्याक्स गरी पेश गर्नुहोस्।</p>
    <br/>
    <p>निवेदनका विवरणहरु चेकजाँच गरे पश्चात, स्वीकृत पत्र तथा लोगोमार्कको डेटा हस्तान्तरण गरिने छ।</p>
    <br/>
    <p>
    【निवेदनका लागि आवश्यक कागजात आदि】
    </p>
    <ul className="dotto">
    <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOPromotionProjectLogoUsageInstructions.pdf" target="_blank">FUKUOKA IS OPEN प्रवर्द्धन परियोजना लोगोमार्क प्रयोग निर्देशिका</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form1_FIOPromotionProjectLogoApplicationForm.docx">(फारामको ढाँचा १), FUKUOKA IS OPEN प्रवर्द्धन परियोजना लोगोमार्क प्रयोग अनुमतिको निवेदन फाराम</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form4_FIOPromotionProjectLogoApplicationForm.docx">(फारामको ढाँचा ४) FUKUOKA IS OPEN प्रवर्द्धन परियोजना लोगोमार्क प्रयोग स्वीकृति परिवर्तनको निवेदन फाराम</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form5_FIOPromotionProjectLogoUsageStatusReport.docx">(फारामको ढाँचा ५) FUKUOKA IS OPEN प्रवर्द्धन परियोजना लोगोमार्क प्रयोगको अवस्था बारेको प्रतिवेदन</a></span></li>
    <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOLOGOUSERGUIDELINE.pdf" target="_blank">[FUKUOKA IS OPEN] LOGO USER GUIDELINE</a></span></li>
    </ul>
    <br/>
    <p>
    【सम्पर्क स्थान】<br/>
    FUKUOKA IS OPEN प्रवर्द्धन परियोजना कार्यसमितिको सचिवालय (फुकुओका-केन अन्तराष्ट्रिय विभाग, अन्तराष्ट्रिय नीति निर्माण शाखा, विदेशी मानव संसाधन सहायता इन्चार्ज)
    </p>
    <br/>
    <p>
    TEL：<a href="tel:+81-92-643-3201">+81-92-643-3201</a><br/>
    FAX：<a href="tel:+81-92-643-3224">+81-92-643-3224</a><br/>
    ८:३० बजे देखि १७:१५ बजे सम्म ( ९ बजे देखि १७:४५ बजे सम्म)<br/>
    यद्यपि, शनिवार, आइतवार, सार्वजनिक बिदा तथा डिसेम्बर २९ तारिख देखि जनवरी ३ तारिख बाहेक
    </p>
    </>)
}

export default LangNe