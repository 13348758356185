import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import { DlNote } from "@/components/Atoms/TextBlock/dlNote"
import { TabTamplate } from "@/components/Templates/tab"


const LangZhTw = ({activeTab, handleTabClick}) => {

    return (<>
    <InnerBox bottom={60}>
    <h1>
    市町村谘詢窗口/國際交流協會
    </h1>
    <p>
    市町村設立了谘詢窗口和國際交流協會，以解決外國人麵臨的各種問題並促進國際交流。 這些窗口提供語言支持、法律谘詢和日常生活方麵的全方位支持。
    </p>
    <h2>市町村谘詢窗口</h2>
    <DlNote color="orange">
    <dl>
        <dt>
        Fukuoka Region
        </dt>
        <dd>
        ●Fukuoka Consultation Center for Foreign Residents<br/>
        ●TEL. 092-725-9207<br/>
        0120-279-906<br/>
        ●Supported language : Japanese, English, Chinese, Korean, Vietnamese, Nepal, Indonesian, Tagalog, Thai, Malay, Spanish, French, German, Italian, Russian, Portuguese, Myanmar, Khmer, Mongolian, Sinhalese, Hindi, Bengali<br/>
        ●Reception hours : Every day (except year-end and New Year holidays) 10:00-18:30<br/>
        *Reception hours will change from 18:30 to 18:00 from January 4, 2024<br/>
        ●Address : 3F Across Fukuoka, 1-1-1 Tenjin, <br/>
        Chuo-ku, Fukuoka City, Fukuoka　<br/>
        ●Consultation form： International Center Kokusai Hiroba Entrance In person, by phone, or email<br/>
        ● Mail:fukuoka-maic@kokusaihiroba.or.jp<br/>
        </dd>
        <dd>
        ●（Public）Fukuoka Yokatopia International Exchange Foundation<br/>
        Fukuoka City Consultation Support Center for Foreign Residents<br/>
        ●TEL.092-262-1799<br/>
        0120-66-1799<br/>
        ●Supported language : Japanese, English, Chinese, Korean, Thai, Vietnamese, Indonesian, Portuguese, Spanish, French, German, Italian, Russian, Nepal, Tagalog, Malay, Myanmar, Khmer, Mongolian, Sinhala, Hindi, Bengali<br/>
        ※Ukraine Displaced Persons Support Worker<br/>
        ※Vietnamese Language Counseling and Support Staff (Tuesdays and Fridays, 10:00 - 16:00)<br/>
        ※Nepalese Language Consultant (Mon. 9:00 - 17:30)<br/>
        ※Korean Language Consultant (Thu. 10:00 - 16:00)<br/>
        ●Reception hours : Weekdays (except national holidays and year-end and New Year holidays) 9:00～17:30  <br/>
        ●Address :  4-1 Tenya-cho, Hakata-ku, Fukuoka City, Fukuoka Fukuoka International House 1F Interviews,<br/>
        ●Consultation form：phone calls, LINE calls, e-mails<br/>
        ●Inquiry Form：<a href="https://www.fcif.or.jp/contact/" target="_blank">https://www.fcif.or.jp/contact/</a><br/>
        Emails are machine translated except for Japanese, English, Chinese, and Korean<br/>
        In addition to foreign language consultation staff, telephone interpretation and tablet terminals are available.<br/>
        </dd>
        <dd>
        ●Sue Town One-Stop Consultation Center for Foreigners <br/>
        ●TEL.070-4066-5327<br/>
        ●Supported language : Japanese, Vietnamese, English<br/>
        Other languages are supported by translation software<br/>
        ●Reception hours : Weekdays（Excluding national holidays and year-end and New Year holidays）9:00～17:00<br/>
        ●Address : 804-1 Oaza Sue, Sue-cho, Kasuya-gun <br/>
        ●Consultation form： International Center Kokusai Hiroba Entrance In person, by phone, or email<br/>
        </dd>
        <dd>
        ●Koga City Multicultural Consultation Desk<br/>
        ●TEL. 092-942-1165<br/>
        ●Supported language : Japanese, English<br/>
        （Other languages are supported by multilingual interpretation tablets and translation devices）<br/>
        ●Reception hours : Weekdays 8:30～17:00（Excluding national holidays and year-end and New Year holidays）<br/>
        ●Address : 1-1-1 Eki-higashi, Koga City<br/>
        Koga City Hall, 2nd Floor, Community Development Promotion Division In person, <br/>
        ●Consultation form： International Center Kokusai Hiroba Entrance In person, by phone, or email<br/>
        ● Mail:<br/>
        kokusai@city.koga.fukuoka.jp<br/>
        </dd>
        </dl>
        </DlNote>
        <br/>
        
        <DlNote color="orange">
        <dl>
        <dt>Kitakyushu Region</dt>
        <dd>
        Kitakyushu City Multicultural <br/>
        One-Stop Information Center（Kurosaki）<br/>
        080-6445-2606<br/>
        ≪23 Languages≫<br/>
        Japanese, English, Chinese, Korean, Vietnamese, Tagalog, Nepal, Thai, Indonesian, Malay, Mongolian, Khmer, Myanmar, Hindi, Bengali, Sinhalese, Russian, Portuguese, Spanish, French, Italian, German, Ukrainian<br/>
        *Email support is only available in Japanese, English, Chinese, Korean, <br/>
        and Vietnamese Weekdays (except national holidays and year-end and New Year holidays) 9:30～16:00<br/>
        3-15-3 Kurosaki, Yahatanishi-ku, Kitakyushu-shi, Kitakyushu<br/>
        Kitakyushu International Association<br/>
        International Center Kokusai Hiroba Entrance In person, by phone, or email<br/>
        <br/>
        ※In addition to foreign language counselors, tablet terminals and telephone interpretation services are available.　　　　　　　　　　　　　　　　　<br/>
        ※Supported languages are subject to change.
        </dd>
        <dd>
        Kitakyushu City Multicultural One-Stop Information Center（Kokura）<br/>
        080-5278-8404<br/>
        ≪23 Languages≫<br/>
        Japanese, English, Chinese, Korean, Vietnamese, Tagalog, Nepal, Thai, Indonesian, Malay, Mongolian, Khmer, Myanmar, Hindi, Bengali, Sinhalese, Russian, Portuguese, Spanish, French, Italian, German, Ukrainian<br/>
        *Email support is only available in Japanese, English, Chinese, Korean, <br/>
        and Vietnamese Weekdays (except national holidays and year-end and New Year holidays) 9:30～12:00、13:00～16:00<br/>
        1-1 Otemachi, Kokurakita-ku, Kitakyushu City<br/>
        International Center Kokusai Hiroba Entrance In person, by phone, or email<br/>
        <br/>
        ※In addition to foreign language counselors, tablet terminals and telephone interpretation services are available.　　　　　　　　　　　　　　　　　<br/>
        ※Supported languages are subject to change.
        </dd>
        <dd>
        Kanda Town One-Stop Consultation Service for Foreigners<br/>
        080-7310-7394<br/>
        Japanese, English<br/>
        Telephone interpretation service is available for other languages<br/>
        Weekdays（Excluding national holidays and year-end and New Year holidays）<br/>
        9:00～11：30/13：00～16：30 <br/>
        1-19-1, Tomikucho, Kanda-cho, Kyoto-gun <br/>
        In person, by phone
        </dd>
        
        </dl>
        </DlNote>
        <br/>
        <DlNote color="orange">
        <dl>
        <dt>Chikugo Region</dt>
        <dd>
        ●Kurume City Consultation Service for Foreign Residents<br/>
        （Public Hearing and Consultation Section, Cooperation Promotion Department）<br/>
        ●TEL. 0942-30-9096<br/>
        ●Supported language : Japanese, English, etc.<br/>
        （15 other languages are supported by the multilingual video interpretation application）<br/>
        ●Reception hours : Monday-Friday 8:30～17:15<br/>
        ●Address : 5-3 Jonan-cho, Kurume City Kurume City Hall 6F<br/>
        ●Consultation form：International Center Kokusai Hiroba Entrance In person, by phone, or email<br/>
        ●Email：sodan@city.kurume.lg.jp<br/>
        We connect you to the Fukuoka Immigration and Residency Management Bureau, lawyers, and other specialized consultation services (14 organizations) provided by the Public Hearing and Consultation Section.
        </dd>
        </dl>
        </DlNote>
        <br/>
        <DlNote color="orange">
        <dl>
        <dt>Chikuho Region</dt>
        <dd>
        ●Iizuka City Consultation Service for Foreign Residents<br/>
        ●TEL. 0948-22-5500<br/>
        ●Supported language : Japanese, English, Vietnamese<br/>
        （Other languages are supported by multilingual interpretation tablets and translation devices）<br/>
        ●Reception hours : Weekdays 8:30 - 17:15 (except national holidays and year-end and New Year holidays)<br/>
        ●Address : 5-5 Shin-Tateiwa, Iizuka City<br/>
        International Policy Division, Iizuka City Hall 6th Floor<br/>
        ●Consultation form：nternational Center Kokusai Hiroba Entrance In person, by phone, or email<br/>
        ●Email：kokusai@city.iizuka.lg.jp<br/>
        </dd>
        </dl>
    </DlNote>
    </InnerBox>
    <InnerBox bottom={0}>
    <h2>
    國際交流協會
    </h2>
    <TabTamplate>
    <div className="tabs-custom">
    <nav>
    <div>
    <button onClick={() => {handleTabClick('tab1')}}
    className={activeTab === 'tab1' ? 'tab-button active' : 'tab-button'}>
    Fukuoka City
    </button>
    </div>
    <div>
    <button onClick={() => {handleTabClick('tab2')}}
    className={activeTab === 'tab2' ? 'tab-button active' : 'tab-button'}>
    Kitakyushu Region
    </button>
    </div>
    <div>
    <button onClick={() => {handleTabClick('tab3')}}
    className={activeTab === 'tab3' ? 'tab-button active' : 'tab-button'}>
    Chikugo Region
    </button>
    </div>
    <div>
    <button onClick={() => {handleTabClick('tab4')}}
    className={activeTab === 'tab4' ? 'tab-button active' : 'tab-button'}>
    Chikuho Region
    </button>
    </div>
    </nav>
    <div className={activeTab === 'tab1' ? 'tab-content active' : 'tab-content'} data-tab="tab1">
    <DlNote color="orange">
    <dl>
    <dt>Fukuoka City</dt>
    <dd>
    ●（Public）Fukuoka Yokatopia International Exchange Foundation<br/>
    ●TEL.092-262-1700<br/>
    HP<a href="https://www.fcif.or.jp/" target="_blank">https://www.fcif.or.jp/</a><br/>
    ●Commemorating the Asia Pacific Expo - Fukuoka '89, promoting international exchange among citizens, supporting foreign residents and foreign students, and fostering global human resources.
    </dd>
    <dd>
    ●Kasuga City International Exchange Network Association<br/>
    ●TEL.092-981-0118<br/>
    HP<a href="https://www.city.kasuga.fukuoka.jp/" target="_blank">https://www.city.kasuga.fukuoka.jp/</a><br/>
    miryoku/kouryuu/1002680.html<br/>
    ●Raise awareness of international understanding at city cultural festivals, etc., and exchange information and collaborate with other international exchange groups.
    </dd>
    <dd>
    ●Onojo City International Exchange Association<br/>
    ●TEL.090-2067-3173<br/>
    ●HP<br/>
    <a href="http://www.city.onojo.fukuoka.jp/030/080/010/index.html" target="_blank">http://www.city.onojo.fukuoka.jp/030/080/010/index.html</a><br/>
    ●Cross-cultural exchange (International Exchange Festival, Language Exchange Plaza (Japanese language classes), etc.), TOMORROW (youth development projects), publication of MISHOP newsletter (BEGIN Newspaper), etc.
    </dd>
    <dd>
    ●Munakata Area International Exchange Liaison Council<br/>
    ●TEL.0940-36-1169 <br/>
    <br/>
    ●1-1-1 Togo, Munakata City, 811-3492, Munakata City Education Department, Education Policy Division, Regional Education Collaboration Office, Global Human Resource Development Section
    </dd>
    <dd>
    ●（Public）Dazaifu City International Association<br/>
    ●TEL.092-918-5391<br/>
    HP<a href="http://www.dciea.or.jp/" target="_blank">http://www.dciea.or.jp/</a><br/>
    ●International exchange promotion projects (Friends Bell Club member gatherings, world culture experience classes, etc.), foreign student support projects (foreign student forums, Japanese culture experience classes), support projects for foreign residents (Japanese language classes support, lifestyle support guidebooks)
    </dd>
    <dd>
    ●Itoshima City International Exchange Association<br/>
    ●TEL.092-332-9166<br/>
    ●HP<br/>
    <a href="http://www.window-kokusai.com/" target="_blank">http://www.window-kokusai.com/</a><br/>
    ●Exchange with the Gimhae Cultural Institute in Gimhae, Korea, a friendship organization, curry buffet, Soumen-nagashi, world cooking classes with foreign residents as lecturers, international cooperation projects, publication of PR magazine (3 times a year), etc.
    </dd>
    <dd>
    ●Nakagawa City International Cultural Exchange Circle<br/>
    ●TEL.092-952-2092 <br/>
    ●HP<br/>
    <a href="https://nakagawakokusai.wordpress.com/" target="_blank">https://nakagawakokusai.wordpress.com/</a><br/>
    ●Theatrical performances by the Yamamomo Theater Company, English conversation classes, Chinese language classes, bus hiking exchange events, food and culture bazaar at the Citizens' Cultural Festival, participation in the Farewell Party for Foreign Students, etc.
    </dd>
    <dd>
    ●Sue International Exchange Association<br/>
    ●TEL.092-935-1323<br/>
    <br/>
    ●Language training programs, cross-cultural exchange programs, local exchange programs, public relations programs
    </dd>
    </dl>
    </DlNote>
    </div>

    <div className={activeTab === 'tab2' ? 'tab-content active' : 'tab-content'} data-tab="tab2">
    <DlNote color="orange">
    <dl>
    <dt>Kitakyushu Region</dt>
    <dd>
    ●（Public）Kitakyushu International Exchange Association<br/>
    ●TEL.093-643-5931<br/>
    HP<a href="https://www.kitaq-koryu.jp/" target="_blank">https://www.kitaq-koryu.jp/</a><br/>
    ●Support for foreign residents and students, promotion of Japanese language education, liaison and coordination with international exchange organizations, and promotion of activities
    </dd>
    <dd>
    ●Ashiya International Exchange Association<br/>
    ●TEL.093-223-0881（Town Hall Representative） <br/>
    <br/>
    ●International exchange parties, cultural exchange programs (cooking classes, bus hikes), publication of PR magazines
    </dd>
    </dl>
    </DlNote>
    </div>

    <div className={activeTab === 'tab3' ? 'tab-content active' : 'tab-content'} data-tab="tab3">
    <DlNote color="orange">
    <dl>
    <dt>Chikugo Region</dt>
    <dd>
    ●（Public）Kurume Tourism Convention International Association<br/>
    ●TEL.0942-31-1717<br/>
    HP<a href="https://welcome-kurume.com/" target="_blank">https://welcome-kurume.com/</a><br/>
    ●Conventions, tourism, and international exchange projects (friendship and sister city exchanges centered on youth, promotion of international exchange at the citizen level, support for foreign residents and foreign students, etc.)
    </dd>
    <dd>
    ●Ogori International Association<br/>
    ●TEL.0942-72-2111（Ext. 222）<br/>
    ●HP（Facebook）<br/>
    「Ogori International Association」<br/>
    <a href="http://ja-jp.facebook.com/おごおり国際交流協会-Ogori-International-Association-139743002867410/" target="_blank">
    http://ja-jp.facebook.com/おごおり国際交流協会-Ogori-International-Association-139743002867410/
    </a>
    <br/>
    ●International understanding classes, Japanese language classes for foreigners, stalls at Ogori Citizens' Festival, etc.
    </dd>
    <dd>
    ●(Foundation）HISHINOMI International Exchange Center
    ●TEL.080-7936-4027<br/>
    ●HP（Facebook）<br/>
    <a href="https://www.facebook.com/Hishinomi/" target="_blank">https://www.facebook.com/Hishinomi/</a>
    <br/>
    ●Overseas Training Programs International Exchange Promotion Programs Public Relations and Awareness Programs
    </dd>
    <dd>
    ●（Company）Yame International Exchange Association<br/>
    ●TEL.090-7382-6650<br/>
    <br/>
    ●Dissemination and promotion of Japanese and foreign arts and culture
    </dd>
    </dl>
    </DlNote>
    </div>

    <div className={activeTab === 'tab4' ? 'tab-content active' : 'tab-content'} data-tab="tab4">
    <DlNote color="orange">
    <dl>
    <dt>Chikuho Region</dt>
    <dd>
    ●Iizuka International Exchange Promotion Council<br/>
    ●TEL.0948-22-5500<br/>
    <br/>
    ●International exchange events such as speech contests, support for foreign students, home visit programs, etc.
    </dd>
    <dd>
    ●Itoda International Exchange Promotion Association<br/>
    ●TEL.0947-26-0038<br/>
    <br/>
    ●Agricultural Trainee Exchange Programs
    </dd>
    </dl>
    </DlNote>
    </div>
    </div>
    </TabTamplate>
    <br/>
    <NavigatorCheck dir="right"></NavigatorCheck>
    </InnerBox>
    </>)
}

export default LangZhTw