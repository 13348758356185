const LangNe = () => {

    return (<>
    <h1>सुलभताको बारेमा</h1>
    <dl>
    <dt>
    पहलहरु
    </dt>
    <dd>
    <p>फुकुओका प्रान्तले जसले पनि प्रयोग गर्न सक्ने गरी जापानी औद्योगिक मापदण्ड「JIS X 8341-3:2016」को आधारमा वेबसाइटको पहुँचलाई कायम राख्न र सुधार गर्न प्रयास गर्नेछौ।</p>
    </dd>
    <dt>
    १. लक्षित दायरा
    </dt>
    <dd>
    <p>
    https://fisop.net डोमेन अन्तर्गत आधिकारिक होमपेज
    </p>
    </dd>
    <dt>
    २. लक्ष्य अनुरूपता स्तर
    </dt>
    <dd>
    <p>
    अनुपालन स्तर: JIS X 8341-3:2016 वेब पहुँच अनुपालन स्तर AA संग अनुरूप<br/>
    (दोस्रो लेयरसम्म वेब एक्सेसिबिलिटी कन्फर्मेन्स लेभल AA को पालना गर्ने लक्ष्यका साथ निरन्तर परिमार्जनहरू गरिनेछन्)<br/>
    「अनुरूपता स्तर AA संग अनुरूप」भन्ने सङ्केत、सूचना र सञ्चार पहुँच परिषद वेब पहुँच पूर्वाधार समिति「वेब सामग्री JIS X 8341-3:2016अनुकूलता स्तर संकेतन दिशानिर्देश मार्च २०१६ संस्करण」मा निर्दिष्ट नोटेशन मा आधारित भएर अनुपालन स्तर A को पालना गर्नुको साथै अनुरूपता स्तर AA को सफलता मापदण्ड पूरा गर्नु यसको अर्थ हो।<br/>
    <br/>
    <a href="https://waic.jp/docs/jis2016/compliance-guidelines/202104/" target="_blank">
    वेब सामग्रीकोJIS X 8341-3:2016 अनुकूलता स्तर संकेतन दिशानिर्देश नयाँ विन्डोमा खुल्छ (बाहिरी लिङ्क)
    </a>
    </p>
    </dd>
    </dl>
    </>)
}

export default LangNe