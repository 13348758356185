import publicPath from "src/utilty/publicPath"
import styled from "styled-components"

const SCopyright = styled.div`
    background: ${props => props.theme.colors.black};
    width: 100%;
    text-align: center;
    padding:10px 0 15px;
`
export const Copyright = () => {

    return(
       <SCopyright><img src={publicPath('images/copyright.svg')} height={11}/></SCopyright>
    )
}  

