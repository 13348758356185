import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"

const SFroeign = styled.div`
    .navposition01{
        position: relative;
        width: 100%;
        height: 50px;
        figure{
            position: absolute;
            z-index: 11;
            width: 70%;
            top: 0;
            right: 20px;
            transform: translateY(-80%);
        }
    }
    @media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
    (max-width: ${props => props.theme.responsive.minPc}) ),
    screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
        .navposition01{
            figure{
                transform: translateY(-60%);
            }
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .navposition01{
            height: 250px;
            figure{
                width: 400px;
                right: auto;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }
`

export const SDecTextUl = styled.ul`
    li{
        position: relative;
        .xl{
            font-size: 50px !important;
        }
        padding-bottom: 5%;
        margin-bottom: 10px;
        &::after{
            content: '';
            width: 100%;
            aspect-ratio: 20 / 1;
            bottom: 0;
            left: 0;
            position: absolute;
            background: url(${publicPath('images/ster.png')}) no-repeat;
            background-size: auto 100%;
            background-position: center;
        }
        &:last-child{
            margin-bottom: 0px;
            &::after{
                display: none;
            }
        }
    }  
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        li{
            padding-top: 20px;
            &:nth-child(4){
                &::after{
                    display: none;
                }
            }
        }
    }

`

const BasciInfoForeign = ({lang}:{lang:langType}) => {

    setTitleDiscrption({
        layer:'basicinfo',
        lang:lang,
        subLayer:"foreign"
    })

    return(
        <SFroeign>
        {lang === 'ja' &&
            <LangJa></LangJa>
        }
        {lang === 'easy_ja' &&
            <LangJa></LangJa>
        }
        {lang === 'en' &&
            <LangEn></LangEn>
        }
        {lang === 'ko' &&
            <LangKo></LangKo>
        }
        {lang === 'zh_cn' &&
            <LangZhCn></LangZhCn>
        }
        {lang === 'zh_tw' &&
            <LangZhTw></LangZhTw>
        }
        {lang === 'th' &&
            <LangTh></LangTh>
        }
        {lang === 'vi' &&
            <LangVi></LangVi>
        }
        {lang === 'ne' &&
            <LangNe></LangNe>
        }

        </SFroeign>
    )
}

export default BasciInfoForeign