
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import { ChallengerrFullLength } from "@/components/Atoms/Character/challenger"
import { NavigatorFullLength } from "src/components/Atoms/Character/navigator"
import { Flogo } from "@/components/Atoms/FLogo"
import LangNav from "@/components/Molecules/LangNav"
import SiteNav from "@/components/Molecules/SiteNav"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { pathAnalyzer } from "src/utilty/pathAnalyzer"
import { langType } from "src/types/lang"

const SSideSection = styled.section`
    width: 300px;
    background: ${props => props.theme.colors.black};
    height: 100%;
    overflow-y: scroll;
    position: relative;
    &::before{
        content: '';
        width: 300px;
        height: 100%;
        position: fixed;
        background: url(${publicPath('images/menuBk_pc.svg')}) no-repeat;
        background-size: 100% auto;
        background-position: bottom;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
    .SSideSectionWrap{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .logo{
        width: 100%;
        padding:10px 0;
        text-align: center;
        background: #fff;
    }
    .lang{
        padding: 20px;
        width: 200px;
        margin: 0;
        z-index: 11;
    }
    .nav{
        padding:0 20px;
        padding-bottom: 50px;
    }
`

const SChallengerrPositon = styled.div`
    position: absolute;
    width: 70%;
    top: 2%;
    left:0%;
    z-index: 1;
`

const SNavigatorPositon = styled.div`
    position: absolute;
    width: 45%;
    right: 0%;
    top: 30%;
    z-index: 2;
`

const SHeadline = styled.h2`
    position: absolute;
    width: 100%; 
    top: 60%;
    z-index: 4;
`
const SMessage = styled.div`
    position: absolute;
    width: 65%;
    top: 14%;
    right: 0%;
    z-index: 3;
    figure{
        padding: 0;
        margin: 0;
    }
`


const PCSideLeft = () => {
    const [lang, setLang] = useState<langType>('ja')
    let location = useLocation();
    useEffect(() => {
        const path = pathAnalyzer(location)
        setLang(path.lang)
    },[location])

    return (
        <SSideSection>
            <div className="SSideSectionWrap">
                <div className="logo">
                    <Flogo width={140} lang={lang}></Flogo>
                </div>
                <div className="lang">
                    <LangNav></LangNav>
                </div>
                <div className="nav">
                    <SiteNav lang={lang}></SiteNav>
                </div>
            </div>
        </SSideSection>
    )
}

export default PCSideLeft