
import { NavigatorNice } from "@/components/Atoms/Character/navigator"
import { FukidashiBlueBottom } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorFukidashiPicup } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"

const LangJa = () => {

    return (
        <>
        <h1>災害発生時における外国人支援</h1>
            <p>
            海外から来られた皆様が、災害発生時に適切に行動し、本県で安心・安全に暮らしていくためには日頃からの備えが大変重要です。<br/>
            本県では様々な形で情報提供を行っています。災害に関することを学ぶことで、自分の命を守り、ご家族や周りの友人など、大切な人の命を守れるようになってください。
            </p>
            <NavigatorFukidashiPicup dir="right">
                災害発生時には、災害情報や避難情報を確実に取得することが重要です。<br/>
                つぎのサイトやアプリを活用すると、簡単に情報収集ができます。
            </NavigatorFukidashiPicup>
            <br/>
            <EmphasisNote>
                
                <h2>防災アプリ<br/>「ふくおか防災ナビ・まもるくん」<br/>のダウンロード</h2>
                <p>
                災害情報等を配信しています。<br/>
                利用・登録は無料で、簡単操作で防災情報が分かるので、ぜひ登録してください。
                </p>
                <p>
                ●現在地の防災情報をプッシュ通知でお知らせしています。<br/>
                ●避難所の場所を地図で分かりやすく示しています。<br/>
                ●災害時に取るべき行動をイラストで掲載しています。<br/>
                ●災害時に家族の位置情報を確認できます。<br/>
                ●言語は日本語、英語、中国語（簡体・繁体）、韓国語、ベトナム語に対応しています。
                    </p>
                    <figure>
                    <img src={publicPath('images/articles/mamorukun01.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
                    </figure>
                    <figure>
                    <img src={publicPath('images/articles/mamorukun02.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
                    </figure>
                    
                <div className="app_qr">
                    <a href="https://apps.apple.com/jp/app/%E3%81%B5%E3%81%8F%E3%81%8A%E3%81%8B%E9%98%B2%E7%81%BD%E3%83%8A%E3%83%93-%E3%81%BE%E3%82%82%E3%82%8B%E3%81%8F%E3%82%93/id1659756418" target="blank">
                        <figure>
                        <img src={publicPath('images/articles/mamorukun_app01.png')} alt="App Store" className="fit-img"/>
                        </figure>
                    </a>
                    <hr/>
                    <a href="https://play.google.com/store/apps/details?id=jp.lg.fukuoka.pref.fukuoka_bosai&hl=ja&gl=US" target="blank">
                        <figure>
                        <img src={publicPath('images/articles/mamorukun_app02.png')} alt="Google Play" className="fit-img"/>
                        </figure>
                    </a>
                </div>
                <h2>福岡県防災ホームページ</h2>
                <p>
                福岡県では「福岡県防災ホームページ」を開設し、災害への備え から、災害情報など最新の情報を提供しています。
                </p>
                <p>
                （URL:<a href="https://www.bousai.pref.fukuoka.jp" target="_blank">
                    https://www.bousai.pref.fukuoka.jp
                </a> ）
                </p>
                <h2>防災ハンドブック</h2>
                <p>
                9言語で「外国人のための防災ハンドブック」を作成していますので、事前にダウンロードするなどして、日本の災害について事前によく学ぶようにしてください。
                </p>
                <p>
                （URL:<a href="https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html" target="_blank">https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html</a>）
                </p>
                <p>
                掲載内容<br/>
                ●発生するおそれのある災害の内容<br/>
                ●災害発生時に取るべき行動<br/>
                ●災害への日頃からの備え<br/>
                ●避難場所<br/>
                ●緊急連絡先など<br/>
                ●言語は、やさしい日本語、英語、中国語、韓国語、タガログ語、ベトナム語、ネパール語、インドネシア語、ミャンマー語に対応しています。
                </p>
                <p>
                <a href="https://www.pref.fukuoka.lg.jp/uploaded/life/671824_61619901_misc.pdf" target="_blnak">
                    <figure>
                    <img src={publicPath('images/articles/disasterprevantionhandbook.png')} alt="disaster prevantion handbook" className="fit-img"/>
                    </figure>
                </a>
                </p>
                
                <h2>防災訓練</h2>
                <p>
                災害時に外国人住民が適切な行動を取れるよう、外国人住民を対象とした防災訓練を実施していますので、ぜひ参加して、災害時に取るべき行動を実際に体験してください。
                </p>
                <p>
                ●日本で起こりやすい災害に関する講義<br/>
                ●体験学習（避難所で用いる器具の組立、即席防災グッズの体験等） など
                </p>
                <figure>
                <img src={publicPath('images/articles/disasterinfo01.jpg')} alt="" className="fit-img"/>
                </figure>
                <figure>
                <img src={publicPath('images/articles/disasterinfo02.jpg')} alt="" className="fit-img"/>
                </figure>
                <figure>
                <img src={publicPath('images/articles/disasterinfo03.jpg')} alt="" className="fit-img"/>
                </figure>
                <h2>災害時多言語支援センター</h2>
                <p>
                大規模災害発生時には市町村による外国人対応をサポートするため、「災害時多言語支援センター」を 設置します。
                </p>
                <p>
                ●福岡県災害対策本部設置時に、国際交流センター内に設置します。<br/>
                ●設置時においては２４時間体制で対応します。<br/>
                （災害の状況に応じて変更あり。）<br/>
                ●多言語支援センターの主な業務は次のとおりです。<br/>
                ⇒外国人の状況に関する被災市町村からの情報収集を行います。<br/>
                ⇒災害時の情報提供及び避難所運営に関する通訳・翻訳支援を行います。<br/>
                ⇒現地への災害時通訳・翻訳ボランティアの派遣を行います。
                </p>
            </EmphasisNote>
            <br/>
            <NavigatorNice dir="right"></NavigatorNice>
    </>
    )
}

export default LangJa