
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import Article from "@/components/Organisms/Article"
import { SDecTextUl } from "."

const LangTh = () => {

    return (<>
     <Article offset={0}>
    <InnerBox>
    <p>จังหวัดฟุกุโอกะเป็นเมืองที่ได้รับความนิยมจากชาวต่างชาติ มีชาวต่างชาติอาศัยอยู่จำนวนมาก โดยมีจำนวนชาวต่างชาติที่อาศัยอยู่สูงเป็นอันดับ 9 ในบรรดาเมืองใหญ่ของญี่ปุ่น </p>
    <h1>จำนวนชาวต่างชาติทั้งหมด</h1>
    </InnerBox>
    <BlackNote>
    <SDecTextUl className="pc-flex col-2">
    <li>
    <p className="large">
    จำนวนชาวต่างชาติทั้งหมดที่อาศัยอยู่ในจังหวัด
    </p>
    <p className="large">
    <span className="xl">89,518</span>คน
    </p>
    <p className="middle">(ข้อมูลเดือนธันวาคม พ.ศ.2565)</p>
    </li>
    <li>
    <p className="large">
    จำนวนชาวต่างชาติที่อาศัยอยู่ในพื้นที่ฟุกุโอกะ
    </p>
    <p className="large">
    <span className="xl">53,009</span>คน
    </p>
    <p className="middle">(ข้อมูลเดือนธันวาคม พ.ศ.2565)</p>
    </li>
    <li>
    <p className="large">
    จำนวนประชากรที่อาศัยอยู่ในพื้นที่คิตะชิวชู
    </p>
    <p className="large">
    <span className="xl">18,870</span>คน
    </p>
    <p className="middle">(ข้อมูลเดือนธันวาคม พ.ศ.2565)</p>
    </li>
    <li>
    <p className="large">จำนวนประชากรที่อาศัยอยู่ในพื้นที่ชิคุโกะ</p>
    <p className="large">
    <span className="xl">12,686</span>คน
    </p>
    <p className="middle">(ข้อมูลเดือนธันวาคม พ.ศ.2565)</p>
    </li>
    <li>
    <p className="large">จำนวนประชากรที่อาศัยอยู่ในพื้นที่ชิคุโฮ</p>
    <p className="large">
    <span className="xl">4,953</span>คน
    </p>
    <p className="middle">(ข้อมูลเดือนธันวาคม พ.ศ.2565)</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    <InnerBox top={10} bottom={100}>
    <p className="text-small">ที่มา：「สถิติข้อมูลเกี่ยวกับชาวต่างชาติที่อาศัยอยู่ในประเทศ」กระทรวงยุติธรรม</p>
    </InnerBox>
    </Article>
    <div className="navposition01">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    <Article offset={0}>
    <InnerBox >
    <h1>
    จำนวนนักศึกษาต่างชาติ
    </h1>
    </InnerBox>

    <BlackNote>
    <SDecTextUl className="pc-flex col-2">
    <li>
    <p className="large">
    จำนวนนักศึกษาต่างชาติทั้งหมดในจังหวัด
    </p>
    <p className="large">
    <span className="xl">19,188</span>คน
    </p>
    <p className="middle">(ข้อมูลเดือนธันวาคม พ.ศ.2565)</p>
    </li>
    <li>
    <p className="large">
    จำนวนนักศึกษาต่างชาติในพื้นที่ฟุกุโอกะ
    </p>
    <p className="large">
    <span className="xl">14,800</span>คน
    </p>
    <p className="middle">(ข้อมูลเดือนธันวาคม พ.ศ.2565)</p>
    </li>
    <li>
    <p className="large">
    จำนวนนักศึกษาต่างชาติในพื้นที่คิตะคิวชู
    </p>
    <p className="large">
    <span className="xl">2,631</span>คน
    </p>
    <p className="middle">(ข้อมูลเดือนธันวาคม พ.ศ.2565)</p>
    </li>
    <li>
    <p className="large">
    จำนวนนักศึกษาต่างชาติในพื้นที่ชิคุโกะ
    </p>
    <p className="large">
    <span className="xl">1,650</span>คน
    </p>
    <p className="middle">(ข้อมูลเดือนธันวาคม พ.ศ.2565)</p>
    </li>
    <li>
    <p className="large">
    จำนวนนักศึกษาต่างชาติในพื้นที่ชิคุโฮ
    </p>
    <p className="large">
    <span className="xl">107</span>คน
    </p>
    <p className="middle">(ข้อมูลเดือนธันวาคม พ.ศ.2565)</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    <InnerBox top={10} bottom={100}>
    <p className="text-small">ที่มา：「สถิติข้อมูลเกี่ยวกับชาวต่างชาติที่อาศัยอยู่ในประเทศ」กระทรวงยุติธรรม</p>
    </InnerBox>
    </Article>
    <div className="navposition01">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    <Article offset={0}>
    <InnerBox>
    <h1>
    จำนวนนักศึกษาที่ทำงานต่อหลังเรียนจบ
    </h1>
    </InnerBox>
    <BlackNote>
    <SDecTextUl>
    <li>
    <p className="large">
    จำนวนนักศึกษาที่เข้าทำงานในสถานประกอบที่ตั้งอยู่ในจังหวัด
    </p>
    <p className="large">
    <span className="xl">1,118</span>คน
    </p>
    <p className="middle">(ปี พ.ศ.2564) </p>
    </li>
    </SDecTextUl>
    </BlackNote>
    </Article>
    </>)
}

export default LangTh