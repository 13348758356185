
import { useEffect, useRef, useState } from "react";
import { langType } from "src/types/lang";
import { AritcleIndex } from "src/types/article";
import { FetchArtcleIndexArg, fetchArticleIndex } from "src/data/fetch";
import { getCmsNews } from "src/utilty/getNews";
import AriclesSlider from "@/components/Molecules/Slider/ariclesSlider"
import LangJa from "@/components/Pages/Virtual/Fiopark/ja";
import LangVi from "@/components/Pages/Virtual/Fiopark/vi";
import LangEn from "@/components/Pages/Virtual/Fiopark/en";
import LangKo from "@/components/Pages/Virtual/Fiopark/ko";
import LangZhCn from "@/components/Pages/Virtual/Fiopark/zhcn";
import LangZhTw from "@/components/Pages/Virtual/Fiopark/zhtw";
import LangTh from "@/components/Pages/Virtual/Fiopark/th";
import LangNe from "@/components/Pages/Virtual/Fiopark/ne";
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink";
import styled from "styled-components";

export const VirtualContens = ({lang}:{lang:langType}) => {
    const virtulaArgs:FetchArtcleIndexArg  = {
        endpoint: 'virtual',
        lang: lang,
        offset: 0,
        limit: 5,
    }
    

    const [articles, setArticle] = useState<AritcleIndex>()

    const [fixed, setFixed] = useState<boolean>(false)
    const articleRef = useRef<HTMLDivElement>(null);  // useRefを使って参照を作成

     // デバウンス関数
     const debounce = (func: (...args: any[]) => void, wait: number): ((...args: any[]) => void) => {
        let timeout: NodeJS.Timeout; 
        return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        };
    };

    useEffect(() => {
        const getArticlesIndex =  async () => {
            const contents  =  await fetchArticleIndex(virtulaArgs)
            setArticle(contents)
        };

        getArticlesIndex()

        const checkIfBottom = debounce(() => {
            if (!articleRef.current) {
              return;
            }
      
            // 要素の下端がビューポート内にあるかチェック
            const { bottom } = articleRef.current.getBoundingClientRect();
            let checkpoint
            window.innerWidth > 1201 ? checkpoint = 0 :  checkpoint = 200
            if (bottom <= checkpoint) {
                setFixed(true); // それ以外の場合はtrueを保持
            } else {
                setFixed(false); // 最下部に達したらfixedをfalseに設定
            }
          },10);
        
        // スクロールイベントリスナーを登録
        window.addEventListener('scroll', checkIfBottom);

        // コンポーネントのアンマウント時にイベントリスナーを解除
        return () => {
        window.removeEventListener('scroll', checkIfBottom);
        };
    },[])

    const SlideTitle  = ({lang}:{lang:langType}) => {
        return (
            <div className="news-title">
                { lang === 'ja' &&
                    <>
                    <span>FUKUOKA IS OPEN PARK</span>
                    <h2>新着・<br/>イベント情報</h2>
                    </>
                }
                { lang === 'en' &&
                    <>
                    <span>FUKUOKA IS OPEN PARK</span>
                    <h2>What's new &<br/>Event information</h2>
                    </>
                }
                {lang === 'ko' &&
                    <>
                    <span>FUKUOKA IS OPEN PARK</span>
                    <h2>새로운 이벤트<br/>정보</h2>
                    </>
                }
                 {lang === 'zh_cn' &&
                    <>
                    <span>FUKUOKA IS OPEN PARK</span>
                    <h2>新信息以及活动
                    <br/>信息</h2>
                    </>
                }
                {lang === 'zh_tw' &&
                    <>
                    <span>FUKUOKA IS OPEN PARK</span>
                    <h2>新信息以及活動
                    <br/>信息</h2>
                    </>
                }
                 {lang === 'th' &&
                    <>
                    <span>FUKUOKA IS OPEN PARK</span>
                    <h2>ข้อมูล/กิจกรรมใหม่</h2>
                    </>
                }
                  {lang === 'vi' &&
                    <>
                    <span>FUKUOKA IS OPEN PARK</span>
                    <h2>Thông tin<br/>sự kiện mới</h2>
                    </>
                }
                 {lang === 'ne' &&
                    <>
                    <span>FUKUOKA IS OPEN PARK</span>
                    <h2>नवीनतम जानकारी<br/> कार्यक्रमको बारेमा जानकारी</h2>
                    </>
                }
            </div>
        )
    }

    return (<>
     { articles &&
     <>
         <figure className="main">
            <img src="./images/virtual/title.webp" alt="Lets enjoy metaverse" />
        </figure>
        <div className="virtual-contes">
            <div className="virtual-inner" ref={articleRef}>
                <div className="news">
                    <span className="corner left_top"></span>
                    <span className="corner rigt_top"></span>
                    <span className="corner rigt_bottom"></span>
                    <span className="corner left_bottom"></span>
                    <SlideTitle lang={lang} />
                    <div className="news-slider">
                        <AriclesSlider
                            articles={articles}
                            lang={lang}
                            layer="virtual"
                            className="mini"
                        ></AriclesSlider>
                    </div>
                    <SMoreLink>
                        <YellowArrowLink lang="ja" linkKey="more" href={`/virtual/articles?lang=${lang}`}/>
                    </SMoreLink>
                </div>  
                {
                lang === 'ja' ? 
                <a href="https://cluster.mu/w/a8267243-dfbf-428f-bd84-04e2e676f497" target="_blan" className={fixed ?  `entrance_link fix` : `entrance_link`}>
                    <img src="./images/virtual/entrance.webp" alt="FUKUOKA IS OPEN 参加する"/>
                </a>
                : 
                <a href="https://cluster.mu/w/a8267243-dfbf-428f-bd84-04e2e676f497" target="_blan" className={fixed ?  `entrance_link fix` : `entrance_link`}>
                <img src="./images/virtual/entrance_en.webp" alt="FUKUOKA IS OPEN 参加する"/>
                </a>}
            </div>
            {
                lang === 'ja' &&
                <LangJa></LangJa>
            }
           
             {
                lang === 'en' &&
                <LangEn></LangEn>
            }
            {
                lang === 'ko' &&
                <LangKo></LangKo>
            }
            {
                lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
            {
                lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {
                lang === 'th' &&
                <LangTh></LangTh>
            }
             {
                lang === 'ne' &&
                <LangNe></LangNe>
            }
             {
                lang === 'vi' &&
                <LangVi></LangVi>
            }
        </div>
    </>
     }
    </>)
}


const SMoreLink = styled.div`
    position: absolute;
    bottom: -9%;
    right: 10%;
    width: 40%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        bottom: -5%;
        right: 10%;
        width: 35%;
    }
`