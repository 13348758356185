
import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlueLeft } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { NavigatorFukidashiCheck } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"

const LangEn = () => {

    return (<>
        <InnerBox top={0} bottom={30} >
        <h1>About Fukuoka International Exchange Foundation</h1>
        <p>
        The Fukuoka International Exchange Foundation (FIEF) was established in 1989 to promote citizen-oriented international exchange in Fukuoka Prefecture by taking advantage of the prefecture's geographical and historical characteristics and by cooperating with other exchange organizations in the prefecture. Fukuoka International Association was established in 1989 for the purpose of contributing to mutual prosperity and world peace.
        </p>
        <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
        <InnerBox top={20} bottom={20} left={0} right={0}>
        <EmphasisNote>

        <p>
        In terms of exchanges between residents of the prefecture and foreign residents, the Fukuoka Prefectural Government holds various events such as lectures and seminars, operates the Fukuoka Consultation Center for Foreign Residents, which provides solutions to various problems of foreign residents in 22 languages including Japanese, recruits volunteers, and provides information related to daily life such as disaster-related information.
        </p>
        <p>
        In terms of overseas exchanges, we are promoting exchanges with the State of Hawaii (U.S.A.), Jiangsu Province (China), and Bangkok Metropolitan Administration (Thailand), which have friendship and other ties with Fukuoka Prefecture.
        </p>
        <p>
        In addition to providing international understanding education by dispatching foreign residents and others as lecturers to schools and community centers in the prefecture, we also support grassroots exchanges conducted by private international exchange groups and Japanese language classes in the community.
        </p>
        <p>
        Furthermore, as the secretariat of the Fukuoka International Student Support Center established by industry, academia, and government, we also provide comprehensive support for international students.
        </p>
        </EmphasisNote>
        </InnerBox>
        <p>
        【Contact Information】<br/>
        〒810-0001<br/>
        8F/3F, ACROS Fukuoka, 1-1-1 Tenjin, Chuo-ku, Fukuoka City, Fukuoka<br/>
        8F TEL：092-725-9204 FAX：092-725-9205 8:30~17:45 (except Sat., Sun. and holidays)<br/>
        3F (Kokusai Hiroba) TEL：092-725-9200 FAX：092-725-9206 10:00~18:30 (except year-end and New Year holidays) <br/>
        <br/>
        </p>
        
        <div className="custom-link">
        <FukidashiRedLeft>About Fukuoka International Exchange Foundation</FukidashiRedLeft>

        <a href="https://kokusaihiroba.or.jp/" target="_blank">
        <img src={publicPath('images/fief.png')} className="img-fit" alt="About Fukuoka International Exchange Foundation"/>
        </a>
        </div>

        </InnerBox>
        <InnerBox top={20} bottom={20}>
        <h1>
        Fukuoka Consultation Center for Foreign Residents
        </h1>
        
        <div className="sp-only">
        <NavigatorFukidashiCheck dir="left">
        <span className="txt-yellow">
        Do you have any problems living in Fukuoka?<br/>
        The Foreigner Consultation Center will solve your problems in multiple languages.
        </span>
        </NavigatorFukidashiCheck>
        </div>

        <br/>
        <p>
        In terms of daily life support for foreign residents, the Fukuoka Consultation Center for Foreign Residents provides various consultations in 22 languages on employment, labor, medical care, housing, education, and other problems in daily life, as well as residence procedures and legal consultation.
        </p>
        <div className="pc-only">
            <div className="pc-flex col-2">
                <div>
                    <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
                </div>
                <div className="center">
                    <FukidashiBlueLeft>
                        <span className="txt-yellow">
                        Do you have any problems living in Fukuoka?<br/>
                        The Foreigner Consultation Center will solve your problems in multiple languages.
                        </span>
                    </FukidashiBlueLeft>
                </div>
            </div>
        </div>

        <br/>

        <div className="pc-flex col-2">
            <div>
                <div className="deco-fukidashi navigatorCheck right pointing">
                If you have any questions or problems, please feel free to call us or visit our office.<br/>
                You can consult with us in many different languages. (Free of charge).
                </div>
            </div>
            <div>
                <figure>
                    <img src={publicPath('images/international01.png')} className="img-fit" alt=""/>
                </figure>
                <figure className="pc-only">
                    <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
                </figure>
            </div>
        </div>

        <div className="pc-flex col-2">
            <div>
                <div className="deco-fukidashi challengerQ left thinking">
                I want to extend my period of stay in Japan. What should I do?
                </div>
            </div>
            <div>
                <div className="deco-fukidashi challengerQ right puzzled">
                Are there any hospitals that can help me in my own language?
                </div>
            </div>
            <div>
                <div className="deco-fukidashi challengerQ right thinking">
                I am having trouble with my landlord. Where can I talk to someone about this?
                </div>
            </div>
            <div>
                <div className="deco-fukidashi challengerQ right thinking">
                Where can I get a certificate of residence?
                </div>
            </div>
        </div>

        <figure>
        <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
        </figure>
        <InnerBox top={20} bottom={20} left={0} right={0}>
        <EmphasisNote>
        <h2>
        Supported Languages
        </h2>
        <p>
        Japanese, English, Chinese, Korean, Vietnamese, Nepali, Indonesian, Tagalog, Thai, Malay, Spanish, French, German, Italian, Russian, Portuguese, Myanmar, Khmer, Mongolian, Sinhalese, Hindi, Bengali.
        </p>
        </EmphasisNote>

        </InnerBox>
        <p>
        Across Fukuoka 3F, 1-1-1 Tenjin, Chuo-ku, Fukuoka City<br/>
        （Fukuoka International Exchange Center<br/>
        0120-279-906
        </p>
        <FukidashiRedLeft>
        Link to the site
        </FukidashiRedLeft>
       <div className="pc-flex col-2">
            <p>
                <a href="https://kokusaihiroba.or.jp/" target="_blank">
                    <img src={publicPath('images/fief.png')} className="img-fit" alt="公益財団法人福岡県国際交流センター"/>
                </a>
            </p>
            <p>
                <a href="https://www.fissc.net/" target="_blank">
                    <img src={publicPath('images/ficc.png')} className="img-fit" alt="FiSSC"/>
                </a>
            </p>
        </div>
        <NavigatorAction actionType="goodluck" charactorType="good" dir="right"></NavigatorAction>

        </InnerBox>
    </>)
}

export default LangEn