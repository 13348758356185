import { Link } from "react-router-dom"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"

const SAnker = styled(Link)`
    img{
        transition: transform 0.3s ease; /* アニメーションの効果を指定 */
    }
    &:hover{
        img{
            transform: translateX(-10px); /* 左に10px動かす */
        }
    }
`

const BackLink = ({
    href,
    ...props
}) => {
    
        
    return (
        <SAnker to={href}>
            <img src={publicPath('images/backLink.png')} className="img-fit"/>
        </SAnker>
    )

}

export default BackLink