import { PropsWithChildren } from "react";
import styled from "styled-components";


const SDlNote = styled.div`
    dl{
        dt{
            display: block;
            background: ${props => props.theme.colors.orange};
            padding: 10px;
            color: #fff;
            font-weight: bold;
            font-size: ${props => props.theme.fontSize.h4_sp};
        }
        dd{
            border: solid 1px ${props => props.theme.colors.orange};
            padding: 10px;
            font-size: ${props => props.theme.fontSize.txt_sp};
            line-height: ${props => props.theme.lineheight.txt_sp};
            background: #FFFFFF;
            white-space: pre-wrap;
            &:nth-child(2n) {
                background:#FFFAF1;;
            }
        }
    }
    &.orange{
        dt{background: ${props => props.theme.colors.orange};}
    }
    &.red{
        dt{background: ${props => props.theme.colors.red};}
    }
`

export const DlNote = ({
    color,
    children
}:PropsWithChildren<{
    color: | 'orange' | 'red'
}>) => {


    return (
        <SDlNote className={color}>
            {children}
        </SDlNote>
    )


}