const LangKo = () => {

    return (<>
    <h1>접근성에 대하여</h1>
    <dl>
    <dt>
    시책
    </dt>
    <dd>
    <p>후쿠오카현은 누구나 이용할 수 있도록 일본공업규격 'JIS X 8341-3:2016'에 근거하여 홈페이지의 접근성 유지·향상에 힘쓰겠습니다.</p>
    </dd>
    <dt>
    1. 대상범위
    </dt>
    <dd>
    <p>
    https://fisop.net 도메인 공식 홈페이지
    </p>
    </dd>
    <dt>
    2. 목표 적합 레벨
    </dt>
    <dd>
    <p>
    적합 레벨：JIS X 8341-3:2016 웹 접근성 적합 레벨 AA 준수<br/>
    (2계층까지의 웹 접근성 적합 레벨 AA 준수를 목표로 지속해서 수정하겠습니다.)<br/>
    적합 레벨 AA를 준수한다'는 표기는 정보통신 접속 협의회 웹 접근성 기반 위원회 '웹 콘텐츠의 JIS X 8341-3:2016 대응도 표기 가이드라인 2016년 3월판'에서 정한 표기에 따라 적합 레벨 A를 준수하면서 적합 레벨 AA의 달성 기준을 충족하는 것을 의미합니다.<br/>
    <br/>
    <a href="https://waic.jp/docs/jis2016/compliance-guidelines/202104/" target="_blank">
    웹 콘텐츠의 JIS X 8341-3:2016 대응도 표기 가이드라인 별도의 창에서 열립니다(외부 링크)
    </a>
    </p>
    </dd>
    </dl>        
    </>)
}

export default LangKo