import InnerBox from "@/components/Atoms/Box/innerBox"
import PageTitleSupport from "@/components/Molecules/PageTitle/support"
import FioTemplate from "@/components/Templates/fio"
import SubLayerTemplate from "@/components/Templates/subLayer"
import publicPath from "src/utilty/publicPath"

const LangZhTw = () => {

    return (<>
    <InnerBox bottom={10}>
    <h1>
    為在福岡生活的外國人提供的實用網站集。
    </h1>
    </InnerBox>
    <InnerBox left={40} right={0}>
    <ul className="linkslist pc-flex col-2">
    <li>
    <h4>●福岡縣政府網頁</h4>
    <a href="https://www.pref.fukuoka.lg.jp/site/english/" target="_blank">
    <figure>
    <img src={publicPath('images/banner/fukuokaken.png')} className="img-fit"/>
    </figure>
    </a>
    </li>
    <li>
    <h4>●觀光信息</h4>
    <a href="https://www.crossroadfukuoka.jp/tw" target="_blank">
    <figure>
    <figcaption>VISIT FUKUOKA</figcaption>
    <img src={publicPath('images/banner/visit_fukuoka.png')} className="img-fit"/>
    </figure>
    </a>
    <a href="https://www.welcomekyushu.com/" target="_blank">
    <figure>
    <figcaption>Visit Kyushu</figcaption>
    <img src={publicPath('images/banner/tabinet.png')} className="img-fit"/>
    </figure>
    </a>
    </li>
    <li>
    <h4>●醫療信息</h4>
    <a href="https://imsc.pref.fukuoka.lg.jp/" target="_blank">
    <figure>
    <figcaption>Fukuoka International Medical Support Center</figcaption>
    <img src={publicPath('images/banner/supportcenter.png')} className="img-fit"/>
    </figure>
    </a>
    </li>
    <li>
    <h4>●關於One Health的信息</h4>
    <a href="https://onehealth.pref.fukuoka.lg.jp/" target="_blank">
    <figure>
    <figcaption>Fukuoka Prefecture One Health Promotion Portal Site</figcaption>
    <img src={publicPath('images/banner/onehealth.png')} className="img-fit"/>
    </figure>
    </a>
    </li>
    <li>
    <h4>●國際金融信息</h4>
    <a href="https://financial-hub-fukuoka.com/" target="_blank">
    <figure>
    <figcaption>Financial Hub FUKUOKA</figcaption>
    <img src={publicPath('images/banner/financialhaub.png')} className="img-fit"/>
    </figure>
    </a>
    </li>
    <li>
    <h4>●招商信息</h4>
    <a href="https://www.kigyorichi.pref.fukuoka.lg.jp/en/" target="_blank">
    <figure>
    <figcaption>INVEST FUKUOKA</figcaption>
    <img src={publicPath('images/banner/investfukuoka.png')} className="img-fit"/>
    </figure>
    </a>
    </li>
    <li>
    <h4>●國際交流信息</h4>
    <a href="https://kokusaihiroba.or.jp/" target="_blank">
    <figure>
    <figcaption>FIEF（Fukuoka Prefecture International Exchange Center）</figcaption>
    <img src={publicPath('images/banner/fief.png')} className="img-fit"/>
    </figure>
    </a>
    </li>
    <li>
    <h4>●留學生信息</h4>
    <a href="https://www.fissc.net/tw/" target="_blank">
    <figure>
    <figcaption>FISSC（International Students Support Center）</figcaption>
    <img src={publicPath('images/banner/fissc.png')} className="img-fit"/>
    </figure>
    </a>
    </li>
    <li>
    <h4>●Information Sport</h4>
    <a href="https://www.tourdekyushu.asia/en/" target="_blank">
        <figure>
            <figcaption>マイナビ TOUR de Kyushu 2024</figcaption>
            <img src={publicPath('images/banner/tourkyushu2024.png')} alt="マイナビ　ツール・ド・九州　2024" className="img-fit"/>
        </figure>
    </a>
    </li>
    </ul>
    </InnerBox>
    </>)
}

export default LangZhTw