import styled from "styled-components"
import { CmsIndex } from "@/components/Organisms/Cms"
import LangJa from "./ja"
import Article from "@/components/Organisms/Article"
import { ArticleMutiLangTemplate } from "@/components/Templates/multilingual"
import { langType } from "src/types/lang"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"

const SFaq = styled.div`
    .question{
       .fukidashi{
            margin-top: -24%;
       }
       margin-bottom:25%;
    }
    .fukidashi{
        span{ color: ${props => props.theme.colors.yellow};}
    }
    .anser-block{
        position: relative;
        .charactor{
            width: 80%;
            margin-top: -20%;
            margin-left: -2%;
        }
    }
    hr{
        border-top: dotted 5px ${props => props.theme.colors.blue};
        margin: 50px 0;
    }
    .links{
        font-weight: bold;
        span{ display:block;
            &.right{ text-align:right; }
        }
        max-width: 400px;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .links{
            font-size: 2.0rem;
        }
        .question{
            margin-bottom:10px;
        }
    }
`

const SupportFaqPage = ({lang}:{lang:langType}) => {
    setTitleDiscrption({
        layer:'support',
        lang:lang,
        subLayer: 'faq'
    })

    return (
        <SFaq>
        <ArticleMutiLangTemplate lang={lang}>
            <Article className="pc-noTopMargin">
            {lang === 'ja' &&
                <LangJa></LangJa>
            }
            {lang === 'easy_ja' &&
                    <LangJa></LangJa>
            }
            {lang === 'en' &&
                <LangEn></LangEn>
            }
            {lang === 'ko' &&
                <LangKo></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh></LangTh>
            }
            {lang === 'vi' &&
                <LangVi></LangVi>
            }
            {lang === 'ne' &&
                <LangNe></LangNe>
            }
            </Article>
        </ArticleMutiLangTemplate>
        </SFaq>
    )
}

export default SupportFaqPage