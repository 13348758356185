
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import Article from "@/components/Organisms/Article"
import { SDecTextUl } from "."

const LangZhTw = () => {

    return (<>
     <Article offset={0}>
    <InnerBox>
    <p>福岡縣居住著許多外國人，是日本外國人居住人數排名第9的城市。</p>
    <h1>外國人總數</h1>
    </InnerBox>
    <BlackNote>
    <SDecTextUl className="pc-flex col-2">
    <li>
    <p className="large">
    居住在縣內的外國人總數
    </p>
    <p className="large">
    <span className="xl">89,518</span>人
    </p>
    <p className="middle">(截至 2022/12）</p>
    </li>
    <li>
    <p className="large">
    居住在福岡地區的外國人數
    </p>
    <p className="large">
    <span className="xl">53,009</span>人
    </p>
    <p className="middle">(截至 2022/12）</p>
    </li>
    <li>
    <p className="large">
    居住在北九州地區的外國人數
    </p>
    <p className="large">
    <span className="xl">18,870</span>人
    </p>
    <p className="middle">(截至 2022/12）</p>
    </li>
    <li>
    <p className="large">居住在築後地區的外國人數</p>
    <p className="large">
    <span className="xl">12,686</span>人
    </p>
    <p className="middle">(截至 2022/12）</p>
    </li>
    <li>
    <p className="large">居住在築豐地區的外國人數</p>
    <p className="large">
    <span className="xl">4,953</span>人
    </p>
    <p className="middle">(截至 2022/12）</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    <InnerBox top={10} bottom={100}>
    <p className="text-small">資料來源：法務省《在留外國人統計》</p>
    </InnerBox>
    </Article>
    <div className="navposition01">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    <Article offset={0}>
    <InnerBox >
    <h1>
    外國留學生人數
    </h1>
    </InnerBox>

    <BlackNote>
    <SDecTextUl className="pc-flex col-2">
    <li>
    <p className="large">
    縣內外國留學生總數
    </p>
    <p className="large">
    <span className="xl">19,188</span>人
    </p>
    <p className="middle">(截至 2022/12）</p>
    </li>
    <li>
    <p className="large">
    福岡地區的外國留學生人數
    </p>
    <p className="large">
    <span className="xl">14,800</span>人
    </p>
    <p className="middle">（截至 2022/12）</p>
    </li>
    <li>
    <p className="large">
    北九州地區的留學生人數
    </p>
    <p className="large">
    <span className="xl">2,631</span>人
    </p>
    <p className="middle">（截至 2022/12）</p>
    </li>
    <li>
    <p className="large">
    築後地區的外國留學生人數
    </p>
    <p className="large">
    <span className="xl">1,650</span>人
    </p>
    <p className="middle">（截至 2022/12）</p>
    </li>
    <li>
    <p className="large">
    築豐地區的留學生人數
    </p>
    <p className="large">
    <span className="xl">107</span>人
    </p>
    <p className="middle">（截至 2022/12）</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    <InnerBox top={10} bottom={100}>
    <p className="text-small">資料來源：法務省《在留外國人統計》</p>
    </InnerBox>
    </Article>
    <div className="navposition01">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    <Article offset={0}>
    <InnerBox>
    <h1>
    留學生就業人數
    </h1>
    </InnerBox>
    <BlackNote>
    <SDecTextUl>
    <li>
    <p className="large">
    在縣內企業等就職的留學生人數
    </p>
    <p className="large">
    <span className="xl">1,118</span>人
    </p>
    <p className="middle">(2021年）</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    </Article>
    </>)
}

export default LangZhTw