import theme from "@/themes"
import publicPath from "src/utilty/publicPath"
import styled, { ThemeProvider } from "styled-components"
import Header from "../../Organisms/Header"
import Footer from "../../Organisms/Footer"
import PCSideNav from "../../Organisms/PCSide/nav"
import PCSideRight from "../../Organisms/PCSide/right"
import SeachForm from "@/components/Molecules/SearchForm"
import { ChallengerrFullLength } from "@/components/Atoms/Character/challenger"
import { NavigatorFullLength } from "@/components/Atoms/Character/navigator"
import { HeaderPc } from "@/components/Organisms/Header/pc"
import { Emergency } from "@/components/Organisms/Emergency"

const SWrap = styled.div`
    background: ${props => props.theme.colors.red};
`

const SMain = styled.main`
    max-width: ${props => props.theme.innerMaxWidth};
    background: ${props => props.theme.colors.red};
    background-image: url(${publicPath('images/mainBk.png')});
    background-size: 100% auto;
    overflow-x:hidden;
    min-height: 100vh;
    *{
        white-space: pre-wrap;
    }
    .c-postion{
        position: absolute;
        display: none;
        &.left{
            width: 250px;
            top: 20px;
            left: 10px;
            z-index: 0;
        }
        &.right{
            width: 150px;
            top: 120px;
            right: 8%;
            z-index: 13;
        }
    }
    .pc-only{
        display: none;
    }
    .pc-flex{
        display: block;
    }
    .sp-only{
        display: block;
    }
    &.emergency{
        position: relative;
        z-index: 2;
        background: ${props => props.theme.colors.emergency};
       
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100px;
            background: ${props => props.theme.colors.red};
            background-image: url(${publicPath('images/pc/bk.webp')});
            background-position: bottom;
            bottom: 0;
            right: 0;
            z-index: 0;

        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        
        width: calc(100% - 250px);
        position: relative;
        margin-right: 0;
        max-width: 100%;
        background-image: url(${publicPath('images/pc/bk.webp')});
        background-size: 100% auto;
        .c-postion{
            display: block;
        }
        .pc-only{
            display: block;
        }
        .sp-only{
            display: none;
        }
        .pc-flex{
            padding: 20px 0;
            display: flex;
            flex-wrap: wrap;
            &.col-2{
                justify-content: space-between;
                > *{
                    flex-basis: 48%;
                }
            }
            > * {
                &.center{
                    display: flex;
                    align-items: center;
                }
            }
        }
        &.emergency{
            .c-postion{
                display: none;
            }
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: calc(100% - 300px);
    }
   
`

const SPCSide = styled.aside`
    background: ${props => props.theme.colors.red};
    position: fixed;
    height: 100vh;
    overflow-y: scroll;
    width:auto;
    top: 0;
    z-index: 0;
    display: none;
    z-index: 11;
    &.left{ 
        left: 0;
    }
    &.right{
        right: 0;
    }
    &> div{
        background: ${props => props.theme.colors.red};
        background-image: url(${publicPath('images/mainBk.png')});
        background-size: 100% auto;
        position: absolute;
        top: 0;
       
        height: 100%;
        z-index: -1;
        overflow: hidden;
       
        
    }
    /*
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) and 
        (max-width: ${props => props.theme.responsive.pcLargePoint}) {
        width: calc( (100% - 414px) / 2);
    }*/
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        display: block;
        z-index: 999;
    }
`

const SHeader = styled.div`

    .pc{
        display: none;
    }
    .sp{
        display: block;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .pc{
            display: block;
        }
        .sp{
            display: none;
        }
    }
`

const ResponsiveFioTemplate = (
    {emergency = false, children}:React.PropsWithChildren<{emergency? : boolean}>) => {
    
    return ( 
        <ThemeProvider theme={theme}>
            <SWrap>
                <SPCSide className="left">
                    <PCSideNav></PCSideNav>
                </SPCSide>
                
                <Emergency>
                    <SHeader>
                        <div className="sp"><Header></Header></div>
                        <div className="pc">
                        <HeaderPc></HeaderPc>
                        </div>
                    </SHeader>
                    <SMain className={emergency ? 'emergency' : ''}>
                        <div className="c-postion left">
                            <ChallengerrFullLength></ChallengerrFullLength>
                        </div>
                        <div className="c-postion right">
                            <NavigatorFullLength></NavigatorFullLength>
                        </div>
                    
                        {children}
                    </SMain>
                </Emergency>

                <Footer></Footer>
            </SWrap>
            

        </ThemeProvider>
    )
}

export default ResponsiveFioTemplate

