
import InnerBox from "@/components/Atoms/Box/innerBox"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { TabTamplate } from "@/components/Templates/tab"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import Article from "@/components/Organisms/Article";
import { MedicalTbody } from "."
import Data from 'src/json/ForeignPatientsMedical/en.json'
import { NavigatorAction } from "@/components/Molecules/CharactorAction"


const LangEn = ({onActive, active}) => {
    const medicals = Data.data
    
    return (
        <Article offset={0} className="pc-noTopMargin">
            <InnerBox>
                <h1>A medical institution that accepts foreign patients.<br/>Base medical institutions.</h1>
                <p>
                To create an environment where foreigners can receive medical care with peace of mind, Fukuoka Prefecture selects medical institutions as bases for accepting foreign patients in each secondary medical care area on the condition that they are able to provide multilingual and cashless services.
                </p>
                </InnerBox>
                <InnerBox left={0} right={0} bottom={30}>
                <BlackNote>
                <div className="decoration">
                <p className="al-c star">What medical facilities are available?</p>
                <p className="txt-middle">
                The following is a list of base medical institutions accepting foreign patients in Fukuoka Prefecture. (as of June 2023)
                </p>
                <br/>
                <p className="txt-large al-c txt-yellow">
                Cashless OK!"
                </p>
                </div>
                </BlackNote>
                <div className="space-deco"></div>
            </InnerBox>
            <InnerBox>
            <TabTamplate>
                <ul className="tabnav-custom">
                    <li><button onClick={() => {onActive(0)}} className={`${active === 0 ? 'active' : ''}`}>Fukuoka City</button></li>
                    <li><button onClick={() => {onActive(1)}} className={`${active === 1 ? 'active' : ''}`}>Fukuoka Region<br/>（Outside Fukuoka City）</button></li>
                    <li><button onClick={() => {onActive(2)}} className={`${active === 2 ? 'active' : ''}`}>Chikugo Area</button></li>
                    <li><button onClick={() => {onActive(3)}} className={`${active === 3 ? 'active' : ''}`}>Chikuhō Area</button> </li>
                    <li><button onClick={() => {onActive(4)}} className={`${active === 4 ? 'active' : ''}`}>Kitakyushu Area</button></li>
                </ul>
            
                <nav className="tab">
                    <div className={`tab-content ${active === 0 ? 'active' : ''}`}>
                        <h3>Fukuoka City</h3>
                        <table className="en">
                            <thead>
                            <tr>
                            <th rowSpan={2}>City/<br/>Town<br/>Name</th>
                            <th rowSpan={2}>Name of medical institution</th>
                            <th colSpan={2}>Accepted Patients</th>
                            <th rowSpan={2}>JMIP ※1 acquisition</th>
                            <th rowSpan={2}>Supported languages</th>
                            </tr>
                            <tr>
                            <th>seriously ill patients</th>
                            <th>patients with minor illnesses</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[0]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 1 ? 'active' : ''}`}>
                        <h3>Fukuoka Region (Outside Fukuoka City)</h3>
                        <table className="en">
                            <thead>
                            <tr>
                            <th rowSpan={2}>City/<br/>Town<br/>Name</th>
                            <th rowSpan={2}>Name of medical institution</th>
                            <th colSpan={2}>Accepted Patients</th>
                            <th rowSpan={2}>JMIP ※1 acquisition</th>
                            <th rowSpan={2}>Supported languages</th>
                            </tr>
                            <tr>
                            <th>seriously ill patients</th>
                            <th>patients with minor illnesses</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[1]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 2 ? 'active' : ''}`}>
                        <h3>Chikugo Area</h3>
                        <table className="en">
                            <thead>
                            <tr>
                            <th rowSpan={2}>City/<br/>Town<br/>Name</th>
                            <th rowSpan={2}>Name of medical institution</th>
                            <th colSpan={2}>Accepted Patients</th>
                            <th rowSpan={2}>JMIP ※1 acquisition</th>
                            <th rowSpan={2}>Supported languages</th>
                            </tr>
                            <tr>
                            <th>seriously ill patients</th>
                            <th>patients with minor illnesses</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[2]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 3 ? 'active' : ''}`}>
                        <h3>Chikuhō Area</h3>
                        <table className="en">
                            <thead>
                            <tr>
                            <th rowSpan={2}>City/<br/>Town<br/>Name</th>
                            <th rowSpan={2}>Name of medical institution</th>
                            <th colSpan={2}>Accepted Patients</th>
                            <th rowSpan={2}>JMIP ※1 acquisition</th>
                            <th rowSpan={2}>Supported languages</th>
                            </tr>
                            <tr>
                            <th>seriously ill patients</th>
                            <th>patients with minor illnesses</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[3]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 4 ? 'active' : ''}`}>
                        <h3>Kitakyushu Area</h3>
                        <table className="en">
                            <thead>
                                <tr>
                                <th rowSpan={2}>City/<br/>Town<br/>Name</th>
                                <th rowSpan={2}>Name of medical institution</th>
                                <th colSpan={2}>Accepted Patients</th>
                                <th rowSpan={2}>JMIP ※1 acquisition</th>
                                <th rowSpan={2}>Supported languages</th>
                                </tr>
                                <tr>
                                <th>seriously ill patients</th>
                                <th>patients with minor illnesses</th>
                                </tr>
                                </thead>
                            <MedicalTbody medicals={medicals[4]}></MedicalTbody>
                        </table>
                    </div>
                   
                </nav>
            </TabTamplate>
            <br/>
            <p className="txt-small">
                (Note 1) This does not preclude medical institutions not listed in the list from treating foreign patients. (Note 2) The medical institutions listed in the list of medical institutions are not a list of medical institutions that accept foreign patients coming to Japan from abroad for medical treatment.<br/>
                1 JMIP (Japan Medical Service Accreditation for International Patients) → Accreditation system for medical institutions accepting foreign patients<br/>
                The purpose of this program is to support the creation of a system that can provide safe and secure medical services to all foreigners at domestic medical institutions through third-party evaluation of domestic medical institutions' systems that contribute to the acceptance of foreign patients, such as multilingual medical information and responses that take into consideration different cultures and religions, as established by the Ministry of Health, Labour and Welfare in 2012 (certification body: Japan Medical Education Foundation).<br/>
                2 For medical institutions that offer 21-language services, use the Fukuoka International Medical Support Center.
                </p>
            <NavigatorAction dir="right" charactorType="normal" actionType="pickup"/>
            <EmphasisNote>
                <h1 className="c-h1">Want more information?</h1>

                <p>
                Nationwide base medical institutions are published on the websites of the Ministry of Health, Labor and Welfare and the Japan Tourism Agency (URL below).<br/>
                </p>
                <p>
                Ministry of Health, Labor and Welfare HP<br/>
                <a href="https://www.mhlw.go.jp/stf/newpage_05774.html" target="_blank">
                https://www.mhlw.go.jp/stf/newpage_05774.html
                </a><br/>
                </p>
                <p>
                Japan Tourism Agency HP<br/>
                <a href="https://www.jnto.go.jp/emergency/jpn/mi_guide.html" target="_blank">
                https://www.jnto.go.jp/emergency/jpn/mi_guide.html
                </a>
                </p>

                <p>
                This web site lists hospitals, clinics, dental clinics, pediatrics, and midwifery clinics in Fukuoka Prefecture, and provides medical information on pediatric emergency, prevention, health, and welfare.<br/>
                </p>
                <p>
                Fukuoka Medical Information Network<br/>
                <a href="https://www.fmc.fukuoka.med.or.jp/" target="_blank">
                https://www.fmc.fukuoka.med.or.jp/
                </a>
                </p>
                </EmphasisNote>
                </InnerBox>
        </Article>
    )
}

export default LangEn