import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { TrapezoidExcl } from "@/components/Molecules/CharactorHeadline/challenger"
import Article from "@/components/Organisms/Article"


const LangTh = () => {

    return (<>
    <Article offset={0} className="pc-noTopMargin">
    <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
        </div>
        <BlackNote>
        <ul className="deco">
        <li>
        <p className="large">
        ● จำนวนประชากรของจังหวัดฟุกุโอกะ<br/>
        5.14 ล้านคน
        </p>
        <p className="al-c">(ปีพ.ศ.2563 อันดับที่ 9 ของประเทศ)</p>
        <span className="txt-small">
        ที่มา: ฝ่ายสำรวจสถิติประจำจังหวัด (รายงานจำนวนประชากรและครัวเรือนในจังหวัดฟุกุโอกะ พ.ศ.2565)
        </span>
        </li>
        </ul>
        </BlackNote>
    </div>
    </Article>
    <TrapezoidExcl dir="left">
    ● พื้นที่ของจังหวัดฟุกุโอกะ<br/>
    </TrapezoidExcl>
    <Article offset={0} className="pc-noTopMargin">
    <InnerBox>
    <p className="p-side-20">
    (ที่มา: ภาพรวมการบริหารจังหวัดประจำปี 2566)
    </p>
    </InnerBox>
    <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
        </div>
        <BlackNote>
        <ul className="deco">
        <li>
        <p className="large">
        ● พื้นที่ของจังหวัดฟุกุโอกะ <br/>
        4,987 ตารางกิโลเมตร
        </p>
        <p className="al-c">(ปีพ.ศ.2563 อันดับที่ 29 ของประเทศ)</p>
        <span className="txt-small">
        ที่มา: สำนักงานภูมิสารสนเทศเชิงพื้นที่, กระทรวงที่ดิน โครงสร้างพื้นฐาน การขนส่ง และการท่องเที่ยวญี่ปุ่น
        <br/>
        (การสำรวจพื้นที่ทั่วประเทศประจำปีพ.ศ.2565 แยกตามจังหวัด เมือง เขต ตำบล และหมู่บ้าน)
        </span>
        </li>
        </ul>
        </BlackNote>
    </div>
    <div className="navi-position sp-only">
        <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    </Article>

    <TrapezoidExcl dir="left">
    ● สภาพภูมิอากาศของจังหวัดฟุกุโอกะ<br/>
    </TrapezoidExcl>
    <Article offset={0} className="pc-noTopMargin">
    <BlackNote>
    <ul className="deco pc-flex col-2">
    <li>
    <p className="large">
    อุณหภูมิเฉลี่ยทั้งปี
    <br/>
    17.3℃
    </p>
    </li>
    <li>
    <p className="large">
    ความชื้นเฉลี่ยทั้งปี <br/>
    68％
    </p>
    </li>
    <li>
    <p className="large">
    ชั่วโมงที่มีแสงแดดต่อปี<br/>
    1,889.4 ชั่วโมง
    </p>
    </li>
    <li>
    <p className="large">
    ปริมาณน้ำฝนต่อปี<br/>
    1,686.9 มิลลิเมตร
    </p>
    <p>ค่าเฉลี่ยภายในช่วง 30 ปี ตั้งแต่ปี 2534 ถึง 2563</p>
    <p className="txt-small">
    ที่มา: หอสังเกตการณ์อุตุนิยมวิทยาฟุกุโอกะ
    </p>
    </li>
    </ul>
   
    </BlackNote>
    <div className="sp-only">
        <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    </Article>
    <InnerBox top={40} bottom={40}>
    </InnerBox>
    </>)
}

export default LangTh