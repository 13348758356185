
import { useState } from "react"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import Article from "@/components/Organisms/Article"
import styled from "styled-components"

import { langType } from "src/types/lang"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { AreaProvider } from "./areaProvider"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"

const SAraes = styled.div`
    position: relative;
    margin-bottom: 25%;
    padding-bottom: 120px;
    g{
        cursor: pointer;
    }
    .map{
        background: #BCD4E8;
    }
    
    rect{
        cursor: pointer;
        stroke-width: 0px;
    }
  
    .area-info{
        position: relative;
    }
    .fukidashiPosition{
        position: absolute;
        display: none;
        z-index: 12;
        width: 90%;
        &.fukuoka{
            top: 35%;
            left: 10%;
        }
        &.chikugo{
            bottom: 47%;
            left: 8%;
        }
        &.chikuhou{
            bottom: 52%;
            right: 6%;
        }
        &.kitakyu{
            top: 20%;
            right: 10%;
        }
        &.active{
            display: block;
        }
        
    }
    .red-fukidashi-postion{
        position: relative;
        width: 100%;
        aspect-ratio: 10 / 2;
        
        &> div {
            position: absolute;
            z-index: 11;
            top: 0;
            
        }
        @media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
        (max-width: ${props => props.theme.responsive.minPc}) ),
        screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
            aspect-ratio: 10 / 1.2;
        }
    }
    .fukidashi{
        padding: 10px;
        border: solid 3px ${props => props.theme.colors.black};
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 2.5rem;
        position: relative;
        z-index: 10;
        background: #fff;
        color: #fff;
        padding: 15px;
    
        figure{
            width: 100%;
            img{width: 100%}
        }
        &::before{
            content: '';
            border-right: solid 4px ${props => props.theme.colors.black};
            border-bottom: solid 4px ${props => props.theme.colors.black};
            position: absolute;
            width: 100%;
            height: 100%;
            left: 2px;
            top: 2px;
            z-index: -1;
        }
        &::after{
            content: '';
            width: 20px;
            height: 30px;
            background: #fff;
            position: absolute;
            left: 0;
            clip-path: polygon(100% 0, 10% 0, 0 100%);
            bottom: -30px;
            left: 20px;
        }
        .speak{
            display: block;
            width: 20px;
            height: 30px;
            background: ${props => props.theme.colors.black};
            position: absolute;
            clip-path: polygon(100% 0, 10% 0, 0 100%);
            bottom: -33px;
            left: 22px;
        }
       &.fukuoka{
            background: #0082cc;
            color: #fff;
            &::after{
                transform: scale(1, -1);
                top: -29px;
                bottom: auto;
                background: #0082cc;
                left: 15%;
            }
            .speak{
                width: 25px;
                height: 34px;
                left: 14.6%;
                transform: scale(1, -1);
                top: -34px;
                bottom: auto;
            }
       }
       &.chikugo{
            background: #f29600;
            &::after{
                left: 10%;
                background: #f29600;
            }
            .speak{
                left: 10.5%;
            }
       }
       &.chikuhou{
            background: #eb6d80;
            &::after{
                transform: scale(-1, 1);
                background: #eb6d80;
                left: auto;
                right: 10%;
                bottom: -29px;
            }
            .speak{
                width: 25px;
                height: 34px;
                transform: scale(-1, 1);
                left: auto;
                bottom: -36px;
                right: 9.8%;
            }
       }
       &.kitakyu{
            background: #1eaa39;
            color: #fff;
           
            &::after{
                transform: scale(-1, -1);
                top: -29px;
                bottom: auto;
                background: #1eaa39;
                right: 16%;
                left: auto;
            }
            .speak{
                width: 25px;
                height: 34px;
                right: 14.6%;
                left: auto;
                transform: scale(-1, -1);
                top: -34px;
                bottom: auto;
            }
       }
    }
    
    .red-fukidashi{
        *{font-size: 1.8rem;}
        position: absolute;
        top: 80px;
        z-index: 10;
    }
    .navi-postion{
        position: absolute;
        width: 100%;
        margin-top: -20%;
        right: 20px;
    }

    .kitakyu {
        fill: #22ac38;
    }

    .cls-1, .cls-2, .cls-3, .cls-4, .cls-5 {
        stroke-width: 0px;
    }

    .chikugo {
    fill: #f39800;
    }

    .moji {
    fill: #fff;
    }

    .chikuhou{
    fill: #ec6d81;
    }

    .fukuoka {
    fill: #0083cd;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        margin-bottom: 0px;
        padding-bottom: 20px;
        .navi-postion{
            display: none;
        }
        .red-fukidashi-postion{
            position: relative;
        }
    }
`

const SContents = styled.div`
    .navi-postion-pc{
        display: none;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        & > p.discription { flex-basis: 100%; margin-bottom: 40px}
        & > div{
            box-sizing: border-box;
            flex-basis: 50%;
           
            &:nth-child(1){
                order: 2;
                
            }
            &:nth-child(2){
                order: 1;
                padding: 20px;
            }
            &:nth-child(4){
                order: 3;
               
            }
        }
        .navi-postion-pc{
            display: block;
            position: absolute;
            width: 50%;
            right: 7%;
            top: 20%;
        }
    }
`

const BasicInfoAras = ({lang}:{lang:langType}) => {
    setTitleDiscrption({
        layer:'basicinfo',
        lang:lang,
        subLayer:"areas"
    })

    const [ onFukidashi, setOnFukidashi] = useState<number>(0)

    function onSwich( num: number) {
        if(onFukidashi > 0) { 
            setOnFukidashi(0)
        }else{
            onFukidashi === num ? setOnFukidashi(0) : setOnFukidashi(num)
        }
    }

    const langProps = {
        onFukidashi,
        onSwich
    }

    return (
        <SAraes>
            <Article className="pc-noTopMargin">
                <SContents>
                {lang === 'ja' &&
                    <>
                    <p className="discription">
                    <span>福岡県は地理的、歴史的、経済的特性などから、</span>
                    福岡、北九州、筑後、筑豊の４地域に分かれます。
                    </p>
                    <div className="red-fukidashi-postion">
                        <FukidashiRedLeft>
                            各地域をタップすると詳細を表示します。
                        </FukidashiRedLeft>
                    </div>
                    </>
                }
                {lang === 'easy_ja' &&
                    <>
                    <p className="discription">
                    <span>福岡県は地理的、歴史的、経済的特性などから、</span>
                    福岡、北九州、筑後、筑豊の４地域に分かれます。
                    </p>
                    <div className="red-fukidashi-postion">
                        <FukidashiRedLeft>
                            各地域をタップすると詳細を表示します。
                        </FukidashiRedLeft>
                    </div>
                    </>
                }
                {lang === 'en' &&
                    <>
                    <p className="discription">
                    Fukuoka Prefecture has 29 cities, 29 towns, and 2 villages. These 60 cities, towns, and villages are divided into 4 regions based on geographical, historical, and economic characteristics: Kitakyushu, Fukuoka, Chikuho, and Chikugo.
                    </p>            
                    <div className="red-fukidashi-postion">
                        <FukidashiRedLeft>
                        Tap each region to view details.
                        </FukidashiRedLeft>
                    </div>
                    </>
                }
                {lang === 'ko' &&
                    <>
                     <p className="discription">
                    후쿠오카현에는 29시(市), 29정(町), 2촌(村)이 있으며, 이 60개 시정촌은 지리적, 역사적, 경제적 특성으로 기타큐슈, 후쿠오카, 지쿠호, 지쿠고의 4개 지역으로 나누어져 있습니다.            </p>
                    <div className="red-fukidashi-postion">
                        <FukidashiRedLeft>
                        각 지역을 탭하면 세부정보를 볼 수 있습니다.
                        </FukidashiRedLeft>
                    </div>
                    </>
                }
                {lang === 'zh_cn' &&
                    <>
                    <p className="discription">
                    福冈县内共有29个市，29个町和2个村，根据这60个市町村的地理，历史以及经济特性等，其又被分成北九州，福冈，筑丰，筑后4个地域。
                    </p>
                    <div className="red-fukidashi-postion">
                        <FukidashiRedLeft>
                        点击每个区域可查看详细信息。
                        </FukidashiRedLeft>
                    </div>
                    </>
                }
                {lang === 'zh_tw' &&
                    <>
                     <p className="discription">
                    福岡縣有 29 個市、29 個町、2 個村，這 60 個市町村依地理、歷史和經濟特徵分為北九州、福岡、築豐、築後 4 個地區。
                    </p>
                    <div className="red-fukidashi-postion">
                        <FukidashiRedLeft>
                        點擊每個區域可查看詳細資訊。
                        </FukidashiRedLeft>
                    </div>
                    </>
                }
                {lang === 'th' &&
                    <>
                    <p className="discription">
                    จังหวัดฟุกุโอกะแบ่งเขตการปกครองเป็น 29 เมือง 29 ตำบล และ 2 หมู่บ้าน โดยทั้ง 60 เขตนี้ได้ถูกแบ่งตามลักษณะทางภูมิศาสตร์ ประวัติศาสตร์ และเศรษฐกิจออกเป็น 4 พื้นที่ ได้แก่ คิตะคิวชู ฟุกุโอกะ ชิคุโฮะ และชิคุโกะ 
                    </p>
                    <div className="red-fukidashi-postion">
                        <FukidashiRedLeft>
                        แตะแต่ละภูมิภาคเพื่อดูรายละเอียด
                        </FukidashiRedLeft>
                    </div>
                    </>
                }
                {lang === 'vi' &&
                    <>
                     <p className="discription">
                    Tỉnh Fukuoka có 29 thành phố, 29 thị trấn và 2 làng. 60 đơn vị hành chính này được chia thành bốn khu vực là Kitakyushu, Fukuoka, Chikuho và Chikugo dựa trên đặc điểm về địa lý, lịch sử và kinh tế...
                    </p>
                    <div className="red-fukidashi-postion">
                        <FukidashiRedLeft>
                        Nhấn vào từng khu vực để xem chi tiết.
                        </FukidashiRedLeft>
                    </div>
                    </>
                }
                {lang === 'ne' &&
                    <>
                     <p className="discription">
                        फुकुओका प्रिफेक्चरमा २९ नगरपालिका, २९ सहरपालिका र २ गाउँपालिकाहरू छन् र यी ६०<br/>
                        पालिकाहरूलाई भौगोलिक, ऐतिहासिक रआर्थिक विशेषताकाआधारमा किताक्युसु, फुकुओका, चिकुहो र चिकुगो चार क्षेत्रहरूमा विभाजन गरिएको छ।
                        </p>
                        <div className="red-fukidashi-postion">
                            <FukidashiRedLeft>
                            विवरणहरू हेर्न प्रत्येक क्षेत्र ट्याप गर्नुहोस्।
                            </FukidashiRedLeft>
                        </div>
                    </>
                }
                <div>
                    <AreaProvider lang={lang}/>
                </div>
                <div className="navi-postion-pc">
                    <NavigatorAction actionType="check" charactorType="normal" dir="right"/>
                </div>
                </SContents>
            </Article>
            <div className="navi-postion">
                <NavigatorAction actionType="check" charactorType="normal" dir="right"/>
            </div>
       </SAraes>
    )

}

export default BasicInfoAras