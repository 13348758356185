import styled from "styled-components"


export const TopSectionCotainerTemplate = (
    {children}:React.PropsWithChildren) => {

    return (
        <SContainer>
            {children}
        </SContainer>
    )
}

const SContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        width: calc(100% - 250px);
        margin-left: 250px;
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
        width: calc(100% - 300px);
        margin-left: 300px;
    }
`