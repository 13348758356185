import styled from "styled-components";



const SSublayer = styled.div`
    padding:60px 0;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        padding: 60px 0 140px;
    }
`

const SubLayerTemplate = ({children}) => {
    
    return (
        <SSublayer>
            {children}
        </SSublayer>
    )
}

export default SubLayerTemplate