import { NavigateCracter } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"

const LangKo = () => {

    return (<>
       <Article className="pc-noTopMargin">
        <h1>고대－아시아와의 교류 창구－－</h1>
        <p>
        후쿠오카는 고대에 먼 조정이라 불렸던 다자이후 정청과 외국 사절의 영빈관인 홍려관이 설치되어 중국 및 한반도와 일본의 교류 창구였습니다. 중세에 들어서도 하카타의 항구는 중국과 한반도를 비롯해 오키나와와 난카이의 무역기지로서 번성했습니다.
        </p>
        </Article>
        <div className="space">
        <div className="navigator right">
        <NavigateCracter type="smile"/>
        </div>
        </div>
        <Article>
        <h1>에도시대－상업의 거점－</h1>
        <p>
        에도 시대에 들어서면서 목랍 등의 경제 작물과 하카타 직물, 구루메 무늬, 오구라 직물 등의 공예품 생산이 활발해졌습니다. 많은 도로와 항구, 온가강, 지쿠고강의 수운이 발달하여 후쿠오카현은 규슈 교통의 요충지를 차지했습니다.
        </p>
        </Article>
        <div className="space">
        </div>
        <Article>
        <h1>메이지~쇼와－석탄, 철강산업의 융성－</h1>
        <p>
        메이지에서 쇼와에 걸쳐 지쿠호 일대 및 지쿠고 오무타 지구에서 석탄의 산출이 활발해지자 이를 활용해서 북부에 철강, 기계, 전기, 화학, 요업 등을 중심으로 하는 '기타큐슈 공업지대'가, 남부에는 '석탄화학 콤비나트'가 형성되어 일본의 근대화와 경제 발전을 지탱했습니다.<br/>
        제2차 세계대전 이후에는 국가의 경사생산 방식 및  6·25전쟁으로 인한 특수경기 등으로 철강, 금속, 화학 등 제조업 및 석탄산업이 융성하여 빠르게 전후 황폐화를 극복했습니다.
        </p>
        </Article>
        <div className="space">
        </div>
        <Article>
        <h1>고도성장기－에너지 혁명에 의한 산업구조의 전환－</h1>
        <p>
        1955년 이후의 고도 경제성장기에는 후쿠오카현의 공업생산이 크게 증가한 반면 동시에 진행된 에너지 혁명으로 인해 석탄 산업이 쇠퇴하면서 탄광지역은 심각한 타격을 받았습니다.<br/>
        1970년대의 2번의 오일쇼크 이후에는 저성장 및 엔고, 산업구조의 전환 속에서 소재형 산업의 비중이 높은 후쿠오카현 경제는 어려운 상황에 놓였습니다. 이에 따라 첨단 성장산업의 육성 및 집적을 위해 자동차 산업, 첨단 반도체, 바이오 테크놀로지, 로봇 관련 등의 기업 입지를 추진했습니다. 또한 후쿠오카시를 중심으로 상업과 서비스업 등 3차 산업이 크게 성장했습니다.
        </p>
        </Article>
        <div className="space">
        <div className="navigator left">
        <NavigateCracter type="good"/>
        </div>
        </div>
        <Article>
        <h1>현재－규슈, 서일본, 아시아의 교류 거점을 목표로ー</h1>
        <p>
        1970년대 중반 이후에는 산업이나 생활을 지탱하는 교통 인프라의 정비가 본격화됩니다. 혼슈와 규슈를 잇는 '동양 최장의 현수교'와 당시 주목받았던 관문교 개통 후 1970년대부터 1980년대에 걸쳐 산요 신칸센 신오사카~하카타 간의 전 노선 개업, 규슈를 남북으로 달리는 규슈종관자동차도 고가IC~도스IC 간의 개통, 후쿠오카시 지하철 무로미~덴진 간의 개업, 기타큐슈 모노레일의 개업 등 교통망이 정비되었습니다.<br/>
        또한 2006년에는 신기타큐슈 공항(현 기타큐슈 공항)이 개항했고 2011년에는 규슈 신칸센 하카타~가고시마추오 간, 2016년에는 히가시큐슈자동차도 기타큐슈시~미야자키시 간 전 노선이 개통했습니다..<br/>
        2005년에 국내 4번째의 국립 박물관으로서 규슈 국립박물관이 개관했습니다. 2015년에는 탄광, 철강업, 조선업 등 관련 시설이 '메이지 일본의 산업혁명유산 제철·제강, 조선, 석탄산업'으로서 유네스코 세계문화유산에 등재되었습니다. 2017년에는 '『신이 머무는 섬』 무나카타·오키노시마와 관련 유산군'이 세계문화유산으로 등재되었습니다.<br/>
        사회가 크게 변동하는 가운데 후쿠오카현은 아시아를 비롯해 세계와의 교류를 촉진하기 위해 중요한 교통 기반 정비와 새로운 산업 유치, 뛰어난 기술 개발 등으로 다양한 어려움을 극복하고 산업과 문화 등 모든 면에서 발전해 왔습니다. 앞으로 일본의 발전을 지탱해 갈 규슈의 리더 현으로서 한층 더 비약해 나가겠습니다.<br/>
        </p>
        </Article>
    </>)
}

export default LangKo