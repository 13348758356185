import PageBack from "@/components/Molecules/PageBack"
import PageTitleVirtual from "@/components/Molecules/PageTitle/virtual"
import { CmsIndex } from "@/components/Organisms/Cms"
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio"
import SubLayerTemplate from "@/components/Templates/subLayer"
import { useLocation, useParams } from "react-router-dom"
import { pathAnalyzer } from "src/utilty/pathAnalyzer"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"
import FioParkPage from "./Fiopark"
import getLayer from "src/utilty/getLayer"
import { useEffect, useState } from "react"
import InnerBox from "@/components/Atoms/Box/innerBox"


const VirtualPage = () => {

    const { category } = useParams();
    const layer = getLayer('virtual')
     
    let layerData
    category && category !== 'index' ? layerData = layer.sublayer[category] :  layerData = layer

    const [ segment, setSegment  ] = useState<string>()
    const location = useLocation()
    const pathAnalys =  pathAnalyzer(location)
    const lang = pathAnalys.lang
   
    useEffect(()=>{

        category === 'articles' ?  setSegment(category) :  setSegment('index')

    },[category])

  
    return (
        <ResponsiveFioTemplate>
            <SubLayerTemplate>
                <PageTitleVirtual lang={lang}>
                    FUKUOKA IS OPEN PARK
                </PageTitleVirtual>
               
                {
                    segment === 'index' &&
                    <>
                    <FioParkPage></FioParkPage>
                    <PageBack backto="/?current=7"></PageBack>
                    </>
                }
                {
                     segment === 'articles' &&
                     <>
                     <CmsIndex pageback={false}></CmsIndex>
                     { pathAnalys.id ? 
                     <></>
                     :
                     <PageBack backto="/virtual"></PageBack>
                     }
                    </>
                }
                
               

            </SubLayerTemplate>
        </ResponsiveFioTemplate>
    )
}

export default VirtualPage