


const LangVi = () => {

    return (
        <div className='article-inner'>
        
        <section>
        <h1>Giới thiệu về dấu logo của Kyushu</h1>
        <ul className="photos">
            <li>
                <img src="./images/kyushulogomark/logomark01.jpg" alt="九州ロゴマーク"/>
            </li>
            <li>
                <img src="./images/kyushulogomark/logomark02.jpg" alt="九州ロゴマーク ※のれん"/>
            </li>
        </ul>
        <p>
        Con dấu này được sử dụng để quảng bá về vùng Kyushu trên toàn nước Nhật và trên thế giới. Dấu logo của Kyushu được thiết kế với họa tiết của tấm rèm noren*, chứa đựng mong muốn chào đón mọi người thông qua tấm rèm noren của Kyushu. Con dấu này được tạo thành từ các chữ Hán gồm “Kyu'', “Shu'' và “Ichi”, tượng trưng cho sự liên kết nhiều địa phương của Kyushu thành một khối thống nhất.<br/>
        *Noren:<br/>
        Noren là một tấm rèm ngăn được treo tại lối vào của cửa hàng để che nắng. Đây là một nét văn hóa độc đáo của Nhật Bản, thường được sử dụng để đặt tên hoặc nhãn hiệu cho cửa hàng.
        </p>
        <br/>
        </section>
        <section>
        <h1>Giới thiệu về Kyushu</h1>
        <figure>
        <img src="./images/kyushulogomark/kyushu.jpg" alt="九州について"/>
        </figure>
        <p>Kyushu nằm ở phía Tây Nam Nhật Bản, là vùng đất cho chúng ta cảm nhận trọn vẹn sự hấp dẫn của Nhật Bản, từ thiên nhiên hùng vĩ của núi và biển cho đến suối nước nóng, truyền thống lịch sử văn hóa và ẩm thực. Vui lòng xem video để biết thêm chi tiết.</p>

        <br/>
        <div className="youtube">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/5GNHVfoQd0s?si=JCxXRHx1bwIt-3EO" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        </section>
        <section>
            <h1>Thực tế sử dụng dấu logo của Kyushu ở nước ngoài</h1>
            <p>Dấu logo của Kyushu cũng đang được sử dụng rất nhiều ở nước ngoài.</p>
            <p><strong>◎BOKKSU INC</strong></p>
            <p>"Hộp bánh kẹo Kyushu"  Đăng trên Sổ tay văn hóa </p>
            <figure>
            <img src="./images/kyushulogomark/logomark-bkksu.jpg" alt="BOKKUS"/>
            </figure>
            <p className="note">Bên trong "Hộp bánh kẹo Kyushu" là bánh kẹo đặc sản của vùng Kyushu, ngoài ra còn kèm theo Sổ tay văn hóa trong đó giải thích về loại bánh kẹo này và mô tả về lịch sử, văn hóa và các điểm tham quan du lịch của Kyushu.</p>

            <p><strong>◎MARUTAI Co., Ltd</strong></p>
            <p>"Kyushu Local Stick Ramen Series" (tổng 8 sản phẩm)  Ghi trên mặt sau của gói hàng.</p>
            <figure>
            <img src="./images/kyushulogomark/logomark-marutai.jpg" alt="Marutai"/>
            </figure>
            <p className="note">Hakata Tonkotsu Ramen,  Kumamoto Black Mar Oil Tonkotsu Ramen, Kagoshima Kurobuta Tonkotsu Ramen</p>

            <p><strong>◎DAISHO Co., Ltd</strong></p>
            <p>"Bộ Yam Teppanyaki" Ghi trên mặt sau của gói hàng.</p>
            <figure>
            <img src="./images/kyushulogomark/yamaimoteppan.jpg" alt="山芋鉄板焼きの素"/>
            </figure>
        </section>
        
        <div className="deco-fukidashi navigatorCheck right smile">
        Để biết thông tin chi tiết về các điểm du lịch tại Kyushu, vui lòng truy cập “Visit Kyushu”.
        </div>
       
        <p>Visit Kyushu</p>
        <a href="https://www.welcomekyushu.com/" target="_blank">
            <figure>
            <img src='./images/banner/tabinet.png' alt="Visit Kyushu（九州旅ネット）" className="img-fit"/>
            </figure>
        </a>
        </div>
    )
}

export default LangVi;