
const LangZhTw = () => {


    return (
        <>
            <h2>
                <img src="./images/virtual/fio_park.webp" alt="FUKUOKA IS OPEN PARK"/>
            </h2>
           
            <div className="virtual-inner">
                <p className="message　al-l">
                “FUKUOKA IS OPEN PARK”是遵照福岡實際的空間創建的、與城市聯動的元宇宙，在這裏可以模擬體驗福岡縣的街景、美食、車輛等。                </p>
                <div className="fiopark">
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_01_title_en.webp" alt="FUKUOKA IS OPENパークの入口"/></dt>
                        <dd>
                        <p>天神中央公園鬱鬱蔥蔥，位於福岡縣和九州中心的“天神地區”。可以在此與朋友們一起輕鬆聊天，或是欣賞視頻。 從這裏還可以去往世界各地！</p>
                            <figure>
                                <img src="./images/virtual/fio_park_01_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_02_title_en.webp" alt="FUKUOKA フルーツパーク"/></dt>
                        <dd>
                            <p>享受采摘福岡縣的時令水果和蔬菜的樂趣！</p>
                            <figure>
                                <img src="./images/virtual/fio_park_02_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    
                </div>
                <div className="fiopark-howto">
                    <div className="fiopark-howto-title">
                    <h3><span>FUKUOKA IS OPEN PARK</span> 的使用方法</h3>
                    </div>
                    <div className="fiopark-howto-entrance">
                        <div><img src="./images/virtual/entrance_en.webp" alt="FUKUOKA IS OPEN 参加する"/></div>
                        <div><p>点击 “Join”即可加入。</p></div>
                    </div>
                    <div className="fiopark-howto-app">
                        <div>
                            <p>請事先將應用程序 “cluster ”下載到智能手機、平板電腦、個人電腦或專用VR設備上。</p>
                            
                            <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend pc" target="_blank">
                               <span> 點擊此處查看推薦的係統要求</span>
                            </a>
                        </div>
                        <div>
                            <h4>已經安裝了應用程序的用戶，</h4>
                            <img src="./images/virtual/cluster01.webp" alt="clusterを起動" width={200}/>
                            <p>請點擊[cluster起動]，</p>
                            <img src="./images/virtual/cluster02.webp" alt="遊びに行く"/>
                            <p>然後再點擊[開始遊戲]，進入體驗室。</p> 
                        </div>
                        <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend sp" target="_blank">
                            <span> 點擊此處查看推薦的係統要求</span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

}

export default LangZhTw