
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote";


const LangTh = () => {

    return (
        <>
            <p className="discpriton">
            อาหารชื่อดังอย่างหนึ่งของจังหวัดฟูคูโอคะ "ทงคตสึราเมง"<br/>
            สามารถลิ้มรสชาติราเมงที่ร้านคัดสรรมาอย่างดีได้ตามร้านราเมงที่ตั้งอยู่ในแต่ละพื้นที่ของจังหวัด<br/>
            "TONKOTSU RAMEN BEATS" เป็นเกมที่มีธีมจาก "ทงคตสึราเมง" นั้น<br/>
            จับจังหวะของชามเพื่อให้ได้คะแนนสูง ๆ !
            </p>
            <EmphasisNote className="customNote">
                <h4><img src="./images/ramenbeats/howtoplay.webp" alt="how to play"/></h4>
                <section>
                    <h5 className="first">
                    กดที่ปุ่มเริ่มต้นตรงด้านล่างของหน้าจอ<br/>(จะเปลื่ยนไปยังหน้าเกม)
                    </h5>
                </section>
                <section>
                    <h5 className="second">
                        กดที่<br/>
                        <img src="./images/ramenbeats/start.webp" alt="start" width={230}/>
                    </h5>
                </section>
                <section>
                    <h5 className="third">
                    กดด่านที่ชอบแล้วเริ่มเกมเลย !
                    </h5>
                    <ul className="caption">
                        <li>
                        กรุณาเล่นด้วย OS และบราวเซอร์เวอร์ชั่นล่าสุด
                        </li>
                        <li>
                        เกมนี้ออกแบบมาสำหรับสมาร์ทโฟน
                        </li>
                    </ul>
                </section>
            </EmphasisNote>
            <h4 className="whattonkotsu"><img src="./images/ramenbeats/what_tonkotsu.webp" alt="What’s TONKOTSU RAMEN？"/></h4>
            <p>
            "ทงคตสึ" หมายความว่า "กระดูกหมู"<br/>
            ซุปของทงคตสึราเมงทำมาจากการต้มกระดูกหมูเป็นเวลานาน<br/>
            มีความแตกต่างกันไปตามพื้นที่และร้าน แต่มีลักษณะเฉพาะคือขาวขุ่น<br/>
            ส่วนใหญ่เส้นจะเป็นเส้นตรงที่เล็กบาง<br/>
            ร้านส่วนใหญ่สามารถสั่งความแข็งตามที่ชอบได้ เช่น "แข็งมาก (บาริคาตะ)" "แข็ง (คาตะ)" เป็นต้น<br/>
            นอกจากนี้ ยังมีลักษณะเฉพาะคือร้านส่วนใหญ่สามารถเติมแต่เส้นเพิ่มได้ทีเรียกว่า "คาเอดามะ"
            </p>
            <ul className="phots"> 
                <li>
                    <img  src="./images/ramenbeats/ramen01.webp" alt="豚骨ラーメン"/>
                </li>
                <li>
                    <img  src="./images/ramenbeats/ramen02.webp" alt="豚骨ラーメン"/>
                </li>
            </ul>
            
        
        </>
    )
}

export default LangTh