
import Article from "@/components/Organisms/Article"
import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"
import { FetchArtcleIndexArg, fetchArticleIndex, fetchArticlesFillerCategory } from "src/data/fetch"
import { useEffect, useState } from "react"
import { AritcleIndex } from "src/types/article"
import AriclesSlider from "@/components/Molecules/Slider/ariclesSlider"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { WhiteMoreLink } from "@/components/Atoms/Links/whiteMoreLink"

const SPage = styled.div`
    .app_qr{
        margin: 30px 0;
        background: #fff;
        padding:20px 20px;
        border-radius: 20px;
        hr{
            margin: 30px 0;
        }
    }
`

const LifeStyleDisasterPage = ({lang}:{lang:langType}) => {


    setTitleDiscrption({
        layer : 'lifestyle',
        lang : lang,
        subLayer : 'disasterinfo'
    })

    const dissterArgs:FetchArtcleIndexArg  = {
        endpoint: 'lifestyle',
        lang: lang,
        offset: 0,
        limit: 5,
        categroyId : 'u4mhbac2g7v0' //u4mhbac2g7v0
    }

    const [articles, setArticles]  = useState<AritcleIndex>()

    useEffect(() => {
        const getArticleIndex = async () => {
            const contens = await fetchArticlesFillerCategory(dissterArgs)
           // setArticles(contens)
        }
        getArticleIndex()
    },[])

    return (
        <SPage>
            <Article>
            { articles && 
            /*
            <SSliderWrap>
                <h2><img src="./images/lifestyle/disaster/title.webp"/></h2>
                <div className="slide">
                <AriclesSlider 
                    articles={articles}
                    lang={lang}
                    layer="lifestyle"
                    className="disaster"
                    prevArrow="slideArrowPrevW.png"
                    nextArrow="slideArrowNextW.png"
                ></AriclesSlider>
                 <SMoreLink>
                    <WhiteMoreLink href={`/lifestyle/disasterinfo?lang=${lang}`}/>
                </SMoreLink>
                </div>
            </SSliderWrap>
            */
            <></>
            }
            {lang === 'ja' &&
                <LangJa></LangJa>
            }
            {lang === 'easy_ja' &&
                <LangJa></LangJa>
            }
            {lang === 'en' &&
                <LangEn></LangEn>
            }
            {lang === 'ko' &&
                <LangKo></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh></LangTh>
            }
            {lang === 'vi' &&
                <LangVi></LangVi>
            }
            {lang === 'ne' &&
                <LangNe></LangNe>
            }
            </Article>
        </SPage>
    )
}

const SSliderWrap = styled.div`
    max-width: 500px;
    margin: 0;
    background: #FFE55F;
    padding: 70px 20px 30px;
    background-image: url('./images/lifestyle/disaster/bk.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 40px;
    position: relative;
    h2{
        background: none;
        border: none;
        padding: 0;
        img{ width:100% }
    }
    .slide{
        padding: 0 10px;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .slide{ padding: 0 30px; }
    }
`

const SMoreLink = styled.div`
    position: absolute;
    bottom: 25%;
    right: 7%;
    width: 46%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        bottom: 0%;
        right: 5%;
        width: 38%;
    }
`

export default LifeStyleDisasterPage