

import styled from "styled-components"
import { Challenger, ChallengerrFullLength } from "@/components/Atoms/Character/challenger"
import { NavigateCracter, NavigatorFullLength } from "@/components/Atoms/Character/navigator"
import { ChallengerType, NavigatorType } from "src/types/charactor"

const SCharactor = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 10 / 3.8;

    .challengerWrap{
        position: absolute;
        width: 50%;
        top: 0;
        left: 3%;
        transform: translateY(-5%);
        &.normal{
            width: 54%;
        }
    }
    .navigator{
        position: absolute;
        width: 28%;
        z-index: 11;
        top:0;
        transform: translateY(-5%);
        right: 20px;
    }
    @media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
    (max-width: ${props => props.theme.responsive.minPc}) ),
    screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
        .challengerWrap{
            figure{
                aspect-ratio: 10 / 9.5;
            }
        }
    }
`

export const NaviAndChallenger = (
    {challengarType, navigatorType}
    :{
        challengarType: ChallengerType,
        navigatorType: NavigatorType
    }   
    ) => {

    return (
        <SCharactor>
            <div className="challenger">
                <div className={`challengerWrap ${challengarType}`}>
                    <Challenger type={challengarType}></Challenger>
                </div>
            </div>
            <div className="navigator">
                <NavigateCracter type={navigatorType}></NavigateCracter>
            </div>
        </SCharactor>
    )
}