import { useState } from "react"
import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang"
import Article from "@/components/Organisms/Article"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"



const  SMuic = styled.div`
    .tabs-custom{
        nav{
            display: flex;
            flex-wrap: wrap;
            > div{
                flex-basis: 48%;
                box-sizing: border-box;
                padding-bottom: 10px;
                &:nth-child(odd){
                    display: flex;
                    justify-content: flex-end;
                }
            }
            button{
                width: 100px;
                &.active{
                    background: #EA6000;
                    color: #fff;
                }
            }
        }
        @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
            nav{
                > div{
                    flex-basis: 24%;
                    text-align: center;
                    &:nth-child(odd){
                        display: block;
                    }
                }
                button{
                    width: 150px;
                }
            }
        }

    }
`


const MunicPage = ({lang}:{lang:langType}) => {


    setTitleDiscrption({
        layer:'consultation',
        lang:lang,
        subLayer:"municipalitycounter"
    })

    const [activeTab, setActiveTab] = useState('tab1'); 
    const handleTabClick = (tabId) => {
        setActiveTab(tabId); // クリックされたタブをアクティブにする
    };

    const langPageProps = {
        activeTab : activeTab,
        handleTabClick : handleTabClick
    }

    return (
        <SMuic>
             <Article offset={0}>
            <>
            {lang === 'ja' &&
                <LangJa {...langPageProps}></LangJa>
            }
            {lang === 'easy_ja' &&
                <LangJa {...langPageProps}></LangJa>
            }
            {lang === 'en' &&
                <LangEn {...langPageProps}></LangEn>
            }
            {lang === 'ko' &&
                <LangKo {...langPageProps}></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn {...langPageProps}></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw {...langPageProps}></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh {...langPageProps}></LangTh>
            }
            {lang === 'vi' &&
                <LangVi {...langPageProps}></LangVi>
            }
            {lang === 'ne' &&
                <LangNe {...langPageProps}></LangNe>
            }
            </>
            </Article>
        </SMuic>
    )
}


export default MunicPage