
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import { AreaMap } from "./areaMap"

const SSvg = styled.div`
      
`
const SOverride = styled.div`
@media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
(max-width: ${props => props.theme.responsive.minPc}) ),
screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
    .fukidashiPosition{
        &.kitakyu{
            top: 18%;
        }
        &.chikuhou{
            bottom: 45% !important;
        }
        &.chikugo{
            bottom: 43% !important;
        }
    }
}
`

const LangEn = ({onSwich, onFukidashi}) => {

    return (
        <SOverride>
            <div className="area-info">
            <SSvg>
            <figure className="map">
                <svg id="_レイヤー_1" data-name="レイヤー 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.92 286.92">
                <rect className="fukuoka" onClick={() => { onSwich(4) }} x="4" y="58.23" width="97.53" height="23.67" rx="3.9" ry="3.9" transform="translate(105.53 140.13) rotate(180)"/>
                <g onClick={() => { onSwich(4) }}>
                    <path className="moji" d="M13.67,69.43c.15,0,.67,0,.67.64,0,.45-.23.66-.67.66h-3.57v3.71c0,.42-.22.69-.73.69-.45,0-.74-.18-.74-.69v-7.67c0-.51,0-1.46,1.4-1.46h4.01c.34,0,.68.13.68.65,0,.49-.27.68-.68.68h-3.52c-.32,0-.42.08-.42.42v2.35h3.57Z"/>
                    <path className="moji" d="M16.58,68.21c0-.4.19-.64.67-.64s.69.21.69.64v3.62c0,.88,0,2.06,1.42,2.06.51,0,1.72-.18,1.72-2.06v-3.62c0-.15,0-.64.68-.64.4,0,.69.16.69.64v6.31c0,.47-.3.62-.66.62-.39,0-.66-.15-.66-.62v-.42c-.67.91-1.54,1.04-2.1,1.04-1.26,0-1.9-.72-2.13-1.14-.28-.55-.33-1.1-.33-2.16v-3.63Z"/>
                    <path className="moji" d="M28.56,67.96c.38-.38.39-.39.59-.39.24,0,.67.19.67.57,0,.19-.17.42-.29.55l-2.15,2.11,2.64,3.11c.12.13.32.39.32.59,0,.38-.4.64-.75.64-.19,0-.33-.09-.45-.23-.04-.04-.17-.23-.19-.27l-3-3.7v3.55c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-8.99c0-.41.21-.64.67-.64.49,0,.69.22.69.64v5.09l2.61-2.64Z"/>
                    <path className="moji" d="M31.91,68.21c0-.4.19-.64.67-.64s.69.21.69.64v3.62c0,.88,0,2.06,1.42,2.06.51,0,1.72-.18,1.72-2.06v-3.62c0-.15,0-.64.68-.64.4,0,.69.16.69.64v6.31c0,.47-.3.62-.66.62-.39,0-.66-.15-.66-.62v-.42c-.67.91-1.54,1.04-2.1,1.04-1.26,0-1.9-.72-2.13-1.14-.28-.55-.33-1.1-.33-2.16v-3.63Z"/>
                    <path className="moji" d="M43.1,67.57c1.94,0,3.53,1.47,3.53,3.78s-1.59,3.79-3.53,3.79-3.52-1.44-3.52-3.79,1.61-3.78,3.52-3.78ZM43.1,73.89c.95,0,2.16-.62,2.16-2.54s-1.22-2.54-2.16-2.54-2.15.59-2.15,2.54,1.21,2.54,2.15,2.54Z"/>
                    <path className="moji" d="M52.4,67.96c.38-.38.39-.39.59-.39.24,0,.67.19.67.57,0,.19-.17.42-.29.55l-2.15,2.11,2.64,3.11c.12.13.32.39.32.59,0,.38-.4.64-.75.64-.19,0-.33-.09-.45-.23-.04-.04-.17-.23-.19-.27l-3-3.7v3.55c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-8.99c0-.41.21-.64.67-.64s.69.22.69.64v5.09l2.61-2.64Z"/>
                    <path className="moji" d="M61.39,73.35c0,.15,0,.4.06.76.01.01.08.42.08.46,0,.5-.56.56-.7.56-.59,0-.64-.49-.67-1.15-.23.28-.93,1.15-2.38,1.15s-2.38-.85-2.38-2.18c0-1.95,1.86-2.23,2.87-2.38,1.58-.23,1.77-.27,1.77-.8,0-.64-.64-.99-1.6-.99-.21,0-.84.01-1.52.29-.17.07-.3.12-.44.12-.33,0-.55-.32-.55-.63,0-.34.22-.46.5-.58.62-.28,1.27-.41,1.95-.41.93,0,1.94.27,2.51.98.28.35.47.84.47,1.86v2.95ZM60.07,71.23c-.33.19-.46.22-1.7.4-.8.12-1.67.25-1.67,1.27,0,.45.22,1.09,1.19,1.09,1.35,0,1.9-.97,2.18-1.47v-1.3Z"/>
                    <path className="moji" d="M68.81,74.43c-.17.46-.25.7-.7.7-.34,0-.85-.16-.85-.61,0-.11.02-.23.16-.58l3-7.83c.17-.45.39-.99,1.2-.99s1.04.52,1.22.99l3,7.83c.1.27.16.42.16.58,0,.44-.5.61-.84.61-.47,0-.57-.27-.73-.7l-.72-1.99h-4.17l-.73,1.99ZM73.25,71.19l-1.63-4.45-1.62,4.45h3.25Z"/>
                    <path className="moji" d="M78.89,74.49c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-6.31c0-.47.29-.62.64-.62.4,0,.67.14.67.62v.41c.36-.59,1.12-1.03,1.78-1.03.15,0,.56.05.56.63s-.3.59-.74.64c-.27.02-1.55.24-1.55,2.1v3.55Z"/>
                    <path className="moji" d="M83.86,71.78c.05.61.17,2.17,2.37,2.17.7,0,1.09-.12,1.5-.24.07-.02.15-.05.24-.05.29,0,.55.24.55.61,0,.32-.17.46-.4.55-.25.1-.97.33-1.94.33-3.13,0-3.68-2.45-3.68-3.72,0-2.12,1.23-3.84,3.28-3.84,2.38,0,3.18,2.16,3.18,3.35,0,.86-.76.86-1.37.86h-3.72ZM87.38,70.75c.13,0,.24,0,.24-.16,0-.36-.36-1.84-1.83-1.84-.97,0-1.77.68-1.9,2h3.49Z"/>
                    <path className="moji" d="M96.41,73.35c0,.15,0,.4.06.76.01.01.08.42.08.46,0,.5-.56.56-.7.56-.59,0-.64-.49-.67-1.15-.23.28-.93,1.15-2.38,1.15s-2.38-.85-2.38-2.18c0-1.95,1.86-2.23,2.87-2.38,1.58-.23,1.77-.27,1.77-.8,0-.64-.64-.99-1.6-.99-.21,0-.84.01-1.52.29-.17.07-.3.12-.44.12-.33,0-.55-.32-.55-.63,0-.34.22-.46.5-.58.62-.28,1.27-.41,1.95-.41.93,0,1.94.27,2.51.98.28.35.47.84.47,1.86v2.95ZM95.08,71.23c-.33.19-.46.22-1.7.4-.8.12-1.67.25-1.67,1.27,0,.45.22,1.09,1.19,1.09,1.35,0,1.9-.97,2.18-1.47v-1.3Z"/>
                </g>
               
                <rect onClick={() => { onSwich(2) }} className="chikugo" x="4" y="185.61" width="97.53" height="23.67" rx="3.9" ry="3.9" transform="translate(105.53 394.9) rotate(180)"/>
                <g onClick={() => { onSwich(2) }}>
                    <path className="moji" d="M9.19,197.52c0-2.88,2.07-5.01,5.12-5.01.9,0,1.79.17,2.57.62.34.21.34.46.34.56,0,.39-.28.72-.63.72-.15,0-.25-.05-.4-.11-.79-.33-1.08-.45-1.83-.45-2.4,0-3.7,1.69-3.7,3.66s1.36,3.67,3.74,3.67c.74,0,1.4-.17,1.73-.29.49-.18.53-.19.66-.19.33,0,.63.27.63.68s-.27.55-.42.62c-1.04.44-1.95.52-2.67.52-3.3,0-5.13-2.32-5.13-5Z"/>
                    <path className="moji" d="M24.93,201.88c0,.41-.22.64-.68.64s-.69-.21-.69-.64v-3.62c0-.89,0-2.06-1.41-2.06-.84,0-1.27.51-1.43.74-.3.45-.3,1-.3,1.32v3.62c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-8.99c0-.41.21-.64.67-.64s.69.22.69.64v3.02c.59-.64,1.1-.96,2.06-.96,1.17,0,1.84.64,2.11,1.14.29.54.34,1.1.34,2.16v3.63Z"/>
                    <path className="moji" d="M28.83,193.28c0,.48-.38.87-.87.87s-.87-.39-.87-.87.4-.87.87-.87.87.4.87.87ZM27.27,195.62c0-.41.21-.64.68-.64.42,0,.69.17.69.64v6.26c0,.4-.19.64-.68.64-.42,0-.69-.17-.69-.64v-6.26Z"/>
                    <path className="moji" d="M34.95,195.34c.38-.38.39-.39.59-.39.24,0,.67.19.67.57,0,.19-.17.42-.29.54l-2.15,2.11,2.64,3.11c.12.13.32.39.32.59,0,.38-.4.64-.75.64-.19,0-.33-.09-.45-.23-.04-.04-.17-.23-.19-.27l-3-3.7v3.55c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-8.99c0-.41.21-.64.67-.64s.69.22.69.64v5.09l2.61-2.64Z"/>
                    <path className="moji" d="M38.3,195.59c0-.4.19-.64.67-.64s.69.21.69.64v3.62c0,.88,0,2.06,1.42,2.06.51,0,1.72-.18,1.72-2.06v-3.62c0-.15,0-.64.68-.64.4,0,.69.16.69.64v6.31c0,.47-.3.62-.65.62-.39,0-.66-.15-.66-.62v-.42c-.67.91-1.54,1.04-2.1,1.04-1.26,0-1.9-.72-2.13-1.14-.28-.55-.33-1.1-.33-2.16v-3.63Z"/>
                    <path className="moji" d="M52.06,194.95c.4,0,.7.15.7.59,0,.3-.16.5-.47.55-.36.06-.44.07-.65.18.07.13.29.53.29,1.18,0,1.72-1.52,2.35-2.83,2.35-.3,0-.51-.04-.63-.05-.45.17-.75.42-.75.68,0,.41.39.44,1.93.53.83.05,2.89.17,2.89,2.22,0,1.84-1.82,2.37-3.51,2.37-2.17,0-3.2-.95-3.2-2.23,0-.55.17-1.29,1.13-1.88-.24-.16-.62-.39-.62-.93,0-.33.16-.8.85-1.23-.81-.58-.97-1.37-.97-1.89,0-1.52,1.31-2.44,2.85-2.44.53,0,1.09.09,1.69.42.47-.35,1.01-.42,1.31-.42ZM48.74,202.02c-.23,0-1.58,0-1.58,1.19s1.54,1.21,1.99,1.21c1.32,0,2.05-.47,2.05-1.21,0-.96-.88-1.19-2.46-1.19ZM47.5,197.42c0,.82.73,1.29,1.55,1.29.88,0,1.58-.51,1.58-1.33s-.63-1.31-1.54-1.31-1.59.53-1.59,1.36Z"/>
                    <path className="moji" d="M57.32,194.95c1.94,0,3.53,1.47,3.53,3.79s-1.59,3.78-3.53,3.78-3.52-1.44-3.52-3.78,1.61-3.79,3.52-3.79ZM57.32,201.27c.95,0,2.16-.62,2.16-2.54s-1.22-2.54-2.16-2.54-2.15.59-2.15,2.54,1.21,2.54,2.15,2.54Z"/>
                    <path className="moji" d="M67.94,201.82c-.17.46-.25.7-.7.7-.34,0-.85-.16-.85-.61,0-.11.02-.23.16-.58l3-7.82c.17-.45.39-1,1.2-1s1.04.52,1.22,1l3,7.82c.1.27.16.42.16.58,0,.44-.5.61-.84.61-.47,0-.57-.27-.73-.7l-.72-1.99h-4.17l-.73,1.99ZM72.38,198.58l-1.63-4.45-1.62,4.45h3.25Z"/>
                    <path className="moji" d="M78.02,201.88c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-6.31c0-.47.29-.62.64-.62.4,0,.67.15.67.62v.41c.36-.59,1.12-1.03,1.78-1.03.15,0,.56.05.56.63s-.3.59-.74.64c-.27.02-1.55.24-1.55,2.1v3.55Z"/>
                    <path className="moji" d="M82.99,199.16c.05.61.17,2.17,2.37,2.17.7,0,1.09-.12,1.5-.24.07-.02.15-.05.24-.05.29,0,.55.24.55.61,0,.32-.17.46-.4.55-.25.1-.97.33-1.94.33-3.13,0-3.68-2.45-3.68-3.72,0-2.12,1.23-3.85,3.28-3.85,2.38,0,3.18,2.16,3.18,3.35,0,.86-.76.86-1.37.86h-3.72ZM86.51,198.13c.13,0,.24,0,.24-.16,0-.36-.36-1.84-1.83-1.84-.97,0-1.77.68-1.9,2h3.49Z"/>
                    <path className="moji" d="M95.53,200.74c0,.15,0,.4.06.76.01.01.08.42.08.46,0,.5-.56.56-.7.56-.59,0-.64-.49-.67-1.15-.23.28-.93,1.15-2.38,1.15s-2.38-.85-2.38-2.18c0-1.95,1.86-2.23,2.87-2.38,1.58-.23,1.77-.27,1.77-.8,0-.64-.64-.99-1.6-.99-.21,0-.84.01-1.52.29-.17.07-.3.12-.44.12-.33,0-.55-.32-.55-.63,0-.34.22-.46.5-.58.62-.28,1.27-.41,1.95-.41.93,0,1.94.27,2.51.98.28.35.47.84.47,1.86v2.95ZM94.21,198.61c-.33.19-.46.22-1.7.4-.8.12-1.67.25-1.67,1.27,0,.45.22,1.09,1.19,1.09,1.35,0,1.9-.97,2.18-1.47v-1.3Z"/>
                </g>
              

                <AreaMap onSwich={onSwich}></AreaMap>
                <rect onClick={() => { onSwich(1) }} className="kitakyu" x="167.93" y="10.6" width="115.57" height="23.67" rx="3.9" ry="3.9" transform="translate(451.44 44.87) rotate(180)"/>
                <g onClick={() => { onSwich(1) }}>
                    <path className="moji" d="M179.31,17.98s.19-.24.23-.28c.17-.17.34-.21.47-.21.39,0,.78.29.78.64,0,.17-.13.38-.24.49l-3.29,3.47,3.6,4.16c.17.19.3.36.3.58,0,.42-.49.67-.8.67s-.47-.21-.72-.5l-3.87-4.67v4.48c0,.42-.22.69-.73.69-.45,0-.74-.18-.74-.69v-8.64c0-.41.21-.68.73-.68.46,0,.74.18.74.68v3.69l3.53-3.88Z"/>
                    <path className="moji" d="M184.47,18.26c0,.49-.38.87-.87.87s-.87-.39-.87-.87.4-.87.87-.87.87.4.87.87ZM182.92,20.6c0-.41.21-.64.68-.64.42,0,.69.17.69.64v6.26c0,.4-.19.64-.68.64-.42,0-.69-.17-.69-.64v-6.26Z"/>
                    <path className="moji" d="M188.88,25.15c0,.76.12,1.1,1.02,1.1.07,0,.4-.01.47-.01.28,0,.57.15.57.59,0,.67-.56.67-1.35.67-2.07,0-2.07-1.24-2.07-2.28v-3.85h-.86c-.12,0-.63,0-.63-.62,0-.46.25-.63.63-.63h.86v-1.61c0-.3.11-.64.67-.64.44,0,.69.17.69.64v1.61h1.55c.32,0,.63.13.63.62,0,.63-.5.63-.63.63h-1.55v3.77Z"/>
                    <path className="moji" d="M198.28,25.72c0,.15,0,.4.06.76.01.01.08.42.08.46,0,.5-.56.56-.7.56-.59,0-.64-.48-.67-1.15-.23.28-.93,1.15-2.38,1.15s-2.38-.85-2.38-2.18c0-1.95,1.86-2.23,2.87-2.38,1.58-.23,1.77-.27,1.77-.8,0-.64-.64-1-1.6-1-.21,0-.84.01-1.52.29-.17.07-.3.12-.44.12-.33,0-.55-.32-.55-.63,0-.34.22-.46.5-.58.62-.28,1.27-.41,1.95-.41.93,0,1.94.27,2.51.98.28.35.47.84.47,1.86v2.95ZM196.96,23.6c-.33.19-.46.22-1.7.4-.8.12-1.67.25-1.67,1.27,0,.45.22,1.09,1.19,1.09,1.35,0,1.9-.97,2.18-1.47v-1.3Z"/>
                    <path className="moji" d="M204.38,20.33c.38-.38.39-.39.59-.39.24,0,.67.19.67.57,0,.19-.17.42-.29.55l-2.15,2.11,2.64,3.11c.12.13.32.39.32.59,0,.38-.4.64-.75.64-.19,0-.33-.09-.45-.23-.04-.04-.17-.23-.19-.27l-3-3.7v3.55c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-8.99c0-.41.21-.64.67-.64.49,0,.69.22.69.64v5.09l2.61-2.64Z"/>
                    <path className="moji" d="M207.23,20.97c-.07-.19-.13-.33-.13-.49,0-.39.44-.55.75-.55.39,0,.47.22.59.52l1.72,4.46,1.72-4.46c.12-.3.19-.52.61-.52.29,0,.73.15.73.56,0,.11-.01.19-.12.46l-3.6,9.04c-.13.33-.22.55-.62.55-.33,0-.7-.17-.7-.55,0-.08.02-.18.12-.44l1.16-2.97-2.23-5.62Z"/>
                    <path className="moji" d="M214.74,20.58c0-.4.19-.64.67-.64s.69.21.69.64v3.62c0,.89,0,2.06,1.42,2.06.51,0,1.72-.18,1.72-2.06v-3.62c0-.15,0-.64.68-.64.4,0,.69.16.69.64v6.31c0,.47-.3.62-.65.62-.39,0-.66-.15-.66-.62v-.42c-.67.91-1.54,1.04-2.1,1.04-1.26,0-1.9-.72-2.13-1.14-.28-.55-.33-1.1-.33-2.16v-3.63Z"/>
                    <path className="moji" d="M223.36,26.06c.17.05.62.19,1.21.19,1.41,0,1.5-.8,1.5-1,0-.65-.47-.81-1.72-1.19-.67-.21-1.86-.57-1.86-2,0-1.27,1.06-2.13,2.56-2.13.72,0,1.25.18,1.46.27.15.06.46.18.46.58,0,.32-.23.64-.57.64-.06,0-.4-.11-.49-.13-.25-.07-.52-.11-.8-.11-.74,0-1.25.32-1.25.9,0,.62.28.71,1.72,1.18.73.23,1.84.59,1.84,2.04s-1.26,2.21-2.9,2.21c-1.03,0-1.81-.3-2.03-.42-.23-.13-.28-.34-.28-.5,0-.28.19-.67.61-.67.05,0,.46.13.52.15Z"/>
                    <path className="moji" d="M235.04,26.86c0,.41-.22.64-.68.64s-.69-.21-.69-.64v-3.62c0-.88,0-2.06-1.41-2.06-.84,0-1.27.51-1.43.74-.3.45-.3,1-.3,1.32v3.62c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-8.99c0-.41.21-.64.67-.64s.69.22.69.64v3.02c.59-.64,1.1-.96,2.06-.96,1.17,0,1.84.64,2.11,1.14.29.55.34,1.1.34,2.16v3.63Z"/>
                    <path className="moji" d="M237.19,20.58c0-.4.19-.64.67-.64s.69.21.69.64v3.62c0,.89,0,2.06,1.42,2.06.51,0,1.72-.18,1.72-2.06v-3.62c0-.15,0-.64.68-.64.4,0,.69.16.69.64v6.31c0,.47-.3.62-.65.62-.39,0-.66-.15-.66-.62v-.42c-.67.91-1.54,1.04-2.1,1.04-1.26,0-1.9-.72-2.13-1.14-.28-.55-.33-1.1-.33-2.16v-3.63Z"/>
                    <path className="moji" d="M250.49,26.8c-.17.46-.25.7-.7.7-.34,0-.85-.16-.85-.61,0-.11.02-.23.16-.58l3-7.82c.17-.45.39-1,1.2-1s1.04.52,1.22,1l3,7.82c.1.27.16.42.16.58,0,.44-.5.61-.84.61-.47,0-.57-.27-.73-.7l-.72-1.99h-4.17l-.73,1.99ZM254.93,23.56l-1.63-4.45-1.62,4.45h3.25Z"/>
                    <path className="moji" d="M260.57,26.86c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-6.31c0-.47.29-.62.64-.62.4,0,.67.15.67.62v.41c.36-.59,1.12-1.03,1.78-1.03.15,0,.56.05.56.63s-.3.59-.74.64c-.27.02-1.55.24-1.55,2.1v3.55Z"/>
                    <path className="moji" d="M265.54,24.15c.05.61.17,2.17,2.37,2.17.7,0,1.09-.12,1.5-.24.07-.02.15-.05.24-.05.29,0,.55.24.55.61,0,.32-.17.46-.4.55-.25.1-.97.33-1.94.33-3.13,0-3.68-2.45-3.68-3.72,0-2.12,1.23-3.85,3.28-3.85,2.38,0,3.18,2.16,3.18,3.35,0,.86-.76.86-1.37.86h-3.72ZM269.06,23.12c.13,0,.24,0,.24-.16,0-.36-.36-1.84-1.83-1.84-.97,0-1.77.68-1.9,2h3.49Z"/>
                    <path className="moji" d="M278.09,25.72c0,.15,0,.4.06.76.01.01.08.42.08.46,0,.5-.56.56-.7.56-.59,0-.64-.48-.67-1.15-.23.28-.93,1.15-2.38,1.15s-2.38-.85-2.38-2.18c0-1.95,1.86-2.23,2.87-2.38,1.58-.23,1.77-.27,1.77-.8,0-.64-.64-1-1.6-1-.21,0-.84.01-1.52.29-.17.07-.3.12-.44.12-.33,0-.55-.32-.55-.63,0-.34.22-.46.5-.58.62-.28,1.27-.41,1.95-.41.93,0,1.94.27,2.51.98.28.35.47.84.47,1.86v2.95ZM276.76,23.6c-.33.19-.46.22-1.7.4-.8.12-1.67.25-1.67,1.27,0,.45.22,1.09,1.19,1.09,1.35,0,1.9-.97,2.18-1.47v-1.3Z"/>
                </g>
                <rect className="chikuhou" onClick={() => { onSwich(3) }} x="188.53" y="173.27" width="94.98" height="23.67" rx="3.9" ry="3.9" transform="translate(472.03 370.22) rotate(180)"/>
                <g onClick={() => { onSwich(3) }}>
                    <path className="moji" d="M192.8,185.18c0-2.88,2.07-5.01,5.12-5.01.9,0,1.79.17,2.57.62.34.21.34.46.34.56,0,.39-.28.72-.63.72-.15,0-.25-.05-.4-.11-.79-.33-1.08-.45-1.83-.45-2.4,0-3.7,1.69-3.7,3.66s1.36,3.68,3.74,3.68c.74,0,1.39-.17,1.73-.29.49-.18.53-.19.66-.19.33,0,.63.27.63.68s-.27.55-.42.62c-1.04.44-1.95.52-2.67.52-3.3,0-5.13-2.32-5.13-5Z"/>
                    <path className="moji" d="M208.54,189.54c0,.41-.22.64-.68.64s-.69-.21-.69-.64v-3.61c0-.89,0-2.06-1.41-2.06-.84,0-1.27.51-1.43.74-.3.45-.3.99-.3,1.32v3.61c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-8.99c0-.41.21-.64.67-.64.49,0,.69.22.69.64v3.02c.59-.64,1.1-.96,2.06-.96,1.16,0,1.84.64,2.11,1.14.29.55.34,1.1.34,2.16v3.63Z"/>
                    <path className="moji" d="M212.44,180.93c0,.49-.38.87-.87.87s-.87-.39-.87-.87.4-.87.87-.87.87.4.87.87ZM210.88,183.28c0-.41.21-.64.68-.64.42,0,.69.17.69.64v6.26c0,.4-.19.64-.68.64-.42,0-.69-.17-.69-.64v-6.26Z"/>
                    <path className="moji" d="M218.56,183c.38-.38.39-.39.59-.39.24,0,.67.19.67.57,0,.19-.17.42-.29.55l-2.15,2.11,2.64,3.11c.12.13.32.39.32.59,0,.38-.4.64-.75.64-.19,0-.33-.09-.45-.23-.04-.04-.17-.23-.19-.27l-3-3.7v3.55c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-8.99c0-.41.21-.64.67-.64.49,0,.69.22.69.64v5.1l2.61-2.64Z"/>
                    <path className="moji" d="M221.91,183.25c0-.4.19-.64.67-.64s.69.21.69.64v3.62c0,.89,0,2.06,1.42,2.06.51,0,1.72-.18,1.72-2.06v-3.62c0-.14,0-.64.68-.64.4,0,.69.16.69.64v6.31c0,.47-.3.62-.66.62-.39,0-.66-.15-.66-.62v-.42c-.67.91-1.54,1.04-2.1,1.04-1.26,0-1.9-.72-2.13-1.14-.28-.55-.33-1.1-.33-2.16v-3.63Z"/>
                    <path className="moji" d="M235.8,189.54c0,.41-.22.64-.68.64s-.69-.21-.69-.64v-3.61c0-.89,0-2.06-1.41-2.06-.84,0-1.27.51-1.43.74-.3.45-.3.99-.3,1.32v3.61c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-8.99c0-.41.21-.64.67-.64.49,0,.69.22.69.64v3.02c.59-.64,1.1-.96,2.06-.96,1.16,0,1.84.64,2.11,1.14.29.55.34,1.1.34,2.16v3.63Z"/>
                    <path className="moji" d="M241.12,182.61c1.94,0,3.53,1.47,3.53,3.79s-1.59,3.79-3.53,3.79-3.52-1.44-3.52-3.79,1.61-3.79,3.52-3.79ZM241.12,188.93c.95,0,2.16-.62,2.16-2.54s-1.22-2.54-2.16-2.54-2.15.59-2.15,2.54,1.21,2.54,2.15,2.54Z"/>
                    <path className="moji" d="M251.74,189.47c-.17.46-.25.7-.7.7-.34,0-.85-.16-.85-.61,0-.11.02-.23.16-.58l3-7.82c.17-.45.39-.99,1.2-.99s1.04.52,1.22.99l3,7.82c.1.27.16.42.16.58,0,.44-.5.61-.84.61-.47,0-.57-.27-.73-.7l-.72-1.99h-4.17l-.73,1.99ZM256.18,186.24l-1.63-4.45-1.62,4.45h3.25Z"/>
                    <path className="moji" d="M261.82,189.54c0,.15,0,.64-.67.64-.41,0-.69-.16-.69-.64v-6.31c0-.47.29-.62.64-.62.4,0,.67.15.67.62v.41c.36-.59,1.12-1.03,1.78-1.03.15,0,.56.05.56.63s-.3.59-.74.64c-.27.03-1.55.24-1.55,2.1v3.55Z"/>
                    <path className="moji" d="M266.79,186.82c.05.61.17,2.17,2.37,2.17.7,0,1.09-.12,1.5-.24.07-.02.15-.05.24-.05.29,0,.55.24.55.61,0,.32-.17.46-.4.55-.25.1-.97.33-1.94.33-3.13,0-3.68-2.45-3.68-3.72,0-2.12,1.23-3.85,3.28-3.85,2.38,0,3.18,2.16,3.18,3.35,0,.86-.76.86-1.37.86h-3.72ZM270.31,185.79c.13,0,.24,0,.24-.16,0-.36-.36-1.84-1.83-1.84-.97,0-1.77.68-1.9,2h3.49Z"/>
                    <path className="moji" d="M279.34,188.4c0,.14,0,.4.06.76.01.01.08.42.08.46,0,.5-.56.56-.7.56-.59,0-.64-.49-.67-1.15-.23.28-.93,1.15-2.38,1.15s-2.38-.85-2.38-2.18c0-1.95,1.86-2.23,2.87-2.38,1.58-.23,1.77-.27,1.77-.8,0-.64-.64-1-1.6-1-.21,0-.84.01-1.52.29-.17.07-.3.12-.44.12-.33,0-.55-.32-.55-.63,0-.34.22-.46.5-.58.62-.28,1.27-.41,1.95-.41.93,0,1.94.27,2.51.98.28.35.47.84.47,1.86v2.95ZM278.01,186.27c-.33.19-.46.22-1.7.4-.8.12-1.67.25-1.67,1.27,0,.45.22,1.09,1.19,1.09,1.35,0,1.9-.97,2.18-1.47v-1.3Z"/>
                </g>
                </svg>
                </figure>
                </SSvg>
                    <div className={`fukidashiPosition kitakyu ${onFukidashi === 1 ? 'active' : ''}`}>
                        <div className="fukidashi kitakyu">
                            <figure>
                            <img src={publicPath('images/basicinfo_area/en/kitakyu.svg')} className="fit-img"
                            alt={`Industries such as steel, automobiles, advanced semiconductors, and robotics have advanced, and the accumulated "manufacturing technology" is being used to revitalize the region. Kitakyushu Airport, which operates 24 hours a day, is located in Kitakyushu, and is being reorganized as a logistics hub for Asia.`} />
                            </figure>
                          <span className="speak"></span>
                        </div>
                    </div>
                    <div className={`fukidashiPosition fukuoka ${onFukidashi === 4 ? 'active' : ''}`}>
                        <div className="fukidashi fukuoka">
                            <figure>
                            <img src={publicPath('images/basicinfo_area/en/fukuoka.svg')} className="fit-img"
                            alt={`The city has developed as a leading zone in western Japan, with a concentration of central administrative functions and tertiary industries in Kyushu. The transportation infrastructure, including the Kyushu Shinkansen bullet train, Fukuoka Airport, and the Port of Hakata, is being developed.`} />
                            </figure>
                          
                          <span className="speak"></span>
                        </div>
                    </div>
                    <div className={`fukidashiPosition chikugo ${onFukidashi === 2 ? 'active' : ''}`}>
                        <div className="fukidashi chikugo">
                       
                        <figure>
                        <img src={publicPath('images/basicinfo_area/en/chikugo.svg')} className="fit-img"
                            alt={`It is a region full of charm, with rich natural agricultural, forestry and fisheries products, local industry, a variety of commercial and industrial industries, culture, and unique cities. The Chikugo Network Garden City Initiative is being promoted to develop the rich individuality of cities through cooperation and conservation of their distinctive features.`} />
                        </figure>
                          <span className="speak"></span>
                        </div>
                    </div>
                    <div className={`fukidashiPosition chikuhou ${onFukidashi === 3 ? 'active' : ''}`}>
                        <div className="fukidashi chikuhou">
                        <figure>
                            <img src={publicPath('images/basicinfo_area/en/chikuho.svg')} className="fit-img"
                            alt={`As a new alternative to the coal industry, the automobile industry has been locating in the area, and human resource development to support the automobile industry is actively underway. The city is also creating a base for the creation of new industries through the concentration of venture companies and research institutes, centered on science and technology universities.`} />
                            </figure>
                        
                          <span className="speak"></span>
                        </div>
                    </div>
                    
                </div>
        </SOverride>
    )
}

export default LangEn