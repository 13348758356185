import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlue, FukidashiBlueBottom } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { ChallengerAction, NavigatorAction } from "@/components/Molecules/CharactorAction"



const LangKo = () => {
    const lang = 'ko'
    
    return (<>  
    <InnerBox left={0} right={0} bottom={60}>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>일본어를 못 합니다. 여러 언어로 대응해 주는 상담 창구가 있나요?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>후쿠오카현 외국인 상담 센터’에서는 일본어를 포함한 22개 국어로 상담이 가능합니다. 또한 각 시정촌이 다국어 대응 상담 창구와 국제교류협회를 설치하여 언어 지원 및 법률 조언, 생활 전반에 걸친 지원을 실시하고 있습니다. </span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="normal"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">자세한 내용은</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/consultation/international?lang=${lang}`}
    linkKey="kokusai_soudan"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="puzzled"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>가족과 함께 오고 싶은데, 일본어를 못 하기 때문에 일본어를 배울 수 있는 곳을 알려 주실 수 있을까요?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>후쿠오카현 내에는 행정기관 및 자원봉사자가 개최하고 있는 일본어 교실이 100곳 있습니다. 평소 생활에서 어려움을 겪고 있는 점 등도 부담 없이 상담할 수 있는 교실도 있습니다. </span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="smile"
        actionType="anser"/>
        </div>
        </div>
    </div>

    <div className="links">
    <span className="left">자세한 내용은</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/education?lang=${lang}`}
    linkKey="kyoiku"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>


    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>외국어로 대응해 주는 의료기관을 알려 주세요.</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>후쿠오카 국제 의료 서포트 센터’에서는 외국어로 대응할 수 있는 의료기관과 의료 통역 서비스를 받을 수 있는 의료기관 소개, 후쿠오카현 내 의료기관 안내, 일본 의료 제도 안내, 의사와의 대화를 통역사가 전화로 도와주는 의료기관을 소개해 드립니다. </span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="good"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">자세한 내용은</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/medical?lang=${lang}`}
    linkKey="iryo"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>아이가 취학할 국제학교(international school)가 있을까요?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>후쿠오카현에는 국제적 교육 인정 기관인 ‘국제 바칼로레아 기구’로부터 인정을 받아, 유아기부터 고등학교 과정까지 일관하여 영어로 교육하고 있는 국제학교(international school)가 있습니다. </span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="pointing"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">자세한 내용은</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/education?lang=${lang}`}
    linkKey="kyoiku"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>후쿠오카에서 일자리를 찾고 있습니다. 어디서 소개받을 수 있을까요?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>외국인의 일자리에 관한 상담·소개는 헬로워크 후쿠오카 주오(中央)/외국인 고용 서비스 센터에서 하고 있습니다. </span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="normal"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">자세한 내용은</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/employment?lang=${lang}`}
    linkKey="works"
    ></YellowArrowLink>
    </div>

    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>후쿠오카로 이주하고 싶은데, 지역에 적응할 수 있을지 불안합니다.</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>본 사이트에서는 후쿠오카현에서 활약하는 외국인의 성공 스토리를 동영상을 통해 전하고 있습니다. 후쿠오카에는 동영상에서 소개된 분들처럼 고향에서 멀리 떠나와 지역에 녹아들어 활약하는 많은 외국인들이 있습니다. </span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="smile"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
        <span className="left">자세한 내용은</span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/movie?lang=${lang}`}
        linkKey="fortunehills"
        ></YellowArrowLink>
        </div>
    <span className="right"></span>
    </div>
    </InnerBox>
    </>)
}

export default LangKo