import publicPath from "src/utilty/publicPath"
import styled from "styled-components"




export const ArticleMutiLangTemplate = (
    {lang, children}:React.PropsWithChildren<{lang : string}>) => {
    

    const SMultilingual = styled.div`
        /*====================================================================
       allow-nav (黄色矢印リンク)
       ======================================================================*/
       .allow-nav{
           content: '';
           display: block;
          
           width: 100%;
           aspect-ratio: 10/1.8;
           cursor: pointer;
           font-size: 0;
           transition: transform 0.3s ease;
           &:hover{
               transform: translateX(10px); 
           }
           &.area{ 
               background: url(${publicPath(`images/arrowLinks/area/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.ayumi{ 
               background: url(${publicPath(`images/arrowLinks/ayumi/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.jinko{ 
               background: url(${publicPath(`images/arrowLinks/jinko/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.shuyo_sangyo{ 
               background: url(${publicPath(`images/arrowLinks/shuyo_sangyo/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.symbol{ 
               background: url(${publicPath(`images/arrowLinks/symbol/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.foreign{ 
               background: url(${publicPath(`images/arrowLinks/foreign/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.international{ 
               background: url(${publicPath(`images/arrowLinks/international/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.ryojikan{ 
               background: url(${publicPath(`images/arrowLinks/ryojikan/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.ayumi{ 
               background: url(${publicPath(`images/arrowLinks/ayumi/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.iryo{ 
               background: url(${publicPath(`images/arrowLinks/iryo/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.works{ 
               background: url(${publicPath(`images/arrowLinks/works/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.living{ 
               background: url(${publicPath(`images/arrowLinks/living/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.kyoiku{ 
               background: url(${publicPath(`images/arrowLinks/kyoiku/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.kokusai_soudan{ 
               aspect-ratio: 10/2;
               background: url(${publicPath(`images/arrowLinks/kokusai_soudan/${lang}.png`)}) no-repeat;
               background-size: 90% auto;
               background-position: center;
           }
           &.shukkoku{ 
               background: url(${publicPath(`images/arrowLinks/shukkoku/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.sichoson{ 
               background: url(${publicPath(`images/arrowLinks/sichoson/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.kokusai_kyokai{ 
               background: url(${publicPath(`images/arrowLinks/kokusai_kyokai/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.sport{ 
               background: url(${publicPath(`images/arrowLinks/sport/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
   
           &.sangyo{ 
               background: url(${publicPath(`images/arrowLinks/sangyo/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.kanko{ 
               background: url(${publicPath(`images/arrowLinks/${lang}/kanko.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.kenko{ 
               background: url(${publicPath(`images/arrowLinks/kenko/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.sangyo{ 
               background: url(${publicPath(`images/arrowLinks/sangyo/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.kankyo{ 
               background: url(${publicPath(`images/arrowLinks/${lang}/kankyo.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.fio{ 
               background: url(${publicPath(`images/arrowLinks/fio/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.sitepolicy{ 
               background: url(${publicPath(`images/arrowLinks/sitepolicy/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.links{ 
               background: url(${publicPath(`images/arrowLinks/privacy/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.privacy{ 
               background: url(${publicPath(`images/arrowLinks/privacy/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.faq{ 
               background: url(${publicPath(`images/arrowLinks/privacy/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.linkforsite{ 
               background: url(${publicPath(`images/arrowLinks/privacy/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.accessibility{ 
               background: url(${publicPath(`images/arrowLinks/privacy/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.fortunehills{
                background: url(${publicPath(`images/arrowLinks/fortunehills/${lang}.png`)}) no-repeat;
               background-size: 90% auto;
               background-position: center;
           }
           &.more{ 
               background: url(${publicPath(`images/arrowLinks/more/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
           &.check{ 
               background: url(${publicPath(`images/arrowLinks/check/${lang}.png`)}) no-repeat;
               background-size: 70% auto;
               background-position: center;
           }
       }
   `

return (
    <SMultilingual>
        {children}
    </SMultilingual>
)

}

