import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlue, FukidashiBlueBottom } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { ChallengerAction, NavigatorAction } from "@/components/Molecules/CharactorAction"


const LangEn = () => {
    const lang = 'en'
    return (<>
    <InnerBox left={0} right={0} bottom={60}>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>I do not speak Japanese. Is there a multilingual consultation service?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>The Fukuoka Consultation Center for Foreign Residents offers consultation services in 22 languages, including Japanese. In addition, each municipality has established multilingual consultation offices and international associations to provide language support, legal advice, and support for all aspects of daily life. </span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="normal"
        actionType="anser"/>
        </div>
        </div>
    </div>

    <div className="links">
    <span className="left">For more information,</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/consultation/international?lang=${lang}`}
    linkKey="kokusai_soudan"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
    <div className="question">
    <div className="charactor">
    <ChallengerAction
    dir="left"
    charactorType="puzzled"
    actionType="question"/>
    </div>
    <div className="fukidashi">
    <FukidashiBlue dir="left">
    <span>I would like to bring my family, but they don't speak Japanese, so can you tell me where they can learn Japanese?</span>
    </FukidashiBlue>
    </div>
    </div>

    <div className="anser-block">
    <div className="fukidashi">
    <FukidashiBlueBottom dir="right">
    <span>There are 100 Japanese language classes held by the government and volunteers in Fukuoka. Some of these classes are also a good place to consult with people who are having difficulties in their daily lives.</span>
    </FukidashiBlueBottom>
    </div>
    <div className="charactor">
    <NavigatorAction
    dir="right"
    charactorType="smile"
    actionType="anser"/>
    </div>
    </div>
    </div>

    <div className="links">
    <span className="left">For more information,</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/education?lang=${lang}`}
    linkKey="kyoiku"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>


    <hr/>
    <div className="pc-flex col-2">
    <div className="question">
    <div className="charactor">
    <ChallengerAction
    dir="left"
    charactorType="thinking"
    actionType="question"/>
    </div>
    <div className="fukidashi">
    <FukidashiBlue dir="left">
    <span> Please let me know which medical institutions provide services in foreign languages.</span>
    </FukidashiBlue>
    </div>
    </div>

    <div className="anser-block">
    <div className="fukidashi">
    <FukidashiBlueBottom dir="right">
    <span>The Fukuoka International Medical Support Center provides information on medical institutions that can provide services in foreign languages and medical interpretation services, information on medical institutions in Fukuoka Prefecture, information on the Japanese medical system, and medical institutions where an interpreter can assist you in talking with a doctor over the phone.</span>
    </FukidashiBlueBottom>
    </div>
    <div className="charactor">
    <NavigatorAction
    dir="right"
    charactorType="good"
    actionType="anser"/>
    </div>
    </div>
    </div>
    <div className="links">
    <span className="left">For more information,</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/medical?lang=${lang}`}
    linkKey="iryo"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>Are there any international schools for my child to attend?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>Fukuoka has international schools that are accredited by the International Baccalaureate Organization, an international educational accreditation organization, and provide consistent education in English from early childhood through high school.</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="pointing"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">For more information,</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/education?lang=${lang}`}
    linkKey="kyoiku"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>I am looking for a job in Fukuoka. Where can you introduce me?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>Consultation and referrals regarding work for foreigners are available at Hello Work Fukuoka Chuo/Fukuoka Employment Service Center for Foreigners.</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="normal"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">For more information,</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/employment?lang=${lang}`}
    linkKey="works"
    ></YellowArrowLink>
    </div>

    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span> I would like to move to Fukuoka, but I am not sure if I will fit in with the local community.</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>This site provides video clips of success stories of foreigners who are active in Fukuoka. There are many foreigners in Fukuoka who have left their hometowns and become active members of the local community, just like the people featured in the video.</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="smile"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">For more information,</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/movie?lang=${lang}`}
    linkKey="fortunehills"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>
    </InnerBox>
    </>)
}

export default LangEn