import publicPath from "src/utilty/publicPath"
import styled from "styled-components"

const SCommingSoon = styled.div`
    min-height: 100vh;
    display: grid;
    place-items: center;
    figure{
        margin-bottom: 20px;
        img{
            width: 70%;
        }
    }
    font-size: 3.0rem;
    letter-spacing: 0.1;
    line-height: 3.6rem;
    font-weight: bold;
    text-align: center;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        min-height: 300px;
    }
`

const CommingSoon = ({children}) => {


    return (
        <SCommingSoon>
            <div>
            <figure>
                <img src={publicPath('images/coomingsoon.png')}
                    className="img-fit"/>
            </figure>
            {children}
            </div>
        </SCommingSoon>
    )
}

export default CommingSoon