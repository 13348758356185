import CommingSoon from "@/components/Atoms/CommingSoon";
import Article from "@/components/Organisms/Article";
import { langType } from "src/types/lang";
import { setTitleDiscrption } from "src/utilty/setTitleDiscription";
import styled from "styled-components";


const SKnowledge = styled.div`
`

const KnowlegePage = ({lang}:{lang:langType}) => {
    setTitleDiscrption({
        layer:'support',
        lang:lang,
        subLayer: 'knowledge'
    })

    let msg 
    if(lang == 'ja'){
        msg = `ただいま\n福岡での生活の不安や、\n疑問を解消して\n皆さまをサポートする\nページを準備中です。\nお楽しみに！`
    }else if(lang == 'en'){
        msg = `We are currently preparing a page to support you by answering any questions or concerns you may have about life in Fukuoka. \nPlease look forward to it!`
    }else if(lang == 'zh_tw'){
        msg = `為了幫助大家解決在福岡生活中遇到的各種問題，\n我們正在製作為大家提供支援的網頁。\n 敬請期待！`
    }else if(lang == 'zh_cn'){
        msg = `目前正在准备一个页面，\n旨在解答您在福冈生活中的不安和疑问，\n为您提供支持。\n敬请期待！`
    }else if(lang == 'ko'){
        msg = `현재 후쿠오카 생활의 불안이나 의문을 해소하고 여러분을 서포트하는 페이지를 준비 중입니다. \n기대해 주십시오!`
    }else if(lang == 'vi'){
        msg = `Hiện Trang hỗ trợ quý vị giải quyết bất an, thắc mắc trong cuộc sống đang được chuẩn bị. \nHãy đón xem!`
    }else if(lang == 'ne'){
        msg = `हाल फुकुओकामा बसोबास गर्ने बारेको चिन्ता र प्रश्नहरूको समाधान गरेर तपाईंलाई सहयोग गर्न पृष्ठ तयार गर्दैछौं। प्रतीक्षा गर्नुहोला！`
    }else if(lang == 'th'){
        msg = `ขณะนี้เรากำลังเตรียมโฮมเพจสำหรับให้ความช่วยเหลือทุกท่าน ไม่ว่าจะเป็นการขจัดความกังวลเกี่ยวกับการใช้ชีวิตในฟุกุโอกะหรือไขข้อสงสัยในเรื่องต่างๆ รอสักหน่อยนะ!`
    }else{
        msg = ''
    }

    return (
        <SKnowledge>
            <Article className="pc-noTopMargin">
            <CommingSoon>
                {msg}
            </CommingSoon>
            </Article>
        </SKnowledge>
    )
}

export default KnowlegePage