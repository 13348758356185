import { langType } from "src/types/lang"
import Data from 'src/json/headLine.json';
import publicPath from "src/utilty/publicPath";


interface HeadLineProps {
    lang : langType
    nameKey : string
}

const HeadLine = ({
    lang,
    nameKey,
    ...props
}:HeadLineProps) => {

    const data = Data.data[nameKey]
    return (
        <img 
            src={publicPath(`images/headline/${nameKey}.png`)}
            alt={data[lang]}
            className="img-fit"
        />   
    )
}

export default  HeadLine