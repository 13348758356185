import { PropsWithChildren } from "react";
import { Link, useNavigate } from "react-router-dom";
import { langType } from "src/types/lang";
import styled from "styled-components";


interface LangLinkProps {
    href: string,
    lang : langType 
}


export const SAnker = styled.a`
        display: inline-block;
        padding:6px 10px;
        width: 100%;
        text-align: center;
        background: rgba(255,255,255,0.9);
        border-radius: 20px;
        border: solid 1px #c3bfbf;
        cursor: pointer;
        &:hover{
            background: rgba(255,255,255,1);
        }
    `
export const LangLink = ({
    children,
    href,
    lang,
    ...props
}: PropsWithChildren<LangLinkProps> ) => {

    

    const  langUrl = () =>{
        const currentUrl = new URL(window.location.href);
        const searchParams = currentUrl.searchParams;

        if(searchParams.has('lang')) searchParams.delete('lang');
        if(searchParams.has('tsutaeru')) searchParams.delete('tsutaeru');
        
        let setLang:string = lang
        if(lang === 'easy_ja')　setLang = 'ja&tsutaeru=on'


        // 'lang'パラメータが存在しない場合、'id'パラメータの有無に基づいて適切な形式で追加
        const paramToAdd = searchParams.has('id') || searchParams.has('current') ? `&lang=${setLang}` : `?lang=${setLang}`;
        // 新しいURLを生成
        currentUrl.search += paramToAdd;

        return currentUrl.toString()
    }

    const onClick=  () => {
        window.offTsutaeru() //伝わるwebのリセット。
        const redirect = langUrl()
        console.log(redirect)
        window.location.href = redirect;
    }

    return (
        <SAnker onClick={onClick}>
            {children}
        </SAnker>
    )
}