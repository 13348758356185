
import InnerBox from "@/components/Atoms/Box/innerBox"
import PageTitleSupport from "@/components/Molecules/PageTitle/support"
import FioTemplate from "@/components/Templates/fio"
import SubLayerTemplate from "@/components/Templates/subLayer"
import publicPath from "src/utilty/publicPath"

const LangJa = () => {

    return (
        <>
        <InnerBox bottom={10}>
            <h1>
                福岡で暮らす外国人のための、お役立ちWEBサイト集です。
            </h1>
        </InnerBox>
        <InnerBox left={40} right={0}>
            <ul className="linkslist pc-flex col-2">
                <li>
                    <h4>●福岡県庁のホームページ</h4>
                    <a href="https://www.pref.fukuoka.lg.jp" target="_blank">
                        <figure>
                        <img src={publicPath('images/banner/fukuokaken.png')} alt="福岡県庁のホームページ" className="img-fit"/>
                        </figure>
                    </a>
                </li>
                <li>
                    <h4>●外国人向け日本の情報</h4>
                    <a href="https://tsuru81.jp/" target="_blank">
                        <figure>
                        <figcaption>Website「つる」</figcaption>
                        <img src={publicPath('images/banner/tsuru81.jpg')} alt="Website「つる」" className="img-fit"/>
                        </figure>
                    </a>
                </li>
                <li>
                    <h4>●観光・食に関する情報</h4>
                    <a href="https://www.crossroadfukuoka.jp/" target="_blank">
                        <figure>
                            <figcaption>VISIT FUKUOKA（クロスロードふくおか）</figcaption>
                            <img src={publicPath('images/banner/visit_fukuoka.png')} alt="VISIT FUKUOKA（クロスロードふくおか）" className="img-fit"/>
                        </figure>
                    </a>
                    <a href="https://www.welcomekyushu.com/" target="_blank">
                        <figure>
                            <figcaption>Visit Kyushu（九州旅ネット）</figcaption>
                            <img src={publicPath('images/banner/tabinet.png')} alt="Visit Kyushu（九州旅ネット）" className="img-fit"/>
                        </figure>
                    </a>
                </li>
                <li>
                    <h4>●医療に関する情報</h4>
                    <a href="https://imsc.pref.fukuoka.lg.jp/" target="_blank">
                        <figure>
                        <figcaption>ふくおか国際医療サポートセンター</figcaption>
                        <img src={publicPath('images/banner/supportcenter.png')} alt="ふくおか国際医療サポートセンター" className="img-fit"/>
                        </figure>
                    </a>
                </li>
                <li>
                    <h4>●ワンヘルスに関する情報</h4>
                    <a href="https://onehealth.pref.fukuoka.lg.jp/" target="_blank">
                        <figure>
                        <figcaption>福岡県ワンヘルス推進ポータルサイト</figcaption>
                        <img src={publicPath('images/banner/onehealth.png')} alt="福岡県ワンヘルス推進ポータルサイト" className="img-fit"/>
                        </figure>
                    </a>
                </li>
                <li>
                    <h4>●国際金融に関する情報</h4>
                    <a href="https://financial-hub-fukuoka.com/" target="_blank">
                        <figure>
                            <figcaption>Financial Hub FUKUOKA</figcaption>
                        <img src={publicPath('images/banner/financialhaub.png')} alt="Financial Hub FUKUOKA" className="img-fit"/>
                        </figure>
                    </a>
                </li>
                <li>
                    <h4>●企業誘致に関する情報</h4>
                    <a href="https://www.kigyorichi.pref.fukuoka.lg.jp" target="_blank">
                        <figure>
                            <figcaption>INVEST FUKUOKA</figcaption>
                            <img src={publicPath('images/banner/investfukuoka.png')} alt="INVEST FUKUOKA" className="img-fit"/>
                        </figure>
                    </a>
                </li>
                <li>
                    <h4>●国際交流に関する情報</h4>
                    <a href="https://kokusaihiroba.or.jp/" target="_blank">
                        <figure>
                        <figcaption>FIEF（福岡県国際交流センター）</figcaption>
                        <img src={publicPath('images/banner/fief.png')} alt="FIEF（福岡県国際交流センター）" className="img-fit"/>
                        </figure>
                    </a>
                </li>
                <li>
                    <h4>●留学生に関する情報</h4>
                    <a href="https://www.fissc.net" target="_blank">
                        <figure>
                            <figcaption>FISSC（留学生サポートセンター）</figcaption>
                        <img src={publicPath('images/banner/fissc.png')} alt="FISSC（留学生サポートセンター）" className="img-fit"/>
                        </figure>
                    </a>
                </li>
                <li>
                    <h4>●就職に関する情報</h4>
                    <a href="https://kghrpc.org" target="_blank">
                        <figure>
                            <figcaption>Work in Kyushu</figcaption>
                            <img src={publicPath('images/banner/workinkyushu.png')} alt="Work in Kyushu" className="img-fit"/>
                        </figure>
                    </a>
                </li>
                <li>
                    <h4>●スポーツに関する情報</h4>
                    <a href="https://www.tourdekyushu.asia/" target="_blank">
                        <figure>
                            <figcaption>マイナビ　ツール・ド・九州　2024</figcaption>
                            <img src={publicPath('images/banner/tourkyushu2024.png')} alt="マイナビ　ツール・ド・九州　2024" className="img-fit"/>
                        </figure>
                    </a>
                </li>
            </ul>
        </InnerBox>
        </>
    )
}

export default LangJa