


const LangKo = () => {

    return (
        <div className='article-inner'>
       <section>
        <h1>규규슈 로고 마크란</h1>
        <ul className="photos">
            <li>
                <img src="./images/kyushulogomark/logomark01.jpg" alt="The Kyushu Logo"/>
            </li>
            <li>
                <img src="./images/kyushulogomark/logomark02.jpg" alt="The Kyushu Logo ※のれん"/>
            </li>
        </ul>
        <p>
        이 마크는 규슈를 전국 그리고 세계에 알리기 위해 사용됩니다. 규슈 로고 마크는 규슈의 노렌을 통해 사람들을 맞이하고자 노렌※을 모티브로 디자인했습니다. '九', '州', '一'이라는 한자로 구성되어 규슈의 여러 지역이 하나로 연계되는 모습을 나타내고 있습니다.<br/>
        ※노렌<br/>
        노렌이란 가게 입구에 걸어놓는 햇빛 가림막을 말합니다. 일본의 독자적인 문화이며 가게의 이름이나 상표를 표시하는 경우가 많습니다.
        </p>
        <br/>
        </section>
        <section>
        <h1>규슈란</h1>
        <figure>
        <img src="./images/kyushulogomark/kyushu.jpg" alt="九州について"/>
        </figure>
        <p>일본의 가장 서쪽 끝에 위치한 규슈는 산과 바다의 웅장하고 아름다운 자연, 온천, 역사, 문화, 맛집 등 일본의 매력을 만끽할 수 있는 곳입니다. 자세한 내용은 영상을 보시기 바랍니다.</p>
       
        
        <br/>
        <div className="youtube">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/5GNHVfoQd0s?si=JCxXRHx1bwIt-3EO" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        </section>
        <section>
            <h1>해외에서의 규슈 로고 마크 활용 사례</h1>
            <p>규슈 로고 마크는 해외에서도 활용되고 있습니다.</p>
            <p><strong>◎BOKKSU 주식회사</strong></p>
            <p>'규슈 과자 박스' 문화 가이드에 게재</p>
            <figure>
           <img src="./images/kyushulogomark/logomark-bkksu.jpg" alt="BOKKUS"/>
            </figure>
            <p className="note">규슈의 과자를 모아놓은 '규슈 과자 BOX'에 과자에 대한 설명, 규슈의 역사 · 문화 · 관광지를 게재한 문화 가이드 동봉</p>

            <p><strong>◎주식회사 마루타이</strong></p>
            <p>막대 라면 지역 시리즈' (총 8개 제품) 패키지 뒷면에 게재</p>
            <figure>
           <img src="./images/kyushulogomark/logomark-marutai.jpg" alt="Marutai"/>
            </figure>
            <p className="note">하카타 돈코쓰 라멘, 구마모토 흑마늘 기름 돈코쓰 라멘, 가고시마 흑돼지 돈코쓰 라멘</p>

            <p><strong>◎주식회사 다이쇼</strong></p>
            <p>'참마 철판구이 키트' 패키지 뒷면에 게재</p>
            <figure>
            <img src="./images/kyushulogomark/yamaimoteppan.jpg" alt="山芋鉄板焼きの素"/>
            </figure>
        </section>

        <div className="deco-fukidashi navigatorCheck right smile">
        규슈의 자세한 관광정보는 'Visit Kyushu'를 참조해 주시기 바랍니다.
        </div>
       
        <p>Visit Kyushu</p>
        <a href="https://www.welcomekyushu.com/" target="_blank">
            <figure>
            <img src='./images/banner/tabinet.png' alt="Visit Kyushu（九州旅ネット）" className="img-fit"/>
            </figure>
        </a>
        </div>
    )
}

export default LangKo;