import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorGood, NavigatorPickUp } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"

const LangNe = () => {

    return (<>
    <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="normal" dir="right" actionType="pickup" actionColor="blue"/>
        </div>
        <BlackNote>
        <p className="large">फुकुओका प्रान्तको जीडीपी $१७८,०२७ मिलियन प्रान्तको प्रतिव्यक्ति आय : $ २४,७९०</p>
        <p className="xl">

        </p>
        <p className="middle txt-yellow">
        <br/>

        </p>
        <p className="al-c"> (अप्रिल २०२२ को अनुसार)"</p>
        <span className="txt-small">
        सामग्री प्रिफेक्चरल अनुसन्धान र तथ्याङ्क <br/>
        विभाग "FY2020 प्रिफेक्चरल इकोनोमिक/नगरपालिका आर्थिक लेखा प्रतिवेदन" प्रति डलर 106.09 येन मा रूपान्तरित (2020 औसत) <br/>
        <br/>

        </span>
        </BlackNote>
    </div>
    <br/>
    <div className="navpicup sp-only">
        <NavigatorPickUp dir="right"></NavigatorPickUp>
    </div>
    <InnerBox top={20}>
    <EmphasisNote>
    <h2>कृषि </h2>
    <p>
    फुकुओका प्रिफेक्चरको खेती गरिएको भूमि क्षेत्र ७८,९00 हेक्टर (२०२२ ),यसले प्रिफेक्चरल भूमिको १६ % ओगटेको छ ।　यस क्षेत्रको विशिष्ट विशेषता भनेको धान खेतीको अनुपात ८१% (राष्ट्रिय औसत ५४%) मा उच्च रहेको छ।
    <br/>
    </p>
    <h2>जंगल बन फुकुओका </h2>
    <p>
    प्रिफेक्चरको वन क्षेत्र लगभग २२४,००० हेक्टर छ, जुन प्रीफेक्चरको भूमि क्षेत्रको ४५% ओगटेको छ।सार्वजनिक कार्यहरू छन् जस्तै पानीका स्रोतहरू रिचार्ज गर्ने र तलछटको बहावलाई रोक्न, र प्रीफेक्चरल बासिन्दाहरूको सुरक्षा र सुरक्षा सुनिश्चित गर्न महत्त्वपूर्ण भूमिका खेल्छ।
    <br/>
    </p>
    <h2>माछापालन उद्योग</h2>
    <p>
    फुकुओका प्रिफेक्चरमा, चिकुजेन सागर, आरीयाके सागर, बुजेन सागर र विभिन्न विशेषताहरू भएका नदी र तालहरू जस्ता अन्तरदेशीय पानीहरूमा विभिन्न प्रकारका माछा मार्ने र जलचर गतिविधिहरू गरिन्छ। रेड सी ब्रीम र नोरी सहित विभिन्न प्रकारका समुद्री<br/>
    उत्पादनहरू अवतरण गरिन्छ, जुन देशको उत्कृष्ट उत्पादकहरू मध्ये एक हो।
    <br/>
    </p>
    <h2>"वाणिज्य र उद्योग </h2>
    <p>
    फुकुओका प्रिफेक्चरको उद्योगले मुख्यतया आधारभूत भौतिक उद्योगहरू जस्तै स्टिल, कोइला, र रसायनहरू, र जीवन शैली-सम्बन्धित उद्योगहरू जस्तै खाद्य पदार्थहरूमा विकास गरेको छ, तर हालका वर्षहरूमा, प्रशोधन र एसेम्बली उद्योगहरू जस्तै अटोमोबाइल-सम्बन्धित उद्योगहरूको पनी चाप बढेको छ, बढ्दै गएको छ।<br/>
    "यसबाहेक, खाद्य उत्पादन उद्योग प्रचुर मात्रामा, उच्च गुणस्तरको कृषि, वन र माछापालन उत्पादनहरूको उपलब्धता, साथै ठूलो उपभोग क्षेत्र र अनुकूल वितरण वातावरणको निकटताका कारण थप केन्द्रित हुँदै गइरहेको छ।
    </p>
    </EmphasisNote>
    </InnerBox>
    <InnerBox top={20}>

  
    <h1>
    Growth Industry<br/>
    फुकुओकाको भविष्यलाई सहयोग गर्ने वृद्धि उद्योग
    </h1>
    <p>
    फुकुओकामा धेरै विश्वविद्यालयहरू तथा उन्नत अनुसन्धान संस्थानहरू、उत्कृष्ट प्रविधि भएका निर्माण उद्योगहरू छन्।<br/>
    प्रान्तले、बजार विस्तार हुने अपेक्षा गरि एस्ता खालका बढ्दो उद्योगहरूमा प्रवेश गर्ने कम्पनीहरू・एकाग्रता प्रवर्द्धनमा ध्यान केन्द्रित छ।
    </p>
    <EmphasisNote>
    <h2>1. जैविक उद्योग</h2>
    <p className="txt-middle">
    फुकुओकालाई अत्याधुनिक जैविक समुदायको रूपमा प्रमाणित गरिएको छ、उन्नत बायोटेक्नोलोजी हब बन्नका लागि प्रयासहरू तीव्र छ
    </p>
    <p>
    फुकुओका प्रान्तले २००१ साल देखि कुरुमे सिटी संगको सहकार्यमा、उद्योग, शिक्षा र सरकार सम्मिलित「फुकुओका प्रिफेक्चर बायो उद्योग आधार प्रवर्द्धन परिषद」मा आधारित、बायो-सम्बन्धित कम्पनीहरू भेला गर्ने लक्ष्य राखेको छ।<br/>
    प्रवर्द्धन परिषद्को प्रयासको फलस्वरूप、बायोटेक्नोलोजीसँग सम्बन्धित कम्पनीको संख्या पहिलेको ३२ बाट बढेर २ सय ३० पुगेको छ ।
    फुकुओकामा、नयाँ पुस्ताको औषधिको रूपमा न्यूक्लिक एसिड औषधि प्रयोग गरेर COVID-१९ को चिकित्सीय औषधि विकासको लागि ध्यान आकर्षित बोनाक कम्पनी लिमिटेड तथा、जापान भरीनै जीनोम सम्पादन प्रविधि मा शक्ति भएको येदित्तो फोसु कम्पनी लिमिटेड 、जापान भित्र तथा बाहिर बाट पनि ध्यान प्राप्त बायोभेन्चर्सहरू एकपछि अर्को जन्मिँदैछन्।<br/>
    यस प्रकारका फुकुओका प्रान्तको लाभहरूलाइ उच्च मूल्याङ्कन गरि、जुन २०२१ मा क्याबिनेट कार्यालयबाट पश्चिमी जापानमा एक मात्र「स्थानीय जैविक समुदाय」（देशव्यापी 4 स्थानहरू）को रूपमा प्रमाणित गर्‍यो।
    </p>
    <h2>2. अन्तरिक्ष उद्योग</h2>
    <p className="txt-middle">
    क्युसुको अन्तरिक्ष व्यापार सृजनलाई प्रवर्द्धन गर्ने पहिलो स्थानीय सरकारको रूपमा फुकुओका प्रान्तलाइ चयन गरिएको छ। फुकुओकाबाट हार्डवेयर・सफ्टवेयर प्रविधिको साथ अन्तरिक्षको चुनौती लिँदै
    </p>
    <p>
    फुकुओका प्रान्तमा、उच्च प्रविधि संग उत्पादन उद्योग तथा、 अद्वितीय नयाँ सेवाहरू सिर्जना गर्ने IT उद्यमहरू आदि、अन्तरिक्ष व्यवसायमा सक्रिय रहने सम्भावना भइ लुकेका कम्पनीहरुको एकाग्रता गरिरहेको छ ।<br/>
    iQPS कम्पनी लिमिटेड（फुकुओका सिटी）ले、दिन वा रात वा मौसमको पर्वाह नगरी पृथ्वीको अवलोकन गर्न सक्ने विश्वको उच्चतम स्तरको सानो SAR उपग्रह सफलतापूर्वक विकास र प्रक्षेपण गरेको छ। मे २०२१ मा विश्वको उच्च स्तरको हाई-रिजोल्युसन तस्बिरहरू पनि खिच्न सफल भएको छ।。iQPS ले、 २०२५ साल पछि ३६ उपग्रह तारामंडलको साथ प्रत्येक १० मिनेटमा पृथ्वीको सतह अवलोकन गर्न सक्षम हुने प्रणालीलाई विस्तार गर्ने लक्ष्य राखेको छ।<br/>
    सेप्टेम्बर २०२० मा、क्याबिनेट कार्यालयले क्युशुमा अन्तरिक्ष व्यापार सृजनलाई प्रवर्द्धन गर्ने पहिलो स्थानीय सरकारको रूपमा、फुकुओका प्रान्तलाई चयन गर्‍यो।
    </p>
    <h2>3. ब्लकचेन</h2>
    <p className="txt-middle">
    जापानको अत्याधुनिक मानव संसाधन र प्रविधिलाई एकै ठाउँमा ल्याउँदै、जापानको प्रमुख ब्लकचेन हब स्थापना गर्दै
    </p>
    <p>
    क्रिप्टो सम्पत्तिहरू व्यवस्थापन गर्न प्रविधिको रूपमा विकसित गरिएको ब्लकचेन、यसको ट्रेसेबिलिटी, डाटा टेम्परिङ रोक्न सक्ने क्षमता、खराबीहरूको प्रतिरोधले ध्यान आकर्षित गरिरहेको छ।<br/>
    फुकुओका प्रान्तमा、धेरै ब्लकचेन स्टार्टअपहरू तथा उच्च दक्ष इन्जिनियरहरू हुनुका साथै、ब्लकचेन क्षेत्रमा अग्रणी अनुसन्धान गर्दै、उत्कृष्ट इन्जिनियरहरू उत्पादन गर्ने क्युसु इन्स्टिच्युट अफ टेक्नोलोजी कम्प्युटर विज्ञान र ईन्जिनियरिङ् संकाय तथा किन्डाई विश्वविद्यालय औद्योगिक विज्ञान र ईन्जिनियरिङ् संकाय छ।<br/>
    यस प्रकारको आईटी उद्योगलाई प्रवर्द्धन गर्ने यी फाइदाहरूको फाइदा उठाउन、फुकुओका प्रान्तले २०२० साल देखि、ब्लकचेन प्रविधि र मानव संसाधन विकासको अनुसन्धान र विकासको लागि पूर्ण-सहायता सुरु गरेको छ, र जापानमा ब्लकचेनको लागि प्रमुख हब बनिरहेको छ।
    </p>
    <h2>4. ऊर्जा उद्योग</h2>
    <p className="txt-middle">
    हाइड्रोजन ऊर्जा उद्योगको विकास गरी、कम्पनीहरूलाई आकर्षित गर्ने लक्ष्य राखेर、अपतटीय वायु ऊर्जा उत्पादन गरेको छ।
    </p>
    <p>
    जापानले、२०५० साल सम्ममा कार्बन न्यूट्रल हासिल गर्ने लक्ष्यका साथ、नवीकरणीय उर्जालाई मुख्य बिजुलीको स्रोत बनाउन प्रयासरत छ।नवीकरणीय ऊर्जा स्रोतहरू मध्ये पनी अपतटीय वायु उर्जा उत्पादन、चारैतिरबाट समुद्रले घेरिएको जापानको लागि एक आदर्श विकल्प हुने अपेक्षा गरिएको छ।<br/>
    फुकुओका प्रान्तले यसकासाथै、अपतटीय बायु उर्जा उत्पादनको परिचय प्रवर्द्धन गर्दै र、वायु शक्ति ऊर्जा उत्पादन कम्पनीहरूलाई आकर्षित गर्न、उद्योग, शिक्षा, र सरकार संग सहकार्य गरेको छ।थप रूपमा प्रान्तले、वातावरणमैत्री हाइड्रोजन ऊर्जा कम्पनिको प्राप्ति तर्फ、उद्योग, शिक्षाविद् र सरकार बीचको सहकार्य गरी「फुकुओका प्रान्त हाइड्रोजन ऊर्जा रणनीति परिषद 」स्थापना गरेको छ।<br/>
    हाइड्रोजन उत्पादन・भण्डारण・ढुवानी देखि प्रयोग सम्मको विस्तृत अनुसन्धान र विकास、उत्पादन विकास、मानव संसाधन विकासको अतिरिक्त、हाइड्रोजन ऊर्जा कम्पनीहरू विकास गर्दै・सञ्चय गर्ने काम पनि गरिरहेका छौं ।
    </p>
    <h2>5. अन्य</h2>
    <p className="txt-middle">
    फुकुओका र क्युशुका प्रमुख विकास उद्योगहरू
    </p>
    <p>
    क्युसुमा、वातावरण、कृषि, वन तथा मत्स्यपालन、अटोमोबाइल उद्योगहरू सफल भएका छन्、विकास उद्योगहरू मानिन्छन्।<br/>
    ●वातावरणीय उद्योग<br/>
    प्लाष्टिकका बोतलहरू तथा घरेलु उपकरणहरू रिसाइक्लिंगको अतिरिक्त、 लिथियम-आयन ब्याट्रीहरू र अन्य उत्पादनहरूमा प्रयोग हुने दुर्लभ धातुहरू पुन: प्रयोग गर्ने जस्ता सीमित स्रोतहरू खेर जान नदिने कुरा सुनिश्चित गर्न सक्रिय रूपमा काम गरिरहेका छौं।<br/>
    ●कृषि वन तथा मत्स्यपालन<br/>
    क्युसुमा कृषि वन तथा मत्स्यपालन फस्टाउँदै गएको छ、जसले देशमा उत्पादन हुने कृषि र मत्स्य उत्पादनको २०% भाग ओगटेको छ।
    विशेष गरी फुकुओकामा、आमाओउ स्ट्रबेरी र यामे चिया आदि、 स्वदेशी र अन्तर्राष्ट्रिय रूपमा उच्च प्रशंसा भएका कृषि र समुद्री उत्पादनहरू उत्पादन गरिन्छ।<br/>
    ●अटोमोटिभ उद्योग<br/>
    प्रमुख अटोमोबाइल उत्पादकहरू निस्सान、टोयोटा、दाइहात्सुको उत्तरी क्युशुमा उत्पादन आधारहरू छन्、तिनीहरूको अटोमोबाइल उत्पादनले घरेलु बजारको १५ % भन्दा बढी हिस्सा ओगटेको छ।
    </p>
    </EmphasisNote>
    <br/>
    <div className="sp-only">
        <NavigatorGood dir="right"></NavigatorGood>
    </div>
    </InnerBox>
    </>)
}

export default LangNe