import { NavigatorFullLength } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangZhTw = () => {

    return (<>
    <div className="navigatorPositon sp-only">
        <NavigatorFullLength></NavigatorFullLength>
    </div>
    <div className="pc-flex col-2">
        <div>
            <h1>
            縣徽
            </h1>
            <figure>
            <img src={publicPath('images/symbol01.png')} alt="県のシンボル" className="img-fit"/>
            </figure>
            <p>
            縣徽以縣花的梅花的形狀為圖案，象徵縣的發展和縣民的和諧。<br/>
            昭和41年5月１０日，透過公開徵選後所決定的。
            </p>
        </div>
        <div>
            <h1>
            ●福岡縣縣花
            梅花
            </h1>
            <figure>
            <img src={publicPath('images/symbol02.png')} className="img-fit"/>
            </figure>
            <p>
            昭和29年3月19日，“NHK植物之友協會”決定以太宰府的梅樹命名。
            據說太宰府天滿宮供奉學問之神的菅原道真，生涯中非常地喜愛梅樹。
            </p>
        </div>
        <div>
            <h1>
            ●福岡縣鳥鶯
            </h1>
            <figure>
            <img src={publicPath('images/symbol03.png')} className="img-fit"/>
            </figure>
            <p>
            昭和39年7月3日，福岡縣政府透過公開徵選所決定。
            在日本有「Ume ni Uguisu(梅にうぐいす)」這樣的說法。
            這就是比喻事物排列整齊、和諧優美的寓意。
            </p>
        </div>
        <div>
            <h1>
            ●福岡縣樹
            杜鵑花
            </h1>
            <figure>
            <img src={publicPath('images/symbol04.png')} className="img-fit"/>
            </figure>
            <p>
            昭和４１年９月５日“Midori no nippon全国運動(日本全國綠化運動)”期間決定種植杜鵑花。
            杜鵑花在久留米地區作為花園植物和盆景廣泛種植，樹苗也出口到海外。
            </p>
        </div>
    </div>
    
    </>)
}

export default LangZhTw