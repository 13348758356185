import InnerBox from "@/components/Atoms/Box/innerBox"

const LangTh = () => {

    return (
        <InnerBox bottom={20} left={0} right={0}>
        <h1>
        เกี่ยวกับห้องเรียนภาษาญี่ปุ่นในท้องถิ่น<br/>
        มีห้องเรียนภาษาญี่ปุ่นกว่า 100 แห่งที่จัดขึ้นโดยรัฐบาลและอาสาสมัครในจังหวัดฟุกุโอกะ
        </h1>
        <p>
        ห้องเรียนภาษาญี่ปุ่นไม่เพียงแต่เป็นสถานที่เรียนภาษาญี่ปุ่นเท่านั้น แต่ยังเป็นสถานที่พบปะพูดคุยแลกเปลี่ยนกับคนญี่ปุ่นที่อาศัยอยู่ในท้องถิ่นอีกด้วยนอกจากนี้ยังมีห้องเรียนที่คุณสามารถปรึกษาเกี่ยวกับปัญหาที่อาจพบเจอในชีวิตประจำวันได้ กรุณาดูข้อมูลเพิ่มเติมในแผนภาพด้านล่าง<br/>
        นอกจากนี้ ในจังหวัดฟุกุโอกะมีการดำเนินโครงการจัดสรรค์สภาพแวดล้อมเพื่อการเรียนภาษาญี่ปุ่น การช่วยเหลือการจัดตั้งห้องเรียนภาษาญี่ปุ่น และการอบรมบุคลากรจิตอาสา เพื่อเอื้ออำนวยต่อการจัดสร้างสภาพแวดล้อมในการเรียนภาษาญี่ปุ่นของชาวต่างชาติที่อาศัยอยู่ในจังหวัดฟุกุโอกะ
        </p>
        </InnerBox>
    )
}

export default LangTh