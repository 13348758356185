import PageTitleBasciInfo from "@/components/Molecules/PageTitle/basicInfo";
import { useLocation, useParams } from "react-router-dom"
import getLayer from "src/utilty/getLayer";
import BasciInfoHistory from "./history";
import SubLayerTemplate from "@/components/Templates/subLayer";
import BasciInfoPopulation from "./population";
import BasciInfoIndustries from "./industries";
import BasciInfoSymbol from "./symbol";
import BasciInfoForeign from "./foreign";
import BasciInfoInternational from "./international";
import BasciInfoConsulate from "./consulate";
import PageBack from "@/components/Molecules/PageBack";
import BasicInfoAras from "./areas";
import { useEffect, useState } from "react";
import { pathAnalyzer } from "src/utilty/pathAnalyzer";
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio";
import KyushulogomarkPage from "./kyushulogomark";
import { CmsIndex } from "@/components/Organisms/Cms";


const BasicInfoPage = () => {

    const { category } = useParams();

    const lowerCategory = category ? category.toLowerCase() : ''

    const layer = getLayer('basicinfo')
    let layerData

    category && category !== 'index' ? layerData = layer.sublayer[category] :  layerData = layer

    const [ segment, setSegment  ] = useState<string>()
    
    const location = useLocation()
    const path = pathAnalyzer(location)

    useEffect(()=>{
        if(!category || category == 'index'){
            setSegment('index')
        }else{
            setSegment(category)
        }
    },[category])
   

    return (
        <ResponsiveFioTemplate>
            <SubLayerTemplate>
                <PageTitleBasciInfo lang={path.lang}>
                    {layerData[path.lang].title}
                </PageTitleBasciInfo>
                {
                    segment === 'index' &&
                    <CmsIndex></CmsIndex>
                }
                {
                    category === 'history' &&
                    <>
                    <BasciInfoHistory lang={path.lang}></BasciInfoHistory>
                    <PageBack backto={`/?current=2&lang=${path.lang}`}></PageBack>
                    </>
                }
                {
                    category === 'areas' &&
                    <>
                    <BasicInfoAras lang={path.lang}></BasicInfoAras>
                    <PageBack backto={`/?current=2&lang=${path.lang}`}></PageBack>
                    </>
                }
                {
                    category === 'population' &&
                    <>
                    <BasciInfoPopulation lang={path.lang}></BasciInfoPopulation>
                    <PageBack backto={`/?current=2&lang=${path.lang}`}></PageBack>
                    </>
                }
                {
                    category === 'industries' &&
                    <>
                    <BasciInfoIndustries lang={path.lang}></BasciInfoIndustries>
                    <PageBack backto={`/?current=2&lang=${path.lang}`}></PageBack>
                    </>
                }
                {
                    category === 'symbol' &&
                    <>
                    <BasciInfoSymbol lang={path.lang}></BasciInfoSymbol>
                    <PageBack backto={`/?current=2&lang=${path.lang}`}></PageBack>
                    </>
                }
                {
                    category === 'foreign' &&
                    <>
                    <BasciInfoForeign lang={path.lang}></BasciInfoForeign>
                    <PageBack backto={`/?current=2&lang=${path.lang}`}></PageBack>
                    </>
                }
                {
                    category === 'international' &&
                    <>
                    <BasciInfoInternational lang={path.lang}></BasciInfoInternational>
                    </>
                }
                {
                    category === 'consulate' &&
                    <>
                    <BasciInfoConsulate lang={path.lang}></BasciInfoConsulate>
                    <PageBack backto={`/?current=2&lang=${path.lang}`}></PageBack>
                    </>
                }
                {
                    category === 'kyushulogomark' &&
                    <>
                    <KyushulogomarkPage lang={path.lang}></KyushulogomarkPage>
                    <PageBack backto={`/?current=2&lang=${path.lang}`}></PageBack>
                    </>
                }
            </SubLayerTemplate>
            
       </ResponsiveFioTemplate>
    )

}

export default BasicInfoPage