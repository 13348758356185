import { useLocation, useParams } from "react-router-dom"
import getLayer from "src/utilty/getLayer";
import SubLayerTemplate from "@/components/Templates/subLayer";
import PageTitleConsultation from "@/components/Molecules/PageTitle/consultation";
import LifeStyleInternational from "./international";
import MunicPage from "./munic";
import PageBack from "@/components/Molecules/PageBack";
import ImmigrationbureauPage from "./immigrationbureau";
import { useEffect, useState } from "react";
import { pathAnalyzer } from "src/utilty/pathAnalyzer";
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio";



const ConsultationPage = () => {

    const { category } = useParams();

    const layer = getLayer('consultation')
    let layerData
    category && category !== 'index' ? layerData = layer.sublayer[category] :  layerData = layer

    const [ segment, setSegment  ] = useState<string>()

    const location = useLocation()
    const path = pathAnalyzer(location)

    useEffect(()=>{
        if(!category || category == 'index'){
            setSegment('index')
        }else{
            setSegment(category)
        }
    },[category])

    return (
        <ResponsiveFioTemplate>
            <SubLayerTemplate>
            <PageTitleConsultation  lang={path.lang}>
                {layerData[path.lang].title}
            </PageTitleConsultation>
            {
                segment === 'index' &&
                <></>
            }
            {
                segment === 'international' &&
                <LifeStyleInternational lang={path.lang}></LifeStyleInternational>
            }
            {
                segment === 'immigrationbureau' &&
                <ImmigrationbureauPage lang={path.lang}></ImmigrationbureauPage>
            }
            {
                segment === 'municipalitycounter' &&
                <MunicPage lang={path.lang}></MunicPage>
            }
            <PageBack backto="/?current=4"></PageBack>
            </SubLayerTemplate>
        </ResponsiveFioTemplate>
    )
}

export default ConsultationPage;