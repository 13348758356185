import Article from "@/components/Organisms/Article";

const LangJa = () => {

    return (
        <>
        <h1>FUKUOKA IS OPENについて</h1>
        <p>
        福岡県は友好提携地域数、留学生数など、全国で上位にあるとともに、本県在住外国人数は89,518人と過去最高を更新しています。
        </p>
        <p>
        今後、人口減少社会の中で地域の活力を維持していくためには、県内企業の働き手や地域社会の新たな担い手として外国人材に活躍してもらうことが不可欠です。
        </p>
        <p>
        そのような中、さらに多くの方に海外から来ていただくため、「FUKUOKA IS OPEN」というキャッチフレーズのもと、全世界に向けて本県の施策を一体的に発信し、本県が外国人にとって暮らしやすく、活躍できる地域であることを知っていただきたいと考えています。
        </p>
        <p>
        そのため、多言語ポータルサイト「FUKUOKA IS OPEN」（<a href="https://www.fisop.net">https://www.fisop.net</a>）では、本県の教育や医療など外国人向け生活関連情報をはじめ、留学生、インバウンド、国際金融機能の誘致やスポーツ、環境、ワンヘルスなど先進的施策に関する情報、在住外国人の活躍など最新情報を発信します。
        </p>
        <br/>
        <h5 className="title-large">FUKUOKA IS OPENオリジナルロゴマークの使用について</h5>
        <p>本ロゴマークをFUKUOKA IS OPEN推進事業実行委員会の承諾なく使用することは禁止します。<br/>
        使用にあたっては使用要領に従い、使用申請が必要です。<br/>
        使用要領および利用ガイドラインをご確認いただき、「（様式第1号）FUKUOKA IS OPEN推進事業ロゴマーク使用申請書」に必要事項を記入の上、FAXにて提出してください。</p>
        <br/>
        <p>申請内容を確認の上、承諾書およびロゴマークのデータをお渡しします。</p>
        <br/>
        <p>
        【申請書類等】
         </p>
        <ul className="dotto">
            <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOPromotionProjectLogoUsageInstructions.pdf" target="_blank">FUKUOKA IS OPEN推進事業ロゴマーク使用要領</a></span></li>
            <li><span className="icon-doc"><a href="/contents/aboutfio/Form1_FIOPromotionProjectLogoApplicationForm.docx">（様式第１号）FUKUOKA IS OPEN推進事業ロゴマーク使用申請書</a></span></li>
            <li><span className="icon-doc"><a href="/contents/aboutfio/Form4_FIOPromotionProjectLogoApplicationForm.docx">（様式第４号）FUKUOKA IS OPEN推進事業ロゴマーク使用承認変更申請書</a></span></li>
            <li><span className="icon-doc"><a href="/contents/aboutfio/Form5_FIOPromotionProjectLogoUsageStatusReport.docx">（様式第５号）FUKUOKA IS OPEN推進事業ロゴマーク使用状況報告書</a></span></li>
            <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOLOGOUSERGUIDELINE.pdf" target="_blank">[FUKUOKA IS OPEN] LOGO USER GUIDELINE</a></span></li>
        </ul>
        <br/>
        <p>
        【お問い合わせ先】<br/>
        FUKUOKA IS OPEN推進事業実行委員会事務局（福岡県国際局国際政策課外国人材支援係）
        </p>　
        <br/>
        <p>
        TEL：<a href="tel:+81-92-643-3201">+81-92-643-3201</a><br/>
        FAX：<a href="tel:+81-92-643-3224">+81-92-643-3224</a><br/>
        ※8時30分から17時15分（9時00分から17時45分）<br/>
        ただし、土日祝及び１２月２９日から１月３日を除く
        </p>
        </>
    )
}

export default LangJa