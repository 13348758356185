import { usePageContext } from "@/context/PageContext";
import { useContext } from "react";
import PageToTop from "src/utilty/pageToTop"

const PageLoder = () => {

    PageToTop()
    /*
    const page = usePageContext();
    const path = window.location.pathname.substring(1);
    page.setPageLayer(path)
    */

    return false
}

export default PageLoder