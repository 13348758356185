import { NavigatorFullLength } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangNe = () => {

    return (<>
        <div className="navigatorPositon sp-only">
            <NavigatorFullLength></NavigatorFullLength>
        </div>
        <div className="pc-flex col-2">
            <div>
                <h1>
                प्रान्तको प्रतीक
                </h1>
                <figure>
                <img src={publicPath('images/symbol01.png')} alt="県のシンボル" className="img-fit"/>
                </figure>
                <p>
                यो प्रिफेक्चरको फूल, प्लम(आलुबखडा) जस्तै आकारको छ, र यसले प्रान्तको विकास र यसका नागरिकहरूको सद्भावलाई प्रतिनिधित्व गर्दछ।प्रिफेक्चरले मे १०, १९६६ मा सार्वजनिक भर्ती मार्फत निर्णय गरेको थियो।<br/>
                </p>
            </div>
            <div>
                <h1>
                फुकुओका प्रान्तको फुल प्लम(आलुबखडा)
                </h1>
                <figure>
                <img src={publicPath('images/symbol02.png')} className="img-fit"/>
                </figure>
                <p>
                मार्च १९ , १९५४ मा, NHK प्लान्ट बिरुवा साथि एसोसिएसनले दाजाइफु को प्लम(आलुबखडा)को फूलको नामबाट नाम राख्ने निर्णय गर्यो।प्लम(आलुबखडा)लाई दजाइफु तेनमांगु तीर्थमा विद्याको देवताको रूपमा विराजमान भएका मिचिजेन सुगावाराले जीवनभर उहाँलाई असाध्यै माया गरेको बताइन्छ।
                </p>
            </div>
            <div>
                <h1>
                फुकुओका प्रान्तका पक्षी भंगेरो
                </h1>
                <figure>
                <img src={publicPath('images/symbol03.png')} className="img-fit"/>
                </figure>
                <p>
                जुलाई ३ , १९६४ मा, प्रान्तले सार्वजनिक भर्ती मार्फत निर्णय गर्यो। जापानमा ‘उमे नि उगुइसु’ भनाइ छ । यो राम्रोसँग व्यवस्थित र सुन्दर ढंगले मिलाउने चीजहरूको बारेमा दृष्टान्तको अर्थ हो।"
                </p>
            </div>
            <div>
                <h1>
                फुकुओका प्रान्तको रूख अजालिया
                </h1>
                <figure>
                <img src={publicPath('images/symbol04.png')} className="img-fit"/>
                </figure>
                <p>
                यो सेप्टेम्बर ५ , १९६६मा हरित जापान राष्ट्रिय अभियान को समयमा निर्णय गरियो। कुरुमे क्षेत्रमा बगैंचाको बोटबिरुवा र बोन्साईको रूपमा अजालियाको व्यापक रूपमा खेती गरिन्छ, र बिरुवाहरू विदेश निर्यात गरिन्छ।
                </p>
            </div>
        </div>
    </>)
}

export default LangNe