
const LangVi = () => {

    return (<>
    <h1>Về FUKUOKA IS OPEN</h1>
    <p>
    Tỉnh Fukuoka xếp hạng cao trong cả nước về số lượng đối tác hữu nghị, số lượng sinh viên quốc tế…Số lượng người nước ngoài sinh sống trong tỉnh hiện là 89.518 người và luôn biến đổi ở mức cao nhất từ trước đến này.
    </p>
    <p>
    Tới đây, để duy trì sức sống của khu vực trong một xã hội có dân số đang giảm dần thì việc tích cực tiếp nhận nhân lực nước ngoài như nguồn lao động cho các doanh nghiệp trong tỉnh và người đảm đương mới trong xã hội địa phương là điều không thể thiếu được.
    </p>
    <p>
    Trong hoàn cảnh như vậy, để thu hút thêm nhiều người từ nước ngoài, chúng tôi lấy khẩu hiệu "FUKUOKA IS OPEN" để giới thiệu một cách toàn diện các chính sách của tỉnh Fukuoka đến toàn thế giới, để mọi người biết rằng nơi đây là một khu vực thuận lợi để sống và phát triển đối với người nước ngoài.
    </p>
    <p>
    ‑____ Do đó, Cổng thông tin điện tử đa ngôn ngữ "FUKUOKA IS OPEN" (<a href="https://www.fisop.net">https://www.fisop.net</a>) cung cấp thông tin liên quan đến cuộc sống cho người nước ngoài như giáo dục, y tế tại tỉnh cũng như thông tin về các chính sách tiên tiến về sinh viên quốc tế, du lịch Nhật Bản , thu hút chức năng tài chính quốc tế, thể thao, môi trường và one health..., thông tin về các hoạt động của người nước ngoài sống tại đây.
    </p>
    <br/>
    <h5 className="title-large">Về việc sử dụng dấu logo gốc FUKUOKA IS OPEN</h5>
    <p>Nghiêm cấm sử dụng dấu logo này khi chưa có sự đồng ý của Ủy ban thực hiện Dự án Xúc tiến FUKUOKA IS OPEN.<br/>
    Để sử dụng dấu logo này, cần phải đăng ký sử dụng dựa theo Hướng dẫn sử dụng.<br/>
    Vui lòng kiểm tra Hướng dẫn sử dụng và Nguyên tắc sử dụng, điền các thông tin cần thiết vào "(Mẫu số 1) Đơn đăng ký sử dụng dấu logo của Dự án xúc tiến FUKUOKA IS OPEN'' rồi gửi đi bằng Fax.</p>
    <br/>
    <p>Sau khi xác nhận nội dung đăng ký, chúng tôi sẽ cấp Giấy đồng ý và Dữ liệu về dấu logo.</p>
    <br/>
    <p>
    [Hồ sơ xin sử dụng]
    </p>
    <ul className="dotto">
    <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOPromotionProjectLogoUsageInstructions.pdf" target="_blank">Hướng dẫn sử dụng dấu logo của Dự án xúc tiến FUKUOKA IS OPEN</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form1_FIOPromotionProjectLogoApplicationForm.docx">(Mẫu số 1) Đơn đăng ký sử dụng dấu logo của Dự án xúc tiến FUKUOKA IS OPEN</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form4_FIOPromotionProjectLogoApplicationForm.docx">(Mẫu số 4) Đơn xin thay đổi phê duyệt sử dụng dấu logo của Dự án xúc tiến FUKUOKA IS OPEN</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form5_FIOPromotionProjectLogoUsageStatusReport.docx">(Mẫu số 5) Báo cáo tình trạng sử dụng dấu logo của Dự án xúc tiến FUKUOKA IS OPEN </a></span></li>
    <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOLOGOUSERGUIDELINE.pdf" target="_blank">[FUKUOKA IS OPEN] LOGO USER GUIDELINE</a></span></li>
    </ul>
    <br/>
    <p>
    [Thông tin liên hệ]<br/>
    Ban thư ký Ủy ban thực hiện Dự án Xúc tiến FUKUOKA IS OPEN (Tổ hỗ trợ nhân sự nước ngoài - Phòng chính sách quốc tế - Ban Quốc tế tỉnh Fukuoka)
    </p>
    <br/>
    <p>
    TEL：<a href="tel:+81-92-643-3201">+81-92-643-3201</a><br/>
    FAX：<a href="tel:+81-92-643-3224">+81-92-643-3224</a><br/>
    Từ 8:30 đến 17:15 (từ 9:00 đến 17:45)<br/>
    Tuy nhiên, trừ thứ Bảy, chủ nhật và ngày lễ, ngày Tết từ ngày 29 tháng 12 đến ngày 3 tháng 1
    </p>
    </>)
}

export default LangVi