
import Article from "@/components/Organisms/Article"
import { langType } from "src/types/lang"
import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"

const SIndustries = styled.div`
      p.txt-middle{    
            font-size: 1.8rem;
            line-height: 2.8rem;
            margin-bottom: 10px;
        }
    .navpicup{
        padding: 0 5%;
    }
    .navigator{
            width: 400px;
        }
`

const BasciInfoIndustries = ({lang}:{lang:langType}) => {


    setTitleDiscrption({
        layer:'basicinfo',
        lang:lang,
        subLayer:"industries"
    })

    return(
        <SIndustries>
            <Article offset={0} className="pc-noTopMargin">
            {lang === 'ja' &&
                <LangJa></LangJa>
            }
            {lang === 'easy_ja' &&
                <LangJa></LangJa>
            }
            {lang === 'en' &&
                <LangEn></LangEn>
            }
            {lang === 'ko' &&
                <LangKo></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh></LangTh>
            }
            {lang === 'vi' &&
                <LangVi></LangVi>
            }
            {lang === 'ne' &&
                <LangNe></LangNe>
            }

            </Article>
            <div className="pc-only navigator">
                <NavigatorAction actionType="good" charactorType="normal" dir="right"/>
            </div>
        </SIndustries>
    )
}

export default BasciInfoIndustries