
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { TabTamplate } from "@/components/Templates/tab"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import Article from "@/components/Organisms/Article";
import { MedicalTbody } from "."
import Data from 'src/json/ForeignPatientsMedical/vi.json'

const LangVi = ({onActive, active}) => {
    const medicals = Data.data

    return (
        <Article offset={0} className="pc-noTopMargin">
            <InnerBox>
                <h1>Cơ sở y tế trọng điểm tiếp nhận bệnh nhân người nước ngoài"<br/></h1>
                <p>
                Tại tỉnh Fukuoka, nhằm xây dựng môi trường y tế nơi người nước ngoài có thể an tâm sử dụng dịch vụ y tế, tỉnh chọn ra các cở sở trọng điểm tiếp nhận bệnh nhân người nước ngoài với sự đánh  giá của cở sở y tế khác rằng cơ sở ý tế đó có đủ điều kiện để hỗ trợ đa ngôn ngữ và thanh toán không dùng tiền mặt.  sở y tế trọng điểm tiếp nhận bệnh nhân người nước ngoài
                </p>
                </InnerBox>
                <InnerBox left={0} right={0} bottom={30}>
                <BlackNote>
                <div className="decoration">
                <p className="al-c star">Có những cơ sở y tế nào?</p>
                <p className="txt-middle">
                Dưới đây là danh sách các cơ sở y tế trọng điểm tiếp nhận bệnh nhân nước ngoài ở tỉnh Fukuoka. (tính đến tháng 6 năm 2023)
                 </p>
                <br/>
                <p className="txt-large al-c txt-yellow">
                Thanh toán không dùng tiền mặt OK
                </p>
                </div>
                </BlackNote>
                <div className="space-deco"></div>
            </InnerBox>
            <InnerBox>
            <TabTamplate>
                <ul className="tabnav-custom">
                    <li><button onClick={() => {onActive(0)}} className={`${active === 0 ? 'active' : ''}`}>Fukuoka City</button></li>
                    <li><button onClick={() => {onActive(1)}} className={`${active === 1 ? 'active' : ''}`}>Fukuoka Region<br/>（Outside Fukuoka City）</button></li>
                    <li><button onClick={() => {onActive(2)}} className={`${active === 2 ? 'active' : ''}`}>Chikugo Area</button></li>
                    <li><button onClick={() => {onActive(3)}} className={`${active === 3 ? 'active' : ''}`}>Chikuhō Area</button> </li>
                    <li><button onClick={() => {onActive(4)}} className={`${active === 4 ? 'active' : ''}`}>Kitakyushu Area</button></li>
                </ul>
            
                <nav className="tab">
                    <div className={`tab-content ${active === 0 ? 'active' : ''}`}>
                        <h3>Fukuoka City</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>Tên thành phố/ thị trấn/làng</th>
                            <th rowSpan={2}>Tên cơ sở y tế</th>
                            <th colSpan={2}>Bệnh nhân có thể tiếp nhận</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>Có giấy chứng nhận</th>
                            <th rowSpan={2}>Ngôn ngữ hỗ trợ</th>
                            </tr>
                            <tr>
                            <th>Bệnh nhân nặng</th>
                            <th>Bệnh nhân nhẹ</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[0]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 1 ? 'active' : ''}`}>
                        <h3>Fukuoka Region (Outside Fukuoka City)</h3>
                        <table>
                            <thead>
                                <tr>
                                <th rowSpan={2}>Tên thành phố/ thị trấn/làng</th>
                                <th rowSpan={2}>Tên cơ sở y tế</th>
                                <th colSpan={2}>Bệnh nhân có thể tiếp nhận</th>
                                <th rowSpan={2}>JMIP<br/>※1<br/>Có giấy chứng nhận</th>
                                <th rowSpan={2}>Ngôn ngữ hỗ trợ</th>
                                </tr>
                                <tr>
                                <th>Bệnh nhân nặng</th>
                                <th>Bệnh nhân nhẹ</th>
                                </tr>
                                </thead>
                            <MedicalTbody medicals={medicals[1]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 2 ? 'active' : ''}`}>
                        <h3>Chikugo Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>Tên thành phố/ thị trấn/làng</th>
                            <th rowSpan={2}>Tên cơ sở y tế</th>
                            <th colSpan={2}>Bệnh nhân có thể tiếp nhận</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>Có giấy chứng nhận</th>
                            <th rowSpan={2}>Ngôn ngữ hỗ trợ</th>
                            </tr>
                            <tr>
                            <th>Bệnh nhân nặng</th>
                            <th>Bệnh nhân nhẹ</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[2]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 3 ? 'active' : ''}`}>
                        <h3>Chikuhō Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>Tên thành phố/ thị trấn/làng</th>
                            <th rowSpan={2}>Tên cơ sở y tế</th>
                            <th colSpan={2}>Bệnh nhân có thể tiếp nhận</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>Có giấy chứng nhận</th>
                            <th rowSpan={2}>Ngôn ngữ hỗ trợ</th>
                            </tr>
                            <tr>
                            <th>Bệnh nhân nặng</th>
                            <th>Bệnh nhân nhẹ</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[3]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 4 ? 'active' : ''}`}>
                        <h3> Kitakyushu Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>Tên thành phố/ thị trấn/làng</th>
                            <th rowSpan={2}>Tên cơ sở y tế</th>
                            <th colSpan={2}>Bệnh nhân có thể tiếp nhận</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>Có giấy chứng nhận</th>
                            <th rowSpan={2}>Ngôn ngữ hỗ trợ</th>
                            </tr>
                            <tr>
                            <th>Bệnh nhân nặng</th>
                            <th>Bệnh nhân nhẹ</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[4]}></MedicalTbody>
                        </table>
                    </div>
                   
                </nav>
            </TabTamplate>
            <br/>
            <p className="txt-small">
                ※(Lưu ý 1) Không ngăn cản bệnh nhân nước ngoài điều trị tại các cơ sở y tế không có tên trong danh sách cơ sở y tế trọng điểm. (Lưu ý 2) Các cơ sở y tế được liệt kê trong danh sách không phải là cơ sở y tế tiếp nhận bệnh nhân nước ngoài đến Nhật Bản với mục đích điều trị y tế.<br/>
                ※1 JMIP（Japan Medical Service Accreditation for International Patients）→Hệ thống chứng nhận cơ sở y tế tiếp nhận bệnh nhân nước ngoài<br/>
                Đây là chính sách do Bộ Y tế, Lao động và Phúc lợi quyết định vào năm 2012 (tổ chức chứng nhận: Tổ chức giáo dục y tế Nhật Bản) nhằm mục đích hỗ trợ xây dựng hệ thống cung cấp dịch vụ y tế an tâm, an toàn cho tất cả người nước ngoài cần khám chữa bệnh tại các cơ sở y tế nội địa thông qua việc cở quan thứ 3 đánh giá hệ thống tiếp nhận bệnh nhân người nước ngoài của các cơ sở y tế nội địa như có hỗ trợ đa ngôn ngữ, quan tâm đến vấn đề khác văn hóa và tín ngưỡng. <br/>
                *2 Chi tiết về các cơ sở y tế hỗ trợ 21 ngôn ngữ, hãy gọi đến Trung tâm hỗ trợ y tế quốc tế Fukuoka.
                </p>
                <NavigatorAction dir="right" charactorType="normal" actionType="pickup"/>
                <EmphasisNote>
                <h1 className="c-h1">Bạn muốn biết thêm thông tin khác không?</h1>

                <p>
                Các cơ sở y tế trọng điểm trên toàn quốc được công bố trên trang website của Bộ Y tế, Lao động, Phúc lợi và Cục du lịch Nhật Bản (URL bên dưới).<br/>
                </p>
                <p>
                Website của Bộ Y tế, Lao động và Phúc lợi <br/>
                <a href="https://www.mhlw.go.jp/stf/newpage_05774.html" target="_blank">
                https://www.mhlw.go.jp/stf/newpage_05774.html
                </a><br/>
                </p>
                <p>
                Website của Cục du lịch<br/>
                <a href="https://www.jnto.go.jp/emergency/jpn/mi_guide.html" target="_blank">
                https://www.jnto.go.jp/emergency/jpn/mi_guide.html
                </a>
                </p>

                <p>
                Đây là trang chủ giúp bạn có thể tìm kiếm các bệnh viện, phòng khám, phòng khám nha khoa・khoa nhi ・ trung tâm hộ sinh cũng như cung cấp các thông tin y tế về cấp cứu・tiêm phòng・ sức khỏe・ phúc lợi nhi khoa...<br/>
                </p>
                <p>
                Mạng lưới thông tin y tế Fukuoka<br/>
                <a href="https://www.fmc.fukuoka.med.or.jp/" target="_blank">
                https://www.fmc.fukuoka.med.or.jp/
                </a>
                </p>
                </EmphasisNote>
                </InnerBox>
        </Article>
    )
}

export default LangVi