
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote";


const LangZhTw = () => {

    return (
        <>
            <p className="discpriton">
            “豬骨拉麵”是福岡縣的名特產之一<br/>
            在福岡縣各處的拉麵店都能品嚐到別具一格的拉麵。<br/>
            “豚骨拉麵 BEATS”是一款以 "豚骨拉麵 "為主題的遊戲。<br/>
            抓住麵碗的節奏,獲得高分！
            </p>
            <EmphasisNote className="customNote">
                <h4><img src="./images/ramenbeats/howtoplay.webp" alt="how to play"/></h4>
                <section>
                    <h5 className="first">
                    點擊屏幕下方的 "開始 "按鈕。<br/>（將轉入遊戲界麵。 ）
                    </h5>
                </section>
                <section>
                    <h5 className="second">
                        點擊<br/>
                        <img src="./images/ramenbeats/start.webp" alt="start" width={230}/>
                    </h5>
                </section>
                <section>
                    <h5 className="third">
                    點擊喜歡的舞台,開始遊戲！
                    </h5>
                    <ul className="caption">
                        <li>請使用最新的操作係統和瀏覽器進行遊戲！</li>
                        <li>玩本遊戲要使用智能手機。</li>
                    </ul>
                </section>
            </EmphasisNote>
            <h4 className="whattonkotsu"><img src="./images/ramenbeats/what_tonkotsu.webp" alt="What’s TONKOTSU RAMEN？"/></h4>
            <p>
            Tonkotsu "意為 "豬骨" 。<br/>
            豬骨拉麵的湯是由豬骨長時間熬煮而成的。<br/>
            不同地區、不同店鋪不盡相同,但其特點是湯色白濁。<br/>
            以細直的麵條為主。<br/>
            許多麵館都允許客人根據自己的喜好來選擇“超硬的麵”、“偏硬的麵”等不同硬度的麵條。<br/>
            另外,許多麵館還為客人免費增加麵條。這也是一大特色。
            </p>
            <ul className="phots"> 
                <li>
                    <img  src="./images/ramenbeats/ramen01.webp" alt="豚骨ラーメン"/>
                </li>
                <li>
                    <img  src="./images/ramenbeats/ramen02.webp" alt="豚骨ラーメン"/>
                </li>
            </ul>
            
        
        </>
    )
}

export default LangZhTw