import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlue, FukidashiBlueBottom } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { ChallengerAction, NavigatorAction } from "@/components/Molecules/CharactorAction"



const LangTh = () => {
    const lang = 'th'
    return (<>
        <InnerBox left={0} right={0} bottom={60}>
        <div className="pc-flex col-2">
            <div className="question">
            <div className="charactor">
            <ChallengerAction
            dir="left"
            charactorType="thinking"
            actionType="question"/>
            </div>
            <div className="fukidashi">
            <FukidashiBlue dir="left">
            <span> หากพูดภาษาญี่ปุ่นไม่ได้ มีช่องทางหรือสถานที่ให้คำปรึกษาที่รองรับหลายภาษาหรือไม่?</span>
            </FukidashiBlue>
            </div>
            </div>

            <div className="anser-block">
            <div className="fukidashi">
            <FukidashiBlueBottom dir="right">
            <span>สามารถรับคำปรึกษาจาก ศูนย์ให้คำปรึกษาชาวต่างชาติจังหวัดฟูกูโอกะ ได้ 22 ภาษารวมถึงภาษาญี่ปุ่น นอกจากนี้องค์กรปกครองส่วนท้องถิ่นแต่ละแห่งยังจัดตั้งช่องทางหรือสถานที่ให้คำปรึกษาหลายภาษาและสมาคมแลกเปลี่ยนระหว่างประเทศเพื่อให้ความช่วยเหลือด้วยภาษาต่างประเทศ ให้คำแนะนำด้านกฎหมาย และให้ความช่วยเหลือทั่วไปในการดำรงชีวิตประจำวัน</span>
            </FukidashiBlueBottom>
            </div>
            <div className="charactor">
            <NavigatorAction
            dir="right"
            charactorType="normal"
            actionType="anser"/>
            </div>
            </div>
        </div>

        <div className="links">
        <span className="left">หากต้องการข้อมูลเพิ่มเติมโปรดดูรายละเอียดที่ </span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/consultation/international?lang=${lang}`}
        linkKey="kokusai_soudan"
        ></YellowArrowLink>
        </div>
        <span className="right"></span>
        </div>

        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
            <div className="charactor">
            <ChallengerAction
            dir="left"
            charactorType="puzzled"
            actionType="question"/>
            </div>
            <div className="fukidashi">
            <FukidashiBlue dir="left">
            <span>อยากพาครอบครัวมาด้วยแต่พวกเขาพูดภาษาญี่ปุ่นไม่ได้ ช่วยแนะนำสถานที่เรียนภาษาญี่ปุ่นให้ได้หรือไม่?</span>
            </FukidashiBlue>
            </div>
            </div>

            <div className="anser-block">
            <div className="fukidashi">
            <FukidashiBlueBottom dir="right">
            <span>ในจังหวัดฟุกุโอกะมีห้องเรียนภาษาญี่ปุ่นที่จัดโดยรัฐหรืออาสาสมัครกว่า 100 ชั้นเรียน นอกจากนี้ยังมีห้องเรียนที่สามารถปรึกษาเกี่ยวกับปัญหาในชีวิตประจำวันอีกด้วย</span>
            </FukidashiBlueBottom>
            </div>
            <div className="charactor">
            <NavigatorAction
            dir="right"
            charactorType="smile"
            actionType="anser"/>
            </div>
            </div>
        </div>

        <div className="links">
        <span className="left">หากต้องการข้อมูลเพิ่มเติมโปรดดูรายละเอียดที่ </span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/lifestyle/education?lang=${lang}`}
        linkKey="kyoiku"
        ></YellowArrowLink>
        </div>
        <span className="right"></span>
        </div>


        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
            <div className="charactor">
            <ChallengerAction
            dir="left"
            charactorType="thinking"
            actionType="question"/>
            </div>
            <div className="fukidashi">
            <FukidashiBlue dir="left">
            <span>อยากทราบเกี่ยวกับสถาบันทางการแพทย์ที่ให้บริการเป็นภาษาต่างประเทศ</span>
            </FukidashiBlue>
            </div>
            </div>

            <div className="anser-block">
            <div className="fukidashi">
            <FukidashiBlueBottom dir="right">
            <span>ศูนย์สนับสนุนการแพทย์นานาชาติฟุกุโอกะ สามารถให้ข้อมูลสถาบันการแพทย์ที่ให้บริการเป็นภาษาต่างประเทศ สถาบันการแพทย์ที่ให้บริการล่ามทางการแพทย์ สถาบันการแพทย์ในจังหวัดฟุกุโอกะ และระบบการแพทย์ของญี่ปุ่น รวมถึงแนะนำสถาบันการแพทย์ที่สามารถให้บริการล่ามทางโทรศัพท์สำหรับสนทนากับแพทย์</span>
            </FukidashiBlueBottom>
            </div>
            <div className="charactor">
            <NavigatorAction
            dir="right"
            charactorType="good"
            actionType="anser"/>
            </div>
            </div>
        </div>
        <div className="links">
        <span className="left">หากต้องการข้อมูลเพิ่มเติมโปรดดูรายละเอียดที่ </span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/lifestyle/medical?lang=${lang}`}
        linkKey="iryo"
        ></YellowArrowLink>
        </div>
        <span className="right"></span>
        </div>

        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
            <div className="charactor">
            <ChallengerAction
            dir="left"
            charactorType="thinking"
            actionType="question"/>
            </div>
            <div className="fukidashi">
            <FukidashiBlue dir="left">
            <span>มีโรงเรียนนานาชาติให้เด็กเข้าเรียนหรือไม่?</span>
            </FukidashiBlue>
            </div>
            </div>

            <div className="anser-block">
            <div className="fukidashi">
            <FukidashiBlueBottom dir="right">
            <span>ในจังหวัดฟุกุโอกะมีโรงเรียนนานาชาติที่ได้รับการรับรองจาก International Baccalaureate Organization ซึ่งเป็นองค์กรรับรองการศึกษาระดับนานาชาติ โดยให้การศึกษาเป็นภาษาอังกฤษตั้งแต่ระดับปฐมวัยจนถึงมัธยมศึกษาตอนปลาย</span>
            </FukidashiBlueBottom>
            </div>
            <div className="charactor">
            <NavigatorAction
            dir="right"
            charactorType="pointing"
            actionType="anser"/>
            </div>
            </div>
        </div>
        <div className="links">
        <span className="left">หากต้องการข้อมูลเพิ่มเติมโปรดดูรายละเอียดที่ </span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/lifestyle/education?lang=${lang}`}
        linkKey="kyoiku"
        ></YellowArrowLink>
        </div>
        <span className="right"></span>
        </div>

        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
            <div className="charactor">
            <ChallengerAction
            dir="left"
            charactorType="thinking"
            actionType="question"/>
            </div>
            <div className="fukidashi">
            <FukidashiBlue dir="left">
            <span>กำลังหางานในฟุกุโอกะ มีที่ไหนช่วยแนะนำให้ได้หรือไม่?</span>
            </FukidashiBlue>
            </div>
            </div>

            <div className="anser-block">
            <div className="fukidashi">
            <FukidashiBlueBottom dir="right">
            <span>สามารถรับคำปรึกษาและการแนะนำเกี่ยวกับงานสำหรับชาวต่างชาติได้ที่ศูนย์บริการจัดหางานฟุกุโอกะสำหรับชาวต่างชาติ Hello Work ตำบลจูโอ อำเภอฟุกุโอกะ</span>
            </FukidashiBlueBottom>
            </div>
            <div className="charactor">
            <NavigatorAction
            dir="right"
            charactorType="normal"
            actionType="anser"/>
            </div>
            </div>
        </div>
        <div className="links">
        <span className="left">หากต้องการข้อมูลเพิ่มเติมโปรดดูรายละเอียดที่ </span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/lifestyle/employment?lang=${lang}`}
        linkKey="works"
        ></YellowArrowLink>
        </div>

        <span className="right"></span>
        </div>

        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
            <div className="charactor">
            <ChallengerAction
            dir="left"
            charactorType="thinking"
            actionType="question"/>
            </div>
            <div className="fukidashi">
            <FukidashiBlue dir="left">
            <span>อยากย้ายมาอยู่ฟุกุโอกะ แต่กังวลเรื่องการปรับตัวเข้ากับชุมชนท้องถิ่น</span>
            </FukidashiBlue>
            </div>
            </div>

            <div className="anser-block">
            <div className="fukidashi">
            <FukidashiBlueBottom dir="right">
            <span>เว็บไซต์นี้มีการนำเสนอวิดีโอเรื่องราวความสำเร็จของชาวต่างชาติที่มีบทบาทในจังหวัดฟุกุโอกะ ในฟุกุโอกะมีชาวต่างชาติจำนวนไม่น้อยซึ่งจากบ้านเกิดมาไกลและได้ใช้ชีวิตที่จนกลายเป็นหนึ่งเดียวกับกับชุมชนท้องถิ่นเหมือนคนที่แนะนำในวิดีโอ</span>
            </FukidashiBlueBottom>
            </div>
            <div className="charactor">
            <NavigatorAction
            dir="right"
            charactorType="smile"
            actionType="anser"/>
            </div>
            </div>
        </div>
        <div className="links">
        <span className="left">หากต้องการข้อมูลเพิ่มเติมโปรดดูรายละเอียดที่ </span>
        <div className="al-c">
        <YellowArrowLink
        lang={lang}
        href={`/movie?lang=${lang}`}
        linkKey="fortunehills"
        ></YellowArrowLink>
        </div>
        <span className="right"></span>
        </div>
        </InnerBox>
        </>)
}

export default LangTh