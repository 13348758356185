import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"


const SLiving = styled.div`
    ul.links{
        li{
            margin-bottom: 10px;
            a{
                display: block;
                margin-top: 5px;
            }
        }
    }
    ul.deco{
        h4{
            font-size: 2.0rem;
            text-align:center;
            color : ${props => props.theme.colors.yellow};
            font-weight: bold;
            &::after{
                content:'';
                display:block;
                width: 100%;
                height: 40px;
                background: url(${publicPath('images/startMark.svg')}) no-repeat;
                background-size: auto 40%;
                background-position:center;
            }
        }
        p{
            font-size: 1.8rem !important;
        }
        li{
            margin-bottom: 30px;
        }
    }
`


const Howtofindhousing = ({lang}:{lang:langType}) => {

    setTitleDiscrption({
        layer : 'lifestyle',
        lang : lang,
        subLayer: "living"
    })
    return (
        <SLiving>
            <Article offset={0}>
            {
            lang === 'ja' &&
            <LangJa></LangJa>
            }
            {lang === 'en' &&
                <LangEn></LangEn>
            }
            {lang === 'ko' &&
                <LangKo></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh></LangTh>
            }
            {lang === 'vi' &&
                <LangVi></LangVi>
            }
            {lang === 'ne' &&
                <LangNe></LangNe>
            }

            </Article>
        </SLiving>
    )
}

export default Howtofindhousing