import { ChallengerType } from "src/types/charactor"
import { direction } from "src/types/common"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"


const SFigure = styled.figure`
    padding: 0;
    margin: 0;
`
interface CharacterProps {
    dir : direction,
    color? : string
}

export const Challenger = ({type}:{type:ChallengerType}) => {

    return (
    <SFigure>
        <img src={publicPath(`images/character/v2/challenger_${type}.png`)}
        alt="challenger" 
        className="img-fit"
        />
    </SFigure>
    )
}

export const ChallengerrFullLength = () => {

    return (
        <SFigure>
        <img src={publicPath('images/character/challenger.png')}
        alt="challenger" 
        className="img-fit"
        />
        </SFigure>
    )
}

export const ChallengerrFullLengthJump= () => {

    return (
        <SFigure>
        <img src={publicPath('images/character/challenger_jump.png')}
        alt="challenger" 
        className="img-fit"
        />
        </SFigure>
    )
}


export const ChallengerrJaggedMark = () => {
    return (
        <SFigure>
        <img src={publicPath('images/character/challenger_patten01.png')}
        alt="challenger" 
        className="img-fit"
        />
        </SFigure>
    )
}

export const ChallengerExcl = ({
    dir,
    color = 'blue',
    ...props
}:CharacterProps) => {

    return (
        <SFigure>
             <img src={publicPath(`images/character/challenger_excl_${dir}_${color}.png`)}
            alt="challenger" 
            className="img-fit"
            />
        </SFigure>
    )
}

export const ChallengerQ = ({
    dir,
    color = 'blue',
    ...props
}:CharacterProps) => {

    return (
        <SFigure>
             <img src={publicPath(`images/character/challenger_q_${dir}_${color}.png`)}
            alt="challenger" 
            className="img-fit"
            />
        </SFigure>
    )
}


export const ChallengerNice = ({
    dir,
    color = 'blue',
    ...props
}:CharacterProps) => {

    return (
        <SFigure>
             <img src={publicPath(`images/character/challenger_nice_${dir}_${color}.png`)}
            alt="Nice!!" 
            className="img-fit"
            />
        </SFigure>
    )
}