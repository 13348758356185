
const LangEn = () => {

    return (<>
    <h1>About FUKUOKA IS OPEN</h1>
    <p>
    Fukuoka Prefecture ranks high in Japan in terms of the number of friendship partner regions and the number of foreign students, the number of foreign residents in Fukuoka Prefecture has reached a record high of 89,518.
    </p>
    <p>
    In order to maintain regional vitality in a society with a declining population, it is essential for foreign human resources to play an active role as workers for companies in the prefecture and as new leaders in local communities.
    </p>
    <p>
    Under such circumstances, in order to attract even more people from overseas, we would like to communicate our policies in an integrated manner to the whole world under the catchphrase "FUKUOKA IS OPEN" and let people know that our prefecture is an easy place for foreigners to live and play an active role.
    </p>
    <p>
    For this reason, the multilingual portal site "FUKUOKA IS OPEN" (<a href="https://www.fisop.net">https://www.fisop.net</a>) provides information related to daily life for foreigners, such as education and medical care in the prefecture, as well as information on foreign students, inbound, attracting international financial functions, and advanced measures such as sports, environment, and one-health. The site also provides up-to-date information on the activities of foreign residents in the prefecture.
    </p>
    <br/>
    <br/>
        <h5 className="title-large">Use of the FUKUOKA IS OPEN original logo</h5>
        <p>The use of this logo without the consent of the FUKUOKA IS OPEN Promotion Project Executive Committee is prohibited.<br/>
        An application for use must be made in accordance with the usage instructions.<br/>
        Please read the usage guidelines and the guidelines for use, and fill in the 'FUKUOKA IS OPEN Promotion Project Logo Application Form (Form 1)' and submit it by fax.</p>
        <br/>
        <p>If your application is approved, you will receive a letter of acceptance and logo data.</p>
        <br/>
        <p>
        [Application documents, etc.].
            </p>
        <ul className="dotto">
            <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOPromotionProjectLogoUsageInstructions.pdf" target="_blank">FUKUOKA IS OPEN Promotion Project Logo Usage Instructions</a></span></li>
            <li><span className="icon-doc"><a href="/contents/aboutfio/Form1_FIOPromotionProjectLogoApplicationForm.docx">FUKUOKA IS OPEN Promotion Project Logo Application Form (Form 1)</a></span></li>
            <li><span className="icon-doc"><a href="/contents/aboutfio/Form4_FIOPromotionProjectLogoApplicationForm.docx">FUKUOKA IS OPEN Promotion Project Logo Application Form for change of approval (Form 4)</a></span></li>
            <li><span className="icon-doc"><a href="/contents/aboutfio/Form5_FIOPromotionProjectLogoUsageStatusReport.docx">FUKUOKA IS OPEN Promotion Project Logo Usage Status Report (Form 5) </a></span></li>
            <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOLOGOUSERGUIDELINE.pdf" target="_blank">[FUKUOKA IS OPEN] LOGO USER GUIDELINE</a></span></li>
        </ul>
        <br/>
        <p>
        (Contact)<br/>
        FUKUOKA IS OPEN Promotion Project Executive Committee Secretariat (Foreign Human Resources Support Section, International Policy Division, International Bureau, Fukuoka Prefecture)
        </p>
        <br/>
        <p>
        TEL：<a href="tel:+81-92-643-3201">+81-92-643-3201</a><br/>
        FAX：<a href="tel:+81-92-643-3224">+81-92-643-3224</a><br/>
        8.30am to 5.15pm (9.00am to 5.45pm)<br/>
        Except Saturdays, Sundays, public holidays and 29 December to 3 January.
        </p>
    </>)
}

export default LangEn