import { useState, createContext, useContext, PropsWithChildren } from 'react';
import { PathAnalysisValue } from "src/utilty/pathAnalyzer";


interface CmsContextType {
    pathAnalys: PathAnalysisValue,
    viewArticleID: string | undefined,
    setViewAricleID: (value:  string | undefined) => void,

}

const CmsContext = createContext<CmsContextType>({
    pathAnalys : {
        layer : 'information',
        category : 'index',
        id: undefined,
        lang : 'ja',
        keyword : undefined
    },
    viewArticleID: undefined,
    setViewAricleID : () => {}
})

export const CmsProvider = (
    {
        pathAnalys, 
        children
    }:PropsWithChildren<{pathAnalys: PathAnalysisValue}>
) => {


    const [viewArticleID,  setViewAricleID] = useState<string | undefined>()
    
    const value = {
        pathAnalys,
        viewArticleID,
        setViewAricleID,
    }

    return (
        <CmsContext.Provider value={value}>
          {children}
        </CmsContext.Provider>
    );
}

export function useCmsContext() {
    return useContext(CmsContext);
}