import InnerBox from "@/components/Atoms/Box/innerBox"
import {FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"

const LangNe = () => {

    return (<>
    <InnerBox>
    <h1>बसोबास　वातावरण</h1>
    <p>
    फुकुओकामा बस्ने ठाउँ खोजेर、ढुक्कका साथ बस्नको लागि、निम्न बुँदाहरू बुझेर、उपयुक्त प्रक्रियाहरू पालना गरेर、प्रशासनको परामर्श सेवाहरूको सहयोग लिनुहोस ।
    </p>
    </InnerBox>
    <BlackNote>
    <ul className="deco">
    <li>
    <h4>１. बासस्थानहरू खोज्ने तरिका</h4>
    <p>
    ●घर जग्गा सम्बन्धी जानकारीका वेबसाइटहरू、एपहरुको प्रयोग गरी घर जग्गा जानकारी खोज्नुहोस।<br/>
    ●विदेशी भाषाहरूमा सेवाहरू प्रदान गर्ने केही घर जग्गा कम्पनीहरू पनी छन् ।
    </p>
    </li>
    <li>
    <h4>２.भाडा आवास को प्रकार</h4>
    <p>
    ●「निजी आवास」:घरबेटी (घर भाडामा लिने व्यक्ति)बाट भाडामा लिने घर<br/>
    ●「सार्वजनिक आवास」: प्रान्त तथा शहर आदिद्वारा भाडामा दिने घरहरु ।<br/>
    ●「यूआर भाडाको घर」: 「यूआर शहरी संस्था」द्वारा भाडामा दिने घरहरु । ग्यारेन्टर आवश्यक हुदैन
    </p>
    </li>
    <li>
    <h4>３.भाडा सम्झौताको आधारभूत कुराहरू</h4>
    <p>
    ●भाडा सम्झौताहरूमा सामान्यतया、सुरक्षा जम्मा रकम（जमानत पैसा）तथा सलामीको रकम、भाडा आदि समाबेश हुन्छ। सम्झौतालाई ध्यानपूर्वक बुझेर、सर्तहरू जाँच गर्नुहोस्।
    </p>
    </li>
    <li>
    <h4>４.सुरक्षा जम्मा रकम र सलामीको रकम </h4>
    <p>
    ●सुरक्षा जम्मा रकम सम्झौताको अन्त्यमा फिर्ता गरिनेछ तर、यदि भाडा तिर्न बाँकी भएको अथवा कोठा फोहोर भएको फोहोरले गर्दा बिग्रेको भाग भएको खण्डमा कटौती हुन सक्छ।सलामीको रकम फिर्ता गरिने छैन।
    </p>
    </li>
    <li>
    <h4>５.ग्यारेन्टर</h4>
    <p>
    ●सामान्यतया सम्झौता गर्दा ग्यारेन्टर आवश्यक हुन्छ। ग्यारेन्टरले कोठा वालाले पैसा तिर्ने नसक्दा ग्यारेन्टरले जिम्मा लिनु पर्ने  हुन्छ।  साथै भाडा ऋण ग्यारेन्टी कम्पनीको प्रयोग गर्न सकिन्छ।
    </p>
    </li>
    <li>
    <h4>６.इन्टरनेट र ग्यास बत्ती पानिको सुविधाहरू</h4>
    <p>
    ●धेरै घरहरूमा इन्टरनेट पहुँच छ। उपयोग खर्चहरू (बिजुली, ग्यास, पानी, आदि) सामान्यतया भाडाबाट छुट्टै तिर्नु पर्ने हुन्छ।
    </p>
    </li>
    <li>
    <h4>７.फर्निचर・घरेलु उपकरणहरू हुने नहुने</h4>
    <p>
    ●घर सरे पछि निवासी दर्ता गर्न आवश्यक हुनेछ। यो शहर/वडा/नगर/गाउँको कार्यालयमा गर्न सकिन्छ।
    </p>
    </li>
    <li>
    <h4>８.जीवनशैली बानी र नियमहरू</h4>
    <p>
    ●जापानमा बस्ने क्रममा、छिमेकीलाइ बिचार गरेर फोहोर फाल्ने नियम आदि、बिशेस खालका जीवनशैली र आचरणहरू हुन्छन। कृपया यी कुराहरूलाई पनि ध्यानमा राख्नुहोस्।
    </p>
    </li>
    <li>
    <h4>9.लिङ्क</h4>
    <p>
    ●तलको लिङ्कमा घर खोज्दा धेरै सुनिने कुराहरु तथा「कोठा खोज्ने गाइडबुक」आदि विदेशीले कोठा खोज्ने क्रम माआवश्यक जानकारी धेरै भाषाहरूमा छन।
    </p>
    </li>
    </ul>
    </BlackNote>
    <InnerBox top={20} bottom={20}>
    <FukidashiRedLeft>
    तलको लिङ्कमा घर खोज्दा धेरै सुनिने कुराहरु तथा「कोठा खोज्ने गाइडबुक」आदि विदेशीले कोठा खोज्ने क्रम माआवश्यक जानकारी धेरै भाषाहरूमा छन।
    </FukidashiRedLeft>
    <h1>
    अध्यागमन सेवा एजेन्सी HP「लिभिङ एण्ड वर्किङ गाइडबुक」
    </h1>
    <ul className="links">
    <li>
    ●अंग्रेजी भाषा ：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392830.pdf</a>
    </li>
    <li>
    ●चिनियाँ भाषा ：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392831.pdf</a>
    </li>
    <li>
    ●कोरियाली भाषा ：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001393145.pdf</a>
    </li>
    <li>
    ●भियतनामी भाषा ：<a href="https://www.moj.go.jp">https://www.moj.go.j/isa/content/001393146.pdfp</a>
    </li>
    <li>
    ●थाई भाषा ： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●नेपाली भाषा ： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●सजिलो जापानी भाषा ： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/930004715.pdf</a>
    </li>
    </ul>
    </InnerBox>
    <InnerBox bottom={20}>
    <h1>सार्वजनिक हित समावेसार्वजनिक हित समावेशी फाउन्डेसन जापान भाडा आवास व्यवस्थापन संघ 「विदेशीहरूको आवास प्रवेश सुविधाका लागि सहयोगको बारेमा जानकारी」शी फाउन्डेसन जापान भाडा आवास व्यवस्थापन संघ 「विदेशीहरूको आवास प्रवेश सुविधाका लागि सहयोगको बारेमा जानकारी」</h1>
    <p>
    <a href="https://www.jpm.jp">https://www.jpm.jp/foreign/</a>
    </p>
    </InnerBox>
    </>)
}

export default LangNe