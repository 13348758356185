
import Data from 'src/json/layer.json'
import { endpoint } from 'src/types/article'
import { layerType, subLayerType } from 'src/types/layer'

const getLayer = (page:layerType) => {
    return Data.data[page]

}

export const getsubLayer = (layer:layerType ,subLayer:subLayerType) => {
    return Data.data[layer][subLayer] ? Data.data[layer][subLayer] : undefined
}

export default getLayer