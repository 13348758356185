
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { TabTamplate } from "@/components/Templates/tab"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import Article from "@/components/Organisms/Article";
import { MedicalTbody } from "."
import Data from 'src/json/ForeignPatientsMedical/th.json'

const LangTh = ({onActive, active}) => {
    const medicals = Data.data
    
    return (
        <Article offset={0} className="pc-noTopMargin">
            <InnerBox>
                <h1>สถาบันการแพทย์ที่รับผู้ป่วยต่างชาติ<br/></h1>
                <p>
                เพื่อสร้างมาตรฐานการบริการทางการแพทย์ที่คนต่างชาติสามารถรับบริการได้อย่างไว้วางใจ ทางจังหวัดฟูกูโอกะจึง ทำการคัดกรองสถาบันการแพทย์ที่สามารถรับผู้ป่วยต่างชาติในแต่ละการบริการระดับทุติยภูมิ โดยต้องสามารถให้บริการด้วยภาคต่างประเทศหลายภาษาและรับชำระเงินแบบไม่ใช้เงินสดได้ 
                </p>
                </InnerBox>
                <InnerBox left={0} right={0} bottom={30}>
                <BlackNote>
                <div className="decoration">
                <p className="al-c star">สถาบันการแพทย์ที่ไหนบ้าง</p>
                <p className="txt-middle">
                บัญชีรายชื่อสถาบันการแพทย์ในจังหวัดฟุกุโอกะที่รับผู้ป่วยต่างชาติมีดังต่อไปนี้ （ณ เดือนมิถุนายน พ.ศ.2566）
                </p>
                <br/>
                <p className="txt-large al-c txt-yellow">
                ชำระเงินโดยไม่ใช้เงินสด OK！
                </p>
                </div>
                </BlackNote>
                <div className="space-deco"></div>
            </InnerBox>
            <InnerBox>
            <TabTamplate>
                <ul className="tabnav-custom">
                    <li><button onClick={() => {onActive(0)}} className={`${active === 0 ? 'active' : ''}`}>Fukuoka City</button></li>
                    <li><button onClick={() => {onActive(1)}} className={`${active === 1 ? 'active' : ''}`}>Fukuoka Region<br/>（Outside Fukuoka City）</button></li>
                    <li><button onClick={() => {onActive(2)}} className={`${active === 2 ? 'active' : ''}`}>Chikugo Area</button></li>
                    <li><button onClick={() => {onActive(3)}} className={`${active === 3 ? 'active' : ''}`}>Chikuhō Area</button> </li>
                    <li><button onClick={() => {onActive(4)}} className={`${active === 4 ? 'active' : ''}`}>Kitakyushu Area</button></li>
                </ul>
            
                <nav className="tab">
                    <div className={`tab-content ${active === 0 ? 'active' : ''}`}>
                        <h3>Fukuoka City</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>ชื่อเมืองอำเภอหมู่บ้าน</th>
                            <th rowSpan={2}>ชื่อหน่วยงานทางการแพทย์</th>
                            <th colSpan={2}>ผู้ป่วยที่สามารถรับรองได้</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>ได้รับการรับรอง</th>
                            <th rowSpan={2}>ภาษาที่สามารถรับรองได้</th>
                            </tr>
                            <tr>
                            <th>ผู้ป่วยอาการหนัก</th>
                            <th>ผู้ป่วยอาการเบา</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[0]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 1 ? 'active' : ''}`}>
                        <h3> Fukuoka Region (Outside Fukuoka City)</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>ชื่อเมืองอำเภอหมู่บ้าน</th>
                            <th rowSpan={2}>ชื่อหน่วยงานทางการแพทย์</th>
                            <th colSpan={2}>ผู้ป่วยที่สามารถรับรองได้</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>ได้รับการรับรอง</th>
                            <th rowSpan={2}>ภาษาที่สามารถรับรองได้</th>
                            </tr>
                            <tr>
                            <th>ผู้ป่วยอาการหนัก</th>
                            <th>ผู้ป่วยอาการเบา</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[1]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 2 ? 'active' : ''}`}>
                        <h3>Chikugo Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>ชื่อเมืองอำเภอหมู่บ้าน</th>
                            <th rowSpan={2}>ชื่อหน่วยงานทางการแพทย์</th>
                            <th colSpan={2}>ผู้ป่วยที่สามารถรับรองได้</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>ได้รับการรับรอง</th>
                            <th rowSpan={2}>ภาษาที่สามารถรับรองได้</th>
                            </tr>
                            <tr>
                            <th>ผู้ป่วยอาการหนัก</th>
                            <th>ผู้ป่วยอาการเบา</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[2]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 3 ? 'active' : ''}`}>
                        <h3>Chikuhō Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>ชื่อเมืองอำเภอหมู่บ้าน</th>
                            <th rowSpan={2}>ชื่อหน่วยงานทางการแพทย์</th>
                            <th colSpan={2}>ผู้ป่วยที่สามารถรับรองได้</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>ได้รับการรับรอง</th>
                            <th rowSpan={2}>ภาษาที่สามารถรับรองได้</th>
                            </tr>
                            <tr>
                            <th>ผู้ป่วยอาการหนัก</th>
                            <th>ผู้ป่วยอาการเบา</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[3]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 4 ? 'active' : ''}`}>
                        <h3>Kitakyushu Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>ชื่อเมืองอำเภอหมู่บ้าน</th>
                            <th rowSpan={2}>ชื่อหน่วยงานทางการแพทย์</th>
                            <th colSpan={2}>ผู้ป่วยที่สามารถรับรองได้</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>ได้รับการรับรอง</th>
                            <th rowSpan={2}>ภาษาที่สามารถรับรองได้</th>
                            </tr>
                            <tr>
                            <th>ผู้ป่วยอาการหนัก</th>
                            <th>ผู้ป่วยอาการเบา</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[4]}></MedicalTbody>
                        </table>
                    </div>
                   
                </nav>
            </TabTamplate>
            <br/>
            <p className="txt-small">
            ※（หมายเหตุ１）สถาบันการแพทย์ที่ไม่อยู่ในบัญชีรายชื่อสถาบันการแพทย์ที่รับผู้ป่วยต่างชาตินี้ไม่ได้หมายความว่าจะไม่รับผู้ป่วยต่างชาติ（หมายเหตุ２）สถาบันการแพทย์ที่อยู่ในบัญชีรายชื่อ สถาบันการแพทย์ที่รับผู้ป่วยต่างชาตินี้ไม่ใช่สถาบันการแพทย์ที่รับผู้ป่วยต่สงชาติที่เข้ามาญี่ปุ่นเพื่อวัตถุประสงค์จะมาตรวจรักษาโดยตรง<br/>
            ※1 JMIP（Japan Medical Service Accreditation for International Patients）→ ระบบประเมินและรับรองสถาบันการแพทย์ที่รับผู้ป่วยต่างชาติ<br/>
            เป็นระบบที่กำหนดโดยกระทรวงสาธารณสุข แรงงานและสวัสดิการ ในปี พ.ศ.2555 เพื่อส่งเสริมการสร้างระบบการให้บริการทางการแพทย์ที่ปลอดภัยและไว้วางใจได้ให้แก่คนต่างชาติที่มารับการตรวจรักษาให้กับสถาบันแพทย์ในประเทศญี่ปุ่น โดยให้หน่วยงานที่ 3 เป็นผู้ประเมินระบบต่างๆ ที่เอื้ออำนวยการรับผู้ป่วยต่างชาติ ของสถานบันการแพทย์นั้น ได้แก่ การให้บริการตรวจรักษาโดยภาษาต่างประเทศหลายภาษา เข้าใจในความแตกต่างของวัฒนธรรม และข้อกำหนดของแต่ละศาสนา เป็นต้น （สถาบันประเมินและรับรอง：องค์กรศึกษาทางการแพทย์แห่งประเทศญี่ปุ่น）<br/>
            สามารถหาข้อมูลของสถาบันการแพทย์ที่ให้บริการด้วยภาษาต่างประเทศ 21 ภาษา ได้ที่ ศูนย์ช่วยเหลือด้านการแพทย์นานาชาติฟุกุโอกะ
            </p>
            <NavigatorAction dir="right" charactorType="normal" actionType="pickup"/>
            <EmphasisNote>
            <h1 className="c-h1">อยากรู้ข้อมูลอื่นๆ</h1>

            <p>
            ดูบัญชีรายชื่อสถาบันการแพทย์ที่รับผู้ป่วยต่างชาติทั่วประเทศญี่ปุ่นได้ในโฮมเพจของกระทวงสาธารณสุข แรงงานและสวัสดิการ และของการท่องเที่ยวญี่ปุ่น (URL ข้างล่างนี้)<br/>
            </p>
            <p>
            โฮมเพจกระทรวงสาธารณสุข แรงงานและสวัสดิการญี่ปุ่น<br/>
            <a href="https://www.mhlw.go.jp/stf/newpage_05774.html" target="_blank">
            https://www.mhlw.go.jp/stf/newpage_05774.html
            </a><br/>
            </p>
            <p>
            โฮมเพจการท่องเที่ยวญี่ปุ่น<br/>
            <a href="https://www.jnto.go.jp/emergency/jpn/mi_guide.html" target="_blank">
            https://www.jnto.go.jp/emergency/jpn/mi_guide.html
            </a>
            </p>

            <p>
            โฮมเพจข้อมูลเกี่ยวกับโรงพยาบาล สถานพยาบาล สถานทันตกรรม กุมารเวช การผดุงครรภ์ การช่วยเหลือเด็ก การป้องกัน ประกันสุขภาพ สังคมสงเคราะห์ ฯลฯ <br/>
            </p>
            <p>
            เครือข่ายข้อมูลทางการแพทย์ฟุกุโอกะ<br/>
            <a href="https://www.fmc.fukuoka.med.or.jp/" target="_blank">
            https://www.fmc.fukuoka.med.or.jp/
            </a>
            </p>
            </EmphasisNote>
            </InnerBox>
        </Article>
    )
}

export default LangTh