
const LangNe = () => {


    return (
        <>
            <h2>
                <img src="./images/virtual/fio_park.webp" alt="FUKUOKA IS OPEN PARK"/>
            </h2>
           
            <div className="virtual-inner">
                <p className="message al-l">
                फुकुओकाको वास्तविक खुल्ला ठाउँमा आधारित सिटी लिंक्ड मेटाभर्स "FUKUOKA IS OPEN PARK" मा फुकुओका प्रिफेक्चरको शहरको दृष्य, खानेकुरा, यातायातका साधन आदिको भर्चुअल अनुभव गरेर हेरौं।
                </p>
                <div className="fiopark">
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_01_title_en.webp" alt="FUKUOKA IS OPENパークの入口"/></dt>
                        <dd>
                        <p>फुकुओका प्रिफेक्चर / क्यूश्यूको केन्द्र "तेन्जिन क्षेत्र" मा अवस्थित हरियालीले भरिपूर्ण तेन्जिन च्यूओ पार्कमा, साथीहरुसँग जमघट भएर, आनन्द पूर्वक गफ गर्दै, भिडियो हेरेर रमाइलो गरौं। यहाँबाट विभिन्न संसारमा प्रस्थान गर्न सकिन्छ</p>
                            <figure>
                                <img src="./images/virtual/fio_park_01_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_02_title_en.webp" alt="FUKUOKA フルーツパーク"/></dt>
                        <dd>
                            <p>ऋतु अनुसारको फुकुओकामा फलेका फलफूल तथा सागपात टिपेर रमाइलो गरौं!</p>
                            <figure>
                                <img src="./images/virtual/fio_park_02_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    
                </div>
                <div className="fiopark-howto">
                    <div className="fiopark-howto-title">
                    <h3>FUKUOKA IS OPEN PARK<span> प्रयोग गर्ने तरिका</span></h3>
                    </div>
                    <div className="fiopark-howto-entrance">
                        <div><img src="./images/virtual/entrance_en.webp" alt="FUKUOKA IS OPEN 参加する"/></div>
                        <div><p>"Join" मा क्लिक गरी भाग लिन सकिन्छ</p></div>
                    </div>
                    <div className="fiopark-howto-app">
                        <div>
                            <p>अग्रिम रुपमा स्मार्टफोन वा ट्यावलेट, कम्प्युटर, VR सपोर्ट गर्ने विशेष डिभाइस आदिमा  "cluster" एप्लिकेसन डाउनलोड गर्नु आवश्यक छ</p>
                            
                            <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend pc" target="_blank">
                               <span>सिफारिस सफ्टवेयरको लागि यहाँ</span>
                            </a>
                        </div>
                        <div>
                            <h4>एप्लिकेसन भएका व्यक्तिले</h4>
                            <img src="./images/virtual/cluster01.webp" alt="clusterを起動" width={200}/>
                            <p>"cluster खोल्ने" क्लिक गरे पछि देखा पर्ने</p>
                            <img src="./images/virtual/cluster02.webp" alt="遊びに行く"/>
                            <p>"घुम्न जाने" मा क्लिक गरी, रुममा प्रवेश गर्ने</p> 
                        </div>
                        <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend sp" target="_blank">
                            <span> सिफारिस सफ्टवेयरको लागि यहाँ </span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

}

export default LangNe