import InnerBox from "@/components/Atoms/Box/innerBox"
import {FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"

const LangVi = () => {

    return (<>
    <InnerBox>
    <h1>Môi trường sống</h1>
    <p>
    Để tìm được nhà ở và an tâm sống tại Fukuoka, hãy hiểu rõ những điểm sau đây và vừa tiến hành các thủ tục cần thiết vừa tìm kiến sự hỗ trợ của các quầy tư vấn của chính quyền.
    </p>
    </InnerBox>
    <BlackNote>
    <ul className="deco">
    <li>
    <h4>１.Cách tìm kiếm nhà ở</h4>
    <p>
    ●Sử dụng các trang web và ứng dụng thông tin bất động sản để tìm kiếm nhà ở.<br/>
    ●Sử dụng các công ty bất động sản có hỗ trợ tiếng nước ngoài.
    </p>
    </li>
    <li>
    <h4>２.Các loại nhà cho thuê</h4>
    <p>
    ●Nhà ở tư nhân: Nhà thuê từ chủ nhà.<br/>
    ●Nhà ở công cộng: Nhà thuê từ tỉnh, thành phố...<br/>
    ●Nhà ở UR: Nhà thuê từ Tổ chức Phát triển Đô thị UR, không cần người bảo lãnh.
    </p>
    </li>
    <li>
    <h4>３.Thông tin cơ bản về hợp đồng thuê nhà</h4>
    <p>
    ●Hợp đồng thuê nhà thường bao gồm tiền đặt cọc (tiền đảm bảo), tiền lễ và tiền thuê nhà. Hãy chắc chắn rằng bạn hiểu hợp đồng và xác nhận các điều kiện.
    </p>
    </li>
    <li>
    <h4>４.Tiền đặt cọc và tiền lễ </h4>
    <p>
    ●Tiền đặt cọc sẽ được trả lại khi kết thúc hợp đồng, nhưng số tiền này có thể bị khấu trừ nếu bạn chậm trả tiền thuê nhà hoặc nếu có một phần phòng bị hư hỏng do vết bẩn. Tiền lễ sẽ không được trả lại.
    </p>
    </li>
    <li>
    <h4>５.Người bảo lãnh</h4>
    <p>
    ●Thường cần có người bảo lãnh khi ký hợp đồng. Người bảo lãnh sẽ chịu trách nhiệm nếu người thuê nhà không thể thanh toán tiền nhà. Ngoài ra, bạn cũng có thể sử dụng dịch vụ công ty bảo hiểm nợ tiền nhà thay cho người bảo lãnh.
    </p>
    </li>
    <li>
    <h4>６.Internet và chi phí điện nước</h4>
    <p>
    ●Nhiều nhà ở đã cài đặt kết nối internet. Chi phí ga điện nước thường phải thanh toán riêng biệt, không bao gồm trong tiền nhà.
    </p>
    </li>
    <li>
    <h4>７.Nhà có sẵn nội thất và thiết bị điện tử hay không</h4>
    <p>
    ●Tùy thuộc từng nhà mà có nhà được trang bị sẵn nội thất và thiết bị điện tử có nhà không. Hãy kiểm tra trước khi chuyển vào để biết mình có phải tự chuẩn bị hay không.
    </p>
    </li>
    <li>
    <h4>８.Đăng ký cư trú</h4>
    <p>
    ●Sau khi chuyển vào nhà, bạn cần đăng ký cư trú tại ủy ban hành chính.
    </p>
    </li>
    <li>
    <h4>9.Phong tục và quy tắc sinh hoạt</h4>
    <p>
    ●Trong cuộc sống tại Nhật Bản, có những phong tục và quy tắc sinh hoạt đặc biệt như không làm phiền đến hàng xóm, quy tắc phân loại rác... Hãy chú ý đến những điều này.
    </p>
    </li>
    </ul>
    </BlackNote>
    <InnerBox top={20} bottom={20}>
    <FukidashiRedLeft>
    Liên kết bên dưới cung cấp thông tin bằng nhiều ngôn ngữ hữu ích cho người nước ngoài đang tìm nhà ở, chẳng hạn như bảng chú giải các thuật ngữ thường gặp khi tìm nhà và Sách hướng dẫn tìm phòng.
    </FukidashiRedLeft>
    <h1>
    Trang chủ Cục quản lý nhập cư và lưu trú: Sổ tay Hướng dẫn cuộc sống và làm việc
    </h1>
    <ul className="links">
    <li>
    ●Tiếng Anh：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392830.pdf</a>
    </li>
    <li>
    ●Tiếng Trung：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392831.pdf</a>
    </li>
    <li>
    ●Tiếng Hàn：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001393145.pdf</a>
    </li>
    <li>
    ●Tiếng Việt：<a href="https://www.moj.go.jp">https://www.moj.go.j/isa/content/001393146.pdfp</a>
    </li>
    <li>
    ●Tiếng Thái： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●Tiếng Nepal： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●Tiếng Nhật dễ hiểu： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/930004715.pdf</a>
    </li>
    </ul>
    </InnerBox>
    <InnerBox bottom={20}>
    <h1>Hướng dẫn hỗ trợ ổn định chỗ ở cho người nước ngoài của Tổ chức công ích Hiệp hội quản lý nhà ở cho thuê Nhật Bản.</h1>
    <p>
    <a href="https://www.jpm.jp">https://www.jpm.jp/foreign/</a>
    </p>
    </InnerBox>
    </>)
}

export default LangVi