
const LangJa = () => {

    return (
        <>
                <h1>サイトポリシー</h1>
                <p>
                「FUKUOKA IS OPEN推進事業実行委員会（以下、「実行委員会」といいます。）」が提供するポータルサイト（https://www.fisop.net内のポータルサイトを指し、以下「ポータルサイト」といいます。）をご利用いただく方は、下記内容をお読みください。なお、下記内容は予告なく変更させていただく場合がございますので、あらかじめご了承ください。
                </p>
                <p>
                    <dl>
                        <dt>
                        ●免責事項について
                        </dt>
                        <dd>
                            <p>
                            「当ポータルサイトに掲載されている情報の内容に関しては、万全を期しておりますが、その内容の正確性及び安全性を保証するものではありません。実行委員会は利用者が当サイトに掲載されている情報によって被った損害、損失に対して一切の責任を負いません。
                            </p>
                            <p>
                            また、当ポータルサイト上から実行委員会以外が管理しているホームページにリンクしている場合がありますが、リンク先のホームページについては実行委員会が管理、運営するものではない場合があります。また、その内容の真偽等についても一切責任を負いかねますのでご了承ください。
                            </p>
                            <p>
                            なお、当ポータルサイトに掲載されている情報については、予告なしに変更又は削除する場合がありますのであらかじめご了承ください。
                            </p>
                        </dd>
                    </dl>
                </p>
                <p>
                    <dl>
                        <dt>
                        ●著作権について
                        </dt>
                        <dd>
                        当ポータルサイトに掲載されている文章、ロゴ、写真、動画、ソフトウェア、その他のすべての情報の著作権は、特に記載の無い限り、実行委員会または第三者が著作権を有しております。私的利用など著作権法によって認められている場合を除き、著作権者の事前の許可なく、これらの情報を利用（複製、改変、転載、配布、公衆送信等を含む）することはできません。また、一部の画像等の著作権は原著作者が有しています。
                        </dd>
                    </dl>
                </p>
                <p>
                    <dl>
                        <dt>
                        ●ご利用環境（ブラウザ）について
                        </dt>
                        <dd>
                            <p>
                            当ポータルサイトは、各種インターネット閲覧ソフト（ブラウザ）最新版でのご利用をおすすめします。
                            </p>
                            <p>
                            上記以外でご覧になる場合は、正しく表示されない場合がありますのであらかじめご了承ください。
                            </p>
                        </dd>
                    </dl>
                </p>
                <p>
                    <dl>
                        <dt>
                        ●頻繁にご覧になるページについて
                        </dt>
                        <dd>
                            <p>
                            インターネット閲覧ソフト（ブラウザ）で、以前閲覧した時点の情報（パソコン内に一時的に保存された情報）が表示されることがあります。
                            </p>
                            <p>
                            そのため、頻繁にご覧になるページについては、最新の情報に更新（再読み込み）することをお勧めします。
                            </p>
                        </dd>
                    </dl>
                </p>
                <p>
                    <dl>
                        <dt>
                        ●JavaScriptについて
                        </dt>
                        <dd>
                            <p>
                            当ポータルサイトでは、一部JavaScriptを使用しています。お使いのインターネットブラウザがJavaScriptに対応していない、または、JavaScriptを無効にしてお使いの場合には一部機能が動作いたしません。
                            </p>
                            <p>
                            全ての機能をご利用いただくには、JavaScriptに対応しているインターネットブラウザをご利用いただくか、インターネットブラウザの設定でJavaScriptを有効にしてください。
                            </p>
                        </dd>
                    </dl>
                </p>
                <p>
                    <dl>
                        <dt>
                        ●準拠法
                        </dt>
                        <dd>
                        このポータルサイトの利用ならびに「サイトポリシー」の解釈及び適用は、他に別段の定めがない限り、日本国の法律に準拠するものとする。<br/>
                        当ポータルサイトへのアクセスはアクセスされた方の自由意思によりなされたものであり、アクセスされた方の責任において当ポータルサイトをご利用ください。当ポータルサイトに関する一切の紛争については、福岡地方裁判所を第一審の専属的合意管轄裁判所とします。
                        </dd>
                    </dl>
                </p>
        </>
    )
}

export default LangJa