

const LangEn = () => {

    return (<>
    <h1>Privacy Policy</h1>
    <p>
    The Portal Site (<a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a>) handles the collection, use, and management of personal information appropriately as follows, based on the "Act on the Protection of Personal Information" and the "Fukuoka Prefecture Ordinance for Enforcement of the Act on the Protection of Personal Information (Fukuoka Prefecture Ordinance No. 43, 2022)". Please note that this description of the handling of personal information was updated on April 1, 2023.
    </p>
    <p>
    Top page of Fukuoka Prefecture's personal information protection system is here (<a href="http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html" target="_blank">http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html</a>)
    </p>
    <p>
    <dl>
    <dt>●What is personal information?</dt>
    <dd>
    Personal information is information about an individual that identifies or can identify a specific individual (address, name, telephone number, e-mail address, etc.).
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●Collection of Personal Information</dt>
    <dd>
    When collecting personal information through the Portal Site, the FUKUOKA IS OPEN Promotion Project Executive Committee (hereinafter referred to as the "Executive Committee") will clarify the purpose of collection and will collect personal information only to the extent necessary to achieve that purpose."
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●Ensuring Security</dt>
    <dd>
    Personal information collected will be strictly managed to prevent leakage, loss, falsification, etc. Personal information that is no longer required to be retained will be promptly disposed of (deleted)."
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●About Cookies</dt>
    <dd>
    <p>Some of the pages on the Portal Site use a technology called "cookies" to allow you to use certain pages.</p>
    <p>
    Cookies" are industry-standard technologies used by web servers to identify your computer and are actually small text files.
    </p>
    <p>
    In general, it is used to record personal information (user IDs and passwords) and operation histories provided to the site administrator by Portal Site users, analyze access conditions and trends, and use the information as statistical information.
    </p>
    <p>
    Cookies" can identify users' computers, but they cannot identify individual users unless they themselves enter or provide detailed personal information.
    </p>
    <p>
    The Portal Site can be viewed and used anonymously with or without the "cookie" function.
    </p>
    <p>
    Depending on your browser, you may be able to change its settings to disable the cookie function, but as a result, you may not be able to use all or part of the services on some pages.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●Restrictions on Use and Provision</dt>
    <dd>
    <p>
    Personal information collected by the Executive Committee will not be used for any purpose other than that for which it was collected, nor will it be provided to any party other than the Executive Committee, except as stipulated by the Act on the Protection of Personal Information or the Fukuoka Prefecture Ordinance for Enforcement of the Act on the Protection of Personal Information."
    </p>
    <p>
    However, we may release statistically processed data or opinions regarding access information on the Portal Site, suggestions and opinions to the Executive Committee, and personal attributes related to such suggestions and opinions.
    </p>
    </dd>
    </dl>
    </p>

    <p>
    <dl>
    <dt>●Notes on Social Media (SNS) Buttons</dt>
    <dd>
    <p>
    When you visit a page on this portal site that has social media (SNS) buttons, etc., information about you may be sent to the SNS concerned. Please check the privacy policy, etc. of the SNS services used on this portal site on the websites of the respective service providers."
    </p>
    </dd>
    </dl>
    </p>

    <p>
    <dl>
    <dt>●Notes on Google Analytics</dt>
    <dd>
    The Portal Site uses Google Analytics to monitor the use of the portal. Google Analytics uses cookies to collect information about users. Please read the Google Analytics Terms of Use<br/>
     (opens in new window <a href=" https://www.google.com/analytics/terms/jp.html" target="_blank">https://www.google.com/analytics/terms/jp.html </a>).
    </dd>
    </dl>
    </p>
    
    <p>
    <dl>
    <dt>●Scope of Policy </dt>
    <dd>
    This Privacy Policy applies only to the Portal Site (<a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a> and the following directories).
    </dd>
    </dl>
    </p>
    </>)
}

export default LangEn