
import styled from "styled-components"
import parse from 'html-react-parser';
import { replaceCotents } from "src/utilty/replaceContents";
import ArticleTemplate, { ArticleEmergencyTemplate } from "@/components/Templates/article"
import { ArticleObj, article, endpoint } from "src/types/article";
import { langType } from "src/types/lang";
import EducationVolunteer from "@/components/Pages/Lifestyle/FixedPage/educationVolunteer";
import ForeignPatientsMedical from "src/components/Pages/Lifestyle/FixedPage/foreignPatientsMedical";
import Howtofindhousing from "src/components/Pages/Lifestyle/FixedPage/howtofindhousing";

import { ArticleMutiLangTemplate } from "@/components/Templates/multilingual";
import { useCmsContext } from "@/context/CmsContext";
import { setTitleDiscrption } from "src/utilty/setTitleDiscription";
import { articleTitleSelect } from "src/utilty/articleTitleSelect";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { setOgUrl } from "src/utilty/setOgTag";
import { fetchArticle } from "src/data/fetch";
import { ICmsViewTrigger } from "../..";
import { layerType } from "src/types/layer";
import ArticleLoader from "@/components/Organisms/Article/articleLoader";


export const CmsEmergencyView = ({
    view,
}:{ view:ICmsViewTrigger }) => {
    
    const CmsContext = useCmsContext()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);

    const lang = CmsContext.pathAnalys.lang
    const endpoint: endpoint = view.endpoint ? view.endpoint : CmsContext.pathAnalys.layer
    
    const [article, setArticle] = useState<article>()

    //固定ページ
    const [isFixed, setIsFixed] = useState<string| undefined>();

    //const article = data[CmsContext.pathAnalys.lang]    
    
    useEffect(() => {

        const fetchAndSetArticle = async () => {
            
            const aritcleObj: ArticleObj = await fetchArticle(
                                                endpoint, 
                                                CmsContext.pathAnalys.lang, 
                                                view.id)
            
            //言語選択
            let selectArticle 
            if(aritcleObj[lang]){
                selectArticle = aritcleObj[lang]  
            }else{
                aritcleObj['en'] ? selectArticle = aritcleObj['en'] :  aritcleObj['ja'] ? selectArticle = aritcleObj['ja'] : selectArticle = null
            }
            
            setTitleDiscrption({
                layer: CmsContext.pathAnalys.layer,
                lang: CmsContext.pathAnalys.lang,
                subLayer: CmsContext.pathAnalys.category,
                articleTitle: articleTitleSelect(CmsContext.pathAnalys.lang ,aritcleObj),
                articleContents : selectArticle.content
            })
            setOgUrl({
                pathname:location.pathname,
                queryParams : queryParams
            })
            //固定ページ判定
            selectArticle.fixedpage ? setIsFixed( selectArticle.fixedpage) : setIsFixed(undefined)

    
            if(selectArticle.eyecatch){
                document.querySelector('meta[property="og:IMAGE"]').setAttribute("content", selectArticle.eyecatch.url)
            }{}

            setArticle(selectArticle)
        }

        fetchAndSetArticle()
        
    },[])
     

    return (
        <>
        { article &&
            <>
             <ArticleEmergencyTemplate>
                {parse(replaceCotents(article.content ? article.content : article.html))}
            </ArticleEmergencyTemplate>
            </>
        }
        {
            !article &&
            <ArticleLoader></ArticleLoader>
        }
        </>
    )
}


