
import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlueLeft } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { NavigatorFukidashiCheck } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"


const LangTh = () => {

    return (<>
    <InnerBox top={0} bottom={30} >
    <h1>เกี่ยวกับศูนย์แลกเปลี่ยนวัฒนธรรมระหว่างประเทศจังหวัดฟุกุโอกะ</h1>
    <p>
    ศูนย์แลกเปลี่ยนระหว่างประเทศจังหวัดฟุกุโอกะ ก่อตั้งเมื่อปี พ.ศ.2532 เป็นหน่วยงานในสังกัดของจังหวัดที่นำเอาลักษณะทางภูมิศาสตร์และประวัติศาสตร์ของจังหวัดฟุกุโอกะมาใช้ประโยชน์ในการส่งเสริมการแลกเปลี่ยนระหว่างประเทศของประชาชนในจังหวัดภายในความร่วมมือขององค์กรแลกเปลี่ยนในจังหวัด โดยมีวัตถุประสงค์เพื่อเพิ่มบทบาทความเป็นศูนย์กลางของจังหวัดในด้านการแลกเปลี่ยนระหว่างประเทศ อีกทั้งกระชับความสัมพันธ์กับประเทศและภูมิภาคต่างๆ ในภูมิภาคเอเชียและทั่วโลก  เพื่อส่งเสริมความเจริญรุ่งเรืองกันทั้งสองฝ่าย และอุทิศตนเพื่อการสร้างสันติภาพบนโลก
    </p>
    <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
    <InnerBox top={20} bottom={20} left={0} right={0}>
    <EmphasisNote>

    <p>
    นอกจากจะมีการจัดกิจกรรมต่างๆ เพื่อการพบปะแลกเปลี่ยนระหว่างชาวต่างชาติที่อาศัยในญี่ปุ่นและชาวญี่ปุ่นท้องถิ่น เช่น การสัมนา การบรรยายและอื่นๆ แล้วยังมี"ศูนย์ให้คำปรึกษาชาวต่างชาติจังหวัดฟุกุโอกะ" ที่ให้บริการให้คำปรึกษาช่วยแก้ปัญหาให้กับชาวต่างชาติใน 22 ภาษารวมถึงภาษาญี่ปุ่น มีการรับสมัครอาสาสมัคร และแชร์ข้อมูลเกี่ยวกับการใช้ชีวิตเช่น ข้อมูลเกี่ยวกับภัยพิบัติ
    </p>
    <p>
    ด้านการแลกเปลี่ยนกับต่างประเทศ จังหวัดได้ดำเนินการแลกเปลี่ยนกับฮาวาย (สหรัฐอเมริกา) มณฑลเจียงซู (จีน) และกรุงเทพฯ (ไทย) ซึ่งได้ลงนามความสัมพันธ์ฉันมิตรกับจังหวัดฟุกุโอกะ
    </p>
    <p>
    นอกจากนี้ก็มีการจัดการศึกษาเพื่อส่งเสริมการเรียนรู้เข้าใจในต่างประเทศ เช่น การส่งชาวต่างชาติไปเป็นผู้สอนในโรงเรียนและศูนย์ชุมชนภายในจังหวัด พร้อมทั้งสนับสนุนการแลกเปลี่ยนระดับพื้นฐานที่ดำเนินการโดยองค์กรแลกเปลี่ยนระหว่างประเทศของเอกชนและห้องเรียนภาษาญี่ปุ่นในท้องถิ่น
    </p>
    <p>
    อีกทั้งยังมีสำนักงานศูนย์ช่วยเหลือนักษาต่างชาติจังหวัดฟุกุโอกะที่จัดตั้งโดยภาคเอกชน สถาบันการศึกษาและรัฐบาลเพื่อดำเนินการช่วยเหลือนักศึกษาต่างชาติในภาพรวม
    </p>
    </EmphasisNote>
    </InnerBox>
    <p>
    【ช่องทางการติดต่อ】<br/>
    〒810-0001<br/>
    อาคาร ACROS Fukuoka ชั้น 8 และชั้น  1-1-1 Tenjin, Chuo-ku, Fukuoka City, Fukuoka Prefecture<br/>
    ชั้น 8　TEL：092-725-9204　FAX：092-725-9205 8:30~17:45（ยกเว้นวันเสาร์อาทิตย์และวันหยุดนักขัตฤกษ์）<br/>
    ชั้น 3 (Kokusai Hiroba) TEL: 092-725-9200 FAX: 092-725-9206 10:00~18:30 (ยกเว้นวันหยุดสิ้นปีและปีใหม่) <br/>
    <br/>
    </p>

    <div className="custom-link">
    <FukidashiRedLeft>เกี่ยวกับศูนย์แลกเปลี่ยนวัฒนธรรมระหว่างประเทศจังหวัดฟุกุโอกะ</FukidashiRedLeft>

    <a href="https://kokusaihiroba.or.jp/" target="_blank">
    <img src={publicPath('images/fief.png')} className="img-fit" alt="เกี่ยวกับศูนย์แลกเปลี่ยนวัฒนธรรมระหว่างประเทศจังหวัดฟุกุโอกะ"/>
    </a>
    </div>

    </InnerBox>
    <InnerBox top={20} bottom={20}>
    <h1>
    ศูนย์ให้คำปรึกษาแก่ชาวต่างชาติจังหวัดฟูคูโอคะ
    </h1>

    <div className="sp-only">
    <NavigatorFukidashiCheck dir="left">
    <span className="txt-yellow">
    ลำบากกับการใช้ชีวิตที่ฟูคูโอคะอยู่หรือไม่ ?<br/>
    แก้ไขความกังวลใจของทุกท่านด้วยหลากหลายภาษาที่ศูนย์ให้คำปรึกษาแก่ชาวต่างชาติ
    </span>
    </NavigatorFukidashiCheck>
    </div>

    <br/>
    <p>
    ในการให้ความช่วยเหลือในการใช้ชีวิตของชาวต่างชาติ ศูนย์ให้คำปรึกษาแก่ชาวต่างชาติจังหวัดฟูคูโอคะ ได้รับรองการให้คำปรึกษาต่าง ๆ ทั้งหมด 22 ภาษา อาทิ เรื่องกลุ้มใจที่เกิดขึ้นจากการใช้ชีวิต เช่น การจ้างงาน แรงงาน การรักษาพยาบาล ที่อยู่อาศัย เป็นต้น การดำเนินการเกี่ยวกับวีซ่า และการปรึกษาเกี่ยวกับข้อกฎหมาย
    </p>
    <div className="pc-only">
            <div className="pc-flex col-2">
                <div>
                    <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
                </div>
                <div className="center">
                    <FukidashiBlueLeft>
                        <span className="txt-yellow">
                        ลำบากกับการใช้ชีวิตที่ฟูคูโอคะอยู่หรือไม่ ?<br/>
                        แก้ไขความกังวลใจของทุกท่านด้วยหลากหลายภาษาที่ศูนย์ให้คำปรึกษาแก่ชาวต่างชาติ
                        </span>
                    </FukidashiBlueLeft>
                </div>
            </div>
        </div>
    <br/>
    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi navigatorCheck right pointing">
            ถ้ามีเรื่องที่อยากรู้หรือเรื่องกลุ้มใจ กรุณาโทรศัพท์หรือมายังเคาน์เตอร์รับเรื่องโดยไม่ต้องคิดมาก<br/>
            สามารถให้คำปรึกษาด้วยหลากหลายภาษา (ไม่มีค่าใช้จ่าย)
            </div>
        </div>
        <div>
            <figure>
                <img src={publicPath('images/international01.png')} className="img-fit" alt=""/>
            </figure>
            <figure className="pc-only">
                <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
            </figure>
        </div>
    </div>
    
    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi challengerQ left thinking">
            อยากจะขยายระยะเวลาวีซ่าอาศัยอยู่ในญี่ปุ่น ควรทำอย่างไรดี ?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right puzzled">
            มีโรงพยาบาลที่รับรองภาษาของประเทศบ้านเกิดของตัวเองหรือไม่ ?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            เกิดปัญหากับเจ้าของบ้าน ปรึกษาที่ไหนถึงจะดี ?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            จำเป็นต้องใช้ทะเบียนผู้อยู่อาศัย ขอรับได้จากที่ไหน ?
            </div>
        </div>
    </div>

    <figure>
    <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
    </figure>
    <InnerBox top={20} bottom={20} left={0} right={0}>
    <EmphasisNote>
    <h2>
    ภาษาที่รับรอง
    </h2>
    <p>
    ภาษาญี่ปุ่น ภาษาอังกฤษ ภาษาจีน ภาษาเกาหลีใต้ ภาษาเวียดนาม ภาษาเนปาล ภาษาอินโดนีเซีย ภาษาตากาล็อก ภาษามาเลย์ ภาษาสเปน ภาษาฝรั่งเศส ภาษาเยอรมัน ภาษาอิตาลี ภาษารัสเซีย ภาษาโปรตุกส ภาษาเมียร์มา ภาษาเขมร ภาษามองโกเลีย ภาษาสิงหล ภาษาฮินดี และภาษาเบงกาลี
    </p>
    </EmphasisNote>
    </InnerBox>
    <p>
    อาคาร Acros Fukuoka ชั้น 3 เลขที่ 1 - 1 - 1 เทนจิน เขตชูโอ เมืองฟูคูโอคะ<br/>
    ภายในศูนย์แลกเปลี่ยนระหว่างประเทศจังหวัดฟูคูโอคะ <br/>
    (มูลนิธิเพื่อประโยชน์สาธารณะ)
    </p>
    <FukidashiRedLeft>
    0120 - 279 - 906
    </FukidashiRedLeft>
    <div className="pc-flex col-2">
        <p>
            <a href="https://kokusaihiroba.or.jp/" target="_blank">
                <img src={publicPath('images/fief.png')} className="img-fit" alt="公益財団法人福岡県国際交流センター"/>
            </a>
        </p>
        <p>
            <a href="https://www.fissc.net/" target="_blank">
                <img src={publicPath('images/ficc.png')} className="img-fit" alt="FiSSC"/>
            </a>
        </p>
    </div>
    <NavigatorAction actionType="goodluck" charactorType="good" dir="right"></NavigatorAction>

    </InnerBox>
    </>)
}

export default LangTh