import { PropsWithChildren } from "react"
import { direction } from "src/types/common"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"


interface FikidashiProps { 
    dir : direction
}

const SFukidashi = styled.div`

    position: relative;
    color: #fff;
    font-size: ${props=>props.theme.fontSize.fukudashi_sp};
    font-weight: bold;
    span, p {
        font-size: ${props=>props.theme.fontSize.fukudashi_sp};
        font-weight: bold;
    }
    padding:0px 30px ;
    margin-top: 26%;
    margin-bottom: 16%;
    
    &::before, &::after{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
    }

    &.left{
        &::before{
            background: url(${publicPath('images/fukidashi/fukidashiLeft_top.png')}) no-repeat;
            background-size: 100% auto;
            padding-bottom: 26%;
            transform: translateY(-99%);
        }
        &::after{
            background: url(${publicPath('images/fukidashi/fukidashiLeft_bottom.png')}) no-repeat;
            background-size: 100% auto;
            bottom: 0;
            padding-bottom:14%;
            transform: translateY(98%);
        }
        background: url(${publicPath('images/fukidashi/fukidashiLeft_main.png')});
        background-size: 100% 100%;
    }
    &.right{
        &::before{
            background: url(${publicPath('images/fukidashi/fukidashiRight_top.png')}) no-repeat;
            background-size: 100% auto;
            padding-bottom: 26%;
            transform: translateY(-99%);
        }
        &::after{
           
            background: url(${publicPath('images/fukidashi/fukidashiRight_bottom.png')}) no-repeat;
            background-size: 100% auto;
            bottom: 0;
            padding-bottom:14%;
            transform: translateY(98%);
        }
      
        background: url(${publicPath('images/fukidashi/fukidashiRight_main.png')});
        background-size: 100% 100%;
        
    }
    
`

export const FukidashiBlue = ({
    dir,
    children
}:PropsWithChildren<FikidashiProps>) => {

    
    return (
        <SFukidashi className={dir}>
            {children}
        </SFukidashi>
    )

}

export const FukidashiBlueBottom =  ({
    dir,
    children
}:PropsWithChildren<FikidashiProps>) => {

    const SFukidashiBottom = styled.div`
        color: #fff;
        font-size: ${props=>props.theme.fontSize.fukudashi_sp};
        font-weight: bold;
        line-height: ${props => props.theme.lineheight.fukudashi_sp};
        font-weight: bold;
        background: #036EB7;
        background: url(${publicPath('images/fukidashi/fukidashiBottomRight_main.png')});
        background-size: 100% 100%;
        padding:10px 30px 0;
        display: block;
        margin-bottom:calc(26% + 10px);
        margin-top: calc(8% + 10px);
        position: relative;
        z-index: 10;
        &.right{
            &::after{
                content: '';
                width: 100%;
                padding-bottom: 20%;
                position: absolute;
                bottom: 0;
                transform: translateY(98%);
                left: 0;
                background: url(${publicPath('images/fukidashi/fukidashiBottomRight_bottom.png')}) no-repeat;
                background-size: 100% auto;
            }
            &::before{
                content: '';
                width: 100%;
                aspect-ratio: 726 / 63;
                position: absolute;
                top: 0;
                transform: translateY(-98%);
                left: 0;
                background: url(${publicPath('images/fukidashi/fukidashiBottomRight_top.png')}) no-repeat;
                background-size: 100% auto;
            }
        }
    `

    return (
        <SFukidashiBottom className={dir}>
            {children}
        </SFukidashiBottom>
    )

}

export const FukidashiBlueLeft =  ({
    children
}:PropsWithChildren) => {
    
    const SFukidashiBlueLeft = styled.div`
        position: relative;
        color: #fff;
        font-size: ${props=>props.theme.fontSize.fukudashi_sp};
        font-weight: bold;
        span, p {
            font-size: ${props=>props.theme.fontSize.fukudashi_sp};
            font-weight: bold;
        }
        padding:0 30px;
        min-height: 200px;
        margin-left: 10%;
        margin-right: 10%;
        background: url(${publicPath('images/fukidashi/left/fukudashi_left02.png')});
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        &::before, &::after{
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
        }
        &::before{
            background: url(${publicPath('images/fukidashi/left/fukudashi_left01.png')}) no-repeat;
            background-size: 100% 100%;
            width: 20%;
            height: 100%;
            left: -18%;
        }
        &::after{
           
            background: url(${publicPath('images/fukidashi/left/fukudashi_left03.png')}) no-repeat;
            background-size: 100% 100%;
            width: 20%;
            height: 100%;
            left: 100%;
       }
    `
    return (
        <SFukidashiBlueLeft>
            <div className="center-positon">
                {children}
            </div>
        </SFukidashiBlueLeft>
    )
}