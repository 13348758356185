
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import { AreaMap } from "./areaMap"
const SSvg = styled.div``

const SOverride = styled.div`
@media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
(max-width: ${props => props.theme.responsive.minPc}) ),
screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
    .fukidashiPosition{
        &.kitakyu{
            top: 18% !important;
        }
        &.chikuhou{
            bottom: 46% !important;
        }
        &.chikugo{
            bottom: 42% !important;
        }
    }
}
`

const LangTh = ({onSwich, onFukidashi}) => {

    return (
        <SOverride>
            <div className="area-info">
            <SSvg>
                <figure className="map">
                <svg id="_レイヤー_1" data-name="レイヤー 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.92 286.92">
                    <rect className="fukuoka" onClick={()=> {onSwich(4)}} x="11.44" y="58.23" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(97.1 140.13) rotate(180)"/>
                    <g onClick={()=> {onSwich(4)}}>
                        <path className="moji" d="M21.27,74.94h-1.13l-1.48-4.5c-.07-.22-.16-.56-.27-1.02-.11.47-.2.81-.27,1.02l-1.49,4.5h-1.11v-4.74c-.12.04-.24.06-.36.06-.28,0-.53-.1-.74-.31-.21-.21-.31-.45-.31-.73s.1-.53.31-.73c.21-.21.45-.31.74-.31.42,0,.75.15.99.44.24.29.36.7.36,1.22v3.58c.24-.82.39-1.3.43-1.44l1.19-3.74h.53c.98,3.04,1.52,4.77,1.63,5.17v-5.17h.98v6.71ZM15.16,69.58c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.26s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11s-.18.04-.26.11c-.07.07-.11.16-.11.25s.04.18.11.26c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M21.16,67.52h-4.66v-.12c0-.52.16-.96.47-1.31s.82-.54,1.5-.54c.22,0,.44.02.65.07v-.37h.7v.65c.24.14.45.3.63.5v-1.15h.7v2.28ZM20.07,66.88c-.33-.46-.86-.69-1.59-.69-.34,0-.61.06-.82.19-.21.13-.34.29-.38.5h2.79Z"/>
                        <path className="moji" d="M22.8,62.71c-.21.53-.58.96-1.13,1.3-.55.34-1.15.51-1.81.51h-.44v-.47c.22-.17.39-.38.51-.63-.19,0-.36-.07-.5-.21s-.21-.3-.21-.5.07-.36.21-.5c.14-.14.3-.21.5-.21.23,0,.43.08.59.22.16.15.24.36.24.63,0,.31-.17.65-.51,1.02,1.01-.14,1.69-.73,2.05-1.76l.5.58ZM19.94,62.99c.08,0,.14-.03.19-.08.05-.05.08-.12.08-.19s-.03-.13-.08-.19c-.05-.05-.11-.08-.19-.08-.07,0-.13.03-.19.08-.05.05-.08.12-.08.19s.03.14.08.19c.05.05.12.08.19.08Z"/>
                        <path className="moji" d="M25.89,74.94h-1.03v-4.74c-.12.04-.24.06-.36.06-.29,0-.53-.1-.74-.31-.21-.21-.31-.45-.31-.73s.1-.53.31-.73.45-.31.74-.31c.43,0,.76.14.99.43.23.29.35.7.35,1.23v4.25c.87-.67,1.66-1.15,2.4-1.43v-4.42h.98v4.34c.43.07.77.23,1.03.48.25.25.38.54.38.87s-.09.61-.28.8c-.19.19-.44.29-.75.29-.91,0-1.36-.56-1.36-1.67-.73.32-1.51.86-2.35,1.61ZM24.5,68.85c-.1,0-.19.04-.26.11-.07.07-.11.16-.11.25s.04.18.11.26c.07.07.16.11.26.11s.18-.04.25-.11c.07-.07.11-.16.11-.26s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11ZM29.23,73.23v.57c0,.16.04.29.11.38.07.09.16.14.27.14.09,0,.17-.04.24-.12.07-.08.11-.18.11-.29,0-.16-.07-.3-.2-.43-.13-.13-.31-.21-.53-.25Z"/>
                        <path className="moji" d="M38.28,74.94h-.98v-4.85c0-.35-.05-.61-.14-.8-.09-.19-.21-.28-.36-.28-.17,0-.35.15-.55.45-.2.3-.39.69-.56,1.19l-1.52,4.29h-1.1v-4.74c-.12.04-.24.06-.36.06-.29,0-.53-.1-.74-.31-.21-.21-.31-.45-.31-.73s.1-.53.31-.73c.21-.21.45-.31.74-.31.43,0,.76.14.99.43.23.29.35.7.35,1.23v3.47l1.11-3.08c.17-.46.34-.84.53-1.15.19-.3.39-.53.61-.68.22-.15.47-.22.74-.22.39,0,.69.17.91.5.22.33.33.81.33,1.42v4.85ZM32.71,69.58c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.26s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11-.1,0-.19.04-.26.11s-.11.16-.11.25.04.18.11.26.16.11.26.11Z"/>
                        <path className="moji" d="M38.16,67.52h-4.66v-.12c0-.52.16-.96.47-1.31s.82-.54,1.5-.54c.77,0,1.43.28,1.98.85v-1.15h.7v2.28ZM37.07,66.88c-.33-.46-.86-.69-1.59-.69-.33,0-.61.06-.82.19-.21.13-.34.29-.38.5h2.79Z"/>
                        <path className="moji" d="M38.47,64.53h-.7v-2.1h.7v2.1Z"/>
                        <path className="moji" d="M47.61,74.94h-1.13l-1.48-4.5c-.07-.22-.16-.56-.27-1.02-.11.47-.2.81-.27,1.02l-1.49,4.5h-1.11v-4.74c-.12.04-.24.06-.36.06-.28,0-.53-.1-.74-.31-.21-.21-.31-.45-.31-.73s.1-.53.31-.73c.21-.21.45-.31.74-.31.42,0,.75.15.99.44.24.29.36.7.36,1.22v3.58c.24-.82.39-1.3.43-1.44l1.19-3.74h.53c.98,3.04,1.52,4.77,1.63,5.17v-8.53h.98v10.07ZM41.5,69.58c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.26s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11s-.18.04-.26.11c-.07.07-.11.16-.11.25s.04.18.11.26c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M47.79,77.44h-.71v-.68c-.19,0-.36-.07-.5-.21-.14-.14-.21-.3-.21-.5s.07-.35.21-.49c.14-.14.3-.21.5-.21s.36.07.5.21c.14.14.21.3.21.49v1.39ZM47.09,76.32c.07,0,.13-.03.19-.08.05-.05.08-.11.08-.19,0-.07-.03-.13-.08-.19-.05-.05-.12-.08-.19-.08s-.13.03-.19.08c-.05.05-.08.12-.08.19,0,.08.03.14.08.19.05.05.12.08.19.08Z"/>
                        <path className="moji" d="M55.43,74.94h-.98v-4.5c0-.36-.17-.67-.52-.94-.35-.27-.79-.41-1.32-.41-.37,0-.7.06-.99.19-.29.13-.5.29-.63.49.61.11,1.03.42,1.24.91-.36.08-.64.24-.84.46-.2.23-.29.5-.29.81v3h-.98v-2.96c0-.68.32-1.11.95-1.31-.11-.16-.53-.24-1.27-.24,0-.63.26-1.17.78-1.6.52-.44,1.2-.65,2.05-.65s1.53.21,2.05.63c.52.42.78.95.78,1.57v4.56Z"/>
                        <path className="moji" d="M55.62,77.44h-.71v-.68c-.19,0-.36-.07-.5-.21-.14-.14-.21-.3-.21-.5s.07-.35.21-.49.3-.21.5-.21.36.07.5.21c.14.14.21.3.21.49v1.39ZM54.91,76.32c.07,0,.13-.03.19-.08.05-.05.08-.11.08-.19,0-.07-.03-.13-.08-.19-.05-.05-.12-.08-.19-.08s-.13.03-.19.08c-.05.05-.08.12-.08.19,0,.08.03.14.08.19.05.05.12.08.19.08Z"/>
                        <path className="moji" d="M62.39,73.96c0,.28-.1.53-.31.73-.21.21-.45.31-.74.31-.43,0-.76-.14-.99-.43-.24-.29-.35-.7-.35-1.23v-4.65c0-.6-.09-.99-.28-1.17s-.46-.28-.82-.28c-.44,0-.96.1-1.57.29-.03-.23-.05-.42-.05-.59,0-.66.19-1.16.56-1.52.37-.36.91-.53,1.63-.53l2.01.08c.42,0,.76-.03,1.02-.1l.11.82c-.24.08-.61.11-1.11.11l-2.01-.07c-.38,0-.68.07-.9.22-.22.15-.34.34-.36.58.26-.09.54-.14.84-.14.72,0,1.22.16,1.51.47.28.31.42.92.42,1.82v4.28c.12-.04.24-.06.36-.06.29,0,.53.1.74.31.21.21.31.45.31.73ZM61.7,73.96c0-.09-.04-.18-.11-.25-.07-.07-.16-.11-.26-.11-.09,0-.18.04-.25.11-.07.07-.11.16-.11.25s.04.18.11.26c.07.07.16.11.25.11.1,0,.19-.04.26-.11.07-.07.11-.16.11-.26Z"/>
                        <path className="moji" d="M69.01,73.72c0,.54-.09.88-.27,1.02-.18.14-.55.21-1.12.21h-2.28c-.57,0-.94-.07-1.11-.21-.18-.14-.27-.48-.27-1.02v-1.2c0-.51.12-.91.35-1.21.23-.3.56-.45,1-.45.28,0,.53.1.73.31.2.21.31.45.31.73s-.1.53-.31.73c-.21.21-.45.31-.73.31-.12,0-.24-.02-.37-.06v.87c0,.24.11.36.32.36h2.45c.21,0,.32-.12.32-.36v-3.13c0-.48-.12-.85-.38-1.13-.25-.27-.71-.41-1.36-.41-1.02,0-1.63.39-1.84,1.16l-.96-.13c.28-1.29,1.27-1.94,2.96-1.94.87,0,1.52.19,1.94.56.42.38.63.98.63,1.81v3.17ZM65.31,72.26c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.25s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11s-.18.04-.26.11c-.07.07-.11.16-.11.25s.04.18.11.25c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M76.53,74.94h-.98v-4.5c0-.36-.17-.67-.52-.94-.35-.27-.79-.41-1.32-.41-.37,0-.7.06-.99.19-.29.13-.5.29-.63.49.61.11,1.02.42,1.24.91-.36.08-.64.24-.84.46-.2.23-.3.5-.3.81v3h-.98v-2.96c0-.68.32-1.11.95-1.31-.11-.16-.53-.24-1.27-.24,0-.63.26-1.17.78-1.6.52-.44,1.2-.65,2.05-.65s1.53.21,2.05.63c.52.42.78.95.78,1.57v4.56Z"/>
                        <path className="moji" d="M82.81,69.03c-.15.66-.45,1.17-.91,1.53-.46.36-1.03.54-1.72.54-.56,0-1.01-.13-1.37-.4-.36-.26-.54-.59-.54-.98,0-.26.09-.49.28-.68.19-.19.41-.28.68-.28s.49.09.68.28.28.41.28.67c0,.29-.1.52-.31.71.06.02.15.04.3.04.54,0,.98-.12,1.31-.35.33-.24.55-.59.67-1.07h.66ZM82.81,72.27c-.15.67-.45,1.18-.91,1.54-.46.36-1.03.54-1.72.54-.56,0-1.01-.13-1.37-.4-.36-.26-.54-.59-.54-.98,0-.27.09-.5.28-.69.19-.19.41-.28.68-.28s.49.09.68.28.28.41.28.67c0,.29-.1.52-.31.71.06.02.15.04.3.04.54,0,.98-.12,1.31-.36.33-.24.55-.6.67-1.08h.66ZM79.23,70.03c.08,0,.16-.03.22-.09s.1-.14.1-.23-.03-.16-.1-.22c-.06-.06-.14-.09-.22-.09s-.16.03-.22.09c-.06.06-.09.14-.09.22s.03.16.09.23c.06.06.14.09.22.09ZM79.23,73.28c.08,0,.16-.03.22-.09.06-.06.1-.13.1-.22,0-.08-.03-.16-.1-.22s-.14-.09-.22-.09-.16.03-.22.09c-.06.06-.09.14-.09.22,0,.09.03.16.09.22.06.06.14.09.22.09Z"/>
                    </g>
                    <rect className="kitakyu" onClick={()=> {onSwich(1)}} x="189.13" y="10.6" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(452.46 44.87) rotate(180)"/>
                    <g onClick={()=> {onSwich(1)}}>
                        <path className="moji" d="M198.35,27.31h-1.13l-1.48-4.5c-.08-.23-.16-.56-.27-1.02-.11.47-.2.8-.27,1.02l-1.49,4.5h-1.11v-4.74c-.12.04-.24.06-.36.06-.29,0-.53-.1-.74-.31-.21-.21-.31-.45-.31-.73s.1-.53.31-.73c.21-.21.45-.31.74-.31.42,0,.75.15.99.44.24.29.36.7.36,1.22v3.58c.24-.82.39-1.3.43-1.44l1.19-3.74h.53c.98,3.04,1.52,4.77,1.63,5.17v-5.17h.98v6.71ZM192.24,21.95c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.26s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11-.1,0-.19.04-.26.11-.07.07-.11.16-.11.25s.04.18.11.26c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M198.24,19.89h-4.66v-.12c0-.52.16-.96.47-1.31.32-.36.82-.54,1.5-.54.22,0,.44.02.65.07v-.37h.7v.65c.24.14.45.3.63.5v-1.15h.71v2.28ZM197.14,19.25c-.33-.46-.86-.69-1.59-.69-.33,0-.61.06-.82.19-.21.13-.34.29-.38.5h2.79Z"/>
                        <path className="moji" d="M199.88,15.08c-.2.53-.58.96-1.13,1.31s-1.15.51-1.81.51h-.44v-.47c.22-.17.39-.38.51-.63-.19,0-.36-.07-.5-.21-.14-.14-.21-.3-.21-.5s.07-.36.21-.5.3-.21.5-.21c.23,0,.43.07.59.22.16.15.24.36.24.63,0,.31-.17.65-.51,1.02,1.01-.14,1.69-.73,2.05-1.76l.5.58ZM197.02,15.36c.07,0,.14-.03.19-.08.05-.05.08-.11.08-.19,0-.07-.03-.13-.08-.19-.05-.05-.12-.08-.19-.08s-.13.03-.19.08c-.05.05-.08.12-.08.19,0,.08.03.14.08.19.05.05.12.08.19.08Z"/>
                        <path className="moji" d="M202.97,27.31h-1.03v-4.74c-.12.04-.24.06-.36.06-.28,0-.53-.1-.74-.31-.21-.21-.31-.45-.31-.73s.1-.53.31-.73c.21-.21.45-.31.74-.31.43,0,.76.15.99.44.23.29.35.7.35,1.23v4.25c.86-.67,1.66-1.14,2.4-1.43v-4.42h.98v4.34c.43.07.77.23,1.03.48.25.25.38.54.38.87s-.09.61-.28.8c-.19.19-.44.29-.75.29-.91,0-1.36-.56-1.36-1.67-.73.32-1.51.86-2.35,1.61ZM201.58,21.23c-.1,0-.18.04-.26.11s-.11.16-.11.25.04.18.11.26c.07.07.16.11.26.11s.18-.04.25-.11c.07-.07.11-.16.11-.26s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11ZM206.3,25.6v.57c0,.16.04.29.11.38.07.09.16.14.27.14.09,0,.17-.04.24-.12.07-.08.11-.18.11-.29,0-.16-.07-.3-.2-.43-.13-.13-.31-.21-.53-.25Z"/>
                        <path className="moji" d="M215.35,27.31h-.98v-4.85c0-.35-.04-.61-.14-.8-.09-.19-.21-.28-.36-.28-.17,0-.35.15-.55.45-.2.3-.39.69-.56,1.19l-1.52,4.29h-1.1v-4.74c-.12.04-.24.06-.36.06-.28,0-.53-.1-.74-.31-.21-.21-.31-.45-.31-.73s.1-.53.31-.73c.21-.21.45-.31.74-.31.43,0,.76.15.99.44s.35.7.35,1.23v3.47l1.11-3.09c.17-.46.34-.84.53-1.15.19-.3.39-.53.61-.68.22-.15.47-.22.74-.22.39,0,.69.17.91.5.22.33.33.8.33,1.42v4.85ZM209.78,21.95c.1,0,.18-.04.25-.11.07-.07.11-.16.11-.26s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11s-.18.04-.26.11-.11.16-.11.25.04.18.11.26c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M215.24,19.89h-4.66v-.12c0-.52.16-.96.47-1.31.32-.36.82-.54,1.5-.54.77,0,1.43.28,1.98.85v-1.15h.7v2.28ZM214.15,19.25c-.33-.46-.86-.69-1.59-.69-.34,0-.61.06-.82.19-.21.13-.34.29-.38.5h2.79Z"/>
                        <path className="moji" d="M215.54,16.9h-.7v-2.1h.7v2.1Z"/>
                        <path className="moji" d="M223.59,27.31h-.98v-4.51c0-.38-.18-.7-.55-.96-.37-.26-.81-.39-1.32-.39-.55,0-1.02.14-1.4.43-.38.29-.58.66-.58,1.12,0,.38.07.82.21,1.32.05.16.08.28.09.34.12-.61.31-1.09.6-1.43.28-.34.62-.51,1-.51.28,0,.53.1.73.31.21.21.31.45.31.73s-.1.53-.31.73c-.21.2-.45.31-.73.31-.32,0-.57-.12-.76-.36-.36.32-.53.77-.53,1.34v1.52h-.98v-.98c0-.33-.1-.9-.3-1.69-.2-.79-.3-1.35-.3-1.68,0-.64.26-1.2.78-1.69.52-.49,1.23-.73,2.14-.73s1.57.21,2.09.64c.52.42.78.96.78,1.6v4.54ZM220.67,24.13c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.25s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11s-.18.04-.26.11-.11.16-.11.25.04.18.11.25c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M223.52,19.89h-4.66v-.12c0-.52.16-.96.47-1.31s.82-.54,1.5-.54c.59,0,1.12.17,1.59.51.47.34.83.83,1.1,1.47ZM222.43,19.25c-.33-.46-.86-.69-1.59-.69-.33,0-.61.06-.82.19-.21.13-.34.29-.38.5h2.79Z"/>
                        <path className="moji" d="M231.57,27.31h-.98v-4.51c0-.54-.29-.98-.87-1.32l-1.01.87-1.01-.86c-.65.29-.97.81-.97,1.58,0,.23.09.69.27,1.37.19.72.28,1.21.28,1.47.05-.08.19-.23.41-.44.32-.31.51-.54.59-.68-.23-.08-.41-.2-.54-.39-.14-.19-.2-.39-.2-.62,0-.28.1-.53.31-.73.21-.21.45-.31.73-.31s.52.1.73.31c.21.21.31.45.31.73v.05c0,.28-.08.57-.24.88-.16.31-.53.75-1.1,1.31-.41.4-.67.68-.76.85-.1.17-.15.32-.15.45h-1.01v-.97c0-.36-.1-.91-.3-1.64-.2-.73-.3-1.28-.3-1.65,0-1.19.65-2.03,1.94-2.52l1.01.87,1.01-.88c.55.22,1,.53,1.34.93s.51.84.51,1.33v4.54ZM228.59,24.13c.1,0,.18-.04.25-.11s.11-.16.11-.25-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11s-.18.04-.26.11c-.07.07-.11.16-.11.25s.04.18.11.25c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M237.86,21.4c-.15.66-.45,1.17-.91,1.53-.46.36-1.03.54-1.72.54-.56,0-1.01-.13-1.37-.4-.36-.26-.54-.59-.54-.98,0-.26.09-.49.28-.68.19-.19.41-.29.68-.29s.49.1.67.29.29.41.29.67c0,.29-.1.52-.31.7.06.02.15.04.3.04.54,0,.98-.12,1.3-.36.33-.24.55-.59.67-1.07h.66ZM237.86,24.64c-.15.67-.45,1.18-.91,1.54-.46.36-1.03.54-1.72.54-.56,0-1.01-.13-1.37-.4-.36-.26-.54-.59-.54-.98,0-.27.09-.5.28-.69.19-.19.41-.28.68-.28s.49.09.67.28.29.41.29.67c0,.29-.1.52-.31.71.06.02.15.04.3.04.54,0,.98-.12,1.3-.36.33-.24.55-.6.67-1.08h.66ZM234.28,22.4c.08,0,.16-.03.22-.09s.09-.14.09-.22-.03-.16-.09-.22c-.06-.06-.14-.09-.22-.09s-.16.03-.22.09c-.06.06-.09.14-.09.22s.03.16.09.22c.06.06.14.09.22.09ZM234.28,25.65c.08,0,.16-.03.22-.09.06-.06.09-.14.09-.22s-.03-.16-.09-.22-.14-.09-.22-.09-.16.03-.22.09c-.06.06-.09.14-.09.22s.03.16.09.22c.06.06.14.09.22.09Z"/>
                        <path className="moji" d="M245,27.31h-.98v-4.51c0-.38-.18-.7-.55-.96-.37-.26-.81-.39-1.32-.39-.55,0-1.02.14-1.4.43-.38.29-.58.66-.58,1.12,0,.38.07.82.21,1.32.05.16.08.28.1.34.11-.61.31-1.09.6-1.43.28-.34.62-.51,1-.51.28,0,.53.1.73.31.21.21.31.45.31.73s-.1.53-.31.73c-.21.2-.45.31-.73.31-.32,0-.57-.12-.76-.36-.35.32-.53.77-.53,1.34v1.52h-.98v-.98c0-.33-.1-.9-.3-1.69-.2-.79-.3-1.35-.3-1.68,0-.64.26-1.2.78-1.69.52-.49,1.24-.73,2.14-.73s1.57.21,2.09.64c.52.42.78.96.78,1.6v4.54ZM242.08,24.13c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.25s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11s-.18.04-.26.11c-.07.07-.11.16-.11.25s.04.18.11.25.16.11.26.11Z"/>
                        <path className="moji" d="M244.93,19.89h-4.66v-.12c0-.52.16-.96.47-1.31s.82-.54,1.5-.54c.59,0,1.12.17,1.58.51.47.34.83.83,1.1,1.47ZM243.84,19.25c-.33-.46-.86-.69-1.59-.69-.34,0-.61.06-.82.19-.21.13-.34.29-.38.5h2.79Z"/>
                        <path className="moji" d="M251.94,25.71c0,.53-.12.94-.35,1.23-.23.29-.56.43-.99.43-.29,0-.53-.1-.73-.31-.2-.21-.31-.45-.31-.73s.1-.53.31-.73c.21-.21.45-.31.73-.31.12,0,.24.02.36.06v-2.36c0-.47-.11-.85-.34-1.12-.22-.27-.63-.41-1.22-.41-.9,0-1.45.39-1.66,1.16l-.96-.13c.27-1.29,1.2-1.94,2.78-1.94s2.38.79,2.38,2.37v2.8ZM250.59,26.7c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.26s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11s-.18.04-.25.11-.11.16-.11.25.04.18.11.26c.07.07.16.11.25.11Z"/>
                        <path className="moji" d="M260.84,20.29c0,.45-.14.84-.43,1.16-.28.32-.66.54-1.12.66.54.32.82.74.82,1.24v2.74c0,.54-.09.88-.27,1.02-.18.14-.55.21-1.12.21h-3.14v-.84h.86v-3.06c0-.47.26-.84.79-1.11,0-.22-.12-.44-.36-.68-.24-.24-.57-.36-.99-.36-.34,0-.64.07-.89.2-.26.14-.41.28-.47.44l.44-.02c.28,0,.53.1.73.31.21.2.31.45.31.73s-.1.52-.31.73c-.21.21-.45.31-.73.31-.41,0-.75-.15-1.02-.46-.27-.31-.41-.69-.41-1.15,0-.53.24-.97.71-1.31.47-.35,1.02-.52,1.65-.52.52,0,1,.12,1.43.36.43.24.71.56.85.97.53-.13.95-.33,1.25-.62.31-.29.46-.62.46-.99v-.13h.98v.15ZM254.95,23.3c.1,0,.18-.04.26-.11.07-.07.11-.16.11-.25s-.04-.18-.11-.26c-.07-.07-.16-.11-.26-.11s-.18.04-.25.11c-.07.07-.11.16-.11.26s.04.18.11.25c.07.07.16.11.25.11ZM259.12,26.12v-2.69c0-.46-.31-.83-.94-1.11-.51.27-.76.63-.76,1.09v3.06h1.38c.21,0,.32-.12.32-.36Z"/>
                        <path className="moji" d="M260.43,29.81h-2.3v-.68c-.19,0-.36-.07-.5-.21-.14-.14-.21-.3-.21-.5s.07-.35.21-.49c.14-.14.3-.21.5-.21s.36.07.5.21c.14.14.21.3.21.49v.82h.89v-1.49h.7v2.06ZM258.12,28.69c.07,0,.13-.03.19-.08.05-.05.08-.12.08-.19s-.03-.13-.08-.19c-.05-.05-.12-.08-.19-.08s-.13.03-.19.08c-.05.05-.08.12-.08.19s.03.14.08.19c.05.05.12.08.19.08Z"/>
                    </g>
                    <AreaMap onSwich={onSwich}/>
                    <rect className="chikuhou"  onClick={()=> {onSwich(3)}} x="203.28" y="173.27" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(480.77 370.22) rotate(180)"/>
                    <g  onClick={()=> {onSwich(3)}}>
                        <path className="moji" d="M216.93,189.98h-1.13l-1.48-4.5c-.07-.22-.16-.56-.27-1.02-.11.47-.2.81-.27,1.02l-1.49,4.5h-1.11v-4.74c-.12.04-.24.06-.36.06-.28,0-.53-.1-.74-.31-.21-.21-.31-.45-.31-.73s.1-.53.31-.73c.21-.21.45-.31.74-.31.42,0,.75.15.99.44.24.29.36.7.36,1.22v3.58c.25-.82.39-1.3.43-1.44l1.19-3.74h.53c.97,3.04,1.52,4.77,1.63,5.17v-5.17h.98v6.71ZM210.82,184.63c.1,0,.18-.04.25-.11.07-.07.11-.16.11-.26s-.04-.18-.11-.25-.16-.11-.25-.11-.18.04-.26.11c-.07.07-.11.16-.11.25s.04.18.11.26c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M216.82,182.56h-4.66v-.12c0-.52.16-.96.47-1.31.32-.36.82-.54,1.5-.54.22,0,.44.02.64.07v-.37h.71v.65c.24.14.45.3.63.5v-1.15h.7v2.28ZM215.72,181.92c-.33-.46-.86-.69-1.59-.69-.34,0-.61.06-.82.19-.21.13-.34.29-.38.5h2.79Z"/>
                        <path className="moji" d="M218.46,177.76c-.21.53-.58.96-1.13,1.31-.55.34-1.15.51-1.81.51h-.44v-.47c.22-.17.39-.38.51-.63-.19,0-.36-.07-.5-.21-.14-.14-.21-.3-.21-.5s.07-.36.21-.5c.14-.14.3-.21.5-.21.23,0,.43.08.59.22.16.15.24.36.24.63,0,.31-.17.65-.51,1.02,1.01-.14,1.69-.73,2.05-1.76l.5.58ZM215.59,178.03c.08,0,.14-.03.19-.08.05-.05.08-.11.08-.19,0-.07-.03-.13-.08-.19-.05-.05-.11-.08-.19-.08-.07,0-.13.03-.19.08s-.08.12-.08.19c0,.08.03.14.08.19.05.05.12.08.19.08Z"/>
                        <path className="moji" d="M221.55,189.98h-1.03v-4.74c-.12.04-.24.06-.36.06-.29,0-.53-.1-.74-.31-.21-.21-.31-.45-.31-.73s.1-.53.31-.73c.21-.21.45-.31.74-.31.43,0,.76.14.99.43.24.29.35.7.35,1.23v4.25c.86-.67,1.66-1.15,2.4-1.43v-4.42h.98v4.33c.43.07.77.23,1.03.48.26.25.38.54.38.87s-.09.61-.28.8c-.19.19-.44.29-.75.29-.91,0-1.36-.56-1.36-1.67-.73.32-1.51.86-2.35,1.61ZM220.16,183.9c-.1,0-.19.04-.26.11-.07.07-.11.16-.11.25s.04.18.11.26c.07.07.16.11.26.11.09,0,.18-.04.25-.11.07-.07.11-.16.11-.26s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11ZM224.88,188.27v.57c0,.16.04.29.11.38.07.1.16.14.27.14.09,0,.17-.04.24-.12.07-.08.11-.18.11-.29,0-.16-.07-.3-.2-.43-.13-.13-.31-.21-.53-.25Z"/>
                        <path className="moji" d="M233.93,189.98h-.98v-4.85c0-.35-.05-.61-.14-.8-.09-.19-.21-.28-.36-.28-.17,0-.35.15-.55.45-.2.3-.38.69-.56,1.19l-1.52,4.29h-1.1v-4.74c-.12.04-.24.06-.36.06-.29,0-.53-.1-.74-.31-.21-.21-.31-.45-.31-.73s.1-.53.31-.73c.21-.21.45-.31.74-.31.43,0,.76.14.99.43.24.29.35.7.35,1.23v3.47l1.11-3.08c.17-.46.34-.84.53-1.15.19-.3.39-.53.61-.68.22-.15.47-.22.74-.22.39,0,.69.17.91.5.22.33.33.81.33,1.42v4.85ZM228.37,184.63c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.26s-.04-.18-.11-.25-.16-.11-.25-.11c-.1,0-.19.04-.26.11-.07.07-.11.16-.11.25s.04.18.11.26c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M233.82,182.56h-4.66v-.12c0-.52.16-.96.47-1.31.32-.36.82-.54,1.5-.54.77,0,1.43.28,1.98.85v-1.15h.71v2.28ZM232.72,181.92c-.33-.46-.86-.69-1.59-.69-.34,0-.61.06-.82.19-.21.13-.34.29-.38.5h2.79Z"/>
                        <path className="moji" d="M234.12,179.57h-.71v-2.1h.71v2.1Z"/>
                        <path className="moji" d="M243.43,182.96c0,.45-.14.84-.43,1.16-.29.32-.66.54-1.12.66.54.32.82.74.82,1.24v2.74c0,.54-.09.88-.27,1.02-.18.14-.55.21-1.12.21h-3.14v-.83h.86v-3.06c0-.47.26-.84.79-1.11,0-.22-.12-.44-.36-.68-.24-.24-.57-.36-1-.36-.34,0-.64.07-.89.2-.26.14-.41.28-.47.44l.44-.02c.29,0,.53.1.73.31.2.21.31.45.31.73s-.1.52-.31.73c-.21.21-.45.31-.73.31-.41,0-.75-.15-1.02-.46-.27-.31-.41-.69-.41-1.15,0-.53.24-.97.71-1.31.47-.35,1.02-.52,1.65-.52.52,0,1,.12,1.43.36.43.24.71.57.85.97.53-.13.95-.33,1.25-.62.31-.29.46-.62.46-.99v-.13h.98v.15ZM237.54,185.97c.1,0,.19-.04.26-.11.07-.07.11-.16.11-.25s-.04-.18-.11-.26c-.07-.07-.16-.11-.26-.11-.09,0-.18.04-.25.11-.07.07-.11.16-.11.26s.04.18.11.25c.07.07.16.11.25.11ZM241.72,188.79v-2.69c0-.46-.31-.83-.94-1.11-.51.27-.76.63-.76,1.09v3.06h1.38c.21,0,.32-.12.32-.36Z"/>
                        <path className="moji" d="M242.78,182.56h-4.66v-.12c0-.52.16-.96.47-1.31.32-.36.82-.54,1.5-.54.59,0,1.12.17,1.58.51.47.34.83.83,1.1,1.47ZM241.69,181.92c-.33-.46-.86-.69-1.59-.69-.34,0-.61.06-.82.19-.21.13-.34.29-.38.5h2.79Z"/>
                        <path className="moji" d="M250.83,189.98h-.98v-4.51c0-.38-.18-.7-.55-.96-.37-.26-.81-.39-1.32-.39-.55,0-1.02.14-1.4.43-.38.29-.58.66-.58,1.12,0,.38.07.82.21,1.32.05.16.08.28.09.34.12-.61.31-1.09.6-1.43.29-.34.62-.51,1-.51.28,0,.53.1.73.31.21.21.31.45.31.73s-.1.53-.31.73c-.21.2-.45.31-.73.31-.32,0-.57-.12-.76-.36-.35.32-.53.77-.53,1.34v1.52h-.98v-.98c0-.33-.1-.9-.3-1.69-.2-.79-.3-1.35-.3-1.68,0-.64.26-1.2.78-1.69.52-.49,1.24-.73,2.14-.73s1.57.21,2.09.64c.52.42.78.96.78,1.6v4.54ZM247.91,186.8c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.25s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11-.1,0-.19.04-.26.11-.07.07-.11.16-.11.25s.04.18.11.25c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M251.05,192.48h-.71v-.68c-.19,0-.36-.07-.5-.21-.14-.14-.21-.3-.21-.5s.07-.35.21-.49c.14-.14.3-.21.5-.21s.36.07.5.21c.14.14.21.3.21.49v1.39ZM250.35,191.36c.07,0,.13-.03.19-.08.05-.05.08-.11.08-.19,0-.07-.03-.13-.08-.19-.05-.05-.12-.08-.19-.08s-.13.03-.19.08-.08.12-.08.19c0,.08.03.14.08.19.05.05.12.08.19.08Z"/>
                        <path className="moji" d="M257.82,189c0,.28-.1.53-.31.73-.21.2-.45.31-.74.31-.43,0-.76-.14-.99-.43-.23-.29-.35-.7-.35-1.23v-4.65c0-.6-.09-.99-.28-1.17-.19-.19-.46-.28-.82-.28-.44,0-.96.1-1.57.29-.03-.22-.05-.42-.05-.59,0-.66.19-1.16.56-1.52.37-.36.91-.53,1.63-.53l2.01.08c.42,0,.76-.03,1.02-.1l.11.82c-.24.08-.61.11-1.11.11l-2.01-.07c-.38,0-.68.07-.9.22-.22.15-.34.34-.36.58.26-.09.54-.14.84-.14.72,0,1.23.16,1.51.47.28.31.42.92.42,1.82v4.28c.12-.04.24-.06.36-.06.28,0,.53.1.74.31.21.21.31.45.31.73ZM257.14,189c0-.09-.04-.18-.11-.25-.07-.07-.16-.11-.26-.11s-.18.04-.25.11c-.07.07-.11.16-.11.25s.04.18.11.26c.07.07.16.11.25.11s.18-.04.26-.11c.07-.07.11-.16.11-.26Z"/>
                        <path className="moji" d="M265.09,182.03c0,.07.01.12.01.17,0,.84-.32,1.59-.96,2.23.21.33.31.72.31,1.16v3.17c0,.54-.09.88-.27,1.02-.18.14-.55.21-1.12.21h-2.28c-.57,0-.94-.07-1.11-.21-.18-.14-.27-.48-.27-1.02v-1.2c0-.51.12-.91.35-1.21.23-.3.56-.45.99-.45.29,0,.53.1.73.31.21.2.31.45.31.73s-.1.53-.31.73-.45.31-.73.31c-.12,0-.24-.02-.37-.06v.87c0,.24.11.36.32.36h2.45c.21,0,.32-.12.32-.36v-3.06c0-.24-.05-.47-.14-.71-.58.32-1.25.49-1.99.49-.65,0-1.16-.11-1.54-.32-.38-.21-.57-.49-.57-.83,0-.31.22-.58.65-.81.43-.23,1-.35,1.69-.35.78,0,1.43.19,1.95.56.39-.44.58-.98.58-1.6v-.14h.98ZM262.81,184.35c-.33-.25-.75-.37-1.26-.37-.43,0-.76.04-1,.12-.24.08-.36.17-.36.26,0,.09.1.17.31.24.2.07.49.11.84.11.53,0,1.02-.12,1.47-.36ZM260.74,187.3c.1,0,.18-.04.25-.11s.11-.16.11-.25-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11s-.18.04-.26.11c-.07.07-.11.16-.11.25s.04.18.11.25c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M270.83,184.07c-.15.66-.45,1.17-.91,1.53-.46.36-1.03.54-1.72.54-.56,0-1.01-.13-1.37-.4-.36-.26-.54-.59-.54-.98,0-.26.09-.49.28-.68.19-.19.41-.28.68-.28s.49.09.68.28c.19.19.28.41.28.67,0,.29-.1.52-.31.71.06.02.15.04.3.04.54,0,.98-.12,1.31-.36.33-.24.55-.59.67-1.07h.66ZM270.83,187.31c-.15.67-.45,1.18-.91,1.54-.46.36-1.03.54-1.72.54-.56,0-1.01-.13-1.37-.4-.36-.26-.54-.59-.54-.98,0-.27.09-.5.28-.69.19-.19.41-.28.68-.28s.49.09.68.28c.19.19.28.41.28.67,0,.29-.1.52-.31.7.06.02.15.04.3.04.54,0,.98-.12,1.31-.36.33-.24.55-.6.67-1.07h.66ZM267.25,185.08c.08,0,.16-.03.22-.1.06-.06.1-.14.1-.22s-.03-.16-.1-.22c-.06-.06-.14-.09-.22-.09s-.16.03-.22.09c-.06.06-.09.14-.09.22s.03.16.09.22c.06.06.14.1.22.1ZM267.25,188.32c.08,0,.16-.03.22-.09.06-.06.1-.14.1-.22s-.03-.16-.1-.22c-.06-.06-.14-.09-.22-.09s-.16.03-.22.09c-.06.06-.09.14-.09.22s.03.16.09.22c.06.06.14.09.22.09Z"/>
                    </g>
                    <rect className="chikugo"  onClick={()=> {onSwich(2)}} x="11.44" y="185.61" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(97.1 394.9) rotate(180)"/>
                    <g  onClick={()=> {onSwich(2)}}>
                        <path className="moji" d="M24.97,202.33h-1.13l-1.48-4.5c-.07-.23-.16-.56-.27-1.02-.11.47-.2.81-.27,1.02l-1.49,4.5h-1.11v-4.74c-.12.04-.24.06-.36.06-.28,0-.53-.1-.74-.31s-.31-.45-.31-.73.1-.53.31-.73c.21-.21.45-.31.74-.31.42,0,.75.15.99.44.24.29.36.7.36,1.22v3.58c.24-.82.39-1.3.43-1.44l1.19-3.74h.53c.98,3.04,1.52,4.77,1.63,5.17v-5.17h.98v6.71ZM18.86,196.97c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.26s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11s-.18.04-.26.11c-.07.07-.11.16-.11.25s.04.18.11.26c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M24.86,194.91h-4.66v-.12c0-.52.16-.96.47-1.31.32-.36.82-.54,1.5-.54.22,0,.44.02.65.07v-.37h.7v.65c.24.14.45.3.63.5v-1.15h.7v2.28ZM23.76,194.26c-.33-.46-.86-.69-1.59-.69-.34,0-.61.06-.82.19-.21.13-.34.3-.38.5h2.79Z"/>
                        <path className="moji" d="M26.5,190.1c-.21.53-.58.96-1.13,1.31-.55.34-1.15.51-1.81.51h-.44v-.47c.22-.17.39-.38.51-.63-.19,0-.36-.07-.5-.21-.14-.14-.21-.3-.21-.5s.07-.36.21-.5c.14-.14.3-.21.5-.21.23,0,.43.08.59.23.16.15.24.36.24.63,0,.31-.17.65-.51,1.02,1.01-.14,1.69-.73,2.05-1.76l.5.58ZM23.63,190.38c.08,0,.14-.03.19-.08.05-.05.08-.11.08-.19,0-.07-.03-.13-.08-.19-.05-.05-.11-.08-.19-.08-.07,0-.13.03-.19.08s-.08.12-.08.19c0,.08.03.14.08.19.05.05.12.08.19.08Z"/>
                        <path className="moji" d="M29.58,202.33h-1.03v-4.74c-.12.04-.24.06-.36.06-.29,0-.53-.1-.74-.31-.21-.21-.31-.45-.31-.73s.1-.53.31-.73c.21-.21.45-.31.74-.31.43,0,.76.15.99.43.23.29.35.7.35,1.23v4.25c.87-.67,1.66-1.15,2.4-1.43v-4.42h.98v4.33c.43.07.77.23,1.03.48.25.25.38.54.38.87s-.09.61-.28.8c-.19.19-.44.29-.75.29-.91,0-1.36-.56-1.36-1.67-.73.32-1.51.86-2.35,1.61ZM28.19,196.24c-.1,0-.19.04-.26.11s-.11.16-.11.25.04.18.11.26c.07.07.16.11.26.11s.18-.04.25-.11c.07-.07.11-.16.11-.26s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11ZM32.92,200.61v.57c0,.16.04.29.11.38.07.09.16.14.27.14.09,0,.17-.04.24-.12.07-.08.11-.18.11-.29,0-.16-.07-.3-.2-.43-.13-.13-.31-.21-.53-.25Z"/>
                        <path className="moji" d="M41.97,202.33h-.98v-4.85c0-.35-.05-.61-.14-.8-.09-.19-.21-.28-.36-.28-.17,0-.35.15-.55.45-.2.3-.39.69-.56,1.19l-1.52,4.29h-1.1v-4.74c-.12.04-.24.06-.36.06-.29,0-.53-.1-.74-.31-.21-.21-.31-.45-.31-.73s.1-.53.31-.73c.21-.21.45-.31.74-.31.43,0,.76.15.99.43.23.29.35.7.35,1.23v3.47l1.11-3.08c.17-.46.34-.84.53-1.15.19-.3.39-.53.61-.68.22-.15.47-.22.74-.22.39,0,.69.17.91.5.22.33.33.81.33,1.42v4.85ZM36.4,196.97c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.26s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11-.1,0-.19.04-.26.11s-.11.16-.11.25.04.18.11.26c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M41.86,194.91h-4.66v-.12c0-.52.16-.96.47-1.31.32-.36.82-.54,1.5-.54.77,0,1.43.28,1.98.85v-1.15h.71v2.28ZM40.76,194.26c-.33-.46-.86-.69-1.59-.69-.33,0-.61.06-.82.19-.21.13-.34.3-.38.5h2.79Z"/>
                        <path className="moji" d="M42.16,191.92h-.7v-2.1h.7v2.1Z"/>
                        <path className="moji" d="M51.47,195.3c0,.45-.14.84-.43,1.16-.28.32-.66.54-1.12.66.54.32.82.74.82,1.24v2.74c0,.54-.09.88-.27,1.02-.18.14-.55.21-1.12.21h-3.14v-.83h.86v-3.06c0-.47.26-.84.79-1.11,0-.22-.12-.45-.36-.68-.24-.24-.57-.36-1-.36-.34,0-.64.07-.89.2-.26.14-.41.28-.47.44l.44-.02c.28,0,.53.1.73.31.21.2.31.45.31.73s-.1.52-.31.73c-.21.21-.45.31-.73.31-.41,0-.75-.15-1.02-.46-.27-.31-.41-.69-.41-1.15,0-.53.24-.97.71-1.31.47-.35,1.02-.52,1.65-.52.52,0,1,.12,1.43.36.43.24.71.56.85.97.53-.13.95-.33,1.25-.62.31-.29.46-.62.46-.99v-.13h.98v.15ZM45.58,198.31c.1,0,.18-.04.26-.11.07-.07.11-.16.11-.25s-.04-.18-.11-.26c-.07-.07-.16-.11-.26-.11s-.18.04-.25.11c-.07.07-.11.16-.11.26s.04.18.11.25c.07.07.16.11.25.11ZM49.75,201.14v-2.69c0-.46-.31-.83-.94-1.11-.51.27-.76.63-.76,1.1v3.06h1.38c.21,0,.32-.12.32-.36Z"/>
                        <path className="moji" d="M50.82,194.91h-4.66v-.12c0-.52.16-.96.47-1.31.32-.36.82-.54,1.5-.54.59,0,1.12.17,1.58.51.47.34.83.83,1.1,1.47ZM49.73,194.26c-.33-.46-.86-.69-1.59-.69-.34,0-.61.06-.82.19-.21.13-.34.3-.38.5h2.79Z"/>
                        <path className="moji" d="M58.87,202.33h-.98v-4.51c0-.38-.18-.7-.55-.96-.37-.26-.81-.39-1.32-.39-.55,0-1.02.14-1.4.43-.38.29-.58.66-.58,1.12,0,.38.07.82.21,1.32.05.16.08.28.09.34.12-.61.31-1.09.6-1.43.28-.34.62-.51,1-.51.28,0,.53.1.73.31s.31.45.31.73-.1.53-.31.73c-.21.2-.45.31-.73.31-.32,0-.57-.12-.76-.36-.36.32-.53.77-.53,1.34v1.52h-.98v-.98c0-.33-.1-.9-.3-1.69-.2-.79-.3-1.35-.3-1.68,0-.64.26-1.2.78-1.69.52-.49,1.23-.73,2.14-.73s1.57.21,2.09.64c.52.42.78.96.78,1.6v4.54ZM55.95,199.15c.09,0,.18-.04.25-.11.07-.07.11-.16.11-.25s-.04-.18-.11-.25c-.07-.07-.16-.11-.25-.11s-.18.04-.26.11c-.07.07-.11.16-.11.25s.04.18.11.25c.07.07.16.11.26.11Z"/>
                        <path className="moji" d="M59.09,204.82h-.71v-.68c-.19,0-.36-.07-.5-.21-.14-.14-.21-.3-.21-.5s.07-.35.21-.49c.14-.14.3-.21.5-.21s.36.07.5.21.21.3.21.49v1.39ZM58.39,203.71c.07,0,.13-.03.19-.08.05-.05.08-.11.08-.19,0-.07-.03-.13-.08-.19-.05-.05-.12-.08-.19-.08s-.13.03-.19.08c-.05.05-.08.12-.08.19,0,.08.03.14.08.19.05.05.12.08.19.08Z"/>
                        <path className="moji" d="M65.86,201.34c0,.28-.1.53-.31.73-.21.2-.45.31-.74.31-.43,0-.76-.14-.99-.43-.24-.29-.35-.7-.35-1.23v-4.65c0-.6-.09-.99-.28-1.17-.19-.19-.46-.28-.82-.28-.44,0-.96.1-1.57.29-.03-.22-.05-.42-.05-.59,0-.66.19-1.16.56-1.52.37-.36.91-.53,1.63-.53l2.01.08c.42,0,.76-.03,1.02-.1l.11.82c-.24.08-.61.11-1.11.11l-2.01-.07c-.38,0-.68.07-.9.22-.22.15-.34.34-.36.58.26-.09.54-.14.84-.14.72,0,1.22.16,1.51.47.28.31.42.92.42,1.82v4.28c.12-.04.24-.06.36-.06.29,0,.53.1.74.31.21.21.31.45.31.73ZM65.18,201.34c0-.1-.04-.18-.11-.25-.07-.07-.16-.11-.26-.11-.09,0-.18.04-.25.11-.07.07-.11.16-.11.25s.04.18.11.26c.07.07.16.11.25.11.1,0,.19-.04.26-.11s.11-.16.11-.26Z"/>
                        <path className="moji" d="M72.83,202.33h-.98v-4.5c0-.36-.17-.67-.52-.95-.35-.27-.79-.41-1.32-.41-.37,0-.7.06-.99.19-.29.13-.5.29-.63.49.61.12,1.03.42,1.24.91-.36.08-.64.24-.84.46-.2.23-.3.5-.3.81v3h-.98v-2.96c0-.67.32-1.11.95-1.31-.11-.16-.53-.24-1.27-.24,0-.63.26-1.17.78-1.6.52-.44,1.2-.65,2.05-.65s1.53.21,2.05.63c.52.42.78.95.78,1.58v4.56Z"/>
                        <path className="moji" d="M79.12,196.41c-.15.66-.45,1.17-.91,1.53-.46.36-1.03.54-1.72.54-.56,0-1.01-.13-1.37-.4-.36-.26-.54-.59-.54-.98,0-.26.09-.49.28-.68.19-.19.41-.28.68-.28s.49.09.67.28c.19.19.29.41.29.67,0,.29-.1.52-.31.7.06.02.15.04.3.04.54,0,.98-.12,1.3-.36.33-.24.55-.59.67-1.07h.66ZM79.12,199.65c-.15.67-.45,1.18-.91,1.54-.46.36-1.03.54-1.72.54-.56,0-1.01-.13-1.37-.4-.36-.26-.54-.59-.54-.98,0-.27.09-.5.28-.69.19-.19.41-.29.68-.29s.49.1.67.29c.19.19.29.41.29.67,0,.29-.1.52-.31.7.06.02.15.04.3.04.54,0,.98-.12,1.3-.36.33-.24.55-.6.67-1.08h.66ZM75.54,197.42c.08,0,.16-.03.22-.09.06-.06.09-.14.09-.22s-.03-.16-.09-.22c-.06-.06-.14-.09-.22-.09s-.16.03-.22.09c-.06.06-.09.14-.09.22s.03.16.09.22c.06.06.14.09.22.09ZM75.54,200.66c.08,0,.16-.03.22-.09.06-.06.09-.13.09-.22s-.03-.16-.09-.22c-.06-.06-.14-.09-.22-.09s-.16.03-.22.09c-.06.06-.09.14-.09.22s.03.16.09.22c.06.06.14.09.22.09Z"/>
                    </g>
                    </svg>
                </figure>
            </SSvg>
                <div className={`fukidashiPosition kitakyu ${onFukidashi === 1 ? 'active' : ''}`}>
                    <div className="fukidashi kitakyu">
                        <figure>
                        <img src={publicPath('images/basicinfo_area/th/kitakyu.svg')} className="fit-img"
                        alt={`มีความเจริญก้าวหน้าทางด้านอุตสาหกรรม เช่น เหล็ก รถยนต์ เซมิคอนดักเตอร์ขั้นสูง และหุ่นยนต์ เป็นต้น และมีแผนกระตุ้นเศรษฐกิจของพื้นที่โดยใช้ประโยชน์จาก "เทคโนโลยีการผลิต" ที่สั่งสมมา มีสนามบินคิตะคิวชูที่สามารถเปิดให้บริการตลอด 24 ชั่วโมง อีกทั้งกำลังอยู่ระหว่างการพัฒนาโครงสร้างพื้นฐานเพื่อพัฒนาเป็นศูนย์กลางโลจิสติกส์แห่งเอเชีย`} />
                        </figure>
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition fukuoka ${onFukidashi === 4 ? 'active' : ''}`}>
                    <div className="fukidashi fukuoka">
                        <figure>
                        <img src={publicPath('images/basicinfo_area/th/fukuoka.svg')} className="fit-img"
                        alt={`เป็นพื้นที่ศูนย์กลางการบริหารของคิวชู เป็นแหล่งรวมของอุตสาหกรรมขั้นตติยะภูมิ จึงได้ถูกพัฒนากลายมาเป็นเขตยุทธศาสตร์ผู้นำ Leading Zone ภาคตะวันตกของญี่ปุ่น มีการพัฒนาโครงสร้างพื้นฐานด้านการคมนาคมอย่างต่อเนื่อง เช่น ชินคังเซ็นในคิวชู สนามบินฟุกุโอกะ และท่าเรือฮากาตะ`} />
                        </figure>
                        
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition chikugo ${onFukidashi === 2 ? 'active' : ''}`}>
                    <div className="fukidashi chikugo">
                    
                    <figure>
                    <img src={publicPath('images/basicinfo_area/th/chikugo.svg')} className="fit-img"
                        alt={`เป็นพื้นที่เปี่ยมเสน่ห์ ไม่ว่าจะเป็นผลผลิตทางภาคเกษตร ป่าไม้ และประมงที่อุดมสมบูรณ์ตามธรรมชาติ อุตสาหกรรมท้องถิ่นและอุตสาหกรรมเชิงพาณิชย์ รวมถึงด้านวัฒนธรรม และเมืองต่างๆ ที่มีเอกลักษณ์เฉพาะตัว อยู่ระหว่างดำเนินโครงการเครือข่ายชิคุโกะอุทยานนครเพื่อให้เมืองต่างๆ ที่มีเอกลักษณ์เฉพาะตัวทำงานร่วมกันเพื่อส่งเสริมพัฒนาและรักษาเอกลักษณ์เหล่านั้นเอาไว้`} />
                    </figure>
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition chikuhou ${onFukidashi === 3 ? 'active' : ''}`}>
                    <div className="fukidashi chikuhou">
                    <figure>
                        <img src={publicPath('images/basicinfo_area/th/chikuho.svg')} className="fit-img"
                        alt={`มุ่งเน้นการพัฒนาทรัพยากรมนุษย์เพื่อรองรับอุตสาหกรรมยานยนต์ซึ่งเป็นอุตสาหกรรมใหม่ที่เข้ามาแทนอุตสาหกรรมถ่านหิน มีนโยบายส่งเสริมบริษัทร่วมทุนและสถาบันวิจัยโดยมีมหาวิทยาลัยด้านวิทยาศาสตร์วิศวกรรมศาสตร์เป็นศูนย์กลาง เพื่อพัฒนาเป็นฐานการสร้างอุตสาหกรรมใหม่ๆ `} />
                        </figure>
                    
                        <span className="speak"></span>
                    </div>
                </div>
            </div>

        </SOverride>
    )
}

export default LangTh