const LangTh = () => {

    return (<>
    <h1>นโยบายเว็บไซต์</h1>
    <p>
    ผู้ใช้งานเว็บไซต์พอร์ทัล (หมายถึงเว็บไซต์พอร์ทัลใน https://www.fisop.net ซึ่งต่อไปจะเรียกว่า เว็บไซต์พอร์ทัล) ที่จัดทำโดยคณะกรรมการดำเนินการโครงการผลักดัน FUKUOKA IS OPEN (ต่อไปจะเรียกว่า คณะกรรมการดำเนินการ) โปรดอ่านข้อมูลต่อไปนี้ และโปรดทราบว่าข้อมูลด้านล่างนี้อาจมีการเปลี่ยนแปลงได้โดยไม่แจ้งให้ทราบล่วงหน้า
    </p>
    <p>
    <dl>
    <dt>
    ●เกี่ยวกับข้อจำกัดความรับผิดชอบ
    </dt>
    <dd>
    <p>
    「แม้เราจะใช้ความระมัดระวังเกี่ยวกับเนื้อหาของข้อมูลที่โพสต์บนเว็บไซต์พอร์ทัลนี้ อย่างไรก็ตามเราไม่สามารถรับประกันความถูกต้องหรือความปลอดภัยของเนื้อหาได้ทั้งหมด คณะกรรมการดำเนินการจะไม่รับผิดชอบต่อความเสียหายหรือความสูญเสียใดๆ ที่เกิดขึ้นโดยผู้ใช้อันเนื่องมาจากข้อมูลที่โพสต์บนเว็บไซต์นี้
    นอกจากนี้ เว็บไซต์พอร์ทัลนี้อาจมีลิงก์ซึ่งเชื่อมโยงไปยังโฮมเพจที่ได้รับการจัดการโดยผู้อื่นที่ไม่ใช่คณะกรรมการดำเนินการ โปรดทราบว่าเราไม่สามารถรับผิดชอบต่อความถูกต้องของเนื้อหาในโฮมเพจปลายทางของลิงก์เหล่านั้นได้
    อีกทั้ง โปรดทราบว่าข้อมูลที่โพสต์บนเว็บไซต์พอร์ทัลนี้อาจมีการเปลี่ยนแปลงหรือลบโดยไม่แจ้งให้ทราบล่วงหน้า
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●เกี่ยวกับลิขสิทธิ์
    </dt>
    <dd>
    ลิขสิทธิ์ของข้อความ โลโก้ รูปภาพ วิดีโอ ซอฟต์แวร์ และข้อมูลอื่นๆ ทั้งหมดที่ปรากฏบนเว็บไซต์พอร์ทัลนี้เป็นของคณะกรรมการดำเนินหรือบุคคลที่สามเว้นแต่จะระบุไว้เป็นอย่างอื่น ห้ามใช้ข้อมูลนี้ (รวมถึงการคัดลอก ดัดแปลง พิมพ์ซ้ำ แจกจ่าย เผยแพร่ต่อสาธารณะ เป็นต้น) โดยไม่ได้รับอนุญาตจากผู้ถือลิขสิทธิ์ล่วงหน้า ยกเว้นกรณีที่กฎหมายลิขสิทธิ์อนุญาต เช่น การใช้งานส่วนตัว นอกจากนี้ลิขสิทธิ์ของภาพบางภาพถือเป็นของผู้เขียนต้นฉบับ
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●เกี่ยวกับสภาพแวดล้อมการใช้งาน (เบราว์เซอร์)
    </dt>
    <dd>
    <p>
    เราขอแนะนำให้ใช้โปรแกรมท่องเว็บ (เบราว์เซอร์) ในเวอร์ชันล่าสุดเพื่อใช้งานเว็บไซต์พอร์ทัลนี้
    โปรดทราบว่าหากดูผ่านเบราว์เซอร์นอกเหนือจากที่กล่าวมาข้างต้น อาจทำให้การแสดงผลไม่ถูกต้อง
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●เกี่ยวกับหน้าที่เยี่ยมชมบ่อย
    </dt>
    <dd>
    <p>
    โปรแกรมท่องเว็บ (เบราว์เซอร์) อาจแสดงข้อมูลที่เคยเปิดอ่านก่อนหน้า (ข้อมูลที่เก็บไว้ชั่วคราวบนคอมพิวเตอร์)
    ดังนั้น เราขอแนะนำให้รีเฟรช (โหลดซ้ำ) หน้าที่เข้าชมบ่อยครั้งเพื่อให้แสดงเป็นข้อมูลล่าสุด
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●เกี่ยวกับ JavaScript
    </dt>
    <dd>
    <p>
    เว็บไซต์พอร์ทัลนี้ใช้ JavaScript ในบางส่วน หากเบราว์เซอร์ของท่านไม่รองรับ JavaScript หรือหากปิดใช้งาน JavaScript คุณลักษณะหรือฟังก์ชันบางอย่างจะไม่ทำงาน
    หากต้องการใช้ฟังก์ชันทั้งหมด โปรดใช้เบราว์เซอร์ที่รองรับ JavaScript หรือเปิดใช้งาน JavaScript ในหน้าตั้งค่าของเบราว์เซอร์
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●กฎหมายที่ใช้บังคับ
    </dt>
    <dd>
    การใช้เว็บไซต์พอร์ทัลนี้ รวมถึงการตีความและการปฏิบัติตาม นโยบายเว็บไซต์ จะเป็นไปตามกฎหมายของประเทศญี่ปุ่น เว้นแต่จะระบุไว้เป็นอย่างอื่น<br/>
    การเข้าถึงเว็บไซต์พอร์ทัลนี้เป็นไปตามเจตจำนงเสรีของบุคคลที่เข้าถึง โปรดใช้บริการเว็บไซต์พอร์ทัลนี้โดยยอมรับความเสี่ยงและรับผิดชอบด้วยตัวท่านเอง สำหรับข้อพิพาทใดๆ เกี่ยวกับเว็บไซต์พอร์ทัลนี้ ศาลประจำเขตจังหวัดฟุกุโอกะจะมีเขตอำนาจในการตัดสินคดีแต่เพียงผู้เดียวในฐานะศาลชั้นต้น
    </dd>
    </dl>
    </p>
    </>)
}

export default LangTh