import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { TrapezoidExcl } from "@/components/Molecules/CharactorHeadline/challenger"
import Article from "@/components/Organisms/Article"

const LangJa = () => {

    return (
        <>
        <Article offset={0} className="pc-noTopMargin">
            <InnerBox>
            <p className="p-side-20">
                令和２年国勢調査による 10 月１日現在の福岡県の人口は、5,135,214 人で、平成 27年から令和２年の５年間に 33,658 人（増加率 0.66％）増加しました。福岡市、北九州市の２つの政令市を有する本県は、人口集積が高く、全国の人口（１億 2,615 万人：総務省統計局「令和２年国勢調査」）の 4.1％を占め、全国第９位の人口規模となっています｡
            </p>
            </InnerBox>
            <div className="pc-flex col-2">
                <div className="pc-only">
                    <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
                </div>
                <BlackNote>
                    <ul className="deco">
                        <li>
                            <p className="large">
                                総人口<br/>
                                514万人
                            </p>
                            <p className="al-c">（2020年・全国第9位）</p>
                            <span className="txt-small">
                            資料 県調査統計課「令和４年 福岡県の人口と世帯年報」
                            </span>
                        </li>
                    </ul>
                
                </BlackNote>
            </div>
        </Article>
        <TrapezoidExcl dir="left">
            福岡県の面積<br/>
        </TrapezoidExcl>
        <Article offset={0} className="pc-noTopMargin">
            <InnerBox>
            <p className="p-side-20">
            総面積は、約49万9千 haで、全国の総面積の 1.3％、九州・沖縄の 11.2％を占めています｡県土は、比較的地形がなだらかで、全国と比較して農地や宅地等の可住地面積が広く、森林が少ないという特徴があります｡<br/>
            （出典：令和５年度　県政概要）
            </p>
            </InnerBox>
            <div className="pc-flex col-2">
                <div className="pc-only">
                    <NavigatorAction actionType="check" dir="right" charactorType="smile"/>
                </div>
            <BlackNote>
                <ul className="deco">
                    <li>
                        <p className="large">
                            福岡県の面積 <br/>
                            4,987㎢
                        </p>
                        <p className="al-c">（2020年・全国第29位）</p>
                        <span className="txt-small">
                        資料 国土交通省国土地理院<br/>
                        「令和4年全国都道府県市区町村別面積調」
                        </span>
                    </li>
                </ul>
            </BlackNote>
            </div>
            <div className="navi-position sp-only">
                <NavigatorCheck dir="right"></NavigatorCheck>
            </div>
        </Article>
        
        <TrapezoidExcl dir="left">
        福岡県の気候<br/>
        </TrapezoidExcl>
        <Article offset={0} className="pc-noTopMargin">
            <InnerBox>
            <p className="p-side-20 m-b-30">
                福岡県は日本海側に位置し、玄界灘や響灘に面する北側は日本海型気候区、南側は内陸型気候区に分かれています。
            </p>
            </InnerBox>
            <BlackNote>
                <ul className="deco pc-flex col-2">
                    <li>
                        <p className="large">
                        年平均気温<br/>
                        17.3℃
                        </p>
                    </li>
                    <li>
                        <p className="large">
                        年平均湿度<br/>
                        68％
                        </p>
                    </li>
                    <li>
                        <p className="large">
                        年間日照時間<br/>
                        1,889.4時間
                        </p>
                    </li>
                    <li>
                        <p className="large">
                        年間降水量<br/>
                        1,686.9mm
                        </p>
                        <p>1991～2020年の30年間の平均値</p>
                        <p className="txt-small">
                            資料 福岡管区気象台
                        </p>
                    </li>
                </ul>
               
            </BlackNote>
            <div className="sp-only">
                <NavigatorCheck dir="right"></NavigatorCheck>
            </div>
        </Article>
        <InnerBox top={40} bottom={40}>
            <EmphasisNote className="picup">
                <h2>福岡・北九州地方の気候</h2>
                <p>
                福岡地方と北九州地方は南に山地があり北に開けているため、冬のシベリア高気圧からの寒気の吹き出しの影響を直接受けます。
                </p>
                <h2>筑後地方の気候</h2>
                <p>
                筑後地方は三方を山に囲まれており、冬の季節風の影響は受けにくいものの山地の西側にあたるため、夏は東シナ海から暖かく湿った空気が入りやすく、福岡地方に比べ降水量が多くなります。
                </p>
                <h2>筑豊地方の気候</h2>
                <p>
                筑豊地方は内陸のため気温の日較差が大きく、北九州地方の京築地域では降水量が少なく、温暖となっています。
                </p>
            </EmphasisNote>
        </InnerBox>
        <div className="pc-only navigator">
            <NavigatorAction actionType="check" charactorType="good" dir="right"/>
        </div>
        </>
    )
}

export default LangJa