
import { NavigatorNice } from "@/components/Atoms/Character/navigator"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorFukidashiPicup } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"

const LangZhCn = () => {

    return (<>
    <h1>发生灾害时对外国人的支援</h1>
    <p>
    为了让来自海外的友人们，在发生灾害时能够采取适当的行动并在本县安心安全地生活，未雨绸缪便显得尤为重要。<br/>
    本县通过多种途经为大家提供各种信息情报。大家请务必通过学习灾难相关知识，在关键时刻通过这些知识保护自己，自己的亲人，周围的友人以及自己珍重的人们的生命。
    </p>
    <NavigatorFukidashiPicup dir="right">
    当灾害发生时，正确高效地获取灾害信息和避难信息非常重要。 通过灵活使用以下网站和APP，可以轻松地收集相关的信息和情报。
    </NavigatorFukidashiPicup>
    <br/>
    <EmphasisNote>

    <h2>下载防灾APP“福冈防灾导航守护君</h2>
    <p>
    我们通过使用防灾APP“福冈防灾导航守护君向大家发布各种防灾情报。<br/>
    因为该APP的使用、注册均为免费，并且可以通过简单的操作查找各种防灾信息，请大家务必注册使用哦。
    </p>
  
    <p>
    ●我们将通过推送通知的方式通知您当前所在位置的防灾信息。<br/>
    ●避难场所的位置将在地图上简单易懂地显示。<br/>
    ●发生灾害时应采取措施的图解进行提供。<br/>
    ●假若灾害发生时可以确认家人的位置信息。<br/>
    ●可提供服务的语言种类有日语、英语、中文（简体、繁体）、韩语、越南语。
    </p>
    <figure>
    <img src={publicPath('images/articles/mamorukun01.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/mamorukun02.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
    </figure>

    <div className="app_qr">
    <a href="https://apps.apple.com/jp/app/%E3%81%B5%E3%81%8F%E3%81%8A%E3%81%8B%E9%98%B2%E7%81%BD%E3%83%8A%E3%83%93-%E3%81%BE%E3%82%82%E3%82%8B%E3%81%8F%E3%82%93/id1659756418" target="blank">
    <figure>
    <img src={publicPath('images/articles/mamorukun_app01.png')} alt="App Store" className="fit-img"/>
    </figure>
    </a>
    <hr/>
    <a href="https://play.google.com/store/apps/details?id=jp.lg.fukuoka.pref.fukuoka_bosai&hl=ja&gl=US" target="blank">
    <figure>
    <img src={publicPath('images/articles/mamorukun_app02.png')} alt="Google Play" className="fit-img"/>
    </figure>
    </a>
    </div>
    <h2>福冈县防灾官方网址</h2>
    <p>
    福冈县通过开设“福冈县防灾主页，面对灾害未雨绸缪、提供各种防灾相关的最新情报。
    </p>
    <p>
    （URL:<a href="https://www.bousai.pref.fukuoka.jp" target="_blank">
    https://www.bousai.pref.fukuoka.jp
    </a> ）
    </p>
    <h2>防灾手册</h2>
    <p>
    我们制作了9种语言版本的《外国人防灾手册》，请大家务必提前阅读，做好防灾准备。
    </p>
    <p>
    （URL:<a href="https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html" target="_blank">https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html</a>）
    </p>
    <p>
    记载的内容包括，<br/>
    ●可能发生的灾害的详细情报<br/>
    ●灾害发生时的对策<br/>
    ●为了应对灾害所需要的日常准备<br/>
    ●避难场所<br/>
    ●紧急联络方式等信息<br/>
    ●可提供服务的语言包括简单易懂的日语、英语、中文、韩语、他加禄语、越南语、尼泊尔语、印度尼西亚语和缅甸语。
    </p>
    <p>
    <a href="https://www.pref.fukuoka.lg.jp/uploaded/life/671824_61619901_misc.pdf" target="_blnak">
    <figure>
    <img src={publicPath('images/articles/disasterprevantionhandbook.png')} alt="disaster prevantion handbook" className="fit-img"/>
    </figure>
    </a>
    </p>

    <h2>面向外国人的防灾训练</h2>
    <p>
    此外，我们还面向外国人居民举办防灾训练，以便他们在发生灾害时能够采取适当的行动，因此请大家务必加入该活动，亲自体验一下发生灾害时应该采取的行动。
    </p>
    <p>
    ●关于日本可能发生的灾害的讲座<br/>
    ●体验学习（对避难时所使用的设备进行组装、体验即时防灾用品等）等
    </p>
    <figure>
    <img src={publicPath('images/articles/disasterinfo01.jpg')} alt="" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/disasterinfo02.jpg')} alt="" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/disasterinfo03.jpg')} alt="" className="fit-img"/>
    </figure>
    <h2>灾害发生时多语言支援中心</h2>
    <p>
    为了能在发生大规模灾害时，对通过市町村的外国人应对进行支援，我们还设立有“灾害发生时多语言支援中心。
    </p>
    <p>
    ●福冈县防灾对策本部，设置在国际交流中心内。<br/>
    ●设置时，我们将提供24小时的对应。 （可能会根据灾害情况进行变更。）<br/>
    ●多语言支援中心的主要职能如下。<br/>
    ⇒我们将收集来自受灾市町村的关于外国人状况的各种情报。<br/>
    ⇒根据受灾市町村的要求，我们将提供灾害期间的情报，以及避难所运作相关情报的口译和笔译的支援。<br/>
    ⇒根据受灾市町村的要求，我们将向灾区派遣灾害期间的口译笔译志愿者。
    </p>
    </EmphasisNote>
    <br/>
    <NavigatorNice dir="right"></NavigatorNice>
    </>)
}

export default LangZhCn