import PageTitleBasciInfo from "@/components/Molecules/PageTitle/basicInfo"
import Article from "src/components/Organisms/Article"
import styled from "styled-components"
import { OffetInner } from "@/components/Atoms/TextBlock/inner"
import publicPath from "src/utilty/publicPath"
import { NavigatorFullLength } from "@/components/Atoms/Character/navigator"
import { langType } from "src/types/lang"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"

const SDiv = styled.div`
    .space{
        position: relative;
        width: 100%;
        aspect-ratio: 10/2.7;
        background: url(${publicPath('images/arrowDonw.png')}) no-repeat;
        background-position: center;
        background-size: auto 65%; 
        .navigator{
            position: absolute;
            z-index: 11;
            width: 30%;
            top: 0;
            transform: translateY(-25%);
            &.right {
                right: 20px;
            }
            &.left {
                left: 20px;
            }
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .space{
            aspect-ratio: 10/0.6;
            .navigator{
                width: 130px;
                &.right {
                right: 15%;
                }
                &.left {
                    left: 15%;
                }
            }
            
        }
        article{
            padding: 40px 0;
        }
       
    }
`


const BasciInfoHistory = ({lang}:{lang: langType}) => {

    setTitleDiscrption({
        layer:'basicinfo',
        lang:lang,
        subLayer:"history"
    })
    

    return (
        <SDiv>
            {lang === 'ja' &&
                <LangJa></LangJa>
            }
            {lang === 'easy_ja' &&
                <LangJa></LangJa>
            }
            {lang === 'en' &&
                <LangEn></LangEn>
            }
            {lang === 'ko' &&
                <LangKo></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
             {lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh></LangTh>
            }
            {lang === 'vi' &&
                <LangVi></LangVi>
            }
            {lang === 'ne' &&
                <LangNe></LangNe>
            }
        </SDiv>
    )
}

export default BasciInfoHistory