

export const setOgUrl = ({
    pathname,
    queryParams
}:{
    pathname:string
    queryParams: URLSearchParams
}) => {
    let url = `https://fisop.net`
    if(queryParams.size > 0){
        const queryString = queryParams.toString();
        url += `${pathname}?${queryParams}` 
    }else{
        url += `${pathname}`
    }

    document.querySelector('meta[property="og:url"]').setAttribute("content", url)
    
}