const LangKo = () => {

    return (<>
    <h1>개인정보 보호에 대하여</h1>
    <p>
    본 포털 사이트(<a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a> )에서는 개인정보의 수집이나 이용 및 관리에 대하여 '개인정보 보호에 관한 법률' 및 '후쿠오카현 개인정보 보호에 관한 법률 시행 조례(2022년 후쿠오카현 조례 43호)'에 근거하여 다음과 같이 적법하게 취급하고 있습니다. 아울러 이 개인정보 취급에 관한 기재는 2023년 4월 1일에 갱신되었습니다.
    </p>
    <p>
    후쿠오카현의 개인정보 보호제도 페이지(<a href="http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html" target="_blank">http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html</a> )
    </p>
    <p>
    <dl>
    <dt>●개인정보란</dt>
    <dd>
    개인에 관한 정보로서 특정 개인이 식별되거나 식별될 수 있는 것(주소, 성명, 전화번호, E-mail 주소 등)을 말합니다.
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●개인정보 수집에 대하여</dt>
    <dd>
    본 포털 사이트를 통해 FUKUOKA IS OPEN 추진사업 실행위원회(이하 '실행위원회')가 개인정보를 수집할 때는 수집 목적을 명확히 하고 그 목적을 달성하기 위해 필요한 범위 내에서 수집합니다.
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●안전성 확보</dt>
    <dd>
    수집한 개인정보에 대해서는 유출, 훼손, 날조 등을 방지하고 엄중하게 관리합니다. 또한 보유할 필요가 없어진 개인정보에 대해서는 신속하게 폐기(소거)합니다.
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●쿠키(Cookie)에 대하여</dt>
    <dd>
    <p>페이지를 이용하기 위해서 '쿠키(Cookie)'라는 기술을 사용하고 있는 일부 페이지가 있습니다.</p>
    <p>
    쿠키란 웹 서버가 이용자의 컴퓨터를 식별할 때 사용되는 업계 표준 기술로서 형태는 작은 용량의 텍스트 파일입니다.
    </p>
    <p>
    일반적으로는 이용자가 포털사이트를 이용하면서 사이트 관리자에게 제공된 개인정보(User ID 및 패스워드), 이용기록, 접속 상황, 경향을 분석하여 통계정보로써 사용하는 용도에 쓰입니다.
    </p>
    <p>
    또한 '쿠키'는 이용자의 컴퓨터를 식별할 수 있지만 이용자 자신이 상세한 개인정보를 입력·제공하지 않는 한 이용자 개인을 특정·식별할 수 없습니다.
    </p>
    <p>
    "본 포털 사이트는 '쿠키' 기능 유무와 관계없이 익명으로 열람 및 이용이 가능합니다.
    </p>
    <p>
    또한 사용 브라우저에 따라서는 그 설정을 변경해서 쿠키의 기능을 비활성화할 수는 있지만 그 결과 페이지의 서비스 전부 또는 일부를 이용할 수 없는 경우가 있습니다.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●이용 및 제공의 제한</dt>
    <dd>
    <p>
    실행위원회가 수집한 개인정보에 대해서는 개인정보 보호에 관한 법률 또는 후쿠오카현 개인정보 보호에 관한 법률 시행 조례에서 정하는 경우를 제외하고 수집 목적을 넘는 이용 및 실행위원회 이외의 제공은 일체 하지 않습니다.
    </p>
    <p>
    단, 본 포털 사이트의 접속 정보, 실행 위원회에 대한 제안·의견 및 이와 관련한 개인 속성에 대해서 통계적으로 처리한 데이터 또는 의견 등을 공표하는 경우가 있습니다.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●소셜미디어(SNS) 버튼 관련 유의사항</dt>
    <dd>
    <p>
    본 포털 사이트에서 소셜미디어(SNS) 버튼이 설치되어 있는 페이지를 열람했을 때 열람자에 관한 정보가 해당 SNS에 송신되는 일이 있습니다. 본 포털 사이트에서 이용하고 있는 SNS 서비스의 프라이버시 정책은 각 사업자의 사이트에서 확인하시기 바랍니다.
    </p>
    </dd>
    </dl>
    </p>
    <p>
        <dl>
            <dt>●Google Analytics 관련 유의사항</dt>
            <dd>
            본 포털 사이트에서는 포털 사이트의 이용 현황을 파악하기 위해 Google Analytics 를 이용하고 있습니다. Google Analytics는 쿠키를 이용하여 이용자의 정보를 수집합니다. Google Analytics 이용 규약에 대해서는 이곳을 참조해 주시기바랍니다. <br/>
            (새로운 창에서 열립니다.  <a href=" https://www.google.com/analytics/terms/jp.html" target="_blank">https://www.google.com/analytics/terms/jp.html </a>)
            </dd>
        </dl>
    </p>
    <p>
        <dl>
            <dt>●적용범위</dt>
            <dd>
            이 프라이버시 정책은 본 포털 사이트(<a href="https://fisop.net" target="_blank">https://fisop.net</a> 이하의 디렉터리)에서만 적용됩니다.
            </dd>
        </dl>
    </p>
    </>)
}

export default LangKo