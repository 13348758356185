import styled from "styled-components"



export const SearchInput = ({register, registerName}) => {

    const SInptut = styled.input`
        border: none;
        padding: 4px;
        width: 100%;
        border-radius: 0%;
        font-size: 16px;
    `  

    return (
        <SInptut type="text"  {...register(registerName)}></SInptut>
    )
}