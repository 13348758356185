import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorGood, NavigatorPickUp } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"

const LangJa = () => {

    return (
        <>
            <InnerBox>
            <p className="p-side-20 m-b-20">
            県内総生産（名目）は20兆円余となっており、九州の約4割を占め、全国第9位となっています。農林水産業も盛んで、第1次産業から第3次産業まで（第1次：全国第13位、第2次：全国第11位、第3次：全国第8位）、多様な産業があります。
            </p>
            </InnerBox>
            <div className="pc-flex col-2">
                <div className="pc-only">
                    <NavigatorAction charactorType="normal" dir="right" actionType="pickup" actionColor="blue"/>
                </div>
                <BlackNote>
                    <p className="large">福岡県のGDP</p>
                    <p className="xl">
                        178,027百万ドル
                    </p>
                    <p className="middle txt-yellow">
                        1人あたり県民所得：<br/>
                        24,790ドル
                    </p>
                    <p className="al-c">（2022年4月現在）</p>
                    <span className="txt-small">
                    資料 県調査統計課<br/>
                    「令和２年度県民経済・市町村民経済計算報告書」<br/>
                    1ドル106.09円で換算（2020年度平均）<br/>
                    資料 内閣府「月例経済報告 主要経済指標」
                    </span>
                </BlackNote>
            </div>
            <div className="navpicup sp-only">
                <NavigatorPickUp dir="right"></NavigatorPickUp>
            </div>
            <InnerBox top={20}>
                <EmphasisNote>
                    <h2>農業</h2>
                    <p>
                    福岡県の耕地面積は7万8,900ｈａ（令和４年）で、県土の16％を占めており、うち水田の割合が 81％（全国平均 54％）と高いのが特徴です。
                    <br/>
                    </p>
                    <h2>森林・林業</h2>
                    <p>
                    福岡県の森林面積は約22万4,000ｈａで、県土の45％を占めています。森林は、水源かん養、土砂流出防止などの公益的機能を有しており、県民の安全・安心な生活を営むうえで重要な役割を果たしています。
                    <br/>
                    </p>
                    <h2>水産業</h2>
                    <p>
                    福岡県では異なる特徴をもつ筑前海、有明海、豊前海の海域と河川・湖沼等の内水面において、多様な漁業や養殖業が行われています。<br/>
                    全国有数の生産を誇るマダイ、ノリをはじめ多様な水産物が水揚げされます。
                    <br/>
                    </p>
                    <h2>商工業</h2>
                    <p>
                    福岡県の工業は、鉄鋼、石炭・化学等の基礎素材型産業や食料品等の生活関連型産業を中心に発展してきましたが、近年では、自動車関連産業等の加工組立型産業のウエイトが高まっています。<br/>
                    また、食料品製造業は、豊富で良質な農林水産物が調達できることや、大消費地に近く物流環境に恵まれていることなどから集積が進んでいます。
                    </p>
                </EmphasisNote>
            </InnerBox>
            <InnerBox top={20}>               
            <h1>
                Growth Industry<br/>
                福岡の未来を支える成長産業
                </h1>
                <p>
                福岡には多くの大学や先進的な研究機関、優れた技術を持つ製造業があります。<br/>
                県は、市場の拡大が見込まれるこうした成長産業への企業の参入・集中を促すことに注力しています。
                </p>
                <EmphasisNote>
                    <h2>1. バイオ業界</h2>
                    <p className="txt-middle">
                        福岡がバイオの最先端コミュニティとして認定され、先進的なバイオテクノロジー拠点となるための取り組みが加速
                    </p>
                    <p>
                    福岡県は2001年から久留米市と共同で、産学官で構成する「福岡県バイオ産業拠点推進会議」を拠点に、バイオ関連企業の集積を目指しています。<br/>
                    推進会議の努力の結果、バイオ関連企業は当初の32社から230社以上に増加しました。
                    福岡では、次世代医療として核酸医薬によるCOVID-19の治療薬開発で注目される株式会社ボナックや、全日本的なゲノム編集技術に強みを持つエディットフォース株式会社など、国内外から注目されるバイオベンチャーが続々と誕生しています。<br/>
                    このような福岡県の利点は高く評価され、2021年6月には内閣府から西日本で唯一の「地域バイオコミュニティ」（全国で4カ所）に認定されました。
                    </p>
                    <h2>2. 宇宙産業</h2>
                    <p className="txt-middle">
                    九州の宇宙ビジネス創出を推進する自治体第1号に福岡県が選ばれる。 福岡発のハード・ソフト技術で宇宙に挑戦
                    </p>
                    <p>
                    福岡県には、優れた技術を持つ製造業や、ユニークな新サービスを生み出すITベンチャーなど、宇宙ビジネスで活躍する可能性を秘めた企業が集積しています。<br/>
                    株式会社iQPS（福岡市）は、昼夜や天候に左右されずに地表を観測できる世界最高水準の小型SAR衛星の開発・打ち上げに成功し、2021年5月には世界最高水準の高解像度画像の撮影にも成功しました。iQPSは、2025年以降に36衛星コンステレーションで10分間隔で地表を観測できるシステムの拡張を目指しています。<br/>
                    2020年9月、内閣府は九州における宇宙ビジネス創出を推進する自治体の第1号として、福岡県を選びました。
                    </p>
                    <h2>3. ブロックチェーン</h2>
                    <p className="txt-middle">
                    日本の最先端人材と技術を結集し、国内有数のブロックチェーン・ハブを設立
                    </p>
                    <p>
                    暗号資産を管理する技術として開発されたブロックチェーンは、そのトレーサビリティ、データの改ざんを阻止する能力、誤作動への耐性が注目されています。<br/>
                    福岡県には、多くのブロックチェーンスタートアップや高度なスキルを持つエンジニアがいるほか、ブロックチェーン分野の研究をリードし、優秀なエンジニアを輩出している九州工業大学コンピュータ理工学部や近大産業理工学部があります。<br/>
                    こうしたIT産業振興のメリットを生かすため、福岡県は2020年から、ブロックチェーン技術の研究開発や人材育成への支援を本格化させ、日本におけるブロックチェーンの主要拠点となりつつあります。
                    </p>
                    <h2>4. エネルギー産業</h2>
                    <p className="txt-middle">
                    水素エネルギー産業を発展させ、企業の集積を図り、洋上風力発電を導入する。
                    </p>
                    <p>
                    日本は、2050年までにカーボンニュートラルを達成することを目標に、再生可能エネルギーを主要な電力源にしようとしています。再生可能エネルギーのなかでも洋上風力発電は、四方を海に囲まれた日本にとって理想的な選択肢であると期待されています。<br/>
                    福岡県はまた、洋上風力発電の導入を推進するとともに、風力発電企業の集積を図るため、産学官と連携しています。また県は、環境に配慮した水素エネルギー社会の実現に向け、産学官が連携して「福岡県水素エネルギー戦略会議」を設立しました。<br/>
                    水素の製造・貯蔵・輸送から利用までの幅広い研究開発、製品開発、人材育成に加え、水素エネルギー企業の育成・集積にも取り組んでいます。
                    </p>
                    <h2>5. その他</h2>
                    <p className="txt-middle">
                    福岡と九州の主要成長産業
                    </p>
                    <p>
                    九州では、環境、農林水産業、自動車産業が成功し、成長産業とみなされています。<br/>
                    ●環境産業<br/>
                    ペットボトルや家電製品のリサイクルに加え、リチウムイオン電池などに使われるレアメタルのリサイクルなど、限りある資源を無駄にしない取り組みも積極的に行っています。<br/>
                    ●農林水産業<br/>
                    九州では農林水産業が盛んで、国内で生産される農水産物の20％を占めています。
                    特に福岡では、あまおうイチゴや八女茶など、国内外から高い評価を受ける農水産物が生産されています。県はAIやロボットを活用したスマート農林水産業にも力を入れています。<br/>
                    ●自動車産業<br/>
                    主要自動車メーカーである日産、トヨタ、ダイハツは北部九州に生産拠点を有し、自動車生産台数は国内市場の15％以上を占めています。
                    </p>
                </EmphasisNote>
                
                <br/>
                <div className="sp-only">
                    <NavigatorGood dir="right"></NavigatorGood>
                </div>
            </InnerBox>

            
        </>
    )
}

export default LangJa

