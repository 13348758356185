
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import { AreaMap } from "./areaMap"
const SSvg = styled.div``
const SOverride = styled.div`
@media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
(max-width: ${props => props.theme.responsive.minPc}) ),
screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
    .fukidashiPosition{
        &.kitakyu{
            top: 18% !important;
        }
        &.chikuhou{
            bottom: 48% !important;
        }
        &.chikugo{
            bottom: 42% !important;
        }
    }
}
`
const LangVI = ({onSwich, onFukidashi}) => {

    return (
        <SOverride>
            <div className="area-info">
                <SSvg>
                    <figure className="map">
                    <svg id="_レイヤー_1" data-name="レイヤー 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.92 286.92">
                        <rect className="fukuoka" onClick={() => {onSwich(4)}} x="5.66" y="60.01" width="92.05" height="20.12" rx="3.9" ry="3.9" transform="translate(103.38 140.13) rotate(180)"/>
                        <g onClick={() => {onSwich(4)}}>
                            <path className="moji" d="M10.66,66.64h.96v3.81h.03l3.16-3.81h1.07l-2.36,2.89,2.73,4.68h-1.06l-2.27-3.94-1.3,1.55v2.39h-.96v-7.57Z"/>
                            <path className="moji" d="M17.5,66h.94v2.25l-.03,1.16c.52-.52,1.09-.94,1.86-.94,1.18,0,1.71.76,1.71,2.2v3.55h-.94v-3.42c0-1.05-.32-1.5-1.06-1.5-.58,0-.97.29-1.54.86v4.06h-.94v-8.21Z"/>
                            <path className="moji" d="M23.94,72.16v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.94v5.6h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19Z"/>
                            <path className="moji" d="M32.28,68.61h.98l1.05,3.19c.18.55.35,1.12.53,1.65h.04c.18-.53.35-1.1.52-1.65l1.06-3.19h.93l-1.99,5.6h-1.09l-2.02-5.6Z"/>
                            <path className="moji" d="M38.65,72.16v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.25c.54-.08.97-.35.97-1.11,0-.21-.07-.44-.17-.62l.73-.35c.15.26.27.58.27.91,0,.93-.58,1.36-1.1,1.53v5.25h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19ZM40.41,75.95c0-.36.26-.6.63-.6s.62.24.62.6c0,.39-.26.63-.62.63s-.63-.24-.63-.63Z"/>
                            <path className="moji" d="M44.82,71.42c0-1.87,1.27-2.95,2.7-2.95.73,0,1.24.31,1.64.66l-.48.62c-.33-.29-.67-.5-1.11-.5-1.02,0-1.76.88-1.76,2.17s.7,2.15,1.73,2.15c.52,0,.97-.24,1.32-.56l.41.63c-.5.45-1.14.71-1.82.71-1.49,0-2.63-1.07-2.63-2.93Z"/>
                            <path className="moji" d="M53.4,66.64h4.36v.82h-3.4v2.56h2.89v.8h-2.89v3.39h-.96v-7.57Z"/>
                            <path className="moji" d="M59,72.16v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.94v5.6h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19Z"/>
                            <path className="moji" d="M65.59,66h.93v5.57h.03l2.38-2.96h1.05l-1.87,2.26,2.11,3.34h-1.02l-1.64-2.7-1.05,1.23v1.47h-.93v-8.21Z"/>
                            <path className="moji" d="M71.31,72.16v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.94v5.6h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19Z"/>
                            <path className="moji" d="M77.48,71.42c0-1.87,1.23-2.95,2.6-2.95s2.6,1.08,2.6,2.95-1.23,2.93-2.6,2.93-2.6-1.07-2.6-2.93ZM81.71,71.42c0-1.29-.66-2.17-1.63-2.17s-1.62.88-1.62,2.17.65,2.15,1.62,2.15,1.63-.86,1.63-2.15Z"/>
                            <path className="moji" d="M84.41,66h.93v5.57h.03l2.38-2.96h1.05l-1.87,2.26,2.11,3.34h-1.02l-1.64-2.7-1.05,1.23v1.47h-.93v-8.21Z"/>
                            <path className="moji" d="M89.89,72.76c0-1.22,1.06-1.83,3.45-2.09,0-.72-.24-1.41-1.16-1.41-.65,0-1.24.3-1.68.6l-.37-.65c.52-.33,1.31-.73,2.21-.73,1.36,0,1.94.92,1.94,2.3v3.45h-.77l-.08-.67h-.03c-.54.44-1.17.8-1.86.8-.94,0-1.64-.58-1.64-1.59ZM93.34,72.85v-1.57c-1.89.23-2.53.69-2.53,1.41,0,.64.43.9.99.9s.99-.26,1.54-.74Z"/>
                        </g>
                        <rect className="kitakyu" onClick={() => {onSwich(1)}} x="171.37" y="12.38" width="107.97" height="20.12" rx="3.9" ry="3.9" transform="translate(450.72 44.87) rotate(180)"/>
                        <g onClick={() => {onSwich(1)}}>
                            <path className="moji" d="M177.75,19.01h.96v3.81h.03l3.16-3.81h1.07l-2.36,2.89,2.73,4.68h-1.06l-2.27-3.94-1.3,1.55v2.39h-.96v-7.57Z"/>
                            <path className="moji" d="M184.59,18.37h.94v2.25l-.03,1.16c.52-.52,1.09-.94,1.86-.94,1.18,0,1.71.76,1.71,2.2v3.55h-.94v-3.42c0-1.05-.32-1.5-1.06-1.5-.58,0-.97.29-1.54.86v4.06h-.94v-8.21Z"/>
                            <path className="moji" d="M191.03,24.53v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.94v5.6h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19Z"/>
                            <path className="moji" d="M199.37,20.98h.98l1.05,3.19c.18.55.35,1.12.53,1.65h.04c.18-.53.35-1.1.52-1.65l1.06-3.19h.93l-1.99,5.6h-1.09l-2.02-5.6Z"/>
                            <path className="moji" d="M205.74,24.53v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.25c.54-.08.97-.35.97-1.11,0-.21-.07-.44-.17-.62l.73-.35c.15.26.27.58.27.91,0,.93-.58,1.36-1.1,1.53v5.25h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19ZM207.49,28.33c0-.36.26-.6.63-.6s.62.24.62.6c0,.39-.26.63-.62.63s-.63-.24-.63-.63Z"/>
                            <path className="moji" d="M211.91,23.79c0-1.87,1.27-2.95,2.7-2.95.73,0,1.24.31,1.64.66l-.48.62c-.33-.29-.67-.5-1.11-.5-1.02,0-1.76.88-1.76,2.17s.7,2.15,1.73,2.15c.52,0,.97-.24,1.32-.56l.41.63c-.5.45-1.14.71-1.82.71-1.49,0-2.63-1.07-2.63-2.93Z"/>
                            <path className="moji" d="M220.48,19.01h.96v3.81h.03l3.16-3.81h1.07l-2.36,2.89,2.73,4.68h-1.06l-2.27-3.94-1.3,1.55v2.39h-.96v-7.57Z"/>
                            <path className="moji" d="M227.16,19.2c0-.36.26-.61.63-.61s.63.25.63.61c0,.38-.26.63-.63.63s-.63-.25-.63-.63ZM227.32,20.98h.94v5.6h-.94v-5.6Z"/>
                            <path className="moji" d="M230.58,24.85v-3.11h-.84v-.71l.88-.05.11-1.58h.78v1.58h1.52v.76h-1.52v3.13c0,.69.22,1.08.87,1.08.2,0,.45-.07.64-.14l.19.7c-.32.1-.69.21-1.03.21-1.2,0-1.6-.75-1.6-1.87Z"/>
                            <path className="moji" d="M233.96,25.13c0-1.22,1.06-1.83,3.44-2.09,0-.72-.24-1.41-1.15-1.41-.65,0-1.24.3-1.68.6l-.37-.65c.52-.33,1.31-.73,2.21-.73,1.36,0,1.94.92,1.94,2.3v3.45h-.77l-.08-.67h-.03c-.54.44-1.17.8-1.86.8-.94,0-1.64-.58-1.64-1.59ZM237.4,25.22v-1.57c-1.89.23-2.53.69-2.53,1.41,0,.64.43.9.99.9s.99-.26,1.54-.74Z"/>
                            <path className="moji" d="M240.38,18.37h.93v5.57h.03l2.38-2.96h1.05l-1.87,2.26,2.11,3.34h-1.02l-1.64-2.7-1.05,1.23v1.47h-.93v-8.21Z"/>
                            <path className="moji" d="M245.76,28.88l.19-.75c.11.04.28.09.42.09.64,0,1.04-.52,1.27-1.21l.12-.42-2.25-5.61h.98l1.14,3.11c.17.49.36,1.06.54,1.57h.05c.16-.51.32-1.07.47-1.57l1-3.11h.92l-2.12,6.06c-.38,1.1-.97,1.95-2.08,1.95-.27,0-.49-.04-.66-.11Z"/>
                            <path className="moji" d="M251.89,24.53v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.94v5.6h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19Z"/>
                            <path className="moji" d="M257.84,25.94l.46-.62c.48.39.99.66,1.66.66.74,0,1.1-.39,1.1-.88,0-.58-.66-.83-1.28-1.06-.78-.29-1.64-.67-1.64-1.62,0-.89.72-1.59,1.94-1.59.69,0,1.3.29,1.73.63l-.45.61c-.38-.29-.77-.5-1.27-.5-.71,0-1.04.37-1.04.8,0,.54.58.73,1.21.97.81.31,1.71.64,1.71,1.7,0,.91-.72,1.66-2.04,1.66-.79,0-1.56-.33-2.09-.77Z"/>
                            <path className="moji" d="M263.56,18.37h.94v2.25l-.03,1.16c.52-.52,1.09-.94,1.86-.94,1.18,0,1.71.76,1.71,2.2v3.55h-.94v-3.42c0-1.05-.32-1.5-1.06-1.5-.58,0-.97.29-1.54.86v4.06h-.94v-8.21Z"/>
                            <path className="moji" d="M270,24.53v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.94v5.6h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19Z"/>
                        </g>
                        <rect className="chikugo" onClick={() => {onSwich(2)}} x="6.95" y="187.39" width="89.03" height="20.12" rx="3.9" ry="3.9" transform="translate(102.94 394.9) rotate(180)"/>
                        <g onClick={() => {onSwich(2)}}>
                            <path className="moji" d="M10.7,194.02h.96v3.81h.03l3.16-3.81h1.07l-2.36,2.89,2.73,4.68h-1.06l-2.27-3.94-1.3,1.55v2.39h-.96v-7.57Z"/>
                            <path className="moji" d="M17.53,193.38h.94v2.25l-.03,1.16c.52-.52,1.09-.94,1.86-.94,1.18,0,1.71.76,1.71,2.2v3.55h-.94v-3.42c0-1.05-.32-1.5-1.06-1.5-.58,0-.97.29-1.54.86v4.06h-.94v-8.21Z"/>
                            <path className="moji" d="M23.98,199.54v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.94v5.6h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19Z"/>
                            <path className="moji" d="M32.32,195.99h.98l1.05,3.19c.18.55.35,1.12.53,1.65h.04c.18-.53.35-1.1.52-1.65l1.06-3.19h.93l-1.99,5.6h-1.09l-2.02-5.6Z"/>
                            <path className="moji" d="M38.69,199.54v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.25c.54-.08.97-.35.97-1.11,0-.21-.07-.44-.17-.62l.73-.35c.15.26.27.58.27.91,0,.93-.58,1.36-1.1,1.53v5.25h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19ZM40.44,203.34c0-.36.26-.6.63-.6s.62.24.62.6c0,.39-.26.63-.62.63s-.63-.24-.63-.63Z"/>
                            <path className="moji" d="M44.85,198.8c0-1.87,1.27-2.95,2.7-2.95.73,0,1.24.31,1.64.66l-.48.62c-.33-.29-.67-.5-1.11-.5-1.02,0-1.76.88-1.76,2.17s.7,2.15,1.73,2.15c.52,0,.97-.24,1.32-.56l.41.63c-.5.45-1.14.71-1.82.71-1.49,0-2.63-1.07-2.63-2.93Z"/>
                            <path className="moji" d="M53,197.82c0-2.44,1.43-3.93,3.35-3.93.92,0,1.64.45,2.07.94l-.52.62c-.39-.42-.9-.72-1.55-.72-1.43,0-2.38,1.18-2.38,3.06s.91,3.11,2.35,3.11c.72,0,1.27-.32,1.75-.85l.53.6c-.6.69-1.34,1.08-2.32,1.08-1.88,0-3.29-1.45-3.29-3.91Z"/>
                            <path className="moji" d="M60.18,193.38h.94v2.25l-.03,1.16c.52-.52,1.09-.94,1.86-.94,1.18,0,1.71.76,1.71,2.2v3.55h-.94v-3.42c0-1.05-.32-1.5-1.06-1.5-.58,0-.97.29-1.54.86v4.06h-.94v-8.21Z"/>
                            <path className="moji" d="M66.54,194.21c0-.36.26-.61.63-.61s.63.25.63.61c0,.38-.26.63-.63.63s-.63-.25-.63-.63ZM66.7,195.99h.94v5.6h-.94v-5.6Z"/>
                            <path className="moji" d="M69.79,193.38h.93v5.57h.03l2.38-2.96h1.05l-1.87,2.26,2.11,3.34h-1.02l-1.64-2.7-1.05,1.23v1.47h-.93v-8.21Z"/>
                            <path className="moji" d="M75.51,199.54v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.94v5.6h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19Z"/>
                            <path className="moji" d="M81.68,202.67c0-.47.3-.93.83-1.27v-.04c-.3-.18-.52-.47-.52-.92s.34-.84.63-1.04v-.04c-.37-.3-.73-.84-.73-1.51,0-1.23.97-2,2.1-2,.31,0,.59.07.78.14h1.95v.72h-1.15c.27.26.45.67.45,1.16,0,1.2-.91,1.95-2.04,1.95-.28,0-.59-.07-.84-.2-.21.17-.36.36-.36.67,0,.36.23.61.98.61h1.09c1.3,0,1.96.41,1.96,1.33,0,1.04-1.1,1.94-2.84,1.94-1.37,0-2.31-.54-2.31-1.51ZM85.91,202.38c0-.52-.4-.69-1.14-.69h-.97c-.21,0-.47-.03-.72-.09-.4.29-.57.62-.57.95,0,.6.61.99,1.62.99,1.07,0,1.78-.57,1.78-1.16ZM85.19,197.85c0-.82-.54-1.31-1.2-1.31s-1.2.49-1.2,1.31.55,1.33,1.2,1.33,1.2-.52,1.2-1.33Z"/>
                            <path className="moji" d="M87.58,198.8c0-1.87,1.23-2.95,2.6-2.95s2.6,1.08,2.6,2.95-1.23,2.93-2.6,2.93-2.6-1.07-2.6-2.93ZM91.81,198.8c0-1.29-.66-2.17-1.63-2.17s-1.62.88-1.62,2.17.65,2.15,1.62,2.15,1.63-.86,1.63-2.15Z"/>
                        </g>
                        <AreaMap onSwich={onSwich}/>
                        <rect className="chikuhou" onClick={() => {onSwich(3)}} x="189.57" y="172.05" width="91.16" height="20.12" rx="3.9" ry="3.9" transform="translate(470.3 364.22) rotate(180)"/>
                        <g onClick={() => {onSwich(3)}}>
                            <path className="moji" d="M195.06,178.68h.96v3.81h.03l3.16-3.81h1.07l-2.36,2.89,2.73,4.68h-1.06l-2.27-3.94-1.3,1.55v2.39h-.96v-7.57Z"/>
                            <path className="moji" d="M201.89,178.04h.94v2.25l-.03,1.16c.52-.52,1.09-.94,1.86-.94,1.18,0,1.71.76,1.71,2.2v3.55h-.94v-3.42c0-1.05-.32-1.5-1.06-1.5-.58,0-.97.29-1.54.86v4.06h-.94v-8.21Z"/>
                            <path className="moji" d="M208.34,184.2v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.94v5.6h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19Z"/>
                            <path className="moji" d="M216.68,180.65h.98l1.05,3.19c.18.55.35,1.12.53,1.65h.04c.18-.53.35-1.1.52-1.65l1.06-3.19h.93l-1.99,5.6h-1.09l-2.02-5.6Z"/>
                            <path className="moji" d="M223.05,184.2v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.25c.54-.08.97-.35.97-1.11,0-.21-.07-.44-.17-.62l.73-.35c.15.26.27.58.27.91,0,.93-.58,1.36-1.1,1.53v5.25h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19ZM224.8,188c0-.36.26-.6.63-.6s.62.24.62.6c0,.39-.26.63-.62.63s-.63-.24-.63-.63Z"/>
                            <path className="moji" d="M229.21,183.46c0-1.87,1.27-2.95,2.7-2.95.73,0,1.24.31,1.64.66l-.49.62c-.33-.29-.67-.5-1.11-.5-1.02,0-1.76.88-1.76,2.17s.7,2.15,1.73,2.15c.52,0,.97-.24,1.32-.56l.41.63c-.5.45-1.14.71-1.82.71-1.49,0-2.63-1.07-2.63-2.93Z"/>
                            <path className="moji" d="M237.36,182.48c0-2.44,1.43-3.93,3.35-3.93.92,0,1.64.45,2.07.94l-.52.62c-.39-.42-.9-.72-1.55-.72-1.43,0-2.38,1.18-2.38,3.06s.91,3.11,2.35,3.11c.72,0,1.27-.32,1.75-.85l.53.6c-.6.69-1.34,1.08-2.32,1.08-1.88,0-3.29-1.45-3.29-3.91Z"/>
                            <path className="moji" d="M244.54,178.04h.94v2.25l-.03,1.16c.52-.52,1.09-.94,1.86-.94,1.18,0,1.71.76,1.71,2.2v3.55h-.94v-3.42c0-1.05-.32-1.5-1.06-1.5-.58,0-.97.29-1.54.86v4.06h-.94v-8.21Z"/>
                            <path className="moji" d="M250.9,178.87c0-.36.26-.61.63-.61s.63.25.63.61c0,.38-.26.63-.63.63s-.63-.25-.63-.63ZM251.06,180.65h.94v5.6h-.94v-5.6Z"/>
                            <path className="moji" d="M254.15,178.04h.93v5.57h.03l2.38-2.96h1.05l-1.87,2.26,2.11,3.34h-1.02l-1.64-2.7-1.05,1.23v1.47h-.93v-8.21Z"/>
                            <path className="moji" d="M259.87,184.2v-3.55h.94v3.43c0,1.04.31,1.49,1.05,1.49.58,0,.98-.28,1.52-.95v-3.97h.94v5.6h-.78l-.07-.88h-.03c-.53.61-1.08,1.01-1.86,1.01-1.18,0-1.7-.76-1.7-2.19Z"/>
                            <path className="moji" d="M266.46,178.04h.94v2.25l-.03,1.16c.52-.52,1.09-.94,1.86-.94,1.18,0,1.71.76,1.71,2.2v3.55h-.94v-3.42c0-1.05-.32-1.5-1.06-1.5-.58,0-.97.29-1.54.86v4.06h-.94v-8.21Z"/>
                            <path className="moji" d="M272.55,183.46c0-1.87,1.23-2.95,2.6-2.95s2.6,1.08,2.6,2.95-1.23,2.93-2.6,2.93-2.6-1.07-2.6-2.93ZM276.78,183.46c0-1.29-.66-2.17-1.63-2.17s-1.62.88-1.62,2.17.65,2.15,1.62,2.15,1.63-.86,1.63-2.15Z"/>
                        </g>
                        </svg>
                    </figure>
                </SSvg>
                <div className={`fukidashiPosition kitakyu ${onFukidashi === 1 ? 'active' : ''}`}>
                    <div className="fukidashi kitakyu">
                        <figure>
                        <img src={publicPath('images/basicinfo_area/vi/kitakyu.svg')} className="fit-img"
                        alt={`Các ngành công nghiệp như thép, ô tô, thể bán dẫn tiên tiến, robot... tại đây rất phát triển. Khu vực luôn sầm uất nhờ áp dụng "kỹ thuật sản xuất" đã tích lũy được trong thời gian dài. Nơi đây còn có sân bay Kitakyushu hoạt động 24/24. Khu vực đang được củng cố để trở thành trọng điểm phân phối lưu thông hàng hóa của châu Á.`} />
                        </figure>
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition fukuoka ${onFukidashi === 4 ? 'active' : ''}`}>
                    <div className="fukidashi fukuoka">
                        <figure>
                        <img src={publicPath('images/basicinfo_area/vi/fukuoka.svg')} className="fit-img"
                        alt={`Đây là trung tâm đầu não của Kyushu, tập chung các ngành công nghiệp dịch vụ và đến nay đã phát triển thành khu vực đi đầu của miền Tây Nhật Bản .  Cơ sở hạ tầng giao thông như Kyushu Shinkansen, sân bay Fukuoka và cảng Hakata rất phát triển. `} />
                        </figure>
                        
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition chikugo ${onFukidashi === 2 ? 'active' : ''}`}>
                    <div className="fukidashi chikugo">
                    
                    <figure>
                    <img src={publicPath('images/basicinfo_area/vi/chikugo.svg')} className="fit-img"
                        alt={`Đây là một khu vực đầy sức hấp dẫn với thiên nhiên phong phú, các ngành sản xuất đa dạng như nông lâm ngư nghiệp, công nghiệp địa phương, công thương nghiệp…, văn hóa đặc sắc và đặc thù của từng thành phố trong khu vực. Các thành phố với nhiều đặc thù riêng đã liên kết và bổ sung chức năng cho nhau để cùng phát triển và xúc tiến tầm nhìn thành phố xanh khắp khu vực Chikugo. `} />
                    </figure>
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition chikuhou ${onFukidashi === 3 ? 'active' : ''}`}>
                    <div className="fukidashi chikuhou">
                    <figure>
                        <img src={publicPath('images/basicinfo_area/vi/chikuho.svg')} className="fit-img"
                        alt={`Vị thế của ngành sản xuất ô tô như một ngành sản xuất mới thay thế cho sản xuất than đá ngày càng tăng cao trong khu vực.  Khu vực này cũng tăng cường đào tạo nguồn nhân lực phục vụ cho ngành sản xuất ô tô. Lấy các trường đại học khối xã hội tự nhiên làm trung tâm để tập chung các công ty khởi nghiệp và trung tâm nghiên cứu để hướng tới trở thành trọng điểm hình thành các ngành công nghiệp mới. `} />
                        </figure>
                    
                        <span className="speak"></span>
                    </div>
                </div>
            </div>
        </SOverride>
    )
}

export default LangVI