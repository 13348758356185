import MoreButton from "@/components/Atoms/Button/moreButton"
import BackLink from "@/components/Atoms/Links/backLink"
import styled from "styled-components"


const SMoreLoad= styled.div`
    position: relative;
    aspect-ratio: 10/1.2;
    .backlink{
        position: absolute;
        &.center{
            width: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.right{
            width: 50%;
            right: 10px;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        aspect-ratio: 100/8;
        .backlink{
            &.center{
                width: 260px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &.right{
                width: 210px;
                right: 10px;
            }
        }
    }
`

const MoreLoad= ({
    onClick,
    positon = 'right',
    ...props
}:{
    onClick:() => void,
    positon?: | 'center' | 'right'
}) => {


    return (
        <SMoreLoad>
            <div className={`backlink ${positon}`}>
            <MoreButton onClick={onClick}/>
             </div>
        </SMoreLoad>
    )
}

export default MoreLoad