import { useState, createContext, useContext } from 'react';


const PageContext = createContext({
    pageLayer : '/',
    setPageLayer: (value: string) => {},
})

export const PageProvider = ({children}) => {


    const [pageLayer, setPageLayer] = useState('/')

    const value = {
        pageLayer,
        setPageLayer
    }

    return (
        <PageContext.Provider value={value}>
          {children}
        </PageContext.Provider>
    );
}

export function usePageContext() {
    return useContext(PageContext);
}