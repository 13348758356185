import InnerBox from "@/components/Atoms/Box/innerBox"
import {FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"

const LangKo = () => {

    return (<>
    <InnerBox>
    <h1>주거 환경</h1>
    <p>
    후쿠오카에서 거주지를 찾아 안심하고 생활하기 위해서는 다음 사항을 이해하고 적절한 절차를 진행하면서 행정 상담 창구의 도움도 이용하세요.
    </p>
    </InnerBox>
    <BlackNote>
    <ul className="deco">
    <li>
    <h4>１.주거용 물건 찾는 법</h4>
    <p>
    ●부동산정보 웹사이트, 어플을 활용하여 물건을 찾아보세요.<br/>
    ●외국어를 지원하는 부동산 회사도 있습니다.
    </p>
    </li>
    <li>
    <h4>２.임대주택의 종류</h4>
    <p>
    ●민간주택: 집주인이 빌려주는 집<br/>
    ●공영주택: 현이나 시가 빌려주는 집<br/>
    ●UR임대주택: UR도시기구가 빌려주는 집. 보증인이 필요 없음
    </p>
    </li>
    <li>
    <h4>３.임대계약의 기본</h4>
    <p>
    ●임대계약은 통상 보증금(담보금)과 사례금, 월세 등이 포함됩니다. 계약서 내용을 잘 이해하고 조건을 확인하세요.
    </p>
    </li>
    <li>
    <h4>４.보증금과 사례금</h4>
    <p>
    ●보증금은 계약종료 시에 반환되지만 월세 체납이나 방이 지저분하고 파손된 곳이 있는 경우에는 차감될 수 있습니다. 사례금은 반환되지 않습니다.
    </p>
    </li>
    <li>
    <h4>５.보증인</h4>
    <p>
    ●일반적으로 계약 시에는 보증인이 필요합니다. 보증인은 계약자가 부담할 수 없는 경우의 책임을 집니다. 또는 월세 채무 보증 회사를 이용하는 경우도 있습니다.
    </p>
    </li>
    <li>
    <h4>６.인터넷과 수도광열비</h4>
    <p>
    ●대부분의 주택에는 인터넷 회선이 갖추어져 있습니다. 수도광열비(전기, 가스, 수도 등)는 기본적으로 집세와 별도로 지불해야 합니다.
    </p>
    </li>
    <li>
    <h4>７.가전 가구 유무</h4>
    <p>
    ●임대 물건에 따라서는 가전이나 가구가 비치되어 있는 경우가 있습니다. 확인하고 입주할지 직접 준비할지 결정하세요.
    </p>
    </li>
    <li>
    <h4>８.주민등록</h4>
    <p>
    ●입주 후에는 주민등록을 할 필요가 있습니다. 이것은 지역 관공서에서 절차를 밟습니다.
    </p>
    </li>
    <li>
    <h4>9.생활 습관과 규칙</h4>
    <p>
    ●일본의 거주지에는 이웃에 대한 배려나 쓰레기 분리수거 규칙 등 특유의 생활 습관이나 매너가 있습니다. 이것들에도 유의하세요.
    </p>
    </li>
    </ul>
    </BlackNote>
    <InnerBox top={20} bottom={20}>
    <FukidashiRedLeft>
    아래 링크에 집을 구할 때 자주 듣게 되는 용어집이나 방 구하기 가이드북 등 외국인이 거주지를 찾을 때 도움이 되는 정보들이 다국어로 소개되어 있습니다.
    </FukidashiRedLeft>
    <h1>
    출입국체류관리청 홈페이지 생활·취업 가이드북
    </h1>
    <ul className="links">
    <li>
    ●영어：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392830.pdf</a>
    </li>
    <li>
    ●중국어：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392831.pdf</a>
    </li>
    <li>
    ●한국어：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001393145.pdf</a>
    </li>
    <li>
    ●베트남어：<a href="https://www.moj.go.jp">https://www.moj.go.j/isa/content/001393146.pdfp</a>
    </li>
    <li>
    ●태국어： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●네팔어： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●쉬운 일본어： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/930004715.pdf</a>
    </li>
    </ul>
    </InnerBox>
    <InnerBox bottom={20}>
    <h1>공익재단법인 일본임대주택관리협회 외국인 입주 원활화 지원 안내</h1>
    <p>
    <a href="https://www.jpm.jp">https://www.jpm.jp/foreign/</a>
    </p>
    </InnerBox>
    </>)
}

export default LangKo