import HeadLine from "@/components/Atoms/HeadLine"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { TopSectionCotainerTemplate } from "@/components/Templates/Responsive/topSectionContainer"
import { langType } from "src/types/lang"
import getLayer from "src/utilty/getLayer"
import { PathAnalysisValue } from "src/utilty/pathAnalyzer"
import publicPath from "src/utilty/publicPath"
import { splitAtFirstNewline } from "src/utilty/replaceContents"
import styled from "styled-components"


const SSection = styled.section`
    position: relative;
    background:url(${publicPath('images/top/specialBk.png')}) no-repeat;
    background-size:100% auto;
    background-position: bottom;
    width: 100%;
    height: 100%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        background: url('images/pc/bk_special.webp') no-repeat;
        background-size: auto 100%;
    }
`

const SHeadline = styled.h2`
     position: absolute;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
    top:0;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 350px;
        transform: translate(-50% ,-25%);
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 440px;
    }
`

const SMessagePosion = styled.div`
    font-size: ${props => props.theme.fontSize.top_mes_sp};
    line-height: ${props => props.theme.lineheight.top_mes_sp};;
    color :#fff;
    font-weight: bold;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 45%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        top: 38%;
        font-size: ${props => props.theme.fontSize.topSection_pc};
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        top: 45%;
    }
`

const SLinkPositon = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 46%;
    @media screen and (min-width: ${props => props.theme.responsive.minSp}) {
        width: 230px;
        bottom: 0%;
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 280px;
    }
`

const SpecialSection = ({pathValue}:{pathValue: PathAnalysisValue}) => {
    const layer = getLayer('special')
    const title = splitAtFirstNewline(layer[pathValue.lang].title)

    return (
        <SSection>
            <TopSectionCotainerTemplate>
            <SHeadline>
                <HeadLine lang="ja" nameKey="special"></HeadLine>
            </SHeadline>
            <SMessagePosion>
                {title[0]}
                { title[1] &&<span> {title[1]} </span>}
            </SMessagePosion>
            <SLinkPositon>
                <YellowArrowLink lang="ja" linkKey="check" href={`/special?lang=${pathValue.lang}`}/>
            </SLinkPositon>
            </TopSectionCotainerTemplate>
         </SSection>
    )
}

export default SpecialSection