import publicPath from "src/utilty/publicPath"


const LangZhCn = () => {

    return (<>
    <h1>
    知事致辞
    </h1>
   <figure className="governor">
     <img src={publicPath('images/fukuoka_governor.jpg')} className="img-fit"/>
    </figure>
    <p>
    　大家好，欢迎大家来到「FUKUOKA IS OPEN」的门户网站。
    </p>
    <p>
    　福冈县自古以来作为亚洲，乃至世界的窗口向外开放，在丰富的自然风光和深厚的历史底蕴下各种各样的文化和产业百花齐放，许许多多的人材争相活跃。
    </p>
    <p>
    　“FUKUOKA IS OPEN”这一门户网站，除了提供在福冈县生活的咨询窗口，以及医疗，教育，就职等各种情报外，还为大家介绍活跃在县内第一线的海外友人，以及运动，环境，同一健康等诸多本县的先进举措，向世界宣传福冈县的食・观光等种种魅力。
    </p>
    <p>
    　为了能让更多的海外人士选择并来到福冈县，并且能在福冈县安心，快乐地生活，我们将尽最大的努力为大家提供支持和帮助。
    </p>
    <p>
    让我们以“FUKUOKA IS OPEN”为口号，在福冈这片土地上一同创造美好的未来吧。
    </p>
    <br/>
    <br/>
    <p className="al-r">
    <strong>
    福冈县知事 服部诚太郎</strong>
    </p>
    </>)
}

export default LangZhCn