import HeadLine from "@/components/Atoms/HeadLine"
import { PropsWithChildren } from "react"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import { pageTitleProps } from "src/components/Molecules/PageTitle"

const SPageTile = styled.div`
    
    .headlineWrap{
        position: relative;
        width: 100%;
        aspect-ratio: 10 / 5;
        &::after{
            width: 100%;
            aspect-ratio: 10 / 5;
            background: url(${publicPath('images/headline/cartoon.png')}) no-repeat;
            background-size: auto 100%;
            background-position: center;
            content: '';
            position: absolute;
            top: 0;
        }
        .headline{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%, -35%);
            z-index: 11;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .headlineWrap{
            max-width: 500px;
        }
       
    }
`

const PageTitleNews = ({
    lang,
    children,
    ...props
} : PropsWithChildren<pageTitleProps>) => {

    return (
        <SPageTile>
            <div className="headlineWrap">
                <div className="headline">
                    <HeadLine lang={lang} nameKey="news"/>
                </div> 
            </div>
        </SPageTile>
       
    )
    
}

export default PageTitleNews