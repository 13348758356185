import { NavigateCracter } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"

const LangNe = () => {

    return (<>
         <Article className="pc-noTopMargin">
        <h1>प्राचीन काल - एशिया संग सम्पर्क बिन्दु－</h1>
        <p>
        फुकुओका ,प्राचीन समयमा,टाढाको शाही अदालत भनेर दाजाइफ़ु सरकारी कार्यालय भनिन्थ्यो तथा विदेशीको लागि एक अतिथि गृह कोरोकान बनाई,चीन र कोरियाली प्रायद्वीप र जापान बीचको आदानप्रदानको लागि सञ्झ्याल थियो।मध्य युगमा पनि, हाकाताको बन्दरगाह मुख्य भूमि चीन, कोरियाली प्रायद्वीप, र्युक्यु र दक्षिणी सागरहरूसँगको व्यापारिक आधारको रूपमा फ़स्टियेको थियो।
        </p>
        </Article>
        <div className="space">
        <div className="navigator right">
        <NavigateCracter type="smile"/>
        </div>
        </div>
        <Article>
        <h1>येदो  अवधि - व्यावसायिक आधार -</h1>
        <p>
        येदो अवधिमा, काठको मोम र हाकाटा-ओरी, कुरुमे कसुरी, र कोकुरा-ओरी जस्ता हस्तकलाहरुको  व्यावसायिक उत्पादन लोकप्रिय भयो।धेरै सडक, बन्दरगाह तथा ओन्गा र चिकुगो नदीमा जलयात्राको विकास  गरियो, जसले यस प्रान्तलाई क्युशुको प्रमुख यातायात केन्द्र बनाएको छ।
        </p>
        </Article>
        <div className="space">
        </div>
        <Article>
        <h1>मेइजी देखि शोवा - कोइला र इस्पात उद्योग को उदय -</h1>
        <p>
        मेइजी कालदेखि शोवा अवधिसम्म, चिकुहो क्षेत्र र चिकुगोको ओमुटा क्षेत्रमा कोइला उत्पादन फस्टाएको थियो, यसको प्रयोग गरेरउत्तरी भागमा रहेको स्टिल,मेसिनरी, बिजुली, रसायन, सिरेमिक आदिमा केन्द्रित ｢किताक्युशु औद्योगिक क्षेत्र｣, दक्षिणमा "कोइला केमिकल कम्प्लेक्स" बनाइयो,जापानको आधुनिकीकरण र आर्थिक विकासलाई सहयोग गर्यो।<br/>
        दोस्रो विश्वयुद्ध पछि, देशको दुबला उत्पादन प्रणाली र कोरियाली युद्धको कारणले भएको विशेष मागको अर्थतन्त्रका कारण इस्पात, धातु र रसायन जस्ता निर्माण उद्योगहरू साथै कोइला उद्योग फस्ट्यो र शहरले चाँडै यसको विनाशबाट मुक्त भयो।
        </p>
        </Article>
        <div className="space">
        </div>
        <Article>
        <h1>मेइजी देखि शोवा - कोइला र इस्पात उद्योग को उदय -</h1>
        <p>
        १९५५ मा सुरु भएको उच्च आर्थिक वृद्धिको अवधिमा, यस प्रान्तको औद्योगिक उत्पादनमा उल्लेखनीय वृद्धि भयो, तर एकै समयमा, ऊर्जा क्रान्तिले कोइला उद्योगको पतन हुन गयो र कोइला उत्पादन गर्ने क्षेत्रहरूलाई गम्भीर धक्का लाग्यो।<br/>
        १९७० को दशकको प्रारम्भमा दुईवटा तेल झट्का पछि, भौतिक-आधारित उद्योगहरूको ठूलो अनुपात भएको प्रिफेक्चरको अर्थतन्त्र न्यून आर्थिक वृद्धि, बलियो येन र औद्योगिक संरचनामा भएको परिवर्तनका कारण कठिन अवस्थामा परेको थियो। यसका कारण, हामीले अत्याधुनिक विकास उद्योगहरूलाई बढावा दिन र जम्मा गर्न काम गरेका छौं, र अटोमोबाइल उद्योग, अत्याधुनिक सेमीकन्डक्टरहरू, बायोटेक्नोलोजी, रोबोट-सम्बन्धित क्षेत्रहरू, इत्यादिमा कम्पनीहरूको स्थानलाई प्रवर्द्धन गरेका छौं। थप रूपमा विशेष गरी फुकुओका सहरमा , वाणिज्य र सेवा उद्योगहरू जस्ता तृतीयक उद्योगहरू उल्लेखनीय रूपमा वृद्धि भएका छन्।
        </p>
        </Article>
        <div className="space">
        <div className="navigator left">
        <NavigateCracter type="good"/>
        </div>
        </div>
        <Article>
        <h1>हाल - क्युशु, पश्चिमी जापान, र एशिया संग आदानप्रदान केंद्र  बन्ने लक्ष्य -</h1>
        <p>
        सन् १९७० को मध्यदेखि उद्योग र दैनिक जीवनलाई समर्थन गर्ने यातायात पूर्वाधारको निर्माण तीव्रताका साथ सुरु भयो।होन्शु र क्युशुलाई जोड्ने `पूर्वको सबैभन्दा लामो पुल`त्यतिबेलाको ध्यानाकर्षण भएको कन्मोन पुलको उद्घाटनपछि सन् १९७० देखि १९८० सम्म को दशकमा,सान्यो शिन्कानसेनशिन-ओसाका र हाकाताबीचको लाइन,क्युशु ज्युँखान   एक्सप्रेसवेको उद्घाटन, जुन क्युशुमा उत्तरदेखि दक्षिणसम्म कोगा आईसी र टोसु आईसी बिच , फुकुओका सिटी सबवे मुरोमी-तेन्जिन खण्ड खुल्यो,  किताक्युशु मोनोरेलको उद्घाटन आदि  यातायात सञ्जालमा सुधारहरू अघि बढेको थियो।  <br/>
        यसबाहेक, २००६ सालमा नयाँ किताक्युशु एयरपोर्ट (वर्तमानमा किताक्युशु एयरपोर्ट) खोलिएको थियो, २०११ सालमा हाकाटा र कागोशिमा लाइनको क्युशु शिन्कान्सेन खोलिएको थियो, २०१६ सालमा  हिगाशिक्युसु एक्सप्रेसवे किताक्युशु शहर र मियाजाकी शहर बीच खोलिएको थियो।<br/>
        २००५सालमा क्यूशु राष्ट्रिय संग्रहालय जापानको चौथो राष्ट्रिय संग्रहालयको रूपमा खोलियो। २०१५ सालमा कोइला खानी, इस्पात उद्योग, र जहाज निर्माण जस्ता सम्बन्धित सुविधाहरू संयुक्त राष्ट्र शैक्षिक, वैज्ञानिक र सांस्कृतिक संगठन (UNESCO) द्वारा 'जापानको मेइजी औद्योगिक क्रान्तिको साइटहरू: फलाम र इस्पात निर्माणको रूपमा विश्व सांस्कृतिक सम्पदा स्थलको रूपमा दर्ता गरिएको थियो। २०१७ सालमा 「『देवताहरू बास गर्ने टापु』, मुनाकाता/ओकिनोशिमा र सम्बन्धित सम्पदा स्थलहरू」विश्व सांस्कृतिक सम्पदा स्थलको रूपमा दर्ता गरिएको छ।<br/>
        समाजमा ठूला परिवर्तनहरू हुँदै जाँदा, हाम्रो प्रान्तले एसिया र बाँकी विश्वसँगको आदानप्रदानबाट महत्त्वपूर्ण यातायात पूर्वाधारको विकास गरेर, नयाँ उद्योगहरूलाई आकर्षित गरेर र उत्कृष्ट प्रविधिको विकास गरेर विभिन्न कठिनाइहरू पार गरेको छ। यसले उद्योग र संस्कृतिलगायत सबै पक्षहरूमा विकास गरेको छ। जापानको भविष्यको विकासलाई सहयोग गर्ने अग्रणी प्रान्तको रूपमा, हामीले क्यूशूको नेतृत्व गर्नुका साथै थप प्रगति गर्न प्रयासरत छौं।<br/>
        </p>
        </Article>
    </>)
}

export default LangNe