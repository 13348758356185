
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import InnerBox from "@/components/Atoms/Box/innerBox"


const LangEn = () => {

    return (<>
        <div className="pc-wapper">
        <InnerBox>
        <h1>
        Consulate
        </h1>
        </InnerBox>
        <BlackNote>
        <ul className="deco">
        <li>
        <p className="name">
        U.S. Consulate Fukuoka<br/> (Est. April 28, 1952)
        </p>
        <p className="address">
        810-0052 2-5-26 Ohori, Chuo-ku, Fukuoka City
        </p>
        <p className="tel">
        092-739-9088
        </p>
        </li>
        <li>
        <p className="name">
        Consulate General of South Korea, Fukuoka<br/>(Est. January 27, 1966)
        </p>
        <p className="address">
        810-0065 1-1-3 Jigyohama, Chuo-ku, Fukuoka
        </p>
        <p className="tel">
        092-771-0461
        </p>
        </li>
        <li>
        <p className="name">
        Consulate-General of The People's Republic of China in Fukuoka<br/>(Est. April 17, 1985)
        </p>
        <p className="address">
        810-0065 1-3-3 Jigyohama, Chuo-ku, Fukuoka City
        </p>
        <p className="tel">
        092-713-1121
        </p>
        </li>
        <li>
        <p className="name">
        Consulate-General of Vietnam in Fukuoka<br/>(Est. April 22, 2009)
        </p>
        <p className="address">
        810-0801 5-3-8, Nakasu, Hakata-ku, Fukuoka<br/>Aqua Hakata 4F
        </p>
        <p className="tel">
        092-263-7668
        </p>
        </li>
        <li>
        <p className="name">
        Royal Thai Consulate-General in Fukuoka<br/>( Est. October 1, 2018)
        </p>
        <p className="address">
        810-0001 4-1-37, Tenjin, Chuo-ku, Fukuoka-shi, Fukuoka <br/>2nd Floor Dai Ichi Myojo
        </p>
        <p className="tel">
        092-739-9088
        </p>
        </li>
        </ul>
        </BlackNote>
        </div>
        
        <div className="checkpostion01 sp-only">
        <NavigatorCheck dir="right"></NavigatorCheck>
        </div>

        <div className="pc-list-wrap">
        <InnerBox bottom={20}>
        <h1>Sister Cities</h1>
        <EmphasisNote>
        <h2>
        Hawaii(United States)<br/>1981.9.25
        </h2>
        <p>
        In September 1980, the "Hakata Gion Yamakasa" festival took part in the Aloha Week Festival, and the "Fukuoka Product Exhibition" was held at the same time, which boosted the spirit of friendship between the two regions and prompted their respective assemblies to vote to promote a sister city relationship.Following this, talks regarding the sister city partnership continued for a year, and on September 25, 1981, Hawaii Governor Ariyoshi and Fukuoka Governor Kamei held a signing ceremony in Honolulu, Hawaii.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        Jiangsu Province (China)<br/>1992.11.4
        </h2>
        <p>
        When Chinese Communist Party General Secretary Jiang Zemin visited the prefecture in April 1992, the governor announced that the prefecture would aim to establish a friendly alliance with China within this fiscal year, which would mark the 20th anniversary of the normalization of diplomatic relations between Japan and China. In response to this, a Friendship and Partnership Review Committee was established, consisting of the government, parliament, and the private sector. As a result of the dispatch of a survey team and discussions, a friendship partnership with Jiangsu Province was decided.
        <br/>In September 1992, an application for a friendship agreement was submitted, and on November 4 of the same year, a signing ceremony was held in Nanjing in Jiangsu Province by Mayor Chen of Jiangsu Province and Governor Okuda of Fukuoka Prefecture.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        Bangkok(Kingdom of Thailand) <br/>2006.2.8
        </h2>
        <p>
        In order to further deepen our relationship with the rest of Asia, we have decided to conduct exchanges with Thailand, which has seen remarkable development in the automotive industry, a major industry in our prefecture, among other Asian countries. Among them, the city of Bangkok was selected as a candidate region because it is the political, economic, and cultural center of the country. In October 2005, a survey team was dispatched to meet with Bangkok Governor Apirak Kosayodhin to confirm his desire for exchange, which received a positive response, and in February 2006, a delegation led by Fukuoka Governor Aso visited Bangkok and signed a friendship agreement.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        Territory of Delhi(India)<br/>2007.3.5
        </h2>
        <p>
        In order to further deepen our relationship with the rest of Asia, we have decided to conduct exchanges with India, which has been developing rapidly in the Asian region, especially in the cutting-edge IT and automobile industries. Among them, the Territory of Delhi, the political, economic, and cultural center of the country, was selected as a candidate region. In October 2005, survey team was dispatched, and in February 2006, a delegation led by Fukuoka Governor Aso visited the Territory of Delhi, met with Chief Minister Dikshit of Delhi, and had a positive exchange of views regarding friendship and the exchange of ideas. With the approval of the Government of India, and following the inclusion in the joint statement at the Japan-India Summit Meeting in December 2006 of a declaration of welcome for friendship between the two regions, a friendship agreement was concluded in March 2007.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        Hanoi(Vietnam) <br/>2008.2.22
        </h2>
        <p>
        In order to further deepen our relationship with the rest of Asia, we have decided to conduct exchanges with Vietnam, which is experiencing remarkable growth among other Asian countries due to its abundant labor force and hard-working people. Hanoi was selected as a candidate region due to it being the political, economic, and cultural center of the country. After dispatching a survey team in October 2005, negotiations continued at the working level, and in February 2008, a delegation led by Fukuoka Prefecture Governor Aso visited Hanoi and concluded a friendship agreement.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        One city and three provinces on the southern coast of South Korea and three prefectures in northern Kyushu and Yamaguchi Prefecture<br/><br/>Announcement of joint declaration
        </h2>
        <p>
        Japan and Korea are geographically located close to each other, and exchange has been active since ancient times., but in particular, the three prefectures of northern Kyushu in Japan (Fukuoka, Saga, and Nagasaki prefectures) and the one city and three provinces on the southern coast of Korea (Busan Metropolitan City, South Jeolla Province, South Gyeongsang Province, and Jeju Special Self-Governing Province) have played a major role as windows of exchange across the Japan-Korea Strait.<br/>With this geographical and historical backdrop, a meeting of the governors of the three northern Kyushu prefectures was held in October 1990 in Tsushima, Nagasaki Prefecture, to discuss the promotion of exchanges between the two regions. As a result of discussions with the South Korean side, an agreement was reached in July 1992 to hold a meeting of heads of state and government to exchange frank opinions on the current status and future prospects of exchange between the two regions and to promote exchange in a variety of fields, and the name of the meeting was changed to the "Japan-Korea Strait Coastal, Prefectural, City and Provincial Governors' Exchange Conference".<br/>
        　"In August 1992, the 1st Japan-Korea Strait Coastal, Prefectural, City and Provincial Governors' Exchange Conference was held in Jeju-do, Korea, where a joint declaration was issued on the implementation of joint projects and the regular holding of governors' exchange conferences to promote exchange between the two regions along the Korea Strait.<br/>
        　Yamaguchi Prefecture joined the project in 1999.<br/>
        　The name was changed to the ""Japan-Korea Strait Coastal Governors' Meeting"" in 2008."
        </p>
        </EmphasisNote>
        </InnerBox>
        </div>
        <InnerBox bottom={20}>
            <div className="pc-navigator">
            <NavigatorCheck dir="right"></NavigatorCheck>
            </div>
        </InnerBox>
    </>)
}

export default LangEn