import publicPath from "src/utilty/publicPath";

const LangVi = () => {

    return (<>
    <h1>Về kết nối</h1>
    <p>
    ●Về kết nối<br/>
    Nếu bạn muốn kết nối đến Cổng thông tin điện của chúng tôi vì mục đích sử dụng lợi nhuận hay phi lợi nhuận, bạn có thể tự do kết nối trang chủ hay các trang bài viết với điều kiện phải ghi rõ việc kết nối. <br/>
    Nếu bạn gắn đường liên kết, vui lòng gửi email đến <a href="mailto:intpol@pref.fukuoka.lg.jp" target="_blank">intpol@pref.fukuoka.lg.jp</a> để chúng tôi có thể xác nhận đường liên kết gốc. Trong email, hãy cung cấp địa chỉ đường liên kết gốc, tên (tên cơ quan), và thông tin liên lạc (địa chỉ email).
    </p>
    <p>
    Chúng tôi cũng cung cấp banner cho việc gắn đường liên kết đến cổng thông tin điện tử của chúng tôi.<br/>
    Có ba loại banner và bạn có thể tải xuống và sử dụng loại bạn mong muốn.<br/>
    Tuy nhiên, trên banner cần ghi rõ liên kết đến Cổng thông tin điện tử Dự án xúc tiến FUKUOKA IS OPEN.
    </p>
    <p>Width 468 px × Height 60 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_468_60.png')}
    className="img-fit"/>
    </figure>
    </p>
    <p>Width 234 px × Height 60 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_234_60.png')}
    className="img-fit"/>
    </figure>
    </p>
    <p>Width 120 px × Height 90 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_120_90.png')}
    className="img-fit"/>
    </figure>
    </p>
    <br/>
    <br/>
    </>)
}

export default LangVi