import InnerBox from "@/components/Atoms/Box/innerBox"


const LangEn = () => {

    return (
        <InnerBox bottom={20} left={0} right={0}>
        <h1>
        About Local Japanese Language Classes<br/>
        There are 100 Japanese language classes held by the government and volunteers in Fukuoka Prefecture.
        </h1>
        <p>
        Japanese language classes serve not only as a place to learn Japanese, but also as a place to meet and interact with Japanese people living in the community.There are also classrooms where you can feel free to consult with us about any problems you may be having in your daily life. For more details, please refer to the chart below.<br/>
        In addition, Fukuoka Prefecture implements the Japanese Language Education Environment Improvement Project to create an environment where foreigners living in Fukuoka can learn Japanese in familiar places. The project includes supporting the establishment of Japanese language classes operated by the government and holding volunteer training courses.
        </p>
        </InnerBox>
        )
}

export default LangEn