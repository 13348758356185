import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlue, FukidashiBlueBottom } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { ChallengerAction, NavigatorAction } from "@/components/Molecules/CharactorAction"


const LangZhTw = () => {
    const lang = 'zh_tw'
    return (<>
    <InnerBox left={0} right={0} bottom={60}>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>我不會說日語，有多語種諮詢窗口嗎？</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>「福岡縣外國人諮詢中心」可用包括日語在內的22種語言進行諮詢。此外，各市町村設置了多語種應對的諮詢窗口和國際交流協會，開展語言支持、法律建議、生活上方方面面的支持。</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="normal"
        actionType="anser"/>
        </div>
        </div>
    </div>

    <div className="links">
    <span className="left">詳情請瀏覽</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/consultation/international?lang=${lang}`}
    linkKey="kokusai_soudan"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="puzzled"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>我想帶家人來，但是不會說日語，可告知學習日語的地方嗎？</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>福岡縣內有100個地方政府及志願者舉辦的日語學習班。也有可輕鬆商談平時生活中感到為難之事等的學習班。</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="smile"
        actionType="anser"/>
        </div>
        </div>
    </div>

    <div className="links">
    <span className="left">詳情請瀏覽</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/education?lang=${lang}`}
    linkKey="kyoiku"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>


    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>請告知可使用外語的醫療機構。</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>「福岡國際醫療支持中心」會為您介紹可使用外語的醫療機構、可提供醫療口譯服務的醫療機構、可透過電話幫助口譯與醫生對話的醫療機構、福岡縣內的醫療機構、日本的醫療制度等。</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="good"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">詳情請瀏覽</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/medical?lang=${lang}`}
    linkKey="iryo"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>作為孩子的上學學校有國際學校嗎？</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>在福岡縣，有獲得了國際性教育認定機構「國際文憑組織」認證、從幼兒期到高中使用英語進行一貫教育的國際學校。</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="pointing"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">詳情請瀏覽</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/education?lang=${lang}`}
    linkKey="kyoiku"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>我正在福岡找工作，哪裡會幫忙介紹工作呢？</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>Work福岡中央（公共職業介紹所）、福岡外國人僱傭服務中心會進行外國人的工作相關諮詢和介紹。</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="normal"
        actionType="anser"/>
        </div>
        </div>
    </div>

    <div className="links">
    <span className="left">詳情請瀏覽</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/employment?lang=${lang}`}
    linkKey="works"
    ></YellowArrowLink>
    </div>

    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>我想移居福岡，但是不知道能不能融入當地。</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>本網站透過影片發佈著在福岡縣大展身手的外國人的成功故事。福岡有很多像影片中介紹的人士那樣遠離故鄉、融入當地大展身手的外國人人士。</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="smile"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left">詳情請瀏覽</span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/movie?lang=${lang}`}
    linkKey="fortunehills"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>
    </InnerBox>
    </>)
}

export default LangZhTw