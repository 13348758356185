import { EmergencyTitleImg } from "@/components/Atoms/Emergency/titleImg"
import { WhiteMoreLink } from "@/components/Atoms/Links/whiteMoreLink"
import { PropsWithChildren, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { FetchArtcleIndexArg, fetchArticleEmergency } from "src/data/fetch"
import { articleTitleSelect } from "src/utilty/articleTitleSelect"
import { DateCms, DateCmsFormat } from "src/utilty/dateFormar"
import { pathAnalyzer } from "src/utilty/pathAnalyzer"
import styled from "styled-components"


export const Emergency = ({children}:PropsWithChildren) => {

    const location = useLocation()
    const pathAnalyze = pathAnalyzer(location)
    const [emergencyOn, setEmergencyOn] = useState<boolean>(false)
    const [ article, setArticle ] = useState<{
        date : DateCms
        title : string,
        id : string,
        eyecatch : string
    }>()


    const args:FetchArtcleIndexArg  = {
        endpoint: 'emergency',
        lang: pathAnalyze.lang,
        offset: 0,
        limit: 20,
        categroyId : 'index'
    }

    const set = (articleObj ) => {
        let langArticle
        if(articleObj[pathAnalyze.lang]){
            langArticle = articleObj[pathAnalyze.lang]  
        }else{
            articleObj['en'] ? langArticle = articleObj['en'] :  articleObj['ja'] ? langArticle = articleObj['ja'] : langArticle = null
        }

        let eyecatchUrl = 'images/emergency/thumb.webp'
        if((langArticle.eyecatch)){
            eyecatchUrl = langArticle.eyecatch.url
        }else if(articleObj.eyecatch ){
            eyecatchUrl = articleObj.eyecatch.url
        }else{}


        setArticle({
            title : articleTitleSelect(pathAnalyze.lang, articleObj),
            date : DateCmsFormat(articleObj.publishedAt),
            id : articleObj.id,
            eyecatch : eyecatchUrl
        })

    }

    const unSet = () => {
        setArticle(undefined)
    }

    useEffect(() => {
        const getEmergency = async() => {
            const articles = await fetchArticleEmergency(args)
            const emergency = articles.contents.filter((article) => {

                        if((location.pathname === '/' && article.display?.top) ||
                            (pathAnalyze.category == 'medical' && article.display?.medical) || 
                            (pathAnalyze.category === 'disasterguidance' && article.display?.disaster) ||
                            (pathAnalyze.category === 'employment' && article.display?.employment) || 
                            (pathAnalyze.category === 'living' && article.display?.living) || 
                            (pathAnalyze.category === 'education' && article.display?.education)
                        ){
                            return article
                        }else{}

                    })
            if(emergency[0]){
                set(emergency[0])
                setEmergencyOn(true)
            }else{
                unSet()
            }
        }
        if(location.pathname.includes(`lifestyle`) || location.pathname === '/' ){
            getEmergency()
        }else{}
        
    },[location])
    

    return (
        <>
        <SEmergency className={emergencyOn ? 'active' : ''}>
            { article &&
            <div className="emergency--inner">
                <Link to={`/emergency?id=${article.id}&lang=${pathAnalyze.lang}`} className="contents">
                    <h3><EmergencyTitleImg lang={pathAnalyze.lang}></EmergencyTitleImg></h3>
                    <figure>
                        <img src={article.eyecatch} />
                    </figure>
                    <p className="datetitle">
                    <span className="date"> {article.date.Y}.{article.date.m}.{article.date.d}</span>
                    <span className="title">{article.title}</span>
                    </p>
                </Link>
                <div className="more">
                    <WhiteMoreLink href={`/emergency?lang=${pathAnalyze.lang}`}></WhiteMoreLink>
                </div>
            </div>
            }
        </SEmergency>
        <SContents className={article ? 'emergency' : ''}>
            {children}
        </SContents>
        </>
    )
}

const SContents = styled.div`
    padding-top: 60px;
    &.emergency{
        padding-top: 0;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        padding-top: 0;
    }
`

const SEmergency = styled.div`
    padding-top: 140px;
    max-width: ${props => props.theme.innerMaxWidth};
    background: ${props => props.theme.colors.emergency};
    width: 100%;
    right: 0;
    position: relative;
    overflow: hidden;
    display: none;
    &.active {
        display: block;
    }
    &::before{
        content: '';
        width: 100%;
        min-width: 1200px;
        height: 30px;
        position: absolute;
        top: 120px;
        left: 0;
        background: url('./images/emergency/border.webp');
        background-size: 100% auto;
        background-position: center;
    }
    &::after{
        content: '';
        width: 100%;
        min-width: 1200px;
        margin-right: 1200px;
        height: 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: url('./images/emergency/border.webp');
        background-size: 100% auto;
        background-position: center bottom;
    }
    .emergency--inner{
        width: 80%;
        min-width: 300px;
        padding: 40px 10px;
       
    }
    img{
        max-width: 100%;
    }
    figure{
        margin:20px 0;
        aspect-ratio: 10 / 6.5;
        position: relative;
        overflow: hidden;
        img{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            transition: 0.6s;
        }
        
    }
    
    span{
        display: block;
        font-weight: bold;
        font-size: 1.6rem;
        margin-bottom: 5px;
        color:#333333;
    }
    .more{
        width:200px;
        padding: 10px 0;
    }
    .contents {
        display: block;
        &:hover{
            figure{ img{
                transform: translate(-50%, -50%) scale(1.2);
            }
            }
            span{
                color: ${props => props.theme.colors.red}
            }
        }
    }
    
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        padding-top: 0px;
        max-width: 100%;
        width: calc(100% - 250px);
        position: relative;
        margin-right: 0;
        &::before{
            top: 0;
        }
        .emergency--inner{
            padding: 60px 0;
        }
        .contents{
            
            margin: 0 auto;
            max-width: 700px;
            position: relative;
            aspect-ratio: 10 / 3;
                       
            h3{
                width: 50%;
            }
            .datetitle{
                width: 50%;
                position: absolute;
                left: 0;
                bottom: 5px;
            }
            figure{
                width: 43%;
                position: absolute;
                right: 0;
                top: 0;
                margin: 0;
            }
        }
        .more{
            padding-top: 30px;
        }
        @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
            width: calc(100% - 300px);
        }
    }
    

`

