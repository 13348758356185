import { useLocation, useParams } from "react-router-dom"
import getLayer from "src/utilty/getLayer";
import SubLayerTemplate from "@/components/Templates/subLayer";
import SupportLinksPage from "./linksPage";
import PageTitleSupport from "@/components/Molecules/PageTitle/support";
import PageBack from "@/components/Molecules/PageBack";
import KnowlegePage from "../Support/knowledge";
import { useEffect, useState } from "react";
import SupportFaqPage from "./faq";
import { pathAnalyzer } from "src/utilty/pathAnalyzer";
import { NaviAndChallenger } from "@/components/Atoms/HeadLine/charactor";
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio";




const SupportPage = () => {

    const { category } = useParams();

    const layer = getLayer('support')
    let layerData
    category && category !== 'index' ? layerData = layer.sublayer[category] :  layerData = layer

    const [ segment, setSegment  ] = useState<string>()
    const location = useLocation()
    const lang = pathAnalyzer(location).lang

    useEffect(()=>{
        if(!category || category == 'index'){
            setSegment('index')
        }else{
            setSegment(category)
        }
    },[category])

    return (
        <ResponsiveFioTemplate>
            <SubLayerTemplate>
                <PageTitleSupport lang="ja">
                    {layerData[lang].title}
                </PageTitleSupport>
                {
                    segment === 'index' &&
                    <></>
                }
                {
                    segment === 'faq' &&
                    <>
                    <div className="sp-only">
                        <NaviAndChallenger
                            challengarType="guts"
                            navigatorType="smile"
                        ></NaviAndChallenger>
                    </div>
                    <SupportFaqPage lang={lang}></SupportFaqPage>
                    <PageBack backto={`/?current=10&lang=${lang}`}></PageBack>
                    </>
                }
                {
                    segment === 'links' &&
                    <>
                    <SupportLinksPage lang={lang}></SupportLinksPage>
                    <PageBack backto={`/?current=10&lang=${lang}`}></PageBack>
                    </>
                }
                {
                    segment === 'knowledge' &&
                    <>
                    <KnowlegePage lang={lang}></KnowlegePage>
                    <PageBack backto={`/?current=10&lang=${lang}`}></PageBack>
                    </>
                }
                
            </SubLayerTemplate>
        </ResponsiveFioTemplate>
    )
}

export default SupportPage;