import CommingSoon from "@/components/Atoms/CommingSoon";
import { NaviAndChallenger } from "@/components/Atoms/HeadLine/charactor";
import PageBack from "@/components/Molecules/PageBack";
import PageTitleSpecial from "@/components/Molecules/PageTitle/special";
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio";
import SubLayerTemplate from "@/components/Templates/subLayer";
import { useLocation, useParams } from "react-router-dom";
import getLayer from "src/utilty/getLayer";
import { pathAnalyzer } from "src/utilty/pathAnalyzer";
import { setTitleDiscrption } from "src/utilty/setTitleDiscription";
import styled from "styled-components";
import { SArticle } from "@/components/Templates/article";
import { useEffect, useRef, useState } from "react";
import LangJa from './ja'
import LangEn from './en'
import LangKo from './ko'
import LangZhCn from './zh_cn'
import LangZhTw from './zh_tw'
import LangVi from './vi'
import LangTh from './th'
import LangNe from './ne'


const SSpecial = styled.div`
   
    .customNote{
        margin-top: 30px;
        padding-top:20%;
        padding: 20% 30px 20%;
        position: relative;
        margin-bottom: 40px;
        &::before{
            content: '';
            background: url('./images/ramenbeats/chochin.webp') no-repeat;
            width: 95%;
            background-size: 100% auto;
            padding-bottom: 15%;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }
        &::after{
            content: '';
            background: url('./images/ramenbeats/chochin.webp') no-repeat;
            width: 95%;
            background-size: 100% auto;
            padding-bottom: 15%;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
        }
        section{
            text-align: center;
            padding: 20px 0;
            position: relative;
            h5{
                padding-top: 30%;
                font-size: 3.0rem;
                line-height: 4.0rem;
                position: relative;
                &::before{
                    content: '';
                    width: 100%;
                    padding-bottom: 25%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    
                }
                &.first{
                    position: relative;
                    padding-bottom: 20px;
                    border-bottom: dotted 4px #CB2939;
                    &::before{
                        background: url('./images/ramenbeats/one.webp') no-repeat;
                        background-size: auto 100%;
                        background-position: center;
                    }
                    &::after{
                        content : '';
                        width: 35%;
                        aspect-ratio: 10/11;
                        background: url('./images/ramenbeats/icon01.webp') no-repeat;
                        background-size: auto 100%;
                        position: absolute;
                        top: -10%;
                        left: 0;
                    }
                }
                &.second{
                    position: relative;
                    &::before{
                        background: url('./images/ramenbeats/two.webp') no-repeat;
                        background-size: auto 100%;
                        background-position: center;
                    }
                    &::after{
                        content : '';
                        width: 35%;
                        aspect-ratio: 10/9;
                        background: url('./images/ramenbeats/icon02.webp') no-repeat;
                        background-size: auto 100%;
                        position: absolute;
                        right: -2%;
                        bottom: 6%;
                    }
                    padding-bottom: 20px;
                    border-bottom: dotted 4px #CB2939;
                }
                &.third{
                    position: relative;
                    &::before{
                        background: url('./images/ramenbeats/three.webp') no-repeat;
                        background-size: auto 100%;
                        background-position: center;
                    }
                    &::after{
                        content : '';
                        width: 35%;
                        aspect-ratio: 10/9;
                        background: url('./images/ramenbeats/icon03.webp') no-repeat;
                        background-size: auto 100%;
                        position: absolute;
                        left: -2%;
                        top: 6%;
                    }
                    padding-bottom: 20px;
                   
                }
            }
            .caption{
                background: #fff;
                padding: 20px;
                border-radius: 20px;
                li{
                    font-size: 2.6rem;
                    line-height: 4.0rem;
                    color: #CB2939;
                    font-weight: bold;
                    text-align: left;
                    padding-left: 35px;
                    position: relative;
                    &::before{
                        content: '☆';
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            
            }
        }
    }
`

const SCustomAricle = styled(SArticle)`
    padding: 0;
    padding-bottom: 60px;
    img{
        max-width: 100%;
    }
    figure.main{
        margin: 0;
        width: 100%;
        background: #fff;
        background-image: url('./images/aritcleBk02.png');
        background-size: 100% auto;
        position: relative;
        z-index: 99;
        img{width:100%}
      
    }
    p{
        font-size: 2.2rem;
        line-height: 3.0rem;
        font-weight: bold;
        &.discpriton{
            color: #CB2939;
        }
    }
    &::before{
        display: none;
    }
    .article-inner{
        max-width: 680px;
        padding: 0 20px;
    }
    .whattonkotsu{
        padding: 0 20px;
        margin-bottom: 20px;
    }
    ul.phots{
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        li{
            flex-basis: 49%;
        }
    }
    .game_start{
        width: 100%;
        aspect-ratio: 10 / 4.5;
        border: solid;
        background: url('./images/ramenbeats/start_bk.webp') no-repeat;
        background-size: 100% auto;
        position: relative;
        margin-bottom: 20px;
        &.fixed{
            position: fixed;
            z-index: 999;
            bottom: 60px;
            left: 0;
            @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
                width: 500px;
                right: 10px;
                top: 35%;
                bottom: auto;
                left: auto;
            }
        }
    }
    .start_btn{
        background: #CB2939;
        padding:8px 20px;
        border-radius: 20px;
        width: 45%;
        display: block;
        position: absolute;
        bottom:20%;
        right: 20px;
        transition: bottom 0.3s ease-in-out; /* アニメーションの追加 */
        &:hover{
            bottom:22%;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        margin-top: -20px;
    }
`

const SpecialPage = () => {

    const location = useLocation()
    const lang = pathAnalyzer(location).lang
    const layer = getLayer('special')
    
    setTitleDiscrption({
        layer : 'special',
        lang : lang
    })
    
    const [fixed, setFixed] = useState<boolean>(true)
    const articleRef = useRef(null); // useRefを使って参照を作成
     // デバウンス関数
    const debounce = (func, wait) => {
        let timeout;
        return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        };
    };

    useEffect(() => {
        const checkIfBottom = debounce(() => {
          if (!articleRef.current) {
            return;
          }
    
          // 要素の下端がビューポート内にあるかチェック
          const { bottom } = articleRef.current.getBoundingClientRect();
          if (bottom <= window.innerHeight) {
            setFixed(false); // 最下部に達したらfixedをfalseに設定
          } else {
            setFixed(true); // それ以外の場合はtrueを保持
          }
        },100);
    
        // スクロールイベントリスナーを登録
        window.addEventListener('scroll', checkIfBottom);
    
        // コンポーネントのアンマウント時にイベントリスナーを解除
        return () => {
          window.removeEventListener('scroll', checkIfBottom);
        };
      }, []); // 空の依存配列を指定して、マウント時とアンマウント時にのみ実行
    const langUtm = {
        ja : 'japanese',
        easy_ja : 'easyjapanese',
        en : 'english',
        zh_tw : 'traditionalchinese',
        zh_cn : 'simplifiedchinese',
        ko : 'korean',
        vi : 'vietnamese',
        ne : 'japanese',
        th : 'nepali',
    }

    return (
        <ResponsiveFioTemplate>
            <SubLayerTemplate>
                <PageTitleSpecial lang="ja">
                    {layer[lang].title}
                </PageTitleSpecial>
                
                <SSpecial>
                    <SCustomAricle>
                        <figure className="main">
                            <img src={lang === 'ja' ? `./images/ramenbeats/main_pc.webp` : `./images/ramenbeats/main_pc_en.webp`} className="pc-only" alt="BEATを感じて上位を目指せ"/>
                            <img src={lang === 'ja' ? `./images/ramenbeats/main_sp.webp` : `./images/ramenbeats/main_sp_en.webp`} className="sp-only"  alt="BEATを感じて上位を目指せ"/>
                        </figure>
                        <div className="article-inner" ref={articleRef}>
                            {lang === 'ja' &&
                                <LangJa></LangJa>
                            }
                            {lang === 'en' &&
                                <LangEn></LangEn>
                            }
                            {lang === 'ko' &&
                                <LangKo></LangKo>
                            }
                            {lang === 'zh_cn' &&
                                <LangZhCn></LangZhCn>
                            }
                            {lang === 'zh_tw' &&
                                <LangZhTw></LangZhTw>
                            }
                            {lang === 'th' &&
                                <LangTh></LangTh>
                            }
                            {lang === 'vi' &&
                                <LangVi></LangVi>
                            }
                            {lang === 'ne' &&
                                <LangNe></LangNe>
                            }
                            <div className={fixed ? 'game_start fixed' : 'game_start'}>
                                <a href={`https://www.shikumi.co.jp/ramen/?utm_source=fiso&utm_medium=gamepagebanner&utm_campaign=${langUtm[lang]}`} target="blank" id="gemestart" className="start_btn">
                                    <img src={lang === 'ja' ? `./images/ramenbeats/gamestart.svg` : `./images/ramenbeats/gamestart_en.svg`} alt="ゲームスタート"/>
                                </a>
                            </div>
                            <div className="navigator right enjoy good"></div>
                        </div>
                    </SCustomAricle>
                </SSpecial>
                <PageBack backto="/?current=8"></PageBack>

            </SubLayerTemplate>
        </ResponsiveFioTemplate>
    )
}

export default SpecialPage