
import { memo } from "react";
import { Link } from "react-router-dom";
import { langType } from "src/types/lang";
import publicPath from "src/utilty/publicPath";



export const Flogo = memo(({width, lang, ...props}
    :{width:number, lang:langType}) => {
   
    return (
        <Link to={`/?lang=${lang}`}>
          <img 
            src={publicPath('images/logo.png')} 
            alt="FukuokaIsOpen" 
            className="img-fit"
            width={width}
        />
        </Link>
    );

})