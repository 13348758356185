import CheckButton from "@/components/Atoms/Button/checkButton"
import { ICmsViewTrigger, TCmsViewHandle } from "@/components/Organisms/Cms"
import { useCmsContext } from "@/context/CmsContext"
import { useEffect, useState } from "react"
import { AritcleIndex, ArticleObj, article } from "src/types/article"
import { articleTitleSelect } from "src/utilty/articleTitleSelect"
import { DateCmsFormat } from "src/utilty/dateFormar"
import { removeH1Tags } from "src/utilty/replaceContents"
import styled from "styled-components"



const SWrap = styled.div`
    background: ${props => props.theme.colors.black};
    padding-bottom: 10px;
    figure{
        margin: 0;
        padding: 0;
        width: 100%;
        aspect-ratio: 10 / 5.5;
        overflow-y: hidden;
        position: relative;
        img{
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: 1s;
        }
        &:hover{
            img{
                transform:translateY(-50%) scale(1.2);
            }
        }
    }
    .check-link{
        width: 40%;
        margin-right: 10px;
    }
    &.emergency{
        background: ${props => props.theme.colors.emergencyLight};
        .check-link{
            margin: 10px auto 0;
        }
    }
`
const SData = styled.h3`
    padding:5px 10px 10px 10px;
    font-weight: bold;
    span.date{
        display: block;
        font-size: 1.3rem;
        color:#FFE200;
        padding: 5px 0;
        .day{
            font-size: 1.5rem;
        }
    }
    .title{
        display: block;
        font-size: ${props => props.theme.fontSize.h3_sp};
        margin-bottom: 5px;
        min-height: 44px;
    }
    font-size: 1.2rem;
    color:#fff;
    &.emergency{
        color: #333333;
        span.date{
            color: #333333;
        }
    }
`

const SBlock = styled.div`
    cursor: pointer;
    &.emergency{
        padding: 20px;
    }
`

export const CmsBlockArticle = ({
    handleView, articleObj}:{
        handleView : TCmsViewHandle
        articleObj:ArticleObj }) => {

    const CmsContext = useCmsContext()
    const lang = CmsContext.pathAnalys.lang
    const [langArticle, setLangArticle] = useState<article>()
    const [eyecatch, setEyecatch] = useState<string>()
    const [content, setContent] = useState<string>()
    const date = DateCmsFormat(articleObj.publishedAt)
    
    useEffect(() => {
        let langArticle
        if(articleObj[lang]){
            langArticle = articleObj[lang]  
        }else{
            articleObj['en'] ? langArticle = articleObj['en'] :  articleObj['ja'] ? langArticle = articleObj['ja'] : langArticle = null
        }

        let eyecatchUrl = 'images/noimg.png'
        if((langArticle.eyecatch)){
            eyecatchUrl = langArticle.eyecatch.url
        }else if(articleObj.eyecatch ){
            eyecatchUrl = articleObj.eyecatch.url
        }else{
            if(CmsContext.pathAnalys.layer === 'emergency' ) eyecatchUrl = 'images/emergency/thumb.webp'
        }

        let content = removeH1Tags(Object.keys(langArticle).indexOf('content') !== -1 ? langArticle.content : langArticle.html).replace(/<\/?[^>]+(>|$)/g, "")

        setLangArticle(langArticle)
        setEyecatch(eyecatchUrl)
        setContent(truncateString(content, 20))
    },[])

    const truncateString = (str:string, num:number) => {
        if (str.length > num) {
            return str.slice(0, num) + '...';
        } else {
            return str;
        }
    }

    const handleProps:ICmsViewTrigger = { 
        id: articleObj.id, 
        endpoint: articleObj.endpoint ? articleObj.endpoint : undefined,
        category: articleObj.category ? articleObj.category.en : undefined,
    }

    return (
        <SWrap className={CmsContext.pathAnalys.layer}>
           
            { langArticle &&
            <SBlock onClick={() => {handleView(handleProps) }} className={CmsContext.pathAnalys.layer}>
                <figure>
                    <img src={eyecatch} />
                </figure>
                <SData className={CmsContext.pathAnalys.layer}>
                    <span className="date">
                        {date.Y}.
                        <span className="day">{date.m}.{date.d}</span>
                    </span>
                    <span className="title">{articleTitleSelect(lang, articleObj)}</span>
                    <p>
                        { content } 
                    </p>
                </SData>
                <div className="check-link">
                    <CheckButton onClick={() => { 
                            handleView(handleProps) 
                            }}></CheckButton>
                </div>
            </SBlock>
            }
        </SWrap>
    )

}