import styled from "styled-components"
import publicPath from "src/utilty/publicPath"
import HeadLine from "@/components/Atoms/HeadLine"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { langType } from "src/types/lang"
import getLayer from "src/utilty/getLayer"
import { PathAnalysisValue } from "src/utilty/pathAnalyzer"
import { tsutaeruToEastJa } from "src/utilty/easyJaCheker"
import { TopSectionCotainerTemplate } from "@/components/Templates/Responsive/topSectionContainer"

const SSection = styled.section`
    position: relative;
    background:url(${publicPath('images/top/aboutBk.png')}) no-repeat;
    background-size:100% auto;
    background-position: bottom;
    width: 100%;
    height: 100%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        background: url('images/pc/bk_about.webp') no-repeat;
        background-size: auto 100%;
    }
`

const SHeadline = styled.h2`
    position: absolute;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    top:0;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 350px;
        transform: translate(-50% ,-15%);
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 440px;
    }
`

const SLinks =  styled.ul`
    position: absolute;
    top: 21%;
    width: ${props => props.theme.topPage.links.ulWidth};
    left: 50%;
    transform: translateX(-50%);
    li{
        width: ${props => props.theme.topPage.links.liWidth};
        margin-top: -8px;
        &:first-child{
            width: 100%;
        }
        &:nth-child(2n){
            margin-top: ${props => props.theme.topPage.links.oddMarginTop};
            margin-left: ${props => props.theme.topPage.links.oddOffest};
        }
        &:last-child{
            margin-top: ${props => props.theme.topPage.links.oddMarginTop};
            margin-left: 0;
            width: 100%;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        display: flex;
        flex-wrap: wrap;
        top:35%;
        
        li{
            flex-basis: 33%;
            margin: 0;
            transform: rotate(2deg);
            &:nth-child(2n){
                margin: 0;
            }
        }
    }
`

const SLinkPositon = styled.div`
    
`

const AboutSection = ({pathValue}:{pathValue: PathAnalysisValue}) => {
  
    const allowLang = tsutaeruToEastJa(pathValue)
    return (
        <SSection>
            <TopSectionCotainerTemplate>
            <SHeadline>
                <HeadLine lang="ja" nameKey="about"></HeadLine>
            </SHeadline>
            <SLinks>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/about/fio?lang=${pathValue.lang}`}
                        linkKey="fio"
                    ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/about/sitepolicy?lang=${pathValue.lang}`}
                        linkKey="sitepolicy"
                    ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/about/privacy?lang=${pathValue.lang}`}
                        linkKey="privacy"
                    ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/about/accessibility?lang=${pathValue.lang}`}
                        linkKey="accessibility"
                    ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/about/linkforsite?lang=${pathValue.lang}`}
                        linkKey="linkforsite"
                    ></YellowArrowLink>
                </li>
            </SLinks>
            </TopSectionCotainerTemplate>
        </SSection>    
    )
}

export default AboutSection