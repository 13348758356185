import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"

const SDiv = styled.div`
    .deco{
        li{
           
            position: relative;
            p{
            font-size: 2.2rem;
                font-weight: bold;
            }
            .large{
                color: ${props => props.theme.colors.yellow};
                font-size: 4.0rem;
                line-height: 4.8rem;
                text-align: center;
                .xl{
                    font-size: 4.2rem;
                }
            }
            padding-bottom: 5%;
            margin-bottom: 10px;
            &::after{
                content: '';
                width: 100%;
                aspect-ratio: 20 / 1;
                bottom: 0;
                left: 0;
                position: absolute;
                background: url(${publicPath('images/ster.png')}) no-repeat;
                background-size: auto 100%;
                background-position: center;
            }
            &:last-child{
                margin-bottom: 0px;
                &::after{
                    display: none;
                }
            }
        }   
    }
    .navi-position{
        position: relative;
        width: 100%;
        aspect-ratio: 10 / 5;
        figure{
            position: absolute;
            top: -20px;
            z-index: 11;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .deco{
            li{
                text-align: center;
            }
            li:nth-child(3),li:nth-child(4){
                padding-top: 20px;
                &::after{
                    display: none;
                }
            }
        }
        .navigator{
            width: 400px;
        }
    }
`


const BasciInfoPopulation = ({lang}:{lang: langType}) => {

    setTitleDiscrption({
        layer:'basicinfo',
        lang:lang,
        subLayer:"population"
    })

    return(
        <SDiv>
        {lang === 'ja' &&
            <LangJa></LangJa>
        }
        {
        lang === 'easy_ja' &&
            <LangJa></LangJa>
        }
        {lang === 'en' &&
            <LangEn></LangEn>
        }
        {lang === 'ko' &&
            <LangKo></LangKo>
        }
        {lang === 'zh_cn' &&
            <LangZhCn></LangZhCn>
        }
        {lang === 'zh_tw' &&
            <LangZhTw></LangZhTw>
        }
        {lang === 'th' &&
            <LangTh></LangTh>
        }
        {lang === 'vi' &&
            <LangVi></LangVi>
        }
        {lang === 'ne' &&
            <LangNe></LangNe>
        }

        </SDiv>
    )
}

export default BasciInfoPopulation