
import styled from "styled-components"
import { useState } from "react"
import publicPath from "src/utilty/publicPath"
import LangJa from "./ja";
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang";

const SPageWrap = styled.div`

    .tabnav-custom{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        li{
            flex-basis: 32%;
            margin-bottom: 10px;
            text-align: center;
            button{
                width: 100px;
                height: 60px;
                padding: 0;
                &.active,&:hover{
                    color: #fff;
                    background: ${props=> props.theme.colors.red}
                }
            }
        }
        @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
            li{
                flex-basis: 20%;
                box-sizing: border-box;
                button{
                    width: 160px;
                }
            }
        }
    }
    

    .decoration{
        .star{
            text-align: center;
            &::after{
                content:'';
                display:block;
                width: 100%;
                height: 40px;
                background: url(${publicPath('images/startMark.svg')}) no-repeat;
                background-size: auto 40%;
                background-position:center;
            }
    
        }
        .txt-middle{
            font-size: 1.7rem;
            line-height: 2.4rem;
        }
        .txt-large{
            font-size: 3.0rem;
            line-height: 3.4rem;
        }
    }
    .c-h1{
        text-align: center;
        border: none;
    }

    .space-deco{
        position: relative;
        width: 100%;
        aspect-ratio: 10/1.6;
        background: url(${publicPath('images/arrowDonw.png')}) no-repeat;
        background-position: center;
        background-size: auto 100%; 
        margin-top: -8%;
        z-index: 11;
        @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
            aspect-ratio: 100/6;
            margin-top: -15px;
        }
    }
    
    nav.tab{
        h3{
            background: ${props => props.theme.colors.red};
            text-align: center;
            color: #fff;
            padding: 5px 8px;
        }
        table{
            width: 100%;
            border-collapse:collapse;
            th,td{
                border: solid 1px ${props => props.theme.colors.black};
                white-space: pre-wrap;
                font-size: 1.4rem;
            }
            td{
                text-align: center;
                padding: 10px 0;
                &:first-child{
                    width: 16%
                }
                &:nth-child(2){
                    width: 40%
                }
                &:last-child{
                    width: 18%;
                }
            }
            thead{
                background: #A0D2F2;
            }
            tbody{
                background: #fff;
            }
            &.en{
                thead{
                    th,td{ font-size: 1.2rem}
                    th{
                        &:nth-child(4){ writing-mode: vertical-rl;}
                    }
                }
            }
        }
    }

    &.foreign{
        .tabnav-custom{
            li{
                button{ min-width: 200px; width:auto}
            }
            @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
                li{
                    button{ min-width: 180px; width:auto; font-size:1.4rem }
                }
            }
        }
        nav.tab{
            table{
                td{
                    font-size: 1.2rem;
                    padding: 2px;
                }
            }
        }
        
    }
`




export const MedicalTbody = ({medicals}) => {
    return (
        <tbody>
            {
                medicals.map((m,i) => (
                    <tr key={`m-${i}`}>
                        <td>{m[0]}</td>
                        <td>{m[1]}</td>
                        <td>{m[2]}</td>
                        <td>{m[3]}</td>
                        <td>{m[4]}</td>
                        <td>{m[5]}</td>
                    </tr>
                ))
            }
        </tbody>
    )
}

const ForeignPatientsMedical = ({lang}:{lang:langType}) => {
    const [active, onActive] = useState<number>(0)

    const langProps = {
        active: active,
        onActive : onActive,
    }   
    

    return (
       
        <SPageWrap className={lang !='ja' ? 'foreign' : ''  }>
            {lang === 'ja' &&
                <LangJa {...langProps}></LangJa>
            }
             {lang === 'easy_ja' &&
                <LangJa {...langProps}></LangJa>
            }
            {lang === 'en' &&
                <LangEn {...langProps}></LangEn>
            }
            {lang === 'ko' &&
                <LangKo {...langProps}></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn {...langProps}></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw {...langProps}></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh {...langProps}></LangTh>
            }
            {lang === 'vi' &&
                <LangVi {...langProps}></LangVi>
            }
            {lang === 'ne' &&
                <LangNe {...langProps}></LangNe>
            }
        </SPageWrap>
    )
}

export default ForeignPatientsMedical