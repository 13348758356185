import InnerBox from "@/components/Atoms/Box/innerBox"
import Article from "@/components/Organisms/Article"
import styled from "styled-components"
import Data from "src/json/fukuoka_jp_school.json"
import { DlNote } from "@/components/Atoms/TextBlock/dlNote"
import { replaceEmailAndUrlWithATag } from "src/utilty/replaceContents"
import parse from 'html-react-parser';
import { useEffect, useState } from "react"
import { NavigatorNice } from "@/components/Atoms/Character/navigator"
import { TabTamplate } from "@/components/Templates/tab"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang"

const SPageWrap = styled.div`
    a:hover{
        color:${props=> props.theme.colors.red};
    }
    .tabs-custom{
        nav{
            display: flex;
            flex-wrap: wrap;
            > div{ 
                flex-basis: 32%; 
                box-sizing: border-box; 
                padding-bottom: 10px;
                position: relative;
            }
            button{ 
                width: 100%;
                height: 56px;
                white-space: pre-wrap;
                cursor: pointer;
                font-weight: bold;
                padding: 0;
                border: solid 1px  ${props=> props.theme.colors.gray};
                &.active,&:hover{
                    color: #fff;
                    background: ${props=> props.theme.colors.red}
                }
            }
            &.en{
                > div{  flex-basis: 48%; }
                button{ font-size: 1.4rem}
            }
        }
        .secondNav{
            position: absolute;
            display: none;
            width: 100%;
            z-index: 11;
            li{
                width: 100%;
                padding:12px 10px;
                text-align: center;
                top: 0;
                background: #fff;
                border: solid 1px  ${props=> props.theme.colors.gray};
                border-bottom: none;
                font-weight: bold;
                cursor: pointer;
                &:last-child{
                    border-bottom: solid 1px  ${props=> props.theme.colors.gray};
                }
                &:hover{
                    color: #fff;
                    background: ${props=> props.theme.colors.red}
                }
            }
            &.active{
                display: block;
            }
           
        }
    }
    .profile{
        display: none;
        &.active{
            display: block;
        }
    }
`
function startsWithF1(text) {
    return /^F1/.test(text);
}

const SchoolNav = ({area, onProf, setOnProf, lang, ...props}) => { 
    
    const nameLang  = lang === 'ja' ? 'ja' :  'en'
    return (
        <div>
        {
            area.subArea.length > 0 &&
            <>
                <button onClick={() => { setOnProf(area.id) }} 
                    className={startsWithF1(onProf) ? 'active' : ''}>
                    {area.name[nameLang]}
                </button>
                <nav className={`secondNav ${onProf === 'F1' ? 'active' : ''}`}>
                    <ul>
                        {area.subArea.map((subarea, i) => (
                            <li onClick={() => {setOnProf(subarea.id)}} 
                                key={`nav-${subarea.id}`}>
                                {subarea.name[nameLang]}
                            </li>
                        ))}
                    </ul>
                </nav>
            </>
        }
        {
            area.subArea.length === 0 &&
            <button onClick={() => { setOnProf(area.id) }} 
            className={onProf === area.id ? 'active' : ''}>
                {area.name[nameLang]}
            </button>
        }
        </div>
    )
}

const SchoolProfies = ({onProf, area, lang}) => {
    const shoolData = Data.data

    const [active, setArcive] = useState<string>()
    const nameLang  = lang === 'ja' ? 'ja' :  'en'

    useEffect(() => {
     //   onProf === 'F1' ? setArcive('F1-1') : setArcive(onProf)
    },[onProf])
    

    return (
        <>
        {
             area.subArea.length > 0 &&
             <>
             {area.subArea.map((subarea, i) => (
                <DlNote color="red" key={`${subarea.id}`}>
                    <dl className={ 
                        onProf === subarea.id ? 'profile active' : 
                        subarea.id === 'F1-1' && onProf === 'F1' ?  'profile active' : 'profile'}>
                        <dt>{subarea.name[nameLang]}</dt>
                        {shoolData[subarea.id].map((profile, i) => (
                        <dd key={`${subarea.id}-profile-${i}`}>
                            {parse(replaceEmailAndUrlWithATag(profile))}
                        </dd>
                        ))}
                    </dl>
                </DlNote>
            ))}
            </>
        }
        {
             area.subArea.length === 0 &&
             <DlNote color="red">
                 <dl className={onProf === area.id ? 'profile active' : 'profile'}>
                    <dt>{area.name[nameLang]}</dt>
                    {shoolData[area.id].map((profile, i) => (
                        <dd key={`${area.id}-profile-${i}`}>
                            {profile}
                        </dd>
                    ))}
                </dl>
             </DlNote>
        }
        </>
    )
}


const EducationVolunteer = ({lang}:{lang:langType}) => {

    const areaList =[ {
            id : 'F1',
            name: {ja: '福岡市内', en: 'Fukuoka City'},
            subArea : [ 
                { id : 'F1-1', name : {ja: '中央区',en: 'Chuo Ward'} }, 
                { id : 'F1-2', name : {ja: '東区',en: 'Higashi Ward'} }, 
                { id : 'F1-3', name : {ja: '博多区',en: 'Hakata Ward'} }, 
                { id : 'F1-4', name : {ja:  '南区',en: 'Minami Ward'} }, 
                { id : 'F1-5', name : {ja: '城南区',en: 'Jonan Ward'} }, 
                { id : 'F1-6', name : {ja: '早良区',en: 'Sawara Ward'} }, 
                { id : 'F1-7', name : {ja: '西区',en: ' Nishi Ward'} },
            ],
        },
        {
            id : 'F2',
            name: {ja: '福岡地方\n（福岡市外）', en: 'Fukuoka Region\n(Outside Fukuoka City)'},
            subArea :[]
        },
        {
            id : 'F3',
            name: {ja: '北九州地区', en:  ' Kitakyushu Area'},
            subArea :[]
        },
        {
            id : 'F4',
            name: {ja: '北九州地区\n(北九州市外)', en: ' Kitakyushu Area\n(Outside Kitakyushu City)'},
            subArea :[]
        },
        {
            id : 'F5',
            name: {ja: '筑後地区', en: 'Chikugo Region'},
            subArea : [],
        },
        {
            id : 'F6',
            name: {ja: '筑豊地区', en: 'Chikuho Region'},
            subArea : [ ],
        },
    ]

    const [onProf, setOnProf] = useState("F1-1")


    return (
       
        <SPageWrap>
             <Article>
             {lang === 'ja' &&
            <LangJa></LangJa>
            }
            {lang === 'easy_ja' &&
                    <LangJa></LangJa>
            }
            {lang === 'en' &&
                <LangEn></LangEn>
            }
            {lang === 'ko' &&
                <LangKo></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh></LangTh>
            }
            {lang === 'vi' &&
                < LangVi ></LangVi >
            }
            {lang === 'ne' &&
                <LangNe></LangNe>
            }

            <InnerBox bottom={10} left={0} right={0}>
            <TabTamplate>
            <div className="tabs-custom">
                <nav className={lang !== 'ja' ? 'en' : ''}>
                {
                    areaList.map((area ,i) => (
                        <SchoolNav
                            area={area}
                            lang={lang}
                            onProf={onProf}
                            setOnProf={setOnProf}
                            key={`s${i}`}
                        ></SchoolNav>))
                }
                </nav>
            </div>
            </TabTamplate>
            </InnerBox>
            <InnerBox top={10} bottom={10} left={0} right={0}>
            {
                areaList.map((area ,i) => (
                
                    <SchoolProfies onProf={onProf} area={area} key={`sp-${i}`} lang={lang}></SchoolProfies>
              
                ))
            }
            </InnerBox>
            <InnerBox bottom={20}  left={0} right={0}>
                <NavigatorNice dir="right"></NavigatorNice>
            </InnerBox>
            </Article>
        </SPageWrap>
    )
}

export default EducationVolunteer