const LangNe = () => {

    return (<>
    <h1>साइट नीति</h1>
    <p>
    「FUKUOKA IS OPEN प्रवर्द्धन परियोजना कार्यकारी समिति（तल、「कार्यकारी समिति」भनिन्छ।) 」द्वारा प्रदान गरिएको पोर्टल साइट （https://www.fisop.net भित्र पोर्टल साइटलाई संकेत गर्दै、तल「पोर्टल साइट 」भनिन्छ।) यसलाई प्रयोग गर्नेहरूका लागि、कृपया निम्न जानकारी पढ्नुहोस्।कृपया、निम्न जानकारी सुचना बिना परिवर्तन हुन सक्ने भएकाले、ध्यान दिनुहोला।
    </p>
    <p>
    <dl>
    <dt>
    ●अस्वीकरणको बारेमा
    </dt>
    <dd>
    <p>
    「यस पोर्टल साइटमा पोस्ट गरिएको जानकारीको सामग्री को बारेमा、सबै सम्भावित सावधानी अपनाइरहेका छौं यद्यपि、सामग्रीको शुद्धता वा सुरक्षाको ग्यारेन्टी दिदैनौं। कार्यकारी समितिले यस वेबसाइटमा पोस्ट गरिएको जानकारी प्रयोग भएर भएको क्षति、कुनै पनि नोक्सानको लागि जिम्मेवार छैनौं।
    </p>
    <p>
    थप रूपमा, यो पोर्टल साइटको कार्यकारी समिति बाहेक अन्य पक्षहरू द्वारा व्यवस्थित गृहपृष्ठहरूमा लिङ्क गर्न सक्छ, तर लिङ्क गरिएको गृहपृष्ठहरू कार्यकारी समितिद्वारा व्यवस्थित वा सञ्चालन गरेको नहुन सक्छ। यसबाहेक, कृपया ध्यान दिनुहोस् कि हामी सामग्रीको प्रामाणिकताको लागि जिम्मेवार हुन सक्दैनौं।
    </p>
    <p>
    साथै、यस पोर्टल साइटमा पोस्ट गरिएको जानकारीको बारेमा、सुचना बिना परिवर्तन वा मेटाउन सकिने हुनाले कृपया ध्यान दिनुहोस।
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●प्रतिलिपि अधिकारको बारेमा
    </dt>
    <dd>
    यस पोर्टल साइटमा पोस्ट गरिएको पाठ、लोगो、फोटो、भिडियो、सफ्टवेयर、र अन्य सबै जानकारीको प्रतिलिपि अधिकार कार्यकारी समिति वा तेस्रो पक्षको स्वामित्वमा छ जबसम्म अन्यथा निर्दिष्ट गरिएको छैन। प्रतिलिपि अधिकार कानून द्वारा अनुमति दिइएको मामलाहरू जस्तै निजी प्रयोग बाहेक, यो जानकारी प्रतिलिपि अधिकार धारकको पूर्व अनुमति बिना (प्रतिलिपि、परिमार्जन、 पुन: प्रिन्ट、वितरण、सार्वजनिक प्रसारण、आदि सहित) प्रयोग गर्न सकिँदैन। थप रूपमा, केही भिडीयोहरू、आदिको प्रतिलिपि अधिकार मूल लेखकको हो।
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●प्रयोग वातावरण(ब्राउजर)को बारेमा
    </dt>
    <dd>
    <p>
    यो पोर्टल साइटलाई、विभिन्न इन्टरनेट ब्राउजिंग सफ्टवेयर (ब्राउजर) नयाँ संस्करणमा प्रयोग गर्न सिफारिस गर्छौं।
    </p>
    <p>
    यदि माथिको बाहेक अन्य बाट यो पृष्ठलाई हेरेको खण्डमा、सही रूपमा नदेखाउन सक्ने हुनाले कृपया ध्यान दिनुहोस्।
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●बारम्बार हेरीएका पृष्ठहरूको बारेमा
    </dt>
    <dd>
    <p>
    तपाईको इन्टरनेट ब्राउजिङ सफ्टवेयर (ब्राउजर) ले तपाईले पहिले हेर्नुभएको साइटको समयको जानकारी (तपाईँको कम्प्युटरमा अस्थायी रूपमा भण्डारण गरिएको जानकारी) देखाउन सक्छ। त्यसकारण、बारम्बार हेरिने पृष्ठहरूको बारेमा、नयाँ जानकारी अपडेट（पुनः लोड ）गर्न सिफारिस गर्छौं।
    </p>
    <p>
    JavaScript को बारेमा
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●यो पोर्टल साइटमा、केहि भाग JavaScript प्रयोग गरिएको छ। यदि इन्टरनेटको ब्राउजरले JavaScript लाई समर्थन गर्दैन, वा यदि JavaScript असक्षम भयो भने, केहि सुविधाहरूले काम गर्ने छैनन्।
    </dt>
    <dd>
    <p>
    कृपया सबै प्रकार्यहरू प्रयोग गर्न、JavaScript समर्थन गर्ने इन्टरनेट ब्राउजर प्रयोग गर्नुहोस् वा、
    </p>
    <p>
    इन्टरनेटको ब्राउजर सेटिङहरूमा JavaScript सक्षम गर्नुहोस्।
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●・कानून、अध्यादेश、नियमहरू、सार्वजनिक व्यवस्था र नैतिकताको उल्लङ्घन गर्ने वा यस कार्यकारी समितिको कार्यमा हस्तक्षेप गर्ने वेब पृष्ठहरूमा कृपया लिङ्क नगर्नुहोस्।
    </dt>
    <dd>
    कानुनि शासन यस पोर्टल साइटको प्रयोग मा「साइट नीति」को व्याख्या र आवेदन、अन्यथा तोकिएको अवस्थामा बाहेक、<br/>
    जापान को कानून को अनुसार हुनेछ।यस पोर्टल साइटमा पहुँच साइट पहुँच गर्ने व्यक्तिको स्वतन्त्र इच्छाले गरिन्छ, र कृपया यो पोर्टल साइट आफ्नो जोखिममा प्रयोग गर्नुहोस्। यस पोर्टल साइट सम्बन्धी कुनै पनि विवादको लागि, फुकुओका जिल्ला अदालतको पहिलो उदाहरणको अदालतको रूपमा विशेष अधिकार क्षेत्र हुनेछ।
    </dd>
    </dl>
    </p>
    </>)
}

export default LangNe