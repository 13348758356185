import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { TrapezoidExcl } from "@/components/Molecules/CharactorHeadline/challenger"
import Article from "@/components/Organisms/Article"

const LangVi = () => {

    return (<>
    <Article offset={0} className="pc-noTopMargin">
    <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
        </div>
        <BlackNote>
        <ul className="deco">
        <li>
        <p className="large">
        ●Dân số tỉnh Fukuoka<br/>
        5.140.000 người
        </p>
        <p className="al-c">（Năm 2020・Thứ 9 toàn quốc）</p>
        <span className="txt-small">
        Tài liệu: [Báo cáo thường niên về dân số và hộ khẩu tỉnh Fukuoka năm 2022] Phòng điều tra thống kê tỉnh 
        </span>
        </li>
        </ul>
        </BlackNote>
    </div>
    </Article>
    <TrapezoidExcl dir="left">
    ●Diện tích tỉnh Fukuoka<br/>
    </TrapezoidExcl>
    <Article offset={0} className="pc-noTopMargin">
    <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
        </div>
        <BlackNote>
        <ul className="deco">
        <li>
        <p className="large">
        ●Diện tích tỉnh Fukuoka <br/>
        4,987㎢
        </p>
        <p className="al-c">（Năm 2020・Thứ 29 toàn quốc）</p>
        <span className="txt-small">
        Tài liệu: Viện lãnh thổ địa lý, Bộ lãnh thổ giao thông 
        <br/>
        「Điều tra diện tích đơn vị hành chính toàn quốc năm 2022」
        </span>
        </li>
        </ul>
        </BlackNote>
    </div>
    <div className="navi-position sp-only">
        <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    </Article>

    <TrapezoidExcl dir="left">
    ●Khí hậu tỉnh Fukuoka<br/>
    </TrapezoidExcl>
    <Article offset={0} className="pc-noTopMargin">
    <BlackNote>
    <ul className="deco pc-flex col-2">
    <li>
    <p className="large">
    Nhiệt độ trung bình năm<br/>
    17.3℃
    </p>
    </li>
    <li>
    <p className="large">
    Độ ẩm trung bình năm<br/>
    68％
    </p>
    </li>
    <li>
    <p className="large">
    Số giờ có nắng trong năm<br/>
    1.889,4 giờ
    </p>
    </li>
    <li>
    <p className="large">
    Lượng mưa trong năm<br/>
    1.686,9mm
    </p>
    <p>Chỉ số trung bình được tính trong 30 năm từ 1991 đến 2020</p>
    <p className="txt-small">
    Tài liệu: Đài quan sát khí tượng khu vực Fukuoka
    </p>
    </li>
    </ul>
   
    </BlackNote>
        <div className="sp-only">
            <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
    </Article>
    <InnerBox top={40} bottom={40}>
    </InnerBox>
    </>)
}

export default LangVi