
import InnerBox from "@/components/Atoms/Box/innerBox"
import {FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"

const LangJa = () => {

    return (
        <>
        <InnerBox>
        <h1>日本での住居の探し方</h1>
        <p>
        外国の方が日本で住居を見つけ、安心して生活するためには、以下のポイントを理解し、適切な手続きを進めることが大切です。地元のサポートや仲介業者の協力も頼りにしましょう。
        </p>
        </InnerBox>
        <BlackNote>
            <ul className="deco">
                <li>
                    <h4>１.物件の探し方</h4>
                    <p>
                    ●不動産情報ウェブサイト、アプリを活用して物件を探しましょう。<br/>
                    ●外国語に対応している不動産会社もあります。
                    </p>
                </li>
                <li>
                    <h4>２.賃貸住宅の種類</h4>
                    <p>
                    ●「民間住宅」：大家（家を貸す人）から借りる家<br/>
                    ●「公営住宅」：県や市などが貸す家。<br/>
                    ●「ＵＲ賃貸住宅」：「ＵＲ都市機構」が貸す家。保証人が不要
                    </p>
                </li>
                <li>
                    <h4>３.賃貸契約の基本</h4>
                    <p>
                    ●賃貸契約は通常、敷金（担保金）や礼金、家賃の支払いなどが含まれます。契約書の内容をよく理解し、条件を確認しましょう。
                    </p>
                </li>
                <li>
                    <h4>４.敷金と礼金</h4>
                    <p>
                    ●敷金は契約終了時に返却されるが、家賃滞納や部屋の汚れ、壊れた箇所がある場合などには差し引かれることがあります。礼金は返還されません。
                    </p>
                </li>
                <li>
                    <h4>５.保証人</h4>
                    <p>
                    ●一般的に契約の際には保証人が必要です。保証人は契約者が負担できない場合の責任を負います。または、家賃債務保証会社を利用する場合もあります。
                    </p>
                </li>
                <li>
                    <h4>６.インターネットと光熱水費</h4>
                    <p>
                    ●多くの住宅には、インターネット回線が整備されています。光熱費（電気、ガス、水道など）は基本的に家賃とは別に支払う必要があります。
                    </p>
                </li>
                <li>
                    <h4>７.家具・家電の有無</h4>
                    <p>
                    ●賃貸物件によっては、家具や家電が備え付けられていることがあります。確認してから入居するか、自分で用意するかを決めましょう。
                    </p>
                </li>
                <li>
                    <h4>８.住民登録</h4>
                    <p>
                    ●入居後は住民登録をする必要があります。これは市区町村役場で手続きします。
                    </p>
                </li>
                <li>
                    <h4>9.生活習慣やルール</h4>
                    <p>
                    ●日本の住まいでは、隣人との配慮やゴミの分別ルールなど、特有の生活習慣やマナーがあります。これらにも留意しましょう。
                    </p>
                </li>
            </ul>
        </BlackNote>
        <InnerBox top={20} bottom={20}>
            <FukidashiRedLeft> 
                下のリンクに家を探す時によく聞く用語集や「部屋探しのガイドブック」など外国の方が住居を探す際に役立つ情報が多言語で紹介されています。
            </FukidashiRedLeft>
            <h1>
            出入国在留管理庁HP<br/>「生活・就労ガイドブック」
            </h1>
            <ul className="links">
                <li> 
                    ●英語：
                    <a href="https://www.moj.go.jp/isa/content/001392830.pdf" target="_blnak">
                    https://www.moj.go.jp/isa/content/001392830.pdf
                    </a>
                </li>
                <li> 
                    ●中国語：
                    <a href="https://www.moj.go.jp/isa/content/001392831.pdf" target="_blnak">
                    https://www.moj.go.jp/isa/content/001392831.pdf
                    </a>
                </li>
                <li> 
                    ●韓国語：
                    <a href="https://www.moj.go.jp/isa/content/001393145.pdf" target="_blnak">
                    https://www.moj.go.jp/isa/content/001393145.pdf
                    </a>
                </li>
                <li> 
                    ●ベトナム語：
                    <a href="https://www.moj.go.jp/isa/content/001393146.pdf" target="_blnak">
                    https://www.moj.go.jp/isa/content/001393146.pdf
                    </a>
                </li>
                <li> 
                    ●タイ語：
                    <a href="https://www.moj.go.jp/isa/content/001392830.pdf" target="_blnak">
                    https://www.moj.go.jp/isa/content/001392839.pdf
                    </a>
                </li>
                <li> 
                    ●ネパール語：
                    <a href="https://www.moj.go.jp/isa/content/001392839.pdf" target="_blnak">
                    https://www.moj.go.jp/isa/content/001392839.pdf
                    </a>
                </li>
                <li> 
                    ●やさしい日本語：
                    <a href="https://www.moj.go.jp/isa/content/930004715.pdf" target="_blnak">
                    https://www.moj.go.jp/isa/content/930004715.pdf
                    </a>
                </li>
            </ul>
        </InnerBox>
        <InnerBox bottom={20}>
            <h1>公益財団法人日本賃貸住宅管理協会<br/>「外国人入居円滑化支援の御案内」</h1>
            <p>
                <a href="https://www.jpm.jp/foreign/" target="_blank">
                    https://www.jpm.jp/foreign/
                </a>
            </p>
        </InnerBox>
</>
    )
}

export default LangJa