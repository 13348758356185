
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { TabTamplate } from "@/components/Templates/tab"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import Article from "@/components/Organisms/Article";
import { MedicalTbody } from "."
import Data from 'src/json/ForeignPatientsMedical/zhcn.json'

const LangZhCn = ({onActive, active}) => {
    const medicals = Data.data

    return (
        <Article offset={0} className="pc-noTopMargin">
            <InnerBox>
                <h1>接待外国人患者的主要医疗机构。<br/></h1>
                <p>
                在福冈县，为了确保外国人患者能够在舒适的医疗环境下安心接受治疗，目前正在每个二次医疗圈内挑选能够接受外国患者的主要医疗机构，该医疗机构提供多语言支持和无现金支付等功能。
                </p>
                </InnerBox>
                <InnerBox left={0} right={0} bottom={30}>
                <BlackNote>
                <div className="decoration">
                <p className="al-c star">有哪些医疗机构？</p>
                <p className="txt-middle">
                以下是福冈县内接待外国人患者的主要医疗机构列表（截至2023年6月）。
                </p>
                <br/>
                <p className="txt-large al-c txt-yellow">
                无现金支付支持！
                </p>
                </div>
                </BlackNote>
                <div className="space-deco"></div>
            </InnerBox>
            <InnerBox>
            <TabTamplate>
                <ul className="tabnav-custom">
                    <li><button onClick={() => {onActive(0)}} className={`${active === 0 ? 'active' : ''}`}>Fukuoka City</button></li>
                    <li><button onClick={() => {onActive(1)}} className={`${active === 1 ? 'active' : ''}`}>Fukuoka Region<br/>（Outside Fukuoka City）</button></li>
                    <li><button onClick={() => {onActive(2)}} className={`${active === 2 ? 'active' : ''}`}>Chikugo Area</button></li>
                    <li><button onClick={() => {onActive(3)}} className={`${active === 3 ? 'active' : ''}`}>Chikuhō Area</button> </li>
                    <li><button onClick={() => {onActive(4)}} className={`${active === 4 ? 'active' : ''}`}>Kitakyushu Area</button></li>
                </ul>
            
                <nav className="tab">
                    <div className={`tab-content ${active === 0 ? 'active' : ''}`}>
                        <h3>Fukuoka City</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>市镇村名称</th>
                            <th rowSpan={2}>医疗机构名称</th>
                            <th colSpan={2}>可接受的患者</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>认证</th>
                            <th rowSpan={2}>可支持的语言</th>
                            </tr>
                            <tr>
                            <th>重症患者</th>
                            <th>轻症患者</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[0]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 1 ? 'active' : ''}`}>
                        <h3> Fukuoka Region (Outside Fukuoka City)</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>市镇村名称</th>
                            <th rowSpan={2}>医疗机构名称</th>
                            <th colSpan={2}>可接受的患者</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>认证</th>
                            <th rowSpan={2}>可支持的语言</th>
                            </tr>
                            <tr>
                            <th>重症患者</th>
                            <th>轻症患者</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[1]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 2 ? 'active' : ''}`}>
                        <h3>Chikugo Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>市镇村名称</th>
                            <th rowSpan={2}>医疗机构名称</th>
                            <th colSpan={2}>可接受的患者</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>认证</th>
                            <th rowSpan={2}>可支持的语言</th>
                            </tr>
                            <tr>
                            <th>重症患者</th>
                            <th>轻症患者</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[2]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 3 ? 'active' : ''}`}>
                        <h3>Chikuhō Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>市镇村名称</th>
                            <th rowSpan={2}>医疗机构名称</th>
                            <th colSpan={2}>可接受的患者</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>认证</th>
                            <th rowSpan={2}>可支持的语言</th>
                            </tr>
                            <tr>
                            <th>重症患者</th>
                            <th>轻症患者</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[3]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 4 ? 'active' : ''}`}>
                        <h3>Kitakyushu Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>市镇村名称</th>
                            <th rowSpan={2}>医疗机构名称</th>
                            <th colSpan={2}>可接受的患者</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>认证</th>
                            <th rowSpan={2}>可支持的语言</th>
                            </tr>
                            <tr>
                            <th>重症患者</th>
                            <th>轻症患者</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[4]}></MedicalTbody>
                        </table>
                    </div>
                   
                </nav>
            </TabTamplate>
            <br/>
            <p className="txt-small">
            ※（注１）上述医疗机构列表中未列出的医疗机构，外国人患者也可前往就医。（注２）上述医疗机构并非专门接待海外赴日就医患者的医疗机构。<br/>
            ※1 JMIP（日本国际患者医疗服务认证）→外国人患者受入医疗机构认证制度<br/>
            该制度于2012年由日本厚生劳动省提倡，通过第三方评估国内医疗机构是否提供多语言诊疗指导，考虑不同文化・宗教背景以及建立外国人患者友好的接待体系。该制度旨在确保所有外国人患者在前往国内医疗机构就诊时都能获得安心・可信赖的医疗服务。（认证机构为一般财团法人日本医疗教育财团）<br/>
            ※2 21种语言支持的医疗机构，请使用「福冈国际医疗支援中心」。
            </p>
            <NavigatorAction dir="right" charactorType="normal" actionType="pickup"/>
            <EmphasisNote>
            <h1 className="c-h1">想要了解其他信息?</h1>

            <p>
            全国各地的主要医疗机构信息公布在厚生劳动省和观光厅的官方网站上（以下URL）。<br/>
            </p>
            <p>
            厚生劳动省官方网址<br/>
            <a href="https://www.mhlw.go.jp/stf/newpage_05774.html" target="_blank">
            https://www.mhlw.go.jp/stf/newpage_05774.html
            </a><br/>
            </p>
            <p>
            观光厅官方网址<br/>
            <a href="https://www.jnto.go.jp/emergency/jpn/mi_guide.html" target="_blank">
            https://www.jnto.go.jp/emergency/jpn/mi_guide.html
            </a>
            </p>

            <p>
            以下网站可检索福冈县内医院・诊所・牙科诊所・儿科・助产所的相关信息以及提供儿科急救・预防・保健・福利的医疗信息。<br/>
            </p>
            <p>
            福冈医疗情报网站<br/>
            <a href="https://www.fmc.fukuoka.med.or.jp/" target="_blank">
            https://www.fmc.fukuoka.med.or.jp/
            </a>
            </p>
            </EmphasisNote>
            </InnerBox>
        </Article>
    )
}

export default LangZhCn