


const LangZhCn = () => {

    return (
        <div className='article-inner'>
        <section>
        <h1>九州的徽标</h1>
        <ul className="photos">
            <li>
                <img src="./images/kyushulogomark/logomark01.jpg" alt="The Kyushu Logo"/>
            </li>
            <li>
                <img src="./images/kyushulogomark/logomark02.jpg" alt="The Kyushu Logo ※のれん"/>
            </li>
        </ul>
        <p>
        该徽标用于在全国乃至全世界宣传九州。九州徽标以门帘*为主题，表达了通过九州门帘欢迎大家的愿望。 它由汉字 "九"、"州 "和 "一 "组成，代表九州的多个地区团结一致。<br/>
        ※门帘<br/>
        门帘是挂在商店门口的遮阳布。这是日本特有的文化，上面经常会展示商店的名称、商标。
        </p>
        <br/>
        </section>
        <section>
        <h1>九州简介</h1>
        <figure>
        <img src="./images/kyushulogomark/kyushu.jpg" alt="九州について"/>
        </figure>
        <p>九州位于日本的最西端，是一个可以尽情享受雄大山海、美丽自然、以及温泉、历史、文化和美食的地方。 更多信息，请观看视频。</p>
       
        
        <br/>
        <div className="youtube">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/5GNHVfoQd0s?si=JCxXRHx1bwIt-3EO" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        </section>
        <section>
            <h1>九州徽标在海外使用的状况</h1>
            <p>九州徽标也在海外使用。</p>
            <p><strong>◎BOKKSU株式会社</strong></p>
            <p> “九州果子BOX”文化指南中登载有九州徽标</p>
            <figure>
            <p className="note">“九州点心盒”荟萃了九州的各种点心，并配有说明书，介绍点心、九州历史、文化和旅游景点</p>

            <img src="./images/kyushulogomark/logomark-bkksu.jpg" alt="BOKKUS"/>
            </figure>
            <p><strong>◎株式会社MARUTAI</strong></p>
            <p>“棒拉面当地系列”（共 8 种产品）的包装背面印有九州徽标</p>
            <figure>
            <img src="./images/kyushulogomark/logomark-marutai.jpg" alt="Marutai"/>
            </figure>
            <p className="note">博多猪骨拉面、熊本黑MA油猪骨拉面、鹿儿岛黑猪猪骨拉面</p>

            <p><strong>◎株式会社DAISHO</strong></p>
            <p>“山药铁板烧套餐”的包装背面印有九州徽标</p>
            <figure>
            <img src="./images/kyushulogomark/yamaimoteppan.jpg" alt="山芋鉄板焼きの素"/>
            </figure>
        </section>
        <div className="deco-fukidashi navigatorCheck right smile">
        有关九州的详细旅游信息，请参阅“Visit Kyushu”。
        </div>
       
        <p>Visit Kyushu</p>
        <a href="https://www.welcomekyushu.com/" target="_blank">
            <figure>
            <img src='./images/banner/tabinet.png' alt="Visit Kyushu（九州旅ネット）" className="img-fit"/>
            </figure>
        </a>
        </div>
    )
}

export default LangZhCn;