const LangTh = () => {

    return (<>
    <h1>เกี่ยวกับการเข้าถึง(Accessibility)</h1>
    <dl>
    <dt>
    การดำเนินการ
    </dt>
    <dd>
    <p>จังหวัดฟุกุโอกะพยายามรักษาและพัฒนาการเข้าถึงเว็บไซต์ให้เป็นไปตามตามมาตรฐานอุตสาหกรรมของญี่ปุ่น "JIS X 8341-3:2016" เพื่อให้ไม่ว่าใครก็สามารถใช้งานได้</p>
    </dd>
    <dt>
    1. ขอบเขตที่ครอบคลุม
    </dt>
    <dd>
    <p>
    โฮมเพจทางการภายใต้โดเมน https://fisop.net
    </p>
    </dd>
    <dt>
    2. ระดับความสามารถในการเข้าถึงที่ตั้งเป้าไว้
    </dt>
    <dd>
    <p>
    ระดับความสามารถในการเข้าถึง: สอดคล้องกับมาตรฐานการเข้าถึงเว็บไซต์ JIS X 8341-3:2016 ระดับ AA<br/>
    (มีการปรับปรุงอย่างต่อเนื่องเพื่อให้บรรลุความสามารถในการเข้าถึงเว็บไซต์ขั้น 2 ระดับ AA)<br/>
    "สอดคล้องกับมาตรฐานการเข้าถึงระดับ AA" หมายถึง การยกระดับมาตรฐานการเข้าถึงให้ได้ระดับ AA โดยพัฒนาขึ้นจากการปฏิบัติตามความสอดคล้องระดับ A ในปัจจุบันซึ่งเป็นไปตามคู่มือเกี่ยวกับตัวบ่งชี้ระดับการปฎิบัติตามข้อกำหนดของเนื้อหาเว็บ "JIS X8341-3:2016 Compliance Level Indication Guidelines for Web Content, March 2016 Edition" ของคณะกรรมการโครงสร้างพื้นฐานการเข้าถึงเว็บ(Web Accessibility Infrastructure Committee (WAIC)) แห่งสภาการเข้าถึงข้อมูลและการสื่อสาร(Info-communication Access Council)<br/>
    <br/>
    <a href="https://waic.jp/docs/jis2016/compliance-guidelines/202104/" target="_blank">
    คู่มือเกี่ยวกับตัวบ่งชี้ระดับการปฎิบัติตามข้อกำหนดของเนื้อหาเว็บตามมาตรฐาน JIS X8341-3:2016
    (JIS X8341-3:2016 Compliance Level Indication Guidelines for Web Content) จะเปิดหน้าต่างใหม่ (ลิงก์ภายนอก)
    </a>
    </p>
    </dd>
    </dl>
    </>)
}

export default LangTh