import { PropsWithChildren } from "react"
import styled from "styled-components"


interface InnerBoxProps {
    top ?:number,
    bottom ?:number,
    right ?:number,
    left ?:number,
}

const InnerBox = ({
    top = 0,
    bottom = 0,
    right = 20,
    left = 20,
    children}:PropsWithChildren<InnerBoxProps>) => {
    
    const SInnerBox = styled.div`
        padding-top: ${top}px;
        padding-bottom: ${bottom}px;
        padding-left: ${left}px;
        padding-right: ${right}px; 
        max-width: 980px;
    `
    

    return (
        <SInnerBox>
            {children}
        </SInnerBox>
    )
}

export default InnerBox