
const LangJa = () => {

    return (
        <>
        <h1>アクセシビリティについて</h1>
        <dl>
            <dt>
            取り組み
            </dt>
            <dd>
            <p>福岡県では、誰もが利用できるよう、日本工業規格「JIS X 8341-3:2016」に基づき、ホームページのアクセシビリティの維持・向上に努めてまいります。</p>
            </dd>
            <dt>
            1.対象範囲
            </dt>
            <dd>
            <p>
            https://fisop.netドメイン配下の公式ホームページ
            </p>
            </dd>
            <dt>
            2.目標とする適合レベル
            </dt>
            <dd>
            <p>
            適合レベル：JIS X 8341-3:2016のウェブアクセシビリティ適合レベルAAに準拠<br/>
            （第2階層までのウェブアクセシビリティ適合レベルAAに準拠することを目標に継続的な修正を行います）<br/>
            「適合レベルAAに準拠する」という表記は、情報通信アクセス協議会ウェブアクセシビリティ基盤委員会「ウェブコンテンツのJIS X 8341-3:2016 対応度表記ガイドライン　2016年3月版」で定められた表記により、適合レベルAに準拠することに加え、適合レベルAAの達成基準を満たすことを意味します。<br/>
            <br/>
            <a href="https://waic.jp/docs/jis2016/compliance-guidelines/202104/" target="_blank">
            ウェブコンテンツのJIS X 8341-3:2016 対応度表記ガイドライン 別ウィンドウで開きます（外部リンク）
            </a> 
            </p>
            </dd>
        </dl>       
        </>
    )
}

export default LangJa