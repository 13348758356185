import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorGood, NavigatorPickUp } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"

const LangTh = () => {

    return (<>
    <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="normal" dir="right" actionType="pickup" actionColor="blue"/>
        </div>
        <BlackNote>
        <p className="large">GDP ของจังหวัดฟุกุโอกะ</p>
        <p className="xl">
        178,027 ล้านดอลลาร์สหรัฐ
        </p>
        <p className="middle txt-yellow">
        รายได้ต่อคนของผู้อยู่อาศัยในจังหวัด:<br/>
        24,790 ดอลลาร์สหรัฐ
        </p>
        <p className="al-c">(ข้อมูล ณ เดือนเมษายน 2565)</p>
        <span className="txt-small">
        ที่มา: ฝ่ายสำรวจสถิติประจำจังหวัด<br/>
        (รายงานการคำนวณเศรษฐกิจครัวเรือนภายในจังหวัดและเขตเมืองย่อยประจำปี 2563)<br/>
        คำนวณอัตราแลกเปลี่ยนที่ 106.09 เยนต่อดอลลาร์สหรัฐ (ค่าเฉลี่ยปี 2563)<br/>
        ที่มา: สำนักงานคณะรัฐมนตรี (รายงานเศรษฐกิจรายเดือน ดัชนีชี้วัดเศรษฐกิจที่สำคัญ)
        </span>
        </BlackNote>
    </div>
    <br/>
    <div className="navpicup sp-only">
        <NavigatorPickUp dir="right"></NavigatorPickUp>
    </div>
    <InnerBox top={20}>
    <EmphasisNote>
    <h2>เกษตรกรรม</h2>
    <p>
    จังหวัดฟุกุโอกะมีพื้นที่เพาะปลูก 78,900 เฮกตาร์ (ข้อมูลปี 2565) ซึ่งคิดเป็น 16% ของพื้นที่จังหวัด โดยมีสัดส่วนที่เป็นนาข้าวสูงถึง 81% (ค่าเฉลี่ยของประเทศ 54%)
    <br/>
    </p>
    <h2>ป่าไม้และอุตสาหกรรมป่าไม้</h2>
    <p>
    พื้นที่ป่าไม้ของจังหวัดฟุกุโอกะมีประมาณ 224,000 เฮกตาร์ ซึ่งคิดเป็น 45% ของพื้นที่จังหวัด ป่าไม้ช่วยกักเก็บและเติมน้ำให้แหล่งน้ำ ป้องกันการพังทลายของหน้าดิน และมีบทบาทสำคัญในการสร้างความมั่นใจต่อการใช้ชีวิตอย่างมั่นคงปลอดภัยของผู้ที่อยู่อาศัยในจังหวัด
    <br/>
    </p>
    <h2>อุตสาหกรรมประมง
    </h2>
    <p>
    ในจังหวัดฟุกุโอกะมีการประมงและเพาะเลี้ยงสัตว์น้ำที่หลากหลายแตกต่างกันไปตามลักษณะเฉพาะของพื้นที่ เช่น พื้นที่ทะเลอย่าง ทะเลชิคุเซ็น ทะเลอาริอาเกะ ทะเลบูเซ็น และแหล่งน้ำจืดอย่างแม่น้ำและทะเลสาบ<br/>
    มีผลิตภัณฑ์อาหารทะเลมากมายหลายชนิด รวมถึงปลากระพงแดงและสาหร่ายซึ่งจังหวัดฟุกุโอกะเป็นหนึ่งในผู้ผลิตที่ดีที่สุดของประเทศ
    <br/>
    </p>
    <h2>การพาณิชย์และอุตสาหกรรม</h2>
    <p>
    จังหวัดฟุกุโอกะเน้นพัฒนาอุตสาหกรรมวัสดุพื้นฐาน เช่น เหล็ก ถ่านหิน และเคมีภัณฑ์ รวมถึงอุตสาหกรรมที่เกี่ยวข้องกับชีวิตประจำวัน เช่น อาหาร มาโดยตลอด แต่ในช่วงไม่กี่ปีที่ผ่านมาได้มีการเทน้ำหนักไปยังอุตสาหกรรมที่ต้องใช้การแปรรูปและการประกอบในอุตสาหกรรมที่เกี่ยวข้องกับยานยนต์มากขึ้น<br/>
    นอกจากนี้ ยังเน้นอุตสาหกรรมการผลิตอาหารมากขึ้น เนื่องจากมีผลิตภัณฑ์ทางการเกษตร ป่าไม้ และประมงคุณภาพสูงมากมาย ตลอดจนอยู่ใกล้กับพื้นที่การบริโภคขนาดใหญ่และสภาพแวดล้อมการกระจายสินค้าที่ดี
    </p>
    </EmphasisNote>
    </InnerBox>
    <InnerBox top={20}>
    <h1>
    Growth Industry<br/>
    อุตสาหกรรมที่มีการเติบโตซึ่งจะช่วยเกื้อหนุนอนาคตของฟุกุโอกะ
    </h1>
    <p>
    ฟุกุโอกะมีมหาวิทยาลัย, สถาบันการวิจัยขั้นสูง และอุตสาหกรรมการผลิตซึ่งมีเทคโนโลยีล้ำสมัยอยู่จำนวนมาก<br/>
    ทางจังหวัดมุ่งเน้นส่งเสริมการเข้าร่วมและร่วมทุนของบริษัทในหมวดหมู่อุตสาหกรรมที่มีการเติบโตเหล่านี้ซึ่งเป็นเป้าหมายในการขยายตลาด
    </p>
    <EmphasisNote>
    <h2>1. อุตสาหกรรมชีวภาพ</h2>
    <p className="txt-middle">
    ฟุกุโอกะเป็นที่ยอมรับในฐานะชุมชนชีวภาพชั้นแนวหน้าและอยู่ในระหว่างการเร่งพัฒนาเป็นเมืองศูนย์กลางเทคโนโลยีชีวภาพขั้นสูง
    </p>
    <p>
    จังหวัดฟุกุโอกะทำงานร่วมกับเมืองคุรุเมะมาตั้งแต่ปีพ.ศ. 2544 โดยใช้ "Fukuoka Prefectural Bio Industry Center Promotion Conference" ซึ่งเป็นการรวมตัวของภาคอุตสาหกรรม, ภาคการศึกษา และภาครัฐบาลเป็นฐานในการรวบรวมกลุ่มบริษัทที่เกี่ยวข้องด้านชีวภาพ<br/>
    ซี่งความร่วมมือดังกล่าวสามารถเพิ่มจำนวนบริษัทที่เกี่ยวข้องด้านชีวภาพจาก 32 บริษัทในช่วงแรกขึ้นเป็นกว่า 230 บริษัท
    ในฟุกุโอกะมี Bioventure ซึ่งเป็นที่จับตามองจากทั้งในประเทศญี่ปุ่นเองและจากต่างประเทศเกิดขึ้นอย่างต่อเนื่อง ตัวอย่างเช่น บริษัท Bonac ที่ได้รับความสนใจจากแนวทางการแพทย์วิถีใหม่จากการพัฒนายาต้าน COVID-19 ด้วยกรดนิวคลีอิก และบริษัท EditForce ที่มีจุดแข็งด้านเทคโนโลยีการปรับแต่งจีโนม เป็นต้น<br/>
    ข้อได้เปรียบเหล่านี้ทำให้จังหวัดฟุกุโอกะได้รับการประเมินค่อนข้างสูง และได้รับการรับรองจากสำนักงานคณะรัฐมนตรีให้เป็น "ชุมชนชีวภาพระดับภูมิภาค" แห่งเดียวในเขตญี่ปุ่นตะวันตก (มีทั้งหมด 4 แห่งทั่วประเทศ) ในเดือนมิถุนายน พ.ศ.2564
    </p>
    <h2>2. อุตสาหกรรมอวกาศ</h2>
    <p className="txt-middle">
    จังหวัดฟุกุโอกะได้รับเลือกให้เป็นรัฐบาลท้องถิ่นแห่งแรกในภูมิภาคคิวชูที่ทำการส่งเสริมการสร้างธุรกิจอวกาศ ท้าทายวงการอวกาศด้วยเทคโนโลยีทั้งฮาร์ดแวร์และซอฟต์แวร์ที่พัฒนาในฟุกุโอกะ
    </p>
    <p>
    ในจังหวัดฟุกุโอกะเป็นแหล่งรวมของบริษัทที่มีศักยภาพในการทำธุรกิจอวกาศ เช่น อุตสาหกรรมการผลิตซึ่งมีเทคโนโลยีล้ำสมัย และ IT venture ที่สร้างบริการใหม่ๆ ไม่เหมือนใคร เป็นต้น<br/>
    iQPS Inc. (เมืองฟุกุโอกะ) ประสบความสำเร็จในการพัฒนาและปล่อยดาวเทียม SAR ดาวเทียมขนาดเล็กที่ได้มาตรฐานสูงสุดในโลกซึ่งสามารถสังเกตพื้นผิวโลกได้ไม่ว่าจะเป็นเวลากลางวัน กลางคืน หรือในสภาพอากาศแบบใดก็ตาม อีกทั้งยังประสบความสำเร็จในการถ่ายภาพด้วยความละเอียดสูงสุดของโลกในเดือนพฤษภาคม พ.ศ.2564 。iQPS ตั้งเป้าที่จะขยายระบบเพื่อให้สามารถสังเกตพื้นผิวโลกได้ในทุก 10 นาทีด้วยกลุ่มดาวเทียม 36 ดวงหลังให้ได้ตั้งแต่ปี พ.ศ.2568<br/>
    ในเดือนกันยายน พ.ศ.2563 สำนักงานคณะรัฐมนตรีได้เลือกจังหวัดฟุกุโอกะเป็นรัฐบาลท้องถิ่นแห่งแรกในภูมิภาคคิวชูที่ส่งเสริมการสร้างธุรกิจอวกาศ
    </p>
    <h2>3. บล็อกเชน</h2>
    <p className="txt-middle">
    รวมบุคลากรชั้นแนวหน้าและเทคโนโลยีล้ำสมัยของญี่ปุ่นเพื่อสร้างเป็นศูนย์กลางบล็อกเชนชั้นนำของประเทศญี่ปุ่น
    </p>
    <p>
    บล็อกเชนที่ถูกพัฒนาขึ้นมาเป็นเทคโนโลยีสำหรับจัดการสินทรัพย์ดิจิทัลเป็นที่จับตามองจากความสามารถในการตรวจสอบย้อนกลับ, ป้องกันการปลอมแปลงข้อมูล และลดข้อผิดพลาดในการทำงาน<br/>
    นอกจากจังหวัดฟุกุโอกะจะเป็นที่ตั้งของบริษัทสตาร์ทอัพด้านบล็อกเชนและวิศวกรสายไอทีทักษะสูงเป็นจำนวนมาก ยังมีคณะวิทยาศาสตร์และวิศวกรรมศาสตร์สาขาคอมพิวเตอร์ของ Kyushu Institute of Technology และคณะวิทยาศาสตร์และวิศวกรรมศาสตร์สาขาอุตสาหกรรมของ Kindai University ซึ่งเป็นผู้นำการวิจัยในสาขาบล็อกเชนและผลิตวิศวกรที่ยอดเยี่ยม<br/>
    เพื่อใช้ประโยชน์จากข้อได้เปรียบในการส่งเสริมอุตสาหกรรมไอทีทั้งหลายนี้ ตั้งแต่ปีพ.ศ.2563 เป็นต้นมาจังหวัดฟุกุโอกะได้เริ่มการสนับสนุนอย่างเต็มรูปแบบสำหรับการวิจัยและพัฒนาเทคโนโลยีบล็อกเชนรวมถึงการพัฒนาบุคลากร และกำลังกลายเป็นศูนย์กลางที่สำคัญของบล็อกเชนในญี่ปุ่น
    </p>
    <h2>4. อุตสาหกรรมพลังงาน</h2>
    <p className="txt-middle">
    พัฒนาอุตสาหกรรมพลังงานไฮโดรเจน เชิญชวนบริษัทพลังงานลมให้เข้ามาลงทุนมากขึ้น และริเริ่มการผลิตไฟฟ้าจากพลังงานลมนอกชายฝั่ง
    </p>
    <p>
    ประเทศญี่ปุ่นกำลังพยายามทำให้พลังงานหมุนเวียนเป็นแหล่งพลังงานหลักที่ใช้ในการผลิตไฟฟ้า โดยมีเป้าหมายเพื่อก้าวไปสู่ความเป็นกลางทางคาร์บอนภายในปี พ.ศ.2593ในบรรดาแหล่งพลังงานหมุนเวียน การผลิตไฟฟ้าจากพลังงานลมนอกชายฝั่งเป็นตัวเลือกที่คาดว่าเหมาะสมสำหรับประเทศญี่ปุ่นซึ่งล้อมรอบด้วยมหาสมุทรทั้ง 4 ด้าน<br/>
    นอกจากส่งเสริมให้มีการริเริ่มผลิตไฟฟ้าจากพลังงานลมนอกชายฝั่ง จังหวัดฟุกุโอกะยังทำงานร่วมกับภาคอุตสาหกรรม สถาบันการศึกษา และรัฐบาลเพื่อเชิญชวนบริษัทพลังงานลมให้เข้ามาลงทุนมากขึ้นจังหวัดยังได้จัดตั้ง "Fukuoka Strategy Conference for Hydrogen Energy" ซึ่งเป็นความร่วมมือระหว่างภาคอุตสาหกรรม สถาบันการศึกษา และรัฐบาล โดยมีเป้าหมายเพื่อสร้างสังคมพลังงานไฮโดรเจนที่เป็นมิตรต่อสิ่งแวดล้อม<br/>
    นอกจากการวิจัยและพัฒนาในหลากหลายด้านตั้งแต่การผลิต จัดเก็บ ขนส่ง ไปจนถึงใช้ประโยชน์พลังงานไฮโดรเจน ยังดำเนินการเกี่ยวกับการพัฒนาผลิตภัณฑ์และพัฒนาบุคลากร รวมถึงสนับสนุนการพัฒนาและรวมกลุ่มของบริษัทที่เกี่ยวข้องกับพลังงานไฮโดรเจนด้วย
    </p>
    <h2>5. อื่นๆ</h2>
    <p className="txt-middle">
    อุตสาหกรรมหลักที่มีการเติบโตในฟุกุโอกะและภูมิภาคคิวชู
    </p>
    <p>
    อุตสาหกรรมที่ประสบความสำเร็จและถือเป็นอุตสาหกรรมที่มีการเติบโตในภูมิภาคคิวชู ได้แก่ อุตสาหกรรมสิ่งแวดล้อม, อุตสาหกรรมเกษตรป่าไม้และประมง และอุตสาหกรรมยานยนต์<br/>
    ●อุตสาหกรรมสิ่งแวดล้อม<br/>
    นอกจากการรีไซเคิลขวดพลาสติกและเครื่องใช้ไฟฟ้าภายในบ้านแล้ว ยังมีการรีไซเคิลโลหะหายากที่ใช้ในแบตเตอรี่ลิเธียมไอออนและผลิตภัณฑ์อื่นๆ เพื่อให้แน่ใจว่าทรัพยากรที่มีจำกัดจะไม่สูญเปล่า<br/>
    ●อุตสาหกรรมเกษตร ป่าไม้ และประมง<br/>
    อุตสาหกรรมเกษตร ป่าไม้ และประมงเป็นอุตสาหกรรมที่เฟื่องฟูในภูมิภาคคิวชู โดยคิดเป็น 20% ของผลิตภัณฑ์ทางการเกษตรและการประมงที่ผลิตในประเทศญี่ปุ่น
    โดยเฉพาะฟุกุโอกะที่มีผลิตภัณฑ์ทางการเกษตรและทางทะเลซึ่งเป็นที่ยอมรับอย่างสูงทั้งในประเทศและต่างประเทศ เช่น สตรอเบอร์รี่อะมะโอ และชายาเมะ เป็นต้น<br/>
    ●อุตสาหกรรมยานยนต์<br/>
    ผู้ผลิตรถยนต์รายใหญ่อย่างนิสสัน โตโยต้า และไดฮัทสุมีฐานการผลิตอยู่ทางตอนเหนือของภูมิภาคคิวชู โดยสัดส่วนการผลิตรถยนต์มีมากกว่า 15% ของตลาดภายในประเทศ
    </p>
    </EmphasisNote>
    <br/>
    <div className="sp-only">
        <NavigatorGood dir="right"></NavigatorGood>
    </div>
    </InnerBox>
    </>)
}

export default LangTh