import { SearchInput } from 'src/components/Atoms/Form/input'
import { SearchSubmit } from '@/components/Atoms/Form/submit'
import styled from 'styled-components'

import { useForm } from "react-hook-form";


const SFrom = styled.form`
    display:flex;
    position: relative;
    width: 100%;
    z-index: 2;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: ${props => props.theme.colors.black};
        top: 2px;
        left: 2px;
        z-index: -1;
    }
`

const SModal = styled.div`
    position: fixed;
    width: 90%;
    max-width: 640px;
    height: 95vh; 
    overflow-y: scroll;
    background: #000;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
`
interface SeachFormProps {
    defaultInput? : string,
    handleSearch?: (searchInput:string) => void
}

const SeachForm = ({
    defaultInput = '',
    handleSearch = () => {},
    ...props
}: SeachFormProps) => {

    const { register, handleSubmit } = useForm({
        defaultValues:{
            searchword : defaultInput
        }
    });


    const onSumit = (values) => {
        document.querySelector('meta[name="viewport"]').setAttribute('content', 'initial-scale=1');
        handleSearch(values.searchword)
    }

    return (
        <>
        <SFrom action="https://google.com/cse">
            <input type="hidden" name="cx" value="87e40503c50d548cf" />
            <input type="hidden" name="ie" value="UTF-8" />        
            <SearchInput register={register} registerName={'q'}></SearchInput>
            <SearchSubmit/>
        </SFrom>
     
        </>
    )
}

export default SeachForm