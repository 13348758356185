import { CmsListArticles } from "@/components/Molecules/Cms/List/articles";
import ArticleLoader from "@/components/Organisms/Article/articleLoader";
import { useCmsContext } from "@/context/CmsContext"
import { Suspense, useEffect, useState } from "react"
import { FetchArtcleIndexArg, fetchArticlesFillerCategory } from "src/data/fetch";
import { AritcleIndex, ArticleObj } from "src/types/article";
import { ICmsArticlesProps } from "../..";
import MoreLoad from "@/components/Molecules/MoreLoad";


export const CmsArticlesCategory = ({
    categoryId,
    handleView
}: ICmsArticlesProps) => {
    
    const CmsContext = useCmsContext()
    const loadLimit = 6;
    const [offset, setOffset] = useState<number>(0)
    const [articleIndexs, setArticleIndexs] = useState<AritcleIndex[]>([])
    const [ articleContens, setArticleContents ] = useState<ArticleObj[]>()
    const [ moreCount, setMoreCount ] = useState<number>(1)
    const [isMore, setIsMore ] = useState<boolean>(false)

    const articleLoadingCompleted = (contents:ArticleObj[]) => {
        setArticleContents(contents)
        setArticleIndexs([{
            contents : contents.slice(0, loadLimit),
            totalCount: contents.length,
            offset : 0,
            limit : 100
        }])
        const loadNum = (offset+1) * loadLimit
        setIsMore(loadNum < contents.length ) 
    }

    useEffect(() => {
        const args:FetchArtcleIndexArg  = {
            endpoint: CmsContext.pathAnalys.layer,
            lang: CmsContext.pathAnalys.lang,
            offset: offset,
            limit: 100,
            categroyId : categoryId
        }
        
        //endpoin全記事取得.
        const getArticlesFiller = async () => {
            const articles = await fetchArticlesFillerCategory(args)
            articleLoadingCompleted(articles.contents);
        };
        
        getArticlesFiller();
    },[])

    const handleMore = () => {
        const offet = moreCount * loadLimit
        setArticleIndexs([...articleIndexs,{
            contents : articleContens.slice(offet, offet+loadLimit),
            totalCount: articleContens.length,
            offset : 0,
            limit : 100
        }])
        setMoreCount(moreCount+1)
        setIsMore(articleContens.length > (offet + loadLimit))
    }

    
    return (
        <>
          { articleIndexs.length > 0 &&
                articleIndexs.map((articles,i) => (
                    <Suspense key={`aritcleList-${i}`}>
                        <div className="articles">
                            <CmsListArticles 
                            handleView={handleView}
                            articles={articles}></CmsListArticles>
                        </div>
                    </Suspense>
                ))
          }
           {
                articleIndexs.length === 0 &&
                <ArticleLoader/>
          }
            {
            isMore && 
            <MoreLoad onClick={handleMore}></MoreLoad>
          }
        </>
        
    )
}