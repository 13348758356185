
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorGood, NavigatorPickUp } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"

const LangEn = () => {

    return (<>
    <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="normal" dir="right" actionType="pickup" actionColor="blue"/>
        </div>
        <BlackNote>
        <p className="large">GDP of Fukuoka Prefecture</p>
        <p className="xl">
        178,027 million dollars
        </p>
        <p className="middle txt-yellow">
        Per capita prefectural income:<br/>
        $24,790.00
        </p>
        <p className="al-c">(as of April 2022)</p>
        <span className="txt-small">
        Source: Research and Statistics Division, Prefectural Government<br/>
        FY 2020 Report on the Prefectural and Municipal Economies<br/>
        Converted at 106.09 yen to the dollar (FY2020 average).<br/>
        Source: Cabinet Office, "Monthly Economic Report: Major Economic Indicators"
        </span>
        </BlackNote>
    </div>
    <br/>
    <div className="navpicup sp-only">
        <NavigatorPickUp dir="right"></NavigatorPickUp>
    </div>
    <InnerBox top={20}>
    <EmphasisNote>
    <h2>Agriculture</h2>
    <p>
    Fukuoka Prefecture's arable land area was 78,900 ha (in 2022), accounting for 16% of the prefecture's land area, of which paddy fields account for 81% (national average: 54%)
    <br/>
    </p>
    <h2>Forests and Forestry</h2>
    <p>
    The forest area of Fukuoka Prefecture is approximately 224,000 ha, which accounts for 45% of the prefecture's land area. Forests play an important role in the safety and security of the citizens of the prefecture by providing water source irrigation, preventing sediment runoff, and other public functions.
    <br/>
    </p>
    <h2>Fisheries Industry
    </h2>
    <p>
    In Fukuoka Prefecture, a variety of fisheries and aquaculture are conducted in the sea areas of the Chikuzen Sea, Ariake Sea, and Buzen Sea, which have different characteristics, as well as in inland waters such as rivers, lakes, and marshes.<br/>
    The prefecture boasts one of the nation's largest productions of red sea bream and nori (seaweed), as well as a variety of other marine products.
    <br/>
    </p>
    <h2>Commerce and Industry</h2>
    <p>
    Industry in Fukuoka Prefecture has developed mainly in basic materials industries such as steel, coal, and chemicals, and in lifestyle-related industries such as foodstuffs. In recent years, the proportion of manufacturing and assembly industries, such as automobile-related industries, has been increasing<br/>
    In addition, the food manufacturing industry has been concentrated due to the availability of abundant and high-quality agricultural, forestry, and fishery products, as well as its proximity to major consumption centers and favorable logistics environment
    </p>
    </EmphasisNote>
    </InnerBox>
    <InnerBox top={20}>
    <h1>
    Growth Industry<br/>
    Growth Industry that supports the future of FUKUOKA
    </h1>
    <p>
    Fukuoka Prefecture is home to many universities and other advanced research institutions as well as to manufacturing companies with excellent technologies.<br/>
    The prefectural government is focusing on encouraging companies
    to enter and concentrate in such growth industries, whose markets are expected to expand.
    </p>
    <EmphasisNote>
    <h2>1. Bioindustry</h2>
    <p className="txt-middle">
    Fukuoka Prefecture recognised as the first “Regional Bio Community”
    Accelerating efforts to make it an advanced biotechnology hub
    </p>
    <p>
    In collaboration with Kurume Municipal Government since 2001, Fukuoka
    Prefectural Government has been looking to achieve a concentration of
    biotechnology-related companies, based on the “Fukuoka Prefectural Bio
    Industry Centre Promotion Conference,” an industry-academia-government
    organisation.<br/>
    As a result of the Promotion Conference’s efforts, the number of biotechnology-related companies has increased from 32 at the beginning to more than 230 companies.
    In Fukuoka, bio ventures who are drawing attention inside and outside Japan are being established one after another̶such as Bonac Corporation, which is attracting attention
    due to its development of a remedy for COVID-19 based on the nucleic acid medicine as the next generation of medicine, and EditForce, Inc., which has an advantage in its all-Japanese genome editing technology.<br/>
    Such advantages of the prefecture have been highly thought of and Fukuoka was recognised as a “Regional Bio Community” (four such locations have been chosen nationwide) by the Cabinet Office in June 2021 as the only such prefecture in western Japan.
    </p>
    <h2>2. Space industry</h2>
    <p className="txt-middle">
    Fukuoka Prefectural Government chosen as the first Local
    Government Promoting Space Business Creation in Kyushu
    Taking up the space challenge with hardware and software
    technologies from Fukuoka
    </p>
    <p>
    In Fukuoka Prefecture, there is a concentration of manufacturing companies having
    excellent technologies, IT startups creating unique new services and many other
    potential companies in the space business.<br/>
    iQPS Inc. (Fukuoka City) succeeded in developing and launching a world-class small SAR satellite that can observe the surface of the Earth regardless of night and day or weatherThe company also successfully captured world-class high-resolution images in May 202iQPS aims to establish a system that enables it to observe the surface of the Earth in ten-minute intervals in or after 2025 with a 36 satellite-constellation.iQPS aims to establish a system that enables it to observe the surface of the Earth in ten-minute intervals in or after 2025 with a 36 satellite-constellation<br/>
    In September 2020, Japan’s Cabinet Office chose Fukuoka Prefectural Government as the first Local Government Promoting Space Business Creation in Kyushu for the advantages that only the prefecture has.
    </p>
    <h2>3. Blockchain</h2>
    <p className="txt-middle">
    Concentration of Japan’s leading-edge human resources and technologies
    Establishing one of the country’s main blockchain hubs
    </p>
    <p>
    Blockchain, which was developed as a technology to manage crypto-assets, is receiving attention for its traceability, ability to thwart data falsification and resistance to malfunction<br/>
    In addition to many blockchain startups and engineers with advanced skills, the School of Computer Science and Systems Engineering at Kyushu Institute of Technology as well as the Faculty of Humanity-Oriented Science and Engineering at Kindai University, which lead research in the blockchain field and produce excellent engineers, are located in Fukuoka Prefecture.<br/>
    To leverage this advantage for IT industry promotion, Fukuoka Prefectural Government started to actively support research and development into blockchain technology and relevant human resource development in earnest in 2020, and the prefecture is becoming a main blockchain hub in Japan.
    </p>
    <h2>4. Energy industry</h2>
    <p className="txt-middle">
    Working to develop the hydrogen energy industry and create a concentration of such companies and to introduce offshore wind power generation
    </p>
    <p>
    Japan is endeavouring to make renewable energy its main power source, with
    the aim of achieving carbon neutrality by 2050.Among other renewable energy sources, offshore wind power generation is expected to be an ideal choice for Japan, which is surrounded by the sea on all sides.<br/>
    Fukuoka Prefectural Government is also promoting the introduction of offshore wind power generation as well as working with industry, universities and government to create a concentration of wind power generation companies.The prefectural government has also established the Fukuoka Strategy Conference for Hydrogen Energy through collaboration among industry, universities and government to create an environmentally conscious hydrogen energy society.<br/>
    In addition to a wide range of research and development, product
    development and human resource development, from the manufacture, storage and
    transport of hydrogen to its use, Fukuoka Prefectural Government has also been
    working to develop and create a concentration of hydrogen energy companies.
    </p>
    <h2>5. Other industries</h2>
    <p className="txt-middle">
    Main growth industries in Fukuoka and Kyushu
    </p>
    <p>
    In Kyushu, the environment, agriculture, forestry and fisheries and automotive industries are successful, and are regarded as growth industries.<br/>
    ●Environmental industry<br/>
    In addition to recycling PET bottles and home appliances, the prefectural
    government is actively working on initiatives to avoid wasting limited resources by
    recycling rare metals used for lithium-ion batteries and the like.<br/>
    ●Agriculture, forestry and fisheries industry<br/>
    In Kyushu, the agriculture, forestry and fisheries industry is active, accounting for 20% of all agricultural and marine products produced in the country.
    In Fukuoka Prefecture, in particular, agricultural and marine products that receive high praise inside and outside Japan̶such as Amaou strawberry and Yame tea̶are produced.<br/>
    ●Automotive industry<br/>
    The major automotive manufacturers, Nissan, Toyota and Daihatsu, have production
    sites in northern Kyushu and their automotive production volume accounts for over
    15% in the domestic market.
    </p>
    </EmphasisNote>
    <br/>
    <div className="sp-only">
        <NavigatorGood dir="right"></NavigatorGood>
    </div>
    </InnerBox>
    </>)
}

export default LangEn