import { useLocation } from "react-router-dom";
import { endpoint } from "src/types/article";
import { langType } from "src/types/lang";
import { subLayerType } from "src/types/layer";

export interface PathAnalysisValue{
    layer : endpoint,
    category : string,
    id: string,
    lang : langType,
    keyword : string,
    tsutaeru?: string
}


export const pathAnalyzer = (location): PathAnalysisValue => {
    const queryParams = new URLSearchParams(location.search);
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const id = queryParams.get('id')
    const lang = queryParams.get('lang')
    const keyword = queryParams.get('keyword')
    const tsutaeru = queryParams.get('tsutaeru')

    return { 
        layer: getEndPoint( pathSegments[0] ?  pathSegments[0].toLowerCase() : undefined),
        category : pathSegments[1] ?  pathSegments[1].toLowerCase() : 'index',
        id :  id ? id.toLowerCase() : undefined,
        lang : getLang(lang ? lang.toLowerCase() : ''),
        keyword : keyword ? keyword : undefined,
        tsutaeru : tsutaeru ? tsutaeru : undefined
    }

}

const getEndPoint = (segment:string):endpoint => {

    const endpoint: endpoint[]  = [
        'information',
        'initiatives',
        'lifestyle',
        'basicinfo',
        'movie',
        'consultation',
        'support',
        'virtual',
        'emergency'
    ] 
 
    const check = endpoint.filter(element => element === segment)

    return  check.length > 0 ? check[0] : 'consultation'
    
}

const getLang = (segment:string):langType => {

    const langs: langType[]  = [
        'ja',
        'en',
        'ko',
        'zh_cn', //簡体字
        'zh_tw', //繁体字
        'th',
        'vi',
        'ne',
       //'ja&tsutaeru=on'
    ] 
 
    const check = langs.filter(element => element === segment)

    return  check.length > 0 ? check[0] : 'ja'
    
}

const getCategory =  (segment:string):subLayerType => {
    const subLayerTypes: subLayerType[]  = [
        'areas',
        'history',
        'population',
        'industries',
        'symbol',
        'foreign',
        'international',
        'consulate',
        'medical',
        'employment',
        'living',
        'education',
        'disasterinfo',
        'international',
        'immigrationbureau',
        'municipalitycounter',
        'sports',
        'industry',
        'sightseeing',
        'health',
        'environment',
        'fio',
        'sitepolicy',
        'privacy',
        'accessibility',
        'linkforsite',
        'knowledge',
        'faq',
        'links'
    ] 

    const check = subLayerTypes.filter(element => element === segment)

    return  check.length > 0 ? check[0] : 'index'
}