import React from "react";
import '@/App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import TopPage from "./components/Pages/topPage"
import BasicInfoPage from './components/Pages/BasicInfo'
import LifeStylePage from './components/Pages/Lifestyle'
import ConsultationPage from './components/Pages/Consultation'
import GwmPage from './components/Pages/Gwm'
import SupportPage from './components/Pages/Support'
import NewsPage from './components/Pages/News'
import MoviePage from './components/Pages/Moive'
import AboutPage from './components/Pages/Abouts'
import InitiativesPage from './components/Pages/Initiatives'
import VirtualPage from './components/Pages/Virtual'
import SpecialPage from './components/Pages/Special'
import PageLoder from './components/Organisms/PageLoader'
import { PageProvider } from './context/PageContext'
import  PageTransMotion from "src/motion/PageTransMotion";
import { AnimatePresence } from "framer-motion";
import EmergencyPage from './components/Pages/Emergency'
import NotFoundPage from './components/Pages/NotFound'

function App() {
  
  return (

   <BrowserRouter basename={process.env.REACT_APP_DIR}>  
      <PageProvider>
        <PageLoder/>
        <Routes>
            <Route path={`/`} element={
              <AnimatePresence>
                <PageTransMotion>
                <TopPage></TopPage>
                </PageTransMotion>
              </AnimatePresence>
            } />
             <Route path={`/gwm`} element={
              <AnimatePresence>
                <PageTransMotion>
                  <GwmPage></GwmPage>
                </PageTransMotion>
              </AnimatePresence>
                
            } />
            <Route path={`/basicinfo/`} element={
              <AnimatePresence>
                <PageTransMotion><BasicInfoPage/></PageTransMotion>
              </AnimatePresence>
            } />
            <Route path={`/basicinfo/:category`} element={
                <AnimatePresence>
                  <PageTransMotion><BasicInfoPage/></PageTransMotion>
                </AnimatePresence>
            } />
            < Route path={`/lifestyle`} element={
                <AnimatePresence>
                  <PageTransMotion><LifeStylePage/></PageTransMotion>
                </AnimatePresence>
            } />
            < Route path={`/lifestyle/:category`} element={
              <AnimatePresence>
                <PageTransMotion><LifeStylePage/></PageTransMotion>
              </AnimatePresence>
            } />
            <Route path={`/consultation`} element={
              <AnimatePresence>
                <PageTransMotion><ConsultationPage/></PageTransMotion>
              </AnimatePresence>
            } />
            <Route path={`/consultation/:category`} element={
               <AnimatePresence>
                 <PageTransMotion><ConsultationPage/></PageTransMotion>
                </AnimatePresence>
            } />
            <Route path={`/Initiatives/`} element={
                <AnimatePresence>
                  <PageTransMotion><InitiativesPage/></PageTransMotion>
                </AnimatePresence>
            } />
            <Route path={`/Initiatives/:category`} element={
              <AnimatePresence>
                <PageTransMotion><InitiativesPage/></PageTransMotion>
              </AnimatePresence>
            } />
           
            <Route path={`/information`} element={
              <AnimatePresence>
                <PageTransMotion><NewsPage/></PageTransMotion>
              </AnimatePresence>
            } />
            <Route path={`/information/:category`} element={
              <AnimatePresence>
                <PageTransMotion><NewsPage/></PageTransMotion>
              </AnimatePresence>
            } />
            <Route path={`/movie`} element={
              <AnimatePresence>
                <PageTransMotion><MoviePage/></PageTransMotion>
              </AnimatePresence>
            } />
            <Route path={`/movie/:category`} element={
              <AnimatePresence>
                <PageTransMotion><MoviePage/></PageTransMotion>
              </AnimatePresence>
            } />
            <Route path={`/virtual`} element={
              <AnimatePresence>
                <PageTransMotion><VirtualPage/></PageTransMotion>
              </AnimatePresence>
            } />
              <Route path={`/virtual/:category`} element={
              <AnimatePresence>
                <PageTransMotion><VirtualPage/></PageTransMotion>
              </AnimatePresence>
            } />
            <Route path={`/special`} element={
              <AnimatePresence>
                <PageTransMotion><SpecialPage/></PageTransMotion>
              </AnimatePresence>
            } />
            <Route path={`/about/`} element={
              <AnimatePresence>
                <PageTransMotion><AboutPage/></PageTransMotion>
              </AnimatePresence>
            } />
             <Route path={`/about/:category`} element={
              <AnimatePresence>
                <PageTransMotion><AboutPage/></PageTransMotion>
              </AnimatePresence>
            } />
             <Route path={`/support`} element={
               <AnimatePresence>
                  <PageTransMotion><SupportPage/></PageTransMotion>
                </AnimatePresence>
            } />
            <Route path={`/support/:category`} element={
              <AnimatePresence>
                <PageTransMotion><SupportPage/></PageTransMotion>
              </AnimatePresence>
            } />

            <Route path={`/emergency`} element={
              <AnimatePresence>
                <PageTransMotion><EmergencyPage/></PageTransMotion>
              </AnimatePresence>
            } />
              <Route path={`/emergency/:category`} element={
              <AnimatePresence>
                <PageTransMotion><EmergencyPage/></PageTransMotion>
              </AnimatePresence>
            } />


            <Route path="*" element={
                <NotFoundPage></NotFoundPage>
            } />
            
        </Routes>
      </PageProvider>
    </BrowserRouter>
  );
}

export default App;
