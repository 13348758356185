
const LangZhCn = () => {


    return (
        <>
            <h2>
                <img src="./images/virtual/fio_park.webp" alt="FUKUOKA IS OPEN PARK"/>
            </h2>
           
            <div className="virtual-inner">
                <p className="message al-l">
                “FUKUOKA IS OPEN PARK”是遵照福冈实际的空间创建的、与城市联动的元宇宙，在这里可以模拟体验福冈县的街景、美食、车辆等。                </p>
                <div className="fiopark">
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_01_title_en.webp" alt="FUKUOKA IS OPENパークの入口"/></dt>
                        <dd>
                        <p>天神中央公园郁郁葱葱，位于福冈县和九州中心的“天神地区”。可以在此与朋友们一起轻松聊天，或是欣赏视频。 从这里还可以去往世界各地！</p>
                            <figure>
                                <img src="./images/virtual/fio_park_01_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_02_title_en.webp" alt="FUKUOKA フルーツパーク"/></dt>
                        <dd>
                            <p>享受采摘福冈县的时令水果和蔬菜的乐趣！</p>
                            <figure>
                                <img src="./images/virtual/fio_park_02_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    
                </div>
                <div className="fiopark-howto">
                    <div className="fiopark-howto-title">
                    <h3><span>FUKUOKA IS OPEN PARK</span> 的使用方法</h3>
                    </div>
                    <div className="fiopark-howto-entrance">
                        <div><img src="./images/virtual/entrance_en.webp" alt="FUKUOKA IS OPEN 参加する"/></div>
                        <div><p>点击 “Join”即可加入。</p></div>
                    </div>
                    <div className="fiopark-howto-app">
                        <div>
                            <p>请事先将应用程序 “cluster ”下载到智能手机、平板电脑、个人电脑或专用VR设备上。</p>
                            
                            <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend pc" target="_blank">
                               <span> 点击此处查看推荐的系统要求</span>
                            </a>
                        </div>
                        <div>
                            <h4>已经安装了应用程序的用户，</h4>
                            <img src="./images/virtual/cluster01.webp" alt="clusterを起動" width={200}/>
                            <p>请点击[cluster起动]，</p>
                            <img src="./images/virtual/cluster02.webp" alt="遊びに行く"/>
                            <p>然后再点击[开始游戏]，进入体验室。</p> 
                        </div>
                        <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend sp" target="_blank">
                            <span> 点击此处查看推荐的系统要求</span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

}

export default LangZhCn