
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import Article from "@/components/Organisms/Article"
import { SDecTextUl } from "."

const LangNe = () => {

    return (<>
     <Article offset={0}>
    <InnerBox>
    <p>फुकुओका प्रान्तमा धेरै विदेशीहरू बस्छन्、विदेशी बासिन्दाहरूको संख्या जापानी शहरहरूमध्ये9नम्बर धेरै आदि、लोकप्रिय शहर हो। </p>
    <h1>विदेशीहरूको कुल संख्या</h1>
    </InnerBox>
    <BlackNote>
    <SDecTextUl className="pc-flex col-2">
    <li>
    <p className="large">
    प्रान्तमा बस्ने विदेशीहरूको कुल संख्या
    </p>
    <p className="large">
    <span className="xl">89,518</span>जना
    </p>
    <p className="middle"> (डिसेम्बर २०२२ को अनुसार)</p>
    </li>
    <li>
    <p className="large">
    फुकुओका क्षेत्रमा विदेशी बासिन्दाहरूको संख्या
    </p>
    <p className="large">
    <span className="xl">53,009</span>जना
    </p>
    <p className="middle"> (डिसेम्बर २०२२ को अनुसार)</p>
    </li>
    <li>
    <p className="large">
    किताक्युशु क्षेत्रमा विदेशी बासिन्दाहरूको संख्या
    </p>
    <p className="large">
    <span className="xl">18,870</span>जना
    </p>
    <p className="middle"> (डिसेम्बर २०२२ को अनुसार)</p>
    </li>
    <li>
    <p className="large">चिकुगो क्षेत्रमा विदेशी बासिन्दाहरूको संख्या</p>
    <p className="large">
    <span className="xl">12,686</span>जना
    </p>
    <p className="middle"> (डिसेम्बर २०२२ को अनुसार)</p>
    </li>
    <li>
    <p className="large">चिकुहो क्षेत्रमा विदेशी बासिन्दाहरूको संख्या</p>
    <p className="large">
    <span className="xl">4,953</span>जना
    </p>
    <p className="middle"> (डिसेम्बर २०२२ को अनुसार)</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    <InnerBox top={10} bottom={100}>
    <p className="text-small">स्रोत: गृह मन्त्रालय「विदेशी बासिन्दाहरूको तथ्याङ्क」</p>
    </InnerBox>
    </Article>
    <div className="navposition01">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    <Article offset={0}>
    <InnerBox >
    <h1>
    अन्तर्राष्ट्रिय विद्यार्थीहरूको संख्या
    </h1>
    </InnerBox>

    <BlackNote>
    <SDecTextUl className="pc-flex col-2">
    <li>
    <p className="large">
    प्रान्तमा अन्तर्राष्ट्रिय विद्यार्थीहरूको कुल संख्या
    </p>
    <p className="large">
    <span className="xl">19,188</span>जना
    </p>
    <p className="middle"> (डिसेम्बर २०२२ को अनुसार)</p>
    </li>
    <li>
    <p className="large">
    फुकुओका क्षेत्रमा अन्तर्राष्ट्रिय विद्यार्थीहरूको संख्या
    </p>
    <p className="large">
    <span className="xl">14,800</span>जना
    </p>
    <p className="middle"> (डिसेम्बर २०२२ को अनुसार)</p>
    </li>
    <li>
    <p className="large">
    किताक्युशु क्षेत्रमा अन्तर्राष्ट्रिय विद्यार्थीहरूको संख्या
    </p>
    <p className="large">
    <span className="xl">2,631</span>जना
    </p>
    <p className="middle"> (डिसेम्बर २०२२ को अनुसार)</p>
    </li>
    <li>
    <p className="large">
    चिकुगो क्षेत्रमा अन्तर्राष्ट्रिय विद्यार्थीहरूको संख्या
    </p>
    <p className="large">
    <span className="xl">1,650</span>जना
    </p>
    <p className="middle"> (डिसेम्बर २०२२ को अनुसार)</p>
    </li>
    <li>
    <p className="large">
    चिकुहो क्षेत्रमा अन्तर्राष्ट्रिय विद्यार्थीहरूको संख्या
    </p>
    <p className="large">
    <span className="xl">107</span>जना
    </p>
    <p className="middle"> (डिसेम्बर २०२२ को अनुसार)</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    <InnerBox top={10} bottom={100}>
    <p className="text-small">स्रोत: गृह मन्त्रालय「विदेशी बासिन्दाहरूको तथ्याङ्क」</p>
    </InnerBox>
    </Article>
    <div className="navposition01">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    <Article offset={0}>
    <InnerBox>
    <h1>
    कार्यरत अन्तर्राष्ट्रिय विद्यार्थीहरूको सङ्ख्या
    </h1>
    </InnerBox>
    <BlackNote>
    <SDecTextUl>
    <li>
    <p className="large">
    प्रान्त भित्र अवस्थित कम्पनीहरूमा कार्यरत अन्तर्राष्ट्रिय विद्यार्थीहरूको संख्या
    </p>
    <p className="large">
    <span className="xl">1,118</span>जना
    </p>
    <p className="middle">(२०२१साल)</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    </Article>
    </>)
}

export default LangNe