
const LangZhCn = () => {

    return (<>
    <h1>关于FUKUOKA IS OPEN</h1>
    <p>
    福冈县在友好交流地区数量、留学生人数等方面位居全国前列，
    与此同时，本县居住的外国人数量也达到89,518人，创下历史最高纪录。
    </p>
    <p>
    在未来，为了在人口减少的社会当中保持地区的活力，将外国人才作为本县企业的劳动力和地方社会的新支持者是至关重要的。
    </p>
    <p>
    在这种情况下，为了进一步吸引海外人才，在"FUKUOKA IS OPEN"这一口号下，向全世界统合传达本县的政策:希望外界了解到福冈县是一个对外国人来说宜居且可以充分发挥其才能的地方。
    </p>
    <p>
    为此，我们在多语言门户网站“FUKUOKA IS OPEN”
    （<a href="https://www.fisop.net">https://www.fisop.net</a>）上首先发布了与外国人生活相关的本县教育、医疗等相关信息；之后还涵盖有关留学生、入境游客、国际金融引进、体育、环境、"One health"等的最新政策信息。同时，外国居民活跃情况等的最新资讯也会发布。
    </p>
    <br/>
    <h5 className="title-large">关于 FUKUOKA IS OPEN 原创徽标的使用</h5>
    <p>未经 FUKUOKA IS OPEN 推进事业实行委员会的同意，不得使用本徽标。<br/>
    申请使用时，必须遵守使用要领。<br/>
    请确认好使用要领和使用指南，
    并填写好“（第1号格式 ）FUKUOKA IS OPEN 推进事业徽标使用申请书”， 通过传真提交。</p>
    <br/>
    <p>申请内容得到确认后，会获得承诺书和徽标数据。</p>
    <br/>
    <p>
    【申请文件等】
    </p>
    <ul className="dotto">
    <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOPromotionProjectLogoUsageInstructions.pdf" target="_blank">FUKUOKA IS OPEN 推进事业徽标使用要领</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form1_FIOPromotionProjectLogoApplicationForm.docx">(第1号格式) FUKUOKA IS OPEN 推进事业徽标使用申请书</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form4_FIOPromotionProjectLogoApplicationForm.docx">(第4号格式) FUKUOKA IS OPEN 推进事业徽标使用承诺变更申请书</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form5_FIOPromotionProjectLogoUsageStatusReport.docx">(第5号格式) FUKUOKA IS OPEN 推进事业徽标使用状况报告书</a></span></li>
    <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOLOGOUSERGUIDELINE.pdf" target="_blank">[FUKUOKA IS OPEN] LOGO USER GUIDELINE</a></span></li>
    </ul>
    <br/>
    <p>
    【咨询处】<br/>
    FUKUOKA IS OPEN推进事业实行委员会事务局（福冈县国际局国际政策课外国人才支援系）
    </p>
    <br/>
    <p>
    TEL：<a href="tel:+81-92-643-3201">+81-92-643-3201</a><br/>
        FAX：<a href="tel:+81-92-643-3224">+81-92-643-3224</a><br/>
    8:30至17:15（9:00至17:45）<br/>
    但是周六、周日、节假日以及12月29日至1月3日除外
    </p>
    </>)
}

export default LangZhCn