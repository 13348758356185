import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import Article from "@/components/Organisms/Article"
import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"


const SConsulate = styled.div`
    .checkpostion01{
        position: relative;
        width: 100%;
       aspect-ratio: 10 / 4.5;
        figure{
            position: absolute;
            width: 80%;
            right: 20px;
        }
    }
    ul.deco{
        li{
            padding-bottom: 10px;
            border-bottom: 1px solid ${props => props.theme.colors.yellow};
            margin-bottom:20px;
            &:last-child{
                border-bottom: none;
                margin-bottom: 0;
            }
            p.name{
                    color : ${props => props.theme.colors.yellow};
                    font-size: 2.4rem;
            }
            p.address{
                    margin-bottom: 0;
                    font-size: 1.6rem;
                    line-height: 2.0rem;
            }
        }   
    }

    h2{
        span{
            display: inline-block;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .pc-wapper{
            max-width: 700px;
            margin-bottom: 60px;
            & > div:first-child{
                padding: 0;
            }
        }
        .pc-list-wrap{
            & > div{
                padding-bottom: 0;
            }
            div{
                margin: 0;
                margin-bottom: 0;
            }
        }
        .pc-navigator{
            margin-top: 40px;
            width: 400px;
        }
    }
`


const BasciInfoConsulate= ({lang}:{lang:langType}) => {

    setTitleDiscrption({
        layer:'basicinfo',
        lang:lang,
        subLayer:"consulate"
    })

    return(
        <SConsulate>
            <Article offset={0} className="pc-noTopMargin">
            {lang === 'ja' &&
                <LangJa></LangJa>
            }
            {lang === 'easy_ja' &&
                <LangJa></LangJa>
            }
            {lang === 'en' &&
                <LangEn></LangEn>
            }
            {lang === 'ko' &&
                <LangKo></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh></LangTh>
            }
            {lang === 'vi' &&
                <LangVi></LangVi>
            }
            {lang === 'ne' &&
                <LangNe></LangNe>
            }
            </Article>
        </SConsulate>
        
    )
}

export default BasciInfoConsulate