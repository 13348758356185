

const LangEn = () => {

    return (<>
    <h1>Accessibilty</h1>
    <dl>
    <dt>
    Initiatives
    </dt>
    <dd>
    <p>Fukuoka Prefecture will strive to maintain and improve the accessibility of its website based on the Japanese Industrial Standard "JIS X 8341-3:2016" so that it can be used by everyone.</p>
    </dd>
    <dt>
    1. Scope of coverage
    </dt>
    <dd>
    <p>
    Official website under the https://fisop.net domain
    </p>
    </dd>
    <dt>
    2. Target conformance level
    </dt>
    <dd>
    <p>
    Conformance level: Conforms to JIS X 8341-3:2016 web accessibility conformance level AA<br/>
    (Ongoing modifications will be made with the goal of complying with Web Accessibility Conformance Level AA up to Tier 2)<br/>
    The notation "conforms to conformance level AA" means that the content conforms to conformance level A, in addition to meeting the achievement criteria for conformance level AA, according to the notation defined in the "JIS X 8341-3:2016 Compliance Level Notation Guidelines for Web Content, March 2016 Edition" by the Web Accessibility Infrastructure Committee of the Info-communication Access Council. This means to meet the achievement criteria of conformance level AA in addition to conformance level A.<br/>
    <br/>
    <a href="https://waic.jp/docs/jis2016/compliance-guidelines/202104/" target="_blank">
    JIS X 8341-3:2016 Compliance Level Notation Guidelines for Web Content Page will open in a new window.
    </a>
    </p>
    </dd>
    </dl> 
    </>)
}

export default LangEn