
import publicPath from "src/utilty/publicPath"

const LangJa = () => {

    return (
        <>
        <h1>
            福岡県知事からのメッセージ
        </h1>
        <figure className="governor">
            <img src={publicPath('images/fukuoka_governor.jpg')} className="img-fit"/>
        </figure>
        <p>
        　皆さん、こんにちは。「FUKUOKA IS OPEN」のポータルサイトへ、ようこそお越しくださいました。
        </p>
        <p>
        　福岡県は、古くからアジア、そして世界との窓口として開かれ、豊かな自然と歴史の中でさまざまな文化や産業が発展し、多様な人材が活躍しています。
        </p>
        <p>
        　「FUKUOKA IS OPEN」ポータルサイトでは、福岡県で生活するための相談窓口、医療、教育、就労などの情報をはじめ、県内で活躍する海外から来られた皆さまの姿を紹介するとともに、スポーツ、環境、ワンヘルスなど本県の先進的な取り組みや、食・観光などの魅力を世界に向けて発信しています。
        </p>
        <p>
        　海外から福岡県へ、多くの皆さんに来ていただき、誰もが安心して、たくさんの笑顔で暮らせるよう、しっかりサポートしてまいります。
        </p>
        <p>
            「FUKUOKA IS OPEN」を合言葉に、この福岡で、一緒に未来をつくっていきましょう。
        </p>
        <br/>
        <br/>
        <p className="al-r">
            <strong> 
            福岡県知事 服部 誠太郎</strong>
        </p>
        </>
    )
}

export default LangJa