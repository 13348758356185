import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { TrapezoidExcl } from "@/components/Molecules/CharactorHeadline/challenger"
import Article from "@/components/Organisms/Article"


const LangZhTw = () => {

    return (<>
        <Article offset={0} className="pc-noTopMargin">
        <div className="pc-flex col-2">
            <div className="pc-only">
                <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
            </div>
            <BlackNote>
            <ul className="deco">
            <li>
            <p className="large">
            ●福岡縣的人口<br/>
            514 萬人
            </p>
            <p className="al-c">（2020年・全國第9位）</p>
            <span className="txt-small">
            資料來源：縣調查統計課 <br/>《令和４年福岡縣的人口與家庭年報》
            </span>
            </li>
            </ul>
            </BlackNote>
        </div>
        </Article>
        <TrapezoidExcl dir="left">
        ●福岡縣的面積<br/>
        </TrapezoidExcl>
        <Article offset={0} className="pc-noTopMargin">
        <div className="pc-flex col-2">
            <div className="pc-only">
                <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
            </div>
            <BlackNote>
            <ul className="deco">
            <li>
            <p className="large">
            ●福岡縣的面積 <br/>
            4,987㎢
            </p>
            <p className="al-c">（2020年 全國第29位）</p>
            <span className="txt-small">
            資料  國土交通省國土地理院
            <br/>
            《令和4年全國都道府縣市區町村別麵積調》
            </span>
            </li>
            </ul>
            </BlackNote>
        </div>

        <div className="navi-position sp-only">
            <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
        </Article>

        <TrapezoidExcl dir="left">
        ●福岡縣的氣候<br/>
        </TrapezoidExcl>
        <Article offset={0} className="pc-noTopMargin">
        <BlackNote>
        <ul className="deco pc-flex col-2">
        <li>
        <p className="large">
        年平均氣温 <br/>
        17.3℃
        </p>
        </li>
        <li>
        <p className="large">
        年平均濕度<br/>
        68％
        </p>
        </li>
        <li>
        <p className="large">
        年日照時數<br/>
        1,889.4小時
        </p>
        </li>
        <li>
        <p className="large">
        年間降水量<br/>
        1,686.9mm
        </p>
        <p>1991～2020年30年間之平均値</p>
        <p className="txt-small">
        資料 福岡管區気象台
        </p>
        </li>
        </ul>
       
        </BlackNote>
        <div className="sp-only">
                <NavigatorCheck dir="right"></NavigatorCheck>
            </div>
        </Article>
    </>)
}

export default LangZhTw