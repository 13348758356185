import { setTitleDiscrption } from "src/utilty/setTitleDiscription";
import { useLocation } from "react-router-dom";
import { pathAnalyzer } from "src/utilty/pathAnalyzer";
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio";
import { CmsIndex } from "@/components/Organisms/Cms";
import SubLayerTemplate from "@/components/Templates/subLayer";
import styled from "styled-components";


const EmergencyPage = () => {

    const location = useLocation()
    const lang = pathAnalyzer(location).lang

    setTitleDiscrption({
        layer : 'emergency',
        lang : lang
    })


    return (
        <ResponsiveFioTemplate emergency={true}>
            <SEmergency>
                <CmsIndex pageback={false}></CmsIndex>
            </SEmergency>
        </ResponsiveFioTemplate>
    )
}

const SEmergency = styled.div`
    padding: 120px 0;
    position: relative;
    &::before{
        content: '';
        width: 100%;
        min-width: 1200px;
        height: 30px;
        position: absolute;
        top: 70px;
        left: 0;
        background: url('./images/emergency/border.webp');
        background-size: 100% auto;
        background-position: center;
    }
    &::after{
        content: '';
        width: 100%;
        min-width: 1200px;
        margin-right: 1200px;
        height: 30px;
        position: absolute;
        bottom: 100px;
        left: 0;
        background: url('./images/emergency/border.webp');
        background-size: 100% auto;
        background-position: center bottom;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        padding: 100px 0 200px;
        &::before{
            top: 0;
        }
    }
`


export default EmergencyPage