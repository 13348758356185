import HeadLine from "@/components/Atoms/HeadLine"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { langType } from "src/types/lang"
import getLayer from "src/utilty/getLayer"
import { PathAnalysisValue } from "src/utilty/pathAnalyzer"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"


const SSection = styled.section`
    position: relative;
    background:url(${publicPath('images/top/gwmBk.png')}) no-repeat;
    background-size:100% auto;
    background-position: center;
    width: 100%;
    height: 100%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        background: url('images/pc/bk_gwm.webp') no-repeat;
        background-size: auto 100%;
    }
`

const SHeadline = styled.h2`
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    top:0;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 40%;
        top:-14%;
        left: 54%;
        transform: translateX(0);
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 90%;
        top: -10%;
    }
`

const SMessagePosion = styled.div`
    font-size: ${props => props.theme.fontSize.top_mes_sp};
    color :#fff;
    font-weight: bold;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 32%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        font-size: ${props => props.theme.fontSize.topSection_pc};
        width: fit-content;
        left: 56%;
    }
`

const SLinkPositon = styled.div`
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translateX(-50%);
    width: 46%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 230px;
        left: 60%;
        transform: translateX(0);
        bottom: 0%;
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 46%;
        left: 54%;
    }
`



const GwmSection = ({pathValue}:{pathValue: PathAnalysisValue}) => {
    const layer = getLayer('gwm')

    return (
        <SSection>
            <SHeadline>
                <HeadLine nameKey="gwm" lang={pathValue.lang}/>
            </SHeadline>
            <SMessagePosion>{layer[pathValue.lang].title}</SMessagePosion>
            <SLinkPositon>
                <YellowArrowLink linkKey="check" lang="ja" href={`/gwm?lang=${pathValue.lang}`}/>
            </SLinkPositon>
        </SSection>    
    )
}

export default GwmSection