import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AritcleIndex, article } from "src/types/article";
import { SlideContent } from "@/components/Atoms/Slider/slideContent";
import { NextBtn, PrevBtn } from "@/components/Atoms/Button/sliderArrowButton";
import styled from "styled-components";
import { langType } from "src/types/lang";
import { articleTitleSelect } from "src/utilty/articleTitleSelect";


interface ContentsSliderProps{
    articles : AritcleIndex,
    lang: langType,
    layer: string,
    className?:string
    prevArrow?: string
    nextArrow?: string
}


const AriclesSlider = ({
    articles,
    layer,
    lang,
    className,
    ...props
}: ContentsSliderProps) => {


    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextBtn arrow={props.nextArrow}></NextBtn>,
        prevArrow: <PrevBtn arrow={props.prevArrow}></PrevBtn>
    };

    const SSlideWrap  = styled.div`
        padding: 20px;
    `

    const getArticleLang = (article) => {
        let articleLang
        if(article[lang]){
            articleLang = lang
        }else{
            article['en'] ? articleLang = 'en':  article['ja'] ? articleLang = 'ja' : articleLang = null
        }

        return articleLang
    }

    return (
        <div>
        { articles.contents.length > 0 ? 
        <SSlide className={className}>
        <Slider {...settings}>
            {articles.contents.map((article, i) => {
                const articleLang = getArticleLang(article)
                if(articleLang){
                    return (
                        <SSlideWrap key={`slider-${i}`}>
                            <SlideContent 
                                article ={article}
                                lang={articleLang}
                                layer={article.endpoint ? article.endpoint : layer} 
                                className={className}
                                ></SlideContent>
                        </SSlideWrap>
                    )
                }
            })}
        </Slider> 
        </SSlide>: 
        <SInfo>
            {lang === 'ja' && 
                <p>現在、新着情報はありません。</p>
            }
            {lang === 'en' &&
                <p>There is currently no new information</p>
            }
            {lang === 'ko' &&
                <p>현재 새로운 정보는 없습니다</p>
            }
            {lang === 'zh_tw' &&
                <p>目前沒有新信息</p>
            }
             {lang === 'zh_cn' &&
                <p>目前没有新信息</p>
            }
            {lang === 'ne' &&
                <p>हाल, नवीनतम जानकारी छैन</p>
            }
            {lang === 'th' &&
                <p>ปัจจุบันยังไม่มีข้อมูลใหม่</p>
            }
            {lang === 'vi' &&
                <p>Hiện tại, không có thông tin mới.</p>
            }
        </SInfo>
        }
       </div>
    )

}

const SSlide = styled.div`
    .slider-contents{
        figure{
        margin: 0;
        padding: 0;
        width: 100%;
        aspect-ratio: 10 / 5.5;
        overflow-y: hidden;
        border: solid 3px ${props => props.theme.colors.red};
        position: relative;
            img{
                width: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .date-title{    
            font-size: 1.6rem;
            color:#fff;
            span.date{
                display: block;
                font-size: 1.3rem;
                color:#FFE200;
                padding: 5px 0;
            }
        }
    }

    &.mini{
        .slider-contents{
            .date-title{    
                font-size: 1.2rem;
                @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
                    font-size: 1.6rem;
                }
            }
        }
    }

    &.disaster{
        .slider-contents{
            figure{ border: none; }
            .date-title{    
                font-weight: bold;
                color:#231815;
                line-height: 1.8rem;
                span.date{    color:#231815; padding: 5px 0 0;}
            }
        }
    }
`

const SInfo = styled.div`
    width: 90%;
    aspect-ratio: 10/6;
    border: solid 2px #fff;
    display: grid;
    place-items: center;
    background: rgba(255, 255, 255, 0.8);
   p{  color: ${props => props.theme.colors.black};  font-size:1.0rem; padding:5px}
   margin-bottom: 30px;
   
`

export default AriclesSlider