import InnerBox from "@/components/Atoms/Box/innerBox";
import Article from "@/components/Organisms/Article";
import styled from "styled-components";
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang";
import { setTitleDiscrption } from "src/utilty/setTitleDiscription";




const SAccessibility = styled.div`
    p{
        margin-bottom: 30px;
    }
    dl{
        dt{
            font-weight: bold;
            margin-bottom: 3px;
        }
    }
    a{
        text-decoration: underline;
    }
`

const AboutAccessibilityPage = ({lang}:{lang:langType}) => {
    
    setTitleDiscrption({
        layer:'about',
        lang:lang,
        subLayer:"accessibility"
    })

    return (
        <SAccessibility>
            <Article offset={0} className="pc-noTopMargin">
                <InnerBox bottom={100}> 
                {lang === 'ja' &&
                <LangJa></LangJa>
                }
                {lang === 'easy_ja' &&
                    <LangJa></LangJa>
                }
                {lang === 'en' &&
                    <LangEn></LangEn>
                }
                {lang === 'ko' &&
                    <LangKo></LangKo>
                }
                {lang === 'zh_cn' &&
                    <LangZhCn></LangZhCn>
                }
                {lang === 'zh_tw' &&
                    <LangZhTw></LangZhTw>
                }
                {lang === 'th' &&
                    <LangTh></LangTh>
                }
                {lang === 'vi' &&
                    <LangVi></LangVi>
                }
                {lang === 'ne' &&
                    <LangNe></LangNe>
                }
                </InnerBox>
            </Article>
        </SAccessibility>
    )
}

export default AboutAccessibilityPage