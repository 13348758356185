import styled from "styled-components";
import publicPath from "src/utilty/publicPath";

const Button = styled.button`
    display: inline-block;
    padding: 8px 14px;
    width: 100%;
    background-color: ${props => props.theme.colors.black}; /* ボタンの背景色 */
    border: none; /* ボーダーはなし */
    border-radius: 20px; /* 角の丸み */
    position: relative; /* 矢印アイコンの位置の基準点 */
    cursor: pointer;
    border: solid 1px #fff;
    &:hover{
        opacity: 0.8;
    }
    img{
        max-width: 100px;
    }
`

export type LangBtnActiveType = 0 | 1

interface LanguageBtnBtnProps {
    //isActive: LangBtnActiveType,
    onClick: () => void
}

export const LangButton = ({
    onClick,...props}:LanguageBtnBtnProps) => {

    return (
        <Button
            onClick={onClick}
        >
           <img
              src={publicPath("images/lang.png" )}
              alt="language"
              className="img-fit"
            />
        </Button>
    )
}