
export interface DateCms {
        Y : number,
        m : string,
        d : string
}

export const DateCmsFormat = (dateString:string): DateCms => {

    const date = new Date(dateString);

    // 年、月、日を抽出
    const year = date.getFullYear(); // 年
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月（getMonth() は 0-11 を返すので、1を加える）
    const day = ('0' + date.getDate()).slice(-2); // 日

    return {
        Y : year,
        m : month,
        d : day
    }
}