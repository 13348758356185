import { langType } from "src/types/lang"
import Article from "@/components/Organisms/Article"
import { ChallengerrFullLength } from "@/components/Atoms/Character/challenger"
import { NavigatorFullLength } from "@/components/Atoms/Character/navigator"
import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"


const SLinks = styled.div`
    .linkslist{
        figure{
            padding-right: 20px;
            figcaption{
                padding-bottom: 10px;
            }
            img{max-width: 300px}
        }
        h4{
            padding-bottom: 10px;
            border-bottom: 1px solid #2CA6E0;
            margin-bottom: 10px;
        }
        li{ margin-bottom: 40px;}
       
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .linkslist{
            li{
                margin-bottom: 80px;
            }
        }
    }
`
const SCharactor = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 10 / 3.5;

    .challenger{
        position: absolute;
        width: 65%;
        top: 0;
        transform: translateY(-4%);
    }
    .navigator{
        position: absolute;
        width: 30%;
        z-index: 11;
        top:0;
        transform: translateY(-10%);
        right: 20px;
    }
`

const SupportLinksPage = ({lang}:{lang:langType}) => {

    setTitleDiscrption({
        layer:'support',
        lang:lang,
        subLayer: 'links'
    })
    return (

        <SLinks>
            <div className="sp-only">
            <SCharactor>
                <div className="challenger">
                    <ChallengerrFullLength></ChallengerrFullLength>
                </div>
                <div className="navigator">
                    <NavigatorFullLength></NavigatorFullLength>
                </div>
                
            </SCharactor>
            </div>
            <Article offset={0} className="pc-noTopMargin">
            {lang === 'ja' &&
                <LangJa></LangJa>
            }
            {lang === 'easy_ja' &&
                <LangJa></LangJa>
            }
            {lang === 'en' &&
                <LangEn></LangEn>
            }
            {lang === 'ko' &&
                <LangKo></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh></LangTh>
            }
            {lang === 'vi' &&
                <LangVi></LangVi>
            }
            {lang === 'ne' &&
                <LangNe></LangNe>
            }
            </Article>
        </SLinks>
    )
}

export default SupportLinksPage