const LangZhTw = () => {

    return (<>
    <h1>關於網絡無障礙性</h1>
    <dl>
    <dt>
    舉措
    </dt>
    <dd>
    <p>福岡縣將根據日本工業標準JIS X 8341-3:2016，努力維護和提高網絡無障礙性，使人人都能使用網站。</p>
    </dd>
    <dt>
    1.對象範圍
    </dt>
    <dd>
    <p>
    https://fisop.net 域名下的官方網站
    </p>
    </dd>
    <dt>
    2.符合目標的級別
    </dt>
    <dd>
    <p>
    符合性級別：以JIS X 8341-3:2016網絡無障礙符合性級別 AA為基準<br/>
    (將不斷進行修改，以達到網絡無障礙符合性 AA 級別，包括第 2 層）。<br/>
    根據信息通信訪問協議會網絡無障礙性基盤委員會《網絡內容的JIS X 8341-3:2016對應度表記指南 2016年3月版》所規定的表記，"符合無障礙級別AA "表示在符合無障礙級別A基礎上，網站還滿足無障礙級別AA的達成標準。<br/>
    <br/>
    <a href="https://waic.jp/docs/jis2016/compliance-guidelines/202104/" target="_blank">
    網頁內容JIS X 8341-3:2016對應度表記指南 在新窗口中打開（外部鏈接）
    </a>
    </p>
    </dd>
    </dl>
    </>)
}

export default LangZhTw