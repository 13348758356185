import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import styled from "styled-components"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useCmsContext } from "@/context/CmsContext"
import SeachForm from "../../SearchForm"
import { useForm } from "react-hook-form"
import { SearchInput } from "@/components/Atoms/Form/input"
import { SearchSubmit } from "@/components/Atoms/Form/submit"


const SArticleSearch = styled.div`
    position: relative;
    z-index: 10;
    p{font-weight:bold; margin:3px}
    .keywords{
        display: flex;
        flex-wrap: wrap;

        & > * {
            margin-bottom: 1%;
            margin-right: 1%;
            
        }
    }
    .morebutton{
        width: 55%;
        margin-bottom: 10px;
        margin-right: 0;
    }
`   

const SFrom = styled.form`
    display:flex;
    position: relative;
    width: 100%;
    z-index: 2;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: ${props => props.theme.colors.black};
        top: 2px;
        left: 2px;
        z-index: -1;
    }
`

const SModal = styled.div`
    position: fixed;
    width: 90%;
    max-width: 640px;
    height: 95vh; 
    overflow-y: scroll;
    background: #000;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
`

type ArticleSearchProps = {
    defaultInput: string,
    handleAricleSearch: (input:string) => void
    keywords: string[];
};


export const CmsSearchForm = ({
    defaultInput,
    handleAricleSearch,
    keywords,
    ...props }: ArticleSearchProps) => {

        const navigate = useNavigate()
    const CmsContext = useCmsContext()

    // 表示するキーワードの数を管理する状態
    const [visibleCount, setVisibleCount] = useState(6);

    const showMoreKeywords = () => {
        setVisibleCount(prevCount => prevCount + 3);
    };
    
    const handleSearch = (currentInput) => {
        const searchWord:string = currentInput.searchword.replace(" ", ",")
        handleAricleSearch(searchWord)
    }

    const searchTitle =  CmsContext.pathAnalys.lang === 'ja' ? '記事を検索' : 'Search Form'
    const freewordTitle =  CmsContext.pathAnalys.lang === 'ja' ? 'フリーワードで検索' : 'Free Word'

    const { register, handleSubmit } = useForm({
        defaultValues:{
            searchword : defaultInput
        }
    });

    return (
        <SArticleSearch>
            <EmphasisNote>
                <h2>{searchTitle}</h2>
                <p>{freewordTitle}</p>
                <SFrom onSubmit={handleSubmit(handleSearch)}>
            
                    <SearchInput register={register} registerName={'searchword'}></SearchInput>
                    <SearchSubmit/>
                </SFrom>
            </EmphasisNote>
        </SArticleSearch>
    )
}