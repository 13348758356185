
import { NavigatorNice } from "@/components/Atoms/Character/navigator"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorFukidashiPicup } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"

const LangNe = () => {

    return (<>
    <h1>विपद्को अवस्थामा विदेशीहरूको लागि सहयोग </h1>
    <p>
    विदेशबाट आउने मानिसहरू सबैले、विपत्ति आइपर्दा उचित कदम चलाएर、यस प्रान्तमा शान्त・सुरक्षित रूपमा बस्नको लागि दैनिक आधारमा तयार हुन धेरै महत्त्वपूर्ण छ।<br/>
    प्रान्तले विभिन्न तरिकामा जानकारी प्रदान गर्दछ। प्रकोप संग सम्बन्धित कुरा बारे सिकेर、आफ्नो जीवन रक्षा गरी 、आफ्नो परिवार तथा नजिकैका साथीहरू आदिको、 आफ्ना प्रियजनहरूको जीवनको रक्षा गर्न सक्षम हुनुहोस ।
    </p>
    <NavigatorFukidashiPicup dir="right">
    विपत्ति आइपर्दा、विपद् जानकारी तथा निकासी(आश्रय)जानकारी विश्वसनीय रूपमा प्राप्त गर्न महत्त्वपूर्ण छ। निम्न साइटहरू तथा एपहरू प्रयोग गरेर、सजिलैसँग जानकारी सङ्कलन गर्न सक्नुहुन्छ।
    </NavigatorFukidashiPicup>
    <br/>
    <EmphasisNote>

    <h2>प्रकोप रोकथाम एप「फुकुओका प्रकोप रोकथाम नेभिगेसन・मामोरु कुन」को डाउनलोड गर्नुहोस्</h2>
    <p>
    प्रकोप रोकथाम एप「 फुकुओका प्रकोप रोकथाम नेभिगेसन・मामोरु कुन」 प्रयोग गरेर विपद् जानकारी आदि वितरण गर्छौं।यो प्रयोग गर्न・दर्ता गर्न नि: शुल्क र、<br/>
    सजिलैसँग प्रकोप रोकथाम जानकारी पाउन सक्नुहुन्छ、त्यसैले कृपया दर्ता गर्नुहोस्।
    </p>
    <p>
    ●हालको स्थानको प्रकोप रोकथाम जानकारी लाइ पुश भन्ने सूचना द्वारा सूचित गर्नेछौं।<br/>
    ●आश्रय स्थान को ठाउँलाई नक्सा बाट स्पष्ट रूपमा देखाइएको छ।<br/>
    ●विपद्को अवस्थामा के कदम चाल्ने दृष्टान्तका साथ चित्रण गरिएको छ।<br/>
    ●​विपद्को समयमा परिवारको हालको स्थान जानकारी जाँच गर्न सक्नुहुन्छ।<br/>
    ●अन्य भाषाहरू जापानी, अंग्रेजी, चिनियाँ (सरलीकृत र परम्परागत), कोरियाली र भियतनामी भाषा उपलब्ध छ।
    </p>
    <figure>
    <img src={publicPath('images/articles/mamorukun01.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/mamorukun02.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
    </figure>

    <div className="app_qr">
    <a href="https://apps.apple.com/jp/app/%E3%81%B5%E3%81%8F%E3%81%8A%E3%81%8B%E9%98%B2%E7%81%BD%E3%83%8A%E3%83%93-%E3%81%BE%E3%82%82%E3%82%8B%E3%81%8F%E3%82%93/id1659756418" target="blank">
    <figure>
    <img src={publicPath('images/articles/mamorukun_app01.png')} alt="App Store" className="fit-img"/>
    </figure>
    </a>
    <hr/>
    <a href="https://play.google.com/store/apps/details?id=jp.lg.fukuoka.pref.fukuoka_bosai&hl=ja&gl=US" target="blank">
    <figure>
    <img src={publicPath('images/articles/mamorukun_app02.png')} alt="Google Play" className="fit-img"/>
    </figure>
    </a>
    </div>
    <h2>फुकुओका प्रान्त प्रकोप रोकथाम होमपेज </h2>
    <p>
    फुकुओका प्रान्तले「फुकुओका प्रान्त प्रकोप रोकथाम होमपेज 」स्थापना गरेर、विपद् पूर्व तयारीबाट、प्रकोप जानकारी आदि सहित को नयाँ जानकारी प्रदान गर्दछौं।
    </p>
    <p>
    （URL:<a href="https://www.bousai.pref.fukuoka.jp" target="_blank">
    https://www.bousai.pref.fukuoka.jp
    </a> ）
    </p>
    <h2>विपद् रोकथाम ह्यान्डबुक</h2>
    <p>
    ९ वटा भाषामा「विदेशीहरूका लागि विपद् रोकथाम ह्यान्डबुक」बनाएको हुनाले 、 पहिले नै जाँच गरेर、विपद्को बारे तयारी गर्नुहोस्।
    </p>
    <p>
    （URL:<a href="https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html" target="_blank">https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html</a>）
    </p>
    <p>
    प्रकाशनका सामग्रीहरू<br/>
    ● हुन सक्ने प्रकोपहरूको सामग्री<br/>
    ●विपत्ति आइपर्दा गर्नुपर्ने कार्य<br/>
    ●विपद्को लागि दैनिक तयारी<br/>
    ●निकासी स्थल<br/>
    ●आपतकालीनमा सम्पर्क गर्ने ठाउँ आदि<br/>
    ●उपलब्ध भाषाहरूमा सजिलो जापानी, अंग्रेजी, चिनियाँ, कोरियाली, तागालोग, भियतनामी, नेपाली, इन्डोनेसियाली, बर्मी समावेश छन्।
    </p>
    <p>
    <a href="https://www.pref.fukuoka.lg.jp/uploaded/life/671824_61619901_misc.pdf" target="_blnak">
    <figure>
    <img src={publicPath('images/articles/disasterprevantionhandbook.png')} alt="disaster prevantion handbook" className="fit-img"/>
    </figure>
    </a>
    </p>

    <h2>विदेशीहरूका लागि विपद् रोकथाम तालिम</h2>
    <p>
    यसबाहेक、प्रकोपको बेला विदेशी बासिन्दाहरूले उपयुक्त कदम चाल्ने बनाउनको लागि、विदेशी बासिन्दाहरूलाई विपद् रोकथाम अभ्यासहरू सञ्चालन गर्दैछौं、त्यसैले कृपया सहभागी भएर、प्रकोपको समयमा चाल्नु पर्ने कदम बारे अनुभव लिनुहोस्।
    </p>
    <p>
    ●जापानमा घट्न सक्ने सम्भावित प्रकोपहरू बारे व्याख्यान<br/>
    ●प्रयोगात्मक सिकाइ (निकासी केन्द्रहरूमा प्रयोग हुने उपकरणहरू मिलाउने, तत्काल प्रकोप रोकथामका सामानहरू प्रयोग हरेर अनुभव गर्ने आदि)इत्यादि
    </p>
    <figure>
    <img src={publicPath('images/articles/disasterinfo01.jpg')} alt="" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/disasterinfo02.jpg')} alt="" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/disasterinfo03.jpg')} alt="" className="fit-img"/>
    </figure>
    <h2>प्रकोप बहुभाषिक सहायता केन्द्र</h2>
    <p>
    ठूलो परिमाणको विपद्को अवस्थामा सहर/नगर/गाउँ बाट विदेशीलाई सहयोग गर्न、「विपद् बहुभाषिक सहायता केन्द्र」स्थापना गर्नेछौं।
    </p>
    <p>
    ●फुकुओका प्रान्त प्रकोप प्रतिक्रिया मुख्यालय स्थापना गर्दा 、इन्टरनेशनल एक्सचेन्ज सेन्टरमा स्थापना गरिनेछ।<br/>
    ●स्थापनाको क्रममा २४ सै घण्टा उपलब्ध हुन्छ। (विपद्को स्थितिमा निर्भर गर्दै परिवर्तनको हुन्छ।)<br/>
    ●बहुभाषी सहायता केन्द्रका मुख्य कर्तव्यहरू निम्नानुसार छन्।<br/>
    ⇒​विदेशी नागरिकको अवस्थाको बारेमा प्रभावित सहर/नगर/गाउँ बाट जानकारी सङ्कलन गर्नेछौं।<br/>
    ⇒प्रकोप-प्रभावित सहर/नगर/गाउँहरूको अनुरोधमा, विपद्को समयमा जानकारी प्रदान गर्दछौं र निकासी आश्रय कार्यहरूको सम्बन्धमा व्याख्या・अनुवाद सहयोग गर्दछौं।<br/>
    ⇒प्रकोप प्रभावित सहर/नगर/गाउँहरूको अनुरोधमा, प्रकोप क्षेत्रमा व्याख्या・अनुवादक स्वयंसेवकहरू पठाउँछौं।
    </p>
    </EmphasisNote>
    <br/>
    <NavigatorNice dir="right"></NavigatorNice>
    </>)
}

export default LangNe