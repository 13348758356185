
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import { AreaMap } from "./areaMap"
const SSvg = styled.div`
      
`
const SOverride = styled.div`
@media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
(max-width: ${props => props.theme.responsive.minPc}) ),
screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
    .fukidashiPosition{
        &.kitakyu{
            top: 18% !important;
        }
        &.chikuhou{
            bottom: 48% !important;
        }
        &.chikugo{
            bottom: 42% !important;
        }
    }
}
`

const LangKo = ({onSwich, onFukidashi}) => {

    return (
        <SOverride>
            <div className="area-info">
            <SSvg>
                <figure className="map">
                <svg id="_レイヤー_1" data-name="レイヤー 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.92 286.92">
                    <rect className="fukuoka" onClick={() => {onSwich(4)}} x="11.44" y="58.23" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(97.1 140.13) rotate(180)"/>
                    <g onClick={() => {onSwich(4)}}>
                        <path className="moji" d="M22.95,73.03h-4.35v2.86h-1.25v-2.86h-4.33v-1.01h9.93v1.01ZM22.38,67.12h-8.87v-1h3.83v-1.23h1.25v1.23h3.79v1ZM17.98,67.65c2.28,0,3.65.67,3.65,1.86s-1.37,1.86-3.65,1.86-3.65-.67-3.65-1.86,1.37-1.86,3.65-1.86ZM17.98,68.6c-1.53,0-2.35.32-2.35.91s.82.92,2.35.92,2.34-.33,2.34-.92-.81-.91-2.34-.91Z"/>
                        <path className="moji" d="M34.39,71.8h-4.32v4.08h-1.26v-4.08h-4.32v-1.03h7.1c.16-.69.27-1.3.32-1.87l-6.39.31-.15-1.05,6.61-.23c.02-.49.02-.92.02-1.36h-6.3v-1.02h7.51v1.01c0,1.14,0,2.41-.39,4.21h1.56v1.03Z"/>
                        <path className="moji" d="M45.87,73.51v1.02h-9.92v-1.02h4.32v-2.1c-2-.18-3.4-1.29-3.4-2.91,0-1.78,1.7-2.94,4.03-2.94s4.03,1.15,4.03,2.94c0,1.62-1.41,2.74-3.41,2.92v2.09h4.36ZM40.9,70.44c1.63,0,2.8-.75,2.8-1.94s-1.18-1.94-2.8-1.94-2.81.75-2.81,1.94,1.18,1.94,2.81,1.94Z"/>
                        <path className="moji" d="M53.06,66.01c0,3.28-.96,6.14-4.94,8.14l-.68-.97c1.92-.96,3.07-2.1,3.71-3.45l-3.47.34-.17-1.08,4.01-.25c.15-.53.24-1.1.29-1.71h-3.75v-1.02h4.99ZM57.55,70.29h-1.66v5.58h-1.25v-10.95h1.25v4.33h1.66v1.04Z"/>
                        <path className="moji" d="M66.61,68.09c0,1.82,1.13,3.71,2.84,4.44l-.69.98c-1.27-.56-2.26-1.75-2.77-3.17-.48,1.53-1.47,2.83-2.75,3.45l-.71-1c1.7-.79,2.83-2.83,2.83-4.71v-1.04h-2.44v-1.03h6.14v1.03h-2.44v1.04ZM71.58,64.92v10.95h-1.26v-10.95h1.26Z"/>
                        <path className="moji" d="M83.11,64.91v6.49h-1.25v-1.49h-2.6c-.51.59-1.31.96-2.23.95-1.62,0-2.85-1.12-2.85-2.67s1.23-2.67,2.85-2.67c.92,0,1.72.36,2.23.95h2.6v-1.54h1.25ZM78.69,68.19c0-.96-.72-1.61-1.66-1.61s-1.66.66-1.66,1.61.72,1.6,1.66,1.6,1.66-.64,1.66-1.6ZM75.72,71.95h7.39v3.94h-1.25v-2.94h-6.14v-1.01ZM81.86,68.89v-1.41h-2.06c.05.22.09.45.09.7s-.04.49-.1.7h2.07Z"/>
                    </g>
                    <rect className="kitakyu" onClick={() => {onSwich(1)}} x="189.13" y="10.6" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(452.46 44.87) rotate(180)"/>
                    <g onClick={() => {onSwich(1)}}>
                        <path className="moji" d="M196.52,18.45c0,3.26-1.31,6.03-5.07,7.85l-.67-1c2.88-1.38,4.23-3.31,4.45-5.83h-3.91v-1.02h5.19ZM199.74,17.29v10.96h-1.25v-10.96h1.25Z"/>
                        <path className="moji" d="M203.87,24.67c1.75-.01,3.08-.07,4.62-.33l.12,1.02c-1.69.27-3.14.33-5.11.33h-.89v-7.45h5.12v1.03h-3.87v2.04h3.66v1.01h-3.66v2.35ZM212.36,22.62h-1.71v5.64h-1.25v-10.98h1.25v4.31h1.71v1.03Z"/>
                        <path className="moji" d="M223.54,24.11h-2.49v4.15h-1.25v-4.15h-2.51v4.15h-1.25v-4.15h-2.41v-1.03h7.12c.15-.68.24-1.27.3-1.83l-6.39.32-.16-1.06,6.61-.23c.02-.48.02-.92.02-1.36h-6.29v-1.02h7.51v1.01c0,1.13,0,2.39-.38,4.17h1.56v1.03Z"/>
                        <path className="moji" d="M235.01,24.47h-2.4v3.85h-1.24v-3.85h-2.62v3.85h-1.25v-3.85h-2.43v-1.02h9.93v1.02ZM234.07,22.4c-1.82-.3-3.3-1.25-4.02-2.52-.7,1.29-2.2,2.22-4.01,2.52l-.49-1.02c2.21-.33,3.83-1.7,3.83-3.15v-.57h1.32v.57c0,1.43,1.65,2.83,3.84,3.15l-.49,1.02Z"/>
                        <path className="moji" d="M244.29,20.46c0,1.82,1.13,3.71,2.84,4.44l-.69.98c-1.27-.56-2.26-1.75-2.77-3.17-.49,1.53-1.47,2.83-2.75,3.45l-.72-1c1.7-.79,2.83-2.83,2.83-4.71v-1.04h-2.44v-1.03h6.14v1.03h-2.44v1.04ZM249.26,17.29v10.96h-1.26v-10.96h1.26Z"/>
                        <path className="moji" d="M260.79,17.28v6.49h-1.25v-1.49h-2.6c-.51.59-1.31.96-2.23.95-1.63,0-2.85-1.12-2.85-2.67s1.22-2.67,2.85-2.67c.92,0,1.72.36,2.23.95h2.6v-1.54h1.25ZM256.37,20.56c0-.96-.72-1.61-1.66-1.61s-1.66.66-1.66,1.61.71,1.6,1.66,1.6,1.66-.64,1.66-1.6ZM253.4,24.32h7.39v3.94h-1.25v-2.94h-6.14v-1.01ZM259.54,21.26v-1.41h-2.06c.05.22.08.45.08.7s-.04.49-.1.7h2.07Z"/>
                    </g>
                   
                    <rect className="chikugo" onClick={() => {onSwich(2)}} x="11.44" y="185.61" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(97.1 394.9) rotate(180)"/>
                    <g onClick={() => {onSwich(2)}}>
                        <path className="moji" d="M22.8,195.47c0,1.82,1.13,3.71,2.84,4.44l-.69.98c-1.27-.56-2.26-1.75-2.77-3.17-.48,1.53-1.47,2.83-2.75,3.45l-.71-1c1.7-.79,2.83-2.83,2.83-4.71v-1.04h-2.44v-1.03h6.14v1.03h-2.44v1.04ZM27.77,192.31v10.95h-1.26v-10.95h1.26Z"/>
                        <path className="moji" d="M40.12,199.18h-4.32v4.08h-1.26v-4.08h-4.32v-1.03h7.1c.16-.69.27-1.3.32-1.87l-6.39.31-.15-1.05,6.61-.23c.02-.49.02-.92.02-1.36h-6.3v-1.02h7.51v1.01c0,1.14,0,2.41-.39,4.21h1.56v1.03Z"/>
                        <path className="moji" d="M51.58,200.8v1.03h-9.9v-1.03h3.69v-3.86h1.26v3.86h4.95ZM49.25,194.34h-6.54v-1.02h7.8v1.08c0,1.36,0,2.88-.42,5.14l-1.26-.13c.42-2.15.42-3.7.42-5.01v-.06Z"/>
                        <path className="moji" d="M60.88,195.47c0,1.82,1.13,3.71,2.84,4.44l-.69.98c-1.27-.56-2.26-1.75-2.77-3.17-.49,1.53-1.47,2.83-2.75,3.45l-.71-1c1.7-.79,2.83-2.83,2.83-4.71v-1.04h-2.44v-1.03h6.14v1.03h-2.44v1.04ZM65.85,192.31v10.95h-1.26v-10.95h1.26Z"/>
                        <path className="moji" d="M77.37,192.29v6.49h-1.25v-1.49h-2.6c-.51.59-1.31.96-2.23.95-1.63,0-2.85-1.12-2.85-2.67s1.23-2.67,2.85-2.67c.92,0,1.72.36,2.23.95h2.6v-1.54h1.25ZM72.96,195.57c0-.96-.72-1.61-1.66-1.61s-1.66.66-1.66,1.61.72,1.6,1.66,1.6,1.66-.64,1.66-1.6ZM69.99,199.33h7.39v3.94h-1.25v-2.94h-6.14v-1.01ZM76.13,196.27v-1.41h-2.06c.05.22.08.45.08.7s-.04.49-.1.7h2.07Z"/>
                    </g>
                    <AreaMap onSwich={onSwich}/>
                    <rect className="chikuhou" onClick={() => {onSwich(3)}} x="203.28" y="173.27" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(480.77 370.22) rotate(180)"/>
                    <g onClick={() => {onSwich(3)}}>
                        <path className="moji" d="M214.63,183.13c0,1.82,1.13,3.71,2.84,4.44l-.69.98c-1.27-.56-2.26-1.75-2.77-3.17-.49,1.53-1.47,2.83-2.75,3.45l-.71-.99c1.7-.79,2.83-2.83,2.83-4.71v-1.04h-2.44v-1.03h6.14v1.03h-2.44v1.04ZM219.6,179.96v10.95h-1.26v-10.95h1.26Z"/>
                        <path className="moji" d="M231.95,186.84h-4.32v4.08h-1.26v-4.08h-4.32v-1.03h7.1c.16-.69.27-1.3.32-1.87l-6.39.32-.15-1.06,6.61-.23c.02-.48.02-.92.02-1.36h-6.29v-1.02h7.51v1.01c0,1.14,0,2.41-.39,4.21h1.56v1.03Z"/>
                        <path className="moji" d="M243.44,188.7v1.03h-9.93v-1.03h4.33v-1.41c-1.88-.13-3.03-.88-3.03-2.1,0-1.32,1.41-2.1,3.64-2.1s3.65.78,3.65,2.1c0,1.21-1.13,1.95-3.01,2.1v1.41h4.35ZM242.93,182.46h-8.96v-1.01h3.87v-1.35h1.25v1.35h3.85v1.01ZM238.44,186.33c1.5,0,2.38-.41,2.38-1.14s-.87-1.13-2.38-1.13-2.37.41-2.37,1.13.87,1.14,2.37,1.14Z"/>
                        <path className="moji" d="M252.71,183.13c0,1.82,1.13,3.71,2.84,4.44l-.69.98c-1.27-.56-2.26-1.75-2.77-3.17-.49,1.53-1.47,2.83-2.75,3.45l-.72-.99c1.7-.79,2.83-2.83,2.83-4.71v-1.04h-2.44v-1.03h6.14v1.03h-2.44v1.04ZM257.69,179.96v10.95h-1.26v-10.95h1.26Z"/>
                        <path className="moji" d="M269.21,179.95v6.49h-1.25v-1.49h-2.6c-.51.59-1.31.96-2.23.95-1.63,0-2.85-1.12-2.85-2.67s1.22-2.67,2.85-2.67c.92,0,1.72.36,2.23.95h2.6v-1.54h1.25ZM264.79,183.23c0-.96-.72-1.61-1.66-1.61s-1.66.66-1.66,1.61.71,1.6,1.66,1.6,1.66-.64,1.66-1.6ZM261.82,186.99h7.39v3.94h-1.25v-2.94h-6.14v-1.01ZM267.96,183.93v-1.41h-2.06c.05.22.08.45.08.7s-.04.49-.1.7h2.07Z"/>
                    </g>
                    </svg>
                </figure>
            </SSvg>
                <div className={`fukidashiPosition kitakyu ${onFukidashi === 1 ? 'active' : ''}`}>
                    <div className="fukidashi kitakyu">
                        <figure>
                        <img src={publicPath('images/basicinfo_area/ko/kitakyu.svg')} className="fit-img"
                        alt={`철강, 자동차, 첨단 반도체, 로봇 등의 산업이 발전했으며 축적된 '제조 기술'을 살려 지역의 활성화를 도모하고 있습니다. 24시간 운항할 수 있는 기타큐슈 공항이 있어 아시아 물류 거점의 기반이 되고 있습니다.`} />
                        </figure>
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition fukuoka ${onFukidashi === 4 ? 'active' : ''}`}>
                    <div className="fukidashi fukuoka">
                        <figure>
                        <img src={publicPath('images/basicinfo_area/ko/fukuoka.svg')} className="fit-img"
                        alt={`규슈의 관리 중추 기능과 3차 산업이 집적되면서 서일본의 전략 지역으로서 발전해 왔습니다. 규슈 신칸센과 후쿠오카 공항, 하카타 항 등 교통 기반이 정비되어 있습니다.`} />
                        </figure>
                        
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition chikugo ${onFukidashi === 2 ? 'active' : ''}`}>
                    <div className="fukidashi chikugo">
                    
                    <figure>
                    <img src={publicPath('images/basicinfo_area/ko/chikugo.svg')} className="fit-img"
                        alt={`풍부한 자연의 농림수산물, 지역산업·상공업 등의 다양한 산업, 문화, 개성 있는 도시군 등의 매력이 넘치는 지역입니다. 개성 풍부한 도시가 각각의 기능을 연계·보존해 발전하기 위해서 지쿠고 네트워크 전원 도시 구상을 추진하고 있습니다.`} />
                    </figure>
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition chikuhou ${onFukidashi === 3 ? 'active' : ''}`}>
                    <div className="fukidashi chikuhou">
                    <figure>
                        <img src={publicPath('images/basicinfo_area/ko/chikuho.svg')} className="fit-img"
                        alt={`석탄 산업을 대체할 새로운 산업으로서 자동차 산업의 입지가 확보되었고 자동차 산업을 지탱할 인재 육성이 활발하게 이루어지고 있습니다. 이공계 대학을 중심으로 벤처 기업이나 연구 기관의 집적을 도모해 새로운 산업 창출의 거점도 마련되고 있습니다.`} />
                        </figure>
                    
                        <span className="speak"></span>
                    </div>
                </div>
            </div>
        </SOverride>
    )
}

export default LangKo