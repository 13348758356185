import publicPath from "src/utilty/publicPath"


const LangTh = () => {

    return (<>
    <h1>
    สารจากผู้ว่าราชการจังหวัด
    </h1>
   <figure className="governor">
     <img src={publicPath('images/fukuoka_governor.jpg')} className="img-fit"/>
    </figure>
    <p>
    　สวัสดีครับทุกท่าน
    ขอต้อนรับทุกท่านเข้าสู่เว็บไซต์พอร์ทัล 「FUKUOKA IS OPEN」ด้วยความยินดีเป็นอย่างยิ่งครับ
    </p>
    <p>
    　จังหวัดฟุกุโอกะเป็นประตูสู่กับเอเชียและประเทศอื่นๆ ทั่วโลก เนื่องจากจังหวัดมีความอุดมสมบูรณ์ทางธรรมชาติและประวัติศาสตร์ ทำให้วัฒนธรรมและอุตสาหกรรมต่างๆ เจริญรุ่งเรือง ทรัพยากรบุคคลหลายหลายสาขาวิชาชีพทำงานอย่างมีศักยภาพ
    </p>
    <p>
    　「FUKUOKA IS OPEN」เป็นเว็บไซด์พอร์ทัลที่จังหวัดใช้เป็นช่องทางการให้ข้อมูลเกี่ยวกับช่องทางขอคำปรึกษา การศึกษา การแพทย์และอื่นๆ เพื่อสนับสนุนการดำรงชีวิตในจังหวัดฟุกุโอกะ บอกเล่าเรื่องราวความสำเร็จของชาวต่างชาติที่เดินทางจากบ้านเกิดมาประกอบอาชีพอยู่ในจังหวัด แนะนำการดำเนินการที่ล้ำสมัยของจังหวัดทั้งทางด้านการกีฬา สิ่งแวดล้อม และ One Health รวมทั้งเผยแพร่เสน่ห์ของจังหวัดทั้งด้านอาหารการกิน การท่องเที่ยว และอื่นๆ อีกด้วย
    </p>
    <p>
    　จังหวัดยินดีที่จะสนับสนุนอย่างเต็มที่เพื่อให้ชาวต่างชาติเดินทางมาจังหวัดฟุกุโอกะเป็นจำนวนมากขึ้น ให้ทุกคนสามารถดำรงชีวิตอยู่อย่างอุ่นใจและมีแต่รอยยิ้ม
    </p>
    <p>
    มาร่วมกันสร้างอนาคตด้วยกันที่นี่ที่ฟุกุโอกะ ให้เป็นไปอย่างคำขวัญที่ว่า「FUKUOKA IS OPEN」
    </p>
    <br/>
    <br/>
    <p className="al-r">
    <strong>
    นายเซทาโร ฮัทโทริ</strong>
    </p>
    </>)
}

export default LangTh