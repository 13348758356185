import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { createClient } from 'microcms-js-sdk';
import { AritcleIndex, ArticleObj, ArticleCategory, endpoint, ArticleCategoryIndex, AritcleSearchRes, IEmergencyArticleIndex } from "src/types/article"
import { langType } from 'src/types/lang';

// 型定義
interface FetchOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: Record<string, string>;
  data?: any;
}

export interface FetchArtcleIndexArg {
  endpoint: endpoint,
  lang: langType,
  offset: number,
  limit: number,
  categroyId?: string,
  ids?: string,
}

const client = createClient({
  serviceDomain: "fukuokaisopen", // YOUR_DOMAIN is the XXXX part of XXXX.microcms.io
  apiKey: "T19jMpWsSMMjLGbyloPJD8jUtCjRboZbrCGd",
  // retry: true // Retry attempts up to a maximum of two times.
  // customFetcher: fetch.bind(globalThis), // Provide a custom `fetch` implementation as an option
});

const allLang = `ja,en,ko,vi,th,zh_tw,zh_cn,ne`

/**=======================================
 * fetchCategory
 * カテゴリの取得. 
 --------------------------------------------*/
export async function fetchCategory
(endpoint: string, name: string) : Promise<ArticleCategoryIndex>
{ 
   let response: ArticleCategoryIndex

   try {
    response = await client.get({
                    endpoint: `${endpoint}-category`,
                    queries: { filters: `en[equals]${name}`, limit: 1 }
                  })
                  
  }catch (error) {
    throw error;
  }
  return response
}


/**=======================================
 * fetchArticleIndex
 * 記事一覧取得. 
 --------------------------------------------*/
export async function fetchArticleIndex
( args:FetchArtcleIndexArg) :Promise<AritcleIndex> 
{
  let response: AritcleIndex
  try {
    response = await client.get({
                    endpoint: args.endpoint,
                    queries: { 
                      fields: `id,title,${allLang},createdAt,publishedAt,category,eyecatch,langTitle`,
                      offset: args.offset, 
                      limit: args.limit,
                      orders: '-publishedAt' }
                  })
  }catch (error) {
    throw error;
  }
  return response
}

/**=======================================
 * fetchArticleIndex
 * 記事一覧取得. 
 --------------------------------------------*/
 export async function fetchArticleEmergency
 ( args:FetchArtcleIndexArg) :Promise<IEmergencyArticleIndex> 
 {
   let response: IEmergencyArticleIndex
   try {
     response = await client.get({
                     endpoint: 'emergency',
                     queries: { 
                       offset: args.offset, 
                       limit: args.limit,
                       orders: '-publishedAt' }
                   })
   }catch (error) {
     throw error;
   }
   return response
 }

/**=======================================
 * fetchArticleNews
 * Newsでfillerをかけた記事一覧取得. 
 --------------------------------------------*/
 export async function fetchArticleNews
 ( args:FetchArtcleIndexArg) :Promise<AritcleIndex> 
 {
   let response: AritcleIndex
   try {
     response = await client.get({
                     endpoint: args.endpoint,
                     queries: { 
                       fields: `id,title,${allLang},createdAt,publishedAt,category,eyecatch,langTitle`,
                       filters: `news[equals]true`,
                       offset: args.offset, 
                       limit: args.limit,
                       orders: '-publishedAt' }
                   })
   }catch (error) {
     throw error;
   }
   return response
 }
 

/**=======================================
 * fetchArticleIndex
 * 記事一覧取得. 
 --------------------------------------------*/
export async function fetchArticlesFillerCategory
  ( args:FetchArtcleIndexArg) :Promise<AritcleIndex> 
{
    let response: AritcleIndex
    try {
      response = await client.get({
                      endpoint: args.endpoint,
                      queries: { 
                        fields: `id,title,${args.lang},createdAt,publishedAt,category,eyecatch,langTitle`,
                        filters: `category[equals]${args.categroyId}`,
                        offset: args.offset, 
                        limit: args.limit,
                        orders: '-publishedAt' }
                    })
    }catch (error) {
      throw error;
    }
    return response

}

export async function fetchArticle
  (endpoint: endpoint, lang: langType, contentId: string):Promise<ArticleObj> 
  {
    let response: ArticleObj
    try {
      response = await client.get({
                      endpoint: endpoint,
                      contentId: contentId,
                      queries: { fields: `id,title,${allLang},createdAt,updatedAt,langTitle`, orders: '-createdAt' }
                    })
      }catch (error) {
        throw error;
      }
      return response
  }




/** =====================================================
キーワード検索用 API通信
===========*/
export async function fetchKewordSearch
  (lang: langType, keyword:string):Promise<AritcleSearchRes> 
  {
    let response: AritcleSearchRes

    try {
      response = await client.get({
                      endpoint: `articles-${lang}`,
                      queries: { q: keyword, fields: `id`, orders: '-createdAt' }
                    })
      }catch (error) {
        throw error;
      }
      return response
  }

export async function fetchArticlesFillerIds
  ( args:FetchArtcleIndexArg) :Promise<AritcleIndex> 
{
    let response: AritcleIndex
    try {
      response = await client.get({
                      endpoint: args.endpoint,
                      queries: { 
                        fields: `id,title,${args.lang},publishedAt,category,eyecatch,langTitle`,
                        filters: `${args.lang}[equals]${args.ids}`,
                        offset: args.offset, 
                        limit: args.limit,
                        orders: '-publishedAt' }
                    })
    }catch (error) {
      throw error;
    }
    return response

}

export async function fetchArticlesFillerIdsCategry
  ( 
  args: FetchArtcleIndexArg,
) :Promise<AritcleIndex> 
{
    let response: AritcleIndex
    
    try {
      response = await client.get({
                      endpoint: args.endpoint,
                      queries: { 
                        fields: `id,title,${args.lang},publishedAt,category,eyecatch,langTitle`,
                        offset: args.offset, 
                        limit: args.limit,
                        filters: `${args.lang}[equals]${args.ids},category[equals]${args.categroyId}`,
                        orders: '-publishedAt' }
                    })
    }catch (error) {
      throw error;
    }
    return response

}
