import styled from "styled-components"

import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang"
import Article from "@/components/Organisms/Article"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"

const SSymbol = styled.div`
   
    figure{
        margin: 0;
        margin-bottom: 10px;
    }
    p{
        margin-bottom: 30px;
    }
    .pt{
        padding-top: 20px;
    }
    .navigatorPositon{
        position: relative;
        width: 100%;
        padding-bottom: 15%;
        figure{
            position: absolute;
            width: 24%;
            right: 0px;
            top: -160%;
        }
    }
`

const BasciInfoSymbol = ({lang}:{lang:langType}) => {


    setTitleDiscrption({
        layer:'basicinfo',
        lang:lang,
        subLayer:"symbol"
    })

    return(
        <SSymbol>
            <Article className="pc-noTopMargin">
            {
            lang === 'ja' &&
            <LangJa></LangJa>
            }
            {
            lang === 'easy_ja' &&
            <LangJa></LangJa>
            }
            {lang === 'en' &&
                <LangEn></LangEn>
            }
            {lang === 'ko' &&
                <LangKo></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh></LangTh>
            }
            {lang === 'vi' &&
                <LangVi></LangVi>
            }
            {lang === 'ne' &&
                <LangNe></LangNe>
            }

            </Article>
        </SSymbol>
    )
}

export default BasciInfoSymbol