import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorGood, NavigatorPickUp } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"

const LangVi = () => {

    return (<>
     <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="normal" dir="right" actionType="pickup" actionColor="blue"/>
        </div>
        <BlackNote>
        <p className="large">GDP của tỉnh Fukuoka</p>
        <p className="xl">
        178,027 triệu USD
        </p>
        <p className="middle txt-yellow">
        Thu nhập một người của tỉnh:<br/>
        24,790 USD
        </p>
        <p className="al-c">(Tính đến tháng 4 năm 2022)</p>
        <span className="txt-small">
        Tài liệu: Phòng thống kê, điều tra tỉnh<br/>
        “Báo cáo kế toán kinh tế tỉnh/thành phố năm 2020”
        <br/>
        Chuyển đổi ở mức 106,09 yên mỗi đô la (trung bình năm 2020)<br/>
        Tài liệu: Văn phòng Nội Các “ Các chỉ số kinh tế chính Báo cáo kinh tế hàng tháng”
        </span>
        </BlackNote>
    </div>
    <br/>
    <div className="navpicup sp-only">
        <NavigatorPickUp dir="right"></NavigatorPickUp>
    </div>
    <InnerBox top={20}>
    <EmphasisNote>
    <h2>Nông nghiệp</h2>
    <p>
    Diện tích đất canh tác của tỉnh Fukuoka là 78.900 ha (năm 2020), chiếm 16% diện tích đất của tỉnh. Điểm đặc biệt là tỷ lệ lúa nước chiếm tỷ lệ cao ở mức 81% (trung bình toàn quốc là 54%).
    <br/>
    </p>
    <h2>Rừng・Lâm nghiệp</h2>
    <p>
    Diện tích rừng của tỉnh Fukuoka khoảng 224.000 ha, chiếm 45% diện tích đất của tỉnh. Rừng có chức năng công ích như bảo vệ nguồn nước và phòng chống sạt lở đất đồng thời đóng vai trò quan trọng trong việc đảm bảo an toàn và an ninh cho cuộc sống của người dân trong tỉnh.
    <br/>
    </p>
    <h2>Ngành công nghiệp thủy sản</h2>
    <p>
    Tại tỉnh Fukuoka, nhiều hoạt động đánh bắt và nuôi trồng thủy sản được diễn ra ở nhiều nơi với các đặc thù khác nhau như biển Chikuzen, biển Ariake, biển Buzen và các vùng nước ngọt như sông, hồ...<br/>
    Nhiều loại hải sản được đánh bắt trong đó có cá tráp biển đỏ và rong biển Nori có sản lượng hàng đầu cả nước.
    <br/>
    </p>
    <h2>Thương mại và công nghiệp</h2>
    <p>
    Công nghiệp ở tỉnh Fukuoka chú trọng phát triển chủ yếu ở các ngành công nghiệp vật liệu cơ bản như thép, than, hóa chất và các ngành liên quan đến đời sống như chế biến thực phẩm. Tuy nhiên, trong những năm gần đây, tỷ trọng của các ngành công nghiệp lắp ráp gia công như các ngành liên quan đến ô tô đang tăng lên.<br/>
    Hơn nữa, ngành chế biến thực phẩm ngày càng phát triển do có nguồn nông lâm thủy sản dồi dào, chất lượng cao cũng như vị trí gần môi trường vận tải hàng hóa và nằm trong vùng tiêu thụ lớn.
    </p>
    </EmphasisNote>
    </InnerBox>
    <InnerBox top={20}>

   
    <h1>
    Growth Industry<br/>
    Những ngành công nghiệp phát triển vì tương lai của Fukuoka
    </h1>
    <p>
    Fukuoka có ngành chế tạo với công nghệ vượt trội, các trường đại học, viện nghiên cứu tiên tiến.<br/>
    Tỉnh đang tập trung khuyến khích các công ty tham gia và đầu tư vào các ngành đang phát triển vì thị trường cho các ngành này dự kiến sẽ được mở rộng.
    </p>
    <EmphasisNote>
    <h2>1. Ngành công nghiệp sinh học</h2>
    <p className="txt-middle">
    Fukuoka được công nhận là cộng đồng sinh học hàng đầu và đang đẩy nhanh dự án nhằm trở thành khu trọng điểm công nghệ sinh học tiên tiến.
    </p>
    <p>
    Từ năm 2001, tỉnh Fukuoka cùng với thành phố Kurume đã lấy "Hội đồng xúc tiến trọng điểm công nghiệp sinh học Fukuoka" làm trọng điểm nhằm tập hợp các doanh nghiệp liên quan đến sinh học.<br/>
    Kết quả nỗ lực của Hội đồng xúc tiến là sự gia tăng từ 32 công ty sinh học ban đầu lên đến hơn 230 công ty.
    Tại Fukuoka, các công ty khởi nghiệp sinh học thu hút sự quan tâm của cả trong và ngoài nước đang tiếp nối nhau xuất hiện như Công ty cổ phần Bonac đang được chú ý trong việc phát triển thuốc điều trị COVID-19 theo phương pháp điều trị hiện đại bằng y dược hạt nhân, Công ty cổ phần Edit Force chuyên công nghệ chỉnh sửa gen trên toàn Nhật Bản...<br/>
    Lợi thế này của tỉnh Fukuoka đã được đánh giá cao. Vào tháng 6 năm 2021, Fukuoka đã được chính phủ công nhận là "Cộng đồng Sinh học Địa phương" duy nhất ở Tây Nhật Bản (trong tổng số 4 địa điểm trên toàn quốc).
    </p>
    <h2>2. Ngành công nghiệp vũ trụ</h2>
    <p className="txt-middle">
    Fukuoka là địa phương đầu tiên được chọn để thúc đẩy sáng tạo kinh doanh vũ trụ ở Kyushu. Khám phá vũ trự với công nghệ phần cứng và phần mềm xuất phát từ Fukuoka.
    </p>
    <p>
    Tỉnh Fukuoka tập trung các doanh nghiệp tiềm năng trong lĩnh vực kinh doanh liên quan đến vũ trụ, các doanh nghiệp khởi nghiệp công nghệ thông tin tạo ra các dịch vụ mới và độc đáo, ngành chế tạo với kỹ thuật vượt trội.<br/>
    Công ty iQPS (trụ sở tại thành phố Fukuoka) đã phát triển và phóng thành công một loại vệ tinh SAR nhỏ có khả năng quan sát bề mặt trái đất mà không bị ảnh hưởng bởi ngày đêm hay thời tiết, đạt đến mức độ cao nhất trên thế giớiVào tháng 5 năm 2021, họ đã chụp được hình ảnh có độ phân giải cao nhất trên thế giới.iQPS đang hướng đến việc mở rộng hệ thống quan sát bề mặt đất mỗi 10 phút với hệ thống 36 vệ tinh từ năm 2025.<br/>
    Tháng 9 năm 2020, Nội các đã chọn tỉnh Fukuoka làm địa phương đầu tiên trong việc thúc đẩy sáng tạo kinh doanh vũ trụ tại Kyushu.
    </p>
    <h2>3. Công nghệ Blockchain</h2>
    <p className="txt-middle">
    Tập hợp nguồn nhân lực và công nghệ tiên tiến của Nhật Bản để thành lập trung tâm blockchain hàng đầu của Nhật Bản
    </p>
    <p>
    Blockchain, được phát triển như một công nghệ để quản lý tài sản mật mã, đang thu hút sự chú ý nhờ khả năng truy xuất nguồn gốc, khả năng ngăn chặn giả mạo dữ liệu và khả năng chống trục trặc.<br/>
    Tại tỉnh Fukuoka, không chỉ có nhiều công ty khởi nghiệp trong lĩnh vực blockchain và các kỹ sư có tay nghề cao, mà còn đi đầu trong nghiên cứu về blockchain, có Khoa Kỹ thuật Máy tính và Công nghệ thông tin thuộc Đại học Công nghiệp Kyushu và Khoa Công nghiệp và Kỹ thuật thuộc Đại học Kinki nơi đào tạo ra nhiều kỹ sư xuất sắc.<br/>
    Nhằm khai thác lợi ích từ việc xúc tiến ngành công nghiệp IT, từ năm 2020, tỉnh Fukuoka đã chính thức hỗ trợ các nghiên cứu phát triển công nghệ blockchain cũng như hỗ trợ các chương trình đào tạo nhân sự cho lĩnh vực này. Fukuoka đang dần trở thành trọng điểm công nghệ blockchain tại Nhật Bản.
    </p>
    <h2>4. Ngành công nghiệp năng lượng</h2>
    <p className="txt-middle">
    Phát triển ngành công nghiệp năng lượng hydro, tập trung doanh nghiệp và triển khai điện gió trên biển.
    </p>
    <p>
    Nhật Bản đang sử dụng năng lượng có khả năng tái tạo làm nguồn điện chính với mục tiêu đạt được mức trung hòa carbon vào năm 2050.Trong số các nguồn năng lượng có khả năng tái tạo, sản xuất điện gió ngoài khơi được kỳ vọng sẽ là lựa chọn lý tưởng cho Nhật Bản, quốc gia được bao bọc bốn phía bởi đại dương.<br/>
    Tỉnh Fukuoka cũng đang hợp tác với các tổ chức sản xuất, giới học thuật và chính quyền để thúc đẩy việc triển khai sản xuất điện gió trên biển, và thu hút các công ty điện gió.Ngoài ra, tỉnh còn thành lập "Hội đồng Chiến lược Năng lượng Hydro Tỉnh Fukuoka" nơi các tổ chức sản xuất, giới học thuật và chính quyền cùng nhau hợp tác để hướng đến hiện thực hóa một xã hội sử dụng năng lượng hydro thân thiện với môi trường.<br/>
    Bên cạnh nghiên cứu phát triển trên diện rộng từ chế tạo, lưu trữ, vận chuyển đến sử dụng hydro, phát triển sản phẩm và đào tạo nhân sự, tỉnh còn tiến hành tập trung, đào tạo các doanh nghiệp hoạt động trong lĩnh vực năng lượng hydro.
    </p>
    <h2>5. Các ngành công nghiệp khác</h2>
    <p className="txt-middle">
    Các ngành tăng trưởng chính ở Fukuoka và Kyushu
    </p>
    <p>
    Ở Kyushu, các ngành môi trường, nông, lâm ngư nghiệp và công nghiệp ô tô đã thành công và được xem là các ngành tăng trưởng trong khu vực<br/>
    ●Ngành công nghiệp môi trường<br/>
    Ngoài việc tái chế chai nhựa và thiết bị gia dụng, tỉnh đang tích cực nỗ lực đảm bảo các nguồn tài nguyên hữu hạn không bị lãng phí thông qua việc tái chế kim loại quý hiếm được sử dụng trong pin lithium-ion...<br/>
    ●Nông lâm ngư nghiệp<br/>
    Tại Kyushu, ngành nông lâm ngư nghiệp phát triển mạnh mẽ, chiếm khoảng 20% sản lượng nông sản và thủy sản được sản xuất trong nước.
    Đặc biệt ở Fukuoka, nhiều sản phẩm nông sản như dâu Amaou và trà Yame, nhận được đánh giá cao trong và ngoài nước.<br/>
    ●Ngành công nghiệp ô tô<br/>
    Nissan, Toyota, và Daihatsu là những hãng sản xuất ô tô hàng đầu, đều có cơ sở sản xuất tại miền Bắc Kyushu, và tỷ lệ sản xuất ô tô chiếm hơn 15% trên thị trường nội địa.
    </p>
    </EmphasisNote>
    <br/>
    <div className="sp-only">
        <NavigatorGood dir="right"></NavigatorGood>
    </div>
    </InnerBox>
    </>)
}

export default LangVi