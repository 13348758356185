


const LangJa = () => {

    return (
        <div className='article-inner'>
        <section>
        <h1>九州ロゴマークとは</h1>
        <ul className="photos">
            <li>
                <img src="./images/kyushulogomark/logomark01.jpg" alt="九州ロゴマーク"/>
            </li>
            <li>
                <img src="./images/kyushulogomark/logomark02.jpg" alt="九州ロゴマーク ※のれん"/>
            </li>
        </ul>
        <p>このマークは九州を全国、そして世界にアピールするために使用さ れます。九州ロゴマークは、九州ののれんを通して人々をお迎えし たいという思いを込めて、のれん※をモチーフにデザインしました。 「九」、「州」、「一」という漢字で構成され、九州の複数の地域 が一つに連携する様子を表しています。<br/>
        ※のれん<br/>
        のれんとは、お店の入り口に掛けて日よけをする布のことです。日 本独自の文化であり、お店の名前や商標を表示することが多いです。</p>
        </section>
        <section>
        <h1>九州とは</h1>
        <figure>
        <img src="./images/kyushulogomark/kyushu.jpg" alt="九州について"/>
        </figure>
        <p>日本の最西端に位置する九州は、山と海の雄大で美しい自然、温泉、 歴史、文化、グルメなど、日本の魅力を満喫できる場所です。詳し くは動画をご覧ください。</p>
        
        <br/>
        <div className="youtube">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/5GNHVfoQd0s?si=JCxXRHx1bwIt-3EO" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        </section>
        <section>
        <h1>海外での九州ロゴマーク活用実績</h1>
            <p>九州ロゴマークは、海外でも活用されています。</p>
            
            <p><strong>◎BOKKSU株式会社</strong></p>
            <p>「九州お菓子BOX」カルチャーガイドに掲載</p>
            <figure>
            <img src="./images/kyushulogomark/logomark-bkksu.jpg" alt="BOKKUS"/>
            </figure>
            <p className="note">（九州のお菓子を詰め合わせた「九州お菓子BOX」に、お菓子の説明、九州の歴史・文化・観光地を掲載したカルチャーガイドを同梱）</p>
            
            <p><strong>◎株式会社マルタイ</strong></p>
            <p>「棒ラーメンご当地シリーズ」（全８商品）パッケージ裏面に掲載</p>
            <figure>
            <img src="./images/kyushulogomark/logomark-marutai.jpg" alt="Marutai"/>
            </figure>
            <p className="note">（博多とんこつラーメン、熊本黒マー油とんこつラーメン、鹿児島黒豚とんこつラーメン）</p>

            <p><strong>◎株式会社ダイショー</strong></p>
            <p>「山芋鉄板焼きの素」パッケージ裏面に掲載</p>
            <figure>
            <img src="./images/kyushulogomark/yamaimoteppan.jpg" alt="山芋鉄板焼きの素"/>
            </figure>
            
        </section>
        <div className="deco-fukidashi navigatorCheck right smile">
        九州の詳しい観光情報は「Visit Kyushu」をご覧ください。
        </div>
        
        <p>Visit Kyushu(九州旅ネット)</p>
        <a href="https://www.welcomekyushu.com/" target="_blank">
            <figure>
            <img src='./images/banner/tabinet.png' alt="Visit Kyushu（九州旅ネット）" className="img-fit"/>
            </figure>
        </a>
        </div>
    )
}

export default LangJa;