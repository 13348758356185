import { SArticle } from "@/components/Templates/article"
import { langType } from "src/types/lang"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"
import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"

const KyushulogomarkPage = ({lang}:{lang: langType}) => {


    setTitleDiscrption({
        layer : 'basicinfo',
        lang : lang,
        subLayer:"kyushulogomark"
    })
    
    return (
        <SCustomAricle className="pc-noTopMargin">
            {
            lang === 'ja' &&
                <LangJa></LangJa>
            }
            {
            lang === 'easy_ja' &&
            <LangJa></LangJa>
            }
            {lang === 'en' &&
                <LangEn></LangEn>
            }
            {lang === 'ko' &&
                <LangKo></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh></LangTh>
            }
            {lang === 'vi' &&
                <LangVi></LangVi>
            }
            {lang === 'ne' &&
                <LangNe></LangNe>
            }
        </SCustomAricle>
    )
}   

const SCustomAricle = styled(SArticle)`
    section{
        margin-bottom: 3dvb;
    }
    img{
        max-width: 100%;
    }
    .youtube{
        position: relative;
        width: 100%;
        aspect-ratio: 10/5.5;
        margin-bottom: 20px;
        iframe{
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
    ul.photos{
        display: flex;
        justify-content: space-between;
        margin-bottom: 2%;
        li{
            &:first-child{
                flex-basis: 44.5%; 
            }
            &:nth-child(2){
                flex-basis: 54.5%; 
            }
        }
    }
    .note{
        font-size: 1.5rem;
    }
`

export default KyushulogomarkPage;