

const LangEn = () => {

    return (<>
    <h1>Site Policy</h1>
    <p>
    FUKUOKA IS OPEN Promotion Project Executive Committee (hereinafter referred to as the Executive Committee) (https://www.fisop.net hereinafter referred to as the Portal Site) provided by the FUKUOKA IS OPEN Promotion Project Executive Committee (hereinafter referred to as the Executive Committee). Please read the following terms and conditions carefully before using the portal site (, hereinafter referred to as the Portal Site). Please note that the following information is subject to change without notice.
    </p>
    <p>
    <dl>
    <dt>
    ●Disclaimer
    </dt>
    <dd>
    <p>
    「Although every effort has been made to ensure the accuracy and safety of the information contained in the Portal Site, the accuracy and safety of the information is not guaranteed. The Executive Committee assumes no responsibility for any damage or loss incurred by users as a result of information posted on this site.
    </p>
    <p>
    In addition, this portal site may contain links to websites managed by parties other than the Executive Committee, but such linked websites may not be managed or operated by the Executive Committee. Please note that the Executive Committee assumes no responsibility for the authenticity of the content of such websites.
    </p>
    <p>
    Please note that the information on the Portal Site is subject to change or deletion without prior notice.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●Copyrights
    </dt>
    <dd>
    Unless otherwise indicated, the Executive Committee or third parties hold the copyrights to all text, logos, photographs, videos, software, and all other information posted on the Portal Site. Except for personal use or other use permitted by copyright law, you may not use (including reproduction, modification, reprinting, distribution, or public transmission) any of this information without the prior permission of the copyright holder. The copyrights to some images and other materials are held by the original authors.
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●Browser Environment
    </dt>
    <dd>
    <p>
    We recommend using the latest versions of various Internet browsing software (browsers) to view the Portal Site.
    </p>
    <p>
    Please note that the Portal Site may not be displayed correctly when viewed in other ways than those listed above.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●About frequently viewed pages
    </dt>
    <dd>
    <p>
    Internet browsing software (browser) may display information from a previous visit (information temporarily stored in your computer).
    </p>
    <p>
    Therefore, we recommend that you refresh (reload) frequently viewed pages to ensure that they are up-to-date.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●About JavaScript
    </dt>
    <dd>
    <p>
    The Portal Site uses JavaScript in some parts. If your Internet browser does not support JavaScript, or if JavaScript is disabled, some functions will not work.
    </p>
    <p>
    To use all functions, please use an Internet browser that supports JavaScript or enable JavaScript in your Internet browser settings.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●Governing Law
    </dt>
    <dd>
    The use of the Portal Site and the interpretation and application of the Site Policy shall be governed by the laws of Japan, unless otherwise specified.<br/>
    Access to this Portal Site is made of the free will of the person accessing the Portal Site, and use of the Portal Site is at the responsibility of the person accessing the Portal Site. The Fukuoka District Court shall be the court of exclusive jurisdiction for the first trial for any and all disputes related to the Portal Site.
    </dd>
    </dl>
    </p>
    </>)
}

export default LangEn