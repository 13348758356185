import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlue, FukidashiBlueBottom } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { ChallengerAction, NavigatorAction } from "@/components/Molecules/CharactorAction"

const LangJa = () => {
    const lang = 'ja'
    return (
        <InnerBox left={0} right={0} bottom={60}>
        <div className="pc-flex col-2">
            <div className="question">
                <div className="charactor">
                    <ChallengerAction
                        dir="left"
                        charactorType="thinking"
                        actionType="question"/>
                </div>
                <div className="fukidashi">
                    <FukidashiBlue dir="left">
                    <span>日本語が話せません。多言語で対応してくれる相談窓口はありますか。</span>
                    </FukidashiBlue>
                </div>
            </div>

            <div className="anser-block">
                <div className="fukidashi">
                    <FukidashiBlueBottom dir="right">
                    <span>「福岡県外国人相談センター」では、日本語を含む２２か国語で相談ができます。また、各市町村が多言語対応の相談窓口や国際交流協会を設置し、言語サポートや法的なアドバイス、生活全般にわたるサポートを実施しています。</span>
                    </FukidashiBlueBottom>
                </div>
                <div className="charactor">
                    <NavigatorAction
                        dir="right"
                        charactorType="normal"
                        actionType="anser"/>
                </div>
            </div>
        </div>

        <div className="links">
            <span className="left">詳しくは</span>
            <div className="al-c">
             <YellowArrowLink 
                    lang={lang}
                    href={`/consultation/international?lang=${lang}`}
                    linkKey="kokusai_soudan"
                ></YellowArrowLink>
            </div>
            <span className="right">をご覧ください。</span>
        </div>

        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
                <div className="charactor">
                    <ChallengerAction
                        dir="left"
                        charactorType="puzzled"
                        actionType="question"/>
                </div>
                <div className="fukidashi">
                    <FukidashiBlue dir="left">
                    <span>家族を連れて来たいのですが、日本語が話せないので、日本語を学べる場所を教えてくれませんか？</span>
                    </FukidashiBlue>
                </div>
            </div>

            <div className="anser-block">
                <div className="fukidashi">
                    <FukidashiBlueBottom dir="right">
                    <span>福岡県内には、行政やボランティアが開催している日本語教室が100カ所あります。<br/>
                    普段の生活で困っていることなども気軽に相談することができる教室もあります。</span>
                    </FukidashiBlueBottom>
                </div>
                <div className="charactor">
                    <NavigatorAction
                        dir="right"
                        charactorType="smile"
                        actionType="anser"/>
                </div>
            </div>
        </div>
        <div className="links">
            <span className="left">詳しくは</span>
            <div className="al-c">
             <YellowArrowLink 
                    lang={lang}
                    href={`/lifestyle/education?lang=${lang}`}
                    linkKey="kyoiku"
                ></YellowArrowLink>
            </div>
            <span className="right">をご覧ください。</span>
        </div>

        
        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
                <div className="charactor">
                    <ChallengerAction
                        dir="left"
                        charactorType="thinking"
                        actionType="question"/>
                </div>
                <div className="fukidashi">
                    <FukidashiBlue dir="left">
                    <span>外国語で対応してくれる医療機関を教えてください。</span>
                    </FukidashiBlue>
                </div>
            </div>

            <div className="anser-block">
                <div className="fukidashi">
                    <FukidashiBlueBottom dir="right">
                    <span>「ふくおか国際医療サポートセンター」では、外国語で対応できる医療機関や医療通訳サービスが受けられる医療機関の紹介、福岡県内の医療機関の案内、日本の医療制度の案内、医師との話を通訳が電話でサポートしてくれる医療機関を紹介してくれます。</span>
                    </FukidashiBlueBottom>
                </div>
                <div className="charactor">
                    <NavigatorAction
                        dir="right"
                        charactorType="good"
                        actionType="anser"/>
                </div>
            </div>
        </div>
        <div className="links">
            <span className="left">詳しくは</span>
            <div className="al-c">
             <YellowArrowLink 
                    lang={lang}
                    href={`/lifestyle/medical?lang=${lang}`}
                    linkKey="iryo"
                ></YellowArrowLink>
            </div>
            <span className="right">をご覧ください。</span>
        </div>
        
        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
                <div className="charactor">
                    <ChallengerAction
                        dir="left"
                        charactorType="thinking"
                        actionType="question"/>
                </div>
                <div className="fukidashi">
                    <FukidashiBlue dir="left">
                    <span>子どもの就学先としてインターナショナルスクールはありますか。</span>
                    </FukidashiBlue>
                </div>
            </div>

            <div className="anser-block">
                <div className="fukidashi">
                    <FukidashiBlueBottom dir="right">
                    <span>福岡県には、国際的な教育認定機関である「国際バカロレア機構」から認定を受け、幼児期から高等科まで一貫した英語による教育を行っているインターナショナルスクールがあります。</span>
                    </FukidashiBlueBottom>
                </div>
                <div className="charactor">
                    <NavigatorAction
                        dir="right"
                        charactorType="pointing"
                        actionType="anser"/>
                </div>
            </div>
        </div>

        <div className="links">
            <span className="left">詳しくは</span>
            <div className="al-c">
             <YellowArrowLink 
                    lang={lang}
                    href={`/lifestyle/education?lang=${lang}`}
                    linkKey="kyoiku"
                ></YellowArrowLink>
            </div>
            <span className="right">をご覧ください。</span>
        </div>

        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
                <div className="charactor">
                    <ChallengerAction
                        dir="left"
                        charactorType="thinking"
                        actionType="question"/>
                </div>
                <div className="fukidashi">
                    <FukidashiBlue dir="left">
                    <span>福岡で仕事を探しています。どこで紹介してくれますか。</span>
                    </FukidashiBlue>
                </div>
            </div>

            <div className="anser-block">
                <div className="fukidashi">
                    <FukidashiBlueBottom dir="right">
                    <span>外国人の仕事に関する相談・紹介は、ハローワーク福岡中央／福岡外国人雇用サービスセンターで行っています。</span>
                    </FukidashiBlueBottom>
                </div>
                <div className="charactor">
                    <NavigatorAction
                        dir="right"
                        charactorType="normal"
                        actionType="anser"/>
                </div>
            </div>
        </div>
        <div className="links">
            <span className="left">詳しくは</span>
            <div className="al-c">
             <YellowArrowLink 
                    lang={lang}
                    href={`/lifestyle/employment?lang=${lang}`}
                    linkKey="works"
                ></YellowArrowLink>
            </div>
           
            <span className="right">をご覧ください。</span>
        </div>

        <hr/>
        <div className="pc-flex col-2">
            <div className="question">
                <div className="charactor">
                    <ChallengerAction
                        dir="left"
                        charactorType="thinking"
                        actionType="question"/>
                </div>
                <div className="fukidashi">
                    <FukidashiBlue dir="left">
                    <span>福岡に移住したいと思っていますが、地域になじめるか不安です。</span>
                    </FukidashiBlue>
                </div>
            </div>

            <div className="anser-block">
                <div className="fukidashi">
                    <FukidashiBlueBottom dir="right">
                    <span>当サイトでは福岡県で活躍する外国人のサクセスストーリーを動画で発信しています。福岡には動画で紹介されている方々のように故郷を遠く離れ、地域に溶け込んで活躍する大勢の外国人がいます。</span>
                    </FukidashiBlueBottom>
                </div>
                <div className="charactor">
                    <NavigatorAction
                        dir="right"
                        charactorType="smile"
                        actionType="anser"/>
                </div>
            </div>
        </div>
        <div className="links">
            <span className="left">詳しくは</span>
            <div className="al-c">
             <YellowArrowLink 
                    lang={lang}
                    href={`/movie?lang=${lang}`}
                    linkKey="fortunehills"
                ></YellowArrowLink>
            </div>
            <span className="right">をご覧ください。</span>
        </div>
        </InnerBox>
    )
}

export default LangJa