import { NavigateCracter } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"

const LangEn = () => {

    return (<>
        <Article className="pc-noTopMargin">
            <h1>Ancient times - window for exchange with Asia－</h1>
            <p>
            In ancient times, Fukuoka was the seat of the Dazaifu government, known as the Imperial Court of Far Away, and of Korokan, the guest house for foreign envoys, and was the window of exchange between Japan and mainland China and the Korean Peninsula. Even in the Middle Ages, the port of Hakata flourished as a trading base with the Chinese mainland and the Korean peninsula, as well as with Ryukyu and the South Sea.
            </p>
            </Article>
            <div className="space">
            <div className="navigator right">
            <NavigateCracter type="smile"/>
            </div>
            </div>
            <Article>
            <h1>Edo Period -Commercial Hub</h1>
            <p>
            In the Edo period (1603-1867), the production of commodity crops such as Mokuro and crafts such as Hakata-ori, Kurume-gasuri, and Kokura-ori flourished. With the development of many highways and ports, as well as water transportation along the Onga and Chikugo Rivers, the prefecture occupied an important transportation hub in Kyushu.            </p>
            </Article>
            <div className="space">
            </div>
            <Article>
            <h1>Meiji - Showa - The Rise of the Coal and Steel Industries</h1>
            <p>
            From the Meiji Period to the Showa Period, coal production flourished in the Chikuho area and the Omuta district of Chikugo, and this was utilized to form the "Kitakyushu Industrial Zone" in the north, mainly for steel, machinery, electricity, chemicals, and ceramics, and the "coal chemical complex" in the south, supporting the modernization and economic development of Japan.<br/>
            After World War II, the country quickly recovered from the devastation, with manufacturing industries such as steel, metals, and chemicals, as well as the coal industry, flourishing due to the country's sloping production system and the special procurement boom resulting from the Korean War.
            </p>
            </Article>
            <div className="space">
            </div>
            <Article>
            <h1>High Growth Period - Transformation of Industrial Structure through Energy Revolution</h1>
            <p>
            During the period of rapid economic growth after 1955, industrial production in the prefecture grew significantly, but at the same time, the energy revolution that was underway caused the coal industry to decline, and coal-producing areas were severely impacted.<br/>
            After the two oil shocks in 1970s, our economy, which is heavily weighted toward material-based industries, faced a difficult situation amid low growth, a strong yen, and a shift in industrial structure. Therefore, we worked to nurture and cluster leading-edge growth industries and promoted the location of companies in the automobile industry, advanced semiconductors, biotechnology, robotics, and related industries. In addition, tertiary industries such as commerce and service industries grew significantly, especially in Fukuoka City.
            </p>
            </Article>
            <div className="space">
            <div className="navigator left">
            <NavigateCracter type="good"/>
            </div>
            </div>
            <Article>
            <h1>Today - Aiming to be a center of exchange in Kyushu, Western Japan, and Asia</h1>
            <p>
            From the middle of 1970s onward, the development of transportation infrastructure to support industry and daily life began in earnest. After the opening of the Kanmon Bridge, which at the time was noted as "the longest suspension bridge in the East" connecting Honshu and Kyushu, the transportation network continued to improve during the 1970s and 1980s with the opening of the Sanyo Shinkansen bullet train between Shin Osaka and Hakata, the Kyushu Longitudinal Expressway between Koga IC and Tosu IC, which runs north-south through Kyushu, the Fukuoka City subway between Muromi and Tenjin, and the Kitakyushu monorail. The Kitakyushu Monorail has also begun service.
            <br/>
            Furthermore, the New Kitakyushu Airport (now Kitakyushu Airport) opened in 2006, the Kyushu Shinkansen Line between Hakata and Kagoshima-Chuo in 2011, and the Higashi-Kyushu Expressway between Kitakyushu City and Miyazaki City in 2016 were fully opened to traffic.
            <br/>
            In 2005, the Kyushu National Museum was opened as the fourth national museum in Japan, and in 2015, facilities related to coal mining, the steel industry, and shipbuilding were inscribed on the United Nations Educational, Scientific and Cultural Organization (UNESCO) World Heritage List as "Industrial Revolutionary Sites of Meiji Japan: Iron and Steel Making, Shipbuilding and Coal Industry". In 2017, "Munakata Okinoshima Island and Related Properties" was registered as a World Cultural Heritage site.
            <br/>
            Amidst drastic changes in society, our prefecture has overcome various difficulties and developed in all aspects, including industry and culture, by improving transportation infrastructure, attracting new industries, and developing superior technologies, which are important for promoting exchanges with Asia and the rest of the world. We are making further strides as a leader prefecture in Kyushu that will support the future development of Japan.<br/>
            </p>
            </Article>
    </>)
}

export default LangEn