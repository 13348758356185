
import { NavigatorNice } from "@/components/Atoms/Character/navigator"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorFukidashiPicup } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"

const LangKo = () => {

    return (
        <>
        <h1>재해 발생 시 외국인 지원</h1>
        <p>
        해외에서 오신 여러분이 재해 발생 시에 적절히 행동하고 본현에서 안심하고 안전하게 살아가기 위해서는 평상시의 대비가 매우 중요합니다.<br/>
        본현에서는 다양한 형태로 정보를 제공하고 있습니다. 재해에 관해 배움으로써 자신의 생명을 지키고 가족과 주변 친구 등 소중한 사람의 생명을 지키게 되길 바랍니다.
        </p>
        <NavigatorFukidashiPicup dir="right">
        재해 발생 시에는 재해 정보와 대피 정보를 확실하게 얻는 것이 중요합니다. 다음 사이트나 앱을 활용하면 쉽게 정보를 수집할 수 있습니다.
        </NavigatorFukidashiPicup>
        <br/>
        <EmphasisNote>

        <h2>방재 앱 '후쿠오카 방재 내비게이션·마모루군' 다운로드</h2>
        <p>
        방재 앱 '후쿠오카 방재 내비게이션·마모루군'을 통해 재해 정보 등을 전송하고 있습니다.<br/>
        이용·등록은 무료이며 간단한 조작으로 방재 정보를 얻을 수 있으므로 꼭 등록하시기를 바랍니다.
        </p>
        <p>
        ●현재 위치의 방재 정보를 푸시 알림으로 전송합니다.<br/>
        ●대피소의 장소를 지도로 알기 쉽게 표시했습니다.<br/>
        ●재해 발생 시에 취해야 할 행동을 일러스트로 게재했습니다.<br/>
        ●재해 발생 시 가족의 위치정보를 확인할 수 있습니다.<br/>
        ●언어는 일본어, 영어, 중국어(간체자·번체자), 한국어, 베트남어를 지원합니다.
        </p>
        <figure>
        <img src={publicPath('images/articles/mamorukun01.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
        </figure>
        <figure>
        <img src={publicPath('images/articles/mamorukun02.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
        </figure>

        <div className="app_qr">
        <a href="https://apps.apple.com/jp/app/%E3%81%B5%E3%81%8F%E3%81%8A%E3%81%8B%E9%98%B2%E7%81%BD%E3%83%8A%E3%83%93-%E3%81%BE%E3%82%82%E3%82%8B%E3%81%8F%E3%82%93/id1659756418" target="blank">
        <figure>
        <img src={publicPath('images/articles/mamorukun_app01.png')} alt="App Store" className="fit-img"/>
        </figure>
        </a>
        <hr/>
        <a href="https://play.google.com/store/apps/details?id=jp.lg.fukuoka.pref.fukuoka_bosai&hl=ja&gl=US" target="blank">
        <figure>
        <img src={publicPath('images/articles/mamorukun_app02.png')} alt="Google Play" className="fit-img"/>
        </figure>
        </a>
        </div>
        <h2>후쿠오카현 방재 홈페이지</h2>
        <p>
        후쿠오카현에서는 '후쿠오카현 방재 홈페이지'를 개설하여 재해에 대한 대비부터 재해 정보 등 최신 정보를 제공하고 있습니다.
        </p>
        <p>
        （URL:<a href="https://www.bousai.pref.fukuoka.jp" target="_blank">
        https://www.bousai.pref.fukuoka.jp
        </a> ）
        </p>
        <h2>방재 핸드북</h2>
        <p>
        9개 언어로 '외국인을 위한 방재 핸드북'을 작성하였으니, 사전에 확인하여 재해에 대비하시기를 바랍니다.
        </p>
        <p>
        （URL:<a href="https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html" target="_blank">https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html</a>）
        </p>
        <p>
        게재 내용은<br/>
        ●발생할 우려가 있는 재해 내용<br/>
        ●재해 발생 시 취해야 할 행동<br/>
        ●재해에 대한 평상시의 대비<br/>
        ●대피장소<br/>
        ●긴급 연락처 등<br/>
        ●언어는 쉬운 일본어, 영어, 중국어, 한국어, 타갈로그어, 베트남어, 네팔어, 인도네시아어, 미얀마어를 지원합니다.
        </p>
        <p>
        <a href="https://www.pref.fukuoka.lg.jp/uploaded/life/671824_61619901_misc.pdf" target="_blnak">
        <figure>
        <img src={publicPath('images/articles/disasterprevantionhandbook.png')} alt="disaster prevantion handbook" className="fit-img"/>
        </figure>
        </a>
        </p>
        </EmphasisNote>
        <br/>
        <NavigatorNice dir="right"></NavigatorNice>
    </>
    )
}

export default LangKo