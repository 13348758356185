

import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"

const SAmine = styled.div`
    @keyframes fadeOut {
        from { opacity: 1; }
        to { opacity: 0; }
    }
    @keyframes slide-off {
        0% {
            clip-path: polygon(0 0, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
        }
        30% {
            top:0;
            left: 0;
        }
        50% {
            clip-path: polygon(50% 50%, 100% 0, 100% 0, 100% 100%, 0 100%, 0 100%);
            
        }
        100% {
            clip-path: polygon(100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%);
            top: calc(100vh - 100px); /* 画面の高さからボックスの高さを引いた値 */
            left: calc(100vw - 100px); /* 画面の幅からボックスの幅を引いた値 */
        }
        
    }
`

const SBlackScreen = styled(SAmine)`
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #231815;
    opacity: 0.6;
    animation: slide-off 1s forwards; /* 3秒間でアニメーションを実行 */
    top: 0;
    z-index: 1000;

`

const SOpeningScreen = styled(SAmine)`
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    /*animation: slide-off 1.2s forwards; /* 3秒間でアニメーションを実行 */
    top: 0;
    z-index: 9999;

    box-sizing: border-box;
    top: 0;
    left: 0;

    .logMsg{
        position: absolute;
        width: 90%;
        max-width: 500px;
        text-align: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -55%);
        figure.message{
             animation: fadeOut 1.2s ease forwards;
             animation-delay: 0.2s;
             margin: 0;
        }
        figure.logo{
            animation: fadeOut 1s ease forwards;
            animation-delay: 1.2s;
            width: 55%;
            margin:0 auto;
            
        }

    }

    animation: slide-off 1.2s forwards; /* 3秒間でアニメーションを実行 */
    animation-delay: 2s;
`


const PageTransMotion = ({children})  => {

    const [visitCount, setVisitCount] = useState<number>(0);

    useEffect(() => {
        // セッションストレージから訪問回数を取得
        const currentCount = parseInt(sessionStorage.getItem('visitCount') || '0', 10);
        
        // 訪問回数を更新
        const newCount = currentCount + 1;
        sessionStorage.setItem('visitCount', newCount.toString());

        // 状態を更新
        setVisitCount(newCount);
    }, []);


    return (  
        <>
            {
                visitCount === 1 &&
                <SOpeningScreen>
                    <div className="logMsg">
                        <figure className="message">
                            <img src={publicPath('images/op_message.png')} alt="Live Comfortably with Smiles" className="img-fit"/>
                        </figure>
                        <figure className="logo">
                            <img src={publicPath('images/op_logo.png')} alt="fukuoka is open logo" className="img-fit"/>
                        </figure>
                    </div>
                </SOpeningScreen>

            }
            {
                visitCount > 1 &&
                <SBlackScreen></SBlackScreen>
            }
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 2 }}
                exit={{ opacity: 0 }}
            >
             {children}
            </motion.div>
            </>
    )
}

export default PageTransMotion
