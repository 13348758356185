import styled from "styled-components"
import { CmsIndex } from "@/components/Organisms/Cms"

import FioTemplate from "@/components/Templates/fio"
import SubLayerTemplate from "@/components/Templates/subLayer"
import PageBack from "@/components/Molecules/PageBack"
import PageTitleMovie from "@/components/Molecules/PageTitle/movie"
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio"

const SMovie = styled.div`
    .article-search-positon{
        margin: 20px 20px 0;
    }
`

const MoviePage = () => {
    const keywords = [
        '総合政策課',
        '海外事務所',
        '企業立地課',
        '中小企業振興課',
        '新規支援課',
        '輸出促進課',
        'サンプル',
        'サンプル',
        'サンプル',
        'サンプル',
    ]

    return (
        <ResponsiveFioTemplate>
            <SubLayerTemplate>
                <CmsIndex></CmsIndex>
            </SubLayerTemplate>
        </ResponsiveFioTemplate>
    )
}

export default MoviePage