import { useLocation, useParams } from "react-router-dom"
import getLayer from "src/utilty/getLayer";
import SubLayerTemplate from "@/components/Templates/subLayer";
import PageBack from "@/components/Molecules/PageBack";
import PageTitleAbout from "@/components/Molecules/PageTitle/about";
import AboutPrivacyPage from "./privacy";
import AboutSitePolicyPage from "./sitepolicy";
import AboutFioPage from "./fio";
import AboutAccessibilityPage from "./accessibility";
import AboutLinkForSitePage from "./linkforsite";
import { useEffect, useState } from "react";
import { pathAnalyzer } from "src/utilty/pathAnalyzer";
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio";


const AboutPage = () => {

    const { category } = useParams();

    const layer = getLayer('about')
    let layerData
    category && category !== 'index' ? layerData = layer.sublayer[category] :  layerData = layer

    const [ segment, setSegment  ] = useState<string>()
    const location = useLocation()
    const path = pathAnalyzer(location)

    useEffect(()=>{
       
        if(!category || category == 'index'){
            setSegment('index')
        }else{
            setSegment(category)
        }
    },[category])

    return (
        <ResponsiveFioTemplate>
            <SubLayerTemplate>
            <PageTitleAbout lang="ja">
                {layerData[path.lang].title}
            </PageTitleAbout>
            {
                segment === 'index' &&
                <></>
            }
            {
                segment=== 'fio' &&
                <AboutFioPage lang={path.lang}></AboutFioPage>
            }
            {
                segment === 'sitepolicy' &&
                <AboutSitePolicyPage lang={path.lang}></AboutSitePolicyPage>
            }
            {
                segment === 'privacy' &&
                <AboutPrivacyPage lang={path.lang}></AboutPrivacyPage>
            }
            {
                segment === 'accessibility' &&
                <AboutAccessibilityPage lang={path.lang}></AboutAccessibilityPage>
            }
            {
                segment === 'linkforsite' &&
                <AboutLinkForSitePage lang={path.lang}></AboutLinkForSitePage>
            }
           
            <PageBack backto="/?current=9" ></PageBack>
            </SubLayerTemplate>
        </ResponsiveFioTemplate>
    )

}

export default AboutPage