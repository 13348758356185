import { Link } from "react-router-dom"
import publicPath from "src/utilty/publicPath"
import styled,{css} from "styled-components"

type menuNameType =  
| 'home'
| 'about' 
| 'basicInfo' 
| 'consultation' 
| 'gwm' 
| 'initiatives' 
| 'support' 
| 'international' 
| 'lifestyle' 
| 'movie' 
| 'news' 
| 'special' 
| 'virtual'

interface MenuLinksFristProps {
    menuName : menuNameType,
    onClick :  (e) => void
}

const AkerStyle = css`
    cursor: pointer;
    width: 220px;
    max-width: 100%;
    display: block;
    img{
        width: 100%;
        transition: transform 0.3s ease; /* アニメーションの効果を指定 */
    }
    &:hover{
        img{
            transform: translateX(10px); /* 右に10px動かす */
        }
    }
`

const SAnker = styled.a`
   ${AkerStyle}
`

const SLink = styled(Link)`
    ${AkerStyle}
`

export const MenuLinkFrist = ({
    menuName, 
    onClick}:MenuLinksFristProps) => 
{
   
    return (
        <SAnker onClick={onClick}>
            <img src={publicPath(`images/nav_${menuName}.png`)} className="img-fit"/>
        </SAnker>
    )
}

export const MenuLinkTo = ({
    menuName,
    href,
    ...props 
}:{menuName :menuNameType, href:string}) => {

    return (
        <SLink to={href}>
            <img src={publicPath(`images/nav_${menuName}.png`)} className="img-fit"/>
        </SLink>
    )
}