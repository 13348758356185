
const LangTh = () => {

    return (<>
    <h1>เกี่ยวกับ FUKUOKA IS OPEN</h1>
    <p>
    จังหวัดฟุกุโอกะเป็นจังหวัดที่มีจำนวนของเมืองที่ลงนามความสัมพันธ์ฉันมิตรและจำนวนของนักศึกษาต่างชาติที่สูงเป็นลำดับต้นๆ ของประเทศ อีกทั้งยังมีจำนวนของชาวต่างชาติที่อาศัยอยู่ในจังหวัดสูงสุดในประวัติการณ์ถึง 89,518 คน
    </p>
    <p>
    ในภาวะปัญหาสังคมที่จำนวนประชากรลดน้อยลง บุคลากรชาวต่างชาติที่ทำงานในบริษัทในจังหวัดหรือคนรุ่นใหม่ที่เป็นผู้มีบทบาทดูแลสังคมในท้องถิ่นเป็นสิ่งที่ขาดไม่ได้ในการที่จะรักษาศักยภาพทางเศรษฐกิจของท้องถิ่นในอนาคตต่อจากไปนี้
    </p>
    <p>
    เพื่อให้บุคลากรจากต่างประเทศเหล่านั้นย้ายมาอยู่จังหวัดฟุกุโอกะให้มากขึ้น ทางจังหวัดจึงทำการเผยแพร่ข้อมูลเกี่ยวกับนโยบายของจังหวัดให้ทั่วโลกได้รู้ ให้เป็นที่ประจักษ์ว่าจังหวัดฟุกุโอกะเป็นเมืองที่น่าอยู่ ชาวต่างชาติสามารถดำรงชีวิตได้ง่ายและทำงานได้อย่างเต็มศักยภาพ ภายใต้คำขวัญที่ว่า 「FUKUOKA IS OPEN」
    </p>
    <p>
    ดังนั้นทางจังหวัดจึงได้จัดทำเว็บไซต์「FUKUOKA IS OPEN」（<a href="https://www.fisop.net">https://www.fisop.net</a>）เป็นภาษาต่างประเทศขึ้นมา เพื่อใช้เป็นช่องทางการให้ข้อมูลใหม่ล่าสุดในด้านต่างๆ เช่น ข้อมูลเกี่ยวกับการศึกษา การแพทย์และอื่นๆ ที่เกี่ยวกับการดำรงชีวิตสำหรับชาวต่างชาติ ข้อมูลเกี่ยวกับการเชิญชวนนักศึกษาต่างชาติ นักท่องเที่ยวต่างชาติและสถาบันการเงินระหว่างประเทศ ข้อมูลเกี่ยวกับกีฬา สิ่งแวดล้อม และหน่วยงานที่ทันสมัยอย่าง One Health รวมทั้งบอกเล่าเรื่องราวความสำเร็จของชาวต่างชาติที่อาศัยอยู่ที่จังหวัดฟุกุโอกะ
    </p>
    <br/>
    <h5 className="title-large">เกี่ยวกับการใช้เครื่องหมายโลโก้ต้นฉบับของ FUKUOKA IS OPEN</h5>
    <p>ห้ามมิให้ใช้โลโก้นี้หากไม่ได้รับความยินยอมจากคณะกรรมการบริหารส่งเสริมโครงการ FUKUOKA IS OPEN<br/>
    หากคุณต้องการใช้งานเครื่องหมายโลโก้ จำเป็นต้องยื่นคำร้องขอใช้งานตามหลักเกณฑ์การใช้งาน<br/>
    โปรดตรวจสอบหลักเกณฑ์การใช้งานและคำแนะนำของการนำไปใช้และกรอกรายการที่จำเป็นลงใน "แบบฟอร์มขอใช้งานเครื่องหมายโลโก้โปรโมทโครงการ FUKUOKA IS OPEN (แบบฟอร์มหมายเลข 1) " และส่งมาทาง FAX</p>
    <br/>
    <p>หลังจากตรวจสอบเนื้อหาในแบบฟอร์มขอใช้งานแล้ว เราจะส่งเอกสารแสดงความยินยอมและดาต้าข้อมูลเครื่องหมายโลโก้ไปให้</p>
    <br/>
    <p>
    【แบบฟอร์มและอื่นๆ】
    </p>
    <ul className="dotto">
    <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOPromotionProjectLogoUsageInstructions.pdf" target="_blank">หลักเกณฑ์การใช้งานเครื่องหมายโลโก้โปรโมทโครงการ FUKUOKA IS OPEN</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form1_FIOPromotionProjectLogoApplicationForm.docx">แบบฟอร์มขอใช้งานเครื่องหมายโลโก้โปรโมทโครงการ FUKUOKA IS OPEN (แบบฟอร์มหมายเลข 1)</a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form4_FIOPromotionProjectLogoApplicationForm.docx">แบบฟอร์มขอเปลี่ยนแปลงการอนุมัติการใช้งานเครื่องหมายโลโก้โปรโมทโครงการ FUKUOKA IS OPEN (แบบฟอร์มหมายเลข 4)  </a></span></li>
    <li><span className="icon-doc"><a href="/contents/aboutfio/Form5_FIOPromotionProjectLogoUsageStatusReport.docx">แบบฟอร์มรายงานสถานะการใช้งานเครื่องหมายโลโก้โปรโมทโครงการ FUKUOKA IS OPEN (แบบฟอร์มหมายเลข 5) </a></span></li>
    <li><span className="icon-pdf"><a href="/contents/aboutfio/FIOLOGOUSERGUIDELINE.pdf" target="_blank">[FUKUOKA IS OPEN] LOGO USER GUIDELINE</a></span></li>
    </ul>
    <br/>
    <p>
    【ติดต่อสอบถาม】<br/>
    สำนักงานคณะกรรมการบริหารส่งเสริมโครงการ FUKUOKA IS OPEN (แผนกสนับสนุนช่วยเหลือบุคลากรชาวต่างชาติฝ่ายนโยบายระหว่างประเทศสำนักงานระหว่างประเทศจังหวัดฟุกุโอกะ)
    </p>
    <br/>
    <p>
    TEL：<a href="tel:+81-92-643-3201">+81-92-643-3201</a><br/>
    FAX：<a href="tel:+81-92-643-3224">+81-92-643-3224</a><br/>
    8:30 น. ถึง 17:15 น. (9:00 น. - 17:45 น.)<br/>
    ยกเว้น, วันหยุดสุดสัปดาห์และวันที่ 29 ธันวาคมถึงวันที่ 3 มกราคม
    </p>
    </>)
}

export default LangTh