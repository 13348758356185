import InnerBox from "@/components/Atoms/Box/innerBox"

const LangVi = () => {

    return (
        <InnerBox bottom={20} left={0} right={0}>
        <h1>
        Về các lớp tiếng Nhật trong khu vực<br/>
        Tại tỉnh Fukuoka có 100 lớp học tiếng Nhật do chính quyền và các tình nguyện viên tổ chức.
        </h1>
        <p>
        Các lớp học tiếng Nhật không chỉ là nơi để học ngôn ngữ, mà đó còn là nơi giúp người nước ngoài và người Nhật sống trong khu vực đó giao lưu với nhau.Tại 1 số lớp tiếng Nhật người nước ngoài còn có thể trao đổi dễ dàng về các vấn đề khó khăn trong cuộc sống thường ngày nên vui lòng xem bản đồ bên dưới để biết thêm thông tin chi tiết.<br/>
        Ngoài ra, tỉnh Fukuoka hiện nay cũng đang thực hiện Dự án hoàn thiện môi trường dạy tiếng Nhật nhằm tạo môi trường học tiếng Nhật gần nhà cho người nước ngoài thông qua việc hỗ trợ thành lập các lớp tiếng Nhật do chính quyền tổ chức, tổ chức các lớp tập huấn cho tình nguyện viên...
        </p>
        </InnerBox>
    )
}

export default LangVi