import { NavigatorFullLength } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangZhCn = () => {

    return (<>
     <div className="navigatorPositon sp-only">
        <NavigatorFullLength></NavigatorFullLength>
    </div>
    <div className="pc-flex col-2">
        <div>
            <h1>
            县徽
            </h1>
            <figure>
            <img src={publicPath('images/symbol01.png')} alt="県のシンボル" className="img-fit"/>
            </figure>
            <p>
            以县花“梅”为造型，表现了县的发展以及县民的融合。<br/>
            于昭和41年5月10日，在公开募集中由县进行了最终决定。
            </p>
        </div>
        <div>
            <h1>
            福冈县县花 梅
            </h1>
            <figure>
            <img src={publicPath('images/symbol02.png')} className="img-fit"/>
            </figure>
            <p>
            于昭 和２９年３月１９日的“NHK植物之友会议”上, 以大宰府的梅花为缘由县花被决定了下来。作为学问之神在太宰府天满宫被供奉的菅原道真公一生中最为喜欢的，便是梅花。
            </p>
        </div>
        <div>   
            <h1>
            福冈县的鸟 黄莺
            </h1>
            <figure>
            <img src={publicPath('images/symbol03.png')} className="img-fit"/>
            </figure>
            <p>
            昭和39年7月3日，在公开募集中由县最终决定。 在日本有着“梅配黄莺”这一种说法。 其旨在表达两种事物搭配起来映衬和谐，且有一种完美调和美。
            </p>
        </div>
        <div>
            <h1>
            福冈县的县树 杜鹃
            </h1>
            <figure>
            <img src={publicPath('images/symbol04.png')} className="img-fit"/>
            </figure>
            <p>
            于昭和41年9月5日的“绿色的日本全国总动员”中被选定。 杜鹃，作为植木，盆栽在久留米地区被广泛栽培，并且其树苗也被出口至海外各地。
            </p>
        </div>
    </div>
    </>)
}

export default LangZhCn