import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { TrapezoidExcl } from "@/components/Molecules/CharactorHeadline/challenger"
import Article from "@/components/Organisms/Article"

const LangZhCn = () => {

    return (<>
    <Article offset={0} className="pc-noTopMargin">
        <div className="pc-flex col-2">
            <div className="pc-only">
                <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
            </div>
            <BlackNote>
            <ul className="deco">
            <li>
            <p className="large">
            福冈县的人口<br/>
            514万人
            </p>
            <p className="al-c">（2020年・全国第9位）</p>
            <span className="txt-small">
            资料 县调查统计科 「令和4年 福冈县的人口和世代年报」
            </span>
            </li>
            </ul>
            </BlackNote>
        </div>
        </Article>
        <TrapezoidExcl dir="left">
        福冈县的面积<br/>
        </TrapezoidExcl>
        <Article offset={0} className="pc-noTopMargin">
        <div className="pc-flex col-2">
            <div className="pc-only">
                <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
            </div>
            <BlackNote>
            <ul className="deco">
            <li>
            <p className="large">
            福冈县的面积 <br/>
            4,987㎢
            </p>
            <p className="al-c">（2020年・全国第29位）</p>
            <span className="txt-small">
            资料 国土交通省国土地理院<br/>
            (令和4年全国各都道府县市区町村面积调查)
            </span>
            </li>
            </ul>
            </BlackNote>
        </div>
        <div className="navi-position sp-only">
            <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
        </Article>

        <TrapezoidExcl dir="left">
        福冈县的气候<br/>
        </TrapezoidExcl>
        <Article offset={0} className="pc-noTopMargin">
        <BlackNote>
        <ul className="deco pc-flex col-2">
        <li>
        <p className="large">
        年平均气温<br/>
        17.3℃
        </p>
        </li>
        <li>
        <p className="large">
        年平均湿度<br/>
        68％
        </p>
        </li>
        <li>
        <p className="large">
        全年日照时间<br/>
        1,889.4小时
        </p>
        </li>
        <li>
        <p className="large">
        全年降水量<br/>
        1,686.9mm
        </p>
        <p> 1991～2020年的30年间的平均值 </p>
        <p className="txt-small">
        资料 福冈区域气象局
        </p>
        </li>
        </ul>
        
        </BlackNote>
            <div className="sp-only">
                <NavigatorCheck dir="right"></NavigatorCheck>
            </div>
        </Article>
        <InnerBox top={40} bottom={40}>
        </InnerBox>
    </>)
}

export default LangZhCn