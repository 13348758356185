
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote";


const LangZhCn = () => {

    return (
        <>
            <p className="discpriton">
            “猪骨拉面”是福冈县的名特产之一<br/>
            在福冈县各处的拉面店都能品尝到别具一格的拉面。<br/>
            “豚骨拉面 BEATS”是一款以 "豚骨拉面 "为主题的游戏。<br/>
            抓住面碗的节奏，获得高分！
            </p>
            <EmphasisNote className="customNote">
                <h4><img src="./images/ramenbeats/howtoplay.webp" alt="how to play"/></h4>
                <section>
                    <h5 className="first">
                    点击屏幕下方的 "开始 "按钮。<br/>（将转入游戏界面。）
                    </h5>
                </section>
                <section>
                    <h5 className="second">
                        点击<br/>
                        <img src="./images/ramenbeats/start.webp" alt="start" width={230}/>
                    </h5>
                </section>
                <section>
                    <h5 className="third">
                    点击喜欢的舞台，开始游戏！
                    </h5>
                    <ul className="caption">
                        <li>
                        请使用最新的操作系统和浏览器进行游戏！
                        </li>
                        <li>
                        玩本游戏要使用智能手机。
                        </li>
                    </ul>
                </section>
            </EmphasisNote>
            <h4 className="whattonkotsu"><img src="./images/ramenbeats/what_tonkotsu.webp" alt="What’s TONKOTSU RAMEN？"/></h4>
            <p>
            Tonkotsu "意为 "猪骨"。<br/>
            猪骨拉面的汤是由猪骨长时间熬煮而成的。<br/>
            不同地区、不同店铺不尽相同，但其特点是汤色白浊。<br/>
            以细直的面条为主。<br/>
            许多面馆都允许客人根据自己的喜好来选择“超硬的面”、“偏硬的面”等不同硬度的面条。<br/>
            另外，许多面馆还为客人免费增加面条。这也是一大特色。
            </p>
            <ul className="phots"> 
                <li>
                    <img  src="./images/ramenbeats/ramen01.webp" alt="豚骨ラーメン"/>
                </li>
                <li>
                    <img  src="./images/ramenbeats/ramen02.webp" alt="豚骨ラーメン"/>
                </li>
            </ul>
            
        
        </>
    )
}

export default LangZhCn