
const LangVi = () => {


    return (
        <>
            <h2>
                <img src="./images/virtual/fio_park.webp" alt="FUKUOKA IS OPEN PARK"/>
            </h2>
           
            <div className="virtual-inner">
                <p className="message al-l">
                Hãy cùng trải nghiệm du lịch thực tế ảo về cảnh quan, ẩm thực, xe cộ, v.v. của tỉnh Fukuoka với "FUKUOKA IS OPEN PARK", vũ trụ ảo liên kết với thành phố, được tạo ra dựa trên không gian thực tế của Fukuoka.
                </p>
                <div className="fiopark">
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_01_title_en.webp" alt="FUKUOKA IS OPENパークの入口"/></dt>
                        <dd>
                        <p>Hãy cùng bạn bè tụ tập, trò chuyện thoải mái và xem video tại Công viên trung tâm Tenjin xanh tươi ở khu vực Tenjin, một khu trung tâm của tỉnh Fukuoka và Kyushu. Từ đây, bạn có thể đi chơi tới nhiều thế giới khác nhau</p>
                            <figure>
                                <img src="./images/virtual/fio_park_01_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_02_title_en.webp" alt="FUKUOKA フルーツパーク"/></dt>
                        <dd>
                            <p>Hãy tham gia trải nghiệm thu hoạch trái cây và rau quả theo mùa được trồng tại tỉnh Fukuoka!</p>
                            <figure>
                                <img src="./images/virtual/fio_park_02_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    
                </div>
                <div className="fiopark-howto">
                    <div className="fiopark-howto-title vi">
                    <h3>Cách sử dụng <span>FUKUOKA IS OPEN PARK</span></h3>
                    </div>
                    <div className="fiopark-howto-entrance">
                        <div><img src="./images/virtual/entrance_en.webp" alt="FUKUOKA IS OPEN 参加する"/></div>
                        <div><p>Nhấp vào "Join" là có thể tham gia</p></div>
                    </div>
                    <div className="fiopark-howto-app">
                        <div>
                            <p>Bạn cần tải trước ứng dụng “cluster” về điện thoại thông minh, máy tính bảng, máy tính cá nhân, thiết bị thực tế ảo VR, v.v.</p>
                            
                            <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend pc" target="_blank">
                            <span>  Vào đây để biết môi trường được đề xuất </span>
                            </a>
                        </div>
                        <div>
                            <h4>Nếu bạn đã có ứng dụng rồi, hãy nhấp vào</h4>
                            <img src="./images/virtual/cluster01.webp" alt="clusterを起動" width={200}/>
                            <p>"Khởi động cluster", khi</p>
                            <img src="./images/virtual/cluster02.webp" alt="遊びに行く"/>
                            <p>“Đi chơi” được hiển thị thì nhấp
                            vào nó để vào phòng</p> 
                        </div>
                        <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend sp" target="_blank">
                           <span> Vào đây để biết môi trường được đề xuất</span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

}

export default LangVi