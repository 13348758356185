import { NavigateCracter } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"

const LangJa = () => {

    return (
        <>
        <Article className="pc-noTopMargin">
            <h1>古代－アジアとの交流窓口－</h1>
            <p>
                福岡は、古代、遠(とお)の朝廷(みかど)と呼ばれた大宰府政庁や外国使節の迎賓館である鴻臚館が置かれ、中国大陸や朝鮮半島と我が国の交流の窓口でした。中世に入っても、博多の港は中国大陸や朝鮮半島をはじめ、琉球や南海との貿易基地として栄えました｡
            </p>
        </Article>
        <div className="space">
            <div className="navigator right">
            <NavigateCracter type="smile"/>
            </div>
        </div>
        <Article>
            <h1>江戸時代－商業の拠点－</h1>
            <p>
            江戸時代に入ると、木ろうなどの商品作物や博多織、久留米絣、小倉織などの工芸品の生産が盛んになりました。多くの街道や港、遠賀川、筑後川の水運が発達し、本県は九州の交通の要衝を占めていました｡
            </p>
        </Article>
        <div className="space">
        </div>
        <Article>
            <h1>明治～昭和－石炭、鉄鋼産業の興隆－</h1>
            <p>
            明治から昭和にかけて、筑豊一帯や筑後の大牟田地区で石炭の産出が盛んになり、これを活用して北部に鉄鋼、機械、電気、化学、窯業などを中心とする｢北九州工業地帯｣が、南部には「石炭化学コンビナート」が形成され、日本の近代化と経済発展を支えました｡<br/>
            第２次世界大戦後は、国の傾斜生産方式と朝鮮戦争による特需景気などにより、鉄鋼、金属、化学などの製造業や石炭産業が隆盛となり、いち早く荒廃から立ち直りました｡
            </p>
        </Article>
        <div className="space">
        </div>
        <Article>
            <h1>高度成長期－エネルギー革命による<br/>産業構造の転換－</h1>
            <p>
            昭和 30 年以降の高度経済成長期には、本県の工業生産も大きく伸びましたが、同時に進行したエネルギー革命により石炭産業が衰退し、産炭地域は深刻な打撃を受けました｡<br/>
            40 年代後半及び 50 年代前半の２度のオイルショック以降は、低成長や円高、産業構造の転換の中で、素材型産業の比重の大きい本県経済は厳しい状況になりました。このため、先端成長産業の育成、集積に取り組み、自動車産業、先端半導体、バイオテクノロジー、ロボット関連などの企業立地を進めました。また、福岡市を中心に、商業やサービス業などの第３次産業が大きく成長しました。
            </p>
        </Article>
        <div className="space">
            <div className="navigator left">
                <NavigateCracter type="good"/>
            </div>
        </div>
        <Article>
            <h1>現在－九州、西日本、アジアにおける<br/>交流拠点をめざして－</h1>
            <p>
            40年代後半以降は、産業や生活を支える交通インフラの整備が本格化します。本州と九州を結ぶ「東洋最長のつり橋」と当時注目された関門橋の開通後、50年代から 60年代にかけて、山陽新幹線新大阪～博多間の全線開業、九州を南北に走る九州縦貫自動車道古賀 IC～鳥栖 IC 間の開通、福岡市地下鉄室見～天神間の開業、北九州モノレールの開業など、交通網の整備が進みました。<br/>
            さらに、平成18年には新北九州空港（現北九州空港）が開港し、23年には九州新幹線博多～鹿児島中央間が、28年には東九州自動車道北九州市～宮崎市間が全線開通しました。<br/>
            平成17年に国内４番目の国立博物館として九州国立博物館が開館しました。27年には炭鉱や鉄鋼業、造船業などの関連施設が「明治日本の産業革命遺産 製鉄・製鋼、造船、石炭産業」として国連教育科学文化機関（ユネスコ）世界文化遺産に登録されました。29年には「『神宿る島』宗像・沖ノ島と関連遺産群」が世界文化遺産に登録されました。<br/>
            社会が大きく変動する中、本県は、アジアをはじめ、世界との交流を促進するために重要な交通基盤の整備や新たな産業の誘致、優れた技術の開発などによりさまざまな困難を乗り越え、産業や文化などのあらゆる面で発展してきました。これからの日本の発展を支えていく九州のリーダー県として､一層の飛躍を図っています。<br/>
            </p>
        </Article>
        </>
    )
}

export default LangJa