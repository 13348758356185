
const LangKo = () => {


    return (
        <>
            <h2>
                <img src="./images/virtual/fio_park.webp" alt="FUKUOKA IS OPEN PARK"/>
            </h2>
           
            <div className="virtual-inner">
                <p className="message al-l">
                후쿠오카에 있는 실제 공간을 바탕으로 만든 도시 연동형 메타버스 'FUKUOKA IS OPEN PARK'에서 후쿠오카현의 거리 풍경·음식·탈 것 등을 유사 체험해 보자.                </p>
                <div className="fiopark">
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_01_title_en.webp" alt="FUKUOKA IS OPENパークの入口"/></dt>
                        <dd>
                            <p>후쿠오카현·규슈의 중심 '텐진 지구'에 있는 수풀이 울창한 텐진 중앙 공원에서 친구들과 모여서 느긋하게 수다를 떨거나 동영상을 시청해 보자. 이곳에서 다양한 곳을 여행할 수 있습니다</p>
                            <figure>
                                <img src="./images/virtual/fio_park_01_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_02_title_en.webp" alt="FUKUOKA フルーツパーク"/></dt>
                        <dd>
                            <p>후쿠오카현산 제철 과일과 야채를 수확해 보자!</p>
                            <figure>
                                <img src="./images/virtual/fio_park_02_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    
                </div>
                <div className="fiopark-howto">
                    <div className="fiopark-howto-title">
                    <h3>FUKUOKA IS OPEN PARK<span> 이용 방법</span></h3>
                    </div>
                    <div className="fiopark-howto-entrance">
                        <div><img src="./images/virtual/entrance_en.webp" alt="FUKUOKA IS OPEN 参加する"/></div>
                        <div><p>'Join'을 클릭하여 참여할 수 있습니다</p></div>
                    </div>
                    <div className="fiopark-howto-app">
                        <div>
                            <p>T사전에 스마트폰, 태블릿, PC, VR 전용 기기 등의 앱에서 'cluster'를 다운로드해야 합니다</p>
                            
                            <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend pc" target="_blank">
                               <span> 권장 환경은 이쪽</span>
                            </a>
                        </div>
                        <div>
                            <h4>이미 앱을 가지고 계신 분은</h4>
                            <img src="./images/virtual/cluster01.webp" alt="clusterを起動" width={200}/>
                            <p>'cluster 부팅'을 클릭한 후 표시되는</p>
                            <img src="./images/virtual/cluster02.webp" alt="遊びに行く"/>
                            <p> '놀러 가기'를 클릭하여 입실합니다</p> 
                        </div>
                        <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend sp" target="_blank">
                            <span>권장 환경은 이쪽</span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

}

export default LangKo