import { NavigateCracter } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"

const LangTh = () => {

    return (<>
       <Article className="pc-noTopMargin">
        <h1>ยุคโบราณ - จุดแลกเปลี่ยนกับเอเชีย－</h1>
        <p>
        ในยุคโบราณ ฟูคูโอคะเป็นที่ตั้งของสำนักงานรัฐบาลดาซาอิฟุซึ่งถูกเรียกว่าเป็นราชสำนักอันห่างไกล และเป็นที่ตั้งของโคโรคังซึ่งเป็นอาคารรับรองแขกบ้านเมืองสำหรับทูตของต่างประเทศ จึงเป็นจุดแลกเปลี่ยนระหว่างจีนแผ่นดินใหญ่ คาบสมุทรเกาหลี และประเทศของเรา (ประเทศญี่ปุ่น) แม้แต่เมื่อเข้ายุคกลาง ท่าเรือที่ฮาคาตะก็เจริญรุ่งเรืองในฐานที่เป็นแหล่งค้าขาย ทั้งกับจีนแผ่นดินใหญ่ คาบสมุทรเกาหลี ริวคิว และทะเลทางใต้
        </p>
        </Article>
        <div className="space">
        <div className="navigator right">
        <NavigateCracter type="smile"/>
        </div>
        </div>
        <Article>
        <h1>ยุคเอโดะ - ฐานที่ตั้งของการค้าขาย</h1>
        <p>
        พอเข้าสู่ยุคเอโดะแล้ว ได้มีการผลิตสินค้าเชิงพาณิชย์ เช่น ขี้ผึ้ง เป็นต้น และงานฝีมือ เช่น ผ้าทักทอแบบฮาคาตะ (ฮาคาตะโอริ) ผ้าทอคูรูเมะคาซูนิ ผ้าทักทอแบบโคคุระ กันอย่างกว้างชวาง เมืองและถนนจำนวนมาก ท่าเรือ การขนส่งทางแม่น้ำองกะและแม่น้ำชิคุโกะได้รับการพัฒนาเป็นอย่างมาก ทำให้จังหวัดนี้เป็นมีจุดสำคัญของการคมนาคมของคิวชูอยู่จำนวนมาก
        </p>
        </Article>
        <div className="space">
        </div>
        <Article>
        <h1>ยุคเมจิถึงยุคโชวะ - ความเจริญรุ่งเรืองของอุตสาหกรรมถ่านหินและเหล็กกล้า - </h1>
        <p>
        ตั้งแต่ยุคเมจิจนถึงยุคโชวะ การผลิตหินถ่านในพื้นที่ชิคุโฮะทั้งพื้นที่หรือพื้นที่โอมุตะในชิคุโกะเจริญรุ่งเรือง และได้ใช้ประโยชน์จากสิ่งเหล่านั้นในการสร้าง "เขตอุตสาหกรรมคิวชูทางเหนือ (คิตะคิวชู)" ซึ่งมีเหล็กกล้า เครื่องจักร ไฟฟ้า เคมีภัณฑ์ และเซรามิกเป็นศูนย์กลางในบริเวณตอนเหนือ และ "ศูนย์อุตสาหกรรมถ่านหินและเคมีภัณฑ์" ในบริเวณตอนใต้ ซึ่งพื้นที่เหล่านั้นได้สนับสนุนการเข้าสูยุคใหม่และการเติบโตของเศรษฐกิจของญี่ปุ่น<br/>
        หลังสงครามโลกครั้งที่ 2 อุตสาหรรมด้านการผลิตอย่างเหล็กกล้า โลหะ หรือเคมีภัณฑ์ และอุตสากรรมถ่านหินได้เจริญรุ่งเรืองเป็นอย่างมากจากนโยบายการจัดลำดับความสำคัญของการผลิตและเศรษฐกิจที่มีอุปสงค์พิเศษจากสงครามในคาบสมุทรเกาหลี ทำให้สามารถฟื้นตัวไดอย่างรวดเร็วจากการถูกทำลาย
        </p>
        </Article>
        <div className="space">
        </div>
        <Article>
        <h1>ยุคเติบโตก้าวกระโดด - การเปลี่ยนแปลงโครงสร้างอุตสาหกรรมจากการปฏิรูปด้านพลังงาน -</h1>
        <p>
        หลังจากช่วงที่เศรษฐกิจเติบโตอย่างก้าวกระโดดหลังจากปีโชวะที่ 30 เป็นต้นไป การผลิตในภาคอุตสาหกรรมของจังหวัดนี้ก็เติบโตเป็นอย่างมาก แต่ในเวลาเดียวกันอุตสาหกรรมถ่ายหินได้ซึมเซาลงจากการปฏิวัติทางด้านพลังงานที่เดินหน้าอย่างต่อเนื่อง ทำให้พื้นที่ผลิตถ่านหินได้รับผลกระทบอย่างรุนแรง<br/>
        หลังจากวิกฤตราคาน้ำมัน 2 ครั้ง ในช่วงครึ่งหลังของยุคโชวะปีที่ 40 และในช่วงครึ่งแรกของยุคโชวะปีที่ 50 เศรษฐกิจเติบโตต่ำ ค่าเงินเยนแข็ง และเกิดการเปลี่ยนแปลงในโครงสร้างอุตสาหกรรม ทำให้เศรษฐกิจของจังหวัดนี้ที่มีอุตสาหกรรมรูปแบบวัสดุเป็นสัดส่วนใหญ่ตกอยู่ในสถานการณ์ที่ยากลำบาก ดังนั้น จึงได้พยายามในการส่งเสริมและรวบรวมอุตสาหกรรมที่มีการเติบโตล้ำหน้า และสนับสนุนการสร้างที่ตั้งของงบริษัทอย่างอุตสาหกรรมยานยนต์ เซมิคอนดักเตอร์ที่ล้ำสมัย ไบโอเทคโนโลยี และอุตสาหกรรมที่เกี่ยวข้องกับหุ่นยนต์ นอกจากนี้ อุตสาหกรรมที่ 3 อย่างการค้าขายหรือการให้บริการได้เติบโตอย่างมากโดยมีศูนย์กลางอยู่ที่เมืองฟูคูโอคะ
        </p>
        </Article>
        <div className="space">
        <div className="navigator left">
        <NavigateCracter type="good"/>
        </div>
        </div>
        <Article>
        <h1>ปัจจุบัน - มุ่งหน้าสู่การเป็นฐานการแลกเปลี่ยนในคิวชู ญี่ปุ่นตะวันออก และเอเชีย -</h1>
        <p>
        หลังจากช่วงหลังของปีโชวะที่ 40 เป็นต้นมา ได้เริ่มปรับปรุงโครงสร้างพื้นฐานทางคมนาคมที่สนับสนุนอุตสาหกรรมและการใช้ชีวิตอย่างจริงจัง หลังจากการเปิดใช้บริการสะพานคันมงที่ในเวลานั้นถูกจับตามองว่าเป็น "สะพานแขวนที่ยาวที่สุดในตะวันออก" ซึ่งเชื่อมต่อระหว่างฮอนชูและคิวชู ในช่วงยุคโชวะที่ 50 ถึง 60 ได้มีการปรับปรุงเครือข่ายของการคมนาคม เช่น เปิดทำการซันโยชินกันเซ็นระหว่างชินโอซากะและฮาคาตะตลอดทั้งสาย เปิดให้บริการทางด่วนคิวชูโจระหว่างจุดสับเปลี่ยนโคกะและจุดสับเปลี่ยนโทสึซึ่งวิ่งตัดผ่านเกาะคิวชูตั้งแต่เหนือจรดใต้ เปิดทำการรถไฟใต้ดินในเมืองฟูคูโอคะระหว่างมูโรมิและเทนชิน และเปิดให้บริการรถไฟโมโนเรลคิตะคิวชู<br/>
        นอกจากนี้ สนามบินชินคิตะคิวชู (ปัจจุบันคือสนามบินคิวชู) เปิดทำการ ในปีเฮเซที่ 18 คิวชูชินกันเซ็นระหว่างฮาคาตะและคาโคชิมะชูโอะได้เปิดให้บริการนปีเฮเซที่ 23 และทางด่วนพิเศษสำหรับยานยนต์ฮิคาชิคิวชูระหว่างคิตะคิวชูชิและมิยาซากิชิตลอดทั้งสายได้เปิดให้บริการในปีเฮเซที่ 28 <br/>
        พิพิธภัณฑ์แห่งชาติคิวชูได้เปิดทำการในปีเฮเซที่ 17 ซึ่งนับว่าเป็นพิพิธภัณฑ์แห่งชาติลำดับที่ 4 ของภายในประเทศ ในปีเฮเซที่ 27 สถานที่ที่เกี่ยวข้องกับถ่ายหิน อุตสาหกรรมเหล็กกล้า และอุตสาหกรรมประกอบเรือ ได้รับการขึ้นทะเบียนเป็นมรดกทางวัฒนธรรมของโลกโดยองค์การเพื่อการศึกษา วิทยาศาสตร์ และวัฒนธรรมแห่งสหประชาชาติ (UNESCO) ในฐานะเป็น "สถานที่ที่มีการปฏิรูปทางอุตสาหกรรมของญี่ปุ่นในยุคเมจิ อุตสาหกรรมการผลิตเหล็ก การผลิตเหล็กกล้า การต่อเรือ และถ่านหิน" ในปีเฮเซที่ 29 '"เกาะที่เทพเจ้าอาศัยอยู่" มุนากาตะ/โอกิโนชิมะ และแหล่งมรดกที่เกี่ยวข้อง' ได้รับการขึ้นทะเบียนเป็นมรดกทางวัฒนธรรมของโลก<br/>
        ในระหว่างที่สังคมได้มีการเปลี่ยนแปลงครั้งใหญ่ จังหวัดเราได้เตรียมโคงสร้างพื้นฐานทางคมนาคมที่สำคัญในการส่งเสริมให้เกิดการแลกเปลี่ยนกับโลก เช่น เอเชีย เป็นต้น และได้ก้าวข้ามความยากลำบากต่าง ๆ เช่น การชี้ชวนอุตสาหกรรมใหม่ การพัฒนาเทคโนโลยีที่ยอดเยี่ยม เป็นต้น ทำให้เกิดการพัฒนาในหลายด้านทั้งอุตสาหกรรมและวัฒนธรรม และได้วางแผนที่จะก้าวหน้าไปอีกขั้นในฐานะจังหวัดผู้นำของคิวชูที่จะส่งเสริมการพัฒนาของญี่ปุ่นต่อไป<br/>
        </p>
        </Article>
    </>)
}

export default LangTh