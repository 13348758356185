
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { TabTamplate } from "@/components/Templates/tab"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import Article from "@/components/Organisms/Article";
import { MedicalTbody } from "."
import Data from 'src/json/ForeignPatientsMedical/ko.json'

const LangKo = ({onActive, active}) => {
    const medicals = Data.data

    return (
        <Article offset={0} className="pc-noTopMargin">
                <InnerBox>
                <h1>외국인 환자를 수용하는 거점 의료기관<br/></h1>
                <p>
                후쿠오카현에서는 외국인이 안심하고 의료서비스를 받을 수 있는 환경을 정비하기 위해 다국어·캐시리스 대응 등을 조건으로 2차 의료권마다 외국인 환자를 수용하는 거점 의료기관을 선정하고 있습니다.
                </p>
                </InnerBox>
                <InnerBox left={0} right={0} bottom={30}>
                <BlackNote>
                <div className="decoration">
                <p className="al-c star">어떤 의료기관이 있나요?</p>
                <p className="txt-middle">
                후쿠오카현내 외국인 환자를 수용하는 거점 의료기관 목록은 다음과 같습니다. (2023년 6월 현재)
                </p>
                <br/>
                <p className="txt-large al-c txt-yellow">
                캐시리스 OK!
                </p>
                </div>
                </BlackNote>
                <div className="space-deco"></div>
            </InnerBox>
            <InnerBox>
            <TabTamplate>
                <ul className="tabnav-custom">
                    <li><button onClick={() => {onActive(0)}} className={`${active === 0 ? 'active' : ''}`}>Fukuoka City</button></li>
                    <li><button onClick={() => {onActive(1)}} className={`${active === 1 ? 'active' : ''}`}>Fukuoka Region<br/>（Outside Fukuoka City）</button></li>
                    <li><button onClick={() => {onActive(2)}} className={`${active === 2 ? 'active' : ''}`}>Chikugo Area</button></li>
                    <li><button onClick={() => {onActive(3)}} className={`${active === 3 ? 'active' : ''}`}>Chikuhō Area</button> </li>
                    <li><button onClick={() => {onActive(4)}} className={`${active === 4 ? 'active' : ''}`}>Kitakyushu Area</button></li>
                </ul>
            
                <nav className="tab">
                    <div className={`tab-content ${active === 0 ? 'active' : ''}`}>
                        <h3>Fukuoka City</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>지역명</th>
                            <th rowSpan={2}>의료기관명</th>
                            <th colSpan={2}>수용 가능 환자</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>취득</th>
                            <th rowSpan={2}>지원 가능 언어</th>
                            </tr>
                            <tr>
                            <th>중증 환자</th>
                            <th>경증 환자</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[0]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 1 ? 'active' : ''}`}>
                        <h3>Fukuoka Region (Outside Fukuoka City)</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>지역명</th>
                            <th rowSpan={2}>의료기관명</th>
                            <th colSpan={2}>수용 가능 환자</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>취득</th>
                            <th rowSpan={2}>지원 가능 언어</th>
                            </tr>
                            <tr>
                            <th>중증 환자</th>
                            <th>경증 환자</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[1]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 2 ? 'active' : ''}`}>
                        <h3>Chikugo Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>지역명</th>
                            <th rowSpan={2}>의료기관명</th>
                            <th colSpan={2}>수용 가능 환자</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>취득</th>
                            <th rowSpan={2}>지원 가능 언어</th>
                            </tr>
                            <tr>
                            <th>중증 환자</th>
                            <th>경증 환자</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[2]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 3 ? 'active' : ''}`}>
                        <h3>Chikuhō Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>지역명</th>
                            <th rowSpan={2}>의료기관명</th>
                            <th colSpan={2}>수용 가능 환자</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>취득</th>
                            <th rowSpan={2}>지원 가능 언어</th>
                            </tr>
                            <tr>
                            <th>중증 환자</th>
                            <th>경증 환자</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[3]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 4 ? 'active' : ''}`}>
                        <h3>Kitakyushu Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>지역명</th>
                            <th rowSpan={2}>의료기관명</th>
                            <th colSpan={2}>수용 가능 환자</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>취득</th>
                            <th rowSpan={2}>지원 가능 언어</th>
                            </tr>
                            <tr>
                            <th>중증 환자</th>
                            <th>경증 환자</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[4]}></MedicalTbody>
                        </table>
                    </div>
                   
                </nav>
            </TabTamplate>
            <br/>
            <p className="txt-small">
            ※ (주 1) 의료기관 목록에 게재되지 않은 의료기관의 외국인 환자 진료를 방해하는 것이 아닙니다. (주 2) 의료기관 목록에 게재된 의료기관은 해외에서 진료 목적으로 일본을 방문하는 외국인 환자 수용 의료기관의 리스트가 아닙니다. 환자를 수용하는 거점 의료기관<br/>
            ※1 JMIP(Japan Medical Service Accreditation for International Patients)→외국인 환자수용의료기관인증 제도<br/>
            일본 국내 의료기관을 대상으로 다국어 진료 안내, 이문화·종교를 배려한 대응 등의 외국인 환자 수용 체제를 제삼자가 평가함으로써 국내 의료기관에서 진료를 받는 모든 외국인에게 안심·안전한 의료서비스를 제공하는 체제 구축 지원을 목적으로 한 것으로, 2012년에 후생노동성이 수립했습니다(인증기관: 일반재단법인 일본 의료교육재단).<br/>
            ※2 21개 언어 지원 의료기관에 대해서는 '후쿠오카 국제 의료 서포트 센터'를 이용.
            </p>
            <NavigatorAction dir="right" charactorType="normal" actionType="pickup"/>
            <EmphasisNote>
            <h1 className="c-h1">다른 정보를 알고 싶나요?</h1>

            <p>
            전국의 거점 의료기관은 후생노동성 및 관광청 홈페이지(아래 URL)에서 공지하고 있습니다.<br/>
            </p>
            <p>
            후생노동성 HP<br/>
            <a href="https://www.mhlw.go.jp/stf/newpage_05774.html" target="_blank">
            https://www.mhlw.go.jp/stf/newpage_05774.html
            </a><br/>
            </p>
            <p>
            관광청 HP<br/>
            <a href="https://www.jnto.go.jp/emergency/jpn/mi_guide.html" target="_blank">
            https://www.jnto.go.jp/emergency/jpn/mi_guide.html
            </a>
            </p>

            <p>
            후쿠오카현내의 병원·진료소·치과·소아과·조산원의 검색, 소아 응급·예방·보건·복지 등의 의료 정보를 제공하는 홈페이지입니다.<br/>
            </p>
            <p>
            후쿠오카 의료 정보 네트워크<br/>
            <a href="https://www.fmc.fukuoka.med.or.jp/" target="_blank">
            https://www.fmc.fukuoka.med.or.jp/
            </a>
            </p>
            </EmphasisNote>
            </InnerBox>
        </Article>
    )
}

export default LangKo