import styled from "styled-components"
import publicPath from "src/utilty/publicPath"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import AriclesSlider from "@/components/Molecules/Slider/ariclesSlider"
import HeadLine from "@/components/Atoms/HeadLine"
import { FetchArtcleIndexArg, fetchArticleIndex } from "src/data/fetch"
import { AritcleIndex } from "src/types/article"
import { useEffect, useState } from "react"
import { langType } from "src/types/lang"
import { easyJaToJa, tsutaeruToEastJa } from "src/utilty/easyJaCheker"
import { PathAnalysisValue } from "src/utilty/pathAnalyzer"

const SSection = styled.section`
    position: relative;
    background:url(${publicPath('images/top/movieBk.png')}) no-repeat;
    background-size:100% auto;
    width: 100%;
    height: 100%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        background: url('images/pc/bk_movie.webp') no-repeat;
        background-size: auto 100%;
        background-position: right;
        position: absolute;
        right: -1%;
    }
`

const SHeadline = styled.h2`
    position: absolute;
    width:fit-content; 
    top: -3%;
    z-index: 4;
    left: 50%;
    width: 70%;
    transform: translateX(-50%);
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
 
        top:-8%;
        left: 58%;
        transform: translate(0%,0%);
    }
`

const SMessagePosion = styled.figure`
    font-size: ${props => props.theme.fontSize.top_mes_sp};
    color :#fff;
    font-weight: bold;
    text-align: center;
    width: 50%;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    img{ width:100%}
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        img{ width:300px}
        top: 50%;
        transform: translateY(-80%);
        left: 30%;
    }
`

const SSliderWrap = styled.div`
    width: 78%;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 10%;
    margin-left: 5%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 24%;
        position: absolute;
        left: auto;
        right:-6%;
        top: -25%;
        z-index: 12;
    }
`

const SMoreLink = styled.div`
    position: absolute;
    bottom: 6%;
    left: 13%;
    width: 46%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        bottom: -5%;
        left: 80%;
        
        width: 250px;
    }
`

const MovieSection = ({pathValue}:{pathValue: PathAnalysisValue}) => {

    const [articles, setArticle] = useState<AritcleIndex>()
    const allowLang = tsutaeruToEastJa(pathValue)

    useEffect(() => {
        const args:FetchArtcleIndexArg  = {
            endpoint: 'movie',
            lang: pathValue.lang,
            offset: 0,
            limit: 5,
        }
        const getArticlesIndex =  async () => {
            setArticle(await fetchArticleIndex(args));
        };

        getArticlesIndex()
    },[])
    

    return (
        <SSection>
            <SHeadline>
                <HeadLine lang="ja" nameKey="movie"></HeadLine>
            </SHeadline>
            <SMessagePosion>
                <img src={publicPath('images/fottunehillsfukuoka.png')} className="" alt="fottunehillsfukuoka"/>
            </SMessagePosion>
            <SSliderWrap>
                {articles && 
                <AriclesSlider
                    articles={articles}
                    layer="movie"
                    lang={pathValue.lang}
                ></AriclesSlider>
                }
            </SSliderWrap>
            <SMoreLink>
                <YellowArrowLink lang="ja" linkKey="check" href={`/movie?lang=${pathValue.lang}`}/>
            </SMoreLink>
        </SSection>     
    )
}

export default MovieSection