
const LangJa = () => {

    return (
        <>
        <h1>個人情報保護方針</h1>
        <p>
            当ポータルサイト（<a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a>）では、個人情報の収集や利用及び管理について、「個人情報の保護に関する法律」及び「福岡県個人情報の保護に関する法律施行条例（令和４年福岡県条例４３号）」に基づき次のとおり適正に取り扱います。なお、この個人情報の取り扱いについての記載は2023年４月１日に更新されました。
        </p>
        <p>
        福岡県の個人情報保護制度トップページは<a href="http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html" target="_blank">こちら</a><br/>
        <a href="http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html" target="_blank">http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html</a>
        </p>
        <p>
            <dl>
                <dt>●個人情報とは</dt>
                <dd>
                個人に関する情報であって、特定の個人が識別され、又は識別され得るもの（住所、氏名、電話番号、E-mailアドレス等）をいいます。
                </dd>
            </dl>
        </p>
        <p>
            <dl>
                <dt>●個人情報の収集について</dt>
                <dd>
                当ポータルサイトを通じてFUKUOKA IS OPEN推進事業実行委員会（以下、「実行委員会」という。）が個人情報を収集する際は収集の目的を明確にし、その目的を達成するために必要な範囲内で収集します。
                </dd>
            </dl>
        </p>
        <p>
            <dl>
                <dt>●安全性の確保</dt>
                <dd>
                収集した個人情報については、漏えい、滅失、改ざん等を防止し、厳重に管理します。また、保有する必要のなくなった個人情報については速やかに廃棄（消去）します。
                </dd>
            </dl>
        </p>
        <p>
            <dl>
                <dt>●クッキー（Cookie）について</dt>
                <dd>
                    <p>一部のページをご利用いただくために、「クッキー（Cookie）」という技術を使っているページがあります。<br/>
                    「クッキー」とは、ウェブサーバがみなさまのコンピュータを識別する際に使用される業界標準の技術で、実態は小さな容量のテキストファイルです。
                    <br/>
                    一般的には、ポータルサイトの利用者のみなさまが行った操作によりサイト管理者に提供された個人情報（ユーザーIDやパスワード）や操作履歴の記録や、アクセスの状況と傾向を分析し、統計情報として使用するなどの用途に使われます。
                    <br/>
                    また、「クッキー」は利用者のみなさまのコンピュータを識別することはできますが、利用者自らが詳細な個人情報を入力・提供しない限り、利用者個人を特定・識別することはできません。
                    <br/>
                    当ポータルサイトは、「クッキー」機能の有無に関わらず、匿名のままでの閲覧・ご利用が可能です。
                    <br/>
                    なお、お使いのブラウザによっては、その設定を変更してクッキーの機能を無効にすることはできますが、その結果、一部のページのサービスの全部または一部がご利用いただけなくなることがあります。
                    </p>
                </dd>
            </dl>
        </p>
        <p>
            <dl>
                <dt>●利用及び提供の制限</dt>
                <dd>
                    <p>
                    実行委員会が収集した個人情報については、個人情報の保護に関する法律又は福岡県個人情報の保護に関する法律施行条例で定める場合を除き、収集の目的を超えての利用や実行委員会以外への提供は一切しません。
                    </p>
                    <p>
                    ただし、当ポータルサイトのアクセス情報、実行委員会へのご提案・ご意見並びにこれらに関係する個人属性について、統計的に処理したデータ又はご意見等の公表をすることがあります。
                    </p>
                </dd>
            </dl>
        </p>
        <p>
            <dl>
                <dt>●ソーシャルメディア（SNS）ボタンに関する留意事項</dt>
                <dd>
                    <p>
                    当ポータルサイトのうち、ソーシャルメディア（SNS）のボタン等が設置されているページを閲覧した際、閲覧者に関する情報が当該SNSに送信されることがあります。当ポータルサイトで利用しているSNS等のサービスのプライバシーポリシー等は、各事業者のサイト等でご確認ください。
                    </p>
                </dd>
            </dl>
        </p>
        <p>
            <dl>
                <dt>●Google Analyticsに関する留意事項</dt>
                <dd>
                    <p>
                    Google Analyticsに関する留意事項
                    当ポータルサイトでは、ポータルサイトの利用状況を把握するためにGoogle Analyticsを利用しています。Google Analyticsは、クッキーを利用して利用者の情報を収集します。Google Analytics利用規約についてはこちらをご覧ください。<br/>
                    （新しいウィンドウで開きます <a href=" https://www.google.com/analytics/terms/jp.html" target="_blank">https://www.google.com/analytics/terms/jp.html </a>）
                    </p>
                </dd>
            </dl>
        </p>
        <p>
            <dl>
                <dt>●適用範囲</dt>
                <dd>
                本プライバシーポリシーは、当ポータルサイト（<a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a> 以下のディレクトリ）においてのみ適用されます。
                </dd>
            </dl>
        </p>
        </>
    )
}

export default LangJa