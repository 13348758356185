import { NavigatorFullLength } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangEn = () => {

    return (<>
        <div className="navigatorPositon sp-only">
            <NavigatorFullLength></NavigatorFullLength>
        </div>
        <div className="pc-flex col-2">
            <div>
                <h1>
                prefectural emblem
                </h1>
                <figure>
                <img src={publicPath('images/symbol01.png')} alt="県のシンボル" className="img-fit"/>
                </figure>
                <p>
                The design is based on the prefectural flower "Ume" and represents the development of the prefecture and the harmony of its citizens.<br/>
                It was chosen by the prefecture on May 10, 1966, through a public contest.
                </p>
            </div>
            <div>
                <h1>
                Flower of Fukuoka Prefecture
                Ume (Japanese plum)
                </h1>
                <figure>
                <img src={publicPath('images/symbol02.png')} className="img-fit"/>
                </figure>
                <p>
                On March 19, 1954, the "NHK Botanical Society" decided on this name in honor of the ume plum tree in Dazaifu.
                Ume is said to have been a favorite of Sugawara Michizane, the god of learning and enshrined in Dazaifu Tenmangu Shrine, throughout his life.
                </p>
            </div>
            <div>
                <h1>
                Bird of Fukuoka Prefecture
                Japanese bush warbler
                </h1>
                <figure>
                <img src={publicPath('images/symbol03.png')} className="img-fit"/>
                </figure>
                <p>
                The prefecture decided on July 3, 1964, through a public contest.
                In Japan, there is a saying, 'Ume ni Uguisu' (plum blossoms and Japanese nightingales).
                This means a metaphor for something that combines well and harmonizes beautifully.
                </p>
            </div>
            <div>
                <h1>
                Tree of Fukuoka Prefecture
                Azalea
                </h1>
                <figure>
                <img src={publicPath('images/symbol04.png')} className="img-fit"/>
                </figure>
                <p>
                It was decided on September 5, 1966, as part of the "Green Nippon National Campaign.
                The azalea is widely cultivated in the Kurume area for planting and bonsai, and its seedlings are exported to foreign countries.
                </p>
            </div>
        </div>
    </>)
}

export default LangEn