import { CharactorAction } from "@/components/Atoms/Character/action"
import { Challenger } from "@/components/Atoms/Character/challenger"
import { NavigateCracter } from "@/components/Atoms/Character/navigator"
import { color } from "framer-motion"
import { ChallengerType, CharactorActionColor, CharactorActionType, NavigatorType } from "src/types/charactor"
import styled from "styled-components"



interface ChallengerProps {
    charactorType : ChallengerType,
    actionType : CharactorActionType,
    actionColor? : CharactorActionColor,
    dir : | 'left' | 'right'
}

interface NavigatorProps {
    charactorType : NavigatorType,
    actionType : CharactorActionType,
    actionColor? : CharactorActionColor,
    dir : | 'left' | 'right'
}


const SDiv = styled.div`
    position: relative;
    width: 100%;
    max-width: 500px;
    overflow: hidden;
    .c_postion{
        position: absolute;
    }
    .a_postion{
        position: absolute;
    }
`
/*** ==================================================================
 * ChallengerAction
 ========================================================================*/

export const ChallengerAction = ({
    charactorType,
    actionType,
    actionColor = 'blue',
    dir
  }:ChallengerProps ) => 
{

    const SChallenger = styled(SDiv)`
        aspect-ratio: 10 / 5;
        /*size*/
        .c_size{
            width: 50%;
            &.normal{ width: 60% }
        }
        .a_size{
            width: 60%;
            &.question-blue{ width: 35%;}
            &.excl-blue{ width:35% }
            &.nice-green{ width:40% }
            
        }
        /*positon*/
        &.left{
            .c_postion{   left: 0;}
            .a_postion{ 
                right: 3%;
                &.excl-blue{ top:5%; right:10% }
                &.nice-green{ right:14%  }
            }
        }
        &.right{
            .c_postion{ right: 0;}
            .a_postion{ left: 3% }
        }
    `

    return (
        <SChallenger className={`${dir}`}>
            <div className={`c_postion c_size ${charactorType}`}>
                <Challenger type={charactorType}/>
            </div>
            <div className={`a_postion a_size ${actionType}-${actionColor}`}>
                <CharactorAction type={actionType} color={actionColor}/>
            </div>
        </SChallenger>
    )
}


/*** ==================================================================
 * NavigatorAction
 ========================================================================*/

export const NavigatorAction = ({
    charactorType,
    actionType,
    actionColor = 'blue',
    dir
  }:NavigatorProps ) => 
{

    const SNatigator = styled(SDiv)`
        aspect-ratio: 10 / 6.8;
        /*size*/
        .c_size{
            width: 40%;
        }
        .a_size{
            width: 40%;
            &.question-blue{ width: 35%;}
            &.excl-blue{ width:35% }
            &.nice-green{ width:40% }
            &.enjoy-green{ width:45% }
            &.goodluck-green{ width:46% }
        }
        /*positon*/
        &.left{
            .c_postion{ left: 0;}
            .a_postion{ 
                right: 3%;
                &.excl-blue{ top:5%; right:10% }
                &.nice-green{ right:14%  }
            }
        }
        &.right{
            .c_postion{ 
               
                right: 0;
                
            }
            .a_postion{ 
                top:34%; left:20%; 
                transform:translateY(-50%);
            }
        }
    `

    return (
        <SNatigator className={`${dir} ${actionType}`}>
             <div className={`c_postion c_size ${charactorType}`}>
                <NavigateCracter type={charactorType}/>
            </div>
            <div className={`a_postion a_size ${actionType}-${actionColor}`}>
                <CharactorAction type={actionType} color={actionColor}/>
            </div>
        </SNatigator>
    )
}