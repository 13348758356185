import styled from "styled-components"
import { CmsIndex } from "@/components/Organisms/Cms"
import { useLocation } from "react-router-dom"
import { pathAnalyzer } from "src/utilty/pathAnalyzer"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"

const SEducation= styled.div``

const SCharactor = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 10 / 3.5;

    .challengerWrap{
        position: absolute;
        width: 65%;
        top: 0;
        aspect-ratio: 10 / 10;
        overflow-y: hidden;
        transform: translateY(-10%);
    }
    .navigator{
        position: absolute;
        width: 30%;
        z-index: 11;
        top:0;
        transform: translateY(-10%);
        right: 20px;
    }
`

const LifeStyleEducation = () => {

    const keywords = [
        '総合政策課',
        '海外事務所',
        '企業立地課',
        '中小企業振興課',
        '新規支援課',
        '輸出促進課',
        'サンプル',
        'サンプル',
        'サンプル',
        'サンプル',
    ]
    const location = useLocation()
    const lang = pathAnalyzer(location).lang

    setTitleDiscrption({
        layer : 'lifestyle',
        lang : lang,
        subLayer : 'education'
    })

    return (
        <SEducation>
            <CmsIndex></CmsIndex>
        </SEducation>
    )
}

export default LifeStyleEducation