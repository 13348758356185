import { NavigateCracter } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"

const LangZhTw = () => {

    return (<>
        <Article>
        <h1>古代 - 與亞洲交流的窗口－</h1>
        <p>
        在古代，福岡被稱為 "遠方的朝廷 "，是太宰府和外國使節"迎賓館 "的所在地，是中國大陸和朝鮮半島與日本交流的窗口。 到了中世紀，博多港作為與中國大陸、朝鮮半島為首的琉球群島和南海的貿易基地，繼續繁榮發展。
        </p>
        </Article>
        <div className="space">
        <div className="navigator right">
        <NavigateCracter type="smile"/>
        </div>
        </div>
        <Article>
        <h1>江戶時代 -商業中心- </h1>
        <p>
        進入江戶時代，木蠟等商品作物、博多織、久留米絣、小倉織等工藝品的生產蓬勃發展。 隨著許多道路、港口、遠賀川、築後川水運的發展，該縣成為九州交通要塞。        </p>
        </Article>
        <div className="space">
        </div>
        <Article>
        <h1>明治～昭和   -煤炭和鋼鐵工業的興起 -</h1>
        <p>
        從明治到昭和，築豐一帶和築後大牟田地區的煤炭生產蓬勃發展。利用這些煤炭在北部形成了以鋼鐵、機械、電力、化工和陶瓷為主的“北九州工業區”。在南部形成了“煤炭化工聯合企業”，為日本的現代化和經濟發展提供了支撐。<br/>
        二戰結束後，日本傾斜式生產體製和朝鮮戰爭引發的特殊采購熱潮，使鋼鐵、金屬和化工等製造業以及煤炭工業蓬勃發展，日本很快從戰火中恢複過來。
        </p>
        </Article>
        <div className="space">
        </div>
        <Article>
        <h1>經濟高速增長時期  -通過能源革命實現產業結構轉型-</h1>
        <p>
        在 1955 年以後的經濟高速增長時期，本縣的工業生產大幅增長，但與此同時，煤炭生產地區卻因當時正在進行的能源革命而受到了煤炭產業衰退的嚴重影響。<br/>
        在經歷了 1970 年代的兩次石油危機後，在經濟低增長、日元堅挺和產業結構轉型的情況下，材料工業占比很高的本縣經濟麵臨困境。 因此，本縣努力培育和聚集尖端成長型產業，促進汽車產業、先進半導體、生物技術、機器人技術和相關產業的企業落戶。 此外，以福岡市為中心，商業和服務業等第三產業也得到了顯著發展。
        </p>
        </Article>
        <div className="space">
        <div className="navigator left">
        <NavigateCracter type="good"/>
        </div>
        </div>
        <Article>
        <h1>現在  -以成為九州、西日本和亞洲的交流中心為目標-</h1>
        <p>
        自1970年代中期以來，為支撐工業和日常生活而大力發展交通基礎設施建設。 備受矚目的，連接本州和九州的 "東方最長懸索橋 "關門橋通車後，1970 年代和 1980 年代，新大阪和博多之間的山陽新幹線全線開通；貫穿九州南北的古賀 IC 和鳥棲 IC 之間的九州縱向機動車道開通；室見到天神之間的福岡市地下鐵開業，以及北九州單軌電車開業。交通網絡不斷發展。<br/>
        此外，新北九州機場（現北九州機場）於 2006 年啟用。博多至鹿兒島中央的九州新幹線於 2011 年，北九州市至宮崎市的東九州動車道於 2016 年全線開通。<br/>
        2005年日本第四個國立博物館“九州國立博物館”開館。2015年煤礦、鋼鐵工業和造船業等相關設施作為 "鋼鐵製造、造船業和煤炭工業的日本明治工業革命遺址 "被列入聯合國教科文組織（UNESCO）的世界遺產名錄。2017年與"神宿島"宗像和衝之島相關的文化遺產群"被列入世界文化遺產名錄。<br/>
        在社會發生重大變化的情況下，本縣克服重重困難，通過建設重要的交通基礎設施，促進與以亞洲為首的世界各地的交流，吸引新興產業，開發先進技術，實現了包括產業和文化在內的全麵發展。 作為支撐日本未來發展的九州的領頭縣，正在邁出更大的步伐。<br/>
        </p>
        </Article>
    </>)
}

export default LangZhTw