import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlueLeft } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { NavigatorFukidashiCheck } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"


const LangKo = () => {

    return (<>
    <InnerBox top={0} bottom={30} >
    <h1>공익재단법인 후쿠오카현 국제교류센터에 대해</h1>
    <p>
    공익재단법인 후쿠오카현 국제교류센터는 후쿠오카현의 지리적, 역사적 특성을 살려, 현 산하의 교류단체 등과 협력하여 현민주체의 국제교류를 추진함으로써, 국제교류에서 후쿠오카현의 중심성을 높이는 동시에, 아시아 국가 및 지역을 비롯해 세계 각국 및 지역과의 교류를 강화하여, 상호 번영과 세계 평화에 기여하는 것을 목적으로, 1989년에 설립되었습니다.    </p>
    <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
    <InnerBox top={20} bottom={20} left={0} right={0}>
    <EmphasisNote>

    <p>
    현민과 거주 외국인과의 교류로는 강연회, 세미나 등 다양한 이벤트를 개최할 뿐만 아니라 거주 외국인의 다양한 문제를 일본어를 포함한 22개국 언어로 해결하는 ‘후쿠오카현 외국인 상담센터’ 운영과 자원봉사 모집, 재해 관련 정보와 같은 생활 관련 정보도 제공하고 있습니다.
    </p>
    <p>
    해외와의 교류로는 후쿠오카현과 우호 관계를 맺고 있는 하와이주(미국), 장쑤성(중국), 방콕도(태국) 등과의 교류를 진행하고 있습니다.
    </p>
    <p>
    또한, 현내의 학교나 공민관에 거주 외국인 등을 강사로 파견하는 국제이해 교육을 실시하는 동시에, 민간 국제교류 단체가 실시하는 풀뿌리 교류 지원 및 지역에서 일본어 교실도 지원하고 있습니다.
    </p>
    <p>
    또한, 산/학/관으로 설치된 후쿠오카현 유학생 서포트센터의 사무국으로서, 유학생의 종합적인 지원도 실시하고 있습니다.
    </p>
    </EmphasisNote>
    </InnerBox>
    <p>
    【문의처】<br/>
    우) 810-0001<br/>
    후쿠오카현 후쿠오카시 주오구 덴진 1-1-1 아크로스 후쿠오카 8층/3층<br/>
    8층 TEL: 092-725-9204 FAX: 092-725-9205 8:30~17:45(주말 및 공휴일 제외)<br/>
    3층(고쿠사이히로바) TEL: 092-725-9200 FAX: 092-725-9206 10:00~18:30(연말연시 제외) <br/>
    <br/>
    </p>
    <div className="custom-link">
    <FukidashiRedLeft>공익재단법인 후쿠오카현 국제교류센터에 대해</FukidashiRedLeft>

    <a href="https://kokusaihiroba.or.jp/" target="_blank">
    <img src={publicPath('images/fief.png')} className="img-fit" alt="공익재단법인 후쿠오카현 국제교류센터에 대해"/>
    </a>
    </div>

    </InnerBox>
    <InnerBox top={20} bottom={20}>
    <h1>
    후쿠오카현 외국인 상담 센터
    </h1>
    
    <div className="sp-only">
    <NavigatorFukidashiCheck dir="left">
    <span className="txt-yellow">
    후쿠오카에서 생활하는 데에 어려운 점은 없습니까?<br/>
    외국인 상담 센터에서는 다국어로 여러분의 고민을 해결해 드립니다
    </span>
    </NavigatorFukidashiCheck>
    </div>
    
    <br/>
    <p>
    외국인의 생활 지원으로는 '후쿠오카현 외국인 상담 센터'에서 고용, 노동, 의료, 주택, 교육 등 생활면에서의 어려운 점이나 체류 수속, 법률 상담 등 다양한 상담 내용에 22개 언어로 대응하고 있습니다.
    </p>
    <div className="pc-only">
        <div className="pc-flex col-2">
            <div>
                <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
            </div>
            <div className="center">
                <FukidashiBlueLeft>
                    <span className="txt-yellow">
                    후쿠오카에서 생활하는 데에 어려운 점은 없습니까?<br/>
                    외국인 상담 센터에서는 다국어로 여러분의 고민을 해결해 드립니다
                    </span>
                </FukidashiBlueLeft>
            </div>
        </div>
    </div>

    <br/>

    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi navigatorCheck right pointing">
            금하거나 어려운 점이 있다면 부담 없이 전화 또는 창구를 이용해 주세요.<br/>
            다국어로 상담 가능합니다. (무료)
            </div>
        </div>
        <div>
            <figure>
                <img src={publicPath('images/international01.png')} className="img-fit" alt=""/>
            </figure>
            <figure className="pc-only">
                <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
            </figure>
        </div>
    </div>

    
    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi challengerQ left thinking">
            일본에서의 체류 기간을 연장하고 싶어요. 어떻게 하면 되나요?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right puzzled">
            자국 언어로 대응해 주는 병원이 있나요?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            집주인과 트러블이 생겼어요. 어디에 상담하면 되나요?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            주민등록표가 필요합니다. 어디에서 받을 수 있나요?
            </div>
        </div>
    </div>

    <figure>
    <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
    </figure>
    <InnerBox top={20} bottom={20} left={0} right={0}>
    <EmphasisNote>
    <h2>
    지원 언어
    </h2>
    <p>
    일본어, 영어, 중국어, 한국어, 베트남어, 네팔어, 인도네시아어, 타갈로그어, 태국어, 말레이어, 스페인어, 프랑스어, 독일어, 이탈리아어, 러시아어, 포르투갈어, 미얀마어, 캄보디아어, 몽골어, 신할라어, 힌디어, 벵골어
    </p>
    </EmphasisNote>
    </InnerBox>
    <p>
    후쿠오카시 주오쿠 덴진 1-1-1 아쿠로스 후쿠오카 3F<br/>
    (공익재단법인) 후쿠오카현 국제 교류 센터 내<br/>
    0120－279－906
    </p>
    <FukidashiRedLeft>
    링크
    </FukidashiRedLeft>
    <div className="pc-flex col-2">
        <p>
            <a href="https://kokusaihiroba.or.jp/" target="_blank">
                <img src={publicPath('images/fief.png')} className="img-fit" alt="公益財団法人福岡県国際交流センター"/>
            </a>
        </p>
        <p>
            <a href="https://www.fissc.net/" target="_blank">
                <img src={publicPath('images/ficc.png')} className="img-fit" alt="FiSSC"/>
            </a>
        </p>
    </div>
    <NavigatorAction actionType="goodluck" charactorType="good" dir="right"></NavigatorAction>
    </InnerBox>
    </>)
}

export default LangKo