
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote";


const LangVi = () => {

    return (
        <>
            <p className="discpriton">
            Một trong những đặc sản của tỉnh Fukuoka là “Tonkotsu Ramen”<br/>
            Tại các cửa hàng ramen nằm rải rác trong tỉnh, các bạn có thể thưởng thức món mì ramen với hương vị đặc trưng riêng của các cửa hàng đó.<br/>
            “TONKOTSU RAMEN BEATS” là trò chơi lấy chủ đề từ món “Tonkotsu Ramen” đó.<br/>
            Hãy bắt đúng nhịp điệu của bát cơm (donburi) để ghi điểm cao nào!
            </p>
            <EmphasisNote className="customNote">
                <h4><img src="./images/ramenbeats/howtoplay.webp" alt="how to play"/></h4>
                <section>
                    <h5 className="first">
                    “Chạm vào nút bắt đầu ở cuối màn hình<br/>
                    (Sẽ chuyển sang màn hình trò chơi)”
                    </h5>
                </section>
                <section>
                    <h5 className="second">
                        Chạm vào<br/>
                        <img src="./images/ramenbeats/start.webp" alt="start" width={230}/>
                    </h5>
                </section>
                <section>
                    <h5 className="third">
                    Chạm vào cấp độ bạn yêu thích và bắt đầu trò chơi!
                    </h5>
                    <ul className="caption">
                        <li>
                        Vui lòng sử dụng hệ điều hành và trình duyệt mới nhất để chơi
                        </li>
                        <li>
                        Trò chơi này được thiết kế cho điện thoại thông minh
                        </li>
                    </ul>
                </section>
            </EmphasisNote>
            <h4 className="whattonkotsu"><img src="./images/ramenbeats/what_tonkotsu.webp" alt="What’s TONKOTSU RAMEN？"/></h4>
            <p>
            “Tonkotsu” có nghĩa là “xương lợn”<br/>
            Súp của Tonkotsu Ramen được làm bằng cách ninh xương lợn trong thời gian dài<br/>
            Mì ramen khác nhau tùy theo khu vực và cửa hàng, nhưng đặc điểm chung là có màu trắng đục<br/>
            Sợi mì thì chủ yếu là sợi mì thẳng và mảnh<br/>
            Tại nhiều cửa hàng, các bạn có thể gọi mì với độ cứng mong muốn, chẳng hạn như “Barikata” (rất cứng), “Kata” (cứng).<br/>
            Ngoài ra, còn có đặc trưng là nhiều cửa hàng có thể gọi "Kaedama" - gọi riêng thêm mì sau khi ăn hết
            </p>
            <ul className="phots"> 
                <li>
                    <img  src="./images/ramenbeats/ramen01.webp" alt="豚骨ラーメン"/>
                </li>
                <li>
                    <img  src="./images/ramenbeats/ramen02.webp" alt="豚骨ラーメン"/>
                </li>
            </ul>
            
        
        </>
    )
}

export default LangVi