const LangVi = () => {

    return (<>
    <h1>Khả năng truy cập</h1>
    <dl>
    <dt>
    Hoạt động
    </dt>
    <dd>
    <p>Tỉnh Fukuoka phấn đấu duy trì và nâng cao khả năng truy cập trang web theo Tiêu chuẩn Công nghiệp Nhật Bản「JIS X 8341-3:2016」để đảm bảo mọi người đều có thể sử dụng trang web.</p>
    </dd>
    <dt>
    1.Phạm vi đối tượng
    </dt>
    <dd>
    <p>
    Trang chủ chính thức dưới tên miền https://fisop.net
    </p>
    </dd>
    <dt>
    2.Mức độ tương thích đề ra
    </dt>
    <dd>
    <p>
    Mức độ tương thích: Tuân thủ cấp độ AA của khả năng truy cập web JIS X 8341-3:2016<br/>
    (Tiếp tục tiến hành điều chỉnh để đạt mục tiêu tuân thủ cấp bậc tương thích AA của khả năng truy cập web lên đến tầng 2)<br/>
    Biểu thị "Tuân thủ cấp độ tương thích AA" là biểu thị theo quy định "Hướng dẫn biểu thị độ tương thích của nội dung Web với JIS X 8341-3:2016 phiên bản tháng 3 năm 2016” của Ủy ban cơ sở hạ tầng truy cập web, Hiệp hội truy cập thông tin truyền thông , ngoài ý nghĩa là tuân thủ cấp độ tương thích AA thì còn có ý nghĩa là trang web đã đáp ứng tiêu chuẩn của cấp độ tương thích AA.<br/>
    <br/>
    <a href="https://waic.jp/docs/jis2016/compliance-guidelines/202104/" target="_blank">
    Hướng dẫn biểu thị độ tương thích của nội dung web với JIS X 8341-3:2016 Mở bằng cửa sổ mới (link bên ngoài)
    </a>
    </p>
    </dd>
    </dl>
    </>)
}

export default LangVi