import styled, { ThemeProvider } from 'styled-components';
import publicPath from 'src/utilty/publicPath';

export const SArticle = styled.article`
    background: #fff;
    background-image: url(${publicPath('images/aritcleBk02.png')});
    background-size: 100% auto;
    width: 100%;
    padding: 20px 20px;
    position: relative;
    margin:40px 0px;
    font-size: ${props => props.theme.fontSize.txt_sp};
    line-height:${props => props.theme.lineheight.txt_sp};
    min-height: 100vh;
    color: #000;
    font-weight: 500;  

    &::before{
        content: '';
        background: #000;
        width: 100%;
        height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-100%);
        clip-path: polygon(100% 80%, 0% 100%, 100% 100%);
    }
    &::after{
        content: '';
        background: #000;
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(20%);
        clip-path: polygon(0% 80%, 0% 100%, 100% 80%);
    }

    p{
        word-break: normal;
        margin-bottom: 10px;
    }
    span{
       /* word-break: break-all;*/
        white-space: pre-wrap;
    }
    figure{
        margin: 0 0 10px 0;
        text-align: center;
        figcaption{
            padding-top: 1px;
            font-weight: bold;
            text-align: left;
        }
        img{
            max-width: 100%;
            height: auto;
        }
    }
    a:hover{
        color:${props => props.theme.colors.blue};
    }
    h1,.title-large{
        border-left: solid 5px ${props => props.theme.colors.blue};
        font-size: ${props => props.theme.fontSize.h1_sp} ;
        line-height:  ${props => props.theme.lineheight.h1_sp};
        padding:5px 10px;
        margin-bottom: 10px;
        font-weight: bold;
        display: block;
        text-align: left;
    }
    h2,.title-middle{
        display: block;
        font-size: ${props => props.theme.fontSize.h2_sp};
        line-height:  ${props => props.theme.lineheight.h2_sp};
        border: solid 1px ${props => props.theme.colors.orange};
        color : ${props => props.theme.colors.orange};
        padding: 10px 15px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 10px;
        background: #fff;
    }
    h3,.title-small{
        display: block;
        font-size: ${props => props.theme.fontSize.h2_sp};
        line-height:  ${props => props.theme.lineheight.h2_sp};
        border: solid 1px ${props => props.theme.colors.orange};
        color : ${props => props.theme.colors.orange};
        padding: 10px 15px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 10px;
        background: #fff;
    }
    h4{
        font-size: ${props => props.theme.fontSize.h4_sp};
        line-height:  ${props => props.theme.lineheight.h4_sp};
        font-weight: bold;
    }
    blockquote,.emphasis-block{
        background: ${props => props.theme.colors.gray};
        color: #fff;
        margin: 0;
        padding: 20px;

        h2,.title-middle,h3,.title-small{
            background: none;
        }
        a{
            color:#fff
        }
        a:hover{
        color:${props => props.theme.colors.orange};
        }
    }
    .txt-small{
        font-size: 10px !important;
        line-height: 15px !important;
        display: inline-block;
    }
    .txt-yellow{
        color: ${props => props.theme.colors.yellow} !important;
        font-weight: bold;
    }
    .txt-blue{
        color: ${props => props.theme.colors.blue} !important;
        font-weight: bold;
    }
    [class*=-txt-block]{
        font-size: ${props => props.theme.fontSize.txt_sp};
        line-height:${props => props.theme.lineheight.txt_sp};
        display: block;
        color:  ${props => props.theme.colors.black};
        padding: 10px;
        font-weight: bold;
    }
    .beige-txt-block{
    
        background: ${props => props.theme.colors.beige};
       
        &.offset-left{
            margin-left: 20px;
        }
    }
    .orange-txt-block{
        background: ${props => props.theme.colors.orange};
        color: #fff;
    }
    .ligtgreen-txt-block{
         background: ${props => props.theme.colors.lightgreen};
        color: #fff;
    }
    .red-txt-block{
        background: ${props => props.theme.colors.red};
        color: #fff;
    }
    .lightgray-txt-block{
        background: #595757;
        color:#fff
    }

    .backgound-white{
        background: #fff;
        padding: 10px;
        color: ${props => props.theme.colors.black};
        display: block;
    }
    
    .deco-fukidashi-top{
        
        color:  ${props => props.theme.colors.yellow};
        font-size: ${props => props.theme.fontSize.fukudashi_sp};
        line-height: ${props => props.theme.lineheight.fukudashi_sp};
        font-weight: bold;
        background: #036EB7;
        background: url(${publicPath('images/fukidashi_main02.png')});
        background-size: 100% 100%;
        padding:20px 10px;
        display: block;
        margin-bottom:calc(12% + 10px);
        margin-top: calc(20% + 10px);
        position: relative;
        &::before{
            content: '';
            width: 100%;
            padding-bottom: 20%;
            position: absolute;
            top: 0;
            transform: translateY(-98%);
            left: 0;
            background: url(${publicPath('images/fukidashi_top02.png')}) no-repeat;
            background-size: 100% auto;
        }
        &::after{
            content: '';
            width: 100%;
            padding-bottom: 14%;
            position: absolute;
            bottom: 0;
            transform: translateY(99%);
            left: 0;
            background: url(${publicPath('images/fukidashi_bottom02.png')}) no-repeat;
            background-size: 100% auto;
            
        }
    }
     /*icon
    ---------------*/
    .icon-pdf{
        position: relative;
        display: inline-block;
        padding-right: 25px;
        padding-bottom: 5px;
        &::after{
            content: '';
            height: 75%;
            width: 23px;
            background: url('./images/pdf_icon.png') no-repeat;
            background-size: auto 100%;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .icon-doc{
        position: relative;
        display: inline-block;
        padding-right: 25px;
        padding-bottom: 5px;
        &::after{
            content: '';
            height: 28px;
            width: 23px;
            background: url('/images/icon_doc.png') no-repeat;
            background-size: 100% auto;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    /**
    ============================================
    吹き出し
    ============================================
    **/
    .deco-fukidashi{
        display: block;
        position: relative;
        color:  ${props => props.theme.colors.yellow};
        font-size: ${props=>props.theme.fontSize.fukudashi_sp};
        font-weight: bold;
        span, p {
            font-size: ${props=>props.theme.fontSize.fukudashi_sp};
            font-weight: bold;
        }
        padding:10px 30px 0;
        margin-top: 26%;
        margin-bottom: 16%;
        
        &::before, &::after{
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
        }
        z-index: 10;

        &.challengerQ{
           
            &.left{
                margin-top: 46%;
                &::before{
                    background: url(${publicPath('images/fukidashi/fukidashiLeft_challenger.png')}) no-repeat;
                    background-size: 100% auto;
                    padding-bottom: 45%;
                    transform: translateY(-99%);
                }
                &.puzzled{
                    &::before{
                        background: url(${publicPath('images/fukidashi/fukidashiLeft_challenger_puzzled.png')}) no-repeat;
                        background-size: 100% auto;
                        padding-bottom: 45%;
                        transform: translateY(-99%);
                    }
                }
                &.thinking{
                    &::before{
                        background: url(${publicPath('images/fukidashi/fukidashiLeft_challenger_thinking.png')}) no-repeat;
                        background-size: 100% auto;
                        padding-bottom: 45%;
                        transform: translateY(-99%);
                    }
                }

                &::after{
                    background: url(${publicPath('images/fukidashi/fukidashiLeft_bottom.png')}) no-repeat;
                    background-size: 100% auto;
                    bottom: 0;
                    padding-bottom:14%;
                    transform: translateY(98%);
                }
                background: url(${publicPath('images/fukidashi/fukidashiLeft_main.png')});
                background-size: 100% 100%;
            }
            &.right{
                margin-top: 50%;
                &::before{
                    background: url(${publicPath('images/fukidashi/fukidashiRight_challenger.png')}) no-repeat;
                    background-size: 100% auto;
                    padding-bottom: 52%;
                    transform: translateY(-99%);
                }
                &.puzzled{
                    &::before{
                        background: url(${publicPath('images/fukidashi/fukidashi_right_challenger_puzzled.png')}) no-repeat;
                        background-size: 100% auto;
                        padding-bottom: 52%;
                        transform: translateY(-99%);
                    }
                }
                &.thinking{
                    &::before{
                        background: url(${publicPath('images/fukidashi/fukidashi_right_challenger_thinking.png')}) no-repeat;
                        background-size: 100% auto;
                        padding-bottom: 52%;
                        transform: translateY(-99%);
                    }
                }
                &::after{
                    background: url(${publicPath('images/fukidashi/fukidashiRight_bottom.png')}) no-repeat;
                    background-size: 100% auto;
                    bottom: 0;
                    padding-bottom:14%;
                    transform: translateY(98%);
                }
            
                background: url(${publicPath('images/fukidashi/fukidashiRight_main.png')});
                background-size: 100% 100%;
                
            }
        }

        &.navigatorCheck{
          
            &.left{
                margin-top: 46%;
                &::before{
                    background: url(${publicPath('images/fukidashi/fukidashiLeft_navi.png')}) no-repeat;
                    background-size: 100% auto;
                    padding-bottom: 62%;
                    transform: translateY(-98.5%);
                    z-index: 9;
                }
                &.pointing{
                    &::before{
                        background: url(${publicPath('images/fukidashi/fukidashiLeft_navi_pointing.png')}) no-repeat;
                        background-size: 100% auto;
                    }
                }
                &.good{
                    &::before{
                        background: url(${publicPath('images/fukidashi/fukidashiLeft_navi_good.png')}) no-repeat;
                        background-size: 100% auto;
                    }
                }
                &.smile{
                    &::before{
                        background: url(${publicPath('images/fukidashi/fukidashiLeft_navi_smile.png')}) no-repeat;
                        background-size: 100% auto;
                    }
                }
                &::after{
                    background: url(${publicPath('images/fukidashi/fukidashiLeft_bottom.png')}) no-repeat;
                    background-size: 100% auto;
                    bottom: 0;
                    padding-bottom:14%;
                    transform: translateY(98%);
                }
                background: url(${publicPath('images/fukidashi/fukidashiLeft_main.png')});
                background-size: 100% 100%;
            }
            &.right{
                margin-top: 63%;
                &::before{
                    background: url(${publicPath('images/fukidashi/fukidashiRight_navi.png')}) no-repeat;
                    background-size: 100% auto;
                    padding-bottom: 62%;
                    transform: translateY(-99%);
                }
                &.pointing{
                    &::before{
                        background: url(${publicPath('images/fukidashi/fukidashi_right_navi_pointing.png')}) no-repeat;
                        background-size: 100% auto;
                    }
                }
                &.good{
                    &::before{
                        background: url(${publicPath('images/fukidashi/fukidashi_right_navi_good.png')}) no-repeat;
                        background-size: 100% auto;
                    }
                }
                &.smile{
                    &::before{
                        background: url(${publicPath('images/fukidashi/fukidashi_right_navi_smile.png')}) no-repeat;
                        background-size: 100% auto;
                    }
                }
                &::after{
                    background: url(${publicPath('images/fukidashi/fukidashiRight_bottom.png')}) no-repeat;
                    background-size: 100% auto;
                    bottom: 0;
                    padding-bottom:14%;
                    transform: translateY(98%);
                }
            
                background: url(${publicPath('images/fukidashi/fukidashiRight_main.png')});
                background-size: 100% 100%;
                
            }
        }

        &.left{
            &::before{
                background: url(${publicPath('images/fukidashi/fukidashiLeft_top.png')}) no-repeat;
                background-size: 100% auto;
                padding-bottom: 26%;
                transform: translateY(-99%);
            }
            &::after{
                background: url(${publicPath('images/fukidashi/fukidashiLeft_bottom.png')}) no-repeat;
                background-size: 100% auto;
                bottom: 0;
                padding-bottom:14%;
                transform: translateY(98%);
            }
            background: url(${publicPath('images/fukidashi/fukidashiLeft_main.png')});
            background-size: 100% 100%;
        }
        &.right{
            &::before{
                background: url(${publicPath('images/fukidashi/fukidashiRight_top.png')}) no-repeat;
                background-size: 100% auto;
                padding-bottom: 26%;
                transform: translateY(-99%);
            }
            &::after{
            
                background: url(${publicPath('images/fukidashi/fukidashiRight_bottom.png')}) no-repeat;
                background-size: 100% auto;
                bottom: 0;
                padding-bottom:14%;
                transform: translateY(98%);
            }
        
            background: url(${publicPath('images/fukidashi/fukidashiRight_main.png')});
            background-size: 100% 100%;
            
        }
    }

  

     /*デコ下吹出し*/
    .deco-fukidashi-bottom{
        
        color:  ${props => props.theme.colors.yellow};
        font-size: ${props => props.theme.fontSize.fukudashi_sp};
        line-height: ${props => props.theme.lineheight.fukudashi_sp};
        font-weight: bold;
        background: #036EB7;
        background: url(${publicPath('images/fukidashi/fukidashi_main03.png')});
        background-size: 100% 100%;
        padding:20px 10px;
        display: block;
        margin-bottom:calc(18% + 10px);
        margin-top: calc(8% + 10px);
        position: relative;
        &::after{
            content: '';
            width: 100%;
            padding-bottom: 18%;
            position: absolute;
            bottom: 0;
            transform: translateY(99%);
            left: 0;
            background: url(${publicPath('images/fukidashi/fukidashi_bottom03.png')}) no-repeat;
            background-size: 100% auto;
        }
      
        &::before{
            content: '';
            width: 100%;
            padding-bottom: 7%;
            position: absolute;
            top: 0;
            transform: translateY(-99%);
            left: 0;
            background: url(${publicPath('images/fukidashi/fukidashi_top03.png')}) no-repeat;
            background-size: 100% auto;
        }
    }

    /*デコ下左吹出し*/
    .deco-fukidashi-bottom-left{
        
        color:  ${props => props.theme.colors.yellow};
        font-size: ${props => props.theme.fontSize.fukudashi_sp};
        line-height: ${props => props.theme.lineheight.fukudashi_sp};
        font-weight: bold;
        background: #036EB7;
        background: url(${publicPath('images/fukidashi/fukidashiBottomLeft_main.png')});
        background-size: 100% 100%;
        padding:20px 10px;
        display: block;
        margin-bottom:calc(26% + 10px);
        margin-top: calc(11% + 10px);
        position: relative;
        z-index: 10;
        &::after{
            content: '';
            width: 100%;
            padding-bottom: 26%;
            position: absolute;
            bottom: 0;
            transform: translateY(98%);
            left: 0;
            background: url(${publicPath('images/fukidashi/fukidashiBottomLeft.png')}) no-repeat;
            background-size: 100% auto;
            z-index: 9;
        }
      
        &::before{
            content: '';
            width: 100%;
            padding-bottom: 11%;
            position: absolute;
            top: 0;
            transform: translateY(-99%);
            left: 0;
            background: url(${publicPath('images/fukidashi/fukidashiBottomLeft_top.png')}) no-repeat;
            background-size: 100% auto;
        }
        &.navigatorWelcom{
            margin-bottom:calc(68% + 10px);
            &::after{
                content: '';
                width: 100%;
                padding-bottom: 68%;
                position: absolute;
                bottom: 0;
                transform: translateY(98%);
                left: 0;
                background: url(${publicPath('images/fukidashi/fukidashiBottomLeft_welcom.png')}) no-repeat;
                background-size: 100% auto;
                z-index: 9;
            }
            &.smile{
                &::after{
                background: url(${publicPath('images/fukidashi/fukidashiBottomLeft_welcom_smile.png')}) no-repeat;
                background-size: 100% auto;
                }
            }
        }
    }


    .deco-fukidashi-bottom-right{
        color:  ${props => props.theme.colors.yellow};
        font-size: ${props => props.theme.fontSize.fukudashi_sp};
        line-height: ${props => props.theme.lineheight.fukudashi_sp};
        font-weight: bold;
        background: #036EB7;
        background: url(${publicPath('images/fukidashi/fukidashiBottomRight_main.png')});
        background-size: 100% 100%;
        padding:20px 10px;
        display: block;
        margin-bottom:calc(26% + 10px);
        margin-top: calc(8% + 10px);
        position: relative;
        z-index: 10;
        &::after{
            content: '';
            width: 100%;
            padding-bottom: 20%;
            position: absolute;
            bottom: 0;
            transform: translateY(98%);
            left: 0;
            background: url(${publicPath('images/fukidashi/fukidashiBottomRight_bottom.png')}) no-repeat;
            background-size: 100% auto;
            
        }
      
        &::before{
            content: '';
            width: 100%;
            aspect-ratio: 726 / 63;
            position: absolute;
            top: 0;
            transform: translateY(-98%);
            left: 0;
            background: url(${publicPath('images/fukidashi/fukidashiBottomRight_top.png')}) no-repeat;
            background-size: 100% auto;
        }
        &.navigatorAnser{
            margin-bottom:calc(68% + 10px);
            &::after{
                content: '';
                width: 100%;
                padding-bottom: 68%;
                position: absolute;
                bottom: 0;
                transform: translateY(98%);
                left: 0;
                background: url(${publicPath('images/fukidashi/fukidashiBottomRight_anser.png')}) no-repeat;
                background-size: 100% auto;
                z-index: 9;
            }
        }
    }

    /*赤吹出し*/
    .red-fukidash-bottom{
        display: block;
        padding: 12px 10px;
        background: ${props => props.theme.colors.red};
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: #fff;
        font-weight: bold;
        position: relative;
        margin-bottom: 6%;
        &::after{
            content: '';
            width: 8%;
            padding-bottom: 6%;
            background: ${props => props.theme.colors.red};
            position: absolute;
            bottom: 0;
            left: 8%;
            transform:translateY(99%);
            clip-path: polygon(76% 0, 0 0, 90% 100%);
        }
    }
    /**
    ============================================
    吹き出し---------END
    ============================================
    **/

    /**
    ============================================
    キャラクター
    ============================================
    **/
    /**
    ======== challenger ========================
    */
    .challenger-excl{
        position: relative;
        width: 100%;
        display: block;
        opacity: 1;
        font-size: 0;
        aspect-ratio: 590 / 504;
        &::after{
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            background: url(${publicPath('images/character/challenger_excl_left_blue.png')}) no-repeat;
            background-size: 100% auto;
            opacity: 1;
        }
    }
    .challenger-q{
        position: relative;
        width: 100%;
        display: block;
        opacity: 1;
        font-size: 0;
        aspect-ratio: 563 / 249;
        &::after{
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
            }
        &.left{
            &::after{
                background: url(${publicPath('images/character/challenger_q_left_blue.png')}) no-repeat;
                background-size: 100% auto;
            }
        }
        &.right{
            &::after{
                background: url(${publicPath('images/character/challenger_q_right_blue.png')}) no-repeat;
                background-size: 100% auto;
            }
        }
        
    }
    .challenger{
        position: relative;
        width: 100%;
        display: block;
        opacity: 1;
        font-size: 0;
        aspect-ratio: 358 / 198;

        &::after{
            content: '';
            width: 60%;
            height: 100%;
            background: url(${publicPath('images/character/v2/challenger_normal.png')}) no-repeat;
            background-size:auto  100%;
            position: absolute;
        }

        &::before{
            content: '';
            width: 40%;
            height: 100%;
            background: url(${publicPath('images/character/v2/action_check_blue.png')}) no-repeat;
            background-size: 100% auto;
            position: absolute;
        }
        &.right{
            &::after{ right: 0; top: 0;}
            &::before{ left: 0; top: 0;}
        }
        &.left{
            &::after{ left: 0; top: 0;}
            &::before{ right: 0; top: 0;}
        }
        &.nice{
            &::before{
                width: 50%;
                background: url(${publicPath('images/character/v2/action_nice_blue.png')}) no-repeat;
                background-size: 100% auto;
            }
        }

        &.guts{
            &::after{
            background: url(${publicPath('images/character/v2/challenger_guts.png')}) no-repeat;
            background-size:auto  100%;
            }
        }
        &.good{
            &::after{
            background: url(${publicPath('images/character/v2/challenger_good.png')}) no-repeat;
            background-size:auto  100%;
            }
        }
    }
     /**
    ======== challenger END =====================
    */

    /**
    ======== navigator  =========================
    */
    .navigator{
        position: relative;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        display: block;
        opacity: 1;
        font-size: 0;
        aspect-ratio: 480 / 317;
        
        &::after{
            content: '';
            width: 40%;
            height: 100%;
            background: url(${publicPath('images/character/v2/navigator_normal.png')}) no-repeat;
            background-size:auto  100%;
            position: absolute;
        }
        &::before{
            content: '';
            width: 60%;
            height: 100%;
            background: url(${publicPath('images/character/v2/action_check_blue.png')}) no-repeat;
            background-size: 100% auto;
            position: absolute;
        }
        &.right{
            &::after{ right: 0; top: 0;}
            &::before{ left: 0; top: 0;}
        }
        &.left{
            &::after{ left: 0; top: 0;}
            &::before{ right: 0; top: 0;}
        }
        &.smile{
            &::after{
            background: url(${publicPath('images/character/v2/navigator_smile.png')}) no-repeat;
            background-size:auto  100%;
            }
        }
        &.pointing{
            &::after{
            background: url(${publicPath('images/character/v2/navigator_pointing.png')}) no-repeat;
            background-size:auto  100%;
            }
        }
        &.good{
            &::after{
            background: url(${publicPath('images/character/v2/navigator_good.png')}) no-repeat;
            background-size:auto  100%;
            }
        }
        
        &.check{
            &::before{
                background: url(${publicPath('images/character/v2/action_check_blue.png')}) no-repeat;
                background-size: 100% auto;
            }
        }
        &.enjoy{
            &::before{
                background: url(${publicPath('images/character/v2/action_enjoy_blue.png')}) no-repeat;
                background-size: 100% auto;
            }
        }
        &.acgood{
            &::before{
                background: url(${publicPath('images/character/v2/action_good_blue.png')}) no-repeat;
                background-size: 100% auto;
            }
        }
        &.nice{
            &::before{
                background: url(${publicPath('images/character/v2/action_nice_blue.png')}) no-repeat;
                background-size: 100% auto;
            }
        }
        &.goodluck{
            &::before{
                background: url(${publicPath('images/character/v2/action_goodluck_blue.png')}) no-repeat;
                background-size: 100% auto;
            }
        }
        &.great{
            &::before{
                background: url(${publicPath('images/character/v2/action_great_blue.png')}) no-repeat;
                background-size: 100% auto;
            }
        }
        &.pickup{
            &::before{
                background: url(${publicPath('images/character/v2/action_pickup_blue.png')}) no-repeat;
                background-size: 100% auto;
            }
        }
    }
   

    .navigator-check{
        position: relative;
        width: 100%;
        display: block;
        opacity: 1;
        font-size: 0;
        aspect-ratio: 480 / 317;
        ::after{
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }
        &.left{
            background: url(${publicPath('images/character/navigator_check_left_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
        &.right{
            aspect-ratio: 480 / 330;
            background: url(${publicPath('images/character/navigator_check_right_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
    }


    .navigator-enjoy{
        position: relative;
        width: 100%;
        display: block;
        opacity: 1;
        font-size: 0;
        aspect-ratio: 530 / 333;
        ::after{
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }
        &.left{
            background: url(${publicPath('images/character/navigator_enjoy_right_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
        &.right{
            background: url(${publicPath('images/character/navigator_enjoy_right_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
    }

    .navigator-good{
        position: relative;
        width: 100%;
        display: block;
        opacity: 1;
        font-size: 0;
        aspect-ratio: 530 / 333;
        ::after{
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }
        &.left{
            background: url(${publicPath('images/character/navigator_good_right_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
        &.right{
            background: url(${publicPath('images/character/navigator_good_right_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
    }
    .navigator-goodluck{
        position: relative;
        width: 100%;
        display: block;
        opacity: 1;
        font-size: 0;
        aspect-ratio: 530 / 333;
        ::after{
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }
        &.left{
            background: url(${publicPath('images/character/navigator_goodluck_right_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
        &.right{
            background: url(${publicPath('images/character/navigator_goodluck_right_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
    }

    .navigator-greate{
        position: relative;
        width: 100%;
        display: block;
        opacity: 1;
        font-size: 0;
        aspect-ratio: 539 / 333;
        ::after{
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }
        &.left{
            background: url(${publicPath('images/character/navigator_greate_right_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
        &.right{
            background: url(${publicPath('images/character/navigator_greate_right_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
    }

    .navigator-nice{
        position: relative;
        width: 100%;
        display: block;
        opacity: 1;
        font-size: 0;
        aspect-ratio: 521 / 333;
        ::after{
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }
        &.left{
            aspect-ratio: 547 / 382;
            background: url(${publicPath('images/character/navigator_nice_left_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
        &.right{
            background: url(${publicPath('images/character/navigator_nice_right_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
    }

    .navigator-picup{
        position: relative;
        width: 100%;
        display: block;
        opacity: 1;
        font-size: 0;
        aspect-ratio: 520 / 346;
        ::after{
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }
        &.left{
            background: url(${publicPath('images/character/navigator_pickup_right_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
        &.right{
            background: url(${publicPath('images/character/navigator_pickup_right_blue.png')}) no-repeat;
            background-size: 100% auto;
        }
    }
    /** =----------------------------------------------------------------------------------------------
     *-------------------------------------------------------------------------------------------------
     * [ PC レイアウト ] 
     *
     ==================================================================================================*/
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        padding: 40px 0px;
        font-size: ${props => props.theme.fontSize.txt_pc};
        line-height:${props => props.theme.lineheight.txt_pc};
        h4{
            font-size: ${props => props.theme.fontSize.h4_pc};
            line-height:  ${props => props.theme.lineheight.h4_pc};
            margin-bottom: 10px;
        }
        &.pc-noTopMargin{
            margin-top: -2%;
            &::before{
                display: none;
            }
        }
    }
    .article-inner{
        max-width: 640px;
    }
`
const ArticleTemplate = (
        {children}:React.PropsWithChildren) => {
    

    return (
        <SArticle>
            <div className='article-inner'>
            {children}
            </div>
        </SArticle>
    )

}

const SEmergencyTemplate = styled(SArticle)`
    background: none;
    &::after,  &::before{display:none}
    padding-top: 0;
    margin-top: 0px;
    min-height: auto;
   .article-inner{
        background: ${props => props.theme.colors.emergencyLight};
        max-width: 720px;
        padding: 20px 20px;
   }
   @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        margin-top:40px;
        .article-inner{    
            padding: 20px 40px;
        }
   }
`
export const ArticleEmergencyTemplate = (
    {children}:React.PropsWithChildren
) => {

    return (
        <SEmergencyTemplate>
            <div className='article-inner'>
            {children}
            </div>
        </SEmergencyTemplate>
    )
}

export default ArticleTemplate
