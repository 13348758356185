import publicPath from "src/utilty/publicPath"
import styled from "styled-components"


const SMoreButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    img{
        transition: transform 0.3s ease; /* アニメーションの効果を指定 */
    }
    &:hover{
        img{
            transform: translateX(10px); /* 左に10px動かす */
        }
    }
    
`
const CheckButton = ({
    onClick,
    ...props
} : {
    onClick : () => void
}) => {

    return (
        <SMoreButton onClick={onClick}>
            <img src={publicPath('images/arrowLinks/common/check.png')} className="img-fit"/>
        </SMoreButton>
    )
}

export default CheckButton