const LangZhTw = () => {

    return (<>
    <h1>網站政策</h1>
    <p>
    FUKUOKA IS OPEN推進事業執行委員會（以下簡稱 執行委員會）提供門戶網站（指https://www.fisop.net內的門戶網站，以下簡稱 門戶網站）。 使用本門戶網站時，請閱讀以下信息。 另外，以下信息有可能在不預先通知的情況下進行更改，敬請諒解。
    </p>
    <p>
    <dl>
    <dt>
    ●免責聲明
    </dt>
    <dd>
    <p>
    「本門戶網站會盡力做好其信息內容，但無法保證內容的準確性和安全性。 對於用戶因本網站信息而遭受的任何損害或損失，執行委員會不承擔任何責任。
    </p>
    <p>
    此外，本門戶網站可能會登載本執行委員會管理以外的其它網站鏈接。這些鏈接網站並非由本執行委員會管理或運營。 請注意，執行委員會對這些網站內容的真偽不承擔任何責任。
    </p>
    <p>
    請注意，本門戶網站上的信息有可能在不預先通知的情況下進行更改或刪除，敬請諒解。
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●關於版權
    </dt>
    <dd>
    除非另有說明，執行委員會或第三方擁有本門戶網站上所有文字、標識、照片、視頻、軟件和所有其它信息的版權，除個人使用等版權法允許的情況外，未經版權所有者事先許可，不得使用這些信息（包括複製、更改、轉載、分發、公開傳播等）。 部分圖片等著作權歸原作者所有。
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●關於使用環境（瀏覽器）
    </dt>
    <dd>
    <p>
    建議使用各種互聯網瀏覽軟件（瀏覽器）的最新版本瀏覽本門戶網站。
    </p>
    <p>
    請注意，使用上述以外的其它方式瀏覽本網站時，可能無法正確顯示。
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●關於經常瀏覽的網頁
    </dt>
    <dd>
    <p>
    互聯網瀏覽軟件（瀏覽器）可能會顯示上次訪問過的信息（暫時存儲在計算機上的信息）。
    </p>
    <p>
    因此，建議更新（重新加載）經常瀏覽頁麵的最新信息。
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●關於 JavaScript
    </dt>
    <dd>
    <p>
    本門戶網站部分使用 JavaScript。 如果您的互聯網瀏覽器不支持 JavaScript 或禁用了 JavaScript，某些功能將無法運行。
    </p>
    <p>
    要使用所有功能，請使用支持 JavaScript 的互聯網瀏覽器，或在互聯網瀏覽器設置中啟用 JavaScript。
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●適用法律
    </dt>
    <dd>
    除另有規定以外，本門戶網站的使用以及 網站政策 的解釋和適用都依據日本法律。<br/>
    訪問本網站是訪問者的自由意誌，請自行承擔使用此門戶網站的風險。 與本網站有關的任何糾紛，均以福岡地方法院為專屬一審法院。
    </dd>
    </dl>
    </p>
    </>)
}

export default LangZhTw