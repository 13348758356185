import theme from "@/themes"
import publicPath from "src/utilty/publicPath"
import styled, { ThemeProvider } from "styled-components"
import Header from "../Organisms/Header"
import Footer from "../Organisms/Footer"
import PCSideLeft from "../Organisms/PCSide/left"
import PCSideRight from "../Organisms/PCSide/right"
import { Copyright } from "@/components/Atoms/Copylight"
import { useEffect, useRef, useState } from "react"

const SWrap = styled.div`
    background: ${props => props.theme.colors.red};
`

const SMain = styled.main`
    max-width: ${props => props.theme.innerMaxWidth};
    background: ${props => props.theme.colors.red};
    background-image: url(${publicPath('images/mainBk.png')});
    background-size: 100% auto;
    overflow-x:hidden;
    min-height: 100vh;
    *{
        white-space: pre-wrap;
    }
    @media screen and (min-width: ${props => props.theme.innerMaxWidth}) {
       width: ${props => props.theme.innerMaxWidth};
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) and 
        (max-width: ${props => props.theme.responsive.pcLargePoint}) {
        width: 414px
    }
`

const SPCSide = styled.aside`
    background: ${props => props.theme.colors.red};
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 0;
    display: none;
    width: calc( (100% - ${props=> props.theme.innerMaxWidth}) / 2);
    &.left{ 
        left: 0;
    }
    &.right{
        
        right: 0;
    }
    &> div{
        background: ${props => props.theme.colors.red};
        background-image: url(${publicPath('images/mainBk.png')});
        background-size: 100% auto;
        position: absolute;
        top: 0;
       
        height: 100%;
        z-index: -1;
        overflow: hidden;
       
        
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) and 
        (max-width: ${props => props.theme.responsive.pcLargePoint}) {
        width: calc( (100% - 414px) / 2);
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        display: block;
    }
`

const FioTemplate = (
    {children}:React.PropsWithChildren) => {
    
 

    return ( 
        <ThemeProvider theme={theme}>
            <SWrap>
                <SPCSide className="left">
                    <PCSideLeft></PCSideLeft>
                </SPCSide>
                <SPCSide className="right">
                    <PCSideRight></PCSideRight>
                </SPCSide>
                <Header></Header>
                <SMain>
                    {children}
                </SMain>
                <Footer></Footer>
            </SWrap>
          
            
        </ThemeProvider>
    )
}

export default FioTemplate

