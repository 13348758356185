
import InnerBox from "@/components/Atoms/Box/innerBox";
import BlackNote from "@/components/Atoms/TextBlock/blackNote";
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote";
import Article from "@/components/Organisms/Article";


const LangJa = () => {

    return (
        <>
            <p className="discpriton">
                福岡県の名産品の一つ「とんこつラーメン」。県内各地に点在するラーメン店ではその店こだわりのラーメンを味合うことができます。「TONKOTSURAMENBEATS」はそんな「とんこつラーメン」をテーマにしたゲーム。どんぶりのリズムをとらえて高得点を刻もう！
            </p>
            <EmphasisNote className="customNote">
                <h4><img src="./images/ramenbeats/howtoplay.webp" alt="how to play"/></h4>
                <section>
                    <h5 className="first">
                    画面下部の<br/>
                    スタートボタンをタップ<br/>
                    （ゲーム画面に遷移します）
                    </h5>
                </section>
                <section>
                    <h5 className="second">
                        <img src="./images/ramenbeats/start.webp" alt="start" width={230}/><br/>
                        をタップ
                    </h5>
                </section>
                <section>
                    <h5 className="third">
                    好きなステージを<br/>タップしてゲームスタート！
                    </h5>
                    <ul className="caption">
                        <li>
                            最新のOSとブラウザでプレイしてください
                        </li>
                        <li>
                            本ゲームはスマホを前提としています
                        </li>
                    </ul>
                </section>
            </EmphasisNote>
            <h4 className="whattonkotsu"><img src="./images/ramenbeats/what_tonkotsu.webp" alt="What’s TONKOTSU RAMEN？"/></h4>
            <p>
            「とんこつ」は「豚の骨」の意味です。とんこつラーメンのスープは豚の骨を長時間煮て作ります。地域やお店によって違いはありますが、白濁しているのが特徴です。麺は細目のストレート麺が主流です。<br/>
            多くのお店は麺を「バリカタ」「カタ」などお好みの硬さにオーダーできます。また、麵だけをお代わりする「替玉」ができるお店も多いのが特徴です。
            </p>
            <ul className="phots"> 
                <li>
                    <img  src="./images/ramenbeats/ramen01.webp" alt="豚骨ラーメン"/>
                </li>
                <li>
                    <img  src="./images/ramenbeats/ramen02.webp" alt="豚骨ラーメン"/>
                </li>
            </ul>
            
        
        </>
    )
}

export default LangJa