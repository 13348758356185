
export function replaceCotents(htmlString: string):string{
    
    let replaceString = replaceEmptyPTagsWithBR(htmlString)
    
    return replaceString

}

function customsCombAnker(htmlString: string){

    htmlString.replace(/<\/span><a href=\\"(.*?)\\">(.*?)<\/a><span class=\\"emphasis-block\\">/g, '<a href="$1" target="_blank">$2</a>');
    htmlString.replace(/<\/span><a href=\\"(.*?)\\" target=\\"_blank\\">(.*?)<\/a><span class=\\"emphasis-block\\">/g, '<a href="$1" target="_blank">$2</a>');
    
    return htmlString
}

function replaceEmptyPTagsWithBR(htmlString: string) {
    // 一時的なDOM要素を作成
    var tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    // tempDiv内のすべての<p>タグを検索し、空のものを<br>に置き換える
    tempDiv.querySelectorAll('p').forEach(function(p) {
        if (p.innerHTML.trim() === '') {
            var br = document.createElement('br');
            p.parentNode.replaceChild(br, p);
        }
    });

    // 加工されたHTMLを文字列として返す
    return tempDiv.innerHTML;
}

//メールアドレスはまたはUrl置換
export function replaceEmailAndUrlWithATag(text) {
    // Regular expression patterns for emails and URLs
    var emailPattern = /[\w.-]+@[\w.-]+/g;
    var urlPattern = /http[s]?:\/\/[\w.-]+/g;

    // Replace emails with <a> tag
    text = text.replace(emailPattern, function(email) {
        return '<a href="mailto:' + email + '">' + email + '</a>';
    });

    // Replace URLs with <a> tag
    text = text.replace(urlPattern, function(url) {
        return '<a href="' + url + '">' + url + '</a>';
    });

    return text;
}

export function removeH1Tags(htmlString) {
    htmlString = htmlString.replace(/&quot;/g, '"');
    // <h1>タグを削除する正規表現
    htmlString = htmlString.replace(/<h1[^>]*>(.*?)<\/h1>/gs, '');

    // .title-largeクラスを持つタグを削除する正規表現
    htmlString = htmlString.replace(/<([^>]*class="[^"]*title-large[^"]*"[^>]*)>(.*?)<\/[^>]+>/gs, '');

    return htmlString;
  }


export function splitAtFirstNewline(str) {
    // Find the index of the first newline character
    let newlineIndex = str.indexOf('\n');
    
    // If there's no newline, return the original string and an empty string
    if (newlineIndex === -1) {
        return [str, ''];
    }

    // Split the string into two parts
    let firstPart = str.substring(0, newlineIndex);
    let secondPart = str.substring(newlineIndex + 1);

    return [firstPart, secondPart];
}

