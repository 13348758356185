
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote";


const LangKo = () => {

    return (
        <>
            <p className="discpriton">
            후쿠오카현 명산품 중 하나인 ‘돈코쓰 라멘’<br/>
            현내 각지 곳곳에 있는 라멘집에서는 그 가게만의 정성 가득한 라멘을 맛볼수 있습니다<br/>
            ‘TONKOTSU RAMEN BEATS’는 그러한 ‘돈코쓰 라멘’을 테마로 한게임<br/>
            그릇의 리듬을 포착하여 고득점을 기록하자!
            </p>
            <EmphasisNote className="customNote">
                <h4><img src="./images/ramenbeats/howtoplay.webp" alt="how to play"/></h4>
                <section>
                    <h5 className="first">
                    화면 하단의 시작 버튼을 탭<br/>
                    (게임 화면으로 이동합니다)
                    </h5>
                </section>
                <section>
                    <h5 className="second">
                        <img src="./images/ramenbeats/start.webp" alt="start" width={230}/><br/>
                        를 탭
                    </h5>
                </section>
                <section>
                    <h5 className="third">
                    좋아하는 스테이지를 탭하여 게임을 시작!
                    </h5>
                    <ul className="caption">
                        <li>
                        최신 OS와 브라우저에서 플레이해 주십시오
                        </li>
                        <li>
                        본 게임은 스마트폰을 전제로 합니다
                        </li>
                    </ul>
                </section>
            </EmphasisNote>
            <h4 className="whattonkotsu"><img src="./images/ramenbeats/what_tonkotsu.webp" alt="What’s TONKOTSU RAMEN？"/></h4>
            <p>
            돈코쓰 라멘의 국물은 돼지 뼈를 오랜 시간 고아 만듭니다<br/>
            지역이나 가게에 따라 차이는 있지만, 빛깔이 뽀얀 것이 특징입니다<br/>
            면은 가늘고 곧은 면이 주를 이룹니다<br/>
            다수의 가게에서는 면의 익힘 정도를 ‘바리카타(매우 딱딱하게)’, ‘가타(딱딱하게)’ 등 취향에 맞게 주문할 수 있습니다<br/>
            또한 면만 추가하는 ‘가에다마’를 선택할 수 있는 가게가 많은 점도 특징입니다
            </p>
            <ul className="phots"> 
                <li>
                    <img  src="./images/ramenbeats/ramen01.webp" alt="豚骨ラーメン"/>
                </li>
                <li>
                    <img  src="./images/ramenbeats/ramen02.webp" alt="豚骨ラーメン"/>
                </li>
            </ul>
            
        
        </>
    )
}

export default LangKo