

import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import Article from "@/components/Organisms/Article"
import { SDecTextUl } from "."

const LangEn = () => {

    return (
        <>
        <Article offset={0}>
        <InnerBox>
        <p>Fukuoka Prefecture is home to many foreigners, and is the 9 most popular city in Japan in terms of the number of foreign residents.</p>
        <h1>Total number of foreign nationals</h1>
        </InnerBox>
        <BlackNote>
        <SDecTextUl className="pc-flex col-2">
        <li>
        <p className="large">
        Total number of foreigners residing in the prefecture
        </p>
        <p className="large">
        <span className="xl">89,518</span>people
        </p>
        <p className="middle">(as of December 2022)</p>
        </li>
        <li>
        <p className="large">
        Number of foreign residents in Fukuoka area
        </p>
        <p className="large">
        <span className="xl">53,009</span>people
        </p>
        <p className="middle">(as of December 2022)</p>
        </li>
        <li>
        <p className="large">
        Number of foreign residents in Kitakyushu area
        </p>
        <p className="large">
        <span className="xl">18,870</span>people
        </p>
        <p className="middle">(as of December 2022)</p>
        </li>
        <li>
        <p className="large">Number of Foreign Residents in Chikugo Area</p>
        <p className="large">
        <span className="xl">12,686</span>people
        </p>
        <p className="middle">(as of December 2022)</p>
        </li>
        <li>
        <p className="large">Number of foreign residents in Chikuho area</p>
        <p className="large">
        <span className="xl">4,953</span>people
        </p>
        <p className="middle">(as of December 2022)</p>
        </li>
        </SDecTextUl>
        </BlackNote>
        <InnerBox top={10} bottom={100}>
        <p className="text-small">Codification: Ministry of Justice Statistics on Foreign Residents</p>
        </InnerBox>
        </Article>
        <div className="navposition01">
        <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
        <Article offset={0}>
        <InnerBox >
        <h1>
        Number of international students
        </h1>
        </InnerBox>

        <BlackNote>
        <SDecTextUl className="pc-flex col-2">
        <li>
        <p className="large">
        Total number of international students in the prefecture
        </p>
        <p className="large">
        <span className="xl">19,188</span>people
        </p>
        <p className="middle">(as of December 2022)</p>
        </li>
        <li>
        <p className="large">
        Number of international students in Fukuoka area
        </p>
        <p className="large">
        <span className="xl">14,800</span>people
        </p>
        <p className="middle">(as of December 2022)</p>
        </li>
        <li>
        <p className="large">
        Number of international students in Kitakyushu area
        </p>
        <p className="large">
        <span className="xl">2,631</span>people
        </p>
        <p className="middle">(as of December 2022)</p>
        </li>
        <li>
        <p className="large">
        Number of international students in Chikugo District
        </p>
        <p className="large">
        <span className="xl">1,650</span>people
        </p>
        <p className="middle">(as of December 2022)</p>
        </li>
        <li>
        <p className="large">
        Number of international students in Chikuho area
        </p>
        <p className="large">
        <span className="xl">107</span>people
        </p>
        <p className="middle">(as of December 2022)</p>
        </li>
        </SDecTextUl>
        </BlackNote>
        <InnerBox top={10} bottom={100}>
        <p className="text-small">Codification: Ministry of Justice Statistics on Foreign Residents</p>
        </InnerBox>
        </Article>
        <div className="navposition01">
        <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
        <Article offset={0}>
        <InnerBox>
        <h1>
        No. of international students
        </h1>
        </InnerBox>
        <BlackNote>
        <SDecTextUl>
        <li>
        <p className="large">
        Number of international students employed by companies, etc. located in the prefecture
        </p>
        <p className="large">
        <span className="xl">1,118</span>people
        </p>
        <p className="middle">(2021)</p>
        </li>
        </SDecTextUl>
        </BlackNote>
    </Article></>
    )
}

export default LangEn