import Article from "@/components/Organisms/Article";
import { langType } from "src/types/lang";
import styled from "styled-components";
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription";




const SPolicy = styled.div`
    p{
        margin-bottom: 30px;
    }
    dl{
        dt{
            font-weight: bold;
            margin-bottom: 3px;
        }
    }
`

const AboutSitePolicyPage = ({lang}:{lang:langType}) => {
    setTitleDiscrption({
        layer:'about',
        lang: lang,
        subLayer:'sitepolicy'
    })
    return (
        <SPolicy>
            <Article className="pc-noTopMargin">
            {lang === 'ja' &&
                <LangJa></LangJa>
            }
            {lang === 'easy_ja' &&
                    <LangJa></LangJa>
            }
            {lang === 'en' &&
                <LangEn></LangEn>
            }
            {lang === 'ko' &&
                <LangKo></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh></LangTh>
            }
            {lang === 'vi' &&
                <LangVi></LangVi>
            }
            {lang === 'ne' &&
                <LangNe></LangNe>
            }
            </Article>
        </SPolicy>
    )
}

export default AboutSitePolicyPage