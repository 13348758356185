import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"

const SImmig = styled.div`
figure{
    figcaption{
        text-align: center;
        padding-top: 10px;
    }
}
`
const ImmigrationbureauPage = ({lang}:{lang:langType}) => {

    setTitleDiscrption({
        layer:'consultation',
        lang:lang,
        subLayer:"immigrationbureau"
    })

    return (
        <SImmig>
        {lang === 'ja' &&
            <LangJa></LangJa>
        }
        {lang === 'easy_ja' &&
                <LangJa></LangJa>
         }
        {lang === 'en' &&
            <LangEn></LangEn>
        }
        {lang === 'ko' &&
            <LangKo></LangKo>
        }
        {lang === 'zh_cn' &&
            <LangZhCn></LangZhCn>
        }
        {lang === 'zh_tw' &&
            <LangZhTw></LangZhTw>
        }
        {lang === 'th' &&
            <LangTh></LangTh>
        }
        {lang === 'vi' &&
            <LangVi></LangVi>
        }
        {lang === 'ne' &&
            <LangNe></LangNe>
        }
        </SImmig>
    )

}

export default ImmigrationbureauPage