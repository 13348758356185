import InnerBox from "@/components/Atoms/Box/innerBox"


const LangKo = () => {

    return (
        <InnerBox bottom={20} left={0} right={0}>
        <h1>
        지역 일본어 교실에 대하여<br/>
        후쿠오카현 내에는 행정 및 자원봉사자가 개최하고 있는 일본어 교실이 100여군데 있습니다.
        </h1>
        <p>
        일본어 교실은 일본어를 배우는 장소만이 아니라 지역에 사는 일본인과의 교류의 장으로서 역할을 담당하고 있습니다.일상생활에서 어려움을 겪고 있는 사항에 대해서도 부담 없이 상담 가능한 교실도 있습니다. 자세한 내용은 아래 그림을 참고하시기 바랍니다.<br/>
        또한 후쿠오카현에서는 후쿠오카에 사는 외국인이 가까운 장소에서 일본어를 배울 수 있는 환경 정비를 위해 일본어 교육 환경 정비 사업을 실시하고 있으며 행정이 운영하는 일본어 교실의 지원이나 자원봉사 양성 강좌등을 개최하고 있습니다.
        </p>
        </InnerBox>
    )
}

export default LangKo