
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { TabTamplate } from "@/components/Templates/tab"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import Article from "@/components/Organisms/Article";
import { MedicalTbody } from "."
import Data from 'src/json/ForeignPatientsMedical/ne.json'

const LangNe = ({onActive, active}) => {
    const medicals = Data.data
    
    return (
        <Article offset={0} className="pc-noTopMargin">
            <InnerBox>
                <h1>विदेशी बिरामीहरू स्वीकार गर्नुहोस् -"आधारभूत चिकित्सा संस्था"</h1>
                <p>
                फुकुओकाप्रान्तमा、विदेशीहरूले ढुक्क साथ चिकित्सा सेवा प्राप्त गर्न सक्ने वातावरण सिर्जना गर्न, बहुभाषिक सेवा  र नगदविहीन सेवा आदि लिन मिल्ने शर्त सहित प्रत्येक माध्यमिक चिकित्सा क्षेत्रमा चिकित्सा संस्थानहरू स्थापना गर्दैछ जसले विदेशी बिरामीहरूलाई स्वीकार गर्ने तेस्ता चिकित्सा संस्था चयन गरिएको छ।
                </p>
                </InnerBox>
                <InnerBox left={0} right={0} bottom={30}>
                <BlackNote>
                <div className="decoration">
                <p className="al-c star">कस्ता प्रकारका चिकित्सा संस्थाहरू छन्?</p>
                <p className="txt-middle">
                फुकुओका प्रान्तमा विदेशी बिरामीहरूलाई स्वीकार गर्ने क्लिनिक चिकित्सा संस्थाहरुको नामावली यस प्रकार छ । (जुन २०२३ सम्म)
                </p>
                <br/>
                <p className="txt-large al-c txt-yellow">
                बहुभाषी OK! नगदरहित OK!
                </p>
                </div>
                </BlackNote>
                <div className="space-deco"></div>
            </InnerBox>
            <InnerBox>
            <TabTamplate>
                <ul className="tabnav-custom">
                     <li><button onClick={() => {onActive(0)}} className={`${active === 0 ? 'active' : ''}`}>Fukuoka City</button></li>
                    <li><button onClick={() => {onActive(1)}} className={`${active === 1 ? 'active' : ''}`}>Fukuoka Region<br/>（Outside Fukuoka City）</button></li>
                    <li><button onClick={() => {onActive(2)}} className={`${active === 2 ? 'active' : ''}`}>Chikugo Area</button></li>
                    <li><button onClick={() => {onActive(3)}} className={`${active === 3 ? 'active' : ''}`}>Chikuhō Area</button> </li>
                    <li><button onClick={() => {onActive(4)}} className={`${active === 4 ? 'active' : ''}`}>Kitakyushu Area</button></li>
                </ul>
            
                <nav className="tab">
                    <div className={`tab-content ${active === 0 ? 'active' : ''}`}>
                        <h3>Fukuoka City</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>शहर/नगर/गाउँको नाम</th>
                            <th rowSpan={2}>चिकित्सा संस्थाको नाम</th>
                            <th colSpan={2}>स्वीकार गर्न सकिने बिरामीहरू</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>प्राप्ति</th>
                            <th rowSpan={2}>उपलब्ध भाषाहरू</th>
                            </tr>
                            <tr>
                            <th>गम्भीर बिरामी</th>
                            <th>सामान्य बिरामी</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[0]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 1 ? 'active' : ''}`}>
                        <h3>Fukuoka Region (Outside Fukuoka City)</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>शहर/नगर/गाउँको नाम</th>
                            <th rowSpan={2}>चिकित्सा संस्थाको नाम</th>
                            <th colSpan={2}>स्वीकार गर्न सकिने बिरामीहरू</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>प्राप्ति</th>
                            <th rowSpan={2}>उपलब्ध भाषाहरू</th>
                            </tr>
                            <tr>
                            <th>गम्भीर बिरामी</th>
                            <th>सामान्य बिरामी</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[1]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 2 ? 'active' : ''}`}>
                        <h3> Chikugo Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>शहर/नगर/गाउँको नाम</th>
                            <th rowSpan={2}>चिकित्सा संस्थाको नाम</th>
                            <th colSpan={2}>स्वीकार गर्न सकिने बिरामीहरू</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>प्राप्ति</th>
                            <th rowSpan={2}>उपलब्ध भाषाहरू</th>
                            </tr>
                            <tr>
                            <th>गम्भीर बिरामी</th>
                            <th>सामान्य बिरामी</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[2]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 3 ? 'active' : ''}`}>
                        <h3>Chikuhō Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>शहर/नगर/गाउँको नाम</th>
                            <th rowSpan={2}>चिकित्सा संस्थाको नाम</th>
                            <th colSpan={2}>स्वीकार गर्न सकिने बिरामीहरू</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>प्राप्ति</th>
                            <th rowSpan={2}>उपलब्ध भाषाहरू</th>
                            </tr>
                            <tr>
                            <th>गम्भीर बिरामी</th>
                            <th>सामान्य बिरामी</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[3]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 4 ? 'active' : ''}`}>
                        <h3>Kitakyushu Area</h3>
                        <table>
                            <thead>
<tr>
                            <th rowSpan={2}>शहर/नगर/गाउँको नाम</th>
                            <th rowSpan={2}>चिकित्सा संस्थाको नाम</th>
                            <th colSpan={2}>स्वीकार गर्न सकिने बिरामीहरू</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>प्राप्ति</th>
                            <th rowSpan={2}>उपलब्ध भाषाहरू</th>
                            </tr>
                            <tr>
                            <th>गम्भीर बिरामी</th>
                            <th>सामान्य बिरामी</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[4]}></MedicalTbody>
                        </table>
                    </div>
                   
                </nav>
            </TabTamplate>
            <br/>
            <p className="txt-small">
                *(नोट १) यसले विदेशी बिरामीहरूलाई चिकित्सा संस्थानहरूको सूचीमा समावेश नगरिएका मेडिकल संस्थाहरूमा उपचार गर्नबाट रोक्दैन। (नोट 2) सूचीमा सूचीबद्ध चिकित्सा संस्थाहरू चिकित्सा उपचारको लागि विदेशबाट जापानमा आउने विदेशी बिरामीहरूलाई स्वीकार गर्ने चिकित्सा संस्थाहरू होइनन्। बिरामीहरू स्वीकार गर्नुहोस् -"आधारभूत चिकित्सा संस्था"<br/>
                *१ JMIP (Japan Medical Service Accreditation for International Patients) → विदेशी बिरामीहरूलाई स्वीकार गर्ने चिकित्सा संस्थाहरूको लागि प्रमाणीकरण प्रणाली<br/>
                विदेशी बिरामीहरूको चेकजाचमा योगदान पुर्‍याउने स्वदेशी चिकित्सा संस्थाहरूलाइ, तेस्रो-पक्ष मूल्याङ्कन प्रणाली मार्फत, जस्तै बहुभाषी चिकित्सा मार्गदर्शन र प्रतिक्रियाहरू जसले विभिन्न संस्कृति र धर्महरूलाई ध्यानमा राख्छ, हामी सुरक्षित र राम्रो चिकित्सा सेवाहरू प्रदान गर्न सक्षम हुनेछौं।यस प्रमाणीकरणको उद्देश्य चिकित्सा हेरचाहको लागि एक प्रणालीको निर्माणलाई समर्थन गर्नु हो, र स्वास्थ्य, श्रम र कल्याण मन्त्रालयले 2012 मा स्थापना गरेको थियो (प्रमाणीकरण निकाय: जापान चिकित्साशास्त्र र शिक्षा फाउन्डेसन)।<br/>
                *२ २१ भाषाहरूलाई समर्थन गर्ने चिकित्सा संस्थाहरूको लागि,「 फुकुओका अन्तर्राष्ट्रिय चिकित्सा सहायता केन्द्र」 प्रयोग गर्नुहोस्।
                </p>
                <NavigatorAction dir="right" charactorType="normal" actionType="pickup"/>
                <EmphasisNote>
                <h1 className="c-h1">थप जानकारी चाहनुहुन्छ?</h1>

                <p>
                राष्ट्रव्यापी रूपमा प्रमुख चिकित्सा संस्थानहरू स्वास्थ्य, श्रम र कल्याण मन्त्रालय र जापान पर्यटन एजेन्सी (तल URL) को वेबसाइटहरूमा प्रकाशित छन्।<br/>
                </p>
                <p>
                स्वास्थ्य, श्रम तथा कल्याण मन्त्रालय HP<br/>
                <a href="https://www.mhlw.go.jp/stf/newpage_05774.html" target="_blank">
                https://www.mhlw.go.jp/stf/newpage_05774.html
                </a><br/>
                </p>
                <p>
                पर्यटन एजेन्सी HP<br/>
                <a href="https://www.jnto.go.jp/emergency/jpn/mi_guide.html" target="_blank">
                https://www.jnto.go.jp/emergency/jpn/mi_guide.html
                </a>
                </p>

                <p>
                यो एक वेबसाइट हो जसले फुकुओका प्रान्तमा अस्पताल,क्लिनिक,डेन्टल क्लिनिक,बाल अस्पताल,मिडवाइफरी केन्द्र को खोज,बाल रोग आपतकालीन,सुइ (रोकथाम),स्वास्थ्य,कल्याण चिकित्सा जानकारी प्रदान गर्दछ।<br/>
                </p>
                <p>
                फुकुओका मेडिकल सूचना नेटवर्क<br/>
                <a href="https://www.fmc.fukuoka.med.or.jp/" target="_blank">
                https://www.fmc.fukuoka.med.or.jp/
                </a>
                </p>
                </EmphasisNote>
                </InnerBox>
        </Article>
    )
}

export default LangNe