import { PropsWithChildren } from "react"
import { direction } from "src/types/common"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"



const SFukidashi = styled.div`
    background: ${props => props.theme.colors.red};
    padding:12px 10px;
    color: #fff;
    font-size:  ${props => props.theme.fontSize.middle_sp};
    line-height: ${props => props.theme.lineheight.middle_sp};
    font-weight: bold;
    position: relative;
    &.w100{
        width: 100%;
    };
   
`

export const FukidashiRedLeft = ({
    children, ...props }:PropsWithChildren<{className?:string}>) => {

    const SFukidashiLeft = styled(SFukidashi)`
        margin-bottom: 12%;
        &::after{
            content: '';
            width: 20%;
            aspect-ratio: 10 / 5;
            position: absolute;
            bottom: 0;
            left: 10%;
            background: url(${publicPath('images/fukidashi/fukidashi_red_bottom_left.png')}) no-repeat;
            background-size: auto 100%;
            transform: translateY(98%);
        }
        @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
            margin-bottom: 3%;
            padding: 30px 10px;
            &::after{
                aspect-ratio: 10 / 2;
            }
        }
    `

    return (
       <SFukidashiLeft className={props.className}>
            {children}
       </SFukidashiLeft>
    )
}