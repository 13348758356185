import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import InnerBox from "@/components/Atoms/Box/innerBox"

const LangJa = () => {

    return (
        <>
        <div className="pc-wapper">
        <InnerBox>
            <p>
                国際交流が盛んな福岡県には、多くの領事館があるほか、世界各国の都市と姉妹都市になっています。
            </p>
            <h1>
                領事館一覧
            </h1>
        </InnerBox>
        <BlackNote>
            <ul className="deco">
                <li>
                    <p className="name">
                    在福岡アメリカ合衆国領事館<br/>（1952.4.28設置）
                    </p>
                    <p className="address">
                        〒810-0052 福岡市中央区大濠2丁目5番26
                    </p>
                    <p className="tel">
                    TEL.092-751-9331
                    </p>
                </li>
                <li>
                    <p className="name">
                    在福岡大韓民国総領事館<br/>（1966.1.27設置）
                    </p>
                    <p className="address">
                        〒810-0065 福岡市中央区地行浜1丁目1-3
                    </p>
                    <p className="tel">
                    TEL.092-771-0461
                    </p>
                </li>
                <li>
                    <p className="name">
                    在福岡中華人民共和国総領事館<br/>（1985.4.17設置）
                    </p>
                    <p className="address">
                    〒810-0065 福岡市中央区地行浜1丁目3-3
                    </p>
                    <p className="tel">
                    TEL.092-713-1121
                    </p>
                </li>
                <li>
                    <p className="name">
                    在福岡ベトナム社会主義共和国<br/>（2009.4.22設置）
                    </p>
                    <p className="address">
                    〒810-0801 福岡市博多区中洲5-3-8<br/>アクア博多4階
                    </p>
                    <p className="tel">
                    TEL.092-263-7668
                    </p>
                </li>
                <li>
                    <p className="name">
                    在福岡タイ王国総領事館<br/>（2018.10.1設置）
                    </p>
                    <p className="address">
                    〒810-0001 福岡市中央区天神4-1-37<br/>第1明星ビル2
                    </p>
                    <p className="tel">
                    TEL.092-751-9331
                    </p>
                </li>
            </ul>
        </BlackNote>
        </div>
        <div className="checkpostion01 sp-only">
            <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
        <div className="pc-list-wrap">
            <InnerBox bottom={20}>
                <h1>姉妹都市一覧</h1>
                <EmphasisNote>
                    <h2>
                    <span>ハワイ州(米国)</span><span> 昭和56.9.25</span>
                    </h2>
                    <p>
                    昭和55年9月、アロハウィークフェスティバルに「博多祇園山笠」が参加し、また時を同じくして「福岡物産展」が開催され両県州の友好促進の気運が盛り上がり、それぞれの議会において姉妹関係締結促進の議決がなされた。これを受けて、一年間にわたる姉妹提携の話し合いを続け、昭和56年9月25日ハワイ州ホノルル市でアリヨシ・ハワイ州知事と亀井福岡県知事が調印式を行った。
                    </p>
                </EmphasisNote>
            </InnerBox>
            <InnerBox bottom={20}>
                <EmphasisNote>
                    <h2>
                    <span>江蘇省(中国)</span><span> 平成4.11.4</span>
                    </h2>
                    <p>
                    平成4年4月中国共産党・江沢民総書記来県の際、知事が日中国交正常化20周年に当たる本年度内に中国との友好提携実現を目指すことを表明。
                    これを受けて行政、議会、民間からなる友好提携検討委員会を設立。調査団の派遣、協議等の結果、江蘇省との友好提携を決定。<br/>平成4年9月に友好提携の申込みを行い、同年11月4日江蘇省南京市で陳江蘇省長と奥田福岡県知事が調印式を行った。
                    </p>
                </EmphasisNote>
            </InnerBox>
            <InnerBox bottom={20}>
                <EmphasisNote>
                    <h2>
                    <span>バンコク都(タイ王国)</span><span> 平成18.2.8</span>
                    </h2>
                    <p>
                    アジアとの関係をさらに幅広く深めていくため、アジア諸地域の中で、本県の主要産業である自動車産業を中心に発展著しいタイと交流を行うこととした。中でも政治、経済、文化の中心地であるバンコク都を候補地域として決定した。２００５年１０月に調査団を派遣し、アピラック・バンコク都知事と面談を行い、交流の意向を確認したところ、積極的な回答が得られたため、２００６年２月に麻生福岡県知事を団長とする訪問団がバンコク都を訪れ友好提携協定書に署名を行った。
                    </p>
                </EmphasisNote>
            </InnerBox>
            <InnerBox bottom={20}>
                <EmphasisNote>
                    <h2>
                    <span>デリー準州（インド）</span><span> 平成19.3.5</span>
                    </h2>
                    <p>
                    アジアとの関係をさらに幅広く深めていくため、アジア諸地域の中で、最先端のIT、自動車産業を中心に発展著しいインドと交流を行うこととした。中でも政治、経済、文化の中心地であるデリー準州を候補地域として決定した。２００５年１０月に調査団を派遣、２００６年２月に麻生福岡県知事を団長とする訪問団がデリー準州を訪れ、ディクシット・デリー準州首席大臣と面談を行い、友好交流について前向きな意見交換を行った。インド政府の承認を得、２００６年１２月に日印首脳会談において本県とデリー準州の友好提携を歓迎する旨、共同声明に盛り込まれたのを受けて、２００７年３月に友好提携を締結した。
                    </p>
                </EmphasisNote>
            </InnerBox>
            <InnerBox bottom={20}>
                <EmphasisNote>
                    <h2>
                    <span>ハノイ市（ベトナム）</span><span> 平成20.2.22提携</span>
                    </h2>
                    <p>
                    アジアとの関係をさらに幅広く深めていくため、アジア諸地域の中で、豊富な労働力と勤勉な国民性等により目覚しい成長を遂げているベトナムと交流を行うこととした。中でも政治、経済、文化の中心地であるハノイ市を候補地域として決定した。２００５年１０月に調査団を派遣後、実務レベルでの交渉を続け、２００８年２月に麻生福岡県知事を団長とする訪問団がハノイ市を訪れて友好提携を締結した。
                    </p>
                </EmphasisNote>
            </InnerBox>
            <InnerBox bottom={20}>
                <EmphasisNote>
                    <h2>
                    <span>韓国南岸の一市三道と</span><span>九州北部三県及び山口県</span><span> 平成4.8.25</span><span>共同宣言文発表</span>
                    </h2>
                    <p>
                    日韓両国は地理的に近い位置にあり、古来から交流が盛んであったが、特に日本の九州北部三県（福岡県、佐賀県、長崎県）と韓国南岸一市三道（釜山広域市、全羅南道、慶尚南道、済州特別自治道）は、日韓海峡を挟んで交流の窓口として大きな役割を果たしてきた。<br/>このような地理的・歴史的背景のなかで、1990年10月、長崎県対馬で九州北部三県知事懇話会が開催され、両地域間の交流促進について議論された。その議論を受けて韓国側と協議した結果、1992年7月両地域間の交流の現状と今後の展望に関し、率直な意見交換を行うとともに、様々な分野における交流を促進するための首長交流会議の開催について合意が成立し、会議の名称を「日韓海峡沿岸県市道知事交流会議」とすることとなった。<br/>
                    　1992年8月韓国済州道において「第1回日韓海峡沿岸県市道知事交流会議」が開催され、日韓海峡沿岸両地域の交流を促進するための共同事業の実施と知事交流会議の定期的開催についての共同宣言文を発表した。<br/>
                    　1999年度から山口県が参加した。<br/>
                    　2008年度から「日韓海峡沿岸県市道交流知事会議」に改称。
                    </p>
                </EmphasisNote>
            </InnerBox>
        </div>
        <InnerBox bottom={20}>
            <div className="pc-navigator">
                <NavigatorCheck dir="right"></NavigatorCheck>
            </div>
        </InnerBox>
        </>
    )
}

export default LangJa