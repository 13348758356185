import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlueLeft } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { NavigatorFukidashiCheck } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"

const LangZhCn = () => {

    return (<>
    <InnerBox top={0} bottom={30} >
    <h1>关于公益财团法人福冈县国际交流中心</h1>
    <p>
    公益财团法人福冈县国际交流中心设立于平成元年，旨在充分利用福冈县地理和历史特点，通过与县内交流组织等的合作，旨在推动以县民为主体的国际交流，提高福冈县在国际交流中的基地性质的同时，加强与包括亚洲国家和地区在内的世界各国和地区的交流，进而促进相互繁荣和全球和平。
    </p>
    <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
    <InnerBox top={20} bottom={20} left={0} right={0}>
    <EmphasisNote>

    <p>
    为了促进县内居民和在福冈居住的外国人之间的交流，举办了各种讲座、研讨会等多样活动。除此之外，针对在日外国人遇到的各种问题，开设了「福冈县外国人咨询中心」,其中还招募了志愿者，提供包括日语在内的22种语言的服务。同时也提供灾害相关的信息和其他生活相关的信息。
    </p>
    <p>
    在与海外的交流上，福冈县与夏威夷州（美国）、江苏省（中国）、曼谷都（泰国）等地建立了友好合作关系，并正在推动着双方的交流。
    </p>
    <p>
    此外，通过派遣在县内学校和公民馆内居住的外国人等担任教师，实施国际理解教育。与此同时，对民间国际交流组织进行的基层交流活动，社区内日语教室的开展也进行了支援。
    </p>
    <p>
    此外，福冈县还担任由产业、学术和政府部门设立的留学生支援中心的事务局，为留学生提供全面的支援。
    </p>
    </EmphasisNote>
    </InnerBox>
    <p>
    【联系方式】<br/>
    〒810-0001<br/>
    福冈县福冈市中央区天神1丁目1番1号Acros福冈 8F／3F<br/>
    8F　TEL：092-725-9204　FAX：092-725-9205 8:30~17:45（周末・公共休假日除外）<br/>
    3F(国际广场)　TEL：092-725-9200　FAX：092-725-9206 10:00~18:30（年末年始除外） <br/>
    <br/>
    </p>
    <div className="custom-link">
    <FukidashiRedLeft>关于公益财团法人福冈县国际交流中心</FukidashiRedLeft>

    <a href="https://kokusaihiroba.or.jp/" target="_blank">
    <img src={publicPath('images/fief.png')} className="img-fit" alt="关于公益财团法人福冈县国际交流中心"/>
    </a>
    </div>

    </InnerBox>
    <InnerBox top={20} bottom={20}>
    <h1>
    福冈县外国人咨询中心
    </h1>

    <div className="sp-only">
    <NavigatorFukidashiCheck dir="left">
    <span className="txt-yellow">
    在福冈的日常生活中有没有什么烦恼呢？ <br/>
    在外国人咨询中心我们将通过多语言提供帮助大家解决烦恼
    </span>
    </NavigatorFukidashiCheck>
    </div>
    <br/>

    <p>
    在对外国人的生活支援中，“福冈县外国人咨询中心”在雇用，劳动，医疗，住宅，教育等的日常生活烦恼，在留手续以及法律咨询等的诸多方面提供共计22种语言的咨询服务。
    </p>
    <div className="pc-only">
        <div className="pc-flex col-2">
            <div>
                <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
            </div>
            <div className="center">
                <FukidashiBlueLeft>
                    <span className="txt-yellow">
                    在福冈的日常生活中有没有什么烦恼呢？ <br/>
                    在外国人咨询中心我们将通过多语言提供帮助大家解决烦恼
                    </span>
                </FukidashiBlueLeft>
            </div>
        </div>
    </div>


    <br/>

    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi navigatorCheck right pointing">
            如果遇到了想要了解或是感到烦恼的事情，请随时通过电话或者到窗口进行咨询。 <br/>
            我们提供诸多语言的咨询服务。（免费）
            </div>
        </div>
        <div>
            <figure>
                <img src={publicPath('images/international01.png')} className="img-fit" alt=""/>
            </figure>
            <figure className="pc-only">
                <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
            </figure>
        </div>
    </div>

    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi challengerQ left thinking">
            我想延长在日本的在留时间。我应该怎么办呢？
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right puzzled">
            有没有能够用我的国家的语言提供服务的医院呢？
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            我与房东发生了一些冲突。我应该去哪里寻求帮助呢？
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            我需要住民票，请问哪里可以获得呢？
            </div>
        </div>
    </div>

    
    <figure>
    <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
    </figure>
    <InnerBox top={20} bottom={20} left={0} right={0}>
    <EmphasisNote>
    <h2>
    可提供咨询服务的语种
    </h2>
    <p>
    日语、英语、中文、韩语、越南语、尼泊尔语、印度尼西亚语、他加禄语、泰语、马来语、西班牙语、法语、德语、意大利语、俄语、葡萄牙语、缅甸语、高棉语、蒙古语、僧伽罗语、印地语、孟加拉语
    </p>
    </EmphasisNote>
    </InnerBox>
    <p>
    福冈市中央区天神1-1-1 ACROS 福冈3F <br/>
    （公财）福冈县国际交流中心内 ０<br/>
    １２０－２７９－９０６
    </p>
    <FukidashiRedLeft>
    链接地址
    </FukidashiRedLeft>
    <div className="pc-flex col-2">
        <p>
            <a href="https://kokusaihiroba.or.jp/" target="_blank">
                <img src={publicPath('images/fief.png')} className="img-fit" alt="公益財団法人福岡県国際交流センター"/>
            </a>
        </p>
        <p>
            <a href="https://www.fissc.net/" target="_blank">
                <img src={publicPath('images/ficc.png')} className="img-fit" alt="FiSSC"/>
            </a>
        </p>
    </div>

    <NavigatorAction actionType="goodluck" charactorType="good" dir="right"></NavigatorAction>

    </InnerBox>
    </>)
}

export default LangZhCn