import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlueLeft } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { NavigatorFukidashiCheck } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"


const LangVi = () => {

    return (<>
    <InnerBox top={0} bottom={30} >
    <h1>Trung tâm Giao lưu Quốc tế tỉnh Fukuoka</h1>
    <p>
    Nhằm mục đích tăng cường giao lưu với các khu vực, quốc gia trên thế giới bắt đầu từ các khu vực, quốc gia châu Á đồng thời góp phần cống hiến cho hòa bình thế giới và sự thịnh vượng chung. Trung tâm Giao lưu Quốc tế tỉnh Fukuoka được thành lập vào năm 1989 để tăng cường giao lưu quốc tế trong người dân tỉnh thông qua việc phát huy đặc điểm về địa lý và lịch sử của tỉnh Fukuoka và hợp tác với các đoàn thể giao lưu trong tỉnh, nâng cao tính trọng điểm của tỉnh Fukuoka trong lĩnh vực giao lưu quốc tế.    </p>
    <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
    <InnerBox top={20} bottom={20} left={0} right={0}>
    <EmphasisNote>

    <p>
    Nhằm tạo cơ hội giao lưu giữa người dân địa phương và cư dân nước ngoài, tỉnh tổ chức nhiều sự kiện khác nhau như diễn thuyết, hội thảo...Tỉnh còn vận hành "Trung tâm tư vấn cho người nước ngoài ở tỉnh Fukuoka" hỗ trợ giải quyết các vấn đề của cư dân nước ngoài bằng 22 ngôn ngữ trong đó có tiếng Nhật, tuyển tình nguyện viên và cung cấp thông tin liên quan đến cuộc sống hàng ngày như thông tin về thảm họa.
    </p>
    <p>
    Trong việc giao lưu với nước ngoài, tỉnh Fukuoka có nhiều giao lưu với với Hawaii (Mỹ), tỉnh Giang Tô (Trung Quốc) và Bangkok (Thái Lan)…là những quốc gia đã thiết lập quan hệ hữu nghị với tỉnh.
    </p>
    <p>
    Ngoài ra, song song với chương trình giáo dục hiểu biết quốc tế phái cử người nước ngoài trong tỉnh đến các trường học và các trung tâm cộng đồng địa phương giới thiệu về nước ngoài thì tỉnh còn hỗ trợ các hoạt động giao lưu của tổ chức phi lợi nhuận và các lớp học tiếng Nhật ở địa phương.
    </p>
    <p>
    Hơn nữa, văn phòng điều hành Trung tâm hỗ trợ du học sinh tỉnh Fukuoka được thiết lập bởi các tổ chức sản xuất, giáo dục và quản lý công đang tiến hành hỗ trợ toàn diện cho du học sinh.
    </p>
    </EmphasisNote>
    </InnerBox>
    <p>
    【Thông tin liên hệ】<br/>
    〒810-0001<br/>
    Tầng 3/ tầng 8 toà nhà Acros Fukuoka, 1-1-1 Tenjin, quận Chuo, thành phố Fukuoka, tỉnh Fukuoka<br/>
    Tầng 8: TEL：092-725-9204　FAX：092-725-9205 8:30~17:45（trừ thứ 7, CN và ngày lễ）<br/>
    Tầng 3 (Kokusai Hiroba) TEL：092-725-9200　FAX：092-725-9206 10:00~18:30（trừ ngày nghỉ lễ cuối năm và đầu năm） <br/>
    <br/>
    </p>
    <div className="custom-link">
    <FukidashiRedLeft>Trung tâm Giao lưu Quốc tế tỉnh Fukuoka</FukidashiRedLeft>

    <a href="https://kokusaihiroba.or.jp/" target="_blank">
    <img src={publicPath('images/fief.png')} className="img-fit" alt="Trung tâm Giao lưu Quốc tế tỉnh Fukuoka"/>
    </a>
    </div>

    </InnerBox>
    <InnerBox top={20} bottom={20}>
    <h1>
    Trung tâm tư vấn cho người nước ngoài tại tỉnh Fukuoka
    </h1>
    
    <div className="sp-only">
    <NavigatorFukidashiCheck dir="left">
    <span className="txt-yellow">
    Anh/chị có gặp khó khăn trong cuộc sống ở Fukuoka không?<br/>
    Trung tâm tư vấn cho người nước ngoài sẽ giúp giải quyết mọi vấn đề mà anh/chị gặp phải bằng nhiều ngôn ngữ.
    </span>
    </NavigatorFukidashiCheck>
    </div>

    <br/>
    <p>
    Trong hỗ trợ cuộc sống cho người nước ngoài, Trung tâm tư vấn cho người nước ngoài tại tỉnh Fukuoka đang hỗ trợ bằng 22 thứ tiếng với nhiều nội dung tư vấn như khó khăn trong cuộc sống bao gồm tìm việc,
    </p>
    <div className="pc-only">
        <div className="pc-flex col-2">
            <div>
                <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
            </div>
            <div className="center">
                <FukidashiBlueLeft>
                    <span className="txt-yellow">
                    Anh/chị có gặp khó khăn trong cuộc sống ở Fukuoka không?<br/>
                    Trung tâm tư vấn cho người nước ngoài sẽ giúp giải quyết mọi vấn đề mà anh/chị gặp phải bằng nhiều ngôn ngữ.
                    </span>
                </FukidashiBlueLeft>
            </div>
        </div>
    </div>

    <br/>
    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi navigatorCheck right pointing">
            lao động, y tế, nhà ở, giáo dục...cũng như hỗ trợ các thủ tục lưu trú và tư vấn pháp lý…<br/>
            Nếu có điều gì Anh/chị muốn biết hoặc gặp khó khăn, đừng ngần ngại gọi điện thoại hoặc đến trực tiếp quầy tư vấn. Bạn có thể tư vấn bằng nhiều ngôn ngữ khác nhau.（Miễn phí）
            </div>
        </div>
        <div>
            <figure>
                <img src={publicPath('images/international01.png')} className="img-fit" alt=""/>
            </figure>
            <figure className="pc-only">
                <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
            </figure>
        </div>
    </div>

    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi challengerQ left thinking">
            I want to extend my period of stay in Japan. What should I do?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right puzzled">
            Are there any hospitals that can help me in my own language?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            I am having trouble with my landlord. Where can I talk to someone about this?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            Where can I get a certificate of residence?
            </div>
        </div>
    </div>
    
    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi challengerQ left thinking">
            Tôi muốn gia hạn thời gian lưu trú ở Nhật Bản. Tôi phải làm thế nào? 
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right puzzled">
            Có bệnh viện nào hỗ trợ ngôn ngữ của quốc gia tôi không?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            Tôi gặp vấn đề với chủ nhà. Tôi có thể xin tư vấn ở đâu?
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            Tôi cần giấy đăng ký cư trú. Tôi có thể lấy ở đâu?
            </div>
        </div>
    </div>
    
 
    <figure>
    <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
    </figure>
    <InnerBox top={20} bottom={20} left={0} right={0}>
    <EmphasisNote>
    <h2>
    Ngôn ngữ hỗ trợ
    </h2>
    <p>
    Tiếng Nhật, Tiếng Anh, Tiếng Trung, Tiếng Hàn, Tiếng Việt, Tiếng Nepal, Tiếng Indonesia, Tiếng Tagalog, Tiếng Thái, Tiếng Mã Lai, Tiếng Tây Ban Nha, Tiếng Pháp, Tiếng Đức, Tiếng Ý, Tiếng Nga, Tiếng Bồ Đào Nha, Tiếng Miến Điện, Tiếng Khmer, Tiếng Mông Cổ, Tiếng Sinhala, Tiếng Hindi, Tiếng Bengal
    </p>
    </EmphasisNote>
    </InnerBox>
    <p>
    Tấng 3 Acros Fukuoka, 1-1-1, Tenjin, quận Chuo, thành phố Fukuoka<br/>
    Trong Trung tâm Giao lưu Quốc tế tỉnh Fukuoka<br/>
    Điện thoại: 0120-279-906
    </p>
    <FukidashiRedLeft>
    Đường link
    </FukidashiRedLeft>
    <div className="pc-flex col-2">
        <p>
            <a href="https://kokusaihiroba.or.jp/" target="_blank">
                <img src={publicPath('images/fief.png')} className="img-fit" alt="公益財団法人福岡県国際交流センター"/>
            </a>
        </p>
        <p>
            <a href="https://www.fissc.net/" target="_blank">
                <img src={publicPath('images/ficc.png')} className="img-fit" alt="FiSSC"/>
            </a>
        </p>
    </div>
    <NavigatorAction actionType="goodluck" charactorType="good" dir="right"></NavigatorAction>
    </InnerBox>
    </>)
}

export default LangVi