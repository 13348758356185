export const articlefontSizes = {
    txt_sp: '1.6rem',
    txt_pc: '1.7rem',

    top_mes_sp : '2.4rem',
    top_mes_pc : '2.4rem',
    
    topSection_pc : '3.0rem',

    fukudashi_pc: '3.0rem',
    fukudashi_sp: '2.0rem',

    h1_pc:'2.2rem',
    h2_pc:'2.2rem',
    
    h3_pc:'2.5rem',
    h4_pc:'2.4rem',
    h5_pc:'2.2rem',

    h1_sp:'2.0rem',
    h2_sp:'2.2rem',
    h3_sp:'2.0rem',
    h4_sp:'2.0rem',
    h5_sp:'1.9rem',

    large_sp : '4.0rem',
    large_pc : '4.0rem',

    middle_sp : '1.9rem',
    middle_pc : '2.8rem',
}


export const articleLineHeight = {
    txt_sp: '2.3rem',
    txt_pc: '2.5rem',

    fukudashi_pc: '3.4rem',
    fukudashi_sp: '3.0rem',

    top_mes_sp : '3.0rem',
    top_mes_pc : '3.0rem',

    h1_pc:'2.8rem',
    h2_pc:'2.8rem',
    h3_pc:'2.5rem',
    h4_pc:'2.8rem',
    h5_pc:'2.2rem',

    h1_sp:'2.8rem',
    h2_sp:'2.5rem',
    h3_sp:'2.0rem',
    h4_sp:'2.0rem',
    h5_sp:'1.9rem',

    large_sp : '4.5rem',
    large_pc : '4.2rem',

    middle_sp : '2.4rem',
}