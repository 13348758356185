import styled from "styled-components"
import publicPath from "src/utilty/publicPath"
import HeadLine from "@/components/Atoms/HeadLine"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { langType } from "src/types/lang"
import getLayer from "src/utilty/getLayer"
import { splitAtFirstNewline } from "src/utilty/replaceContents"
import { PathAnalysisValue } from "src/utilty/pathAnalyzer"
import { tsutaeruToEastJa } from "src/utilty/easyJaCheker"
import { TopSectionCotainerTemplate } from "@/components/Templates/Responsive/topSectionContainer"


const SSection = styled.section`
    position: relative;
    background:url(${publicPath('images/top/initiativesBk.png')}) no-repeat;
    background-size:100% auto;
    background-position: bottom;
    width: 100%;
    height: 100%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        background: url('images/pc/bk_intiatives.webp') no-repeat;
        background-size: auto 100%;
    }
`

const SHeadline = styled.h2`
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    top:0;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 400px;
        transform: translate(-50%,-20%);
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 600px;
    }
`

const SMessagePosion = styled.div`
    font-size: ${props => props.theme.fontSize.top_mes_sp};
    color :#fff;
    font-weight: bold;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 17%;
    span{
        padding-top: 6px;
        display: block;
        font-size: ${props => props.theme.fontSize.txt_sp};
        line-height: ${props => props.theme.lineheight.txt_sp};
        font-weight: bold;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        font-size: ${props => props.theme.fontSize.topSection_pc};
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        top: 20%;
    }
`

const SLinks =  styled.ul`
    position: absolute;
    top: 35%;
    width: ${props => props.theme.topPage.links.ulWidth};
    left: 50%;
    transform: translateX(-50%);
    li{
        width: ${props => props.theme.topPage.links.liWidth};
        margin-top: -8px;
        &:nth-child(2n){
            margin-top: ${props => props.theme.topPage.links.oddMarginTop};
            margin-left: ${props => props.theme.topPage.links.oddOffest};
        }
    }
    @media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
    (max-width: ${props => props.theme.responsive.minPc}) ),
    screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
        top: 33%;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        display: flex;
        flex-wrap: wrap;
        top:40%;

        li{
            flex-basis: 25%;
            margin: 0;
            transform: rotate(2deg);
            &:nth-child(2n){
                margin: 0;
            }
        }
    }
`

const InitiaivesSeciton = ({pathValue}:{pathValue: PathAnalysisValue}) => {
    const layer = getLayer('initiatives')
    const title = splitAtFirstNewline(layer[pathValue.lang].title);

    const allowLang = tsutaeruToEastJa(pathValue)
    return (
        <SSection>
            <TopSectionCotainerTemplate>
            <SHeadline>
                <HeadLine lang="ja" nameKey="initiatives"></HeadLine>
            </SHeadline>
            <SMessagePosion>
                {title[0]}
                { title[1] &&<span> {title[1]} </span>}
            </SMessagePosion>
            <SLinks>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/Initiatives/sports?lang=${pathValue.lang}`}
                        linkKey="sport"
                    />
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/Initiatives/industry?lang=${pathValue.lang}`}
                        linkKey="sangyo"
                    />
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/Initiatives/sightseeing?lang=${pathValue.lang}`}
                        linkKey="kanko"
                    />
                </li>
                <li><YellowArrowLink 
                       lang={allowLang}
                        href={`/Initiatives/health?lang=${pathValue.lang}`}
                        linkKey="kenko"
                    />
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/Initiatives/environment?lang=${pathValue.lang}`}
                        linkKey="kankyo"
                    />
                </li>
            </SLinks>
            </TopSectionCotainerTemplate>
        </SSection>    
    )
}

export default InitiaivesSeciton