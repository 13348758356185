import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangVi = () => {

    return (
        <Article>
        <p>
        Cục quản lí xuất nhập cảnh Fukuoka là nơi tiến hành các thủ tục khác nhau liên quan đến danh nghĩa cư trú.<br/>
        (Gia hạn thời gian lưu trú, thay đổi tư cách lưu trú, xét duyệt vĩnh trú, thủ tục Giấy phép tham gia các hoạt động khác ngoài tư cách cư trú để làm việc bán thời gian, thủ tục bảo lãnh người nước ngoài từ nước ngoài vào Nhật...)<br/>
        　Cục thiết lập Trung tâm tư vấn thông tin tổng hợp về lưu trú cho người nước ngoài với vai trò là Cổng tư vấn có nghĩa vụ hướng dẫn thủ tục nhập cảnh như danh nghĩa cư trú...Trong những năm gần đây, nhu cầu giúp đỡ cư dân nước ngoài giải quyết các vấn đề họ gặp phải trong cuộc sống hàng ngày ngày càng tăng, vì vậy ngoài việc hướng dẫn về thủ tục nhập cư thông thường, Trung tâm còn cung cấp tư vấn về các vấn đề sinh hoạt nói chung, và mang chức năng của Cổng thông tin hỗ trợ lưu trú.
        </p>
        <NavigatorAction dir="right" actionType="pickup" charactorType="normal"></NavigatorAction>

        <EmphasisNote>
        <h2>Những ai có thể đến tư vấn</h2>
        <p>
        ●Cư dân nước ngoài và gia đình đang cư trú, các đối tượng liên quan…
        </p>
        <figure>
        <img src={publicPath('images/articles/nyukan02.png')} alt="Cục quản lí xuất nhập cảnh (Quầy nộp hồ sơ)"/>
        <figcaption>Cục quản lí xuất nhập cảnh (Quầy nộp hồ sơ)</figcaption>
        </figure>
        <h2>Thông tin về cơ sở</h2>
        <p>
        ●Tên cơ sở／　<br/>
        Cục quản lý xuất nhập cảnh Fukuoka, Phòng quản lý thẩm tra<br/>
        ● Địa chỉ／　<br/>
        Tòa nhà Tổng cục Tư pháp số 1 Fukuoka, Tầng 6Fukuoka, Chuo-ku, Maizuru 3-5-25 Mã bưu chính: 810-0073<br/>
        ●SĐT: 092-717-7595<br/>
        ●Giờ mở cửa:／9:00 - 16:00 (trừ thứ 7, Chủ nhật, ngày lễ và ngày nghỉ cuối năm - đầu năm)<br/>
        ●Không có thông dịch viên
        </p>
        <p>
        Quầy tư vấn thông tin sẽ hướng dẫn cách điền đơn xét duyệt.<br/>
        ※ Giờ mở cửa của Trung tâm thông tin giống với Quầy nộp hồ sơ. Có hỗ trợ bằng tiếng Anh, tiếng Trung và tiếng Hà.
        </p>
        <h2>Đường Link Trang chủ của Cục Quản lí xuất nhập cảnh</h2>
        <p>
        【URL】<br/>
        <a href="https://www.moj.go.jp/isa/index.html" target="_blank">https://www.moj.go.jp/isa/index.html</a>
        </p>
        <h2>Đường dẫn đến Facebook của Cục Quản lí xuất nhập cảnh Fukuoka</h2>
        <p>
        【URL】<br/>
        <a href="https://www.facebook.com/fukuoka.nyukan.support" target="_blank">https://www.facebook.com/fukuoka.nyukan.support</a>
        </p>
        <FukidashiRedLeft>
        Ngoài thông tin về tình trạng cư trú, thông tin về tình trạng tắc nghẽn của quầy hồ sơ và các thông tin hữu ích khác cũng được cập nhật.Hãy thích và theo dõi chúng tôi!!
        </FukidashiRedLeft>
        <figure>
        <img src={publicPath('images/articles/nyukan_fb_qr.png')} alt="Facebook của Cục quản lí xuất nhập cảnh Fukuoka"/>
        <figcaption>Facebook của Cục quản lí xuất nhập cảnh Fukuoka</figcaption>
        </figure>

        </EmphasisNote>
        <br/>
        <NavigatorAction dir="right" actionType="pickup" charactorType="pointing"></NavigatorAction>

        </Article>
    )
}

export default LangVi