import { langType } from "src/types/lang"
import getLayer from "./getLayer"
import { layerType, subLayerType } from "src/types/layer"
import { endpoint } from "src/types/article"
import { useLocation } from 'react-router-dom';

interface Params {
    layer : layerType | endpoint,
    lang: langType,
    subLayer? : string,
    articleTitle? : string
    articleContents? : string
}


export const setTitleDiscrption = (param:Params) => {
    
    let title = ''
    const layerData = getLayer(param.layer)
    let layerNum = 0;

    if(param.layer !== 'top') {

        if(param.articleTitle) {
            title += param.articleTitle + ' / '
            layerNum+=1;
        }else{}

        if(param.subLayer) {
            layerData.sublayer[param.subLayer] ?  
                title += layerData.sublayer[param.subLayer][param.lang].title + ' / ' :
                title += '';
                layerNum+=1;
        }else{}
    
        title += layerData[param.lang].title + ' / FUKUOKA IS OPEN'
        layerNum+=1;

        let description = ''
        if(layerNum === 1){
            description = layerData[param.lang].description
        }else if( layerNum === 2){
            if(layerData.sublayer[param.subLayer]) description = layerData.sublayer[param.subLayer][param.lang].description
        }else if(param.articleContents){
            description = stripHtmlTagsAndLimitLength(param.articleContents,50)
        }
      

        document.querySelector('meta[name="description"]').setAttribute("content", description)
        document.querySelector('meta[property="og:description"]').setAttribute("content", description)

    }else{
        title += layerData[param.lang].title
    }

    document.title = title
    document.querySelector('meta[property="og:TITLE"]').setAttribute("content", title)
}

function stripHtmlTagsAndLimitLength(htmlString, maxLength) {
    // HTMLタグを削除
    const strippedString = htmlString.replace(/<[^>]*>?/gm, '');

    // 文字列を指定の長さで切り取る
    return strippedString.length > maxLength ? strippedString.substr(0, maxLength) + '...' : strippedString;
}