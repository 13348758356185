import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { TrapezoidExcl } from "@/components/Molecules/CharactorHeadline/challenger"
import Article from "@/components/Organisms/Article"


const LangNe = () => {

    return (<>
    	<Article offset={0} className="pc-noTopMargin">
        <div className="pc-flex col-2">
            <div className="pc-only">
                <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
            </div>
            <BlackNote>
            <ul className="deco">
            <li>
            <p className="large">
            फुकुओका प्रान्तको जनसंख्या<br/>
            ५.१४ मिलियन मानिसहरू 
            </p>
            <p className="al-c"> (२०२० साल , देशमा ९ औं स्थान) ）</p>
            <span className="txt-small">
            सामाग्री प्रिफेक्चरल अनुसन्धान र तथ्याङ्क विभाग「२०२२  फुकुओका प्रान्तको जनसंख्या र घरेलु वार्षिक प्रतिवेदन」
            </span>
            </li>
            </ul>
            </BlackNote>
        </div>
        </Article>
        <TrapezoidExcl dir="left">
        ● फुकुओका प्रान्तको क्षेत्रफल<br/>
        </TrapezoidExcl>
        <Article offset={0} className="pc-noTopMargin">
        <InnerBox>
        <p className="p-side-20">
        (स्रोत: FY २०२३ प्रीफेक्चुरल सरकार अवलोकन)
        </p>
        </InnerBox>
        <div className="pc-flex col-2">
            <div className="pc-only">
                <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
            </div>
            <BlackNote>
            <ul className="deco">
            <li>
            <p className="large">
            ● फुकुओका प्रान्तको क्षेत्रफल <br/>
            ४,९८७㎢ 
            </p>
            <p className="al-c">  (२०२०, देशमा २९  औं) </p>
            <span className="txt-small">
            भूमि, पूर्वाधार, यातायात र पर्यटन मन्त्रालय, जापानको भूस्थानिक सूचना प्राधिकरण 「प्रान्त, शहर, वडा, सहर, गाउँ द्वारा क्षेत्रको २०२२ साल राष्ट्रव्यापी सर्वेक्षण」
            </span>
            </li>
            </ul>
            </BlackNote>
        </div>
        <div className="navi-position sp-only">
            <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
        </Article>

        <TrapezoidExcl dir="left">
        ● फुकुओका प्रान्तको मौसम<br/>
        </TrapezoidExcl>
        <Article offset={0} className="pc-noTopMargin">
        <BlackNote>
        <ul className="deco pc-flex col-2">
        <li>
        <p className="large">
        वार्षिक औसत तापमान<br/>
        17.3℃
        </p>
        </li>
        <li>
        <p className="large">
        वार्षिक औसत आर्द्रता<br/>
        68％
        </p>
        </li>
        <li>
        <p className="large">
        वार्षिक घामको घन्टा<br/>
        १,८८९.४
        </p>
        </li>
        <li>
        <p className="large">
        वार्षिक वर्षा<br/>
        १,६८६ .९ mm
        </p>
        <p>१९९१ देखि २०२० सम्मको ३० वर्षको औसत मूल्यसामाग्री फुकुओका </p>
        <p className="txt-small">
        क्षेत्रीय मौसम विज्ञान पर्यवेक्षक
        </p>
        </li>
        </ul>
       
        </BlackNote>
        <div className="sp-only">
            <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
        </Article>
        <InnerBox top={40} bottom={40}>
        </InnerBox>
    </>)
}

export default LangNe