import styled from "styled-components"
import publicPath from "src/utilty/publicPath"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import HeadLine from "@/components/Atoms/HeadLine"
import { langType } from "src/types/lang"
import getLayer from "src/utilty/getLayer"
import { PathAnalysisValue } from "src/utilty/pathAnalyzer"
import { tsutaeruToEastJa } from "src/utilty/easyJaCheker"
import { TopSectionCotainerTemplate } from "@/components/Templates/Responsive/topSectionContainer"

const SSection = styled.section`
    position: relative;
    background:url(${publicPath('images/top/lifestyleBk.png')}) no-repeat;
    background-size:100% auto;
    background-position: bottom;
    width: 100%;
    height: 100%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        background: url('images/pc/bk_lifestyle.webp') no-repeat;
        background-size: auto 100%;
    }
`

const SHeadline = styled.h2`
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    top:0;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 440px;
        transform: translate(-50%,-20%);
    }

    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 540px;
    }
`

const SMessagePosion = styled.div`
    font-size: ${props => props.theme.fontSize.top_mes_sp};
    color :#fff;
    font-weight: bold;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 28%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        top: 35%;
        font-size: ${props => props.theme.fontSize.topSection_pc};
    }
  

`

const SLinks =  styled.ul`
    position: absolute;
    top: 40%;
    width: ${props => props.theme.topPage.links.ulWidth};
    left: 50%;
    transform: translateX(-50%);
    li{
        width: ${props => props.theme.topPage.links.liWidth};
        margin-top: -8px;
        &:nth-child(2n){
            margin-top: ${props => props.theme.topPage.links.oddMarginTop};
            margin-left: ${props => props.theme.topPage.links.oddOffest};
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        display: flex;
        flex-wrap: wrap;
        top:50%;
 
        li{
         
            flex-basis: 25%;
            margin: 0;
            transform: rotate(2deg);
            &:nth-child(2n){
                margin: 0;
            }
        }
    }
   
`

const LifeStyleSection = ({pathValue}:{pathValue: PathAnalysisValue}) => {

    const layer = getLayer('lifestyle')
    const allowLang = tsutaeruToEastJa(pathValue)
    return (
        <SSection>
            <TopSectionCotainerTemplate>
            <SHeadline>
                <HeadLine nameKey="lifestyle" lang={allowLang}/>
            </SHeadline>
            <SMessagePosion>{layer[pathValue.lang].title}</SMessagePosion>
            <SLinks>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/lifestyle/medical?lang=${pathValue.lang}`}
                        linkKey="iryo"
                    ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/lifestyle/employment?lang=${pathValue.lang}`}
                        linkKey="works"
                        ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/lifestyle/living?lang=${pathValue.lang}`}
                        linkKey="living"
                        ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/lifestyle/education?lang=${pathValue.lang}`}
                        linkKey="kyoiku"
                        ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/lifestyle/disasterguidance?lang=${pathValue.lang}`}
                        linkKey="disasterinfo"
                        ></YellowArrowLink>
                </li>
            </SLinks>
            </TopSectionCotainerTemplate>
        </SSection>    
    )
}

export default LifeStyleSection