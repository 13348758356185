import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import InnerBox from "@/components/Atoms/Box/innerBox"


const LangVi = () => {

    return (<>
    <div className="pc-wapper">
    <InnerBox>
        <h1>
        Consulate
        </h1>
        </InnerBox>
        <BlackNote>
        <ul className="deco">
        <li>
        <p className="name">
        U.S. Consulate Fukuoka<br/> (Est. April 28, 1952)
        </p>
        <p className="address">
        810-0052 2-5-26 Ohori, Chuo-ku, Fukuoka City
        </p>
        <p className="tel">
        092-739-9088
        </p>
        </li>
        <li>
        <p className="name">
        Consulate General of South Korea, Fukuoka<br/>(Est. January 27, 1966)
        </p>
        <p className="address">
        810-0065 1-1-3 Jigyohama, Chuo-ku, Fukuoka
        </p>
        <p className="tel">
        092-771-0461
        </p>
        </li>
        <li>
        <p className="name">
        Consulate-General of The People's Republic of China in Fukuoka<br/>(Est. April 17, 1985)
        </p>
        <p className="address">
        810-0065 1-3-3 Jigyohama, Chuo-ku, Fukuoka City
        </p>
        <p className="tel">
        092-713-1121
        </p>
        </li>
        <li>
        <p className="name">
        Consulate-General of Vietnam in Fukuoka<br/>(Est. April 22, 2009)
        </p>
        <p className="address">
        810-0801 5-3-8, Nakasu, Hakata-ku, Fukuoka<br/>Aqua Hakata 4F
        </p>
        <p className="tel">
        092-263-7668
        </p>
        </li>
        <li>
        <p className="name">
        Royal Thai Consulate-General in Fukuoka<br/>( Est. October 1, 2018)
        </p>
        <p className="address">
        810-0001 4-1-37, Tenjin, Chuo-ku, Fukuoka-shi, Fukuoka <br/>2nd Floor Dai Ichi Myojo
        </p>
        <p className="tel">
        092-739-9088
        </p>
        </li>
        </ul>
        </BlackNote>
    </div>
    <div className="checkpostion01 sp-only">
        <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    
    <div className="pc-list-wrap">
        <InnerBox bottom={20}>
        <h1>Thành phố kết nghĩa</h1>
        <EmphasisNote>
        <h2>
        Tiểu bang Hawaii(Mỹ)<br/>1981.9.25
        </h2>
        <p>
        Tháng 9 năm Showa 55, "Hakata Gion Yamakasa" đã tham gia "Lễ hội tuần lễ Aloha " và cùng thời đó "Triểm lãm sản phẩm Fukuoka" cũng được tổ chức đã xúc tiến tình hữu nghị giữa tỉnh và tiểu bang. Sau đó, nghị quyết xúc tiến thiết lập quan hệ kết nghĩa được đưa ra trong hội nghị ở 2 bên.
        Tiếp đó, sau một năm thảo luận về việc thiết lập mối quan hệ kết nghĩa, vào ngày 25 tháng 9 năm Showa 56, Thống đốc tiểu bang Hawaii Ariyoshi và Thống đốc tỉnh Fukuoka Kamei đã tổ chức lễ ký kết tại thành phố Honolulu, tiểu bang Hawaii.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        Tỉnh Giang Tô(Trung Quốc)<br/>1992.11.4
        </h2>
        <p>
        Tháng 4 năm Heisei 4, khi Tổng Bí thư Đảng Cộng sản Trung Quốc, ông Jiang Zemin, đến thăm tỉnh và công bố mục tiêu hiện thực hóa việc thiết lập mối quan hệ hữu nghị với Trung Quốc trong dịp kỷ niệm 20 năm bình thướng hóa quan hệ Nhật Bản Trung Quốc. Theo đó, tỉnh đã thành lập Ủy ban cân nhắc thiết lập quan hệ hữu nghị với thành phần bao gồm cơ quan hành chính, hội đồng nhân dân và doanh nghiệp. Sau khi cử phái đoàn đi khảo sát và thông qua hội nghị...đã quyết định thiết lập mối quan hệ hữu nghị với tỉnh Giang Tô.<br/>
        Tháng 9 năm Heisei 4, tỉnh đã đệ trình đơn đề nghị thiết lập mối quan hệ hữu nghị, và vào ngày 4 tháng 11 cùng năm, Thống đốc tỉnh Fukuoka Okuda và Chủ tịch tỉnh Giang Tô Chen đã tổ chức lễ ký kết tại thành phố Nam Kinh, tỉnh Giang Tô.       
         </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        Thành phố Bangkok(Vương quốc Thái Lan)<br/>2006.2.8
        </h2>
        <p>
        Nhằm thiết lập mối quan hệ sâu rộng với châu Á, tỉnh đã tiền hành giao lưu với Thái Lan, quốc gia đang có sự phát triển nổi bật giữa các quốc gia châu Á trong ngành công nghiệp ô tô, là ngành công nghiệp chính của tỉnh. Trong đó, Bangkok, trung tâm chính trị, kinh tế và văn hóa, được chọn là khu vực tiềm năng. Tháng 10 năm 2005, tỉnh đã phái cử phái đoàn khảo sát đi đàm phán với ông Apirak, Thống đốc thủ đô Bangkok. Khi tiến hành xác nhận ý định hợp tác thì đã nhận được câu trả lời tích cực nên vào tháng 2 năm 2006, phái đoàn do ông Masao, Thống đốc tỉnh Fukuoka làm trưởng đoàn, đã đến thăm Bangkok và ký kết Hiệp định thiết lập quan hệ hữu nghị.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        Bang New Delhi(Ấn Độ)<br/>2007.3.5
        </h2>
        <p>
        Nhằm thiết lập mối quan hệ sâu rộng với châu Á, tỉnh đã tiến hành giao lưu với với Ấn Độ, nơi ngành IT đang đi đầu và ngành công nghiệp ô tô đang phát triển nổi bật trong khu vực Châu Á. Trong đó, bang New Delhi, trung tâm chính trị, kinh tế và văn hóa, được chọn làm khu vực tiềm năng. Tháng 10 năm 2005, tỉnh đã phái cử phái đoàn khảo sát, vào tháng 2 năm 2006, phái đoàn do ông Masao, Thống đốc tỉnh Fukuoka làm trưởng đoàn, đã thăm bang New Delhi và tiến hành thảo luận với ông Dikshit, Thủ tướng New Delhi về việc giao lưu hữu nghị. Sau khi nhận được sự chấp thuận của Chính phủ Ấn Độ, vào tháng 12 năm 2006, trong Hội nghị thượng đỉnh Nhật-Ấn  việc thiết lập quan hệ hữu nghị với bang New Delhi đã nhận lời tán thưởng từ 2 phía nên vào tháng 3 năm 2007 hai bên đã thiết lập quan hệ hữu nghị.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        Thành phố Hà Nội（Việt Nam）<br/>2008.2.22
        </h2>
        <p>
        Nhằm thiết lập mối quan hệ sâu rộng với châu Á, tỉnh đã tiến hành giao lưu với Việt Nam, quốc gia đang có tốc độ tăng trưởng vượt trội so với các khu vực châu Á nhờ lực lượng lao động dồi dào, chăm chỉ trong lao động. Trong đó, Hà Nội, thủ đô chính trị, kinh tế và văn hóa, đã được chọn làm khu vực tiềm năng. Sau khi phái cử phái đoàn khảo sát vào tháng 10 năm 2005, 2 bên đã tiếp tục đàm phán hướng triển khai cụ thể và vào tháng 2 năm 2008, phái đoàn do Thống đốc tỉnh Fukuoka ông Aso dẫn đầu đã đến thăm Hà Nội và ký kết quan hệ hữu nghị.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        Một thành phố ba tỉnh ở bờ biển phía nam Hàn Quốc và ba tỉnh ở phía bắc Kyushu và tỉnh Yamaguchi<br/><br/>Công bố tuyên bố chung
        </h2>
        <p>
        Hai nước Nhật Bản và Hàn Quốc có vị trí địa lý gần nhau và từ xưa đã có nhiều giao lưu. Đặc biệt, ba tỉnh phía bắc của Kyushu, Nhật Bản (tỉnh Fukuoka, tỉnh Saga, tỉnh Nagasaki) và 3 tỉnh và 1 thành phố ở bờ biển phía nam Hàn Quốc (thành phố Busan và tỉnh Jeollanam, tỉnh Gyeongsangnam, và đặc khu tự trị đặc biệt Jeju) đã đóng vai trò quan trọng như cửa ngõ giao lưu qua eo biển Nhật Hàn.<br/>Trong bối cảnh địa lý và lịch sử như vậy, vào tháng 10 năm 1990, tọa đàm giữa các Thống đốc của ba tỉnh phía bắc Kyushu được tổ chức tại Tsushima, tỉnh Nagasaki để thảo luận về việc xúc tiến giao lưu giữa hai khu vực. Tiếp đó, sau khi tiến hành hội nghị với phía Hàn Quốc, vào tháng 7 năm 1992, tỉnh đã đạt được thỏa thuận về việc tổ chức hội nghị giao lưu thượng đỉnh nhằm trao đổi ý kiến về tình hình giao lưu hiện tại giữa 2 khu vực và triển vọng tương lai đồng thời xúc tiến giao lưu trong nhiều lĩnh vực với tên gọi là "Hội nghị Giao lưu Thống đốc giữa các tỉnh và đô thị ven biển Nhật Hàn".<br/>
        Tháng 8 năm 1992, tại tỉnh Jeju, Hàn Quốc, đã diễn ra "Hội nghị Giao lưu Thống đốc giữa các tỉnh và đô thị ven biển Nhật Hàn lần thứ nhất," trong đó đã công bố tuyên bố chung về việc triển khai dự án nhằm xúc tiến giao lưu giữa hai khu vực ven biển của Nhật Hàn và việc tổ chức định kỳ hội nghị giữa các Thống đốc.<br/>
        Từ năm 1999, tỉnh Yamaguchi tham gia.<br/>
        Từ năm 2008, đổi tên thành "Hội nghị Thống đốc Giao lưu giữa các tỉnh và đô thị ven biển Nhật Hàn".
        </p>
        </EmphasisNote>
        </InnerBox>
    </div>
    <InnerBox bottom={20}>
        <div className="pc-navigator">
            <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
    </InnerBox>
    </>)
}

export default LangVi