
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote";


const LangNe = () => {

    return (
        <>
            <p className="discpriton">
            फुकुओका प्रिफेक्चरको लोकप्रिय खाना मध्येको एक "तोन्कोचु रामेन" 
            प्रिफेक्चरभर यत्रतत्र छरिएका रामेन रेष्टुरेन्टहरुमा, सो रेष्टुरेन्टको मौलिक रामेनको स्वाद लिन सक्नु हुन्छ।
            "TONKOTSU RAMEN BEATS"  भनेको त्यस्तो "तोन्कोचु रामेन" लाई विषयवस्तु बनाएको गेम
            ठूलो कचौराको तालमा ताल मिलाएर उच्च स्कोरको रेकर्ड बनाऔं!
            </p>
            <EmphasisNote className="customNote">
                <h4><img src="./images/ramenbeats/howtoplay.webp" alt="how to play"/></h4>
                <section>
                    <h5 className="first">
                    स्क्रीनको तलको स्टार्ट बटन थिच्ने <br/>(गेम स्क्रिन खुल्छ)
                    </h5>
                </section>
                <section>
                    <h5 className="second">
                        <img src="./images/ramenbeats/start.webp" alt="start" width={230}/><br/>
                        थिच्ने
                    </h5>
                </section>
                <section>
                    <h5 className="third">
                    आफूलाई मन परेको स्टेज थिचेर गेम स्टार्ट गर्ने!
                    </h5>
                    <ul className="caption">
                        <li>
                        नवीनतम OS र ब्राउजरमा खेल्नुहोस्
                        </li>
                        <li>
                        यो गेम, स्मार्टफोनमा खेल्नको लागि बनाएको हो
                        </li>
                    </ul>
                </section>
            </EmphasisNote>
            <h4 className="whattonkotsu"><img src="./images/ramenbeats/what_tonkotsu.webp" alt="What’s TONKOTSU RAMEN？"/></h4>
            <p>
            "तोन्कोचु" भनेको "सुँगरको हड्डी" भन्ने अर्थ हो। <br/>
            तोन्कोचु रामेनको सूप सुँगुरको हड्डीलाई लामो समय सम्म उमालेर बनाइन्छ<br/>
            स्थान र रेष्टुरेन्ट अनुसार फरक त हुन्छ तर, सेतो धमिलो यसको विशेषता हो<br/>
            नुडल्स चाहिँ मसिनो सिधा खालको नुडल्स बढी प्रयोग हुन्छ<br/>
            धेरै जसो रेष्टुरेन्टमा नुडल्सलाई "बारिखाता" "खाता" आदि आफूलाई मनपर्ने कडापनमा अर्डर गर्न सकिन्छ<br/>
            त्यसै गरी, नुडल्स मात्र थप गर्ने "खाएदामा" गर्न सकिने पसल धेरै हुनु पनि एउटा विशेषता हो
            </p>
            <ul className="phots"> 
                <li>
                    <img  src="./images/ramenbeats/ramen01.webp" alt="豚骨ラーメン"/>
                </li>
                <li>
                    <img  src="./images/ramenbeats/ramen02.webp" alt="豚骨ラーメン"/>
                </li>
            </ul>
            
        
        </>
    )
}

export default LangNe