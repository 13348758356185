

import publicPath from "src/utilty/publicPath"


const LangEn = () => {

    return (<>
    <h1>
    Governor's Message
    </h1>
   <figure className="governor">
     <img src={publicPath('images/fukuoka_governor.jpg')} className="img-fit"/>
    </figure>
    <p>
    　Hello everyone. Welcome to the FUKUOKA IS OPEN portal site.
    </p>
    <p>
    　Fukuoka Prefecture has long been open as a window to Asia and the world, and its rich natural environment and history have led to the development of a variety of cultures and industries, and a diverse range of human resources.
    </p>
    <p>
    　The "FUKUOKA IS OPEN" portal site provides information on consultation services, medical care, education, employment, and other services for living in Fukuoka Prefecture, and introduces visitors from overseas who are active in the prefecture. The website also introduces to the world the advanced efforts of the prefecture in sports, the environment, and one-health, as well as the attractiveness of food and tourism.
    </p>
    <p>
    　We will support them well so that many people from overseas will come to Fukuoka Prefecture and everyone can live with peace of mind and many smiles on their faces.
    </p>
    <p>
    With "FUKUOKA IS OPEN" as our motto, let's create the future together here in Fukuoka.
    </p>
    <br/>
    <br/>
    <p className="al-r">
    <strong>
    Seitaro Hattori, Governor, Fukuoka Prefecture</strong>
    </p>
    </>)
}

export default LangEn