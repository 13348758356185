import CommingSoon from "@/components/Atoms/CommingSoon";
import { NaviAndChallenger } from "@/components/Atoms/HeadLine/charactor";
import PageBack from "@/components/Molecules/PageBack";
import PageTitleVirtual from "@/components/Molecules/PageTitle/virtual";
import Article from "@/components/Organisms/Article";
import FioTemplate from "@/components/Templates/fio";
import SubLayerTemplate from "@/components/Templates/subLayer";
import { useLocation, useParams } from "react-router-dom";
import getLayer from "src/utilty/getLayer";
import { pathAnalyzer } from "src/utilty/pathAnalyzer";
import { setTitleDiscrption } from "src/utilty/setTitleDiscription";
import styled from "styled-components";


const SVirtual = styled.div`
`

const NotFoundPage = () => {

    
    document.title = '404:Not Found'


    
    return (
        <FioTemplate>
            <SubLayerTemplate>
                <NaviAndChallenger
                    challengarType="thinking"
                    navigatorType="normal"
                ></NaviAndChallenger>
                <SVirtual>
                    <Article>
                    <h1>404:Not Found</h1>
                    </Article>
                </SVirtual>
                <PageBack backto="/"></PageBack>

            </SubLayerTemplate>
        </FioTemplate>
    )
}

export default NotFoundPage