import InnerBox from "@/components/Atoms/Box/innerBox"
import {FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"

const LangZhCn = () => {

    return (<>
    <InnerBox>
    <h1>生活环境</h1>
    <p>
    为了能够在福冈找到住所并安心地生活，理解以下几点，推进适当手续的同时，也可以依赖政府咨询窗口的支持。
    </p>
    </InnerBox>
    <BlackNote>
    <ul className="deco">
    <li>
    <h4>１.如何寻找房源</h4>
    <p>
    ●使用房地产信息网站和应用程序来寻找房源。<br/>
    ●也有一些支持外语的房地产公司。
    </p>
    </li>
    <li>
    <h4>２.租赁住宅的类型</h4>
    <p>
    ●「民间住宅」：从房东（出租房屋的人）那里租赁的房屋。<br/>
    ●「公营住宅」：由县，市等政府机构出租的房屋。<br/>
    ●「ＵＲ租赁住宅」：由「ＵＲ都市机构」出租的房屋。无需担保人。
    </p>
    </li>
    <li>
    <h4>３.租赁合同的基本事项</h4>
    <p>
    ●租赁合同通常包括押金（保证金）、礼金以及租金的支付等。请仔细理解合同内容，确认其中的条款。
    </p>
    </li>
    <li>
    <h4>４.押金和礼金</h4>
    <p>
    ●押金在合同结束时会返还，但如果因为租金拖欠或房间脏乱导致部分损坏，则可能会被扣除。而礼金则不会退还。
    </p>
    </li>
    <li>
    <h4>５.担保人</h4>
    <p>
    ●一般来说，在签订合同时需要提供担保人。在租户无法负担的情况下，担保人承担责任。此外，也有可以使用租金债务保证公司的情况。
    </p>
    </li>
    <li>
    <h4>６.网络和光热费用</h4>
    <p>
    ●许多住宅现在都配备了互联网连接。光热费用（包括电费、煤气费、水费等）基本上与房租是分开支付的。
    </p>
    </li>
    <li>
    <h4>７.家具家电的有无</h4>
    <p>
    ●根据租赁物业的不同，有的可能配备了家具和家电。在确认家电情况后，决定是使用已配备的家电入住还是自行准备。
    </p>
    </li>
    <li>
    <h4>８.居民登记</h4>
    <p>
    ●入住后需要进行居民登记。可在市、区、町的市政府办理相关手续。
    </p>
    </li>
    <li>
    <h4>9.生活习惯和规则</h4>
    <p>
    ●在日本的居住环境中，存在着特有的生活习惯和礼仪，例如与邻里的相互体谅以及垃圾分类规则等。请注意遵守以上这些。
    </p>
    </li>
    </ul>
    </BlackNote>
    <InnerBox top={20} bottom={20}>
    <FukidashiRedLeft>
    以下的链接提供了在寻找房屋时经常听到的术语集以及“房屋寻找指南”等多种语言的信息，对外国人在寻找房屋时会有所帮助。
    </FukidashiRedLeft>
    <h1>
    英语出入国在留管理局网站「生活就业指南手册」
    </h1>
    <ul className="links">
    <li>
    ●英语：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392830.pdf</a>
    </li>
    <li>
    ●中文：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392831.pdf</a>
    </li>
    <li>
    ●韩语：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001393145.pdf</a>
    </li>
    <li>
    ●越南语：<a href="https://www.moj.go.jp">https://www.moj.go.j/isa/content/001393146.pdfp</a>
    </li>
    <li>
    ●泰语： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●尼泊尔语： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●简易日语： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/930004715.pdf</a>
    </li>
    </ul>
    </InnerBox>
    <InnerBox bottom={20}>
    <h1>日本出租住宅管理协会公益财团「外国人入住顺畅化支援向导」</h1>
    <p>
    <a href="https://www.jpm.jp">https://www.jpm.jp/foreign/</a>
    </p>
    </InnerBox>
    </>)
}

export default LangZhCn