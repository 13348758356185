export const responsive = {
    minWdith640:{
        header:{
            langNavTop: '90px',
            navTop: '90px'
        }
    },
    minSp: '415px',
    minPc : '1201px',
    pcLargePoint : '1350px'
}as const