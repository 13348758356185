
const LangZhTw = () => {

    return (<>
<h1>關於FUKUOKA IS OPEN</h1>
<p>
福岡縣的友好合作地區數量、留學生人數等在日本全國位居前列，同時居住在福岡縣的外國人數為89,518人，創歷史新高。
</p>
<p>
今後，為了在人口減少社會中保持地區的活力，作為縣內企業的勞動者和地區社會的新中堅力量，外籍人才發揮才幹是不可缺少的。
</p>
<p>
在這種情況下，為了讓更多的人士從海外來到本縣，在「FUKUOKA IS OPEN」的宣傳口號下，面向全世界一體地宣傳福岡縣的政策措施，希望大家知道福岡縣是外國人易於生活、能夠大展身手的地區。
</p>
<p>
為此，多語種入口網站「FUKUOKA IS OPEN」（<a href="https://www.fisop.net">https://www.fisop.net</a>）將發佈各種最新相關資訊，例如，福岡縣的教育和醫療等面向外國人的生活相關資訊，與留學生、入境旅遊、國際金融功能的招引、體育、環境、全健康（One Health）等先進的政策措施相關的資訊，居住外國人大顯身手等最新資訊。
</p>
<br/>
<h5 className="title-large">關於FUKUOKA IS OPEN原創徽標的使用</h5>
<p>未經 FUKUOKA IS OPEN 推進事業實行委員會的同意，不得使用本徽標。<br/>
申請使用時，必須遵守使用要領。<br/>
請確認好使用要領和使用指南，並填寫好“（第1號格式) FUKUOKA IS OPEN 推進事業徽標使用申請書”， 通過傳真提交。</p>
<br/>
<p>申請內容得到確認後，會獲得承諾書和徽標數據。</p>
<br/>
<p>
【申請文件等】
</p>
<ul className="dotto">
<li><span className="icon-pdf"><a href="/contents/aboutfio/FIOPromotionProjectLogoUsageInstructions.pdf" target="_blank">FUKUOKA IS OPEN 推進事業徽標使用要領</a></span></li>
<li><span className="icon-doc"><a href="/contents/aboutfio/Form1_FIOPromotionProjectLogoApplicationForm.docx">(第1號格式) FUKUOKA IS OPEN 推進事業徽標使用申請書</a></span></li>
<li><span className="icon-doc"><a href="/contents/aboutfio/Form4_FIOPromotionProjectLogoApplicationForm.docx">(第4號格式) FUKUOKA IS OPEN 推進事業徽標使用承諾變更申請書</a></span></li>
<li><span className="icon-doc"><a href="/contents/aboutfio/Form5_FIOPromotionProjectLogoUsageStatusReport.docx">(第5號格式) FUKUOKA IS OPEN 推進事業徽標使用狀況報告書</a></span></li>
<li><span className="icon-pdf"><a href="/contents/aboutfio/FIOLOGOUSERGUIDELINE.pdf" target="_blank">[FUKUOKA IS OPEN] LOGO USER GUIDELINE</a></span></li>
</ul>
<br/>
<p>
【谘詢處】<br/>
FUKUOKA IS OPEN推進事業實行委員會事務局（福岡縣國際局國際政策課外國人才支援係）
</p>
<br/>
<p>
TEL：<a href="tel:+81-92-643-3201">+81-92-643-3201</a><br/>
FAX：<a href="tel:+81-92-643-3224">+81-92-643-3224</a><br/>
8:30至17:15（9:00至17:45）<br/>
但是周六、周日、節假日以及12月29日至1月3日除外
</p>
    </>)
}

export default LangZhTw