import { langType } from "src/types/lang"
import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"
import { CmsIndex } from "@/components/Organisms/Cms"

const SInternational = styled.div`
    position: relative;
    .firstPostionWrap{
        position: relative;
        width: 100%;
        padding-bottom: 85%;
    }
    .firstPostion{
        position: absolute;
        top: -20%;
    }
    @media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
    (max-width: ${props => props.theme.responsive.minPc}) ),
    screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
        .firstPostion{
            position: absolute;
            top: -10%;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .firstPostionWrap{
            margin-bottom: 20px;
        }
        .firstPostion{
            top:50%;
            transform: translateY(-40%);
        }
     
        .pc-flex.custom{
            & > *{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px;
            }
            .navigator-center{
                position: relative;
                > * {
                    position: absolute;
                    right: 50px;
                    top: 20px;
                }
            }
        }
       
    }
`

const BasciInfoInternational = ({lang}:{lang:langType}) => {

    setTitleDiscrption({
        layer:'basicinfo',
        lang:lang,
        subLayer:"international"
    })

    return(
        <SInternational>
            <CmsIndex></CmsIndex>
        </SInternational>
        
    )
}

export default BasciInfoInternational