import publicPath from "src/utilty/publicPath";

const LangZhTw = () => {

    return (<>
        <h1>關於鏈接</h1>
        <p>
        ●關於鏈接<br/>
        隻要明確指出是鏈接到本門戶網站，不管是首頁還是文章頁，也不管是商業鏈接還是非商業鏈接，原則上都可以自由使用。<br/>
        如果想鏈接網站，請發送電子郵件至<a href="mailto:intpol@pref.fukuoka.lg.jp" target="_blank">intpol@pref.fukuoka.lg.jp</a>。我們將確認鏈接來源。 請在電子郵件中注明鏈接來源的地址、姓名（所屬）和聯係方式（電子郵件地址）。
        </p>
        <p>
        鏈接本門戶網站可使用圖標。<br/>
        有三種圖標可供選擇，請下載您想使用的圖標。<br/>
        在圖標的替代文本說明中，請注明該鏈接指向 FUKUOKA IS OPEN 推進事業門戶網站。
        </p>
        <p>Width 468 px × Height 60 px
        <figure>
        <img src={publicPath('images/fio_banner/fio_468_60.png')}
        className="img-fit"/>
        </figure>
        </p>
        <p>Width 234 px × Height 60 px
        <figure>
        <img src={publicPath('images/fio_banner/fio_234_60.png')}
        className="img-fit"/>
        </figure>
        </p>
        <p>Width 120 px × Height 90 px
        <figure>
        <img src={publicPath('images/fio_banner/fio_120_90.png')}
        className="img-fit"/>
        </figure>
        </p>
        <br/>
        <br/>
    </>)
}

export default LangZhTw