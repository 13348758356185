

import { NavigatorPickUp } from "@/components/Atoms/Character/navigator"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangEn = () => {

    return (
        <Article>
        <p>
        The Fukuoka Immigration Bureau handles various procedures regarding status of residence. <br/>
        (extension of period of stay, change of status of residence, application for permanent residence, procedures for permission to engage in activities other than those permitted under the status of residence in order to work part-time, procedures for bringing in a foreigner who is abroad, etc.)<br/>
        The General Consultation Information Center for Foreign Residents has been established as a consultation center that mainly provides information on immigration procedures related to status of residence and other matters. In recent years, there has been an increasing need to address the problems that foreign residents are facing in their daily lives, so in addition to the conventional information on immigration procedures, the center also provides consultation services related to general daily life, and functions as an entry point for residency support.
        </p>
        <NavigatorAction dir="right" actionType="pickup" charactorType="normal"></NavigatorAction>

        <EmphasisNote>
        <h2>People you can consult with</h2>
        <p>
        ●Resident aliens, their families, and related persons
        </p>
        <figure>
        <img src={publicPath('images/articles/nyukan02.png')} alt="Fukuoka Immigration Bureau (Contact Information)"/>
        <figcaption>Fukuoka Immigration Bureau (Contact Information)</figcaption>
        </figure>
        <h2>Facility Information</h2>
        <p>
        ●Name of Facility　／　<br/>
        Fukuoka Immigration Bureau, Audit Management Division<br/>
        ●Address　／　<br/>
        Fukuoka No.1 Legal Affairs General Building 6F, 3-5-25 Maizuru, Chuo-ku, Fukuoka City, Fukuoka 810-0073<br/>
        ●Phone number: 092-717-7595<br/>
        ●Office Hours／9:00-16:00 [Closed on Saturdays, Sundays, holidays, and year-end and New Year holidays].<br/>
        ●Interpreter Not available
        </p>
        <p>
        Information on how to fill out the application form is available at the Information Center.<br/>
        ※※The Information Center is open the same hours as the application counter. English, Chinese, and Korean are available.
        </p>
        <h2>Immigration and Emigration Agency Website URL</h2>
        <p>
        【URL】<br/>
        <a href="https://www.moj.go.jp/isa/index.html" target="_blank">https://www.moj.go.jp/isa/index.html</a>
        </p>
        <h2>Fukuoka Immigration Bureau Facebook URL</h2>
        <p>
        【URL】<br/>
        <a href="https://www.facebook.com/fukuoka.nyukan.support" target="_blank">https://www.facebook.com/fukuoka.nyukan.support</a>
        </p>
        <FukidashiRedLeft>
        In addition to information about status of residence, we also post information about the congestion at the application counter and other useful information.Please like and follow us.
        </FukidashiRedLeft>
        <figure>
        <img src={publicPath('images/articles/nyukan_fb_qr.png')} alt="Fukuoka Immigration Bureau Facebook"/>
        <figcaption>Fukuoka Immigration Bureau Facebook</figcaption>
        </figure>

        </EmphasisNote>
        <br/>
        <NavigatorAction dir="right" actionType="pickup" charactorType="pointing"></NavigatorAction>

        </Article>
    )
}

export default LangEn