
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import { ChallengerrFullLength } from "@/components/Atoms/Character/challenger"
import { NavigatorFullLength } from "src/components/Atoms/Character/navigator"
import { Flogo } from "@/components/Atoms/FLogo"
import LangNav from "@/components/Molecules/LangNav"
import SiteNav from "@/components/Molecules/SiteNav"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { pathAnalyzer } from "src/utilty/pathAnalyzer"
import { langType } from "src/types/lang"

const SSideSection = styled.section`
    width: 250px;
    background: ${props => props.theme.colors.black};
    height: 100%;
    overflow-y: scroll;
    position: relative;
    z-index: 999;
    -ms-overflow-style: none;
    scrollbar-width: none;
    *::-webkit-scrollbar{
        display:none;
    }
    padding-bottom: 40px;

    &::before{
        content: '';
        width: 250px;
        height: 100%;
        position: fixed;
        background: url(${publicPath('images/menuBk_pc.svg')}) no-repeat;
        background-size: 100% auto;
        background-position: bottom;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
    .SSideSectionWrap{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .logo{
        width: 100%;
        padding:10px 0;
        text-align: center;
        background: #fff;
    }
    .lang{
        padding: 20px;
        width: 200px;
        margin: 0;
        z-index: 11;
    }
    .nav{
        padding:0 20px;
        padding-bottom: 150px;
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
       width: 300px;
       &::before{
        width: 300px;
       }
    }
`




const PCSideNav = () => {
    const [lang, setLang] = useState<langType>('ja')
    let location = useLocation();
    useEffect(() => {
        const path = pathAnalyzer(location)
        setLang(path.lang)
    },[location])

    return (
        <SSideSection>
            <div className="SSideSectionWrap">
                <div className="logo">
                    <Flogo width={140} lang={lang}></Flogo>
                </div>
                <div className="lang">
                    <LangNav></LangNav>
                </div>
                <div className="nav">
                    <SiteNav lang={lang}></SiteNav>
                </div>
            </div>
        </SSideSection>
    )
}

export default PCSideNav