import { PropsWithChildren } from "react"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"


const SBlackWrap = styled.div`
    position: relative;
    padding: 50px 0 0;
`

const SBlackNote = styled.div`
    background: ${props => props.theme.colors.gray};
    background-image: url(${publicPath('images/dottoBk.png')});
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    background-size: 100% auto;
    padding:0 10px;
    color: #fff;
    position: relative;
    z-index: 10;
    .texarea{
        padding: 10px 20px 30px;
        box-sizing: border-box;
        position: relative;
        p{
           font-size: 2.2rem;
            font-weight: bold;
            line-height: 2.8rem;
        }
        .large{
            color: ${props => props.theme.colors.yellow};
            font-size: 3.5rem;
            line-height: 4.0rem;
            text-align: center;
            
        }
        .xl{
            color: ${props => props.theme.colors.yellow};
            font-size: 45px;
            line-height: 4.8rem;
            text-align: center;
            font-weight: bold;
        }
        .middle{    
            font-size: 2.8rem;
            line-height: 3.2rem;
            text-align: center;
        }
        &::before{
            content: '';
            width: calc(100% + 10px);
            height: 2px;
            left: -10px;
            bottom: 10px;
            background: ${props => props.theme.colors.yellow};
            position: absolute;
        }
        &::after{
            content: '';
            width: 2px;
            height:calc(100% - 10px);
            background: ${props => props.theme.colors.yellow};
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    
`
const SBlackTop = styled.div`
    content: '';
    clip-path: polygon(0 12%, 100% 0, 100% 100%, 0% 100%);
    background:#3D3A39;
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    .yellow{
        content: '';
        clip-path: polygon(0 12%, 100% 0, 100% 100%, 0% 100%);
        width: 100%;
        background: ${props => props.theme.colors.yellow};
        height: 200px;
        position: absolute;
        left: -10px;
        top: 10px;
        &::after{
            content: '';
            clip-path: polygon(0 12%, 100% 0, 100% 100%, 0% 100%);
            width: 100%;
            background:#3D3A39;
            height: 200px;
            position: absolute;
            left: -2px;
            top: 2px;
        }
    }
   

`


const BlackNote = ({
    children,
    ...props}:PropsWithChildren) => {

        return (
            <SBlackWrap>
                <SBlackTop>
                    <div className="yellow"/>
                </SBlackTop>
                <SBlackNote>
                    <div className="texarea">
                        {children}
                    </div>
                </SBlackNote>
                
            </SBlackWrap>
        )
}

export default BlackNote