
import { CmsProvider } from "@/context/CmsContext"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { fetchCategory } from "src/data/fetch"
import { PathAnalysisValue, pathAnalyzer } from "src/utilty/pathAnalyzer"
import styled from "styled-components"
import { CmsArtileIndexHeadLine } from "@/components/Organisms/Cms/headLine"
import InnerBox from "@/components/Atoms/Box/innerBox"
import { CmsArticlesIndex } from "./Articles/Index"
import { CmsArticlesCategory } from "./Articles/Category"
import { CmsArticlesCategroySearch } from "./Articles/Category/search"
import { CmsSearchForm } from "@/components/Molecules/Cms/SearchForm"
import { CmsArticlesIndexSearch } from "./Articles/Index/search"
import { CmsArticlesNews } from "./Articles/News"
import { CmsArticlesNewsSearch } from "./Articles/News/search"
import { CmsView } from "./view"
import { CmsPageBack } from "@/components/Organisms/Cms/pageBack"
import { endpoint } from "src/types/article"
import { CmsEmergencyView } from "./Articles/Emargancy/view"
import { CmsArticlesEmergency } from "./Articles/Emargancy"

const SCmsIndex= styled.div`
    min-height: 100vh;
`

export interface ICmsViewTrigger {
    id : string,
    endpoint ?: endpoint
    category ?: string
}

export type TCmsViewHandle = ({id, endpoint}: ICmsViewTrigger) => void

export interface ICmsArticlesProps {
    categoryId? : string,
    searchWord? : string,
    handleView  : ({id, endpoint}:ICmsViewTrigger) => void
}

export const CmsIndex = ({ pageback = true}:{pageback?: boolean}) => {
    const location = useLocation()
    let pathAnalys:PathAnalysisValue = pathAnalyzer(location)
    if(pathAnalys.lang === 'easy_ja') pathAnalys.lang ='ja'

    //const [pathAnalys, setPathAnalys] = useState<PathAnalysisValue>()   
    //記事カテゴリ
    const [ reLoad, setReLoad ] = useState<boolean>( pathAnalys.id ? true : false )
    const [ categoryId, setCategoryId ] = useState<string>()
    const [view, setView] = useState<ICmsViewTrigger>({id:pathAnalys.id})

    //記事の表示モード
    const [ articlesMode, setArticlesMode ] = useState<string>( pathAnalys.id ? 'view' : '' )
    //検索ワード
    const [ searchWord, setSearchWord ] = useState<string>()
   
    const navigate = useNavigate()

   
    
    //カテゴリの取得.
    useEffect(() => {
        window.scrollTo(0,0)
        //カテゴリID取得.
        const getCategory = async() => {
            
            if(pathAnalys.layer === 'information'){
                setCategoryId('index')
                setArticlesMode('news')

            }else if(pathAnalys.category === 'index'){
                setCategoryId('index')
                setArticlesMode('index')
            }else{
                const categoies = await fetchCategory(
                    pathAnalys.layer, 
                    pathAnalys.category)

                if(categoies.totalCount > 0) {
                    setCategoryId(categoies.contents[0].id)
                }else{}

                setArticlesMode('category')
            }
        }
        
        if(pathAnalys && !pathAnalys.id){
            getCategory()
        }else{}

    },[location])
    
    const keywords = [
        'サンプル','サンプル','サンプル','サンプル',
    ]

    const handleAricleSearch = (input: string) => {
        input ? setSearchWord(input) : setSearchWord(undefined)

        if(articlesMode === 'category' || articlesMode === 'category_search'){
            input ? setArticlesMode('category_search') : setArticlesMode('category')
        }else if(articlesMode === 'index' || articlesMode === 'index_search'){
            input ? setArticlesMode('index_search') : setArticlesMode('index')
        }else if(articlesMode === 'news' || articlesMode === 'news_search'){
            input ? setArticlesMode('news_search') : setArticlesMode('news')
        }else if(articlesMode === 'emergency' || articlesMode === 'emergency_search'){
            input ? setArticlesMode('emergency_search') : setArticlesMode('emergency')
        }
        
    }

    const handleView = ({id, endpoint, category}:ICmsViewTrigger) => {
        window.scrollTo(0, 0)
        //const layer = endpoint ?  endpoint : pathAnalys.layer
        const layer = endpoint ? endpoint : pathAnalys.layer 
       
        const href = `/${layer}/${category ? category : pathAnalys.category}?id=${id}&lang=${pathAnalys.lang}`
        navigate(href)
       
        setArticlesMode('view')
        setView({id: id , endpoint: endpoint})
    }

    const closeViewer = () => {
        window.scrollTo(0, 0)
        let href
        if(pathAnalys.layer === 'information'){
            setArticlesMode('news')
            href = `/information/${pathAnalys.category}?lang=${pathAnalys.lang}`
        }else{
            pathAnalys.category === 'index' ? setArticlesMode('index') : setArticlesMode('category')
            href = `/${pathAnalys.layer}/${pathAnalys.category}?lang=${pathAnalys.lang}`
        }
        navigate(href, {replace : true})
        if( reLoad === true ) setReLoad(false)
    }

    return (
        <SCmsIndex>
             <CmsProvider pathAnalys={pathAnalys}>
             <CmsArtileIndexHeadLine/>
             {articlesMode !== 'view' &&  
               <>
                <div>
                    <InnerBox>
                    <CmsSearchForm
                        defaultInput={searchWord}
                        keywords={keywords} 
                        handleAricleSearch={handleAricleSearch}></CmsSearchForm>
                    </InnerBox>
                </div>
                <InnerBox top={40} bottom={20}>
                {
                    articlesMode === 'index' &&
                    <CmsArticlesIndex 
                        handleView={handleView}
                        categoryId={categoryId}></CmsArticlesIndex>
                }
                {
                    articlesMode === 'index_search' &&
                    <CmsArticlesIndexSearch
                        handleView={handleView}
                        searchWord={searchWord} 
                        categoryId={categoryId}></CmsArticlesIndexSearch>
                }
                {
                    articlesMode === 'category' &&
                    <CmsArticlesCategory 
                            handleView={handleView}
                        categoryId={categoryId}></CmsArticlesCategory>
                }
                {
                    articlesMode === 'category_search' &&
                    <CmsArticlesCategroySearch
                        handleView={handleView}
                        searchWord={searchWord} 
                        categoryId={categoryId}></CmsArticlesCategroySearch>
                }
                {
                    articlesMode === 'news'&&
                    <CmsArticlesNews handleView={handleView}></CmsArticlesNews>
                }
                {
                    articlesMode === 'news_search'&&
                    <CmsArticlesNewsSearch 
                        handleView={handleView}
                        searchWord={searchWord}></CmsArticlesNewsSearch>
                }
                 {
                    articlesMode === 'emergency'&&
                    <CmsArticlesEmergency handleView={handleView}></CmsArticlesEmergency>
                }
                {
                    articlesMode === 'emergency_search'&&
                    <CmsArticlesNewsSearch 
                        handleView={handleView}
                        searchWord={searchWord}></CmsArticlesNewsSearch>
                }
              
                </InnerBox>
                { pageback &&
                <InnerBox top={40}>
                    <CmsPageBack page="index"></CmsPageBack>
                </InnerBox>
                }
            </>
            }
            {
                articlesMode === 'view' &&
                <>
                {
                    pathAnalys.layer === 'emergency' ? 
                    <CmsEmergencyView view={view}></CmsEmergencyView>
                    : <CmsView view={view}/>
                }
                
                <CmsPageBack page="view" onClick={closeViewer}></CmsPageBack>
                </>

            }
            </CmsProvider>
        </SCmsIndex>
    )
}