import { Link } from "react-router-dom"
import { ArticleObj } from "src/types/article"
import { langType } from "src/types/lang"
import { subLayerType } from "src/types/layer"
import { articleTitleSelect } from "src/utilty/articleTitleSelect"
import { DateCmsFormat } from "src/utilty/dateFormar"
import styled from "styled-components"


interface SlideContentProps {
    article: ArticleObj,
    lang: langType,
    layer: string,
    className? : string
}

export const SlideContent = (
    {   
        article,
        lang,
        layer,
        className
    } : SlideContentProps
) => {

    const date = DateCmsFormat(article.publishedAt)
    const title = articleTitleSelect(lang, article)
    let eyecatch
    if(layer === 'movie' && article[lang]){
        eyecatch = article[lang].eyecatch ? article[lang].eyecatch.url  :undefined
    }else{
        eyecatch = article.eyecatch ? article.eyecatch.url  :undefined
    }
    
    const subLayer: subLayerType = article.category ? article.category.en : 'index'
    
    return (
        <div  className="slider-contents">
            <Link to={`/${layer}/${subLayer}?id=${article.id}&lang=${lang}`}>
                <figure>
                     <img src={eyecatch ? eyecatch : 'images/noimg.png'} alt={title}/>
                </figure>
                <div  className="date-title">
                    <span className="date">{date.Y}.{date.m}.{date.d}</span>
                    {title}
                </div>
            </Link>
        </div>
    )
}   