import publicPath from "src/utilty/publicPath";

const LangZhCn = () => {

    return (<>
    <h1>关于链接</h1>
    <p>
    ●关于链接<br/>
    与本门户网站的链接，只要明确表明是对本门户网站的链接，不论是主页还是文章页面，而且原则上无论是营利还是非营利都是自由的。<br/>
    添加链接的情况下，请发送电子邮件至 <a href="mailto:intpol@pref.fukuoka.lg.jp" target="_blank">intpol@pref.fukuoka.lg.jp</a>。我们将确认链接来源。请在电子邮件中提供链接来源地址、您的姓名（所属机构）和联系方式（电子邮件地址）。
    </p>
    <p>
    您可以使用横幅来链接到我们的门户网站。 <br/>
    我们提供以下三种横幅广告,请下载并使用您喜欢的那一款。<br/>
    此外，请在横幅图片的替代文本中注明：链接至「FUKUOKA IS OPEN推进事业门户网站」。
    </p>
    <p>Width 468 px × Height 60 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_468_60.png')}
    className="img-fit"/>
    </figure>
    </p>
    <p>Width 234 px × Height 60 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_234_60.png')}
    className="img-fit"/>
    </figure>
    </p>
    <p>Width 120 px × Height 90 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_120_90.png')}
    className="img-fit"/>
    </figure>
    </p>
    <br/>
    <br/>
    </>)
}

export default LangZhCn