import { NavigatorFullLength } from "@/components/Atoms/Character/navigator"
import publicPath from "src/utilty/publicPath"

const LangJa = () => {

    return (
        <>
       <div className="navigatorPositon sp-only">
            <NavigatorFullLength></NavigatorFullLength>
        </div>
        <div className="pc-flex col-2">
            <div>
                <h1>
                    県章
                </h1>
                <figure>
                    <img src={publicPath('images/symbol01.png')} alt="県のシンボル" className="img-fit"/>
                </figure>
                <p>
                県の花“うめ”を形どり、県の発展と県民の融和を表しています。<br/>
                昭和４１年５月１０日、公募によって県が決めました。
                </p>
            </div>
            <div>
                <h1>
                福岡県の花 うめ
                </h1>
                <figure>
                    <img src={publicPath('images/symbol02.png')} alt="福岡県の花 うめ" className="img-fit"/>
                </figure>
                <p>
                昭和２９年３月１９日“NHK植物友の会”が大宰府の梅にちなんで決めました。うめは、学問の神様として太宰府天満宮にまつられている菅原道真公が生涯を通じてこよなく愛したとされています
                </p>
                
            </div>
            <div>
                <h1>
                    福岡県の鳥 うぐいす
                </h1>
                <figure>
                    <img src={publicPath('images/symbol03.png')} alt="福岡県の鳥 うぐいす" className="img-fit"/>
                </figure>
                <p>
                昭和３９年７月３日、公募によって県が決めました。日本では「梅にうぐいす」という言葉があります。これは取り合わせの良いもの、美しく調和するもののたとえの意味です
                </p>
            </div>
            <div>
                <h1>
                福岡県の木 つつじ
                </h1>
                <figure>
                    <img src={publicPath('images/symbol04.png')}  alt="福岡県の木 つつじ"  className="img-fit"/>
                </figure>
                <p>
                昭和４１年９月５日“みどりのニッポン全国運動”で決まりました。つつじは、植木、盆栽用として久留米地方で栽培が盛んで、その苗木は海外へ輸出されています
                </p>
            </div>
        </div>
        </>
    )
}

export default LangJa