import styled from "styled-components"
import { Link } from "react-router-dom"
import { PropsWithChildren } from "react"

interface MenuLinksSecondProps {
    href : string
}
const SAnker = styled(Link)`
    display: block;
    padding: 8px 5px;
    border-left: 4px solid ${props => props.theme.colors.blue};
    position: relative;
    font-size: 1.4rem;
    line-height: 2.0rem;
    font-weight: bold;
    white-space: pre-wrap;
    &:link, &:visited{
        color: #fff;
    }
    &::after{
        content: '';
        background: ${props => props.theme.colors.blue};
        width: 0%;
        height: 100%;
        transition:all .5s;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    &:hover{
        &::after{
            width: 100%;
        }
    }
`

export const MenuLinksSecond = ({
    href,
    children
}: PropsWithChildren<MenuLinksSecondProps>) => {

    
    return (
        <SAnker to={href}>
            {children}
        </SAnker>
    )
}