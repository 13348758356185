import FioTemplate from "@/components/Templates/fio";
import { useLocation, useParams } from "react-router-dom"
import SubLayerTemplate from "@/components/Templates/subLayer";
import PageTitleLifeStyle from "@/components/Molecules/PageTitle/lifestyle";
import LifeStyleLivingPage from "./living";
import PageBack from "@/components/Molecules/PageBack";
import LifeStyleEmployment from "./employment";
import LifeStyleEducation from "./education";
import LifeStyleMedical from "./medical";
import getLayer from "src/utilty/getLayer";

import { useEffect, useState } from "react";
import LifeStyleDisasterPage from "./disaster";
import { pathAnalyzer } from "src/utilty/pathAnalyzer";
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio";
import { CmsIndex } from "@/components/Organisms/Cms";
import LifeStyleDisasterArticle from "./disaster/articles";


const LifeStylePage = () => {

    const { category } = useParams();

    const layer = getLayer('lifestyle')
    
    let layerData
    category && category !== 'index' ? layerData = layer.sublayer[category] :  layerData = layer

    const [ segment, setSegment  ] = useState<string>()
    const location = useLocation()
    const path = pathAnalyzer(location)

    useEffect(()=>{
        if(!category || category == 'index'){
            setSegment('index')
        }else{
            setSegment(category)
        }
    },[category])



    return (
        <ResponsiveFioTemplate>
             <SubLayerTemplate>
                <PageTitleLifeStyle lang={path.lang}>
                { layerData[path.lang].title }
                </PageTitleLifeStyle>
                {
                    segment === 'index' &&
                    <CmsIndex></CmsIndex>
                }
                {
                    segment === 'medical' &&
                    <>
                    <LifeStyleMedical></LifeStyleMedical>      
                    </>
                }
                {
                    segment === 'employment' &&
                    <>
                    <LifeStyleEmployment></LifeStyleEmployment>
                    </>
                }
                {
                    segment === 'living' &&
                    <>
                        <LifeStyleLivingPage ></LifeStyleLivingPage>
                    </>
                }
                {
                    segment === 'education' &&
                    <>
                    <LifeStyleEducation></LifeStyleEducation>
                    </>
                }
                {
                    segment == 'disasterguidance' &&
                    <>
                    <LifeStyleDisasterPage lang={path.lang}></LifeStyleDisasterPage>
                    <PageBack backto={`/?current=3&lang=${path.lang}`}></PageBack>
                    </>
                }
                 {
                    segment == 'disasterinfo' &&
                    <>
                    <LifeStyleDisasterArticle></LifeStyleDisasterArticle>
                    <PageBack backto={`/lifestyle/disasterguidance`}></PageBack>
                    </>
                }
                
             </SubLayerTemplate>
        </ResponsiveFioTemplate>
    )

}

export default LifeStylePage