const colors = {
    black : '#231815',
    blue : '#005BAC',
    red: '#D20A23',
    yellow : '#FFE100',
    gray: '#3E3A39',
    orange : '#EB6100',
    beige : '#F5AF7E',
    lightgreen: "#6FB92C",
    emergency : "#FFE55F",
    emergencyLight : '#FFFADF',
    policy:{
        theme: '#5CB531',
        highligh: '#5CB531',
        link:'036EB8'
    },
    movie:{
        theme: '#E60012',
        highligh: '#E60012',
        link:'036EB8'
    },
    life: {
        theme: '#A0D2F2',
        highligh: '#00A9E4',
        link:'036EB8'
    },
    fukuoka: {
        theme: '#F6BDC0',
        highligh: '#EC6D81',
        link:'036EB8'
    }
    // その他の色...
} as const

export default colors 