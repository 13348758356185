
import { NavigatorNice } from "@/components/Atoms/Character/navigator"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorFukidashiPicup } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"

const LangZhTw = () => {

    return (<>
    <h1>災害發生時對外國人的支援</h1>
    <p>
    為了在發生災害時能夠采取恰當的行動，並在本縣安全、安心地生活，外國居民的日常防災準備非常重要。<br/>
    本縣以各種形式提供信息。 通過學習有關災害的知識，可以保護自己的生命，也可以保護家人、朋友以及周圍親人的生命安全。
    </p>
    <NavigatorFukidashiPicup dir="right">
    發生災害時，準確地獲取災害信息和避難信息非常重要。 利用以下網站和應用程序，可簡便地收集到信息。
    </NavigatorFukidashiPicup>
    <br/>
    <EmphasisNote>

    <h2>下載防災應用程序 福岡防災導航/Mamoru-kun</h2>
    <p>
    通過防災應用程序福岡防災導航/Mamoru-kun發布災害信息和其它信息。<br/>
    使用和注冊都是免費的，隻需簡單操作就能了解災害信息，請一定注冊。
    </p>
    <p>
    ●該係統通過推送通知，告知當前所在位置的防災信息。<br/>
    ●在地圖上，簡單易懂地顯示避難場所。<br/>
    ●以插圖的形式提供發生災難時應采取的行動。<br/>
    ●發生災害時，可以確認家人的位置。<br/>
    ●有日語、英語、中文（簡體/繁體）、韓語和越南語版本。
    </p>
    <figure>
    <img src={publicPath('images/articles/mamorukun01.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/mamorukun02.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
    </figure>

    <div className="app_qr">
    <a href="https://apps.apple.com/jp/app/%E3%81%B5%E3%81%8F%E3%81%8A%E3%81%8B%E9%98%B2%E7%81%BD%E3%83%8A%E3%83%93-%E3%81%BE%E3%82%82%E3%82%8B%E3%81%8F%E3%82%93/id1659756418" target="blank">
    <figure>
    <img src={publicPath('images/articles/mamorukun_app01.png')} alt="App Store" className="fit-img"/>
    </figure>
    </a>
    <hr/>
    <a href="https://play.google.com/store/apps/details?id=jp.lg.fukuoka.pref.fukuoka_bosai&hl=ja&gl=US" target="blank">
    <figure>
    <img src={publicPath('images/articles/mamorukun_app02.png')} alt="Google Play" className="fit-img"/>
    </figure>
    </a>
    </div>
    <h2>福岡縣防災網站</h2>
    <p>
    福岡縣開設了 福岡縣防災網站，提供最新的防災信息和災害信息。
    </p>
    <p>
    （URL:<a href="https://www.bousai.pref.fukuoka.jp" target="_blank">
    https://www.bousai.pref.fukuoka.jp
    </a> ）
    </p>
    <h2>防災手冊</h2>
    <p>
    有 9 種語言的《外國人防災手冊》，請提前查閱，做好防災準備。
    </p>

    <p>
    （URL:<a href="https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html" target="_blank">https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html</a>）
    </p>
    <p>
    內容包括<br/>
    ●可能發生的災害<br/>
    ●發生災害時應采取的行動<br/>
    ●日常的防災準備<br/>
    ●避難場所<br/>
    ●緊急聯係電話 等<br/>
    ●語言有簡單日語、英語、中文、韓語、他加祿語、越南語、尼泊爾語、印尼語和緬甸語。
    </p>
    <p>
    <a href="https://www.pref.fukuoka.lg.jp/uploaded/life/671824_61619901_misc.pdf" target="_blnak">
    <figure>
    <img src={publicPath('images/articles/disasterprevantionhandbook.png')} alt="disaster prevantion handbook" className="fit-img"/>
    </figure>
    </a>
    </p>

    <h2>針對外國人的防災訓練</h2>
    <p>
    此外，為了確保外國居民在發生災害時能夠采取恰當的行動，還以外國居民為對象舉行災害演習，請務必參加，並親身體驗發生災害時應采取的行動。
    </p>
    <p>
    ●關於日本易發災害的講座<br/>
    ●體驗學習（如組裝避難中心使用的設備、體驗即時防災用品等） 等
    </p>
    <figure>
    <img src={publicPath('images/articles/disasterinfo01.jpg')} alt="" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/disasterinfo02.jpg')} alt="" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/disasterinfo03.jpg')} alt="" className="fit-img"/>
    </figure>
    <h2>災害時多語言支援中心</h2>
    <p>
    設立“災害時多語言支援中心”，為在發生重大災害時支援市町村應對外國人。
    </p>
    <p>
    ●福岡縣災害對策本部成立時將設在國際交流中心內。<br/>
    ●設立後，將 24小時提供服務。 (根據災情進行調整）。<br/>
    ●多語言支援中心的主要任務如下。<br/>
    ⇒從受災市町村收集有關外國居民情況的信息。<br/>
    ⇒根據受災市町村的要求，提供災害信息，以及關於避難中心運營的口譯/筆譯支援。<br/>
    ⇒應受災市町村的要求，派遣口譯/筆譯誌願者。
    </p>
    </EmphasisNote>
    <br/>
    <NavigatorNice dir="right"></NavigatorNice>
    </>)
}

export default LangZhTw