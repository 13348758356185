import { CmsListArticles } from "@/components/Molecules/Cms/List/articles";
import ArticleLoader from "@/components/Organisms/Article/articleLoader";
import { useCmsContext } from "@/context/CmsContext"
import { Suspense, useEffect, useState } from "react"
import { FetchArtcleIndexArg, fetchArticlesFillerIds, fetchKewordSearch } from "src/data/fetch";
import { AritcleIndex, AritcleSearchRes } from "src/types/article";
import { ICmsArticlesProps } from "../..";


export const CmsArticlesIndexSearch = ({
    searchWord,
    categoryId,
    handleView
}: ICmsArticlesProps) => {
    
    const CmsContext = useCmsContext()
    const loadLimit = 6;
    const [offset, setOffset] = useState<number>(0)
    const [articleIndexs, setArticleIndexs] = useState<AritcleIndex[]>([])
    const [isMore, setIsMore ] = useState<boolean>(false)

    const articleLoadingCompleted = (articles:AritcleIndex) => {
        setArticleIndexs([...articleIndexs, articles])
        const loadNum = (offset+1) * loadLimit
        setIsMore(loadNum < articles.totalCount ) 
    }

    useEffect(() => {
        const searchGetArticles =  async () => {
            const searchRes: AritcleSearchRes = await fetchKewordSearch(
                CmsContext.pathAnalys.lang,
                searchWord
            )
            const searchIds = searchRes.contents.map((c) => c.id).join(`[or]${CmsContext.pathAnalys.lang}[equals]`)
            
            const args:FetchArtcleIndexArg  = {
                endpoint: CmsContext.pathAnalys.layer,
                lang: CmsContext.pathAnalys.lang,
                offset: offset,
                limit: loadLimit,
                ids : searchIds,
                categroyId : categoryId
            }
            
            const articles = await fetchArticlesFillerIds(args);
            articleLoadingCompleted(articles)
        };
        
        searchGetArticles();
    },[])

    
    return (
        <>
          { articleIndexs.length > 0 &&
                articleIndexs.map((articles,i) => (
                    <Suspense key={`aritcleList-${i}`}>
                        <div className="articles">
                            <CmsListArticles 
                            handleView={handleView}
                            articles={articles}></CmsListArticles>
                        </div>
                    </Suspense>
                ))
          }
          {
            articleIndexs.length === 0 &&
            <ArticleLoader/>
          }
        </>
        
    )
}