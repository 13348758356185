import styled from "styled-components"

const STabTempalte = styled.div`
    
    .tab-content{
        display: none;
        &.active{
            display: block;
        }
    }
   button{
        border-radius: 0;
        border: none;
        padding:15px 10px;
        background: #ffffff;
        cursor: pointer;
        color: #898989;
        border: solid 1px #898989;
        font-weight: bold;
        font-size: ${props => props.theme.fontSize.txt_sp};
   }
`

export const TabTamplate = ({children}) => {

    return (
        <STabTempalte>
            {children}
        </STabTempalte>
    )
}