import styled, { css } from "styled-components";
import publicPath from "src/utilty/publicPath";

interface nextPrevProps  {
    onClick?: ()=>void
    arrow ?: string
}  


const style = css`
    width: 40px;
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    top: 40%;
    
`

export const NextBtn = ({ onClick, arrow = 'slideArrowRight.png'}: nextPrevProps) => {

    const Button = styled.button`
        ${style}
        right:0;
    `

    return (
        <Button onClick={onClick}>
           <img
              src={`./images/${arrow}`}
              alt="slide next"
              className="img-fit"
            />
        </Button>
    )
}

export const PrevBtn = ({ onClick, arrow = 'slideArrowLeft.png'}: nextPrevProps) => {
    
    const Button = styled.button`
        ${style}
        left:0;
        z-index: 1;
    `

    return (
        <Button onClick={onClick}>
           <img
              src={`./images/${arrow}`}
              alt="slide prev"
              className="img-fit"
            />
        </Button>
    )
}