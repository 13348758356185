import publicPath from "src/utilty/publicPath";


const LangNe = () => {

    return (<>
    <h1>लिङ्कहरूको बारेमा</h1>
    <p>
    ●लिङ्कहरूको बारेमा<br/>
    यस पोर्टल साइटमा लिङ्कमा、यस पोर्टल साइटमा लिङ्क भएको कुरा निर्दिष्ट भएमा、शीर्ष पृष्ठ एक लेख पृष्ठ हो वा होइन、साथै、नाफाको लागि・गैर-नाफा सैद्धान्तिक रूपमा त्यसो गर्न स्वतन्त्र छ । लिङ्क पोस्ट गरेको अबस्थामा <a href="mailto:intpol@pref.fukuoka.lg.jp" target="_blank">intpol@pref.fukuoka.lg.jp</a> मा इमेल गर्नुहोस। लिङ्क स्रोत जाँच गर्नेछौ। इमेलमा、 लिङ्क स्रोत ठेगाना、नाम(आबद्धता)、सम्पर्क जानकारी (इमेल ठेगाना) समावेश गर्नुहोस्।<br/>

    </p>
    <p>
    यस पोर्टल साइटमा लिङ्क गर्न ब्यानरहरू प्रयोग गर्न सक्नुहुन्छ।ब्यानरहरू、अबको ३ प्रकारको भएकाले、चाहेको चिजलाइ डाउनलोड गरी प्रयोग गर्नुहोस। <br/>
    <br/>
    साथै, कृपया ब्यानर तस्बिरहरूका लागि वैकल्पिक पाठमा、「FUKUOKA IS OPEN प्रचार व्यापार पोर्टल साइट」को लिङ्क भएको कुरा लेख्नुहोस्।
    </p>
    <p>Width 468 px × Height 60 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_468_60.png')}
    className="img-fit"/>
    </figure>
    </p>
    <p>Width 234 px × Height 60 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_234_60.png')}
    className="img-fit"/>
    </figure>
    </p>
    <p>Width 120 px × Height 90 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_120_90.png')}
    className="img-fit"/>
    </figure>
    </p>
    <br/>
    <br/>
    </>)
}

export default LangNe