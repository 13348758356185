import { NavigatorFullLength } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangVi = () => {

    return (<>
    <div className="navigatorPositon sp-only">
        <NavigatorFullLength></NavigatorFullLength>
    </div>
    <div className="pc-flex col-2">
        <div>
            <h1>
            Biểu tượng tỉnh
            </h1>
            <figure>
            <img src={publicPath('images/symbol01.png')} alt="県のシンボル" className="img-fit"/>
            </figure>
            <p>
            Bông hoa có hình dạng giống hoa mơ tượng trưng cho sự phát triển của tỉnh và sự hòa hợp của người dân trong tỉnh.<br/>
            Tỉnh đã quyết định vào ngày 10 tháng 5 năm 1966 thông qua tuyển chọn mở.
            </p>
        </div>
        <div>
            <h1>
            ●Hoa của tỉnh Fukuoka
            Hoa mơ
            </h1>
            <figure>
            <img src={publicPath('images/symbol02.png')} className="img-fit"/>
            </figure>
            <p>
            Vào ngày 19 tháng 3 năm 1954, ''Hội những người bạn thực vật NHK'' đã quyết định đặt tên theo tên hoa mơ của Dazaifu.
            Hoa mơ là hoa mà Michizane Sugawara- người được tôn thờ tại Đền Dazaifu Tenmangu với tư cách là vị thần học tập đã yêu thích trong suốt cuộc đời mình.
            </p>
        </div>
        <div>   
            <h1>
            ●Chim của tỉnh Fukuoka
            Chim chích
            </h1>
            <figure>
            <img src={publicPath('images/symbol03.png')} className="img-fit"/>
            </figure>
            <p>
            Tỉnh đã quyết định vào ngày 3 tháng 7 năm 1964 thông qua tuyển chọn mở.
            Ở Nhật Bản có câu nói "Ume ni Uguisu".
            Nó dùng để nói về những thứ kết hợp hài hòa, đẹp đẽ.
            </p>
        </div>
        <div>
            <h1>
            ●Cây của tỉnh Fukuoka
            Cây hoa đỗ quyên
            </h1>
            <figure>
            <img src={publicPath('images/symbol04.png')} className="img-fit"/>
            </figure>
            <p>
            Nó được quyết định vào ngày 5 tháng 9 năm 1966 trong Chiến dịch Quốc gia Nhật Bản Xanh.
            Hoa đỗ quyên được trồng rộng rãi ở vùng Kurume làm thực vật và cây cảnh. Cây giống còn được xuất khẩu ra nước ngoài.
            </p>
        </div>
    </div>
    
</>)
}

export default LangVi