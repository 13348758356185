import BackButton from "@/components/Atoms/Button/backButton"
import BackLink from "@/components/Atoms/Links/backLink"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import { pathAnalyzer } from "src/utilty/pathAnalyzer"
import styled from "styled-components"


const SPageBack = styled.div`
    position: relative;
    aspect-ratio: 10/2;
    .backlink{
        position: absolute;
        &.center{
            width: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.left{
            width: 50%;
            left: 10px;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        aspect-ratio: 100/8;
        .backlink{
            &.center{
                width: 260px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &.left{
                width: 200px;
                left: 10px;
            }
        }
    }
`

const PageBack = ({
    backto = '/',
    positon = 'center',
    type = 'link',
    onClick = () => {},
    ...props
}:{
    backto? :string,
    positon?: | 'center' | 'left',
    type? : | 'link' | 'button',
    onClick? : () => void
}) => {
     
     //const [modifiedUrl, setModifiedUrl] = useState('');
     // 'lang'パラ  ータがすでに含まれているかチェック
     const hasLangParam = backto.includes('lang=');
     const location = useLocation()
     let newUrl
     // 'lang'パラメータがない場合のみ追加する
     if (!hasLangParam) {
       const lang = pathAnalyzer(location).lang
        // '?'が含まれているかチェック
       const hasQuestionMark = backto.includes('?');
       // 条件に基づいて文字列を変更
        newUrl = hasQuestionMark ? `${backto}&lang=${lang}` :`${backto}?lang=${lang}`;
       //setModifiedUrl(newUrl);
     } else {
       // 'lang'パラメータがすでにある場合は変更しない
       newUrl = backto
     }


    return (
        <SPageBack>
            <div className={`backlink ${positon}`}>
                {   type === 'link' &&
                    <BackLink href={newUrl}/>
                }
                 {   type === 'button' &&
                    <BackButton onClick={onClick}/>
                }
             </div>
        </SPageBack>
    )
}

export default PageBack