


const LangNe = () => {

    return (
        <div className='article-inner'>
        <section>
        <h1>क्यूश्यू लोगो मार्क भन्नाले</h1>
        <ul className="photos">
            <li>
                <img src="./images/kyushulogomark/logomark01.jpg" alt="The Kyushu Logo"/>
            </li>
            <li>
                <img src="./images/kyushulogomark/logomark02.jpg" alt="The Kyushu Logo ※のれん"/>
            </li>
        </ul>
        <p>
        यो लोगो मार्क, क्यूश्यूलाई देशभर अनि, विश्वमा चिनाउनको लागि प्रयोग गरिरहेको छ। क्यूश्यू लोगो मार्क, क्यूश्यूको नोरेन मार्फत, हामी पाहुनाहरुलाई हृदय देखि स्वागत गर्न चाहन्छौ भन्ने भाव प्रकट गरेर, ※नोरेनको डिजाइन गरेको हो। 「九」 र「州」र 「一」भन्ने खान्जीबाट बनाएको, क्यूश्यूको धेरै क्षेत्रलाई एउटै मालामा गाँसेको भाव देखाइएको छ। <br/>
        ※नोरेन<br/>
        नोरेन भनेको, पसलको ढोकामा घामलाई छेक्नको लागि झुन्ड्याइने कपडा अर्थात पर्दा हो। जापानको मौलिक संस्कृति हो, पसलको नाम र ट्रेडमार्क यस पर्दामा लेख्ने चलन धेरै छ।  
        </p>
        <br/>
        </section>
        <section>
        <h1>क्यूश्यू भनेको</h1>
        <figure>
        <img src="./images/kyushulogomark/kyushu.jpg" alt="九州について"/>
        </figure>
        <p>जापानको सुदूर पश्चिममा अवस्थित क्यूश्यू क्षेत्र, पहाड र समुन्द्रको भव्य अनि सुन्दर र मनमोहक प्राकृतिक दृष्य, तातोपानीको मुहान, इतिहास, संस्कृति, स्वादिष्ट खाना जस्ता, जापानका प्रमुख आकर्षणहरुको मज्जा लिन सकिने स्थान हो। विस्तृत जानकारीको लागि, भिडियो हर्नुहोस्।  </p>
        <br/>
        <div className="youtube">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/5GNHVfoQd0s?si=JCxXRHx1bwIt-3EO" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        </section>
        <section>
            <h1>विदेशमा क्यूश्यू लोगो मार्क प्रयोगको अवस्था</h1>
            <p>क्यूश्यू लोगो मार्क, विदेशमा पनि प्रयोग भइरहेको छ। </p>
            <p><strong>◎BOKKSU कम्पनी</strong></p>
            <p>"क्यूश्यू ओखासी बक्स" कल्चर गाइडमा प्रकाशित </p>
            <figure>

           <img src="./images/kyushulogomark/logomark-bkksu.jpg" alt="BOKKUS"/>
            </figure>
            <p className="note">क्यूश्यूका विभिन्न किसिमका मिठाईले भरिएको "क्यूश्यू मिठाई बक्स" मा, मिठाईको बारेमा वर्णन, क्यूश्यूको इतिहास, संस्कृति, पर्यटकीय स्थलको बर्णन उल्लेखित कलल्चर गाइडबुक पनि राखिएको छ</p>

            <p><strong>◎मारुताइ कम्पनी</strong></p>
            <p>"बोउ रामेन स्थानीय सिरिज" (कूल ८ उत्पादनहरु) प्याकेजको पछाडि भागमा छापिएको</p>
            <figure>
           <img src="./images/kyushulogomark/logomark-marutai.jpg" alt="Marutai"/>
            </figure>
            <p className="note">हाकाता तोन्कोचु रामेन, कुमामोतो कुरो माःयु तोन्कोचु रामेन, खागोसिमा कुरोबुटा (कालो बंगुर) तोन्कोचु रामेन</p>

            <p><strong>◎DAISHO कम्पनी</strong></p>
            <p>"याम तेप्पन्याकी आधार" प्याकेजको पछाडि भागमा छापिएको</p>
            <figure>
            <img src="./images/kyushulogomark/yamaimoteppan.jpg" alt="山芋鉄板焼きの素"/>
            </figure>
        </section>
        
        <div className="deco-fukidashi navigatorCheck right smile">
        क्यूश्यूको विस्तृत दृष्यावलोकन जानकारीको लागि, " Visit Kyushu" हेर्नुहोस्। 
        </div>
       
        <p>Visit Kyushu</p>
        <a href="https://www.welcomekyushu.com/" target="_blank">
            <figure>
            <img src='./images/banner/tabinet.png' alt="Visit Kyushu（九州旅ネット）" className="img-fit"/>
            </figure>
        </a>
        </div>
    )
}

export default LangNe;