import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangNe = () => {

    return (
        <Article>
            <p>
            फुकुओका अध्यागमन ब्यूरोमा、निवासको स्थिति सम्बन्धी विभिन्न प्रक्रियाहरू पूरा गर्दछ।<br/>
            (बसाइको अवधि विस्तार、बसोबासको स्थिति परिवर्तन、स्थायी बसोबासको लागि आवेदन、पार्ट टाईम जब गर्नको लागि पहिले प्रदान गरिएको आवासको स्थिति अन्तर्गत अनुमति दिइएको बाहेक अन्य गतिविधिहरूमा संलग्न हुन अनुमतिको प्रक्रियाहरू、विदेशीमा हुने बिदेशीलाइ आमन्त्रित गर्ने प्रक्रियाहरू आदि ) । <br/>
            　मुख्यतया निवास स्थिति इत्यादि सम्बन्धी अध्यागमन प्रक्रियाहरूको निर्देशनात्मक कार्य सम्पन्न गर्न परामर्श डेस्कको रूपमा、विदेशी बासिन्दाहरूको लागि एक सामान्य परामर्श सूचना केन्द्र स्थापना गरेका छौं।भर्खरका वर्षहरू、विदेशी बासिन्दाहरूले आफ्नो दैनिक जीवनमा सामना गर्ने समस्याहरूको मद्दत गर्न बढ्दो आवश्यकताको कारण、केन्द्रमा、सामान्य अध्यागमन प्रक्रियाहरूमा निर्देशनको अतिरिक्त、सामान्य जीवनसँग सम्बन्धित परामर्शहरूमा पनि प्रतिक्रिया दिनुका साथै、निवास सहयोगको लागी एक प्रवेश बिन्दु को रूप मा कार्य गर्दछ।
            </p>
            <NavigatorAction dir="right" actionType="pickup" charactorType="normal"></NavigatorAction>

            <EmphasisNote>
            <h2>परामर्श गर्न सक्ने व्यक्ति</h2>
            <p>
            ●विदेशी बासिन्दाहरू तथा उनीहरूका परिवारहरू、सम्बन्धित पक्षहरू इत्यादि
            </p>
            <figure>
            <img src={publicPath('images/articles/nyukan02.png')} alt="फुकुओका अध्यागमन ब्यूरो (आवेदन काउन्टर)"/>
            <figcaption>फुकुओका अध्यागमन ब्यूरो (आवेदन काउन्टर)</figcaption>
            </figure>
            <h2>सुविधा सम्बन्धी जानकारी</h2>
            <p>
            ●सुविधाको नाम ／　<br/>
            फुकुओका अध्यागमन ब्यूरो परिक्षण व्यवस्थापन विभाग<br/>
            ●स्थान／　<br/>
            〒८१०-००७३ फुकुओका शहर चुयो वार्ड माइजुरी३-५-२५ फुकुओका पहिलो कानूनी मामिला सामान्य कार्यालय भवन छैठौं तला<br/>
            ●टेलिफोन नम्बर: ०९२-७१७-७५९५<br/>
            ●खुल्ने समय／९:००～१६:०० [शनिबार・आइतबार・सार्वजनिक बिदा・वर्षको अन्त्य र नयाँ वर्षको बिदाको समयमा कार्यालय बन्द] <br/>
            ●दोभाषे छैन
            </p>
            <p>
            सूचना केन्द्रले आवेदन फारम कसरी भर्ने भन्ने बारेमा निर्देशन प्रदान गर्नेछ।<br/>
            ※※सूचना केन्द्र खुल्ने समय एप्लिकेसन काउन्टरको जस्तै हो। अङ्ग्रेजी・चिनियाँ・कोरियाली भाषा उपलब्ध छ।
            </p>
            <h2>अध्यागमन सेवा एजेन्सी होमपेज URL</h2>
            <p>
            【URL】<br/>
            <a href="https://www.moj.go.jp/isa/index.html" target="_blank">https://www.moj.go.jp/isa/index.html</a>
            </p>
            <h2>फुकुओका अध्यागमन ब्यूरो फेसबुक URL</h2>
            <p>
            【URL】<br/>
            <a href="https://www.facebook.com/fukuoka.nyukan.support" target="_blank">https://www.facebook.com/fukuoka.nyukan.support</a>
            </p>
            <FukidashiRedLeft>
            निवास स्थितिको बारेमा जानकारीको अतिरिक्त、आवेदन काउन्टर भीड स्थिति तथा अन्य उपयोगी जानकारीको बारेमा पनि पोस्टगर्छौं।कृपया हामीलाई लाइक र फलो गर्नुहोस।
            </FukidashiRedLeft>
            <figure>
            <img src={publicPath('images/articles/nyukan_fb_qr.png')} alt="फुकुओका अध्यागमन ब्यूरो फेसबुक "/>
            <figcaption>फुकुओका अध्यागमन ब्यूरो फेसबुक </figcaption>
            </figure>

            </EmphasisNote>
            <br/>
            <NavigatorAction dir="right" actionType="pickup" charactorType="pointing"></NavigatorAction>

            </Article>
    )
}

export default LangNe