const LangZhCn = () => {

    return (<>
    <h1>关于个人信息保护</h1>
    <p>
    本门户网站（<a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a>）上，根据「个人信息保护法」和「福冈县个人信息保护法的施行条例（令和4年福冈县条例43号）」，此后会妥善处理个人信息的收集、使用和管理。请注意，针对上述的个人信息处理的声明已于2023年4月1日进行了更新。
    </p>
    <p>
    福冈县的个人信息保护制度主页，请点击这里（<a href="http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html" target="_blank">http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html</a>）"
    </p>
    <p>
    <dl>
    <dt>●个人信息是指</dt>
    <dd>
    涉及个人的信息，可以识别或可能识别特定个人的信息（包括地址、姓名、电话号码、电子邮件地址等）
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●关于个人信息的收集</dt>
    <dd>
    FUKUOKA IS OPEN推进事业执行委员会（以下简称为「执行委员会」）在通过本门户网站进行个人信息收集的时候，将明确收集目的，并仅在达到该目的所必需的范围内进行收集。"
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●安全性的确保</dt>
    <dd>
    对于收集的个人信息，我们将防止信息泄漏、丧失、篡改等，并严格进行管理。同时，一旦不再需要保留的个人信息，我们将迅速进行销毁（删除）处理。"
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●关于Cookie</dt>
    <dd>
    <p>为了一部分网页能正常使用，其中称为「Cookie」的技术将会被利用。</p>
    <p>
    「Cookie」是一种业界标准的技术，用于识别Web服务器上的用户计算机，它实际上是一种小容量的文本文件。"
    </p>
    <p>
    通常，通过门户网站的用户操作提供给网站管理员的个人信息（如用户ID和密码）、操作历史以及访问情况和趋势，之后会被分析作为统计信息等用途被使用。
    </p>
    <p>
    另外，「Cookie」虽然能够识别用户的计算机，但除非用户自行输入或提供详细的个人信息，否则无法特定或识别个别用户身份。
    </p>
    <p>
    本门户网站允许匿名浏览和使用，无论「Cookie」功能是否开启。
    </p>
    <p>
    另外，请注意，根据您使用的浏览器，您可以更改设置以禁用Cookie功能，但结果可能导致无法使用某些页面的全部或部分服务。
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●使用和提供的限制</dt>
    <dd>
    <p>
    实行委员会收集的个人信息，除了「个人信息保护法」或者「福冈县个人信息保护法的施行条例」规定的情况，将不会超出收集目的进行使用，也不会向实行委员会以外的任何方提供。"
    </p>
    <p>
    然而，关于本门户网站的访问信息、向执行委员会的您的提案、意见以及与这些相关的个人属性，我们可能会对经过统计处理的数据或意见等进行公开。
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●关于社交媒体（SNS）按钮的注意事项</dt>
    <dd>
    <p>
    本门户网站当中，当浏览设置有社交媒体（SNS）按钮等的页面时，有关浏览者的信息可能会被发送到相应的SNS。在本门户网站上使用SNS等的服务隐私政策等，请您在各服务提供商的网站上确认。
    </p>
    </dd>
    </dl>
    </p>
    <p>
        <dl>
        <dt>●关于Google Analytics的注意事项</dt>
        <dd>
        本门户网站，为了了解门户网站的使用情况，我们使用了Google Analytics。<br/>
        Google Analytics使用Cookie来收集用户信息。查看Google Analytics的使用条款，请点击此处<br/>
        （在新窗口中打开 <a href=" https://www.google.com/analytics/terms/jp.html" target="_blank">https://www.google.com/analytics/terms/jp.html </a>）。
        </dd>
        </dl>
    </p>
    <p>
        <dl>
        <dt>●适用范围</dt>
        <dd>
        本隐私政策仅适用于本门户网站（<a href="https://fisop.net" target="_blank">https://fisop.net</a> 以下的目录）。"
        </dd>
        </dl>
    </p>
    </>)
}

export default LangZhCn