import PageBack from "@/components/Molecules/PageBack"
import PageTitleGwm from "@/components/Molecules/PageTitle/gwm"
import Article from "@/components/Organisms/Article"
import SubLayerTemplate from "@/components/Templates/subLayer"
import styled from "styled-components"
import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { pathAnalyzer } from "src/utilty/pathAnalyzer"
import { useLocation } from "react-router-dom"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio"

const SGwm = styled.div`
    margin-top: -55px;
    .governor{
        max-width: 400px;
    }
`

const GwmPage = () => {
    const location = useLocation()
    const lang = pathAnalyzer(location).lang
    setTitleDiscrption({
        layer:'gwm',
        lang:lang,
    })

    return (
        <ResponsiveFioTemplate>
            <SubLayerTemplate>
            <PageTitleGwm lang="ja"></PageTitleGwm>
           
            <SGwm>
                <Article>
                {lang === 'ja' &&
                    <LangJa></LangJa>
                }
                {lang === 'easy_ja' &&
                    <LangJa></LangJa>
                }
                {lang === 'en' &&
                    <LangEn></LangEn>
                }
                {lang === 'ko' &&
                    <LangKo></LangKo>
                }
                {lang === 'zh_cn' &&
                    <LangZhCn></LangZhCn>
                }
                {lang === 'zh_tw' &&
                    <LangZhTw></LangZhTw>
                }
                {lang === 'th' &&
                    <LangTh></LangTh>
                }
                {lang === 'vi' &&
                    <LangVi></LangVi>
                }
                {lang === 'ne' &&
                    <LangNe></LangNe>
                }
                </Article>
            </SGwm>
            <PageBack backto="/?current=1"></PageBack>
            </SubLayerTemplate>
        </ResponsiveFioTemplate>
    )
}

export default GwmPage