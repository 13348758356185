


const LangEn = () => {

    return (
        <div className='article-inner'>
        <section>
        <h1>The Kyushu Logo</h1>
        <ul className="photos">
            <li>
                <img src="./images/kyushulogomark/logomark01.jpg" alt="The Kyushu Logo"/>
            </li>
            <li>
                <img src="./images/kyushulogomark/logomark02.jpg" alt="The Kyushu Logo ※のれん"/>
            </li>
        </ul>
        <p>
        This logo is used to promote Kyushu to the whole country as well as the world. The Kyushu logo was designed with a noren* curtain motif to show that we welcome people through the Kyushu noren curtains. The kanji characters of “Kyu”, `”Shu'', and “One” are combined together to make up the logo, representing how multiple regions of Kyushu work together as one. <br/>
        *Noren is a fabric that is hung at the entrance of a store to shade the sun. This is an unique Japanese culture, and the store's name or trademark is often displayed on the noren itself.
        </p>
        <br/>
        </section>
        <section>
        <h1>About Kyushu</h1>
        <figure>
        <img src="./images/kyushulogomark/kyushu.jpg" alt="九州について"/>
        </figure>
        <p>Kyushu is located at the westernmost tip of Japan. It is a place where you can enjoy everything Japan has to offer including the magnificent and beautiful nature of mountains and sea, hot springs, history, culture, and amazing food. Please refer to the video for more details.</p>
        <br/>
        <div className="youtube">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/5GNHVfoQd0s?si=JCxXRHx1bwIt-3EO" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        </section>
        <section>
            <h1>Kyushu logo usage record overseas</h1>
            <p>The Kyushu logo mark is also used overseas.</p>
            <p><strong>◎BOKKSU INC.</strong></p>
            <p>"Kyushu Snack BOX" Published in culture guide.</p>
            <figure>
            <img src="./images/kyushulogomark/logomark-bkksu.jpg" alt="BOKKUS"/>
            </figure>
            <p className="note">（The "Kyushu Snack BOX", which is an assortment of Kyushu Snack, comes with a culture guide that describes information of snacks, Kyushu's history, culture, and sightseeing spots.）</p>
            
            <p><strong>◎Marutai Co., Ltd.</strong></p>
            <p>Bar ramen local series (8 products in total)Posted on the back of the package</p>
            <figure>
            <img src="./images/kyushulogomark/logomark-marutai.jpg" alt="Marutai"/>
            </figure>
            <p className="note">（Hakata Tonkotsu Ramen, Kumamoto Black Mer Oil Tonkotsu Ramen, Kagoshima Kurobuta Tonkotsu Ramen）</p>

            <p><strong>◎DAISHO Co., Ltd.</strong></p>
            <p>Published on the back of the “Yamaimo Teppanyaki Kit” package</p>
            <figure>
            <img src="./images/kyushulogomark/yamaimoteppan.jpg" alt="山芋鉄板焼きの素"/>
            </figure>
        </section>


        <div className="deco-fukidashi navigatorCheck right smile">
        For detailed tourist information on Kyushu, please see the website "Visit Kyushu".
        </div>
       
        <p>Visit Kyushu</p>
        <a href="https://www.welcomekyushu.com/" target="_blank">
            <figure>
            <img src='./images/banner/tabinet.png' alt="Visit Kyushu（九州旅ネット）" className="img-fit"/>
            </figure>
        </a>
        </div>
    )
}

export default LangEn;