const LangNe = () => {

    return (<>
    <h1>व्यक्तिगत जानकारी सुरक्षा बारे</h1>
    <p>यो पोर्टल साइट (<a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a>) मा 、व्यक्तिगत जानकारीको सङ्कलन, प्रयोग र व्यवस्थापन बारेमा、「व्यक्तिगत सूचना संरक्षण ऐन」र「व्यक्तिगत सूचना संरक्षण ऐन कार्यान्वयन अध्यादेश（२०२२ साल फुकुओका प्रान्त अध्यादेश नम्बर ४३）」निम्नानुसार उपयुक्त रूपमा उपयोग हुनेछ। कृपया ध्यान दिनुहोस् कि व्यक्तिगत जानकारीको उपयोगको विवरण अप्रिल १ २०२३ सालमा अद्यावधिक गरियो। फुकुओका प्रिफेक्चर व्यक्तिगत जानकारी सुरक्षा प्रणाली शीर्ष पृष्ठको लागि यहाँ क्लिक गर्नुहोस् 
    (<a href="http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html" target="_blank">http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html</a>)
    </p>
    
    <p>
        <dl>
        <dt>●व्यक्तिगत जानकारी के हो?  </dt>
        <dd>
        व्यक्तिको बारेमा जानकारी、विशिष्ट व्यक्ति पहिचान भइ、पहिचान गर्न सकिने व्यक्ति (ठेगाना、नाम、टेलिफोन नम्बर、इ-मेल ठेगाना आदि) लाई भनिन्छ।    व्यक्तिगत जानकारी को संग्रहको बारे मा  यस पोर्टल साइट मार्फत FUKUOKA IS OPEN प्रवर्द्धन परियोजना कार्यकारी समिति（यसपछि「कार्यकारी समिति」भनिन्छ ।）ले व्यक्तिगत जानकारी सङ्कलन गर्दा, हामी सङ्कलनको उद्देश्य स्पष्ट गर्नेछौं,र त्यो उद्देश्य हासिल गर्न आवश्यक हदसम्म व्यक्तिगत जानकारी सङ्कलन गर्नेछौं।
        </dd>
        </dl>
    </p> 
    <p>
        <dl>
        <dt>●सुरक्षा सुनिश्चित गर्दै</dt>
        <dd>
        संकलित व्यक्तिगत जानकारी सम्बन्धमा、चुहावट、नाश、झूटो आदि रोक्ने र、कडाइका साथ व्यवस्थापन गरिनेछ ।थप रूपमा、राख्न आवश्यक नभएको व्यक्तिगत जानकारीलाई तुरुन्तै खारेज (मेटाइने) गरिनेछ। 
        </dd>
        </dl>
    </p> 
    <p>
        <dl>
        <dt>●कुक्की（Cookie）बारेमा </dt>
        <dd>
        केहि पृष्ठहरू प्रयोग गर्नका लागि、「कुक्की（Cookie） 」भन्ने प्रविधि प्रयोग भएको पृष्ठहरू छन्। 「कुक्की」भनेको、वेब सर्भरले आफ्नो कम्प्युटर पहिचान गर्नको लागि प्रयोग गरिने उद्योग मापदण्ड को प्रविधि भइ、वास्तविकतामा एउटा सानो पाठ फाइल हो।<br/>
        सामान्यतया、पोर्टल साइटको प्रयोग गर्ने सबैले गरेको सञ्चालनबाट साइटको व्यवस्थापकलाइ प्रदान गरिएको व्यक्तिगत जानकारी（युजर आईडी र पासवर्ड）तथा सञ्चालन इतिहासको रेकर्ड तथा、पहुँच स्थिति र प्रवृत्ति विश्लेषण गरी、तथ्याङ्कीय जानकारीको रूपमा प्रयोग गर्ने आदिको उद्देश्यका लागि प्रयोग गरिन्छ।<br/>
        थप रूपमा, 「कुक्की」प्रयोगकर्ता सबैको कम्प्युटर पहिचान गर्न सक्छ तर प्रयोगकर्ताहरूले आफैं विस्तृत व्यक्तिगत जानकारी लेख्ने・प्रदान नगरेसम्म、व्यक्तिगत प्रयोगकर्ताहरू पहिचान・परिचय हुन सक्दैन।<br/>
        यो पोर्टल साइटमा、「कुक्की 」कार्यक्षमता जस्तोसुकै भए तापनि、बेनामी को रुपमा हेर्न・ प्रयोग गर्न सकिन्छ। साथै、प्रयोग गरेको ब्राउजर को आधारमा、त्यो सेटिङ परिवर्तन गरेर कुक्कीको सुविधा असक्षम गर्न सक्नुहुन्छ तर、नतिजा、केही पृष्ठहरू को सेवा को सबै अथवा आंशिक सेवाहरू प्रयोग गर्न सक्षम नहुन सक्नुहुन्छ।
        </dd>
        </dl>
    </p> 
    <p>
        <dl>
            <dt>●सामाजिक मिडिया (SNS) बटन सम्बन्धी नोटहरू</dt>
            <dd>यस पोर्टल साइटमा、सामाजिक मिडिया (SNS)को  बटनहरू इत्यादि भएका पृष्ठहरू हेर्दा, दर्शकको बारेमा जानकारी SNS मा पठाउन सकिन्छ। कृपया यस पोर्टल साइटमा प्रयोग गरिएका SNS र अन्य सेवाहरूको गोपनीयता नीतिहरू आदि、सम्बन्धित कम्पनीहरूको वेबसाइट आदि हरूमा जाँच गर्नुहोस्।</dd>
        </dl>
    </p>
    <p>
        <dl>
            <dt>●Google Analytics सम्बन्धी नोटहरू</dt>
            <dd>
            यस पोर्टल साइटमा、पोर्टल साइट को उपयोग स्थिति बुझ्न Google Analytics को प्रयोग गर्नुहोस। Google Analytics、कुक्कीहरू प्रयोग गरेर प्रयोगकर्ता जानकारी सङ्कलन गर्छौ।Google Analytics प्रयोगका सर्तहरूको लागि यहाँ क्लिक गर्नुहोस्(हेर्नुहोस)।<br/>
            （ नयाँ विन्डोमा खुल्छ  <a href=" https://www.google.com/analytics/terms/jp.html" target="_blank">https://www.google.com/analytics/terms/jp.html </a>)। 
            </dd>
        </dl>
    </p>
    <p>
        <dl>
            <dt>●अनुप्रयोगको दायरा </dt>
            <dd> यस गोपनीयता नीति、यस पोर्टल साइट（<a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a>  निम्न निर्देशिका）मा मात्र लागू हुन्छ।</dd>
        </dl>
    </p>
    </>)
}

export default LangNe