const LangVi = () => {

    return (<>
    <h1>Bảo hộ thông tin cá nhân</h1>
    <p>
    Tại Cổng thông tin điện tử (<a href="https://www.fisop.net" target="_blank">https://www.fisop.net</a>), chúng tôi sẽ thu thập, sử dụng và quản lí thông tin cá nhân theo quy định tại "Luật Bảo hộ Thông tin Cá nhân" và “Nghị định thực hiện Luật Bảo hộ Thông tin Cá nhân của tỉnh Fukuoka (Quy định số 43 của tỉnh Fukuoka năm 2022)". Thông báo về việc sử dụng thông tin cá nhân này đã được cập nhật vào ngày 1 tháng 4 năm 2023.
    </p>
    <p>
    Trang chủ của Hệ thống Bảo hộ Thông tin Cá nhân tỉnh Fukuoka có thể tìm thấy tại đây. (<a href="http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html" target="_blank">http://www.pref.fukuoka.lg.jp/contents/kojinjouhouhogo-top.html</a>）
    </p>
    <p>
    <dl>
    <dt>● Thông tin cá nhân </dt>
    <dd>
    Thông tin cá nhân là thông tin liên quan đến một cá nhân cụ thể, có thể nhận diện được hoặc có khả năng nhận diện là một cá nhân cụ thể thông qua địa chỉ, tên, số điện thoại, địa chỉ email…
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●Về việc thu thập thông tin cá nhân</dt>
    <dd>
    Thông qua Cổng thông tin điện tử này, Ban tổ chức dự án xúc tiến FUKUOKA IS OPEN (sau đây gọi là 'Ban tổ chức') khi thu thập thông tin cá nhân sẽ xác định rõ mục đích của việc thu thập và chỉ thu thập trong phạm vi cần thiết để đạt được mục tiêu đó."
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●"Đảm bảo tính an toàn</dt>
    <dd>
    Đối với những thông tin cá nhân đã được thu thập, chúng tôi sẽ quản lý một cách nghiêm ngặt để tránh rò rỉ, mất mát, hoặc sửa đổi. Hơn nữa, với thông tin cá nhân không còn cần thiết thì sẽ được hủy bỏ (xóa) ngay lập tức."
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●"Về Cookie</dt>
    <dd>
    <p>Một số trang bạn truy cập có sử dụng công nghệ gọi là 'Cookie'.</p>
    <p>
    'Cookie' là một công nghệ tiêu chuẩn trong ngành được sử dụng để máy chủ nhận diện máy tính của bạn. Trên thực tế đó là một tệp văn bản với dung lượng nhỏ."
    </p>
    <p>
    Thông thường, thông tin cá nhân (ID và mật khẩu) hoặc lịch sử thao tác sẽ được cung cấp cho người quản trị trang web thông qua các thao tác của người dùng trên Cổng thông tin điện tử để phân tích tình trạng và xu hướng truy cập và sử dụng làm thông tin thống kê.
    </p>
    <p>
    Hơn nữa, 'Cookie' có thể nhận diện máy tính của người dùng, nhưng nếu người dùng không tự nhập hoặc cung cấp thông tin cá nhân chi tiết, thì không thể xác định hoặc nhận biết được người dùng.
    </p>
    <p>
    Cổng thông tin điện tử này cho phép người dùng duyệt web và sử dụng mà không cần bật chức năng "Cookie".
    </p>
    <p>
    Tuy nhiên, tùy thuộc vào trình duyệt đang sử dụng, bạn có thể tắt chức năng 'Cookie' bằng cách thay đổi cài đặt, nhưng điều này có thể dẫn đến việc không thể sử dụng hoặc chỉ có thể sử dụng một phần dịch vụ trên một số trang.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●"Giới hạn về Sử dụng và Cung cấp</dt>
    <dd>
    <p>
    Thông tin cá nhân được Ban tổ chức thu thập sẽ không được sử dụng ngoài mục đích đã xác định trước đó hoặc cung cấp cho bên thứ ba ngoại trừ những trường hợp được quy định bởi Luật bảo hộ thông tin cá nhân hoặc Nghị định thực hiện Luật bảo hộ thông tin cá nhân của tỉnh Fukuoka."
    </p>
    <p>
    Tuy nhiên, về thông tin về tình trạng truy cập Cổng thông tin điện tử, các đề xuất và ý kiến gửi đến Ban tổ chức, cũng như các thuộc tính cá nhân liên quan, dữ liệu sau khi được thống kê, xử lí và ý kiến đóng góp có thể sẽ được công bố.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>●Các lưu ý về Nút chia sẻ trên Mạng xã hội (SNS)</dt>
    <dd>
    <p>
    Một số trang trên Cổng thông tin điện tử này có cài đặt nút chia sẻ trên Mạng xã hội (SNS). Khi xem trang này, thông tin của người xem có thể được gửi đến SNS tương ứng. Về chính sách bảo mật của dịch vụ SNS đang sử dụng trên Cổng thông tin điện tử này..., hãy kiểm tra trang web của nhà phân phối dịch vụ liên quan."
    </p>
    </dd>
    </dl>
    </p>
    <p>
        <dl>
            <dt>●Các lưu ý về Google Analytics</dt>
            <dd>
            Trang web của chúng tôi sử dụng Google Analytics để nắm bắt về tình trạng sử dụng của Cổng thông tin điện tử. Google Analytics sử dụng Cookie để thu thập thông tin về người sử dụng. Vui lòng xem Điều khoản sử dụng của Google Analytics tại đây <br/>
            (mở cửa sổ mới tại  <a href=" https://www.google.com/analytics/terms/jp.html" target="_blank">https://www.google.com/analytics/terms/jp.html </a>).
            </dd>
        </dl>
    </p>
    <p>
        <dl>
            <dt>●Phạm vi áp dụng</dt>
            <dd>
            Chính sách bảo mật này chỉ áp dụng cho trang web này (<a href="https://fisop.net" target="_blank">https://fisop.net</a> và các thư mục liên quan)
            </dd>
        </dl>
    </p>
    </>)
}

export default LangVi