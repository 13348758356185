import publicPath from "src/utilty/publicPath";


const LangJa = () => {

    return (
        <>
        <h1>このサイトへのリンクについて</h1>
        <p>
        ●リンクについて<br/>
        「FUKUOKA IS OPEN推進事業ポータルサイト」（<a href="https:// fisop.net" target="_blank">https:// fisop.net</a>）へのリンクは自由に設定していただいて結構です。
        トップページ以外へリンクされる場合は、ページの構成変更等によりリンクがとぎれることがありますのでご了承ください。<br/>
        リンクを張った場合は、 <a href="mailto:intpol@pref.fukuoka.lg.jp" target="_blank">intpol@pref.fukuoka.lg.jp</a> 宛へメールを送ってください。リンク元を確認させていただきます。メールには、 リンク元アドレス、お名前（所属）、連絡先（メールアドレス）を記入してください。
        </p>
        <p>
        「FUKUOKA IS OPEN推進事業ポータルサイト」へのリンクには、バナーをご利用いただけます。<br/>
        バナーには、次の３種類がありますので、ご希望のものをダウンロードしてお使いください。<br/>
        また、バナー画像の代替テキストには、「FUKUOKA IS OPEN推進事業ポータルサイト」へのリンクである旨を記載してください。
        </p>
        <p>横468ピクセル × 縦60ピクセル
        <figure>
            <img src={publicPath('images/fio_banner/fio_468_60.png')}
            className="img-fit"/>
        </figure>
        </p>
        <p>横234ピクセル × 縦60ピクセル
        <figure>
            <img src={publicPath('images/fio_banner/fio_234_60.png')}
            className="img-fit"/>
        </figure>
        </p>
        <p>横120ピクセル × 縦90ピクセル
        <figure>
            <img src={publicPath('images/fio_banner/fio_120_90.png')}
            className="img-fit"/>
        </figure>
        </p>
        <p>
        また、下記の項目にご注意ください。<br/>
        ●当ポータルサイトへのリンクを作成・利用したことにより賠償・苦情などが生じた場合、当実行委員会は責任を負いかねますのでご了承ください。<br/>
        ●当ポータルサイトのURLは予告なく変更する場合がありますので、あらかじめご了承ください。<br/>
        ●当実行委員会、または他団体、他者を誹謗中傷する内容や信用失墜を意図する内容を含んだウェブページからのリンクはご遠慮ください。<br/>
        ●当実行委員会、または他団体、他者の著作権、商標権等の知的財産権、財産、プライバシーもしくは肖像権、その他の権利を侵害する恐れのあるウェブページからのリンクはご遠慮ください。<br/>
        ●当ポータルサイトのコンテンツであることが不明確となり、第三者の誤解を招く可能性がある表現を伴うリンク（画像ファイル又は動画ファイル等への直接のリンクやフレームを利用したHTMLページ内で表示させる形式でのリンク等）はご遠慮ください。<br/>
        ●法律、条例、規則を含む法令または公序良俗に違反する行為、当実行委員会の運営を妨害するウェブページでのリンクはご遠慮ください。
        </p>
        <br/>
        <br/>
        </>
    )
}

export default LangJa