import { type } from "os";
import { useEffect, useState } from "react";
import publicPath from "src/utilty/publicPath";
import styled from "styled-components";

const SMenuBtn = styled.div`
  display: inline-block;
  background:  ${props  => props.theme.colors.black};
  clip-path: polygon(0% 100%, 30% 0%, 100% 0%, 100%100%);
  padding: 8px 5px 8px 25px;
  border-bottom:#fff 2px solid;
  position: relative;
  width: 80px;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: -4px;
    left:11px; /* 左上の頂点の位置に合わせる */
    width: 0;
    height: 113%;
    border-left: 3px solid #fff; /* 左側のボーダーを斜辺に見せる */
    transform: rotate(19deg);
   
  }
  .menu_icon{
    width: 100%;
    padding-bottom: 30px;
    background: url(${publicPath('images/menu_humberger.svg')}) no-repeat;
    background-size: auto 100%;
    display: block;
   
  }
  .menu_name{
    width: 100%;
    padding-bottom: 20%;
    background: #fff;
    display: block;
    margin-top: 8px;
    margin-left: -5px;
    background: url(${publicPath('images/menu.svg')}) no-repeat;
    background-size: 100% auto;
  }
  &.close_after{
    .menu_icon{
        animation: spin 1s linear forwards;
    }
  }
  @keyframes spin {
    from {
        transform: rotateY(180deg);
    }
    to {
        transform: rotateY(360deg);
    }
 }
`

const SCloseBtn = styled(SMenuBtn)`
     border-bottom:${props  => props.theme.colors.black} 2px solid;
    &::after{
        display: none;
    }
    .menu_icon{
        background: url(${publicPath('images/menu_humberger_close.svg')}) no-repeat;
        background-size: 100% auto;
        animation: spin 1s linear forwards;
    }
    .menu_name{
        background: url(${publicPath('images/menu_close.svg')}) no-repeat;
        background-size: 100% auto;
    }
     
`


export type HamburgerActiveType = 0 | 1

interface HamburgerBtnProps {
    isActive: boolean,
    onClick : () => void
}

export const MenuButton = (
    {isActive, onClick,...props} : HamburgerBtnProps
) => {
    
    const [afterClose, setAfterClose] = useState<boolean>(false)

    useEffect(() => {
        if(isActive) setAfterClose(true)
    },[isActive])

    return(
        <>
        { ! isActive  &&
            <SMenuBtn onClick={onClick} className={afterClose ? 'close_after' : ''}>
                <span className="menu_icon"></span>
                <span className="menu_name"></span>
            </SMenuBtn>
        }
        {
        isActive &&
            <SCloseBtn onClick={onClick}>
                <span className="menu_icon"></span>
                <span className="menu_name"></span>
            </SCloseBtn>
        }
        </>
    )
}