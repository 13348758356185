import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { TrapezoidExcl } from "@/components/Molecules/CharactorHeadline/challenger"
import Article from "@/components/Organisms/Article"


const LangEn = () => {

    return (<>
        <Article offset={0} className="pc-noTopMargin">

        <div className="pc-flex col-2">
            <div className="pc-only">
                <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
            </div>
            
            <BlackNote>
                <ul className="deco">
                <li>
                <p className="large">
                Population of Fukuoka Prefecture<br/>
                5,117,967
                </p>
                <p className="al-c">(9th in Japan in 2020)</p>
                <span className="txt-small">
                Source: Research and Statistics Division, Prefectural Government
                </span>
                </li>
                </ul>
            </BlackNote>
        </div>
        </Article>
        <TrapezoidExcl dir="left">
        Area of Fukuoka Prefecture<br/>
        </TrapezoidExcl>
        <Article offset={0} className="pc-noTopMargin">
        <div className="pc-flex col-2">
            <div className="pc-only">
                <NavigatorAction charactorType="smile" dir="right" actionType="check" actionColor="blue"/>
            </div>
            <BlackNote>
            <ul className="deco">
            <li>
            <p className="large">
            Area of Fukuoka Prefecture <br/>
            4,987㎢
            </p>
            <p className="al-c">(2020, 29th in Japan)</p>
            <span className="txt-small">
            Data: Ministry of Land, Infrastructure, Transportation and Tourism, National Institute of Land Geography.
            <br/>
            (National Survey of the Area of Urban Areas in Prefectures in the 4th Year.)
            </span>
            </li>
            </ul>
            </BlackNote>
        </div>
        <div className="navi-position sp-only">
            <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
        </Article>

        <TrapezoidExcl dir="left">
        Climate of Fukuoka Prefecture / Fukuoka Climate<br/>
        </TrapezoidExcl>
        <Article offset={0} className="pc-noTopMargin">
        <BlackNote>
        <ul className="deco pc-flex col-2">
        <li>
        <p className="large">
        Average annual temperature<br/>
        17.3℃
        </p>
        </li>
        <li>
        <p className="large">
        Average annual humidity<br/>
        68％
        </p>
        </li>
        <li>
        <p className="large">
        Annual sunshine hours<br/>
        1,889.4 hours
        </p>
        </li>
        <li>
        <p className="large">
        Annual precipitation<br/>
        1,686.9mm
        </p>
        <p>Average value for 30 years from 1991 to 2020</p>
        <p className="txt-small">
        Source: Fukuoka District Meteorological Observatory
        </p>
        </li>
        </ul>
        
        </BlackNote>
            <div className="sp-only">
                <NavigatorCheck dir="right"></NavigatorCheck>
            </div>
        </Article>
        <InnerBox top={40} bottom={40}>
        </InnerBox>
    </>)
}

export default LangEn