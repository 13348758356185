import { ArticleObj } from "src/types/article";
import { langType } from "src/types/lang";

export const articleTitleSelect = (lang:langType, article:ArticleObj) => {

    let title
    if(lang === 'ja'){
        title = article.title
    }else{
        if(article.langTitle){
            
            if( article.langTitle[lang]){
                title =  article.langTitle[lang]
            }else if(article.langTitle['en']){
                title = article.langTitle['en'];
            }else{
                title = article.title;
            }
        }else{
            title = article.title;
        }
    }

    return title

}