import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import InnerBox from "@/components/Atoms/Box/innerBox"


const LangKo = () => {

    return (<>
        <div className="pc-wapper">
        <InnerBox>
        <h1>
        Consulate
        </h1>
        </InnerBox>
        <BlackNote>
        <ul className="deco">
        <li>
        <p className="name">
        U.S. Consulate Fukuoka<br/> (Est. April 28, 1952)
        </p>
        <p className="address">
        810-0052 2-5-26 Ohori, Chuo-ku, Fukuoka City
        </p>
        <p className="tel">
        092-739-9088
        </p>
        </li>
        <li>
        <p className="name">
        Consulate General of South Korea, Fukuoka<br/>(Est. January 27, 1966)
        </p>
        <p className="address">
        810-0065 1-1-3 Jigyohama, Chuo-ku, Fukuoka
        </p>
        <p className="tel">
        092-771-0461
        </p>
        </li>
        <li>
        <p className="name">
        Consulate-General of The People's Republic of China in Fukuoka<br/>(Est. April 17, 1985)
        </p>
        <p className="address">
        810-0065 1-3-3 Jigyohama, Chuo-ku, Fukuoka City
        </p>
        <p className="tel">
        092-713-1121
        </p>
        </li>
        <li>
        <p className="name">
        Consulate-General of Vietnam in Fukuoka<br/>(Est. April 22, 2009)
        </p>
        <p className="address">
        810-0801 5-3-8, Nakasu, Hakata-ku, Fukuoka<br/>Aqua Hakata 4F
        </p>
        <p className="tel">
        092-263-7668
        </p>
        </li>
        <li>
        <p className="name">
        Royal Thai Consulate-General in Fukuoka<br/>( Est. October 1, 2018)
        </p>
        <p className="address">
        810-0001 4-1-37, Tenjin, Chuo-ku, Fukuoka-shi, Fukuoka <br/>2nd Floor Dai Ichi Myojo
        </p>
        <p className="tel">
        092-739-9088
        </p>
        </li>
        </ul>
        </BlackNote>
        </div>

        <div className="checkpostion01 sp-only">
            <div className="checkpostion01">
            <NavigatorCheck dir="right"></NavigatorCheck>
            </div>
        </div>

        <div className="pc-list-wrap">
        <InnerBox bottom={20}>
        <h1>자매 도시"</h1>
        <EmphasisNote>
        <h2>
        하와이주(미국)<br/>1981.9.25
        </h2>
        <p>
        1980년 9월 알로하 위크 페스티벌에 '하카타 기온 야마카사'가 참가하였고 때를 같이하여 '후쿠오카 토산물 전시회'가 개최되면서 두 현과 하와이주의 우호 촉진의 기운이 고조되어 각각의 의회에서 자매 관계 체결 촉진이 의결되었다.
        이에 따라 1년에 걸쳐 자매 관계 제휴에 관한 논의를 거듭해 1981년 9월 25일 하와이주 호놀룰루시에서 하와이 아리요시 주지사와 후쿠오카현 가메이 지사가 조인식을 가졌다.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        장쑤성(중국)
        <br/>1992.11.4
        </h2>
        <p>
        1992년 4월 중국 공산당 장쩌민 총서기가 현을 방문했을 때 지사가 중일 국교정상화 20주년에 해당하는 당해년도 내에 중국과의 우호 제휴 실현을 목표로 할 것임을 표명했다. 이에 따라 행정, 의회, 민간으로 구성된 우호 제휴 검토 위원회를 설립했다. 조사단 파견, 협의 등의 결과 장쑤성과의 우호 제휴를 결정했다.<br/>
        1992년 9월 우호 제휴 신청을 했으며 같은 해 11월 4일 장쑤성 난징시에서 장쑤성 진 성장과 후쿠오카현 오쿠다 지사가 조인식을 가졌다.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        방콕(태국)<br/>2006.2.8
        </h2>
        <p>
        아시아와의 관계를 더욱 심화시키기 위해 아시아 여러 지역 중 본 현의 주요 산업인 자동차산업을 중심으로 눈부시게 발전하는 태국과 교류하기로 했다. 그중에서도 정치, 경제, 문화의 중심지인 방콕을 후보 지역으로 결정했다. 2005년 10월 조사단을 파견해 방콕 아피락 지사와 면담을 하고 교류 의향을 확인한 결과 적극적인 답변을 얻었기 때문에 2006년 2월에 후쿠오카현 아소 지사를 단장으로 하는 방문단이 방콕을 방문하여 우호 제휴 협정서에 서명했다.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        델리 (인도)<br/>2007.3.5
        </h2>
        <p>
        아시아와의 관계를 더욱 심화시키기 위해 아시아 여러 지역 중 최첨단 IT, 자동차 산업을 중심으로 발전이 두드러지는 인도와 교류하기로 했다. 그중에서도 정치, 경제, 문화의 중심지인 델리를 후보 지역으로 결정했다. 2005년 10월에 조사단을 파견하고 2006년 2월에 후쿠오카현 아소 지사를 단장으로 하는 방문단이 델리를 방문해 델리 딕시트 수석장관과 면담을 갖고 우호 교류에 대해 긍정적인 의견을 교환했다. 인도 정부의 승인을 얻어 2006년 12월에 일인(日印)정상회담에서 본 현과 델리의 우호 제휴를 환영한다는 취지가 공동성명에 포함되면서 2007년 3월에 우호제휴를 체결했다.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        하노이시(베트남)<br/>2008.2.22
        </h2>
        <p>
        아시아와의 관계를 더욱 심화시키기 위해 아시아 여러 지역 중 풍부한 노동력과 근면한 국민성으로 눈부신 성장을 이루고 있는 베트남과 교류하기로 했다. 그중에서도 정치, 경제, 문화의 중심지인 하노이시를 후보 지역으로 결정했다. 2005년 10월에 조사단을 파견한 후 실무 차원의 교섭을 지속하여 2008년 2월에 후쿠오카현 아소 지사를 단장으로 하는 방문단이 하노이시를 방문하여 우호 제휴를 체결하였다.
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        한국 남쪽 해안의 1시 3도와 규슈북부 3현 및 야마구치현<br/><br/>공동선언문 발표
        </h2>
        <p>
        한일 양국은 지리적으로 가까운 위치에 있어 예로부터 교류가 활발했는데 특히 일본 규슈북부 3현(후쿠오카현, 사가현, 나가사키현)과 한국 남쪽 해안 1시 3도(부산광역시, 전라남도, 경상남도, 제주특별자치도)는 한일해협을 사이에 두고 교류 창구로서 큰 역할을 해왔다.<br/>이러한 지리적·역사적 배경 속에서 1990년 10월 나가사키현 쓰시마에서 규슈북부 3현 지사 간담회가 개최되어 양 지역 간의 교류 촉진에 대해 논의되었다. 이 논의에 따라 한국 측과 협의한 결과 1992년 7월 양 지역간의 교류 현황 및 향후 전망에 관해 솔직한 의견을 교환하고 다양한 분야에서의 교류를 촉진하기 위한 수장 교류회 개최 합의가 이루어졌으며 회의 명칭을 '한일해협연안 시도현지사 교류회의'로 정하게 되었다.<br/>
        　"1992년 8월 한국 제주도에서 '제1회 한일해협연안 시도현지사 교류회의'가 개최되어 한일해협연안 양지역의 교류를 촉진하기 위한 공동사업 실시 및 지사교류회의 정기 개최에 대한 공동선언문을 발표하였다.<br/>
        　1999년도부터 야마구치현이 참가했다.<br/>
        　2008년도부터 '한일해협연안 시도현교류 지사회의'로 개칭."
        </p>
        </EmphasisNote>
        </InnerBox>
        </div>

        <InnerBox bottom={20}>
            <div className="pc-navigator">
            <NavigatorCheck dir="right"></NavigatorCheck>
            </div>
        </InnerBox>
    </>)
}

export default LangKo