import InnerBox from "@/components/Atoms/Box/innerBox"

const LangZhTw = () => {

    return (
        <InnerBox bottom={20} left={0} right={0}>
        <h1>
        關於各地日語教室<br/>
        福岡縣內有 100 個由政府和誌願者組織的日語教室。
        </h1>
        <p>
        日語教室不僅是學習日語的場所，也是與居住在當地的日本人交流的場所。有的日語教室還可以就日常生活中遇到的問題進行谘詢，詳情請參閱下圖。<br/>
        此外，為了讓居住在福岡的外國人可以就近學習日語，福岡縣正在實施日語教育環境改善事業，政府支持開辦日語教室並舉辦誌願者培訓講座等。
        </p>
        </InnerBox>
    )
}

export default LangZhTw