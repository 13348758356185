import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlueLeft } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import { NavigatorFukidashiCheck } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"

const LangZhTw = () => {

    return (<>
    <InnerBox top={0} bottom={30} >
    <h1>公益財團法人福岡國際交流中心介紹</h1>
    <p>
    公益財團法人福岡國際交流中心成立於1989年，目的在於運用福岡縣特有的地理條件、歷史淵源，透過協助由縣內交流團體等所推動以縣民為主體的國際交流，提升福岡縣在國際交流中之地位，同時加深與包含亞洲各國、地區在內的世界各國、地區的交流，共同為彼此的繁榮與世界和平做出貢獻。
    </p>
    <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
    <InnerBox top={20} bottom={20} left={0} right={0}>
    <EmphasisNote>

    <p>
    在縣民與外籍居民之間的交流上，除了舉辦演講、研討會等各種活動之外，還經營了「福岡縣外國人諮詢中心」，以22國語言解決外籍居民各種問題，以及招募志工、提供災害相關資訊在內的生活相關資訊等活動。
    </p>
    <p>
    與國外的交流方面，也積極推動與福岡縣友好並有多項合作的夏威夷州（美國）、江蘇省（中國）、曼谷（泰國）等進行交流。
    </p>
    <p>
    此外，派遣外籍居民等至縣內的學校或公民館擔任講師加強國際理解教育，同時支援民間國際交流團體舉辦的草根交流活動或地方性的日語教室等。
    </p>
    <p>
    此外，也兼具產、官、學界設置的福岡縣留學生支援中心的辦公處，為留學生提供綜合性的支援。
    </p>
    </EmphasisNote>
    </InnerBox>
    <p>
    【聯係方式】<br/>
    〒810-0001<br/>
    福岡縣福岡市中央區天神1丁目1番1號ACROS 福岡 8F／3F<br/>
    8F　TEL：092-725-9204　FAX：092-725-9205 08:30~17:45（週六、日、國定假日除外）<br/>
    3F（國際廣場） TEL：092-725-9200　FAX：092-725-9206 10:00~18:30（年末年初除外） <br/>
    <br/>
    </p>

    <div className="custom-link">
    <FukidashiRedLeft>公益財團法人福岡國際交流中心介紹</FukidashiRedLeft>

    <a href="https://kokusaihiroba.or.jp/" target="_blank">
    <img src={publicPath('images/fief.png')} className="img-fit" alt="公益財團法人福岡國際交流中心介紹"/>
    </a>
    </div>

    </InnerBox>
    <InnerBox top={20} bottom={20}>
    <h1>
    福岡縣外國人谘詢中心
    </h1>

    <div className="sp-only">
    <NavigatorFukidashiCheck dir="left">
    <span className="txt-yellow">
    在福岡生活有什麽困難嗎？<br/>
    外國人谘詢中心可以使用多種語言為您排憂解難。
    </span>
    </NavigatorFukidashiCheck>
    </div>
    
    <br/>
    <p>
    在外國人的生活支援方麵，福岡縣外國人谘詢中心 用22種語言為外國人提供就業、勞務、醫療、住房、教育等生活困難，以及在留手續、法律谘詢等的各種谘詢。
    </p>
    <div className="pc-only">
        <div className="pc-flex col-2">
            <div>
                <NavigatorAction actionType="pickup" charactorType="smile" dir="right"/>
            </div>
            <div className="center">
                <FukidashiBlueLeft>
                    <span className="txt-yellow">
                    在福岡生活有什麽困難嗎？<br/>
                    外國人谘詢中心可以使用多種語言為您排憂解難。
                    </span>
                </FukidashiBlueLeft>
            </div>
        </div>
    </div>
    <br/>

    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi navigatorCheck right pointing">
            如有任何疑問或困難，請隨時致電或訪問窗口。<br/>
            可以用各種語言提供谘詢。 （免費）
            </div>
        </div>
        <div>
            <figure>
                <img src={publicPath('images/international01.png')} className="img-fit" alt=""/>
            </figure>
            <figure className="pc-only">
                <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
            </figure>
        </div>
    </div>


    <div className="pc-flex col-2">
        <div>
            <div className="deco-fukidashi challengerQ left thinking">
            想延長在日逗留時間。 該如何做？
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right puzzled">
            有能用我的母語為我提供服務的醫院嗎？
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            我和房東發生了糾紛。 應該去哪裏谘詢？
            </div>
        </div>
        <div>
            <div className="deco-fukidashi challengerQ right thinking">
            需要住民票。 在哪裏可以拿到？
            </div>
        </div>
    </div>

    
    <figure>
    <img src={publicPath('images/international02.png')} className="img-fit" alt="FiSSC"/>
    </figure>
    <InnerBox top={20} bottom={20} left={0} right={0}>
    <EmphasisNote>
    <h2>
    可對應的語言
    </h2>
    <p>
    日語、英語、漢語、韓語、越南語、尼泊爾語、印尼語、他加祿語、泰語、馬來語、西班牙語、法語、德語、意大利語、俄語、葡萄牙語、緬甸語、高棉語、蒙古語、僧伽羅語、印地語、孟加拉語
    </p>
    </EmphasisNote>
    </InnerBox>
    <p>
    福岡市中央區天神1-1-1 Across福岡3F<br/>
    （公財）福岡縣國際交流中心內<br/>
    0120-279-906
    </p>
    <FukidashiRedLeft>
    鏈接
    </FukidashiRedLeft>
    <div className="pc-flex col-2">
            <p>
                <a href="https://kokusaihiroba.or.jp/" target="_blank">
                    <img src={publicPath('images/fief.png')} className="img-fit" alt="公益財団法人福岡県国際交流センター"/>
                </a>
            </p>
            <p>
                <a href="https://www.fissc.net/" target="_blank">
                    <img src={publicPath('images/ficc.png')} className="img-fit" alt="FiSSC"/>
                </a>
            </p>
        </div>
        <NavigatorAction actionType="goodluck" charactorType="good" dir="right"></NavigatorAction>
    </InnerBox>
    </>)
}

export default LangZhTw