import InnerBox from "@/components/Atoms/Box/innerBox"
import {FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"

const LangZhTw = () => {

    return (<>
    <InnerBox>
    <h1>住房</h1>
    <p>
    要想在福岡找到住所，安心地生活，需要了解以下幾點，辦理適當的手續，並請依靠政府谘詢窗口的幫助。
    </p>
    </InnerBox>
    <BlackNote>
    <ul className="deco">
    <li>
    <h4>１.如何尋找房屋</h4>
    <p>
    ●利用房地產信息網站和應用程序尋找房屋。<br/>
    ●有些房地產中介公司也可提供外語服務。
    </p>
    </li>
    <li>
    <h4>２.租賃住房的類型</h4>
    <p>
    ●私人住房：從房東（租房人）租用的房屋<br/>
    ●公營住房：市、縣等出租的房屋。<br/>
    ●UR 租賃住房：由“ UR 都市機構”出租的房屋。 無需擔保人。
    </p>
    </li>
    <li>
    <h4>３.租房合同的基本內容</h4>
    <p>
    ●租房合同通常包括押金（保證金）、禮金和租金。 要理解合同內容，並檢查各項條件。
    </p>
    </li>
    <li>
    <h4>４.押金和禮金</h4>
    <p>
    ●押金在合同結束時退還，但如果房租逾期未付或房間因汙垢造成部分損壞時，押金可能會被扣除。 禮金不予退還。
    </p>
    </li>
    <li>
    <h4>５.擔保人</h4>
    <p>
    ●一般來說，簽訂合同時需要有擔保人。 擔保人在簽約人無法支付房租時承擔責任。 另外，也可以使用租金擔保公司。
    </p>
    </li>
    <li>
    <h4>６.互聯網和水電煤氣費</h4>
    <p>
    ●許多房屋都配有互聯網。 電費、煤氣費、水費等一般要與房租分開支付。
    </p>
    </li>
    <li>
    <h4>７.家具和電器的有無</h4>
    <p>
    ●有些公寓配有家具和電器。是核實好家具與電器再入住，還是自備家具與電器，請自行決定。
    </p>
    </li>
    <li>
    <h4>８.居民登記</h4>
    <p>
    ●入住後需要進行居民登記。 這項手續要到市區町村政廳辦理。
    </p>
    </li>
    <li>
    <h4>9.生活習慣和規則</h4>
    <p>
    ●在日本居住，有一些特有的生活習慣和禮儀，如要顧及鄰居、垃圾分類規則等。 這些也需要注意。
    </p>
    </li>
    </ul>
    </BlackNote>
    <InnerBox top={20} bottom={20}>
    <FukidashiRedLeft>
    以下鏈接為尋找住房的外國人提供了有用的多語言信息，如找房時常用術語集和 找房指南。
    </FukidashiRedLeft>
    <h1>
    出入國在留管理庁 HP 生活和工作指南。
    </h1>
    <ul className="links">
    <li>
    ●英文：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392830.pdf</a>
    </li>
    <li>
    ●中文：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392831.pdf</a>
    </li>
    <li>
    ●韓語：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001393145.pdf</a>
    </li>
    <li>
    ●越南語：<a href="https://www.moj.go.jp">https://www.moj.go.j/isa/content/001393146.pdfp</a>
    </li>
    <li>
    ●泰語： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●尼泊爾語： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●簡易的日語： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/930004715.pdf</a>
    </li>
    </ul>
    </InnerBox>
    <InnerBox bottom={20}>
    <h1>公益財團法人日本租賃住宅管理協會 《外國人入住順利支持指南》</h1>
    <p>
    <a href="https://www.jpm.jp">https://www.jpm.jp/foreign/</a>
    </p>
    </InnerBox>
    </>)
}

export default LangZhTw