import InnerBox from "@/components/Atoms/Box/innerBox"
import { FukidashiBlue, FukidashiBlueBottom } from "@/components/Atoms/Fukidashi/fukidashiBlue"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { ChallengerAction, NavigatorAction } from "@/components/Molecules/CharactorAction"


const LangNe = () => {
    const lang = 'ne'
    return (<>
    <InnerBox left={0} right={0} bottom={60}>
    <div className="pc-flex col-2">
    <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>जापानी बोल्न सक्दिन। धेरै भाषाहरूमा समर्थन प्रदान हुने परामर्श डेस्क छ ? </span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>「फुकुओका प्रान्त विदेशी निवासी परामर्श केन्द्र」मा、जापानी भाषा सहित २२ भाषाहरूमा परामर्श प्राप्त गर्न सक्नुहुन्छ। थप रूपमा、प्रत्येक नगर शहर गाउँले बहुभाषिक परामर्श डेस्क र अन्तर्राष्ट्रिय आदान प्रदान संघहरू स्थापना गरेको छ、भाषा समर्थन तथा कानुनी सल्लाह、 र दैनिक जीवनमा आबश्येक सबै खालका समर्थन र सहयोग प्रदान गर्दछ।थप जानकारीको लागि,</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="normal"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left"> कृपया </span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/consultation/international?lang=${lang}`}
    linkKey="kokusai_soudan"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="puzzled"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>परिवारलाई ल्याउन चाहन्छु तर 、जापानी भाषा बोल्न सक्दैनन्、कृपया जापानी भाषा सिक्न सक्ने ठाउँ बताइदिन सक्नुहुन्छ?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>फुकुओका प्रान्तमा、प्रशासन तथा स्वयंसेवकहरू द्वारा आयोजित जापानी भाषा कक्षाहरू १०० ठाउँ मा छन्। दैनिक जीवनमा हुन सक्ने कुनै पनि समस्याहरूको सजिलै सल्लाह लिने सकिने कक्षा पनी हो। थप जानकारीको लागि、</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="smile"
        actionType="anser"/>
        </div>
        </div>
    </div>

    <div className="links">
    <span className="left"> कृपया </span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/education?lang=${lang}`}
    linkKey="kyoiku"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>


    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>विदेशी भाषाहरूमा सेवा प्रदान गर्ने चिकित्सा संस्थाहरूको बारेमा बताउनुहोस्।</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>「फुकुओका इन्टरनेशनल मेडिकल सपोर्ट सेन्टर」ले विदेशी भाषा बोल्न सक्ने चिकित्सा संस्थाहरू र चिकित्सा व्याख्या सेवाहरू प्रदान गर्ने चिकित्सा संस्थाहरूको परिचय、फुकुओका प्रान्त भित्रका चिकित्सा संस्थाहरूको जानकारी、जापानी चिकित्सा प्रणालीको बारेमा जानकारी、डाक्टरहरूसँगको कुराकानी व्याख्या सेवा तथा फोन मा सहयोग उपलब्ध गराउन गर्ने चिकित्सा संस्थाहरू चिनाउने गर्दछ। थप जानकारीको लागि,</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="good"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left"> कृपया </span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/medical?lang=${lang}`}
    linkKey="iryo"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>बच्चाको अध्ययन गर्ने ठाउँको रुपमा अन्तर्राष्ट्रिय विद्यालय छ?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>फुकुओका प्रान्तमा、अन्तर्राष्ट्रिय शैक्षिक प्रमाणीकरण संस्था「अन्तर्राष्ट्रिय स्नातक संगठन」बाट प्रमाणित भएको、बाल्यकाल देखि हाई स्कूल सम्म निरन्तर अङ्ग्रेजी-आधारित शिक्षा प्रदान गर्ने अन्तर्राष्ट्रिय विद्यालय छ। थप जानकारीको लागि、</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="pointing"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left"> कृपया </span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/education?lang=${lang}`}
    linkKey="kyoiku"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>फुकुओकामा जागिर खोज्दै छु। कहिँ कतै चिनाइदिन सक्नुहुन्छ ?</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>विदेशीहरूको लागि रोजगारी सम्बन्धी परामर्श・परिचयहरू、हेलो वर्क फुकुओका चुओ/फुकुओका विदेशी रोजगार सेवा केन्द्रमा उपलब्ध छन्।थप जानकारीको लागि、</span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="normal"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left"> कृपया </span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/lifestyle/employment?lang=${lang}`}
    linkKey="works"
    ></YellowArrowLink>
    </div>

    <span className="right"></span>
    </div>

    <hr/>
    <div className="pc-flex col-2">
        <div className="question">
        <div className="charactor">
        <ChallengerAction
        dir="left"
        charactorType="thinking"
        actionType="question"/>
        </div>
        <div className="fukidashi">
        <FukidashiBlue dir="left">
        <span>फुकुओका बसाइसराइ गर्न चाहन्छु तर、यस क्षेत्रसँग फिट हुन सक्ने नसक्ने मा चिन्तित छु।</span>
        </FukidashiBlue>
        </div>
        </div>

        <div className="anser-block">
        <div className="fukidashi">
        <FukidashiBlueBottom dir="right">
        <span>यस साइटले फुकुओका प्रान्तमा सक्रिय रहेका विदेशीहरूको सफलताका कथाहरूको भिडियोहरू उपलब्ध गराउँछ। फ़ुकुओकामा भिडियोमा परिचय दिइएका मानिसहरू जस्तै गृहनगर बाट धेरै टाढा、स्थानीय समुदायमा सक्रिय रहेका विदेशीहरू धेरै छन्।थप जानकारीको लागि, </span>
        </FukidashiBlueBottom>
        </div>
        <div className="charactor">
        <NavigatorAction
        dir="right"
        charactorType="smile"
        actionType="anser"/>
        </div>
        </div>
    </div>
    <div className="links">
    <span className="left"> कृपया </span>
    <div className="al-c">
    <YellowArrowLink
    lang={lang}
    href={`/movie?lang=${lang}`}
    linkKey="fortunehills"
    ></YellowArrowLink>
    </div>
    <span className="right"></span>
    </div>
    </InnerBox>
    </>)
}

export default LangNe