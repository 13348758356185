import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangKo = () => {

    return (
        <Article>
        <p>
        후쿠오카 출입국 체류 관리국에서는 체류 자격에 관한 다양한 수속을 처리하고 있습니다.<br/>
        (체류 기간 갱신, 체류자격 변경, 영주권 신청, 아르바이트를 하기 위한 자격 외 활동 허가 수속, 해외에 있는 외국인을 불러들이기 위한 수속 등).<br/>
        　주로 체류 자격 등에 관한 출입국관리국의 수속 안내 업무를 하는 상담 창구로서 외국인 체류 종합 상담 정보 센터를 설치하고 있습니다. 최근 체류 외국인이 안고 있는 생활 전반의 어려움을 대처할 필요성이 커지면서 센터에서는 기존의 출입국관리국 수속 안내와 더불어 생활 전반과 관련된 상담에 대처함으로써 체류 지원의 창구 역할을 하고 있습니다.
        </p>
        <NavigatorAction dir="right" actionType="pickup" charactorType="normal"></NavigatorAction>

        <EmphasisNote>
        <h2>상담 대상</h2>
        <p>
        ●체류 외국인 및 가족, 관계자 등
        </p>
        <figure>
        <img src={publicPath('images/articles/nyukan02.png')} alt="후쿠오카 출입국 체류 관리국(신청 창구)"/>
        <figcaption>후쿠오카 출입국 체류 관리국(신청 창구)</figcaption>
        </figure>
        <h2>시설 정보</h2>
        <p>
        ●시설 명칭　／　<br/>
        후쿠오카 출입국 체류 관리국 심사관리 부문<br/>
        ●소재지　／　<br/>
        우편번호 810-0073 후쿠오카시 주오구 마이즈루 3-5-25 후쿠오카 제1 법무 종합청사 6층<br/>
        ●전화번호 092-717-7595<br/>
        ●이용 시간　／9시 00분~16시 00분 [토·일·공휴일·연말연시는 휴무]<br/>
        ●통역 없음
        </p>
        <p>
        정보 센터에서 신청 용지의 작성법을 안내하고 있습니다.<br/>
        ※　※ 정보 센터의 이용 시간은 신청 창구와 같습니다. 영어, 중국어, 한국어 지원 가능.
        </p>
        <h2>출입국 체류 관리청 홈페이지 URL</h2>
        <p>
        【URL】<br/>
        <a href="https://www.moj.go.jp/isa/index.html" target="_blank">https://www.moj.go.jp/isa/index.html</a>
        </p>
        <h2>후쿠오카 출입국 체류 관리국 페이스북 URL</h2>
        <p>
        【URL】<br/>
        <a href="https://www.facebook.com/fukuoka.nyukan.support" target="_blank">https://www.facebook.com/fukuoka.nyukan.support</a>
        </p>
        <FukidashiRedLeft>
        체류 자격에 관한 정보 이외에도 신청 창구의 혼잡도 및 유용한 정보를 게재하고 있습니다.꼭 좋아요·팔로우 부탁드립니다.
        </FukidashiRedLeft>
        <figure>
        <img src={publicPath('images/articles/nyukan_fb_qr.png')} alt="후쿠오카 출입국 관리국 페이스북"/>
        <figcaption>후쿠오카 출입국 관리국 페이스북</figcaption>
        </figure>

        </EmphasisNote>
        <br/>
        <NavigatorAction dir="right" actionType="pickup" charactorType="pointing"></NavigatorAction>

        </Article>
    )
}

export default LangKo