import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorGood, NavigatorPickUp } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"

const LangZhCn = () => {

    return (<>
    <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="normal" dir="right" actionType="pickup" actionColor="blue"/>
        </div>
        <BlackNote>
        <p className="large">福冈县的GDP </p>
        <p className="xl">
        178,027百万美元
        </p>
        <p className="middle txt-yellow">
        每个县民所得：<br/>
        24,790美元
        </p>
        <p className="al-c">（截至2022年4月）</p>
        <span className="txt-small">
        资料 县调查统计科 <br/>
        (令和2年县民经济・市町村民经济计算报告书) <br/>
        以1美元等于106.09日元进行换算 (2020年平均) <br/>
        资料 内阁府(每月经济报告 主要经济指标)
        </span>
        </BlackNote>
    </div>
    <br/>
    <div className="navpicup sp-only">
        <NavigatorPickUp dir="right"></NavigatorPickUp>
    </div>
    <InnerBox top={20}>
    <EmphasisNote>
    <h2>农业 </h2>
    <p>
    福冈县的耕地面积为7万8,900公顷（截至令和４年），占县土地总面积的16%，其中水田的比例为81%（全国平均为54%）其极高的占比尤为特色
    <br/>
    </p>
    <h2>森林・林业 </h2>
    <p>
    福冈县的森林面积约为22万4,000公顷，占县土地总面积的45%森林，对水源的涵养，泥石流失的防治等有着重大的积极作用，在县民安全・安心生活的保障方面占有着至关重要的地位
    <br/>
    </p>
    <h2>水产业 </h2>
    <p>
    于福冈县各个风格迥异的筑前海，有明海，丰前海的海域以及川河，湖泊等的内陆水域，发展着各式各样的渔业以及养殖业除了于产量在全国位居前茅的真鲷，以及海藻之外，各种丰富多样的水产品也都在这里被捕捞<br/>

    <br/>
    </p>
    <h2>工商业 </h2>
    <p>
    福冈县的工业以钢铁，煤炭・化学等的基础材料型产业和食品等的生活相关型为中心发展至今，于近年，汽车相关产业等的加工组装型产业的比重也在逐步增加<br/>
    此外，在食品制造业方面，由于其可以对丰富且品质优良的农林和水产品进行调度，以及和消费量较大地区的距离较近有着得天独厚的物流环境等要素，大量的企业正在源源不断地向此聚集
    </p>
    </EmphasisNote>
    </InnerBox>
    <InnerBox top={20}>

   
    <h1>
    Growth Industry<br/>
    支持福冈未来发展的成长产业
    </h1>
    <p>
    福冈汇聚了众多大学、先进研究机构，以及技术卓越的制造业。<br/>
    福冈县正专注于推动企业进入和集中到预计市场将扩大的成长产业。
    </p>
    <EmphasisNote>
    <h2>1. 生物产业界</h2>
    <p className="txt-middle">
    福冈被认定为生物技术的先进社区，为成为先进的生物技术基地而加速推进努力。
    </p>
    <p>
    自2001年起，福冈县与久留米市共同设立了由产学官组成的「福岡县生物产业基地推进会议」，旨在推动生物相关企业的集聚。<br/>
    在推进会议的努力下，生物相关企业从最初的32家增加到了230多家。
    在福冈，引起关注的生物初创企业不断涌现，如株式会社ボナック的核酸药物治疗 COVID-19的研发，作为下一代医疗而备受瞩目；还有具有全日本基因编辑技术实力的エディットフォース株式会社等。这些企业在国内外都备受关注。<br/>
    福冈县的这些优势受到了高度评价，于2021年6月被内阁府认定为西日本唯一的「地域bio community」（全国共有4个地方）。
    </p>
    <h2>2. 宇宙产业</h2>
    <p className="txt-middle">
    福冈被选定为九州的宇宙产业创新推进自治体第1号。通过福冈产的硬软技术挑战太空。
    </p>
    <p>
    福冈县汇聚了拥有卓越技术的制造业以及开发独创新服务的IT初创公司以及在宇宙产业中具有发展潜力的企业。<br/>
    株式会社iQPS（福冈市）成功研发并成功发射了世界最高水平的小型SAR卫星，能够在昼夜和不受天气影响的条件下进行地表观测。2021年5月，世界最高水平的高分辨率图像的拍摄也取得了成功。iQPS公司计划在2025年后扩展其系统，通过36颗卫星组成的星座，实现以10分钟间隔观测地表的能力。<br/>
    在2020年9月，内阁府选择福冈县作为推动九州地区宇宙产业创新的自治体的第1号。
    </p>
    <h2>3. 区块链（Block chain）</h2>
    <p className="txt-middle">
    集结了日本最先进的人才和技术，成立了国内领先的区块链枢纽。
    </p>
    <p>
    暗区块链是作为管理加密资产的技术而开发的，其追溯性、防止数据篡改的能力以及对错误操作的抗性备受关注。<br/>
    福冈县拥有许多区块链初创企业和高技能工程师，此外，九州工业大学计算机理工学部和近畿大学产业理工学部在区块链领域的研究中处于领先，并培养出优秀的工程师。<br/>
    为了充分利用这种IT产业振兴的优势，福冈县从2020年开始加大对区块链技术研究开发和人才培养的支持力度，正在成为日本区块链的主要基地。
    </p>
    <h2>4. 能源产业</h2>
    <p className="txt-middle">
    发展氢能源产业，促进企业集聚，引入离岸风电。
    </p>
    <p>
    日本设定目标在2050年实现碳中和，致力于将可再生能源作为主要电力来源。在可再生能源中，尤其是对于四面环海的日本而言，洋上风力发电被期望成为一种理想的选择。<br/>
    此外，福冈县在推动引入洋上风力发电的同时，与产学官联合，旨在促进风力发电企业的集聚。此外，为了实现环保的氢能源社会，福冈县与产学官协作设立了「福冈县氢能源战略会议」。<br/>
    除了进行从氢的生产、储存、运输到使用的广泛研究开发、产品开发和人才培养外,还致力于培育和集聚氢能源企业。
    </p>
    <h2>5. 其他</h2>
    <p className="txt-middle">
    福冈和九州的主要成长产业
    </p>
    <p>
    在九州，环境、农林水产业和汽车工业都取得了成功，并被视为成长产业。<br/>
    ●环境产业<br/>
    积极进行回收利用，不仅包括对PET瓶和家电产品的回收再利用，还包括对用于锂离子电池等的稀有金属的回收再利用等，致力于不浪费有限资源。<br/>
    ●-农林水产业<br/>
    在九州，农林水产业繁荣，占据国内农水产品产量的20％。
    特别是在福冈，生产了受到国内外高度评价的农水产品。<br/>
    ●汽车产业<br/>
    主要的汽车制造商，例如日产（Nissan）、丰田（Toyota）、大发（Daihatsu）在九州北部设有生产基地，汽车生产数量占据国内市场的15％以上。
    </p>
    </EmphasisNote>
    <br/>
    <div className="sp-only">
        <NavigatorGood dir="right"></NavigatorGood>
    </div>
    </InnerBox>
</>)
}

export default LangZhCn