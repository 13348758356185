import styled from "styled-components"
import publicPath from "src/utilty/publicPath"
import HeadLine from "@/components/Atoms/HeadLine"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { langType } from "src/types/lang"
import { PathAnalysisValue } from "src/utilty/pathAnalyzer"
import { tsutaeruToEastJa } from "src/utilty/easyJaCheker"
import { TopSectionCotainerTemplate } from "@/components/Templates/Responsive/topSectionContainer"

const SSection = styled.section`
    position: relative;
    background:url(${publicPath('images/top/supportBk.png')}) no-repeat;
    background-size:100% auto;
    width: 100%;
    height: 100%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        background: url('images/pc/bk_about.webp') no-repeat;
        background-size: auto 100%;
    }
`

const SHeadline = styled.h2`
    position: absolute;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
    top:-6%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 350px;
        transform: translate(-50% ,-15%);
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 440px;
        transform: translate(-50% ,0%);
    }
`


const SLinks =  styled.ul`
    position: absolute;
    top: 11%;
    width: ${props => props.theme.topPage.links.ulWidth};
    left: 50%;
    transform: translateX(-50%);
    li{
        width: ${props => props.theme.topPage.links.liWidth};
        margin-top: -8px;
        &:nth-child(2n){
            margin-top: ${props => props.theme.topPage.links.oddMarginTop};
            margin-left: ${props => props.theme.topPage.links.oddOffest};
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        display: flex;
        flex-wrap: wrap;
        top:40%;
       
       
        li{
            flex-basis: 33%;
            margin: 0;
            transform: rotate(2deg);
            &:nth-child(2n){
                margin: 0;
            }
        }
    }
`



const SupprtSection = ({pathValue}:{pathValue: PathAnalysisValue}) => {
    
    const allowLang = tsutaeruToEastJa(pathValue)

    return (
        <SSection>
            <TopSectionCotainerTemplate>
            <SHeadline>
                <HeadLine lang="ja" nameKey="support"></HeadLine>
            </SHeadline>
            <SLinks>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/support/faq?lang=${pathValue.lang}`}
                        linkKey="faq"
                    ></YellowArrowLink>
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/support/links?lang=${pathValue.lang}`}
                        linkKey="links"
                    ></YellowArrowLink>
                </li>
            </SLinks>
            </TopSectionCotainerTemplate>
        </SSection>        
    )
}

export default SupprtSection