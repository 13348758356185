import publicPath from 'src/utilty/publicPath'
import styled from 'styled-components'


type IconImageProps = {
    src: string
    width: number 
    alt: string
}

export const Icon = ({src, width, alt}:React.PropsWithChildren<IconImageProps>) => {

    return (
        <img 
            src={publicPath(src)} 
            width={width} 
            alt={alt}
            className='img-fit'/>
    )
}