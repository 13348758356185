import HeadLine from "@/components/Atoms/HeadLine"
import { PropsWithChildren } from "react"
import { langType } from "src/types/lang"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import { pageTitleProps } from "src/components/Molecules/PageTitle"
import TrapezoidTitle from "@/components/Atoms/HeadLine/trapezoidTitle"

const SPageTile = styled.div`
    
    .headlineWrap{
        position: relative;
        width: 100%;
        aspect-ratio: 10 / 5;
        z-index: 11;
        &::after{
            width: 100%;
            aspect-ratio: 10 / 5;
            background: url(${publicPath('images/headline/cartoon.png')}) no-repeat;
            background-size: auto 100%;
            background-position: center;
            content: '';
            position: absolute;
            top: 0;
        }
        .headline{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 80%;
            transform: translate(-50%, -27%);
            z-index: 11;
        }
        .subtitle{
            width: 70%;
            position: absolute;
            bottom:3px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 12;
        }
        &.lang{
            aspect-ratio: 10 / 4;
            .subtitle{display: none}
            .headline{  transform: translate(-50%, -30%);}
            &::after{ aspect-ratio: 10 / 4;}
        }
        @media screen and (min-width: ${props => props.theme.responsive.minSp}) {
            .headline{
                transform: translate(-50%, -42%);
            }
            .subtitle{
                bottom:8%;
            }
        }
    }
    .fhf_logo{
        margin: 0 20px;
    }
    margin-bottom: 40px;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .headlineWrap{
            max-width: 500px;
        }
        margin-bottom: 0px;
    }
`
const STrapezoidPositon = styled.div`
    margin-top: -12%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        margin-top:0;
    }
`

const PageTitleMovie = ({
    lang,
    children,
    ...props
} : PropsWithChildren<pageTitleProps>) => {

    return (
        <SPageTile>
            <div className={`headlineWrap ${lang !== 'ja' ? 'lang' : ''}`}>
                <div className="headline">
                    <HeadLine lang={lang} nameKey="movie"/>
                </div> 
                <div className="subtitle">
                    <img 
                        src={publicPath('images/headline/sub_movie.png')} 
                        className="img-fit"
                        alt="fortune hills 福岡"
                    />
                </div>
            </div>
            <STrapezoidPositon>
                <TrapezoidTitle>
                    <figure className="fhf_logo">
                    <img src={publicPath('images/fottunehillsfukuoka.png')} className="img-fit" alt="fottunehillsfukuoka"/>
                    </figure>
                </TrapezoidTitle>
            </STrapezoidPositon>
           
        </SPageTile>
       
    )
    
}

export default PageTitleMovie