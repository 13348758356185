import InnerBox from "@/components/Atoms/Box/innerBox"

const LangZhCn = () => {

    return (
        <InnerBox bottom={20} left={0} right={0}>
        <h1>
        关于地方的日语教室<br/>
        在福冈县内，有100个由政府和志愿者组织举办的日语教室。
        </h1>
        <p>
        日语教室不仅是学习日语的场所，还承担着作为与当地居民互动和交流的场所的角色。在这些教室中，您还可以轻松地咨询有关日常生活中遇到的问题等。具体情况请参考下面的图表。<br/>
        此外，在福冈县，为了帮助在福冈生活的外国人更容易在身边找到学习日语的机会，实施了日语教育环境整备项目、包括进行对政府管理的日语教室开设的支援以及志愿者培训课程的开展等。
        </p>
        </InnerBox>
    )
}

export default LangZhCn