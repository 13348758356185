
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import { AreaMap } from "./areaMap"
const SSvg = styled.div``

const SOverride = styled.div`
text{
    cursor: pointer;
}
@media screen and ((min-width: ${props => props.theme.responsive.minSp}) and
(max-width: ${props => props.theme.responsive.minPc}) ),
screen and (min-width: ${props => props.theme.responsive.pcLargePoint}) {
    .fukidashiPosition{
        &.kitakyu{
            top: 18% !important;
        }
        &.chikuhou{
            bottom: 46% !important;
        }
        &.chikugo{
            bottom: 42% !important;
        }
    }
}
`

const LangNe = ({onSwich, onFukidashi}) => {

    return (
        <SOverride>
            <div className="area-info">
            <SSvg>
                <figure className="map">
                <svg id="_レイヤー_1" data-name="レイヤー 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.92 286.92">
                    <rect className="fukuoka" onClick={()=> {onSwich(4)}} x="5.01" y="58.23" width="84.45" height="23.67" rx="3.9" ry="3.9" transform="translate(94.47 140.13) rotate(180)"/>
                    <text className="moji"  onClick={()=> {onSwich(4)}} transform="translate(10.99 74.98)"><tspan className="cls-1" x="0" y="0">फुकुओका</tspan><tspan className="cls-3" x="41.69" y="-.08"> </tspan><tspan className="cls-1" x="44.71" y="0">क्षेत्र</tspan></text>
                    <rect className="kitakyu"  onClick={()=> {onSwich(1)}} x="181.29" y="10.6" width="93.14" height="23.67" rx="3.9" ry="3.9" transform="translate(455.72 44.87) rotate(180)"/>
                    <text className="moji"  onClick={()=> {onSwich(1)}} transform="translate(183.42 27.27)"><tspan className="cls-3" x="0" y="0"> </tspan><tspan className="cls-1" x="3.02" y=".08">किताक्युसु</tspan><tspan className="cls-3" x="52.2" y="0"> </tspan><tspan className="cls-1" x="55.22" y=".08">क्षेत्र</tspan></text>
                    <AreaMap onSwich={onSwich}/>
                    <rect className="chikuhou"  onClick={()=> {onSwich(3)}} x="203.28" y="173.27" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(480.77 370.22) rotate(180)"/>
                    <text className="moji"  onClick={()=> {onSwich(3)}} transform="translate(207.37 190.02)"><tspan className="cls-1" x="0" y="0">चिकुहो</tspan><tspan className="cls-3" x="32.61" y="-.08"> </tspan><tspan className="cls-1" x="35.63" y="0">क्षेत्र</tspan></text>
                    <rect className="chikugo"  onClick={()=> {onSwich(2)}} x="11.44" y="185.61" width="74.21" height="23.67" rx="3.9" ry="3.9" transform="translate(97.1 394.9) rotate(180)"/>
                    <text className="moji"  onClick={()=> {onSwich(2)}} transform="translate(15.25 202.36)"><tspan className="cls-1" x="0" y="0">चिकुगो</tspan><tspan className="cls-3" x="33.18" y="-.08"> </tspan><tspan className="cls-1" x="36.2" y="0">क्षेत्र</tspan></text>
                    </svg>
                </figure>
            </SSvg>
                <div className={`fukidashiPosition kitakyu ${onFukidashi === 1 ? 'active' : ''}`}>
                    <div className="fukidashi kitakyu">
                        <figure>
                        <img src={publicPath('images/basicinfo_area/ne/kitakyu.svg')} className="fit-img"
                        alt={`स्टील, अटोमोबाइल, उन्नत अर्धचालक, र रोबर्ट जस्ता उद्योगहरू प्रगति गर्दैछन्, र संचित "उत्पादन प्रविधि" को प्रयोग गरेर क्षेत्र पुनरुत्थान भइरहेको छ। चौबीसै घण्टा सञ्चालन गर्न सक्ने किताक्युसु विमानस्थललाई एसियाकै लजिस्टिक आधारका रूपमा सुदृढ गर्दै लगिएको छ ।`} />
                        </figure>
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition fukuoka ${onFukidashi === 4 ? 'active' : ''}`}>
                    <div className="fukidashi fukuoka">
                        <figure>
                        <img src={publicPath('images/basicinfo_area/ne/fukuoka.svg')} className="fit-img"
                        alt={`क्युशुको केन्द्रीय प्रशासनिक कार्यहरू र तृतीयक उद्योगहरू केन्द्रित छन्, र यो पश्चिमी जापानको एक अग्रणी क्षेत्रको रूपमा विकसित भएको छ। क्युसु शिन्कानसेन, फुकुओका एयरपोर्ट र हाकाता बन्दरगाह जस्ता यातायात पूर्वाधारको विकास भइरहेको छ।`} />
                        </figure>
                        
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition chikugo ${onFukidashi === 2 ? 'active' : ''}`}>
                    <div className="fukidashi chikugo">
                    
                    <figure>
                    <img src={publicPath('images/basicinfo_area/ne/chikugo.svg')} className="fit-img"
                        alt={`प्राकृतिक कृषि, वन तथा मत्स्य उत्पादन, स्थानीय उद्योग, विभिन्न किसिमका व्यावसायिक तथा औद्योगिक उद्योग, संस्कृति र अद्वितीय सहरहरूले भरिएको यो क्षेत्र आकर्षणले भरिपूर्ण छ। हामीले चिकुगो नेटवर्क गार्डेन सिटी इनिसिएटिभलाई प्रवर्द्धन गर्दैछौं ताकि तिनीहरूको सम्बन्धित कार्यहरू सहयोग र संरक्षण गर्ने अद्वितीय शहरहरू विकास गर्न सकिन्छ।`} />
                    </figure>
                        <span className="speak"></span>
                    </div>
                </div>
                <div className={`fukidashiPosition chikuhou ${onFukidashi === 3 ? 'active' : ''}`}>
                    <div className="fukidashi chikuhou">
                    <figure>
                        <img src={publicPath('images/basicinfo_area/ne/chikuho.svg')} className="fit-img"
                        alt={`कोइला उद्योगलाई प्रतिस्थापन गर्न नयाँ उद्योगको रूपमा, अटोमोबाइल उद्योग देशमा बढ्दो रूपमा अवस्थित छ, र अटोमोबाइल उद्योगलाई समर्थन गर्न मानव संसाधन विकास सक्रिय रूपमा विकास भइरहेको छ। सहरले विज्ञान र प्रविधि विश्वविद्यालयहरूमा केन्द्रित भेन्चर कम्पनीहरू र अनुसन्धान संस्थानहरू भेला गरेर नयाँ उद्योगहरू सिर्जना गर्ने आधार सिर्जना गर्न पनि काम गरिरहेको छ।`} />
                        </figure>
                    
                        <span className="speak"></span>
                    </div>
                </div>
            </div>

        </SOverride>
    )
}

export default LangNe