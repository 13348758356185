
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import Article from "@/components/Organisms/Article"
import { SDecTextUl } from "."

const LangJa = () => {

    return (
        <>
         <Article offset={0}>
         <InnerBox>
                <p>福岡県には多くの外国人が住んでいて、在住外国人数は日本の都市では9番目に多いなど、人気の町です。</p>
                <h1>外国人総数</h1>
            </InnerBox>
            <BlackNote>
                <SDecTextUl className="pc-flex col-2">
                    <li>
                        <p className="large">
                        県内在住の<br/>外国人総数
                        </p>
                        <p className="large">
                            <span className="xl">89,518</span>人
                        </p>
                        <p className="middle">（2022年12月現在）</p>
                    </li>
                    <li>
                        <p className="large">
                        福岡地区の<br/>在住外国人数
                        </p>
                        <p className="large">
                            <span className="xl">53,009</span>人
                        </p>
                        <p className="middle">（2022年12月現在）</p>
                    </li>
                    <li>
                        <p className="large">
                        北九州地区の<br/>在住外国人数
                        </p>
                        <p className="large">
                            <span className="xl">18,870</span>人
                        </p>
                        <p className="middle">（2022年12月現在）</p>
                    </li>
                    <li>
                        <p className="large">筑後地区の<br/>在住外国人数</p>
                        <p className="large">
                            <span className="xl">12,686</span>人
                        </p>
                        <p className="middle">（2022年12月現在）</p>
                    </li>
                    <li>
                        <p className="large">筑豊地区の<br/>在住外国人数</p>
                        <p className="large">
                            <span className="xl">4,953</span>人
                        </p>
                        <p className="middle">（2022年12月現在）</p>
                    </li>
                </SDecTextUl>
            </BlackNote>
            <InnerBox top={10} bottom={100}>
                <p className="text-small">出典：法務省「在留外国人統計」</p>
            </InnerBox>
        </Article>
        <div className="navposition01">
            <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
        <Article offset={0}>
            <InnerBox >
                <h1>
                    留学生数
                </h1>
            </InnerBox>
            
            <BlackNote>
                <SDecTextUl className="pc-flex col-2">
                    <li>
                        <p className="large">
                        県内の<br/>留学生総数
                        </p>
                        <p className="large">
                            <span className="xl">19,188</span>人
                        </p>
                        <p className="middle">（2022年12月現在）</p>
                    </li>
                    <li>
                        <p className="large">
                        福岡地区の<br/>留学生数
                        </p>
                        <p className="large">
                            <span className="xl">14,800</span>人
                        </p>
                        <p className="middle">（2022年12月現在）</p>
                    </li>
                    <li>
                        <p className="large">
                        北九州地区の<br/>留学生数
                        </p>
                        <p className="large">
                            <span className="xl">2,631</span>人
                        </p>
                        <p className="middle">（2022年12月現在）</p>
                    </li>
                    <li>
                        <p className="large">
                        筑後地区の<br/>留学生数
                        </p>
                        <p className="large">
                            <span className="xl">1,650</span>人
                        </p>
                        <p className="middle">（2022年12月現在）</p>
                    </li>
                    <li>
                        <p className="large">
                        筑豊地区の<br/>留学生数
                        </p>
                        <p className="large">
                            <span className="xl">107</span>人
                        </p>
                        <p className="middle">（2022年12月現在）</p>
                    </li>
                </SDecTextUl>
            </BlackNote>
            <InnerBox top={10} bottom={100}>
                <p className="text-small">出典：法務省「在留外国人統計」</p>
            </InnerBox>
        </Article>
        <div className="navposition01">
            <NavigatorCheck dir="right"></NavigatorCheck>
        </div>
        <Article offset={0}>
            <InnerBox>
                <h1>
                留学生就職者数
                </h1>
            </InnerBox>
            <BlackNote>
                <SDecTextUl>
                    <li>
                        <p className="large">
                        県内に所在する<br/>企業等の<br/>留学生就職者数
                        </p>
                        <p className="large">
                            <span className="xl">1,118</span>人
                        </p>
                        <p className="middle">（2021年）</p>
                    </li>
                </SDecTextUl>
            </BlackNote>
            </Article>
        </>
    )
}

export default LangJa