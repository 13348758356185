import InnerBox from "@/components/Atoms/Box/innerBox"

const LangJa = () => {

    return (
        <InnerBox bottom={20} left={0} right={0}>
        <h1>
        福岡県内には、行政やボランティアが<br/>
        開催している日本語教室が<br/>
        100カ所あります。
        </h1>
        <p>
        日本語教室は、日本語を学ぶ場としてだけではなく、地域に住む日本人との交流の場としての役割を担っています。普段の生活で困っていることなどについても気軽に相談することができますので、是非、ご活用ください！<br/>
        また、福岡県では、福岡で暮らす外国人が身近な場所で日本語を学べる環境整備のため、日本語教育環境整備事業を実施しており、行政が運営する日本語教室の立ち上げ支援や、ボランティア養成講座の開催等を行っております。
        </p>
        </InnerBox>
    )
}

export default LangJa