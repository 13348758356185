const LangZhCn = () => {

    return (<>
    <h1>关于网络无障碍性</h1>
    <dl>
    <dt>
    举措
    </dt>
    <dd>
    <p>福冈县将根据日本工业标准JIS X 8341-3:2016，努力维护和提高网络无障碍性，使人人都能使用网站。</p>
    </dd>
    <dt>
    1.对象范围
    </dt>
    <dd>
    <p>
    https://fisop.net 域名下的官方网站
    </p>
    </dd>
    <dt>
    2.符合目标的级别
    </dt>
    <dd>
    <p>
    符合性级别：以JIS X 8341-3:2016网络无障碍符合性级别 AA为基准<br/>
    (将不断进行修改，以达到网络无障碍符合性 AA 级别，包括第 2 层）。<br/>
    根据信息通信访问协议会网络无障碍性基盘委员会《网络内容的JIS X 8341-3:2016对应度表记指南 2016年3月版》所规定的表记，"符合无障碍级别AA "表示在符合无障碍级别A基础上，网站还满足无障碍级别AA的达成标准。<br/>
    <br/>
    <a href="https://waic.jp/docs/jis2016/compliance-guidelines/202104/" target="_blank">
    网页内容JIS X 8341-3:2016对应度表记指南 在新窗口中打开（外部链接）
    </a>
    </p>
    </dd>
    </dl>
    </>)
}

export default LangZhCn