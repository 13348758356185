
import InnerBox from "@/components/Atoms/Box/innerBox"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { TabTamplate } from "@/components/Templates/tab"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import Article from "@/components/Organisms/Article";
import { MedicalTbody } from "."
import Data from 'src/json/ForeignPatientsMedical/ja.json'
import { NavigatorAction } from "@/components/Molecules/CharactorAction"

const LangJa = ({onActive, active}) => {
    const medicals = Data.data
    return (
        <Article offset={0} className="pc-noTopMargin">
            <InnerBox>
            <h1>外国人患者を受け入れる<br/>拠点的な医療機関</h1>
            <p>
            福岡県では、外国人が安心して医療を受けられる環境を整備するため、多言語対応・キャッシュレス対応等ができることを条件として、二次医療圏ごとに外国人患者を受け入れる拠点的な医療機関を選定しています。
            </p>
            </InnerBox>
            <InnerBox left={0} right={0} bottom={30}>
                <BlackNote>
                    <div className="decoration">
                        <p className="al-c star">どんな医療機関がある？</p>
                        <p className="txt-middle">
                        福岡県内の外国人患者を受け入れる拠点的な医療機関一覧は以下の通りです。<br/>（2023年6月時点）
                        </p>
                        <br/>
                        <p className="txt-large al-c txt-yellow">
                        <span>全てキャッシュレス</span><span>対応しています。</span>
                        </p>
                    </div>
                </BlackNote>
                <div className="space-deco"></div>
            </InnerBox>
            <InnerBox>
            <TabTamplate>
                <ul className="tabnav-custom">
                    <li><button onClick={() => {onActive(0)}} className={`${active === 0 ? 'active' : ''}`}>福岡市内</button></li>
                    <li><button onClick={() => {onActive(1)}} className={`${active === 1 ? 'active' : ''}`}>福岡地方<br/>（福岡市外）</button></li>
                    <li><button onClick={() => {onActive(2)}} className={`${active === 2 ? 'active' : ''}`}>筑後地区</button></li>
                    <li><button onClick={() => {onActive(3)}} className={`${active === 3 ? 'active' : ''}`}>筑豊地区</button> </li>
                    <li><button onClick={() => {onActive(4)}} className={`${active === 4 ? 'active' : ''}`}>北九州地区</button></li>
                </ul>
            
                <nav className="tab">
                    <div className={`tab-content ${active === 0 ? 'active' : ''}`}>
                        <h3>福岡市内の医療機関の医療機関</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>市町村</th>
                                    <th rowSpan={2}>医療機関名</th>
                                    <th colSpan={2}>受入可能<br/>患者</th>
                                    <th rowSpan={2}>JMIP<br/>※1<br/>取得</th>
                                    <th rowSpan={2}>対応可能<br/>言語</th>
                                </tr>
                                <tr>
                                    <th>重症<br/>患者</th>
                                    <th>軽症<br/>患者</th>
                                </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[0]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 1 ? 'active' : ''}`}>
                        <h3>福岡地方（福岡市外）の医療機関</h3>
                        <p>（2023年6月時点）</p>
                        <table>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>市町村</th>
                                    <th rowSpan={2}>医療機関名</th>
                                    <th colSpan={2}>受入可能<br/>患者</th>
                                    <th rowSpan={2}>JMIP<br/>※1<br/>取得</th>
                                    <th rowSpan={2}>対応可能<br/>言語</th>
                                </tr>
                                <tr>
                                    <th>重症<br/>患者</th>
                                    <th>軽症<br/>患者</th>
                                </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[1]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 2 ? 'active' : ''}`}>
                        <h3>筑後地区の医療機関</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>市町村</th>
                                    <th rowSpan={2}>医療機関名</th>
                                    <th colSpan={2}>受入可能<br/>患者</th>
                                    <th rowSpan={2}>JMIP<br/>※1<br/>取得</th>
                                    <th rowSpan={2}>対応可能<br/>言語</th>
                                </tr>
                                <tr>
                                    <th>重症<br/>患者</th>
                                    <th>軽症<br/>患者</th>
                                </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[2]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 3 ? 'active' : ''}`}>
                        <h3>筑豊地区の医療機関</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>市町村</th>
                                    <th rowSpan={2}>医療機関名</th>
                                    <th colSpan={2}>受入可能<br/>患者</th>
                                    <th rowSpan={2}>JMIP<br/>※1<br/>取得</th>
                                    <th rowSpan={2}>対応可能<br/>言語</th>
                                </tr>
                                <tr>
                                    <th>重症<br/>患者</th>
                                    <th>軽症<br/>患者</th>
                                </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[3]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 4 ? 'active' : ''}`}>
                        <h3>北九州地区の医療機関</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>市町村</th>
                                    <th rowSpan={2}>医療機関名</th>
                                    <th colSpan={2}>受入可能<br/>患者</th>
                                    <th rowSpan={2}>JMIP<br/>※1<br/>取得</th>
                                    <th rowSpan={2}>対応可能<br/>言語</th>
                                </tr>
                                <tr>
                                    <th>重症<br/>患者</th>
                                    <th>軽症<br/>患者</th>
                                </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[4]}></MedicalTbody>
                        </table>
                    </div>
                   
                </nav>
            </TabTamplate>
            <br/>
            <p className="txt-small">
            ※（注１）医療機関一覧に掲載されていない医療機関における外国人患者の診療を妨げるものではありません。（注２）医療機関一覧に掲載されている医療機関は、外国から診療目的で来日する外国人患者を受け入れる医療機関のリストではありません。<br/>
            ※1 JMIP（Japan Medical Service Accreditation forInternational Patiants）→ 外国人患者受入医療機関認証制度<br/>
            国内医療機関に対し、多言語による診療案内や、異文化・宗教に配慮した対応など、外国人患者の受入れに資する体制を第三者的に評価することを通じて、国内医療機関を受診するすべての外国人に、安心・安全な医療サービスを提供できる体制づくりを支援することを目的としたものであり、2012年に厚生労働省が策定しています（認証機関：一般財団法人 日本医療教育財団）。<br/>
            ※2 ２１言語対応医療機関については、「ふくおか国際医療サポートセンター」を利用。
            </p>
            <NavigatorAction dir="right" charactorType="normal" actionType="pickup"/>

            <EmphasisNote>
                <h1 className="c-h1">他の情報を知りたい？</h1>
                <h2>全国の拠点的な<br/>医療機関の情報</h2>
                <p>
                厚生労働省及び観光庁のホームページで公表されています。<br/>
                </p>
                <p>
                厚生労働省HP<br/>
                <a href="https://www.mhlw.go.jp/stf/newpage_05774.html" target="_blank">
                    https://www.mhlw.go.jp/stf/newpage_05774.html
                </a><br/>
                </p>
                <p>
                観光庁HP<br/>
                <a href="https://www.jnto.go.jp/emergency/jpn/mi_guide.html" target="_blank">
                https://www.jnto.go.jp/emergency/jpn/mi_guide.html
                </a>
                </p>
                <h2>福岡県内の医療機関等の情報</h2>
                <p>
                福岡県内の病院・診療所・歯科診療所・小児科・助産所の検索、小児救急・予防・保健・福祉などの医療情報の提供を行うホームページです。<br/>
                </p>
                <p>
                ふくおか医療情報ネット<br/>
                <a href="https://www.fmc.fukuoka.med.or.jp/" target="_blank">
                https://www.fmc.fukuoka.med.or.jp/
                </a>
                </p>
            </EmphasisNote>
            </InnerBox>
        </Article>
    )
}

export default LangJa