import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import AriclesSlider from "@/components/Molecules/Slider/ariclesSlider"
import { useEffect, useState } from "react"
import { FetchArtcleIndexArg, fetchArticleIndex } from "src/data/fetch"
import { AritcleIndex, article } from "src/types/article"
import { langType } from "src/types/lang"
import { easyJaToJa } from "src/utilty/easyJaCheker"
import { getCmsNews } from "src/utilty/getNews"
import { PathAnalysisValue } from "src/utilty/pathAnalyzer"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"


const SNews = styled.section`
    position: relative;
    width: 100%;
    height: 100%;
    background: url('images/top/newSecionBk.png') no-repeat;
    background-size: 100% auto;
    background-position: left;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        background: url('images/pc/bk_news.webp') no-repeat;
        background-size: auto 100%;
        background-position: right;
        width: 44%;
        position: absolute;
        right: 5%;
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 40%;
        right: 5%;
    }
`

const SSliderWrap = styled.div`
    width: 78%;
    padding-top: 10%;
    margin-left: 5%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 52%;
        position: absolute;
        right: 10%;
        top: -12%;
        z-index: 12;
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        top: -5%;
    }
    
    
`

const Sheadline = styled.h2`
    position: absolute;
    width:fit-content; 
    top: -5%;
    z-index: 4;
    left: 50%;
    width: 70%;
    transform: translateX(-50%);
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 50%;
        top: 50%;
        left: 0;
        transform: translate(-10%, -60%);
    }
`

const SMoreLink = styled.div`
    position: absolute;
    bottom: 25%;
    right: 7%;
    width: 46%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        bottom: -5%;
        right: -5%;
        width: 38%;
    }
`

const NewsSection = ({pathValue}:{pathValue: PathAnalysisValue}) => {

    const NewsArgs:FetchArtcleIndexArg  = {
        endpoint: 'information',
        lang: pathValue.lang,
        offset: 0,
        limit: 5,
        categroyId : 'index'
    }
    
    const MovieArgs:FetchArtcleIndexArg  = {
        endpoint: 'movie',
        lang: pathValue.lang,
        offset: 0,
        limit: 5,
        categroyId : 'index'
    }

    const LifeStyleArg:FetchArtcleIndexArg  = {
        endpoint: 'lifestyle',
        lang: pathValue.lang,
        offset: 0,
        limit: 5,
        categroyId : 'index'
    }
 
    const InitiativesArg:FetchArtcleIndexArg  = {
        endpoint: 'initiatives',
        lang: pathValue.lang,
        offset: 0,
        limit: 5,
        categroyId : 'index'
    }

    const BasicInfoArg:FetchArtcleIndexArg  = {
        endpoint: 'basicinfo',
        lang: pathValue.lang,
        offset: 0,
        limit: 5,
        categroyId : 'index'
    }

    const VirtualArg:FetchArtcleIndexArg  = {
        endpoint: 'virtual',
        lang: pathValue.lang,
        offset: 0,
        limit: 20,
        categroyId : 'index'
    }
    
    const [articles, setArticle] = useState<AritcleIndex>()
    useEffect(() => {
        const args:FetchArtcleIndexArg  = {
            endpoint: 'information',
            lang: pathValue.lang,
            offset: 0,
            limit: 5,
        }
        const getArticlesIndex =  async () => {
            const contents  =  await getCmsNews({
                                NewsArgs,
                                MovieArgs,
                                LifeStyleArg,
                                InitiativesArg,
                                BasicInfoArg,
                                VirtualArg 
                            })
            setArticle({
                contents : contents.slice(0, 5),
                totalCount: contents.length,
                offset : 0,
                limit : 100
            })
        };
        
        getArticlesIndex()
    },[])
    


    return (
        <SNews>
            <Sheadline>
                <img src={publicPath('images/headline/news.png')} 
                alt="news" 
                className="img-fit"
                />
            </Sheadline>
            <SSliderWrap>
            {articles && 
                <AriclesSlider
                    articles={articles}
                    lang={pathValue.lang}
                    layer="information"
                ></AriclesSlider>
            }
            </SSliderWrap>
            <SMoreLink>
                <YellowArrowLink lang="ja" linkKey="more" href={`/information?lang=${pathValue.lang}`}/>
            </SMoreLink>
        </SNews>
    )
    
}

export default NewsSection