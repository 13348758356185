import InnerBox from "@/components/Atoms/Box/innerBox"


const LangNe = () => {

    return (
        <InnerBox bottom={20} left={0} right={0}>
        <h1>
        स्थानीय जापानी कक्षाहरूको बारेमा<br/>
        फुकुओका प्रान्तमा、प्रशासन तथा स्वयंसेवकहरू द्वारा सञ्चालन गरेका भाषा कक्षाहरू १०० ठाउमा छ।
        </h1>
        <p>
        जापानी भाषा कक्षाहरू、जापानी भाषा सिक्ने ठाउँको रूपमा मात्र नभई、स्थानीय क्षेत्रमा बसोबास गर्ने जापानीहरूसँग अन्तरक्रिया गर्ने ठाउँको रूपमा पनि काम गरेको छ।दैनिक जीवनमा परेको दुख समस्याको बारे आदि मा पनी सजिलै परामर्श गर्न सकिने कक्षा पनी भएकाले、थप जानकारीको लागि、तलको चित्र उदाहरण गर्नुहोस।<br/>
        थप रूपमा、फुकुओका प्रान्तले、फुकुओकामा बसोबास गर्ने विदेशीहरूले चिनेको ठाउँमा जापानी भाषा सिक्न सकिने वातावरण सिर्जना गर्न、जापानी भाषा शिक्षा वातावरण सुधार परियोजना कार्यान्वयन गरी、प्रशासन द्वारा सञ्चालित जापानी भाषा कक्षाहरू सञ्चालन गर्नका लागि सहयोग गर्ने तथा、स्वयंसेवक तालिम पाठ्यक्रमहरू सञ्चालन इत्यादि गर्छौं।
        </p>
        </InnerBox>
    )
}

export default LangNe