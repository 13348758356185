import publicPath from "src/utilty/publicPath"
import styled from "styled-components"


export const SearchSubmit = () => {
    
    const SInput = styled.input`
        display: inline-block;
        width: 20px;
        height: 20px;
        padding: 5px;
        background: #fff;
        border-radius: 0;
    `

    return (
        <SInput 
            type="image" 
            name="submit" 
            src={publicPath('images/icon_search.svg')}/>
    )
}