import { FetchArtcleIndexArg, fetchArticleIndex, fetchArticleNews } from "src/data/fetch";
import { ArticleObj, endpoint } from "src/types/article";


export interface IGetNewsIndex{
    NewsArgs:FetchArtcleIndexArg
    MovieArgs:FetchArtcleIndexArg
    LifeStyleArg:FetchArtcleIndexArg
    InitiativesArg:FetchArtcleIndexArg
    BasicInfoArg: FetchArtcleIndexArg
    VirtualArg: FetchArtcleIndexArg
} 

export const getCmsNews = async ({
    NewsArgs,
    MovieArgs,
    LifeStyleArg,
    InitiativesArg,
    BasicInfoArg,
    VirtualArg
}: IGetNewsIndex):Promise<ArticleObj[]> => {

        const [res1, res2, res3, res4, res5, res6] = await Promise.all([
                        fetchArticleIndex(NewsArgs),
                        fetchArticleIndex(MovieArgs),
                        fetchArticleNews(LifeStyleArg),
                        fetchArticleNews(InitiativesArg),
                        fetchArticleNews(BasicInfoArg),
                        fetchArticleNews(VirtualArg),
                    ])

        const contents = [
            ...addendpoint(res1.contents, 'information'),
            ...addendpoint(res2.contents, 'movie'),
            ...addendpoint(res3.contents, 'lifestyle'),
            ...addendpoint(res4.contents, 'initiatives'),
            ...addendpoint(res5.contents, 'basicinfo'),
            ...addendpoint(res6.contents, 'virtual'),
        ]
        
       contents.sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime());

       return contents
}


const addendpoint = (contents:ArticleObj[], endpoint: endpoint):ArticleObj[] => {
                    
    return contents.map((c) =>  {c.endpoint = endpoint; return c })
}