import PageTitleInitiatives from "@/components/Molecules/PageTitle/initiatives"
import SubLayerTemplate from "@/components/Templates/subLayer"
import { useLocation, useParams } from "react-router-dom"
import getLayer from "src/utilty/getLayer";
import InitiativesIndusty from "./industry"
import InitiativesSightseeing from "./sightseeing"
import InitiativesHealth from "./health"
import InitiativesEnvironment from "./environment"
import InitiativesSpots from "./spots"
import { useEffect, useState } from "react"
import { pathAnalyzer } from "src/utilty/pathAnalyzer"
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio"
import { CmsIndex } from "@/components/Organisms/Cms";


const InitiativesPage = () => {

    const { category } = useParams();

    const layer = getLayer('initiatives')
    let layerData
    category && category !== 'index' ? layerData = layer.sublayer[category] :  layerData = layer

    const [ segment, setSegment  ] = useState<string>()
    const location = useLocation()
    const path = pathAnalyzer(location)

    useEffect(()=>{

        if(!category || category == 'index'){
            setSegment('index')
        }else{
            setSegment(category)
        }
    },[category])
    
    

    return(
        <ResponsiveFioTemplate>
            <SubLayerTemplate>
                <PageTitleInitiatives lang={path.lang}>
                    {layerData[path.lang].title}
                </PageTitleInitiatives>
                {
                    segment === 'index' &&
                    <CmsIndex></CmsIndex>
                }
                {
                    segment === 'industry' &&
                    <InitiativesIndusty></InitiativesIndusty>
                }
                {
                    segment === 'sightseeing' &&
                    <InitiativesSightseeing></InitiativesSightseeing>
                }
                {
                    segment === 'health' &&
                    <InitiativesHealth></InitiativesHealth>
                }
                 {
                    segment === 'environment' &&
                    <InitiativesEnvironment></InitiativesEnvironment>
                }
                 {
                    segment === 'sports' &&
                    <InitiativesSpots></InitiativesSpots>
                }
            </SubLayerTemplate>
        </ResponsiveFioTemplate>
    )
}

export default InitiativesPage