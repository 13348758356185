import { PropsWithChildren } from "react"
import styled from "styled-components"
import { SArticle } from "@/components/Templates/article"


interface ArticleProps {
    offset?: number,
    background?: boolean
    className? : string
}

const Article = ({
    offset = 20,
    children,
    ...props
}:PropsWithChildren<ArticleProps>) => {


    const ArticleWrap = styled(SArticle)`
        padding-left: ${offset}px;
        padding-right: ${offset}px;
        min-height: auto;
    `
    const ArticleInner = styled.div`
        max-width: 980px;
    `

    return (
        <ArticleWrap className={props.className}>
            <ArticleInner>
                {children}
            </ArticleInner>
        </ArticleWrap>
    )


}

export default Article 