import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorGood, NavigatorPickUp } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"

const LangZhTw = () => {

    return (<>
    <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="normal" dir="right" actionType="pickup" actionColor="blue"/>
        </div>
        <BlackNote>
        <p className="large">福岡縣的GDP為</p>
        <p className="xl">
        17,8027百万美元
        </p>
        <p className="middle txt-yellow">
        一個人的平均縣民所得金額：約<br/>
        24,790美元
        </p>
        <p className="al-c">(2022年4月最新)</p>
        <span className="txt-small">
        資料 福岡縣調査統計課
        <br/>
        「令和２年度縣民經濟・是経済計算報告書」<br/>
        1美元以106.09円匯率換算（2020年度平均）<br/>
        資料來源：內閣府《月度經濟報告主要經濟指標》
        </span>
        </BlackNote>
    </div>
    <br/>
    <div className="navpicup sp-only">
        <NavigatorPickUp dir="right"></NavigatorPickUp>
    </div>
    <InnerBox top={20}>
    <EmphasisNote>
    <h2>農業</h2>
    <p>
    福岡縣的耕地面積為78,900公頃（令和４年），佔全縣土地的16%，其特徵是水田比例高達81%（全國平均54%）
    <br/>
    </p>
    <h2>森林・林業</h2>
    <p>
    福岡縣的森林面積約22.4萬公頃，佔全縣土地的45%森林具有補充水源、防止土石流等公共功能，在保障縣民的安全且安心生活中，扮演非常重要的腳色
    <br/>
    </p>
    <h2>水産業</h2>
    <p>
    福岡縣境內有各具特色的築前海、有明海、豐前海以及河流、湖泊等，內陸水域進行多樣性的漁業和水產養殖活動
    在此海域上岸的海產品種類繁多，其中以紅鯛和紫菜的產量為全國領先<br/>

    <br/>
    </p>
    <h2>商工業</h2>
    <p>
    福岡縣的工業主要發展鋼鐵、煤炭、化學等基礎材料產業和食品等生活相關產業，但近年來汽車相關產業等加工組裝產業的比重不斷增加<br/>
    此外，由於擁有農林水產品豐富、品質優良，且靠近消費中心及物流環境良好，促使食品製造業日益集中
    </p>
    </EmphasisNote>
    </InnerBox>
    <InnerBox top={20}>

    <h1>
    Growth Industry<br/>
    支持福岡未來的成長產業
    </h1>
    <p>
    福岡擁有許多大學及先進研究機關、具備優秀技術的製造業。<br/>
    本縣也致力於鼓勵企業投入、專注在預期擴大市場的成長產業。
    </p>
    <EmphasisNote>
    <h2>1. 生物業界</h2>
    <p className="txt-middle">
    福岡被認定為生物最先進的社區，加速欲成為先進生物技術據點的相關舉措
    </p>
    <p>
    福岡縣自2001年起，與久留米市共同以產官學組成的「福岡縣生物產業據點推進會議」為據點，目標是聚集生物相關企業。<br/>
    在推進會議的努力之下，生物相關企業由最初的32家公司增加到超過230家。
    福岡也陸續誕生多家深受國內外注目的生技新創公司，如透過核酸醫藥做COVID-19治療藥物開發而受到矚目的次世代醫療BONAC株式會社，以及在全日本基因組編輯技術上深具優勢的EditForce株式會社等。<br/>
    這些福岡縣的優勢獲得很高評價，也在2021年6月被內閣府認定為西日本唯一的「地方生物社區」（全國共4處）。
    </p>
    <h2>2. 太空產業</h2>
    <p className="txt-middle">
    福岡縣被選為九州推動太空商務創新自治團體第一名。以源自於福岡的軟硬體技術挑戰太空
    </p>
    <p>
    福岡縣聚集了未來極可能活躍於太空商務的潛力企業，如具有卓越技術的製造業，或催生出特殊新服務的IT新創產業等。<br/>
    iQPS株式會社（福岡市）就成功地開發並發射了不受日夜或天候左右而能觀測地表，世界最高水準的小型SAR衛星，2021年5月也成功地拍射到世界最高水準的高解析度照片。iQPS的目標是持續擴大系統，可望於2025之後由36顆衛星每隔10分鐘觀測一次地表。<br/>
    2020年9月，內閣府將福岡縣選為九州推動太空商務創新的自治團體第一名。
    </p>
    <h2>3. 區塊鏈</h2>
    <p className="txt-middle">
    集結日本最頂尖的人才與技術，成立國內屈指可數的區塊鏈中心
    </p>
    <p>
    區塊鏈是一種開發來管理加密資產的技術，因其可追溯性、防竄改數據的能力以及對誤操作的耐受力而受到矚目。<br/>
    福岡縣除了擁有衆多區塊鏈新創企業和高端技術的工程師之外，還有引領區塊鏈領域的研究、優秀工程師輩出的九州工業大學計算機理工學院和近大産業理工學院。<br/>
    為了發揮振興IT產業的優勢，福岡縣從2020年起，正式對區塊鏈科技的研究開發和人才培養提供支援，成為日本區塊鏈的主要據點。
    </p>
    <h2>4. 能源產業</h2>
    <p className="txt-middle">
    發展氫能源產業、聚集企業、引進海上風力發電。
    </p>
    <p>
    日本以2050年達成碳中和為目標，預計將可再生能源作為主要的電力來源。可再生能源中的海上風力發電，對四面環海的日本而言可說是最理想的選擇。<br/>
    福岡縣在推動引進海上風力發電的同時，也與產官學合作以謀求聚集風電企業。此外，為了成為更加環保的氫能源社會，本縣也與產官學合作開設「福岡縣氫能源戰略會議」。<br/>
    致力於從氫的製造、儲藏、輸送乃至於使用的廣泛研究開發、產品開發、人才培育，加上氫能源企業的育成、招募等。
    </p>
    <h2>5. 其他</h2>
    <p className="txt-middle">
    福岡與九州的主要成長產業
    </p>
    <p>
    九州有成功的環境、農林水產業、汽車產業，都屬於成長產業。<br/>
    ●環境產業<br/>
    積極執行不浪費有限資源的措施，如回收保特瓶或家電產品，以及鋰電池中所使用的稀有金屬等。<br/>
    ●農林水產業<br/>
    九州的農林水產業相當興盛，約占國內總生產農漁產品之20％。
    尤其福岡更是生產多種廣獲國內外好評的農漁作物，如甘王草莓或八女茶等。<br/>
    ●汽車產業<br/>
    主要的汽車製造商如NISSAN、TOYOTA、大發汽車都在北九州有生產據點，汽車生產數量（輛）占了日本國內市場15％以上。
    </p>
    </EmphasisNote>
    <br/>

    <div className="sp-only">
        <NavigatorGood dir="right"></NavigatorGood>
    </div>
    </InnerBox>
    </>)
}

export default LangZhTw