
import { NavigatorNice } from "@/components/Atoms/Character/navigator"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorFukidashiPicup } from "@/components/Molecules/CharactorFukidashi/navigatorFukidashi"
import publicPath from "src/utilty/publicPath"

const LangTh = () => {

    return (<>
    <h1>การช่วยเหลือชาวต่างชาติยามเกิดภัยพิบัติ</h1>
    <p>
    การชาวต่างชาติทุกท่านที่เดินทางมาจากต่างประเทศสามารถปฏิบัติตัวได้อย่างถูกต้องกรณีเกิดภัยพิบัติ และสามารถใช้ชีวิตอยู่ในจังหวัดได้อย่างสบายใจและปลอดภัย ถือเป็นสิ่งที่สำคัญมาก<br/>
    จังหวัดฟุกุโอกะให้ข้อมูลแก่ชาวต่างชาติในรูปแบบต่างๆ การศึกษาทำความเข้าใจเกี่ยวกับภัยพิบัติถือว่าเป็นการปกป้องคุ้มครองชีวิตของท่านเอง อีกยังสามารถปกป้องคุ้มครองชีวิตของคนสำคัญอย่างเช่นในครอบครัวและเพื่อนรอบข้างได้ด้วย
    </p>
    <NavigatorFukidashiPicup dir="right">
    สิ่งที่สำคัญในกรณีเกิดภัยพิบัติคือการรับข้อมูลข่าวสารที่ถูกต้องเกี่ยวกับภัยพิบัติและการลี้ภัย การใช้เว็บไซด์หรือแอบพลิเคชันต่อไปนี้ถือว่าเป็นวิธีการรับข้อมูลที่ถูกต้องได้ง่ายและสะดวก
    </NavigatorFukidashiPicup>
    <br/>
    <EmphasisNote>

    <h2>ดาวน์โหลดแอบพลิเคชัน Fukuoka Disaster Prevention Navi Mamoru-kun </h2>
    <p>
    แอบพลิเคชัน Fukuoka Disaster Prevention Navi Mamoru-kun จะส่งข้อมูลเกี่ยวกับภัยพิบัติให้แก่ท่าน<br/>
    ไม่มีค่าใช้จ่ายใดๆ ในการลงทะเบียนและการใช้บริการ ใช้งานง่าย ทำให้รู้และเข้าใจในข้อมูลเกี่ยวกับการป้องกันภัยพิบัติ เรารอการลงทะเบียนของท่านอยู่
    </p>
    <p>
    ●ส่งข้อมูลการป้องกันภัยพิบัติของสถานที่ที่เกิดเหตุในปัจจุบันไปยังมือถือโดยตรง<br/>
    ●แสดงแผนที่ตำแหน่งสถานที่ลี้ภัยที่เข้าใจง่าย<br/>
    ●ใช้ภาพประกอบคำอธิบายข้อควรปฏิบัติตนในยามเกิดภัยพิบัติ<br/>
    ●สามารถเช็คตำแหน่งที่อยู่ของคนในครอบครัวยามเกิดภัยพิบัติ<br/>
    ●ให้ข้อมูลเป็นภาษาญี่ปุ่น อังกฤษ จีน(แบบง่ายและแบบดั้งเดิม) เกาหลี และเวียดนาม
    </p>
    <figure>
    <img src={publicPath('images/articles/mamorukun01.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/mamorukun02.png')} alt="ふくおか防災ナビ まもるくん" className="fit-img"/>
    </figure>

    <div className="app_qr">
    <a href="https://apps.apple.com/jp/app/%E3%81%B5%E3%81%8F%E3%81%8A%E3%81%8B%E9%98%B2%E7%81%BD%E3%83%8A%E3%83%93-%E3%81%BE%E3%82%82%E3%82%8B%E3%81%8F%E3%82%93/id1659756418" target="blank">
    <figure>
    <img src={publicPath('images/articles/mamorukun_app01.png')} alt="App Store" className="fit-img"/>
    </figure>
    </a>
    <hr/>
    <a href="https://play.google.com/store/apps/details?id=jp.lg.fukuoka.pref.fukuoka_bosai&hl=ja&gl=US" target="blank">
    <figure>
    <img src={publicPath('images/articles/mamorukun_app02.png')} alt="Google Play" className="fit-img"/>
    </figure>
    </a>
    </div>
    <h2>โฮมเพจของศูนย์ป้องกันภัยพิบัติจังหวัดฟุกุโอกะ</h2>
    <p>
    จังหวัดฟุกุโอกะได้จัดตั้ง ศูนย์ป้องกันภัยพิบัติจังหวัดฟุกุโอกะ เพื่อเตรียมความพร้อมในการรับมือกับภัยพิบัติและให้ข้อมูลข่าวสารใหม่ล่าสุดเกี่ยวกับภัยพิบัติ เป็นต้น
    </p>
    <p>
    （URL:<a href="https://www.bousai.pref.fukuoka.jp" target="_blank">
    https://www.bousai.pref.fukuoka.jp
    </a> ）
    </p>
    <h2>คู่มือป้องกันภัยพิบัติ</h2>
    <p>
    จัดทำ คู่มือป้องกันภัยพิบัติสำหรับชาวต่างชาติ 9 ภาษา ดังนั้นกรุณาอ่านทำความเข้าใจไว้ก่อนเพื่อเตรียมความพร้อมในการรับมือยามเมื่อเกิดภัยพิบัติ
    </p>
    <p>
    （URL:<a href="https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html" target="_blank">https://www.pref.fukuoka.lg.jp/contents/bousaihandbook-36.html</a>）
    </p>s
    <p>
    รายละเอียดที่เขียนไว้<br/>
    ●ข้อมูลเกี่ยวกับภัยพิบัติที่อาจจะเกิดขึ้นได้<br/>
    ●ข้อควรปฏิบัติยามเมื่อเกิดภัยพิบัติ<br/>
    ●การเตรียมความพร้อมเพื่อรับมือภัยพิบัติไว้ก่อน<br/>
    ●สถานที่ลี้ภัย<br/>
    ●หน่วยงานและช่องทางการติดต่อยามฉุกเฉิน เป็นต้น<br/>
    ●จัดทำคู่มือเป็น ภาษาญี่ปุ่นที่เข้าใจง่าย อังกฤษ จีน เกาหลี ตากาล็อก เวียดนาม เนปาล อินโดเนเซีย และเมียนมาร์
    </p>
    <p>
    <a href="https://www.pref.fukuoka.lg.jp/uploaded/life/671824_61619901_misc.pdf" target="_blnak">
    <figure>
    <img src={publicPath('images/articles/disasterprevantionhandbook.png')} alt="disaster prevantion handbook" className="fit-img"/>
    </figure>
    </a>
    </p>

    <h2>การซ้อมการป้องกันภัยพิบัติสำหรับชาวต่างชาติ</h2>
    <p>
    อีกทั้ง ยังมีบริการฝึกสอนการซ้อมการป้องกันภัยพิบัติให้แก่ประชาชนชาวต่างชาติเพื่อให้ประชาชนชาวต่างชาติจะได้ปฏิบัติตัวอย่างถูกต้องในยามที่เกิดภัยพิบัติขึ้นได้ ดังนั้นจึงอยากเชิญชวนให้ท่านมาลองศึกษาทำความเข้าใจและซ้อมวิธีการปฏิบัติตนในยามที่ต้องเจอกับเหตุภัยพิบัติ
    </p>
    <p>
    ●การบรรยายเกี่ยวกับภัยพิบัติที่เกิดขึ้นง่ายในประเทศญี่ปุ่น<br/>
    ●การเรียนรู้โดยการลองทำ ( การประกอบชิ้นส่วนเครื่องมือที่ใช้ในสถานที่ลี้ภัย ทดลองใช้ผลิตภัณฑ์ป้องกันภัยพิบัติที่ใช้ได้แบบทันที เป็นต้น)
    </p>
    <figure>
    <img src={publicPath('images/articles/disasterinfo01.jpg')} alt="" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/disasterinfo02.jpg')} alt="" className="fit-img"/>
    </figure>
    <figure>
    <img src={publicPath('images/articles/disasterinfo03.jpg')} alt="" className="fit-img"/>
    </figure>
    <h2>ศูนย์ช่วยเหลือยามเกิดภัยพิบัติโดยภาษาต่างประเทศ</h2>
    <p>
    จัดตั้ง ศูนย์ช่วยเหลือยามเกิดภัยพิบัติโดยภาษาต่างประเทศ เพื่อให้การช่วยเหลือชาวต่างชาติโดยองค์กรปกครองส่วนท้องถิ่นในกรณีที่เกิดภัยพิบัติขนาดใหญ่หรือที่รุนแรงขึ้น
    </p>
    <p>
    ●จะจัดตั้งในศูนย์แลกเปลี่ยนระหว่างประเทศในกรณีที่ก่อตั้งสำนักงานป้องกันภัยพิบัติจังหวัดฟุกุโอกะ<br/>
    ●จะเปิดปฏิบัติงานตลอด 24 ชั่วโมงตลอดระหว่างที่ศูนย์เปิดทำการ<br/>
    ●ภาระกิจสำคัญของศูนย์ช่วยเหลือยามเกิดภัยพิบัติโดยภาษาต่างประเทศ<br/>
    ⇒รวบรวมข้อมูลเกี่ยวกับสถานการณ์ของชาวต่างชาติจากองค์กรปกครองส่วนท้องถิ่น<br/>
    ⇒ช่วยเหลือเรื่องล่ามและการแปลเอกสารเกี่ยวกับการให้ข้อมูลและการดูแลจัดการสถานที่ลี้ภัยในยามที่เกิดภัยพิบัติตามคำร้องขอขององค์กรปกครองส่วนท้องถิ่น<br/>
    ⇒จัดส่งล่ามและนักแปลเอกสารในกรณีเกิดภัยพิบัติไปยังสถานที่เกิดเหตุตามคำร้องขอขององค์กรปกครองส่วนท้องถิ่น
    </p>
    </EmphasisNote>
    <br/>
    <NavigatorNice dir="right"></NavigatorNice>
    </>)
}

export default LangTh