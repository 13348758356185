import { LangButton } from "@/components/Atoms/Button/langButton"
import { LangLink, SAnker } from "@/components/Atoms/Links/langLink"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"

const SLangNav = styled.div`
`
const SLangList = styled.ul`
    padding-top: 5px;
    li{
        margin-bottom: 5px;
    }
    display: none;
    &.active{
        display: block;
    }
`


const LangNav = () => {

    const [active, setActive] = useState<boolean>(false)
    const onClick = () => { 
        setActive(!active)
    }

  
    
    return (
        <SLangNav>
            <LangButton onClick={onClick}></LangButton>
            <SLangList id="LangList" className={active ? 'active' : ''}>
                <li>
                    <LangLink 
                        href={'/'}
                        lang="ja">
                        日本語
                    </LangLink> 
                </li>
                <li>
                    <LangLink 
                        href={'/'}
                        lang="easy_ja">
                        やさしい日本語
                    </LangLink> 
                </li>
                <li>
                    <LangLink 
                         href={'/'}
                        lang="en">
                        English
                    </LangLink> 
                </li>
                <li>
                    <LangLink 
                         href={'/'}
                        lang='zh_cn'>
                        简体中文 
                    </LangLink> 
                </li>
                <li>
                    <LangLink 
                         href={'/'}
                        lang='zh_tw'>
                        繁體中文
                    </LangLink> 
                </li>
                <li>
                    <LangLink 
                         href={'/'}
                        lang="ko">
                        한국어
                    </LangLink> 
                </li>
                <li>
                    <LangLink 
                       href={'/'}
                        lang='ne'>
                        नेपाली भाषा
                    </LangLink> 
                </li>
                <li>
                    <LangLink 
                         href={'/'}
                        lang='vi'>
                        Tiếng Việt
                    </LangLink> 
                </li>

                <li>
                    <LangLink 
                         href={'/'}
                        lang='th'>
                        ภาษาไทย
                    </LangLink> 
                </li>
            </SLangList>
        </SLangNav>
    )
}

export default LangNav