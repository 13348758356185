
const LangJa = () => {


    return (
        <>
            <h2>
                <img src="./images/virtual/fio_park.webp" alt="FUKUOKA IS OPEN PARK"/>
            </h2>
           
            <div className="virtual-inner">
                <p className="message">
                    福岡にある実際の空間をもとに作成した
                    <span>都市連動型メタバース</span>
                    <span>「FUKUOKA IS OPENパーク」で、</span>
                    <span>福岡県の街並み・食・乗り物などを疑似体験しよう。</span>
                </p>
                <div className="fiopark">
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_01_title.webp" alt="FUKUOKA IS OPENパークの入口"/></dt>
                        <dd>
                        <p>福岡県・九州の中心「天神地区」にある緑豊かな天神中央公園で、友達と集まってゆったりおしゃべりしたり、動画の視聴を楽しもう。ここからいろいろなワールドに出かけることができます。</p>
                            <figure>
                                <img src="./images/virtual/fio_park_01_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    <dl className="solo">
                        <dt><img src="./images/virtual/fio_park_02_title.webp" alt="FUKUOKA フルーツパーク"/></dt>
                        <dd>
                            <p>季節に応じた福岡県産のフルーツや野菜の収穫を<span>楽しもう！！</span></p>
                            <figure>
                                <img src="./images/virtual/fio_park_02_img.webp"/>
                            </figure>
                        </dd>
                    </dl>
                    
                </div>
                <div className="fiopark-howto">
                    <div className="fiopark-howto-title">
                    <h3>FUKUOKA IS OPEN PARK<span> 利用方法</span></h3>
                    </div>
                    <div className="fiopark-howto-entrance">
                        <div><img src="./images/virtual/entrance.webp" alt="FUKUOKA IS OPEN 参加する"/></div>
                        <div><p>をクリックして参加できます。</p></div>
                    </div>
                    <div className="fiopark-howto-app">
                        <div>
                            <p>事前にスマートフォンやタブレット、PC、VR専用機器などへ、アプリ「cluster」のダウンロードが必要です。</p>
                            
                            <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend pc" target="_blank">
                               <span> 推奨環境はこちら</span>
                            </a>
                        </div>
                        <div>
                            <h4>●すでにアプリをお持ちの方は</h4>
                            <img src="./images/virtual/cluster01.webp" alt="clusterを起動" width={200}/>
                            <p>をクリック後に表示される、</p>
                            <img src="./images/virtual/cluster02.webp" alt="遊びに行く"/>
                            <p>をクリックして入室します。</p> 
                        </div>
                        <a href="https://help.cluster.mu/hc/ja/sections/115000206971" className="recommend sp" target="_blank">
                            <span> 推奨環境はこちら </span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

}

export default LangJa