import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import Article from "@/components/Organisms/Article"
import { SDecTextUl } from "."

const LangKo = () => {

    return (<>
     <Article offset={0}>
    <InnerBox>
    <p>후쿠오카현에는 많은 외국인이 살고 있으며, 거주 외국인 수는 일본의 도시 중에서 9번째로 많은 인기 지역입니다.</p>
    <h1>외국인 총수</h1>
    </InnerBox>
    <BlackNote>
    <SDecTextUl className="pc-flex col-2">
    <li>
    <p className="large">
    현내 거주 외국인 총수
    </p>
    <p className="large">
    <span className="xl">89,518</span>명
    </p>
    <p className="middle">(2022년 12월 현재)</p>
    </li>
    <li>
    <p className="large">
    후쿠오카 지구 거주 외국인 수
    </p>
    <p className="large">
    <span className="xl">53,009</span>명
    </p>
    <p className="middle">(2022년 12월 현재)</p>
    </li>
    <li>
    <p className="large">
    기타큐슈 지구 거주 외국인 수
    </p>
    <p className="large">
    <span className="xl">18,870</span>명
    </p>
    <p className="middle">(2022년 12월 현재)</p>
    </li>
    <li>
    <p className="large">지쿠고 지구 거주 외국인 수</p>
    <p className="large">
    <span className="xl">12,686</span>명
    </p>
    <p className="middle">(2022년 12월 현재)</p>
    </li>
    <li>
    <p className="large">지쿠호 지구 거주 외국인 수</p>
    <p className="large">
    <span className="xl">4,953</span>명
    </p>
    <p className="middle">(2022년 12월 현재)</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    <InnerBox top={10} bottom={100}>
    <p className="text-small">출처 : 법무성 '체류외국인통계'</p>
    </InnerBox>
    </Article>
    <div className="navposition01">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    <Article offset={0}>
    <InnerBox >
    <h1>
    유학생 수
    </h1>
    </InnerBox>

    <BlackNote>
    <SDecTextUl className="pc-flex col-2">
    <li>
    <p className="large">
    현내 유학생 총수
    </p>
    <p className="large">
    <span className="xl">19,188</span>명
    </p>
    <p className="middle">(2022년 12월 현재)</p>
    </li>
    <li>
    <p className="large">
    후쿠오카 지구 유학생 수
    </p>
    <p className="large">
    <span className="xl">14,800</span>명
    </p>
    <p className="middle">(2022년 12월 현재)</p>
    </li>
    <li>
    <p className="large">
    기타큐슈 지구 유학생 수
    </p>
    <p className="large">
    <span className="xl">2,631</span>명
    </p>
    <p className="middle">(2022년 12월 현재)</p>
    </li>
    <li>
    <p className="large">
    지쿠고 지구 유학생 수
    </p>
    <p className="large">
    <span className="xl">1,650</span>명
    </p>
    <p className="middle">(2022년 12월 현재)</p>
    </li>
    <li>
    <p className="large">
    지쿠호 지구 유학생 수
    </p>
    <p className="large">
    <span className="xl">107</span>명
    </p>
    <p className="middle">(2022년 12월 현재)</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    <InnerBox top={10} bottom={100}>
    <p className="text-small">출처 : 법무성 '체류외국인통계'</p>
    </InnerBox>
    </Article>
    <div className="navposition01">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    <Article offset={0}>
    <InnerBox>
    <h1>
    유학생 취업자 수
    </h1>
    </InnerBox>
    <BlackNote>
    <SDecTextUl>
    <li>
    <p className="large">
    현내 소재 기업 등의 유학생 취업자 수
    </p>
    <p className="large">
    <span className="xl">1,118</span>명
    </p>
    <p className="middle">(2021년)</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    </Article>
    </>)
}

export default LangKo