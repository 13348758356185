import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import InnerBox from "@/components/Atoms/Box/innerBox"


const LangZhTw = () => {

    return (<>
    <div className="pc-wapper">
    <InnerBox>
        <h1>
        Consulate
        </h1>
        </InnerBox>
        <BlackNote>
        <ul className="deco">
        <li>
        <p className="name">
        U.S. Consulate Fukuoka<br/> (Est. April 28, 1952)
        </p>
        <p className="address">
        810-0052 2-5-26 Ohori, Chuo-ku, Fukuoka City
        </p>
        <p className="tel">
        092-739-9088
        </p>
        </li>
        <li>
        <p className="name">
        Consulate General of South Korea, Fukuoka<br/>(Est. January 27, 1966)
        </p>
        <p className="address">
        810-0065 1-1-3 Jigyohama, Chuo-ku, Fukuoka
        </p>
        <p className="tel">
        092-771-0461
        </p>
        </li>
        <li>
        <p className="name">
        Consulate-General of The People's Republic of China in Fukuoka<br/>(Est. April 17, 1985)
        </p>
        <p className="address">
        810-0065 1-3-3 Jigyohama, Chuo-ku, Fukuoka City
        </p>
        <p className="tel">
        092-713-1121
        </p>
        </li>
        <li>
        <p className="name">
        Consulate-General of Vietnam in Fukuoka<br/>(Est. April 22, 2009)
        </p>
        <p className="address">
        810-0801 5-3-8, Nakasu, Hakata-ku, Fukuoka<br/>Aqua Hakata 4F
        </p>
        <p className="tel">
        092-263-7668
        </p>
        </li>
        <li>
        <p className="name">
        Royal Thai Consulate-General in Fukuoka<br/>( Est. October 1, 2018)
        </p>
        <p className="address">
        810-0001 4-1-37, Tenjin, Chuo-ku, Fukuoka-shi, Fukuoka <br/>2nd Floor Dai Ichi Myojo
        </p>
        <p className="tel">
        092-739-9088
        </p>
        </li>
        </ul>
        </BlackNote>
        </div>

        <div className="checkpostion01 sp-only">
            <div className="checkpostion01">
            <NavigatorCheck dir="right"></NavigatorCheck>
            </div>
        </div>

        <div className="pc-list-wrap">
        <InnerBox bottom={20}>
        <h1>姊妹市</h1>
        <EmphasisNote>
        <h2>
        夏威夷州
        （美國） <br/>1981.9.25
        </h2>
        <p>
        1980 年 9 月，"博多祇園山笠 "參加了 "阿羅哈周慶典"，同時舉辦了 "福岡物產展"，增進了兩縣州的友誼，並在各自議會分別通過了促進締結姐妹關係的決議。<br/>
        此後，關於締結姐妹關係的討論持續了一年，1981 年 9 月 25 日夏威夷州州長阿裏吉和福岡縣知事龜井在夏威夷檀香山舉行了簽字儀式。
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        江蘇省 中國 <br/>1992.11.4
        </h2>
        <p>
        1992 年 4 月中國共產黨總書記江澤民來縣訪問時，知事宣布了於中日邦交正常化 20 周年之際實現與中國建立友好合作夥伴關係的目標。 為此成立了由行政部門、議會和民間團體組成的友好關係研究委員會。 派出考察團，並進行商議，之後決定與江蘇省建立友好合作關係。<br/>
        平成4年9月，提出了建立友好合作关系的申请。同年11月4日，江苏省省长陈江与福冈县知事奥田在南京市举行了签署仪式。
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        曼穀(泰國）<br/>2006.2.8
        </h2>
        <p>
        為了進一步廣泛地深化與亞洲各國的友好，決定與在亞洲地區發展顯著的泰國進行交流，特別是在本縣主要的汽車產業方麵。 2005 年 10 月本縣派出考察團前往曼穀，與曼穀都知事阿皮拉克先生會麵，確認了建立交流的意向，得到了他的積極回應。 於是於2006年2月福岡縣知事麻生又作為團長率領代表團訪問了曼穀，並簽署了友好協議。
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        德裏 （印度） <br/>2007.3.5
        </h2>
        <p>
        為了進一步廣泛地深化與亞洲各國的友好，福岡縣決定與印度開展交流。在亞洲諸多地區中，印度的發展尤為顯著，特別是在最尖端的 IT 和汽車產業方麵。其中，德裏作為該國的政治、經濟和文化中心，被選為候選地區。 2005 年 10 月本縣派出考察團前往德裏，2006 年 2 月福岡縣知事麻生又率領代表團訪問了德裏，會見了德裏首席大臣迪克希特，並就友好交流積極地交換了意見。 2006 年 12 月在印度與日本首腦會議的聯合聲明中，表示了對本縣與德裏之間友好關係的歡迎，並經印度政府批準，雙方於 2007 年 3 月締結了友好協定。
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        河內市越南_<br/>2008.2.22
        </h2>
        <p>
        為了進一步廣泛地深化與亞洲各國的友好，決定與憑借豐富的勞動力和勤勞的人民而取得顯著發展的亞洲國家越南開展交流。 其中，越南的政治、經濟和文化中心河內被選為候選地區。2005 年 10 月派出考察團後，持續開展了實務層麵的談判，2008 年 2 月福岡縣知事麻生率團訪問河內，並締結了友好協定。
        </p>
        </EmphasisNote>
        </InnerBox>
        <InnerBox bottom={20}>
        <EmphasisNote>
        <h2>
        韓國南部沿海一市三道與九州北部三縣和山口縣<br/><br/>合宣言發表
        </h2>
        <p>
        日韓兩國地理位置相鄰，自古以來交流活躍，特別是日本九州北部三縣（福岡縣、佐賀縣、長崎縣）與韓國南部沿海一市三道（釜山廣域市、全羅南道、慶尚南道、濟州特別自治道），作為日韓海峽兩岸交流的窗口發揮了重要的作用。<br/>在這一地理和歷史背景下，1990 年 10 月九州北部三縣的縣知事在長崎縣對馬舉行會議，討論促進兩地交流的問題。 經過這些討論以及與韓方的協商，1992 年 7 月達成了一項協議，即舉行一次首長會議，就兩地區交流的現狀和未來前景坦誠交換意見，並促進各領域的交流。 這次會議被命名為“日韓海峽沿岸縣市道知事交流會議”。<br/>
        　"1992 年 8 月在韓國濟州道召開了 ""第一屆日韓海峽沿岸縣市道知事交流會議""，發表了關於實施共同項目和定期召開知事交流會議，以促進日韓海峽兩岸地區交流的共同宣言。<br/>
        　山口縣自 1999 年加入。<br/>
        　2008年更名為“日韓海峽沿岸縣市道交流知事會議”。"
        </p>
        </EmphasisNote>
        </InnerBox>
        </div>

        <InnerBox bottom={20}>
            <div className="pc-navigator">
            <NavigatorCheck dir="right"></NavigatorCheck>
            </div>
        </InnerBox>
    </>)
}

export default LangZhTw