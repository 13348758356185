

import HeadLine from "@/components/Atoms/HeadLine"
import { PropsWithChildren } from "react"
import styled from "styled-components"
import { pageTitleProps } from "src/components/Molecules/PageTitle"
import { EmergencyTitleImg } from "@/components/Atoms/Emergency/titleImg"


const PageTitleEmergency = ({
    lang,
    children,
    ...props
} : PropsWithChildren<pageTitleProps>) => {

    return (
        <SPageTile>
           <EmergencyTitleImg lang={lang}></EmergencyTitleImg>
        </SPageTile>
       
    )
    
}

const SPageTile = styled.div`
    text-align: center;
    padding: 20px 0;
    img{
        width: 80%;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        padding: 0;
        img{
            width: auto;
        }
    }
`

export default PageTitleEmergency