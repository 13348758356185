const LangZhCn = () => {

    return (<>
    <h1>网站政策</h1>
    <p>
    FUKUOKA IS OPEN推進事業実行委員会（以下简称为「执行委员会」）提供的门户网站（指的是https://www.fisop.net内的门户网站，以下简称为「门户网站」），使用者请阅读以下内容。请注意，由于可能在事先无通知的情况下进行更改，请您提前了解并谅解。
    </p>
    <p>
    <dl>
    <dt>
    ●关于免责声明
    </dt>
    <dd>
    <p>
    「尽管我们在尽力确保本门户网站上发布的信息内容的完整性，但并不能保证其准确性和安全性。执行委员会对于用户因使用本网站发布的信息而遭受的损害和损失概不负责。
    </p>
    <p>
    此外，尽管本门户网站上可能包含指向由执行委员会以外的机构管理的主页的链接，但这些链接的主页可能不是由执行委员会管理和运营的。同时，我们对于这些链接主页的内容真实性等不负任何责任，请您谅解。
    </p>
    <p>
    请注意，关于在本门户网站上发布的信息内容，可能在事先无通知的情况下进行更改或者删除，请您提前了解并谅解。
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●关于著作权
    </dt>
    <dd>
    在本门户网站上刊载的文章、标识、照片、视频、软件以及所有其他信息的版权，除非另有说明，否则由执行委员会或第三方拥有。除了根据著作权法允许的私人使用等情况外，未经版权所有者事先许可，不得使用这些信息（包括复制、修改、转载、分发、公开传播等）。此外，一些图片等的版权由原始作者所有。
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●关于利用环境（浏览器）
    </dt>
    <dd>
    <p>
    建议在各种互联网浏览软件（浏览器）的最新版本中使用我们的门户网站。
    </p>
    <p>
    如果您使用的不是上述推荐的浏览器，可能导致页面无法正确显示，请提前注意。
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●关于您经常访问的页面：
    </dt>
    <dd>
    <p>
    在互联网浏览器中，有时会显示先前查看时的信息（在计算机内暂时保存的信息）。
    </p>
    <p>
    因此，关于您经常访问的网页，我们建议您定期刷新（重新加载）以获取最新信息。
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●关于JavaScript
    </dt>
    <dd>
    <p>
    在本门户网站中，部分功能使用了JavaScript。如果您的互联网浏览器不支持JavaScript，或者您已禁用JavaScript，则某些功能可能无法正常工作。
    </p>
    <p>
    为了使用所有功能，请使用支持JavaScript的互联网浏览器，或在互联网浏览器的设置中启用JavaScript。
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●法律依据
    </dt>
    <dd>
    对于本门户网站的使用以及「网站政策」的解释和适用，除非另有特别规定，将遵循日本国法律的规定。<br/>
    访问本门户网站是由访问者的自由意愿进行的，访问者有责任在其责任范围内使用此门户网站。关于与本门户网站相关的任何争议，福冈地方法院将被视为首审专属协议管辖法院。
    </dd>
    </dl>
    </p>
    </>)
}

export default LangZhCn