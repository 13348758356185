import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorGood, NavigatorPickUp } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"

const LangKo = () => {

    return (<>
     <div className="pc-flex col-2">
        <div className="pc-only">
            <NavigatorAction charactorType="normal" dir="right" actionType="pickup" actionColor="blue"/>
        </div>
        <BlackNote>
        <p className="large">후쿠오카현의 GDP</p>
        <p className="xl">
        178,027백만 달러
        </p>
        <p className="middle txt-yellow">
        1인당 현민 소득:<br/>
        24,790달러
        </p>
        <p className="al-c">(2022년 4월 현재)</p>
        <span className="txt-small">
        자료 현조사통계과<br/>
        2020년도 현민 경제·시정촌민 경제 계산 보고서'<br/>
        1달러 106.09엔으로 환산(2020년도 평균)<br/>
        자료 내각부 '월례 경제보고 주요 경제지표'
        </span>
        </BlackNote>
    </div>
    <br/>
    <div className="navpicup sp-only">
        <NavigatorPickUp dir="right"></NavigatorPickUp>
    </div>
    <InnerBox top={20}>
    <EmphasisNote>
    <h2>농업</h2>
    <p>
    후쿠오카현의 경지 면적은 7만 8,900ha(2022년)로 현면적의 16%를 차지하고 있으며, 그 중 논의 비율이 81%(전국 평균 54%)로 높은 것이 특징입니다
    <br/>
    </p>
    <h2>삼림 · 임업</h2>
    <p>
    후쿠오카현의 삼림 면적은 약 22만 4,000ha로 현면적의 45%를 차지하고 있습니다. 삼림은 유역 보호, 토사 유출 방지 등 공익적 기능을 가지고 있으며 현민의 안전하고 안심할 수 있는 생활을 영위하는 데 중요한 역할을 하고 있습니다
    <br/>
    </p>
    <h2>수산업</h2>
    <p>
    후쿠오카현에서는 특징이 다른 지쿠젠해, 아리아케해, 부젠해의 해역과 하천·호수 등의 내수면에서 다양한 어업 및 양식업이 이루어지고 있습니다.<br/>
    전국 유수의 생산을 자랑하는 참돔과 김을 비롯한 다양한 수산물이 어획됩니다
    <br/>
    </p>
    <h2>상공업</h2>
    <p>
    후쿠오카현의 공업은 철강, 석탄·화학 등의 기초 소재형 산업과 식료품 등의 생활 관련형 산업을 중심으로 발전해 왔지만 최근에는 자동차 관련 산업 등의 가공 조립형 산업의 비중이 높아지고 있습니다.<br/>
    또한 식료품 제조업은 양질의 풍부한 농림 수산물이 조달 가능하고 대형 유통지 인근에 물류 환경이 정비되어있어 집적이 진행되고 있습니다.
    </p>
    </EmphasisNote>
    </InnerBox>
    <InnerBox top={20}>

  
    <h1>
    Growth Industry<br/>
    후쿠오카의 미래를 지원하는 성장 산업
    </h1>
    <p>
    후쿠오카에는 많은 대학과 선진적인 연구 기관, 뛰어난 기술을 가진 제조업이 있다.<br/>
    현은 시장 확대가 예상되는 이러한 성장 산업에 대한 기업의 진출 및 집중을 촉구하는 데 주력하고 있다.
    </p>
    <EmphasisNote>
    <h2>1. 바이오 업계</h2>
    <p className="txt-middle">
    후쿠오카가 바이오의 최첨단 커뮤니티로 인정받아, 선진적인 바이오테크놀로지 거점이 되기 위한 노력을 가속화
    </p>
    <p>
    후쿠오카현은 2001년부터 구루메시와 공동으로, 산학관으로 구성된 ‘후쿠오카현 바이오산업 거점 추진 회의’를 기반으로, 바이오 관련 기업의 집적을 목표로 하고 있다.<br/>
    추진 회의가 노력한 결과, 바이오 관련 기업은 당초 32사에서 230사 이상으로 증가했다.
    후쿠오카에서는 차세대 의료로서 핵산의약에 의한 COVID-19의 치료약 개발에서 주목받는 주식회사 보낙과 전일본적인 게놈 편집 기술에 강점을 가진 에디트포스 주식회사 등 일본 국내외에서 주목받는 바이오벤처들이 잇달아 탄생하고 있다.<br/>
    이러한 후쿠오카현의 이점은 높은 평가를 받아, 2021년 6월에는 내각부에서 서일본에서 유일한 ‘지역 바이오 커뮤니티’(일본 전국에서 4곳)로 인정받았다.
    </p>
    <h2>2. 우주산업</h2>
    <p className="txt-middle">
    규슈의 우주 비즈니스 창출을 추진하는 지자체 제1호에 후쿠오카현이 선정되었다. 후쿠오카발 하드/소프트 기술로 우주에 도전
    </p>
    <p>
    후쿠오카현에는 뛰어난 기술을 가진 제조업과 독특한 신서비스를 창출하는 IT 벤처 등 우주 비즈니스에서 활약할 가능성을 지닌 기업들이 집적되어 있다.<br/>
    주식회사 iQPS(후쿠오카시)는 낮과 밤 또는 날씨에 좌우되지 않고 지표를 관측할 수 있는 세계 최고 수준의 소형 SAR 위성의 개발 및 발사에 성공하여,2021년 5월에는 세계 최고 수준의 고해상도 이미지 촬영에도 성공했다.iQPS는 2025년 이후 36위성 별자리로 10분 간격으로 지표를 관측할 수 있는 시스템의 확장을 목표로 하고 있다.<br/>
    2020년 9월, 내각부는 규슈에서 우주 비즈니스 창출을 추진하는 지자체 제1호로 후쿠오카현을 선정했다.
    </p>
    <h2>3. 블록체인</h2>
    <p className="txt-middle">
    일본의 최첨단 인재와 기술을 결집하여, 일본 국내에서 손꼽히는 블록체인 허브를 설립
    </p>
    <p>
    암호화 자산을 관리하는 기술로 개발된 블록체인은 그 추적성, 데이터의 변조를 방지하는 능력, 오작동에 대한 내성이 주목받고 있다.<br/>
    후쿠오카현에는 많은 블록체인 스타트업이나 고도의 스킬을 가진 엔지니어뿐만 아니라, 블록체인 분야의 연구를 리드하여, 우수한 엔지니어를 배출하고 있는 규슈공업대학 컴퓨터 이공학부와 긴키대학 산업 이공학부가 있다.<br/>
    이러한 IT산업 진흥의 장점을 살리기 위해 후쿠오카현은 2020년부터 블록체인 기술의 연구 개발과 인재 육성에 대한 지원을 본격화하여, 일본에서 블록체인의 주요 거점이 되고 있다.
    </p>
    <h2>4. 에너지산업</h2>
    <p className="txt-middle">
    수소에너지산업을 발전시켜 기업의 집적을 도모하며 해상풍력발전을 도입한다.
    </p>
    <p>
    일본은 2050년까지 탄소 중립 달성을 목표로 재생 가능 에너지를 주요 전력원으로 삼으려 하고 있다.재생 가능 에너지 중에서도 해상풍력발전은 사방이 바다로 둘러싸인 일본에서 이상적인 선택지가 될 것으로 기대한다.<br/>
    후쿠오카현은 또한 해상풍력발전의 도입을 추진하는 동시에 풍력발전기업의 집적을 도모하기 위해 산학관과 연계하고 있다.또한, 현은 친환경 수소에너지 사회 실현을 위해 산학관이 연계하여 ‘후쿠오카현 수소에너지 전략 회의’를 설립했다.<br/>
    수소의 제조, 저장, 수송부터 이용까지 폭넓은 연구 개발, 제품 개발, 인재 육성과 더불어 수소에너지 기업의 육성 및 집적에도 힘쓰고 있다.
    </p>
    <h2>5. 기타</h2>
    <p className="txt-middle">
    후쿠오카와 규슈의 주요 성장 산업
    </p>
    <p>
    규슈에서는 환경, 농림수산업, 자동차산업이 성공하여 성장 산업으로 여겨지고 있다.<br/>
    ●환경산업<br/>
    페트병이나 가전제품 리사이클과 더불어, 리튬 이온 전지 등에 사용되는 레어메탈의 리사이클 등, 한정된 자원을 낭비하지 않기 위한 노력도 적극적으로 이루어지고 있다.<br/>
    ●- 농림수산업<br/>
    규슈에서는 농림수산업이 발달하여, 일본 국내에서 생산되는 농수산물의 20%를 차지하고 있다.
    특히 후쿠오카에서는 아마오우 딸기와 야메차 등 일본 국내외에서 높은 평가를 받는 농수산물이 생산되고 있다.<br/>
    ●자동차산업<br/>
    주요 자동차 제조사인 닛산, 도요타, 다이하쓰는 북부 규슈에 생산 거점이 있으며, 자동차 생산 대수는 일본 국내 시장의 15% 이상을 차지하고 있다.
    </p>
    </EmphasisNote>
    <br/>
    <div className="sp-only">
        <NavigatorGood dir="right"></NavigatorGood>
    </div>
    </InnerBox>
    </>)
}

export default LangKo