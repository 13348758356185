import styled from "styled-components"
import NewsSection from "../Organisms/TopPageSections/news"
import TopSection from "../Organisms/TopPageSections/top"
import ResponsiveTopTemplate from "../Templates/Responsive/top"
import GwmSection from "../Organisms/TopPageSections/gwm"
import BasicInfoSection from "../Organisms/TopPageSections/basicInfo"
import InitiaivesSeciton from "../Organisms/TopPageSections/Initiatives"
import ConsultationSection from "../Organisms/TopPageSections/consultation"
import MovieSection from "../Organisms/TopPageSections/movie"
import VirtualSection from "../Organisms/TopPageSections/virtual"
import SpecialSection from "../Organisms/TopPageSections/special"
import AboutSection from "../Organisms/TopPageSections/about"
import SupprtSection from "../Organisms/TopPageSections/support"
import { NavigateCracter, NavigatorCheck, NavigatorEnjoy, NavigatorGoodLuck, NavigatorJaggedMark, NavigatorSmorkMark } from "../Atoms/Character/navigator"
import LifeStyleSection from "../Organisms/TopPageSections/lifestyle"
import publicPath from "src/utilty/publicPath"
import { ChallengerNice, ChallengerrJaggedMark } from "../Atoms/Character/challenger"
import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { ChallengerAction, NavigatorAction } from 'src/components/Molecules/CharactorAction'
import { pathAnalyzer } from "src/utilty/pathAnalyzer"
import getLayer from "src/utilty/getLayer"
import { setTitleDiscrption } from "src/utilty/setTitleDiscription"

const SNone = styled.div`
    display:none;
`

const SectionWrap = styled.div`
    width: 100%;
    position: relative;
    opacity:0;
    &.left{
        &.active{
            animation-name:fedeLeftAnime;
            animation-duration:0.5s;
            animation-fill-mode:forwards;
        }
    }

    &.right{
        &.active{
            animation-name:fedeRightAnime;
            animation-duration:0.5s;
            animation-fill-mode:forwards;
        }
    }


    @keyframes fedeLeftAnime{
        from {
            opacity: 0.3;
            transform: translateX(-200px);
        }

        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    @keyframes fedeRightAnime{
        from {
            opacity: 0.3;
            transform: translateX(200px);
        }

        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
`


const SNewWrap = styled(SectionWrap)`
    margin-top: -25%;
    aspect-ratio:320 / 356;
    z-index: 10;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        margin-top: -15%;
        aspect-ratio:1200 / 300;
        position: relative;
        margin-bottom: 120px;
    }
`
const SGwmWrap = styled(SectionWrap)`
     aspect-ratio:320 / 220;
     z-index: 10;
     @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:4 / 1;
        margin-bottom: 120px;
     }
`
const SBasicinfo = styled(SectionWrap)`
     aspect-ratio:320 / 500;
     z-index: 10;
     @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:4 / 1.2;
     }
`


const SLiving = styled(SectionWrap)`
    margin-top: -2%;
    aspect-ratio:320 / 365;
    z-index: 10;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:10 / 3;
        z-index: 11;
    }
`

const SInitiatives = styled(SectionWrap)`
     aspect-ratio:320 / 360;
     z-index: 12;
     @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:10 / 2.8;
        z-index: 11;
    }
`
const SConsultation = styled(SectionWrap)`
     aspect-ratio:320 / 300;
     z-index: 10;
     @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:1200 / 270;
        z-index: 11;
    }
`

const SMoive = styled(SectionWrap)`
    aspect-ratio:320 / 370;
    z-index: 11;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:1200 / 300;
        position: relative;
        margin-bottom: 120px;
    }
`

const SVirtual = styled(SectionWrap)`
    aspect-ratio:320 / 145;
    z-index: 10;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        z-index: 12;
        aspect-ratio:1200 / 220;
        position: relative;
        margin-bottom: 120px;
    }
`

const SSpecial  = styled(SectionWrap)`
    aspect-ratio:320 / 145;
    z-index: 10;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:1200 / 220;
    }
`

const SAbout  = styled(SectionWrap)`
    aspect-ratio:320 / 260;
    z-index: 12;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:1200 / 260;
     }
`

const SSupport  = styled(SectionWrap)`
    aspect-ratio:320 / 320;
    z-index: 10;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:1200 / 260;
        margin-bottom:200px;
     }
`

const SSpase = styled.div`
    position: relative;
    .decoration{
        position: absolute;
    }
`
const SSpaceBasicLiving = styled(SSpase)`
    aspect-ratio:320 / 200;
    margin-top: -15%;
    .decoration{
        width: 100%;
        top:10%;
        left: 50%;
        transform: translateX(-56%);
        position: absolute;
        z-index: 9;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:4 / 1;
        z-index: 11;
        margin-top: -5%;
        position: relative;
       
        .decoration{    
            width: 670px;
            height: 100%;
            top: 0;
            left: 70%;
        }
     }
`

const SSpaceLivingCons = styled(SSpase)`
    aspect-ratio:320 / 85;
    .decoration{
        width: 45%;
        right: 0px;
        top: -20px;
        z-index: 12;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:10 / 1;
        .decoration{
            width: 20%;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        aspect-ratio:10 / 1;
        .decoration{
            width: 30%;
        }
    }
`

const SSpaceConsInitia = styled(SSpase)`
    aspect-ratio:320 / 130;
    .decoration{
        width: 100%;
        left: 50%;
        top: 0px;
        z-index: 11;
        transform: translateX(-45%);
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:100 / 14;
        .decoration{
            width: 30%;
            left: 46%;
        }
    }
    @media screen and (min-width: 1700px){
        aspect-ratio:100 / 12;
    }
`

const SSpaceInitiaMovie = styled(SSpase)`
    aspect-ratio:320 / 120;
    margin-top: -10%;
    .decoration{
        &.d1{
            width: 26%;
            right: 20px;
        }
        &.d2{
            left: 10px;
            width: 30%;
            bottom: -12%;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:100 / 14;
        z-index: 12;
        margin-top: -8%;
        .decoration{
            &.d1{
                width: 14%;
                right: 20px;
            }
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        .decoration{
            &.d1{
                width: 26%;
                right: 20px;
            }
        }
    }
    
`

const SSpeaceMovieVirtual = styled(SSpase)`
    aspect-ratio:320 / 165;
    margin-top: -22%;
    .decoration{
        width: 74%;
        right: 5%;
        top: 40px;
        z-index: 10;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:100 / 18;
        margin-top: -8%;
        .decoration{
            width: 670px;
            right: auto;
            left: 20%;
            top: 0%;
            z-index: 11;
        }
    }
`

const SSpeaceVirtualSpecial = styled(SSpase)`
    aspect-ratio:320 / 90;
    margin-top: -10%;
    .decoration{
        &.d1{
            width: 26%;
            left: 10px;
            z-index: 11;
        }
        &.d2{
            left: 10px;
            width: 60%;
            bottom: -42%;
            right: -30%;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:100 / 15;
        margin-top: -12%;
        .decoration{
            &.d1{
                width: 26%;
                left: 85%;
                z-index: 12;
            }
        }
    }
`

const SSpeaceSpecialAbout = styled(SSpase)`
    aspect-ratio:320 / 75;
    margin-top: -10%;
    .decoration{
        &.d1{
            width: 24%;
            left: 10px;
            z-index: 11;
        }
        &.d2{
            left: 10px;
            width: 44%;
            top: 15%;
            right: -50%;
            z-index: 11;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:100 / 15;
        margin-top: -8%;
        .decoration{
            &.d1{
                left: 30%;
                width: 140px;
                top: 10%;
            }
            &.d2{
                left: 70%;
                width: 44%;
                top: 35%;
                right: 0%;
                z-index: 11;
                width: 400px;
            }
        }
    }
`

const SSpeaceAboutSupport = styled(SSpase)`
    aspect-ratio:320 / 200;
    margin-top: -8%;
    .decoration{
        width: 80%;
        left: 50%;
        transform: translateX(-56%);
        top: 0px;
        z-index: 13;
    }

    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio:100 / 18;
        margin-top: -3%;
        .decoration{
            width: 670px;
            right: auto;
            left: 80%;
            top: 0%;
            z-index: 10;
        }
    }
`


const TopPage = () => {
     
    const location = useLocation();
    const pathValue = pathAnalyzer(location)
    
    setTitleDiscrption({layer:'top', lang:pathValue.lang})
    
    // Refを使用してsection要素を参照  
    const sectionsRef = useRef([]);

   // スクロールイベントのハンドラー
    const handleScroll = () => {
        
        sectionsRef.current.forEach(sec => {
            let top = sec.getBoundingClientRect().top;
            let bottom = sec.getBoundingClientRect().bottom;
            
            if (top <= (window.innerHeight - 50) ) {
                sec.classList.add('active');
            } else {
            }
        });
    };

    // useEffectを使用してイベントリスナーを設定
    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
       
        // クリーンアップ関数
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

  
     //currentの取得
    useEffect(() => {
        // URLからパラメーターを取得
        const queryParams = new URLSearchParams(location.search);
        const current = queryParams.get('current');
    
        if (current !== null) {
          const index = parseInt(current, 10);
          if (index >= 0 && index < sectionsRef.current.length) {
            // 対応するセクションへスクロール
            const section = sectionsRef.current[index];
            if (section) {
            const offsetTop = section.offsetTop;

            // スクロールする位置を計算（セクションの位置 + 100px）
            const scrollPosition = offsetTop - 200;

            // 指定された位置にスクロール
            window.scrollTo({ top: scrollPosition});
            }
          }
        }
      }, [location]);


    return (
        <ResponsiveTopTemplate>
            
            <TopSection pathValue={pathValue}></TopSection>
           
            {/*新着情報*/}
            <SNewWrap className="left" ref={el => sectionsRef.current[0] = el} >
                <NewsSection pathValue={pathValue}></NewsSection>
            </SNewWrap>
            
            {/*県知事メッセージ*/}
            <SGwmWrap className="left" ref={el => sectionsRef.current[1] = el}>
                <GwmSection pathValue={pathValue}></GwmSection>
            </SGwmWrap>
           
             {/*基礎情報*/}
            <SBasicinfo className="left" ref={el => sectionsRef.current[2] = el}>
                <BasicInfoSection pathValue={pathValue}></BasicInfoSection>
            </SBasicinfo>  
    
            {/*セクション間キャラクター*/}
            <SSpaceBasicLiving>
                <div className="decoration">
                    <NavigatorAction 
                     dir="right"
                     charactorType="normal"
                     actionColor="green"
                     actionType="check"
                    ></NavigatorAction>
                </div>
            </SSpaceBasicLiving>
         
            {/*生活環境*/}
            <SLiving className="left" ref={el => sectionsRef.current[3] = el}>
                <LifeStyleSection pathValue={pathValue}></LifeStyleSection>
            </SLiving>
           
            <SSpaceLivingCons>
                <div className="decoration">
                    <img 
                        src={publicPath('images/top/peint04.png')}
                        className="img-fit"
                    />
                </div>
            </SSpaceLivingCons>

             {/*相談*/}
            <SConsultation className="left" ref={el => sectionsRef.current[4] = el}>
                <ConsultationSection pathValue={pathValue}></ConsultationSection>
            </SConsultation>
            
            <SSpaceConsInitia>
                <div className="decoration">
                    <ChallengerAction
                        dir="left"
                        charactorType="good"
                        actionType="nice"
                        actionColor="green"/>
                </div>
            </SSpaceConsInitia>

            {/*福岡県の取組*/}
            <SInitiatives className="left" ref={el => sectionsRef.current[5] = el}>
                <InitiaivesSeciton pathValue={pathValue}></InitiaivesSeciton>
            </SInitiatives>
    
            <SSpaceInitiaMovie>
                <div className="decoration d1">
                    <img 
                        src={publicPath('images/top/peint03.png')}
                        className="img-fit"
                    />
                </div>
            </SSpaceInitiaMovie>

            {/* 動画 */}
            <SMoive className="right" ref={el => sectionsRef.current[6] = el}>
                <MovieSection pathValue={pathValue}></MovieSection>
            </SMoive>
          
            <SSpeaceMovieVirtual>
                <div className="decoration">
                    <NavigatorAction 
                        dir="right"
                        charactorType="pointing"
                        actionType="enjoy"
                        actionColor="green"/>
                </div>
            </SSpeaceMovieVirtual>

            {/*仮想環境*/}
            <SVirtual className="left" ref={el => sectionsRef.current[7] = el}>
                <VirtualSection pathValue={pathValue}></VirtualSection>
            </SVirtual>

     
            <SSpeaceVirtualSpecial>
                <div className="decoration d1">
                    <img 
                        src={publicPath('images/top/peint01.png')}
                        className="img-fit"
                    />
                </div>
            </SSpeaceVirtualSpecial>
            {/*スペシャル*/}
            <SSpecial className="left" ref={el => sectionsRef.current[8] = el}>
                <SpecialSection pathValue={pathValue}></SpecialSection>
            </SSpecial>
           
            <SSpeaceSpecialAbout>
                <div className="decoration d1">
                    <img 
                        src={publicPath('images/top/peint03.png')}
                        className="img-fit"
                    />
                </div>
                <div className="decoration d2">
                    <img 
                        src={publicPath('images/top/peint02.png')}
                        className="img-fit"
                    />
                </div>
            </SSpeaceSpecialAbout>

            {/* FIOについて */}
            <SAbout className="left" ref={el => sectionsRef.current[9] = el}>
                <AboutSection pathValue={pathValue}></AboutSection>
            </SAbout>
            <SSpeaceAboutSupport>
                <div className="decoration">
                    <NavigatorAction 
                        dir="right"
                        charactorType="good"
                        actionType="goodluck"
                        actionColor="green"/>
                </div>
            </SSpeaceAboutSupport>

             {/* サポート */}
            <SSupport className="left" ref={el => sectionsRef.current[10] = el}>
                <SupprtSection pathValue={pathValue}></SupprtSection>
            </SSupport>
        </ResponsiveTopTemplate>
    )
}

export default TopPage