
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import Article from "@/components/Organisms/Article"
import { SDecTextUl } from "."

const LangZhCn = () => {

    return (<>
     <Article offset={0}>
    <InnerBox>
    <p>福冈县内居住着许许多多的外国人，居住外国人总人数在日本的各个都市中排名第9位，是一个有着极高人气的地方。</p>
    <h1>外国人总人数</h1>
    </InnerBox>
    <BlackNote>
    <SDecTextUl className="pc-flex col-2">
    <li>
    <p className="large">
    县内居住的外国人总人数
    </p>
    <p className="large">
    <span className="xl">89,518</span>人
    </p>
    <p className="middle">（截止2022年12月）</p>
    </li>
    <li>
    <p className="large">
    福冈地区的居住外国人总数
    </p>
    <p className="large">
    <span className="xl">53,009</span>人
    </p>
    <p className="middle">（截止2022年12月）</p>
    </li>
    <li>
    <p className="large">
    北九州地区的居住外国人总数
    </p>
    <p className="large">
    <span className="xl">18,870</span>人
    </p>
    <p className="middle">（截止2022年12月）</p>
    </li>
    <li>
    <p className="large">筑后地区的居住外国人总数</p>
    <p className="large">
    <span className="xl">12,686</span>人
    </p>
    <p className="middle">（截止2022年12月）</p>
    </li>
    <li>
    <p className="large">筑丰地区的居住外国人总人数</p>
    <p className="large">
    <span className="xl">4,953</span>人
    </p>
    <p className="middle">（截止2022年12月）</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    <InnerBox top={10} bottom={100}>
    <p className="text-small">出处;法务省【居住外国人统计】</p>
    </InnerBox>
    </Article>
    <div className="navposition01">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    <Article offset={0}>
    <InnerBox >
    <h1>
    留学生人数
    </h1>
    </InnerBox>

    <BlackNote>
    <SDecTextUl className="pc-flex col-2">
    <li>
    <p className="large">
    县内的留学生总人数
    </p>
    <p className="large">
    <span className="xl">19,188</span>人
    </p>
    <p className="middle">（截止2022年12月）</p>
    </li>
    <li>
    <p className="large">
    福冈地区的留学生总人数
    </p>
    <p className="large">
    <span className="xl">14,800</span>人
    </p>
    <p className="middle">（截止2022年12月）</p>
    </li>
    <li>
    <p className="large">
    北九州地区的留学生总人数
    </p>
    <p className="large">
    <span className="xl">2,631</span>人
    </p>
    <p className="middle">（截止2022年12月）</p>
    </li>
    <li>
    <p className="large">
    筑后地区的留学生总人数
    </p>
    <p className="large">
    <span className="xl">1,650</span>人
    </p>
    <p className="middle">（截止2022年12月）</p>
    </li>
    <li>
    <p className="large">
    筑丰地区的留学生总人数
    </p>
    <p className="large">
    <span className="xl">107</span>人
    </p>
    <p className="middle">（截止2022年12月）</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    <InnerBox top={10} bottom={100}>
    <p className="text-small">出处;法务省【居住外国人统计】</p>
    </InnerBox>
    </Article>
    <div className="navposition01">
    <NavigatorCheck dir="right"></NavigatorCheck>
    </div>
    <Article offset={0}>
    <InnerBox>
    <h1>
    留学生就职人数
    </h1>
    </InnerBox>
    <BlackNote>
    <SDecTextUl>
    <li>
    <p className="large">
    在位于县内的企业等就职的留学生人数
    </p>
    <p className="large">
    <span className="xl">1,118</span>人
    </p>
    <p className="middle">（2021年）</p>
    </li>
    </SDecTextUl>
    </BlackNote>
    </Article>
    </>)
}

export default LangZhCn