import { AritcleIndex } from "src/types/article"
import { langType } from "src/types/lang"
import styled from "styled-components"
import { CmsBlockArticle } from "../Block/article"
import { TCmsViewHandle } from "@/components/Organisms/Cms"

interface CmsListArticlesProps{
    articles : AritcleIndex,
    handleView : TCmsViewHandle
}

const SAritcleBlock = styled.div`
    margin-bottom: 40px;
`
const SCmsListArticles = styled.div`
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        display: flex;
        justify-content:flex-start;
        flex-wrap: wrap;

        > * {
            margin: 0;
            margin-right: 40px;
            flex-basis: calc(50% - 20px);
            /* (100 / x - (margin(%) - (margin(%) / x))   */
            margin-bottom: 40px;
            &:nth-child(2n){
                margin-right: 0;
            }
        }


    }
`

export const CmsListArticles = (props: CmsListArticlesProps) => {
    return (
        <SCmsListArticles>
            {
                props.articles.contents.map((article, i) => (
                    <SAritcleBlock key={article.id}>
                        <CmsBlockArticle 
                        handleView={props.handleView}
                        articleObj={article}></CmsBlockArticle>
                    </SAritcleBlock>
                ))
            }
        </SCmsListArticles>
    )
} 