import React, { PropsWithChildren, useState } from "react"

import LangJa from "./ja"
import LangEn from "./en"
import LangKo from "./ko"
import LangNe from "./ne"
import LangTh from "./th"
import LangVi from "./vi"
import LangZhCn from "./zhcn"
import LangZhTw from "./zhtw"
import { langType } from "src/types/lang"
import InnerBox from "@/components/Atoms/Box/innerBox"
  

export const AreaProvider = ({lang}:{lang:langType}) => {
    const [ onFukidashi, setOnFukidashi] = useState<number>(0)

    function onSwich( num: number) {
        if(onFukidashi > 0) { 
            setOnFukidashi(0)
        }else{
            onFukidashi === num ? setOnFukidashi(0) : setOnFukidashi(num)
        }
    }
    const langProps = {
        onFukidashi,
        onSwich
    }

    return (
        <InnerBox left={0} right={0} bottom={80}>
            {lang === 'ja' &&
                <LangJa {...langProps}></LangJa>
            }
            {lang === 'easy_ja' &&
                <LangJa {...langProps}></LangJa>
            }
            {lang === 'en' &&
                <LangEn {...langProps}></LangEn>
            }
            {lang === 'ko' &&
                <LangKo  {...langProps}></LangKo>
            }
            {lang === 'zh_cn' &&
                <LangZhCn  {...langProps}></LangZhCn>
            }
            {lang === 'zh_tw' &&
                <LangZhTw  {...langProps}></LangZhTw>
            }
            {lang === 'th' &&
                <LangTh  {...langProps}></LangTh>
            }
            {lang === 'vi' &&
                <LangVi  {...langProps}></LangVi>
            }
            {lang === 'ne' &&
                <LangNe  {...langProps}></LangNe>
            }
        </InnerBox>
    )
}