import { CharactorActionColor, CharactorActionType } from "src/types/charactor"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"

const SFigure = styled.figure`
    padding: 0;
    margin: 0;
`

export const CharactorAction = (
    {type, color}:{type:CharactorActionType, color:CharactorActionColor}) => {

    return (
        <SFigure>
             <img src={publicPath(`images/character/v2/action_${type}_${color}.png`)}
            alt={type} 
            className="img-fit"
        />
        </SFigure>
    )
}