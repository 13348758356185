const LangVi = () => {

    return (<>
    <h1>Chính sách website</h1>
    <p>
    Khi sử dụng Cổng thông tin điện tử https://www.fisop.net (dưới đây gọi là 'Cổng thông tin điện tử'), do Ban tổ chức dự án xúc tiến FUKUOKA IS OPEN (dưới đây gọi là 'Ban tổ chức') cung cấp, người sử dụng xin vui lòng đọc nội dung dưới đây. Lưu ý rằng nội dung dưới đây có thể thay đổi mà không thông báo trước.
    </p>
    <p>
    <dl>
    <dt>
    ●Về các danh mục miễn trách nhiệm
    </dt>
    <dd>
    <p>
    「Thông tin được đăng trên Cổng thông tin điện tử này đã được biên soạn cẩn thận nhưng về sự chính xác và tính an toàn của thông tin thì không có sự bảo đảm. Ban tổ chức không chịu trách nhiệm đối với bất kỳ thiệt hại hoặc tổn thất mà người sử dụng gặp phải do liên quan đến thông tin trên website này.
    </p>
    <p>
    Hơn nữa, có trường hợp Cổng thông tin điện tử này dẫn đến các trang chủ do các đơn vị khác ngoài Ban tổ chức quản lý. Các trang chủ được dẫn đến có trường hợp không phải do Ban tổ chức quản lý và vận hành. Xin lưu ý rằng chúng tôi không chịu trách nhiệm về sự chính xác hoặc độ tin cậy của nội dung trên các trang chủ đó.
    </p>
    <p>
    Nội dung của Cổng thông tin điện tử này có thể thay đổi hoặc xoá bỏ mà không thông báo trước nên xin vui lòng lưu ý.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●Về Bản quyền
    </dt>
    <dd>
    Về vấn đề bản quyền của thông tin được đăng trên Cổng thông tin điện tử này, bao gồm văn bản, logo, hình ảnh, video, phần mềm và tất cả các thông tin khác, trừ khi có ghi chú đặc biệt khác, bản quyền thuộc về Ban tổ chức hoặc bên thứ ba. Ngoại trừ các trường hợp được công nhận bởi luật bản quyền như sử dụng cho mục đích cá nhân, không được phép sử dụng thông tin này (bao gồm sao chép, sửa đổi, đăng lại, phát tán, phổ biến...) mà không có sự cho phép trước của chủ sở hữu bản quyền. Đồng thời, một số hình ảnh... thuộc bản quyền của tác giả gốc.
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●Về môi trường sử dụng (trình duyệt)
    </dt>
    <dd>
    <p>
    Chúng tôi khuyến khích người sử dụng nên mở Cổng thông tin điện tử này bằng bản cài đặt mới nhất của các trình duyệt.
    </p>
    <p>
    Lưu ý rằng trường hợp xem bằng các trình duyệt khác với các trình duyệt nói trên,Cổng thông tin điện tử này có thể không được hiển thi đúng.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●Các trang thường xuyên xem
    </dt>
    <dd>
    <p>
    Trên trình duyệt web, thông tin từ lần truy cập trước đó (thông tin được lưu tạm thời trên máy tính) có thể hiển thị.
    </p>
    <p>
    Do đó, đối với những trang thường xuyên truy cập, chúng tôi khuyến khích người sử dụng làm mới trang (truy cập lại) trước khi xem.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●Về JavaScript
    </dt>
    <dd>
    <p>
    Cổng thông tin điện tử này 1 phần có sử dụng JavaScript. Do đó nếu trình duyệt web không tương ứng sử dụng Javascript, hay sử dụng trong điều kiện vô hiệu hoá JavaScript, 1 số chức năng của trang web sẽ không thể hoạt động.
    </p>
    <p>
    Để sử dụng toàn bộ các chức năng, hãy sử dụng với trình duyệt web có cho phép sử dụng JavaScript, hoặc vui lòng kích hoạt tính năng dùng JavaScript trong trình duyệt.
    </p>
    </dd>
    </dl>
    </p>
    <p>
    <dl>
    <dt>
    ●Pháp luật áp dụng
    </dt>
    <dd>
    Việc sử dụng trang web cổng thông tin này và việc hiểu - áp dụng Chính sách trang web sẽ tuân theo pháp luật của Nhật Bản, trừ khi có quy định khác đặc biệt.<br/>
    Việc truy cập trang web cổng thông tin này là do ý muốn tự do của người truy cập, và họ chịu trách nhiệm cá nhân khi sử dụng trang web cổng thông tin này. Mọi tranh chấp liên quan đến trang web cổng thông tin này sẽ được giải quyết tại Tòa án Hình sự Fukuoka là tòa án chính thức duy nhất.
    </dd>
    </dl>
    </p>
    </>)
}

export default LangVi