import SubLayerTemplate from "@/components/Templates/subLayer";
import styled from "styled-components";

import { setTitleDiscrption } from "src/utilty/setTitleDiscription";
import { useLocation } from "react-router-dom";
import { pathAnalyzer } from "src/utilty/pathAnalyzer";
import ResponsiveFioTemplate from "@/components/Templates/Responsive/fio";
import { CmsIndex } from "@/components/Organisms/Cms";


const SNews = styled.div`
    .article-search-positon{
        margin: -10px 20px 0;
    }
`

const NewsPage = () => {
    const keywords = [
        '総合政策課',
        '海外事務所',
        '企業立地課',
        '中小企業振興課',
        '新規支援課',
        '輸出促進課',
        'サンプル',
        'サンプル',
        'サンプル',
        'サンプル',
    ]

    const location = useLocation()
    const lang = pathAnalyzer(location).lang

    setTitleDiscrption({
        layer : 'information',
        lang : lang
    })

    return (
        <ResponsiveFioTemplate>
            <SubLayerTemplate>
                <CmsIndex></CmsIndex>
            </SubLayerTemplate>
        </ResponsiveFioTemplate>
    )
}

export default NewsPage