import styled from 'styled-components'
import publicPath from "src/utilty/publicPath";

const ScrollImgL  =  'top/scroll_l.png'
const ScrollImgS = 'top/scroll_s.png'

const Button = styled.button`
    border: none;
    background: none;
    cursor: pointer;
    &:hover{
        opacity: 0.8;
    }
`

interface TopBtnProps {
    height?: number | 60,
    onClick: () => void 
}

export const PageTopButton = ({
    height = 60,
    onClick,
    ...props
}:TopBtnProps) => {
    
    
    return (
        <Button onClick={onClick}>
            <img 
                src={publicPath('images/pagetop.png')} 
                height={height}
                alt="Scrolle" />
        </Button>
    )
}


