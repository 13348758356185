import { NavigatorFullLength } from "@/components/Atoms/Character/navigator"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"
const LangTh = () => {

    return (<>
        <div className="navigatorPositon sp-only">
            <NavigatorFullLength></NavigatorFullLength>
        </div>
        <div className="pc-flex col-2">
            <div>
                <h1>
                ตราประจำจังหวัด
                </h1>
                <figure>
                <img src={publicPath('images/symbol01.png')} alt="県のシンボル" className="img-fit"/>
                </figure>
                <p>
                มีรูปทรงเหมือน "ดอกบ๊วย" ซึ่งเป็นดอกไม้ประจำจังหวัด แสดงถึงความเจริญก้าวหน้าพัฒนาของจังหวัดและความสามัคคีปรองดองของคนในจังหวัด
                <br/>
                กำหนดเมื่อวันที่ 10 พฤษภาคม 2509 ผ่านการประกวดคัดเลือก。
                </p>
            </div>
            <div>
                <h1>
                ● ดอกไม้ประจำจังหวัดฟุกุโอกะ
                ดอกบ๊วย
                </h1>
                <figure>
                <img src={publicPath('images/symbol02.png')} className="img-fit"/>
                </figure>
                <p>
                เมื่อวันที่ 19 มีนาคม 2497 สมาคมเพื่อนพันธุ์พืช NHK ได้กำหนดจากดอกบ๊วยของดาไซฟุ
                กล่าวกันว่าดอกบ๊วยนั้นเป็นที่ชื่นชอบของท่านสุกาวาระ มิจิซาเนะ ซึ่งเป็นเทพเจ้าแห่งการศึกษาของศาลเจ้าดาไซฟุ เท็นมังกู
                </p>
            </div>
            <div>
                <h1>
                ● นกประจำจังหวัดฟุกุโอกะ
                นกกระจ้อยญี่ปุ่น
                </h1>
                <figure>
                <img src={publicPath('images/symbol03.png')} className="img-fit"/>
                </figure>
                <p>
                กำหนดเมื่อวันที่ 3 กรกฎาคม 2507 ผ่านการประกวดคัดเลือก
                ในญี่ปุ่นมีคำกล่าวว่า "นกกระจ้อยคู่กับดอกบ๊วย"
                เป็นคำอุปมาเกี่ยวกับสิ่งเข้าคู่กันดีและกลมกลืนกันอย่างสวยงาม
                </p>
            </div>
            <div>
                <h1>
                ● ต้นไม้ประจำจังหวัดฟุกุโอกะ
                อาซาเลีย (กุหลาบพันปี)
                </h1>
                <figure>
                <img src={publicPath('images/symbol04.png')} className="img-fit"/>
                </figure>
                <p>
                กำหนดเมื่อวันที่ 5 กันยายน 2509 ในกิจกรรม "การรณรงค์ญี่ปุ่นสีเขียว"
                อาซาเลีย (กุหลาบพันปี) นิยมปลูกเป็นพืชสวนและบอนไซในเมืองคุรุเมะ อีกทั้งยังมีการส่งออกต้นกล้าไปต่างประเทศด้วย
                </p>
            </div>
        </div>
    </>)
}

export default LangTh