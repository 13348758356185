

import publicPath from "src/utilty/publicPath";


const LangEn = () => {

    return (<>
   <h1>Linking to the Portal Site</h1>
    <p>
    ●Linking to the Portal Site<br/>
    In principle, you are free to link to the Portal Site, regardless of whether the link is to the top page or an article page, and regardless of whether the link is commercial or non-commercial, as long as you clearly indicate that the link is to the Portal Site.<br/>
    If you link to the Portal Site, please send an e-mail to <a href="mailto:intpol@pref.fukuoka.lg.jp" target="_blank">intpol@pref.fukuoka.lg.jp</a>. We will confirm the source of the link. Please include the source address, your name (affiliation), and contact information (e-mail address) in the e-mail.
    </p>
    <p>
    Banners are available for linking to the Portal Site.<br/>
    The following three types of banners are available.<br/>
    In the alternative text of the banner image, please state that it is a link to the FUKUOKA IS OPEN Promotion Project Portal Site.
    </p>
    <p>Width 468 px × Height 60 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_468_60.png')}
    className="img-fit"/>
    </figure>
    </p>
    <p>Width 234 px × Height 60 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_234_60.png')}
    className="img-fit"/>
    </figure>
    </p>
    <p>Width 120 px × Height 90 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_120_90.png')}
    className="img-fit"/>
    </figure>
    </p>
    <br/>
    <br/>
    </>)
}

export default LangEn