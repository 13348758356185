import publicPath from "src/utilty/publicPath";


const LangKo = () => {

    return (<>
    <h1>링크에 대하여</h1>
    <p>
    ●링크에 대하여<br/>
    본 포털 사이트 링크는 본 포털 사이트 링크임을 명시하면 메인 페이지·기사 페이지, 영리·비영리 여부를 불문하고 원칙적으로 자유롭게 이용 가능합니다.<br/>
    링크를 첨부한 경우에는 <a href="mailto:intpol@pref.fukuoka.lg.jp" target="_blank">intpol@pref.fukuoka.lg.jp</a> 앞으로 메일을 보내주시기 바랍니다. 사이트를 확인해 드리겠습니다. 메일에는 사이트 주소, 성함(소속), 연락처(메일주소)를 기입해주시기 바랍니다.
    </p>
    <p>
    본 포털 사이트 링크에는 배너를 이용하실 수 있습니다.<br/>
    배너에는 다음 세 가지 종류가 있으니 원하시는 것을 다운로드하여 사용하시면 됩니다.<br/>
    또한 배너 사진의 대체 텍스트에는 FUKUOKA IS OPEN 추진사업 포털 사이트의 링크라는 취지를 기재해주시기 바랍니다.
    </p>
    <p>Width 468 px × Height 60 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_468_60.png')}
    className="img-fit"/>
    </figure>
    </p>
    <p>Width 234 px × Height 60 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_234_60.png')}
    className="img-fit"/>
    </figure>
    </p>
    <p>Width 120 px × Height 90 px
    <figure>
    <img src={publicPath('images/fio_banner/fio_120_90.png')}
    className="img-fit"/>
    </figure>
    </p>
    <br/>
    <br/>
    </>)
}

export default LangKo