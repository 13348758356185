import theme from "@/themes"
import publicPath from "src/utilty/publicPath"
import styled, { ThemeProvider } from "styled-components"
import Header from "../../Organisms/Header"
import Footer from "../../Organisms/Footer"
import PCSideNav from "../../Organisms/PCSide/nav"
import SeachForm from "@/components/Molecules/SearchForm"
import { HeaderPc } from "@/components/Organisms/Header/pc"
import { Emergency } from "@/components/Organisms/Emergency"

const SWrap = styled.div`
    background: ${props => props.theme.colors.red};
`

const SMain = styled.main`
    max-width: ${props => props.theme.innerMaxWidth};
    background: ${props => props.theme.colors.red};
    background-image: url(${publicPath('images/mainBk.png')});
    background-size: 100% auto;
    overflow-x:hidden;
    min-height: 100vh;
    *{
        white-space: pre-wrap;
    }

    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        width: 100%;
        max-width: 100%;
        background-image: url(${publicPath('images/pc/bk.webp')});
        background-size: 100% auto;
    }
    /*
    @media screen and (min-width: ${props => props.theme.innerMaxWidth}) {
       width: ${props => props.theme.innerMaxWidth};
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) and 
        (max-width: ${props => props.theme.responsive.pcLargePoint}) {
        width: 414px
    }*/
`

const SPCSide = styled.aside`
    background: ${props => props.theme.colors.red};
    position: fixed;
    height: 100vh;
    overflow-y: scroll;
    width:auto;
    top: 0;
    z-index: 0;
    display: none;
    z-index: 11;
    &.left{ 
        left: 0;
    }
    &.right{
        right: 0;
    }
    &> div{
        background: ${props => props.theme.colors.red};
        background-image: url(${publicPath('images/mainBk.png')});
        background-size: 100% auto;
        position: absolute;
        top: 0;
       
        height: 100%;
        z-index: -1;
        overflow: hidden;
       
        
    }
    /*
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) and 
        (max-width: ${props => props.theme.responsive.pcLargePoint}) {
        width: calc( (100% - 414px) / 2);
    }*/
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        display: block;
        z-index: 999;
    }
`

const SHeader = styled.div`

    .pc{
        display: none;
    }
    .sp{
        display: block;
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        .pc{
            display: block;
        }
        .sp{
            display: none;
        }
    }
`

const ResponsiveTopTemplate = (
    {children}:React.PropsWithChildren) => {
    
    return ( 
        <ThemeProvider theme={theme}>
            <SWrap>
                <SPCSide className="left">
                    <PCSideNav></PCSideNav>
                </SPCSide>
               
                               
                <Emergency>
                    <SHeader>
                        <div className="sp"><Header></Header></div>
                        <div className="pc">
                            <HeaderPc></HeaderPc>
                        </div>
                    </SHeader>
                    <SMain>
                    {children}
                    </SMain>
                </Emergency>

                
                <Footer></Footer>
            </SWrap>
          
            
        </ThemeProvider>
    )
}

export default ResponsiveTopTemplate

