import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorCheck } from "@/components/Atoms/Character/navigator"
import { DlNote } from "@/components/Atoms/TextBlock/dlNote"
import Article from "@/components/Organisms/Article"
import { TabTamplate } from "@/components/Templates/tab"

const LangJa = ({activeTab, handleTabClick}) => {

    return (
        <>
       
            <InnerBox bottom={60}>
            <h1>
                市町村窓口／国際交流協会
            </h1>
            <p>
            外国人が直面する様々な問題の対処、また、国際交流を推進するために、各市町村が相談窓口や国際交流センターを設置しています。これらの窓口では、言語サポートや法的なアドバイス、生活全般にわたるサポートが提供されます。
            </p>
            <h2>
                相談窓口一覧
            </h2>
            <DlNote color="orange">
                <dl>
                    <dt>
                        福岡地方
                    </dt>
                    <dd>
                    ●（公財）福岡県国際交流センター<br/>
                    　福岡県外国人相談センター<br/>
                    ●TEL.092-725-9207<br/>
                    　0120-279-906<br/>
                    ●対応言語 日、英、中、韓、ベトナム、ネパール、インドネシア、タガログ、タイ、マレー、スペイン、フランス、ドイツ、イタリア、ロシア、ポルトガル、ミャンマー、クメール、モンゴル、シンハラ、ヒンディー、ベンガル<br/>
                    ●受付時間 毎日（年末年始を除く）10:00～18:30<br/>
                    ※受付時間を令和6年1月4日から18:30を18:00に変更します。<br/>
                    ●所在地 福岡市中央区天神1-1-1アクロス福岡3階　<br/>
                    国際交流センターこくさいひろば入口<br/>
                    ●相談形態 面談、電話、メール<br/>
                    ●備考 メール fukuoka-maic@kokusaihiroba.or.jp<br/>
                    </dd>
                    <dd>
                    ●（公財）福岡よかトピア国際交流財団<br/>
                    　福岡市外国人総合相談支援センター<br/>
                    ●TEL.092-262-1799<br/>
                    　0120-66-1799<br/>
                    ●対応言語 日、英、中、韓、タイ、ベトナム、インドネシア、ポルトガル、スペイン、フランス、ドイツ、イタリア、ロシア、ネパール、タガログ、マレー、ミャンマー、クメール、モンゴル、シンハラ、ヒンディー、ベンガル<br/>
                    ※ウクライナ避難民支援員<br/>
                    ※ベトナム語相談支援員（火・金／10:00～16:00）<br/>
                    ※ネパール語相談支援員（月／9:00～17:30）<br/>
                    ※韓国語相談支援員（木／10:00～16:00）<br/>
                    ●受付時間 平日（祝日・年末年始を除く）9:00～17:30<br/>
                    ●所在地 福岡市博多区店屋町4-1福岡市国際会館1階<br/>
                    ●相談形態 面談、電話、LINE コール、メール<br/>
                    　問い合わせフォーム：<a href="https://www.fcif.or.jp/contact/" target="_blank">https://www.fcif.or.jp/contact/</a><br/>
                    ●備考 メールは日、英、中、韓以外は、機械翻訳で対応<br/>
                    外国語相談担当のほか、電話通訳、タブレット端末を利用して対応します。<br/>
                    </dd>       
                    <dd>
                    ●須恵町外国人相談窓口ワンストップセンター<br/>
                    ●TEL.070-4066-5327<br/>
                    ●対応言語 日、ベトナム、英<br/>
                    その他言語は翻訳ソフトで対応<br/>
                    ●受付時間 平日（祝日・年末年始を除く）9:00～17:30<br/>
                    ●所在地 糟谷郡須恵町大字須恵804番地１<br/>
                    ●相談形態 面談、電話、メール<br/>
                    </dd>
                    <dd>
                    ●古賀市多文化共生相談窓口<br/>
                    ●TEL.092-942-1165<br/>
                    ●対応言語 日、英<br/>
                    （その他の言語は多言語通訳タブレットや翻訳機器で対応）<br/>
                    ●受付時間 平日8:30～17:00（祝日・年末年始を除く）<br/>
                    ●所在地 古賀市駅東1-1-1<br/>
                    古賀市役所2階まちづくり推進課<br/>
                    ●相談形態 面談、電話、メール<br/>
                    ●備考 メール<br/>
                    kokusai@city.koga.fukuoka.jp<br/>
                    </dd>
                </dl>
            </DlNote>
            <br/>
    
            <DlNote color="orange">
                <dl>
                    <dt>北九州地方</dt>
                    <dd>
                    ●（公財）北九州国際交流協会<br/>
                    　北九州市多文化共生ワンストップインフォメーションセンター（黒崎）<br/>
                    ●TEL.080-6445-2606<br/>
                    ●対応言語 ≪23言語≫<br/>
                    日、英、中、韓、ベトナム、タガログ、ネパール、タイ、インドネシア、マレー、モンゴル、クメール、ミャンマー、ヒンディー、ベンガル、シンハラ、ロシア、ポルトガル、スペイン、フランス、イタリア、ドイツ、ウクライナ<br/>
                    ※メール対応は、日、英、中、韓、ベトナムのみ<br/>
                    ●受付時間 平日（祝日・年末年始を除く）9:30～16:00<br/>
                    ●所在地 北九州市八幡西区黒崎3-15-3<br/>
                    　コムシティ3階北九州国際交流協会内　<br/>
                    ●相談形態 面談、電話、メール<br/>
                    ●備考 メール helpdesk@kitaq-koryu.jp<br/>
                    ※外国語相談員のほか、タブレット端末や電話通訳サービスを利用して対応します。<br/>
                    ※対応言語は変動することがあります。
                    </dd>
                    <dd>
                    ●（公財）北九州国際交流協会北九州市多文化共生ワンストップインフォメーションセンター（小倉）<br/>
                    ●TEL.080-5278-8404<br/>
                    ●対応言語 ≪23言語≫<br/>
                    日、英、中、韓、ベトナム、タガログ、ネパール、タイ、インドネシア、マレー、モンゴル、クメール、ミャンマー、ヒンディー、ベンガル、シンハラ、ロシア、ポルトガル、スペイン、フランス、イタリア、ドイツ、ウクライナ<br/>
                    ※メール対応は、日、英、中、韓、ベトナムのみ<br/>
                    ●受付時間 平日（祝日・年末年始を除く）9:30～12:00、13:00～16:00<br/>
                    ●所在地 北九州市小倉北区大手町1-1小倉北区役所2階<br/>
                    ●相談形態 面談、電話、メール<br/>
                    ●備考 メール helpdesk@kitaq-koryu.jp<br/>
                    ※外国語相談員のほか、タブレット端末や電話通訳サービスを利用して対応します。<br/>
                    ※対応言語は変動することがあります。
                    </dd>
                    <dd>
                        ●苅田町外国人ワンストップ相談窓口<br/>
                        ●TEL.080-7310-7394<br/>
                        ●対応言語 日、英<br/>
                        その他言語は電話通訳サービスを利用<br/>
                        ●受付時間 平日（祝日・年末年始を除く）<br/>
                        　9:00～11：30/13：00～16：30<br/>
                        ●所在地 京都郡苅田町富久町一丁目19番地1<br/>
                        ●相談形態 面談、電話
                    </dd>
                    
                </dl>
            </DlNote>
            <br/>
            <DlNote color="orange">
                <dl>
                    <dt>筑後地方</dt>
                    <dd>
                    ●久留米市外国人相談窓口<br/>
                    　（協働推進部　広聴・相談課）<br/>
                    ●TEL.0942-30-9096<br/>
                    ●対応言語 日、英、他<br/>
                    （その他15か国語の言語を多言語映像通訳アプリにて対応）<br/>
                    ●受付時間 月～金 8:30～17:15<br/>
                    ●所在地 久留米市城南町15番地3久留米市役所6Ｆ　<br/>
                    ●相談形態 面談、電話、メール<br/>
                    Email：sodan@city.kurume.lg.jp<br/>
                    ●備考 広聴・相談課で行っている福岡出入国在留管理局、弁護士などの専門相談（１４団体）へ繋げています。
                    </dd>
                </dl>
            </DlNote>
            <br/>
            <DlNote color="orange">
                <dl>
                    <dt>筑豊地方</dt>
                    <dd>
                    ●飯塚市外国人相談窓口<br/>
                    ●TEL.0948-22-5500<br/>
                    ●対応言語 日、英、ベトナム等<br/>
                    （その他の言語は多言語通訳タブレットや翻訳機器で対応）<br/>
                    ●受付時間 平日8:30～17:15（祝日・年末年始を除く）<br/>
                    ●所在地 飯塚市新立岩5番5号<br/>
                    　飯塚市役所6階国際政策課　<br/>
                    ●相談形態 面談、電話、メール<br/>
                    ●備考 メール kokusai@city.iizuka.lg.jp<br/>
                    </dd>
                </dl>
            </DlNote>
            </InnerBox>
            <InnerBox bottom={0}>
                <h2>
                    国際交流協会一覧
                </h2>
                <TabTamplate>
                    <div className="tabs-custom">
                    <nav>
                        <div>
                            <button onClick={() => {handleTabClick('tab1')}} 
                                className={activeTab === 'tab1' ? 'tab-button active' : 'tab-button'}>
                                福岡市内
                            </button>
                        </div>
                        <div>
                        <button onClick={() => {handleTabClick('tab2')}} 
                            className={activeTab === 'tab2' ? 'tab-button active' : 'tab-button'}>
                            北九州地方
                        </button>
                        </div>
                        <div>
                        <button onClick={() => {handleTabClick('tab3')}} 
                            className={activeTab === 'tab3' ? 'tab-button active' : 'tab-button'}>
                            筑後地方
                        </button>
                        </div>
                        <div>
                        <button onClick={() => {handleTabClick('tab4')}} 
                            className={activeTab === 'tab4' ? 'tab-button active' : 'tab-button'}>
                            筑豊地区
                        </button>
                        </div>
                    </nav>
                    <div className={activeTab === 'tab1' ? 'tab-content active' : 'tab-content'} data-tab="tab1">
                        <DlNote color="orange">
                        <dl>
                        <dt>福岡市内</dt>
                        <dd>
                        ●（公財）福岡よかトピア国際交流財団<br/>
                        ●TEL.092-262-1700<br/>
                        ●ホームページ <a href="https://www.fcif.or.jp/" target="_blank">https://www.fcif.or.jp/</a><br/>
                        ●主な事業 アジア太平洋博覧会-福岡’89を記念する事業、市民の国際交流を促進する事業、在住外国人及び外国人学生を支援する事業、グローバル人材を育成する事業
                        </dd>
                        <dd>
                        ●春日市国際交流ネットワーク会<br/>
                        ●TEL.092-981-0118<br/>
                        ●ホームページ <a href="https://www.city.kasuga.fukuoka.jp/" target="_blank">https://www.city.kasuga.fukuoka.jp/</a><br/>
                        miryoku/kouryuu/1002680.html<br/>
                        ●主な事業 市文化祭等での国際理解啓発、国際交流団体間での情報交換・連携
                        </dd>
                        <dd>                        
                        ●大野城市国際交流協会<br/>
                        ●TEL.090-2067-3173<br/>
                        ●ホームページ<br/>
                            <a href="http://www.city.onojo.fukuoka.jp/030/080/010/index.html" target="_blank">http://www.city.onojo.fukuoka.jp/030/080/010/index.html</a><br/>
                        ●主な事業 異文化交流（国際交流フェスティバル、ことばの交流ひろば（日本語教室）等）、TOMORROW（青少年育成事業）、協会通信（BEGIN新聞）発刊など
                        </dd>
                        <dd>
                        ●宗像地域国際交流連絡協議会<br/>
                        ●TEL.0940-36-1169 <br/>
                        ●ホームページ なし<br/>
                        ●主な事業 市内在住の外国人等と市民の交流の場づくり、留学生の地域交流支援、英語教育
                        </dd>
                        <dd>
                        ●（公財）太宰府市国際交流協会<br/>
                        ●TEL.092-918-5391<br/>
                        ●ホームページ <a href="http://www.dciea.or.jp/" target="_blank">http://www.dciea.or.jp/</a><br/>
                        ●主な事業 国際交流促進事業（フレンズベル倶楽部メンーのつどい、世界文化体験講座など）、外国人学生支援事業（留学生フォーラム、日本文化体験講座）、在住外国人支援事業（日本語教室支援、生活支援ガイドブック）
                        </dd>
                        <dd>
                        ●糸島市国際交流協会<br/>
                        ●TEL.092-332-9166<br/>
                        ●ホームページ<br/>
                            <a href="http://www.window-kokusai.com/" target="_blank">http://www.window-kokusai.com/</a><br/>
                        ●主な事業 友好団体である韓国金海市金海文化院との交流、カレービュッフェ、そうめん流し、在住外国人を講師に招いての世界の料理教室、国際協力事業、広報誌発行（年3回）など。
                        </dd>
                        <dd>
                        ●那珂川市国際文化交流サークル<br/>
                        ●TEL.092-952-2092 <br/>
                        ●ホームページ<br/>
                        <a href="https://nakagawakokusai.wordpress.com/" target="_blank">https://nakagawakokusai.wordpress.com/</a><br/>
                        ●主な事業 「劇団やまもも」観劇会、英会話教室、中国語講座、バスハイク交流会、市民文化祭食文化バザー、留学生お別れ交流会参加など。
                        </dd>
                        <dd>
                        ●須恵町国際交流協会<br/>
                        ●TEL.092-935-1323<br/>
                        ●ホームページ なし<br/>
                        ●主な事業 語学研修事業、異文化交流事業、現地交流事業、広報事業
                        </dd>
                    </dl>
                        </DlNote>
                    </div>

                    <div className={activeTab === 'tab2' ? 'tab-content active' : 'tab-content'} data-tab="tab2">
                        <DlNote color="orange">
                        <dl>
                        <dt>北九州地方</dt>
                        <dd>
                        ●（公財）北九州国際交流協会<br/>
                        ●TEL.093-643-5931<br/>
                        ●ホームページ <a href="https://www.kitaq-koryu.jp/" target="_blank">https://www.kitaq-koryu.jp/</a><br/>
                        ●主な事業 外国人市民・留学生の支援、日本語教育の推進、国際交流団体との連絡調整及び活動の振興
                        </dd>
                        <dd>
                        ●芦屋町国際交流協会<br/>
                        ●TEL.093-223-0881（役場代表） <br/>
                        ●ホームページ なし<br/>
                        ●主な事業 国際交流パーティー、文化交流事業（料理教室・バスハイク）、広報誌の発行
                        </dd>
                    </dl>
                        </DlNote>
                    </div>

                    <div className={activeTab === 'tab3' ? 'tab-content active' : 'tab-content'} data-tab="tab3">
                        <DlNote color="orange">
                        <dl>
                            <dt>筑後地方</dt>
                            <dd>
                            ●（公財）久留米観光コンベンション国際交流協会<br/>
                            ●TEL.0942-31-1717<br/>
                            ●ホームページ <a href="https://welcome-kurume.com/" target="_blank">https://welcome-kurume.com/</a><br/>
                            ●主な事業 コンベンション事業・観光事業・国際交流事業（青少年を中心とした友好・姉妹都市交流、市民レベルの国際交流の推進、在住外国人・留学生の支援等）
                            </dd>
                            <dd>
                            ●おごおり国際交流協会<br/>
                            ●TEL.0942-72-2111（内線222）<br/>
                            ●ホームページ（Facebook）<br/>
                            「おごおり国際交流協会 Ogori International Association」<br/>
                            <a href="http://ja-jp.facebook.com/おごおり国際交流協会-Ogori-International-Association-139743002867410/" target="_blank">
                            http://ja-jp.facebook.com/おごおり国際交流協会-Ogori-International-Association-139743002867410/
                            </a>
                            <br/>
                            ●主な事業 国際理解講座、外国人のための日本語教室、小郡市民まつり出店など
                            </dd>
                            <dd>
                            ●（一財）ひしのみ国際交流センター
                            ●TEL.080-7936-4027<br/>
                            ●ホームページ（Facebook）<br/>
                            <a href="https://www.facebook.com/Hishinomi/" target="_blank">https://www.facebook.com/Hishinomi/</a>
                            <br/>
                            ●主な事業 海外派遣研修事業、国際交流推進事業、広報啓発事業
                            </dd>
                            <dd>
                            ●（一社）八女国際交流協会<br/>
                            ●TEL.090-7382-6650<br/>
                            ●ホームページ なし<br/>
                            ●主な事業 日本及び海外の芸術文化の普及と振興
                            </dd>
                        </dl>
                            </DlNote>
                        </div>

                        <div className={activeTab === 'tab4' ? 'tab-content active' : 'tab-content'} data-tab="tab4">
                            <DlNote color="orange">
                            <dl>
                            <dt>筑豊地区</dt>
                            <dd>
                            ●飯塚国際交流推進協議会<br/>
                            ●TEL.0948-22-5500<br/>
                            ●ホームページ なし<br/>
                            ●主な事業 スピーチコンテスト等の国際交流イベント、留学生などの外国人支援、ホームビジット事業等
                            </dd>
                            <dd>
                            ●糸田町国際交流振興会<br/>
                            ●TEL.0947-26-0038<br/>
                            ●ホームページ なし<br/>
                            ●主な事業 農業研修生交流事業
                            </dd>
                        </dl>
                        </DlNote>
                    </div>
                    </div>
                </TabTamplate>
                <br/>
                <NavigatorCheck dir="right"></NavigatorCheck>
            </InnerBox>
        </>
    )
}

export default LangJa