import publicPath from "src/utilty/publicPath"
import styled from "styled-components"
import {  ChallengerrFullLengthJump } from "@/components/Atoms/Character/challenger"
import {  NavigatorFullLengthJump } from "src/components/Atoms/Character/navigator"
import { langType } from "src/types/lang"
import { PathAnalysisValue } from "src/utilty/pathAnalyzer"

const STopSection = styled.section`
    width: 100%;
    aspect-ratio: 320 / 700;
    overflow: hidden;
    background: url(${publicPath('images/top/topSecitonBk.png')}) no-repeat;
    background-size: 100% auto;
    position: relative;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        aspect-ratio: 500 / 260;
        background: url(${publicPath('images/pc/bk_main.webp')}) no-repeat;
        background-size: auto 100%;
        background-position: right;
        overflow: visible;
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        aspect-ratio: 500 / 260;
    }
`

const SChallengerrPositon = styled.div`
    position: absolute;
    width: 100%;
    top: 15%;
    left:-5%;
    z-index: 1;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 37%;
        left: 260px;
        top: 4%;
    }

    /** PCレイアウト **/
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 37%;
        left: 17%;
        top: 4%;
    }
`

const SNavigatorPositon = styled.div`
    position: absolute;
    width: 66%;
    right: -4%;
    top: 35%;
    z-index: 2;
    
    /** PCレイアウト **/
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 25%;
        right: 0;
        top: 5%;
    }

     /** PCレイアウト **/
     @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 25%;
        right: 5%;
        top: 5%;
    }

   
`

const Sheadline = styled.h2`
    position: absolute;
    width: 100%; 
    top: 60%;
    z-index: 4;
    /** PCレイアウト **/
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 35%;
        top: 45%;
        left: 46%;
        transform: rotate(5deg);
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 35%;
        top: 44%;
        left: 38%;
        transform: rotate(5deg);
    }
    
`
const SMessage = styled.div`
    position: absolute;
    width: 54%;
    top: 17%;
    right: 0;
    z-index: 3;
    /** PCレイアウト **/
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 34%;
        top: 10%;
        right: auto;
        left: 45%;
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 32%;
        top: 10%;
        right: auto;
        left: 42%;
    }
   
   
`


const TopSection = ({pathValue}:{pathValue: PathAnalysisValue}) => {
    
    const massage = pathValue.tsutaeru ? 'top_message_easy_ja.png' : 'top_message.png'
    return (
        
        <STopSection>
            <SMessage>
                <img src={publicPath('images/top/welcom_to_fukuoka.png')}
                    alt='welcom_to_fukuoka'
                    className="img-fit"
                    />
            </SMessage>
            <Sheadline className={pathValue.tsutaeru ? 'easy_ja' : ''}>
                <img src={publicPath(`images/top/${massage}`)}
                    alt='福岡県に移住する外国人の方を応援します！'
                    className="img-fit"
                    />
            </Sheadline>
            <SChallengerrPositon>
                <ChallengerrFullLengthJump></ChallengerrFullLengthJump>
            </SChallengerrPositon>
            <SNavigatorPositon className={pathValue.tsutaeru ? 'easy_ja' : ''}>
                <NavigatorFullLengthJump></NavigatorFullLengthJump>
            </SNavigatorPositon>
            
        </STopSection>
      
    )
}

export default TopSection