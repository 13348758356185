import colors from "./color";
import { articlefontSizes, articleLineHeight } from "./fontSizes"
import { LinksStyle } from "./topPage";
import { responsive } from "./responsive";
const theme = {
    colors : colors,
    innerMaxWidth : '600px' ,
    fontSize: articlefontSizes,
    lineheight: articleLineHeight,
    topPage : {
        links: LinksStyle
    },
    responsive: responsive
}

export default theme 