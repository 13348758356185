import HeadLine from "@/components/Atoms/HeadLine"
import { YellowArrowLink } from "@/components/Atoms/Links/yellowAllowLink"
import { TopSectionCotainerTemplate } from "@/components/Templates/Responsive/topSectionContainer"
import { langType } from "src/types/lang"
import { tsutaeruToEastJa } from "src/utilty/easyJaCheker"
import getLayer from "src/utilty/getLayer"
import { PathAnalysisValue } from "src/utilty/pathAnalyzer"
import publicPath from "src/utilty/publicPath"
import styled from "styled-components"


const SSection = styled.section`
    position: relative;
    background:url(${publicPath('images/top/consultationBK.png')}) no-repeat;
    background-size:100% auto;
    background-position: bottom;
    width: 100%;
    height: 100%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        background: url('images/pc/bk_consultation.webp') no-repeat;
        background-size: auto 100%;
    }
`

const SHeadline = styled.h2`
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    top:0%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        width: 440px;
        transform: translateX(-50%) translateY(-20%) rotate(3deg);
    
    }
    @media screen and (min-width: ${props => props.theme.responsive.pcLargePoint}){
        width: 600px;
    }
`

const SMessagePosion = styled.div`
    font-size: ${props => props.theme.fontSize.top_mes_sp};
    color :#fff;
    font-weight: bold;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 22%;
    @media screen and (min-width: ${props => props.theme.responsive.minPc}){
        top: 30%;
        font-size: ${props => props.theme.fontSize.topSection_pc};
    }
`

const SLinks =  styled.ul`
    position: absolute;
    top: 35%;
    width: ${props => props.theme.topPage.links.ulWidth};
    left: 50%;
    transform: translateX(-50%);
    li{
        width: ${props => props.theme.topPage.links.liWidth};
        margin-top: -8px;
        &:first-child{
            width: 100%;
        }
        &:nth-child(2n){
            margin-top: ${props => props.theme.topPage.links.oddMarginTop};
            margin-left: ${props => props.theme.topPage.links.oddOffest};
        }
        &:last-child{
            margin-left: 0;
            width: 100%;
        }
    }
    @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
        display: flex;
        flex-wrap: wrap;
        top:50%;
        li{
            flex-basis: 33.3%;
            margin: 0;
            transform: rotate(2deg);
            &:nth-child(2n){
                margin: 0;
            }
        }
    }
`


const ConsultationSection = ({pathValue}:{pathValue: PathAnalysisValue}) => {
    const layer = getLayer('consultation')
    const allowLang = tsutaeruToEastJa(pathValue)
    return (
        <SSection>
            <TopSectionCotainerTemplate>
            <SHeadline>
                <HeadLine lang="ja" nameKey="consultation"></HeadLine>
            </SHeadline>
            <SMessagePosion>{layer[pathValue.lang].title}</SMessagePosion>
            <SLinks>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/consultation/international?lang=${pathValue.lang}`}
                        linkKey="kokusai_soudan"
                    />
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/consultation/immigrationbureau?lang=${pathValue.lang}`}
                        linkKey="shukkoku"
                    />
                </li>
                <li><YellowArrowLink 
                        lang={allowLang}
                        href={`/consultation/municipalitycounter?lang=${pathValue.lang}`}
                        linkKey="sichoson"
                    />
                </li>
            </SLinks>
            </TopSectionCotainerTemplate>
        </SSection>    
    )
}

export default ConsultationSection