
import { FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangJa = () => {

    return (
        <Article>
        <p>
        福岡出入国在留管理局では、在留資格についての様々な手続きを行っています。<br/>
        （在留期間の更新、在留資格の変更、永住の申請、アルバイトを行うための資格外活動許可の手続き、海外にいる外国人を呼び寄せるための手続きなど）。<br/>
        　主に在留資格等に関する入管手続の案内業務を実施する相談窓口として、外国人在留総合相談インフォメーションセンターを設置しています。近年、在留外国人が抱える生活上の困り事への対応の必要性も高まってきていることから、センターにおいては、従来の入管手続の案内に加え、生活全般に係る相談にも応対し、在留支援の入口としての機能を持たせています。
        </p>
        <NavigatorAction dir="right" actionType="pickup" charactorType="normal"></NavigatorAction>

        <EmphasisNote>
            <h2>相談ができる人</h2>
            <p>
            ●在留外国人やその家族、関係者など
            </p>
            <figure>
                <img src={publicPath('images/articles/nyukan02.png')} alt="福岡出入国在留管理局［申請窓口］"/>
                <figcaption>福岡出入国在留管理局［申請窓口］</figcaption>
            </figure>
            <h2>施設情報</h2>
            <p>
            ●施設名称／　<br/>
            福岡出入国在留管理局 審査管理部門<br/>
            ●所 在 地／　<br/>
            〒810-0073 福岡市中央区舞鶴3-5-25福岡第１法務総合庁舎6階<br/>
            ●電話番号／092-717-7595<br/>
            ●開庁時間／9時00分～16時00分<br/>［土・日・休祝日・年末年始は閉庁日］<br/>
            ●通訳／無し
            </p>
            <p>
            インフォメーションセンターにおいて、申請用紙の書き方を案内しています。<br/>
            ※インフォメーションセンターの開庁時間は申請窓口と同じです。英語・中国語・韓国語対応可。
            </p>
            <h2>出入国在留管理庁ホームページ</h2>
            <p>
            【URL】<br/>
            <a href="https://www.moj.go.jp/isa/index.html" target="_blank">https://www.moj.go.jp/isa/index.html</a>
            </p>
            <h2>福岡出入国在留管理局<br/>フェイスブック</h2>
            <p>
            【URL】<br/>
            <a href="https://www.facebook.com/fukuoka.nyukan.support" target="_blank">https://www.facebook.com/fukuoka.nyukan.support</a>
            </p>
            <FukidashiRedLeft>
            在留資格についての情報以外にも、申請窓口の混雑状況や役に立つ情報を掲載しています。ぜひ、いいね・フォローをお願いします。
            </FukidashiRedLeft>
            <figure>
                <img src={publicPath('images/articles/nyukan_fb_qr.png')} alt="福岡入管フェイスブック"/>
                <figcaption>福岡入管フェイスブック</figcaption>
            </figure>
            
        </EmphasisNote>
        <br/>
        <NavigatorAction dir="right" actionType="pickup" charactorType="pointing"></NavigatorAction>
    
        </Article>
    )
}

export default LangJa