
import InnerBox from "@/components/Atoms/Box/innerBox"
import { NavigatorAction } from "@/components/Molecules/CharactorAction"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import { TabTamplate } from "@/components/Templates/tab"
import EmphasisNote from "@/components/Atoms/TextBlock/emphasisNote"
import Article from "@/components/Organisms/Article";
import { MedicalTbody } from "."
import Data from 'src/json/ForeignPatientsMedical/zhtw.json'


const LangZhTW = ({onActive, active}) => {
    const medicals = Data.data

    return (
        <Article offset={0} className="pc-noTopMargin">
            <InnerBox>
                <h1>接診外國患者<br/>各地核心醫療機構</h1>
                <p>
                福岡縣為能建立外國人可以安心接受醫療的環境，正在各二級醫療地區設立核心醫療機構，作為接診外國病患的據點，其條件是多國語言對應與Cashless 支付OK！(電子支付等)。
                </p>
                </InnerBox>
                <InnerBox left={0} right={0} bottom={30}>
                <BlackNote>
                <div className="decoration">
                <p className="al-c star">有哪些醫療機構？</p>
                <p className="txt-middle">
                福岡縣接收外國病患的醫療中心 醫療機構名單如下。 （2023年6月）
                </p>
                <br/>
                <p className="txt-large al-c txt-yellow">
                Cashless(電子支付等) OK！
                </p>
                </div>
                </BlackNote>
                <div className="space-deco"></div>
            </InnerBox>
            <InnerBox>
            <TabTamplate>
                <ul className="tabnav-custom">
                    <li><button onClick={() => {onActive(0)}} className={`${active === 0 ? 'active' : ''}`}>Fukuoka City</button></li>
                    <li><button onClick={() => {onActive(1)}} className={`${active === 1 ? 'active' : ''}`}>Fukuoka Region<br/>（Outside Fukuoka City）</button></li>
                    <li><button onClick={() => {onActive(2)}} className={`${active === 2 ? 'active' : ''}`}>Chikugo Area</button></li>
                    <li><button onClick={() => {onActive(3)}} className={`${active === 3 ? 'active' : ''}`}>Chikuhō Area</button> </li>
                    <li><button onClick={() => {onActive(4)}} className={`${active === 4 ? 'active' : ''}`}>Kitakyushu Area</button></li>
                </ul>
            
                <nav className="tab">
                    <div className={`tab-content ${active === 0 ? 'active' : ''}`}>
                        <h3>Fukuoka City</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>市町村名稱</th>
                            <th rowSpan={2}>醫療機構名稱</th>
                            <th colSpan={2}>可接受的患者</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>取得</th>
                            <th rowSpan={2}>可使用語言</th>
                            </tr>
                            <tr>
                            <th>重症患者</th>
                            <th>輕症患者</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[0]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 1 ? 'active' : ''}`}>
                        <h3>Fukuoka Region (Outside Fukuoka City)</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>市町村名稱</th>
                            <th rowSpan={2}>醫療機構名稱</th>
                            <th colSpan={2}>可接受的患者</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>取得</th>
                            <th rowSpan={2}>可使用語言</th>
                            </tr>
                            <tr>
                            <th>重症患者</th>
                            <th>輕症患者</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[1]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 2 ? 'active' : ''}`}>
                        <h3>Chikugo Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>市町村名稱</th>
                            <th rowSpan={2}>醫療機構名稱</th>
                            <th colSpan={2}>可接受的患者</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>取得</th>
                            <th rowSpan={2}>可使用語言</th>
                            </tr>
                            <tr>
                            <th>重症患者</th>
                            <th>輕症患者</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[2]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 3 ? 'active' : ''}`}>
                        <h3>Chikuhō Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>市町村名稱</th>
                            <th rowSpan={2}>醫療機構名稱</th>
                            <th colSpan={2}>可接受的患者</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>取得</th>
                            <th rowSpan={2}>可使用語言</th>
                            </tr>
                            <tr>
                            <th>重症患者</th>
                            <th>輕症患者</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[3]}></MedicalTbody>
                        </table>
                    </div>
                    <div className={`tab-content ${active === 4 ? 'active' : ''}`}>
                        <h3>Kitakyushu Area</h3>
                        <table>
                            <thead>
                            <tr>
                            <th rowSpan={2}>市町村名稱</th>
                            <th rowSpan={2}>醫療機構名稱</th>
                            <th colSpan={2}>可接受的患者</th>
                            <th rowSpan={2}>JMIP<br/>※1<br/>取得</th>
                            <th rowSpan={2}>可使用語言</th>
                            </tr>
                            <tr>
                            <th>重症患者</th>
                            <th>輕症患者</th>
                            </tr>
                            </thead>
                            <MedicalTbody medicals={medicals[4]}></MedicalTbody>
                        </table>
                    </div>
                   
                </nav>
            </TabTamplate>
            <br/>
            <p className="txt-small">
            ※（註1）未列入此名單中的醫療機構，並非妨礙或拒絕接診外國患者。（註2）名單中所記載的醫療機構，並非是專門對應從國外來日本就醫為目的的外國患者為對象的名單。<br/>
            ※1 JMIP（ZhTWpan Medical Service Accreditation for International Patients）→接診外國病患的醫療機構認證制度<br/>
            該制度的目的是針對國內醫療機構接診外國病患時，是否考慮到各國家文化和宗教差異等，且能提供多種語言的醫療服務與友善的支援和應對措施，進行第三方評估審核，為外國人提供安全和安心的醫療服務系統，完善醫療體系。此認證由厚生勞動省於2012年所制定。（認證機構：日本醫學教育基金會）。<br/>
            ※2 提供21國的語言對應醫療機構，請利用「福岡國際醫療支援中心。」
            </p>
            <NavigatorAction dir="right" charactorType="normal" actionType="pickup"/>
            <EmphasisNote>
            <h1 className="c-h1">想了解其他信息？</h1>

            <p>
            全國的各處的核心醫療機構的相關情報，公開在厚生勞動省及日本觀光廳的官方網站。（網址如下）<br/>
            </p>
            <p>
            厚生勞動省HP<br/>
            <a href="https://www.mhlw.go.jp/stf/newpage_05774.html" target="_blank">
            https://www.mhlw.go.jp/stf/newpage_05774.html
            </a><br/>
            </p>
            <p>
            觀光廳HP<br/>
            <a href="https://www.jnto.go.jp/emergency/jpn/mi_guide.html" target="_blank">
            https://www.jnto.go.jp/emergency/jpn/mi_guide.html
            </a>
            </p>

            <p>
            搜尋福岡縣內的醫院、診所、牙科診所、兒科、助產所等，提供兒科急救、預防、保健、福祉等醫療資訊的主頁。<br/>
            </p>
            <p>
            福岡醫療資訊網<br/>
            <a href="https://www.fmc.fukuoka.med.or.jp/" target="_blank">
            https://www.fmc.fukuoka.med.or.jp/
            </a>
            </p>
            </EmphasisNote>
            </InnerBox>
        </Article>
    )
}

export default LangZhTW