import InnerBox from "@/components/Atoms/Box/innerBox"
import {FukidashiRedLeft } from "@/components/Atoms/Fukidashi/fukidashiRed"
import BlackNote from "@/components/Atoms/TextBlock/blackNote"
import Article from "@/components/Organisms/Article"
import publicPath from "src/utilty/publicPath"

const LangEn = () => {

    return (<>
    <InnerBox>
    <h1>How to find housing in Japan</h1>
    <p>
    In order to find housing in Fukuoka and live with peace of mind, please understand the following points, follow the appropriate procedures, and rely on the cooperation of the government's consultation services.
    </p>
    </InnerBox>
    <BlackNote>
    <ul className="deco">
    <li>
    <h4>１. How to search for properties</h4>
    <p>
    ●Use real estate information websites and apps to search for properties.<br/>
    ●Some real estate companies offer services in foreign languages."
    </p>
    </li>
    <li>
    <h4>２.Types of rental housing</h4>
    <p>
    ●Private housing: A house rented from a landlord (a person who rents a house)<br/>
    ●Public housing: A house rented by a prefecture or city.<br/>
    ●UR rental housing: A house rented by the “UR Urban Renaissance Agency.” No guarantor required."
    </p>
    </li>
    <li>
    <h4>３.Basics of rental contracts</h4>
    <p>
    ●Rental contracts usually include security deposits, key money, and rent payments. Make sure you understand the contract carefully and check the terms and conditions.
    </p>
    </li>
    <li>
    <h4>４.Security deposit and key money</h4>
    <p>
    ●The security deposit will be returned at the end of the contract, but it may be deducted if you are behind on rent or if there are parts of the room that are damaged due to dirt. Key money will not be returned.
    </p>
    </li>
    <li>
    <h4>５.Guarantor</h4>
    <p>
    ●Generally, a guarantor is required when signing a contract. The guarantor is responsible for any costs that the contractor cannot cover. Alternatively, you may use a rent liability guarantee company.
    </p>
    </li>
    <li>
    <h4>６.Internet and utilities</h4>
    <p>
    ●Many homes are equipped with Internet access. Utility costs (electricity, gas, water, etc.) must be paid separately from the rent.
    </p>
    </li>
    <li>
    <h4>７.Presence of furniture/home appliances</h4>
    <p>
    ●Depending on the rental property, furniture and home appliances may be provided. Check before deciding whether to move in or prepare your own.
    </p>
    </li>
    <li>
    <h4>８.Resident registration</h4>
    <p>
    ●After moving in, you will need to register as a resident. This procedure must be completed at the municipal office.
    </p>
    </li>
    <li>
    <h4>9.Living practices and rules</h4>
    <p>
    ●Japanese homes have unique living practices and manners, such as being considerate of neighbors and rules for separating trash. Please keep these in mind as well.
    </p>
    </li>
    </ul>
    </BlackNote>
    <InnerBox top={20} bottom={20}>
    <FukidashiRedLeft>
    The link below provides information in multiple languages that is useful for foreigners looking for housing, such as a glossary of frequently asked terms when looking for a home and an "Apartment Search Guidebook."
    </FukidashiRedLeft>
    <h1>
    Immigration Services Agency homepage “Guidebook on Living and Working”
    </h1>
    <ul className="links">
    <li>
    ●English：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392830.pdf</a>
    </li>
    <li>
    ●Chinese：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392831.pdf</a>
    </li>
    <li>
    ●Korean：<a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001393145.pdf</a>
    </li>
    <li>
    ●Vietnamese：<a href="https://www.moj.go.jp">https://www.moj.go.j/isa/content/001393146.pdfp</a>
    </li>
    <li>
    ●Thai： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●Nepali： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/001392839.pdf</a>
    </li>
    <li>
    ●Easy Japanese： <a href="https://www.moj.go.jp">https://www.moj.go.jp/isa/content/930004715.pdf</a>
    </li>
    </ul>
    </InnerBox>
    <InnerBox bottom={20}>
    <h1>Japan Property Management Association “Guidelines to Facilitate Private Rental Residence Occupancy by Non-Japanese Nationals”</h1>
    <p>
    <a href="https://www.jpm.jp">https://www.jpm.jp/foreign/</a>
    </p>
    </InnerBox>
    
    </>)
}

export default LangEn