import getLayer  from "src/utilty/getLayer"
import { NaviAndChallenger } from "@/components/Atoms/HeadLine/charactor"
import Article from "@/components/Organisms/Article"
import PageTitleInitiatives from "@/components/Molecules/PageTitle/initiatives"
import { useCmsContext } from "@/context/CmsContext";
import PageTitleMovie from "@/components/Molecules/PageTitle/movie";
import PageTitleNews from "@/components/Molecules/PageTitle/news";
import InnerBox from "@/components/Atoms/Box/innerBox";
import { ChallengerType, NavigatorType } from "src/types/charactor";
import styled from "styled-components";
import PageTitleEmergency from "@/components/Molecules/PageTitle/emergency";

const CharactorHeadLine = ({category}) => {

    interface charactorType {
        challengarType : ChallengerType,
        navigatorType : NavigatorType
    }
    let ncProps: charactorType
    //fAQ
    if(category === 'faq'){
        ncProps = { 
            challengarType : 'guts',
            navigatorType : 'normal'
         }
    //医療
    }else if(category === 'medical'){
        ncProps = { 
            challengarType : 'guts',
            navigatorType : 'normal'
         }
    //就労環境
    }else if(category === 'employment'){
        ncProps = { 
            challengarType : 'normal',
            navigatorType : 'good'
         }
    //教育環境
    }else if(category === 'education'){
        ncProps= { 
            challengarType : 'good',
            navigatorType : 'normal'
         }
    //観光
    }else if(category === 'sightseeing'){
        ncProps = { 
            challengarType : 'good',
            navigatorType : 'good'
         }
    //健康
    }else if(category === 'health'){
        ncProps = { 
            challengarType : 'guts',
            navigatorType : 'normal'
        }
    //環境
    }else if(category === 'environment'){
        ncProps = { 
            challengarType : 'normal',
            navigatorType : 'pointing'
        }
    //スポーツ
    }else if(category === 'sports'){
        ncProps = { 
            challengarType : 'guts',
            navigatorType : 'good'
        }
    }else{
        ncProps = { 
            challengarType : 'normal',
            navigatorType : 'normal'
         }
    }
    

    return (
        <NaviAndChallenger {...ncProps}></NaviAndChallenger>
    )
}

export const CmsArtileIndexHeadLine = ()  => {

    const CmsContext = useCmsContext()

    const layer = getLayer(CmsContext.pathAnalys.layer)
    const category = CmsContext.pathAnalys.category
    const data = layer.sublayer[category] ? layer.sublayer[category] : layer
    const lang = CmsContext.pathAnalys.lang
    //キャラクタータイプの設定
    
    const SHaedrLine = styled.div`
        @media screen and (min-width: ${props => props.theme.responsive.minPc}) {
            display: none;
        }
    `
   

    switch (CmsContext.pathAnalys.layer) {

        case 'initiatives' :
        case 'lifestyle':
        case 'support' :
            return (
                <SHaedrLine>
                    <CharactorHeadLine category={category}></CharactorHeadLine>
                    { category !== 'living' &&
                    <Article>
                        <p>
                            {data[lang].description}
                        </p>
                    </Article>
                    }
                </SHaedrLine>
            )

        case 'movie' : 
            return (
                <PageTitleMovie lang={lang}>
                    {data[lang].title}
                </PageTitleMovie>
            )
        case 'information' : 
            return (
                <InnerBox bottom={20} left={0} right={0}>
                    <PageTitleNews lang={lang}> </PageTitleNews>
                </InnerBox>
            )
        case 'emergency' : 
            return (
                <InnerBox bottom={20} left={0} right={0} >
                    <div className="al-c">
                    <PageTitleEmergency lang={lang} ></PageTitleEmergency>
                    </div>
                </InnerBox>
            )
        default : 

            return (
                <></>
            )
    }
    
} 

export const CmsArtileViewHeadLine = () => {
    const CmsContext = useCmsContext()
    const layer = getLayer(CmsContext.pathAnalys.layer)
    const category = CmsContext.pathAnalys.category

    const data = layer.sublayer[category] ? layer.sublayer[category] : layer

    return (
        <>
        {CmsContext.pathAnalys.layer == 'initiatives' && 
            <PageTitleInitiatives lang={CmsContext.pathAnalys.lang}>
                {data.ja.description}
            </PageTitleInitiatives>
        }
        </>
    )
}